import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Typography, Button, Dialog, DialogContent, DialogTitle, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { getJournalClubs, getJCUserDetails, getJournalClubDetails,setJCContent } from '../../../state/actions/journalclub';
import { acceptJCInvite, rejectJCInvite, joinJC, leaveJC } from '../../../api';
import { JC_CONTENT } from '../../../constants/constants';
import { pxToRem } from '../../../utils';
import { Navigate } from 'react-router';
import { JC_COLORS } from '../../../styles/constants';


const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 700,
    textTransform: 'none',

    [theme.breakpoints.up('md')]: {
        fontSize: '0.75rem',
        lineHeight: '0.875rem',
        //height: '42px',
        minWidth: '142px',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(12),
        lineHeight: pxToRem(20),
        //height: '28px',
        //minWidth: '150px',
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        '& .MuiDialogContent-root': {
            padding: "25px",
        },
        '& .MuiDialogActions-root': {
            padding: "25px",
        },
        '& .MuiDialog-paper': {
            width: '70%',
            //height: '68%'
        }
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialog-paper': {
            width: '80%',
            height: 'auto',
        }
    },

}));


const JCInviteButtons = ({ JCID, userEmail, nextAction, }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <StyledButton
                variant="contained"
                sx={{
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    width: 'fit-content',
                    display: 'inline-block',
                }}
                onClick={
                    async () => {

                        try {
                            const response = await acceptJCInvite(JCID, userEmail)
                            if (response) {
                                dispatch(getJournalClubDetails(JCID, userEmail))
                                dispatch(getJCUserDetails(JCID, userEmail));
                                dispatch(getJournalClubs(userEmail));
                                dispatch(setJCContent(JC_CONTENT.DEFAULT)); 
                            }
                            if (nextAction) {
                                nextAction()
                            }
                        } catch (error) {
                            console.log(error);
                            console.log(error?.response)
                            setErrorMessage(error?.response?.data);
                            setOpenErrorDialog(true);
                        }
                    }

                }
            >
                Accept Invite
            </StyledButton>
            <StyledButton
                variant="outlined"
                sx={{
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    marginLeft: '14px',
                    width: 'fit-content',
                    display: 'inline-block',
                }}
                onClick={
                    async () => {

                        try {
                            const response = await rejectJCInvite(JCID, userEmail)
                            if (response) {
                                dispatch(getJournalClubDetails(JCID, userEmail))
                                dispatch(getJCUserDetails(JCID, userEmail));
                                dispatch(getJournalClubs(userEmail));
                            }

                        } catch (error) {
                            console.log(error);
                            console.log(error?.response)
                            setErrorMessage(error?.response?.data);
                            setOpenErrorDialog(true);
                        }
                    }

                }
            >
                Reject Invite
            </StyledButton>
            <BootstrapDialog
                open={openErrorDialog}
                onClose={() => setOpenErrorDialog(false)}
            >
                <DialogTitle
                    sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                        backgroundColor: JC_COLORS.JC_RED,
                        color: JC_COLORS.JC_WHITE,
                    }}
                >
                    <Typography sx={{ ...theme.typography.H3_BITTER, fontWeight: 700, }}>Error</Typography>
                    <CloseIcon
                        sx={{
                            width: '24px',
                            height: '24px',
                            color: JC_COLORS.WHITE,
                        }}
                        onClick={() => setOpenErrorDialog(false)}
                    />

                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ ...theme.typography.H3_RALEWAY, fontWeight: 500, marginTop: "16px" }}>{errorMessage}</Typography>
                </DialogContent>

            </BootstrapDialog>
        </Box>
    )
}

export default JCInviteButtons