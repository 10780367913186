import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Chip,Avatar } from '@mui/material';
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Divider } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThanksEmoji,CopyLink } from '../../assets';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';

import { useSelector, useDispatch } from "react-redux";
import SaveIcon from '../../assets/SaveIcon';
import {
  ProposeIcon,
  ShareIcon } from '../../assets';
import { setSaved } from '../../state/actions/article';
import { AuthContext } from '../../context/AuthContext';
import ProposeToJC from './ProposeToJc';


  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    textAlign: 'initial',
}));

function ActionComponent(props) {
  const {isDisabled = false} = props
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const { isJCPage,publishJCIds } = props;
  const [openShare, setOpenShare] = React.useState(false)
  const [toastCopyMsg,setToastCopyMsg] = React.useState(false)
  const CurrentUrl = window.location.href

  const dispatch = useDispatch();
  const {
    PMID,
    savedByUser,
    status,
    isDeNovo
  } = useSelector((state) => state.article.articleData);
  


  const [open, setOpen] = React.useState(false);
  const [openThanks, setOpenThanks] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true)
  };
  const handleSaveClick = () => {
    if(!isDisabled){
    dispatch(setSaved(PMID,userEmail,"J00000000",savedByUser))
    }
  }
  const handleOpenShare = () =>{
    if(!isDisabled){
    setOpenShare(true)
    }
  }
  const handleCloseShare = () =>{
    setOpenShare(false)
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(CurrentUrl)
    setToastCopyMsg(true)
  }
  const handleCopyLinkClose = () =>{
    setToastCopyMsg(false)
  }

  const proposeHandleClose = () => {
    setOpen(false)
  }
  return (
    <React.Fragment>
    <Grid item>
        <Item sx={{textAlign: 'center'}}>
        <Button variant="contained" color="primary" 
          sx={{fontFamily: 'bitter',fontSize:'16px',fontWeight: '700',border:'0px',
            width:'100%',height:'35px',textTransform: 'none',cursor:'default',
            "&.MuiButtonBase-root:hover": {bgcolor: "#EF5B50"},
            "&.Mui-disabled": {bgcolor:"#838383",color:'#FFFFFF'}
          }} 
          disableElevation
          disableRipple
          disabled = {isDisabled}>
            Actions
        </Button>

        {/* Propose to JCs */}
        {!isDisabled && !isDeNovo && 
        <Box sx={{backgroundColor: '#F3F3F3',textAlign:'left', display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          alignItems: 'center',cursor:'pointer'}} mt={1} pt={1} pb={1} pl={2} onClick={handleClickOpen}>
          <Box sx={{display:'inline-block'}} >
            <ProposeIcon/>
          </Box>
          <Typography variant="BODY_COPY1_RALEWAY_DESKTOP" className="desktop-actions"
            sx={{ marginTop: '0px', marginLeft: "8px" }}>
            Propose to JCs
          </Typography>
        </Box>
        }
      
        <ProposeToJC open={open} onClose={proposeHandleClose} publishJCIds={publishJCIds}/>
              
        <Box sx={{backgroundColor: '#F3F3F3',textAlign:'left', display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',cursor: isDisabled ? 'default': 'pointer'}} mt={1} pt={1} pb={1} pl={2} onClick={handleSaveClick} disabled={isDisabled}>
        <Box sx={{display:'inline-block'}} pr={1} >
    
        <SaveIcon fillcolor={savedByUser && !isDisabled ? "#EF5B50" : "None" }/>
        </Box>
        <Typography variant="BODY_COPY1_RALEWAY_DESKTOP" className="desktop-actions" color={isDisabled ? "#838383" : "#262626"}
         sx={{marginTop: '-2px'}} >
          Save Article
        </Typography>
        </Box>

        { !isDeNovo &&
        <Box sx={{backgroundColor: '#F3F3F3',textAlign:'left', display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          alignItems: 'center',cursor: isDisabled ? 'default': 'pointer'}} mt={1} pt={1} pb={1} pl={2} onClick={handleOpenShare}>
          <Box sx={{display:'inline-block'}} pr={1}>
            <ShareIcon/>
          </Box>
          <Typography variant="BODY_COPY1_RALEWAY_DESKTOP" className="desktop-actions" color={isDisabled ? "#838383" : "#262626"}
              sx={{marginTop: '-5px'}}>
            Share
          </Typography>
        </Box>
        }


        {/* Share popup */}
        <BootstrapDialog
        onClose={handleCloseShare}
        aria-labelledby="customized-dialog-title"
        open={openShare}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseShare}>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box sx={{width:'450px',height:'300px',textAlign:'center'}} pt={3} pl={2} pr={2}>
            <Box pl={10} pr={10} pt={7} mb={2}>
            <Typography variant="h6"
              sx={{fontFamily: 'bitter',
                fontWeight: '600'
              }}
              gutterBottom>
              Share This Article
            </Typography>
            </Box>
            <Box>
              <Box onClick={handleCopyLink} sx={{cursor:'pointer'}}>
                <CopyLink />
              </Box>
              <Box>
                <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" 
                  sx={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    fontWeight: '500'
                  }}
                  gutterBottom mb={2}>
                  Copy Link
                </Typography>
              </Box>
            </Box>

            <div>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={toastCopyMsg}
                autoHideDuration={2000}
                onClose={handleCopyLinkClose}
                key={'top' + 'center'}
                sx={{top:'70%!important'}}
              >
                <SnackbarContent sx={{
                  backgroundColor:'#262626',
                  fontFamily:'Raleway',
                  fontSize: '14px',
                  padding:'0',
                  paddingLeft: '20px',
                  minWidth:'150px!important'
                  }}
                  message="Copied to Clipboard"
                />
              </Snackbar>
            </div>

          </Box>
        </DialogContent>
      </BootstrapDialog>
      

        </Item>
        </Grid>
        
    </React.Fragment>
  )
}

export default ActionComponent