import React, { useState } from 'react';
import { IconButton, Button, Box, Typography, Menu, Dialog, styled } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { pxToRem } from '../../../../utils';
import { JC_COLORS } from '../../../../styles/constants';
import DialogContentAddOrEditEntity from './DialogContentAddOrEditEntity';

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 700,
    textTransform: 'none',

    [theme.breakpoints.up('md')]: {
        fontSize: '0.75rem',
        lineHeight: '0.875rem',
        //height: '42px',
        width: '142px',
        backgroundColor: `${JC_COLORS.JC_WHITE}`,
        color: `${JC_COLORS.JC_BLACK}`,
        "&:hover": {
            backgroundColor: `${JC_COLORS.JC_RED}`,
            color: `${JC_COLORS.JC_WHITE}`
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'


    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(12),
        lineHeight: pxToRem(20),
        //height: '28px',
        minWidth: '150px',
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        '& .MuiDialogContent-root': {
            padding: "25px",
        },
        '& .MuiDialogActions-root': {
            padding: "25px",
        },
        '& .MuiDialog-paper': {
            width: '70%',
            //height: '68%'
        }
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialog-paper': {
            width: '80%',
            height: 'auto',
        }
    },

}));

const EditOrDeleteEntity = ({ name, type }) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const [openEditEntityDialogBox, setOpenEditEntityDialogBox] = useState(false);

    const [hovered, setHovered] = useState(null);
    return (

        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: 'center'
                }}>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography color="#262626"
                        variant="H3_RALEWAY"
                        sx={{
                            fontWeight: '700!important'
                        }}>
                        {name}
                    </Typography>

                    <Typography
                        variant="BODY_COPY2_RALEWAY"
                        color={JC_COLORS.JC_ICON}
                        sx={{
                            fontWeight: '700!important'
                        }}
                    >
                        Accreditor
                    </Typography>
                </Box>
                <IconButton
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    <DeleteIcon />
                    
                </IconButton>
                {/* <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <StyledButton
                            variant="standard"
                            endIcon={hovered === "edit" && <CheckIcon />}
                            onClick={
                                () => {
                                    setOpenEditEntityDialogBox(true);
                                    setHovered(null);
                                    setAnchorEl(false); 
                                }
                            }
                            onMouseOver={() => setHovered("edit")}
                        >
                            Edit
                        </StyledButton>

                        <StyledButton
                            variant="standard"
                            endIcon={hovered === "delete" && <CheckIcon />}
                            onClick={() => { }}
                            onMouseOver={() => setHovered("delete")}

                        >
                            Delete
                        </StyledButton>

                    </Box>

                </Menu> */}
            </Box>
            {/* <BootstrapDialog
                open={openEditEntityDialogBox}
                onClose={() => setOpenEditEntityDialogBox(false)}
            >
                <DialogContentAddOrEditEntity
                    name={name}
                    logoUrl={""}
                    type={""}
                    closeIconHandler={() => setOpenEditEntityDialogBox(false)}
                />
            </BootstrapDialog> */}
        </>





    );
}

export default EditOrDeleteEntity;