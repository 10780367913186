import {
  UPDATE_REPORT_NAV,
  FETCH_MEMBER_DATA_SUCCESS,
  UPDATE_ROOT_FILTER,
  FETCH_ROOT_DATA_SUCCESS,
  UPDATE_ROOT_DATE_RANGE,
  UPDATE_MEMBER_ID,
  UPDATE_CURRENT_TAB
} from "../actions/jcreports";

// intial state

const intialState = {
  reportNav: "Reports",
  reportRootData: {},
  reportRootFilter: "All Time",
  rootDateRange: { startDate: null, endDate: null },
  memberData: {},
  currentMemberData: null,
  currentTab:""
};

// Set up reducer

export default (state = intialState, action) => {
  switch (action.type) {
    case UPDATE_REPORT_NAV:
      return {
        ...state,
        reportNav: action.payload,
      };
    case UPDATE_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case UPDATE_MEMBER_ID:
      return {
        ...state,
        currentMemberData: action.payload,
      };
    case UPDATE_ROOT_FILTER: {
      return {
        ...state,
        reportRootFilter: action.payload,
      };
    }
    case UPDATE_ROOT_DATE_RANGE: {
      return {
        ...state,
        rootDateRange: action.payload,
      };
    }
    case FETCH_ROOT_DATA_SUCCESS: {
      return {
        ...state,
        reportRootData: action.payload,
      };
    }
    case FETCH_MEMBER_DATA_SUCCESS:
      return {
        ...state,
        memberData: action.payload,
      };
    default:
      return state;
  }
};
