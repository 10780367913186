import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from "./state/reducers";

import AuthContextProvider from './context/AuthContext';
import UserDataContextProvider from './context/UserDataContext';
import KeywordsContextProvider from './context/KeywordsContext';


export const loadState = () => {
  try {
    const serialState = localStorage.getItem('appState');
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState);
  } catch (err) {
    return undefined;
  }
};


export const saveState = (state) => {
  try {
    const serialState = JSON.stringify(state);
    localStorage.setItem('appState', serialState);
  } catch (err) {
    console.log(err);
  }
};

const persistedState = loadState();
export const store = createStore(reducers, persistedState, compose(applyMiddleware(thunk)));

store.subscribe(() => {
  saveState({
    /* example state */
    keywords: store.getState().keywords,
    sources: store.getState().sources,
    feedQueryParams: store.getState().feed.queryParameters,
    //userID: store.getState().user.userDetails.id,
  });
});


const queryClient = new QueryClient();


ReactDOM.render(
  // <React.StrictMode>
  <AuthContextProvider>
    <QueryClientProvider client={queryClient}>
      <UserDataContextProvider>
        <KeywordsContextProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </KeywordsContextProvider>
      </UserDataContextProvider>
    </QueryClientProvider>
  </AuthContextProvider>
  // </React.StrictMode>,
  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


