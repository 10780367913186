import React, { useMemo, useState } from 'react'
import { Box, Typography, Hidden, Avatar, Dialog, DialogTitle, DialogContent, IconButton, Tooltip, tooltipClasses } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { styled } from '@mui/system';

import { JC_COLORS } from '../../../styles/constants';
import { pxToRem } from '../../../utils';
import SectionTitle from '../components/SectionTitle';
import AvatarNameRow from '../components/AvatarNameRow';
import AvatarName from '../components/AvatarName';
import SquareAvatar from "../components/SquareAvatar";
import { Row } from '../../../common/components';

const GreyBox = styled(Box)(({ theme }) => ({
  backgroundColor: JC_COLORS.LIGHT_GREY,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('md')]: {
    borderRadius: '8px',
    padding: "9px 20px",
    marginBottom: '10px',
  },
  [theme.breakpoints.down('md')]: {
    borderRadius: '8px',
    padding: "9px 20px",
    marginBottom: '10px',
  }

}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontWeight: 600,
  textAlign: 'left',

  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '18px',
    maxWidth: '75%',
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    lineHeight: '19px',
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: 0,
    margin: 0,
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      width: '40%',
      height: '68%'
    }
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      borderRadius: '0px',
      margin: 0,
    }
  },

}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    //color: '#bdbdbd',
    color: '#838383',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    //backgroundColor: '#bdbdbd',
    backgroundColor: '#838383'
  },

}));



const PartnersAffiliates = ({ partnersAffiliatesList, showFiveElements }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const sortedPartnersAffiliatesList = useMemo(() => {
    return partnersAffiliatesList.sort((institutionA, institutionB) => institutionA?.priority - institutionB?.priority)
  }, [partnersAffiliatesList])
  return (
    <>
      <Hidden mdDown>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <SectionTitle>Partners / Affiliates</SectionTitle>

          {
            sortedPartnersAffiliatesList.length > 0 && (
              <AvatarNameRow
                name={`${sortedPartnersAffiliatesList[0].name} (${sortedPartnersAffiliatesList[0].status})`}
                logoUrl={sortedPartnersAffiliatesList[0].logoUrl}
              />

            )
          }
          {
            sortedPartnersAffiliatesList.length > 1 && (
              <AvatarNameRow
                name={`${sortedPartnersAffiliatesList[1].name} (${sortedPartnersAffiliatesList[1].status})`}
                logoUrl={sortedPartnersAffiliatesList[1].logoUrl}
              />
            )
          }
          {
            (sortedPartnersAffiliatesList.length > 2 && showFiveElements === true) && (
              <AvatarNameRow
                name={`${sortedPartnersAffiliatesList[2].name} (${sortedPartnersAffiliatesList[2].status})`}
                logoUrl={sortedPartnersAffiliatesList[2].logoUrl}
              />

            )
          }
          {
            (sortedPartnersAffiliatesList.length > 3 && showFiveElements === true) && (
              <AvatarNameRow
                name={`${sortedPartnersAffiliatesList[3].name} (${sortedPartnersAffiliatesList[3].status})`}
                logoUrl={sortedPartnersAffiliatesList[3].logoUrl}
              />
            )
          }
          {
            (sortedPartnersAffiliatesList.length > 4 && showFiveElements === true) && (
              <AvatarNameRow
                name={`${sortedPartnersAffiliatesList[4].name} (${sortedPartnersAffiliatesList[4].status})`}
                logoUrl={sortedPartnersAffiliatesList[4].logoUrl}
              />
            )
          }
          {
            sortedPartnersAffiliatesList.length > (showFiveElements === true ? 5 : 2) && (
              <Typography
                sx={{
                  marginLeft: "0px",
                  cursor: 'pointer',
                  fontFamily: 'Raleway',
                  fontWeight: 600,
                  fontSize: pxToRem(16),
                  lineHeight: pxToRem(22),
                  textAlign: 'left',
                }}
                onClick={() => setOpenDialog(true)}
              >
                {`+${sortedPartnersAffiliatesList.length - (showFiveElements === true ? 5 : 2)} More`}
              </Typography>

            )
          }
          {
            sortedPartnersAffiliatesList.length > (showFiveElements === true ? 5 : 2) && (
              <BootstrapDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
              >
                <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="H3_BITTER" sx={{ color: JC_COLORS.JC_ICON, fontWeight: 700, margin: '38px 0px 0px 38px' }}>Partners/ Affiliates</Typography>
                  <IconButton onClick={() => setOpenDialog(false)} sx={{ marginRight: '17px' }}>
                    <CloseIcon
                      sx={{
                        width: '32px',
                        height: '32px',
                        color: JC_COLORS.JC_ICON,
                      }}
                    />
                  </IconButton>
                </DialogTitle>
                <DialogContent
                  sx={{
                    marginRight: '17px',
                    marginLeft: '38px',
                    '&::-webkit-scrollbar': { width: '6px' },
                    '&::-webkit-scrollbar-track': { backgroundColor: JC_COLORS.JC_WHITE, borderRadius: '9px', },
                    '&::-webkit-scrollbar-thumb': { backgroundColor: "#BDBDBD", borderRadius: '9px' },
                  }}>

                  <Box>
                    <Box sx={{ height: '19px' }}></Box>
                    {
                      sortedPartnersAffiliatesList.length > 0 && sortedPartnersAffiliatesList.map((obj, i) => (

                        <Row sx={{ marginBottom: "8px" }} key={i}>
                          <SquareAvatar
                            name={obj.name}
                            logoUrl={obj.logoUrl}
                          >
                          </SquareAvatar>
                          <AvatarName>{`${obj.name} ` + `(${obj.status})`}</AvatarName>
                        </Row>

                      ))
                    }

                  </Box>

                </DialogContent>
              </BootstrapDialog>

            )
          }


        </Box>

      </Hidden>
      <Hidden mdUp>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',

          }}
        >
          <SectionTitle>Partners / Affiliates</SectionTitle>
          {
            sortedPartnersAffiliatesList.length > 0 && sortedPartnersAffiliatesList.map((obj, i) => (

              <Row sx={{ marginBottom: "8px" }} key={i}>
                <SquareAvatar
                  name={obj?.name}
                  logoUrl={obj?.logoUrl}
                >
                </SquareAvatar>
                <AvatarName>{`${obj?.name} ` + `(${obj?.status})`}</AvatarName>
              </Row>

            ))
          }

        </Box>

      </Hidden>
    </>



  )
}

export default PartnersAffiliates

// <GreyBox>
            //   <Row>
            //     <StyledText>{sortedPartnersAffiliatesList[0].name}</StyledText>
            //     <StyledText
            //       sx={{ color: JC_COLORS.JC_ICON, marginLeft: '4px' }}
            //     >
            //       {`(${sortedPartnersAffiliatesList[0].status})`}
            //     </StyledText>
            //   </Row>
            // </GreyBox>


            // <GreyBox key={obj.name}>
                      //   <Row>
                      //     <Typography
                      //       sx={{
                      //         fontFamily: 'Raleway',
                      //         fontWeight: 600,
                      //         textAlign: 'left',
                      //         fontSize: '14px',
                      //         lineHeight: '18px',
                      //       }}
                      //     >
                      //       {obj.name}
                      //       <span
                      //         style={{
                      //           color: JC_COLORS.JC_ICON,
                      //           marginLeft: '4px',
                      //           fontFamily: 'Raleway',
                      //           fontWeight: 600,
                      //           textAlign: 'left',
                      //           fontSize: '14px',
                      //           lineHeight: '18px',
                      //         }}
                      //       >
                      //         {`(${obj.status})`}
                      //       </span>
                      //     </Typography>

                      //   </Row>
                      // </GreyBox>
