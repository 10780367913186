import { fetchJCReports } from "../../api";

export const UPDATE_REPORT_NAV = "UPDATE_REPORT_NAV";
export const FETCH_MEMBER_DATA_SUCCESS = "FETCH_MEMBER_DATA_SUCCESS";
export const FETCH_ROOT_DATA_SUCCESS = "FETCH_ROOT_DATA_SUCCESS";
export const UPDATE_ROOT_FILTER = "UPDATE_ROOT_FILTER";
export const UPDATE_ROOT_DATE_RANGE = "UPDATE_ROOT_DATE_RANGE";
export const UPDATE_MEMBER_ID = "UPDATE_MEMBER_ID";
export const UPDATE_CURRENT_TAB = "UPDATE_CURRENT_TAB";

//Action Methods

export const updateReportNav = (nav) => {
  return {
    type: UPDATE_REPORT_NAV,
    payload: nav,
  };
};
export const updateCurrentTab = (tab) => {
  return {
    type: UPDATE_CURRENT_TAB,
    payload: tab,
  };
};

export const updateMemberId = (id) => {
  return {
    type: UPDATE_MEMBER_ID,
    payload: id,
  };
};

export const updateRootFilter = (filter) => {
  return {
    type: UPDATE_ROOT_FILTER,
    payload: filter,
  };
};

export const updateRootDateRange = (dateRange) => {
  return {
    type: UPDATE_ROOT_DATE_RANGE,
    payload: dateRange,
  };
};

export const fetchMemberDataSuccess = (data) => {
  return {
    type: FETCH_MEMBER_DATA_SUCCESS,
    payload: data,
  };
};
export const fetchRootDataSuccess = (data) => {
  return {
    type: FETCH_ROOT_DATA_SUCCESS,
    payload: data,
  };
};

export const getReportRootData = (JCID, queryParams) => {
  return (dispatch) => {
    fetchJCReports(JCID, queryParams)
      .then((response) => {
        if (response?.data) {
          dispatch(fetchRootDataSuccess(response.data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getMemberData = (memberId) => {
  return (dispatch) => {
    dispatch(fetchMemberDataSuccess(memberData));
  };
};

const memberData = {
  member_articles: [
    {
      proposedStartedOn: "2022-07-25",
      cmeCredits: 0.0,
      PMID: "33856477",
      numberOfComments: 0,
      numberOfNotes: 0,
      title:
        "Bibliometric Analysis of Neurology Articles Published in General Medicine Journals.",
      status: "Under Discussion",
    },
    {
      proposedStartedOn: "2022-07-25",
      cmeCredits: 0.0,
      PMID: "34480211",
      numberOfComments: 2,
      numberOfNotes: 0,
      title:
        "Neuron-specific biomarkers predict hypo- and hyperalgesia in individuals with diabetic peripheral neuropathy.",
      status: "Published",
    },
    {
      proposedStartedOn: "2022-07-27",
      cmeCredits: 0.0,
      PMID: "35608876",
      numberOfComments: 1,
      numberOfNotes: 0,
      title:
        "No pain management for labour: individual and organisational determinants: A secondary analysis of the 2016 French National Perinatal Survey.",
      status: "Published",
    },
    {
      proposedStartedOn: "2022-07-25",
      cmeCredits: 0.0,
      PMID: "35834174",
      numberOfComments: 1,
      numberOfNotes: 0,
      title:
        "National Trends in the Achievement of Recommended Strategies for Stroke Prevention in U.S. Adults With Type 2 Diabetes, 2001-2018.",
      status: "Published",
    },
    {
      proposedStartedOn: "2022-07-22",
      cmeCredits: 0.0,
      PMID: "35834175",
      numberOfComments: 0,
      numberOfNotes: 0,
      title:
        "Population-Level Impact and Cost-effectiveness of Continuous Glucose Monitoring and Intermittently Scanned Continuous Glucose Monitoring Technologies for Adults With Type 1 Diabetes in Canada: A Modeling Study.",
      status: "Under Discussion",
    },
    {
      proposedStartedOn: "2022-07-27",
      cmeCredits: 0.0,
      PMID: "35834242",
      numberOfComments: 0,
      numberOfNotes: 0,
      title:
        "Remnant Cholesterol and Its Visit-to-Visit Variability Predict Cardiovascular Outcomes in Patients With Type 2 Diabetes: Findings From the ACCORD Cohort.",
      status: "Rejected",
    },
    {
      proposedStartedOn: "2022-07-27",
      cmeCredits: 0.0,
      PMID: "35857322",
      numberOfComments: 0,
      numberOfNotes: 0,
      title:
        "Advance Care Planning Experiences Among Sexual and Gender Minority People.",
      status: "Rejected",
    },
    {
      proposedStartedOn: "2022-08-16",
      cmeCredits: 0.0,
      PMID: "35943414",
      numberOfComments: 0,
      numberOfNotes: 0,
      title:
        "Factors associated with severe or fatal clinical manifestations of SARS-CoV-2 infection after receiving the third dose of vaccine.",
      status: "Under Discussion",
    },
  ],
  numAllCMECredits: 0,
  numAllStartedDenovoArticles: 0,
  member: {
    profession: "Consumer*",
    country: "Albania",
    profilePhotoURL: null,
    role: "Course Director",
    is_licensed_professional: false,
    about:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    status: "Joined",
    institution: "Stanford University Medical School",
    name: "Akash Coursedirector",
  },
  numAllArticlesProposed: 0,
  numAllArticlesPublished: 0,
  numAllArticlesRead: 0,
};
