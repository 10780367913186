import React from 'react'
import { Box } from '@mui/system'

const Spacer = ({width="0vw", height="0vh"}) => {
  return (
    <Box
        sx={{
            width: width, 
            height: height
        }}
    >

    </Box>
  )
}

export default Spacer