import React from 'react'; 
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { JC_COLORS } from '../../../styles/constants';

const StyledTitle = styled(Typography)(({ theme }) => ({
    color: JC_COLORS.JC_ICON,
    ...theme.typography.H3_BITTER, 
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
        marginBottom: '19px',
        fontSize: "16px",
        lineHeight: "22px",
    },
    [theme.breakpoints.down('md')]: {
        marginBottom: '12px',
        fontSize: "12px",
        lineHeight: "19px",
    }
}));


const SectionTitle = ({children}) => {
    return (
        <StyledTitle>{children}</StyledTitle>
    )
}

export default SectionTitle