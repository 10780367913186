import {ContentState, convertFromHTML, EditorState,Modifier,convertToRaw} from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import {htmlToText} from 'html-to-text';
import { pdfImgUrl,videoImgUrl } from '../constants/constants';
import axios from "axios";
import { convertBytes, updateInFilesEditor,updateInFilesDOM,DOCUMENT_EDIT_CONSTANT } from "./editorBasics";

const encodeGetParams = params => Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

export const getEntities = (editorState, entityType = null) => {
    const content = editorState.getCurrentContent();
    const entities = [];
    content.getBlocksAsArray().forEach((block) => {
        let selectedEntity = null;
        block.findEntityRanges(
            (character) => {
                if (character.getEntity() !== null) {
                    const entity = content.getEntity(character.getEntity());
                    if (!entityType || (entityType && entity.getType() === entityType)) {
                        selectedEntity = {
                            entityKey: character.getEntity(),
                            blockKey: block.getKey(),
                            entity: content.getEntity(character.getEntity()),
                        };
                        return true;
                    }
                }
                return false;
            },
            (start, end) => {
                entities.push({...selectedEntity, start, end});
            });
    });
    return entities;
};

export const CovertEditorToText = (editorState) => {
    const options = {
        selectors: [ 
            { selector: 'img', format: 'skip' },
            { selector: 'a', options: { ignoreHref: true }}
        ]
      };
    const text = htmlToText(editorState,options)
    const cleanedText = text.replace(/\s+/g, ' ').trim();
    return cleanedText
}

export const embedVideoCallBack = (link) =>{
    if (link.indexOf("youtube") >= 0){
        link = link.replace("watch?v=","embed/");
        link = link.replace("/watch/", "/embed/");   
    }
    else{
      link = link.replace("youtu.be/","youtube.com/embed/");
    }
    return link
}

export const makeid = (length) => {
    var result           = ''
    var characters       = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    var charactersLength = characters.length
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export const htmlToJCEditor = (htmlData,commentMedia) =>{
    var parser = new DOMParser();
    var doc = parser.parseFromString(htmlData, "text/html");
    if(commentMedia && commentMedia.length > 0){
        var allImg = doc.body.querySelectorAll('img')
        allImg.forEach(function(item){
            const imgId = item.getAttribute('id')
            if(commentMedia.filter(media => media.fileId === imgId).length > 0){
            const newSrc = commentMedia.filter(media => media.fileId === imgId)[0].downloadUrl
            item.setAttribute('src',newSrc)
            }
        })
        var videoTags = doc.body.querySelectorAll('video')
        videoTags.forEach(function(vItem){
            const vId = vItem.getAttribute('id')
            if(commentMedia.filter(vmedia => vmedia.fileId === vId).length > 0){
                const newSrc = commentMedia.filter(vmedia => vmedia.fileId === vId)[0].downloadUrl
                vItem.setAttribute('src',newSrc)
            }
        })
        var iframeTags = doc.body.querySelectorAll('iframe')
        iframeTags.forEach(function(vItem){
            const iframeId = vItem.getAttribute('id')
            const newId = iframeId + DOCUMENT_EDIT_CONSTANT
            if(iframeId){
                var resultObj = {}
                if(commentMedia.filter(vmedia => vmedia.fileId === iframeId).length > 0){
                    resultObj = commentMedia.filter(vmedia => vmedia.fileId === iframeId)[0]
                    const newSrc = resultObj.downloadUrl
                    vItem.setAttribute('id',newId)
                    vItem.setAttribute('data-src',newSrc)
                }
                setTimeout(()=> {
                    const inputFile = {
                        id: newId,
                        src: resultObj.downloadUrl,
                        file : {
                            name: resultObj.fileName,
                            size : resultObj.fileSize
                        }
                    }
                    updateInFilesEditor(inputFile,true)
                },1000)
            }
        })
    }
    return doc.body.innerHTML
}
export const convertToRender = (htmlData,commentMedia) =>{
    var parser = new DOMParser();
    var doc = parser.parseFromString(htmlData, "text/html");
    if(commentMedia && commentMedia.length > 0){
        var allImg = doc.body.querySelectorAll('img')
        allImg.forEach(function(item){
            const imgId = item.getAttribute('id')
            if(commentMedia.filter(media => media.fileId === imgId).length > 0){
            const newSrc = commentMedia.filter(media => media.fileId === imgId)[0].downloadUrl
            item.setAttribute('src',newSrc)
            }
        })
        var videoTags = doc.body.querySelectorAll('video')
        videoTags.forEach(function(vItem){
            const vId = vItem.getAttribute('id')
            if(commentMedia.filter(vmedia => vmedia.fileId === vId).length > 0){
                const newSrc = commentMedia.filter(vmedia => vmedia.fileId === vId)[0].downloadUrl
                vItem.setAttribute('src',newSrc)
            }
        })
        var iframeTags = doc.body.querySelectorAll('iframe')
        iframeTags.forEach(function(vItem){
            const iframeId = vItem.getAttribute('id')
            if(iframeId){
                var resultObj = {}
                if(commentMedia.filter(vmedia => vmedia.fileId === iframeId).length > 0){
                    resultObj = commentMedia.filter(vmedia => vmedia.fileId === iframeId)[0]
                    const newSrc = resultObj.downloadUrl
                    vItem.setAttribute('data-src',newSrc)
                }
                setTimeout(()=> {
                    const inputFile = {
                        id: iframeId,
                        src: resultObj.downloadUrl,
                        fileName: resultObj.fileName,
                        fileSize : resultObj.fileSize
                    }
                    updateInFilesDOM(inputFile)
                },1000)
            }
        })
    }
    return doc.body.innerHTML
}

export const updateFileEntiyList = (editorState,fileEntityList) => {
    const content = editorState.getCurrentContent()
    const entityKeys = Object.keys(convertToRaw(content).entityMap)
    var newEntityList = []
    if(entityKeys.length > 0){
        const entityList = getEntities(editorState)
        entityList.forEach(function(entity){
            if(fileEntityList.includes(entity.entityKey) === false){
            newEntityList.push(entity.entityKey)
            } 
        })
    }
    return newEntityList
}

export const updateImageEntityAttribute = (editorState,fileEntityList,recentUploadFile) => {
    const content = editorState.getCurrentContent()
    const entityKeys = Object.keys(convertToRaw(content).entityMap)
    var newEntityList = fileEntityList
    if(entityKeys.length > 0){
        const entityList = getEntities(editorState)
        entityList.forEach(function(entity){
            if(fileEntityList.includes(entity.entityKey) === false){
            content.mergeEntityData(entity.entityKey,{alt:recentUploadFile.fileName})
            newEntityList.push(entity.entityKey)
            } 
        })
    }
    return newEntityList
}

export const convertFileArraytoPost = (uploadFileArray) =>{
    var resultArray = []
    if(uploadFileArray?.length > 0){
        uploadFileArray.map(uploadFile => {
            var result = {fileId: '',fileName : '',fileSize: '',mimeType:''}
            result.fileId = uploadFile.id
            result.fileName = uploadFile.file.name
            result.fileSize = uploadFile.file.size
            result.mimeType = uploadFile.file.type
            resultArray.push(encodeGetParams(result))
        })
    }
    return resultArray.join("|")
}

export const uploadToS3FromUrl = (signedUrl,uploadedFile,upload_data) => {
    return new Promise((resolve, reject) => { 
        const dataToS3 = uploadedFile.file
        const form  = new FormData()

        form.append("x-amz-algorithm", upload_data['x-amz-algorithm'])
        form.append("x-amz-credential", upload_data['x-amz-credential'])
        form.append("x-amz-date", upload_data['x-amz-date'])
        form.append("Policy", upload_data.policy)
        form.append("x-amz-signature", upload_data['x-amz-signature'])
        form.append("key", upload_data.key)
        form.append("x-amz-security-token", upload_data['x-amz-security-token'])
        form.append('file', dataToS3)

        axios.post(signedUrl, form,{
        headers:{
            'Content-Type':' multipart/form-data',
            'x-amz-acl': 'public-read'
        }
        })
        .then(r => {
        // NOTE: S3 upload successful
            if(r.status >= 200 && r.status < 400){
            resolve(r.status)
            console.log("Upload done")
            }
        })
        .catch(err => { 
            console.warn('An error occurred while uploading. ', err)
        })
    })           
}

export const addWrapperToContent = (contentHTML,commentMedia = null) => {
    var resultObject = {
        wrappedContent : '',
        UploadedAndExistIDLIst: [],
        fileIdListToUpload: []
    }

    var parser = new DOMParser();
    var doc = parser.parseFromString(contentHTML, "text/html");
    
    const UploadedAndExistIDLIst = []
    const srcList = []

    // Get all Images
    var allImg = doc.body.querySelectorAll('img')
    allImg.forEach(function(item){
        var imgId = item.getAttribute('id')
        if(commentMedia &&  commentMedia.filter(media => media.fileId === imgId).length > 0){
          const id = commentMedia.filter(media => media.fileId === imgId)[0].fileId
          UploadedAndExistIDLIst.push(id)
        } else {             
          srcList.push(imgId)
        }
    })

    // Get all iframes
    var allIframes = doc.body.querySelectorAll('iframe')
    allIframes.forEach(function(item){
        var iframeId = item.getAttribute('id')
        if(iframeId){
            iframeId = iframeId.replace(DOCUMENT_EDIT_CONSTANT,"")
        }
        if( iframeId && commentMedia &&  commentMedia.filter(media => media.fileId === iframeId).length > 0){
          const id = commentMedia.filter(media => media.fileId === iframeId)[0].fileId
          item.setAttribute('id',id)
          UploadedAndExistIDLIst.push(id)
        } else if(iframeId){             
          srcList.push(iframeId)
        }
    })

    // Get all videos
    var allVideos = doc.body.querySelectorAll('video')
    allVideos.forEach(function(item){
        var videoId = item.getAttribute('id')
        if( videoId && commentMedia &&  commentMedia.filter(media => media.fileId === videoId).length > 0){
          const id = commentMedia.filter(media => media.fileId === videoId)[0].fileId
          UploadedAndExistIDLIst.push(id)
        } else if(videoId){             
          srcList.push(videoId)
        }
    })
    
    resultObject.wrappedContent = doc.body.innerHTML
    resultObject.UploadedAndExistIDLIst = UploadedAndExistIDLIst
    resultObject.fileIdListToUpload = srcList
    return resultObject

}

export const getFileObjectsToUpload = (imageFiles,videoFiles,documentFiles,idList) => {
    var fileListToUpload = [];
    const imageToUpload = imageFiles.filter((image) => idList.includes(image.id))
    const videoToUpload = videoFiles.filter((video) => idList.includes(video.id))
    const documentToUpload = documentFiles.filter((document) => idList.includes(document.id))
    fileListToUpload = [...imageToUpload,...videoToUpload,...documentToUpload]
    return fileListToUpload
}