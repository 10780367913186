import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router';
import { Box, Typography } from '@mui/material';
import { Spacer, TopDrawer, CenteredActionButton } from "../../../common/components";
import { JC_COLORS } from '../../../styles/constants';
import { OPTIONS_SORT_ARTICLES_BY_TYPE, OPTIONS_SORT_ARTICLES_BY_TIME } from '../../../constants/constants';
import { updateFeedQueryParameters, getUserFeed, getUserSavedArticles } from '../../../state/actions/feed';
import CustomRadioArticleType from '../../../pages/Feed/components/CustomRadioArticleType';
import CustomRadioDateRange from '../../../pages/Feed/components/CustomRadioDateRange';
import { AuthContext } from '../../../context/AuthContext';

const SortDrawer = ({ open, closeHandler, openHandler, filterCount = 8 }) => {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  let { feed, keywords, sources, user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const content = location.pathname.replace(/\//, '');
  const [dateRange, setDateRange] = useState(feed.queryParameters.dateRange);
  const [dateRangeLabel, setDateRangeLabel] = useState(feed.queryParameters.dateRangeLabel);
  const [orderByLabel, setOrderByLabel] = useState(feed.queryParameters.orderByLabel);

  const applySort = () => {
    let orderBy = OPTIONS_SORT_ARTICLES_BY_TYPE[orderByLabel];
    let unreadOnly = feed.queryParameters.unreadOnly;
    let pageNumber = 1;
    let queryParams = {
      orderBy,
      unreadOnly,
      pageNumber,
    }

    const { startDate, endDate } = dateRange;
    queryParams = startDate ? { ...queryParams, startDate } : { ...queryParams };
    queryParams = endDate ? { ...queryParams, endDate } : { ...queryParams };

    let selectedKeywords = keywords.items.filter(el => el.isSelected).map(el => el.title);
    let keyword = selectedKeywords.join('|');

    let selectedSources = sources.items.filter(el => el.isSelected).map(el => el.title);
    let source = selectedSources.length > 0 ? selectedSources[0] : "";
    
    queryParams = { ...queryParams, dateRangeLabel, dateRange, orderByLabel, orderBy, keyword, source };
    dispatch(updateFeedQueryParameters(queryParams));
    var filterQueryParams = { pageNumber: 1,orderBy, keyword, source }
    if(dateRange.startDate && dateRange.endDate){
      filterQueryParams = {...filterQueryParams,...dateRange}
    }
    if (content === 'saved') {
      dispatch(getUserSavedArticles(userEmail, {  ...filterQueryParams,unreadOnly: false }));
    } else {
      dispatch(getUserFeed(userEmail, { ...filterQueryParams, unreadOnly: unreadOnly }));
    }

    closeHandler();
  }

  return (
    <TopDrawer
      open={open}
      onClose={closeHandler}
      onOpen={openHandler}
    >


      <Box sx={{ padding: '5rem 1rem 1rem 1rem' }}>

        <Typography
          sx={{
            textDecoration: 'underline',
            color: JC_COLORS.JC_RED,
            fontFamily: 'Raleway', 
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
            fontWeight: '600',
            marginTop: "3px",
            marginBottom: "0.9rem",

          }}>Clear All
        </Typography>

        <CustomRadioArticleType
          orderByLabel={orderByLabel}
          changeArticleTypeHandler={(val) => setOrderByLabel(val)}
        />

        <Spacer height={"2.1rem"} />

        <Typography sx={{
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: 700,
          fontFamily: 'Raleway',
        }}>Date Range</Typography>

        <Spacer height={"1.1rem"} />

        <CustomRadioDateRange
          // dateRange={dateRange}
          dateRangeInput={dateRange}
          dateRangeLabel={dateRangeLabel}
          changeDateRangeLabel={(val) => setDateRangeLabel(val)}
          changeDateRangeHandler={(val) => setDateRange(val)}
          filterCount={filterCount}
        />

        <Spacer height={"4rem"} />
        <CenteredActionButton onClick={() => applySort()}>Sort Now</CenteredActionButton>
      </Box>
    </TopDrawer>
  )
}

export default SortDrawer; 