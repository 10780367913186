import React from 'react'; 
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { pxToRem } from '../../../utils';

const ResponsiveName = styled(Typography)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 600,
    //display: "-webkit-box",
    //WebkitLineClamp: "1",
    //WebkitBoxOrient: "vertical",
    overflow: "visible", 
    //textOverflow: "ellipsis", 
    textAlign: 'left', 
    [theme.breakpoints.up('md')]: {
        marginLeft: '15px',
        //minWidth: '198px', 
        //maxWidth: '268px',
        fontSize: pxToRem(16),
        lineHeight: pxToRem(22),
        
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: '8px',
        minWidth: '79px',
        fontSize: pxToRem(12),
        lineHeight: pxToRem(18),
    }
}));

const AvatarName = ({children, sx}) => {
    return (
        <ResponsiveName sx={{...sx}}>{children}</ResponsiveName>
    )
}

export default AvatarName