import { Box, CircularProgress, Grid, Hidden, InputAdornment, Link, Pagination, Paper, styled, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { BottomAppBar, CenteredContainer } from '../../../common/components';
import { NavbarDesktop, NavbarMobile } from '../../../common/containers';
import { AuthContext } from '../../../context/AuthContext';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { InlineBox, StyledTextField, StyledSearchButton } from '../../JCDetails/jc-content/report-content/ReportHelpers'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { PrivateJCIcon, PublicJCIcon } from '../../../assets/SVG';
import { fetchJournalClubs } from '../../../api';
import CustomDropdown from '../../JCDetails/components/CustomDropdown';
import CheckIcon from '@mui/icons-material/Check';
import { JC_CONTENT, JC_ROWS_PER_PAGE } from '../../../constants/constants';
import { setJCContent } from '../../../state/actions/journalclub';
import { useDispatch, useSelector } from 'react-redux';
import { UserDataContext } from '../../../context/UserDataContext';
import Error404Component from '../../../common/components/Error404';
import { getUserDetails } from '../../../state/actions/user';
import AllJournalClubsList from '../../../common/containers/AllJournalClubsList';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#262626',
    padding: theme.spacing(1),
    textAlign: 'left',
    minHeight: '100vh',
    margin: '0px 11px',
    padding: '24px 36px',
    [theme.breakpoints.down('md')]: {
        borderRadius: 'None',
        boxShadow: 'None',
        margin: '0px 0px',
        padding: '72px 12px 24px 12px',
    },
}));

function ManageJournalClubsPage() {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const [value, setValue] = useState("");
    const navigate = useNavigate();
    const [isFetchingJCList, setIsFetchingJCList] = useState(false);
    const [jcList, setJCList] = useState([]);
    const [filter, setFilter] = useState('All');
    const [approvalStatus, setApprovalStatus] = useState("Approved");
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfRows, setNumberOfRows] = useState(20);
    const [searchTerm, setSearchTerm] = useState("")

    //const { isLoading, data, isError, error, isFetching } = useContext(UserDataContext);
    const { user } = useSelector((state) => state);
    const { userDetails } = user;
    const dispatch = useDispatch();

    useEffect(() => {
        if (userEmail) {
            dispatch(getUserDetails(userEmail));
        } else {
            navigate('/feed');
        }
    }, []);

    useEffect(() => {
        if (Object.keys(userDetails).length != 0 && !userDetails?.superAdmin) {
            navigate('/feed');
        }
    }, [userDetails]);


    return (
        <>
            <Hidden mdDown>
                <NavbarDesktop />
                <Box
                    sx={{
                        margin: '88px auto auto auto',
                        maxWidth: '1440px',
                    }}
                >

                    <Item>
                        <Box mx={-1} mb={2}>
                            <Link
                                sx={{ cursor: 'pointer', textDecoration: 'None' }}
                                onClick={() => navigate(-1)}
                                className="report-nav-root_text"
                            >
                                <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
                                <Typography variant="BODY_COPY2_RALEWAY">Go Back</Typography>
                            </Link>
                        </Box>

                        <Box mb={4}>
                            <Typography
                                variant="H1_BITTER"
                                color="primary"
                                sx={{
                                    fontWeight: '700!important'
                                }}
                            >
                                Manage Journal Clubs
                            </Typography>
                        </Box>



                        <AllJournalClubsList
                            topic={''}
                        />

                       

                    </Item>
                </Box>
            </Hidden>

            <Hidden mdUp>
                <NavbarMobile title={'All Journal Clubs'} />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "95vh" }} p={2}>
                    <Typography variant="H2_RALEWAY"  >
                       Please use a desktop to access this feature
                    </Typography>
                </Box>
                {userEmail && <BottomAppBar />}
            </Hidden>
        </>
    );
}

export default ManageJournalClubsPage

const JournalClubItem = ({ jcName, isPublic, JCID, urlSlug, approved }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    return (
        <Box
            onClick={() => {
                dispatch(setJCContent(JC_CONTENT.MANAGE));
                navigate(`/jc${urlSlug}`);

            }}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <Box sx={{ display: 'inline-block', position: 'absolute', paddingTop: '2px' }}>
                {isPublic ?
                    <PublicJCIcon width="18px" height="14px" />
                    :
                    <PrivateJCIcon width="18px" height="14px" />
                }
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={3}>
                <Typography variant="H3_RALEWAY">
                    {jcName}
                </Typography>
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={3}>
                <Typography variant="H3_RALEWAY">
                    lorem ipsum
                </Typography>
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={3}>
                <Typography variant="H3_RALEWAY">
                    2
                </Typography>
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={3}>
                <Typography variant="H3_RALEWAY">
                    isCmeAccredited
                </Typography>
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={1}>
                {approved ? <CheckIcon /> : <></>}
            </Box>
        </Box>
    )


}