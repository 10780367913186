import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Card, Hidden, Box, Button, Typography, CircularProgress, Dialog} from "@mui/material";
import { PublicJCIcon, PrivateJCIcon } from '../../assets/SVG';
import { Row, Spacer, CenteredContainer } from '../../common/components';
import { JC_COLORS } from '../../styles/constants';
import {  resetJcDetails } from '../../state/actions/journalclub';
import { AuthContext } from '../../context/AuthContext';
import CourseDirectors from './containers/CourseDirectors';
import PartnersAffiliates from './containers/PartnersAffiliates';
import AccreditorsAndSupporters from './containers/AccreditorsAndSupporters';
import JCActions from './containers/JCActions';
import { pxToRem } from '../../utils';
import CMEAccreditingClub from '../../common/components/CMEAccreditingClub';
import { useTheme } from '@mui/material/styles';
import JCDetailsFullScreenMobile from './containers/JCDetailsFullScreenMobile';
import JCDetailsSingleTrayMobile from './containers/JCDetailsSingleTrayMobile';
import JCContentTitle from "./components/JCContentTitle"
import JoinJCButton from './components/JoinJCButton';
import ChangeJCRoleButton from './components/ChangeJCRoleButton';
import JCInviteButtons from './components/JCInviteButtons';
import { useErrorHandler } from "react-error-boundary";
import { INSTITUTION_STATUS, MEMBERSHIP_STATUS } from '../../constants/constants';
import SectionTitle from './components/SectionTitle';
import DialogContentCMEDisclosure from './jc-content/manage-content/DialogContentCMEDisclosure';
import { fetchFeedInBackground } from '../../state/actions/feed';

const MainCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: JC_COLORS.JC_WHITE,
    [theme.breakpoints.up('md')]: {
        maxHeight: "928px",
        padding: "30px 40px 16px 40px",
    },
    [theme.breakpoints.down('md')]: {
        padding: '0 16px 0 16px'
    }

}));

const JCDescription = styled(Typography)(({ theme }) => ({
    ...theme.typography.H3_RALEWAY,
    fontWeight: 600,
    color: JC_COLORS.JC_ICON,
    textAlign: 'left',
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: "hidden",
    [theme.breakpoints.up('md')]: {
        lineHeight: '1rem',
    },
    [theme.breakpoints.down('md')]: {
        lineHeight: '0.8rem',
    }

}));

const PublicPrivateStatusText = styled(Typography)(({ theme }) => ({
    ...theme.typography.H3_RALEWAY,
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
        color: JC_COLORS.JC_BLACK,
    },
    [theme.breakpoints.down('md')]: {
        color: JC_COLORS.JC_ICON,

    }
}));

const ContributorsReadersText = styled(Typography)(({ theme }) => ({
    ...theme.typography.H3_RALEWAY,
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
        color: JC_COLORS.JC_BLACK,
    },
    [theme.breakpoints.down('md')]: {
        color: JC_COLORS.JC_ICON,
    }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: "25px",
    },
    '& .MuiDialogActions-root': {
        padding: "25px",
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialog-paper': {
            width: '90%',
            maxWidth: '100%',
            height: '80%',
            maxHeight: '100%',
            borderRadius: '0px',
            margin: 0,
        }
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDialog-paper': {
            borderRadius: '18px',
            width: '90vw',
            minWidth: '900px'
        }
    },
}));

export const JCDetails = () => {
    const theme = useTheme();
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const { isAuthenticated } = auth;
    const location = useLocation();
    const urlSlug = location.pathname.replace(/\/jc\//, '');
    const { journalclub,feed } = useSelector((state) => state)
    const {
        isFetchingDetails,
        jcDetails,
        jcDetailsPublicData,
        jcContent,
        jcUserDetails,
        error
    } = journalclub;
    const { JCID, acceptedCmeDisclosure, cmeDownloadUrl, isCmeAccrediting, membershipStatus } = jcDetails;
    const {  articleList } = feed;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleError = useErrorHandler();

    const [courseDirectorsList, setCourseDirectorsList] = useState([]);
    const [accreditorsAndSupportersList, setAccreditorsAndSupportersList] = useState([]);
    const [partnersAndAffiliatesList, setPartnersAndAffiliatesList] = useState([]);
    const [partnersList, setPartnersList] = useState([]);
    const [affiliatesList, setAffiliatesList] = useState([]);

    const [showCMEDisclosurePDF, setShowCMEDisclosurePDF] = useState(false);

    useEffect(() => {
        if (userEmail && isFetchingDetails === false) {
            setCourseDirectorsList(parseCourseDirectors(jcDetails));
            setPartnersList(parsePartners(jcDetails));
            setAffiliatesList(parseAffiliates(jcDetails));
            setAccreditorsAndSupportersList(jcDetails?.institutions?.filter(obj => (
                obj?.status === INSTITUTION_STATUS.ACCREDITOR ||
                obj?.status === INSTITUTION_STATUS.SUPPORTER
            )) || []);
            setPartnersAndAffiliatesList(jcDetails?.institutions?.filter(obj => (
                obj?.status === INSTITUTION_STATUS.PARTNER ||
                obj?.status === INSTITUTION_STATUS.AFFILIATE
            )) || [])
        }

    }, [jcDetails]);

    useEffect(() => {
        if (
            (acceptedCmeDisclosure === false) &&
            (membershipStatus === MEMBERSHIP_STATUS.JOINED)
        ) {
            setShowCMEDisclosurePDF(true)
        } else {
            setShowCMEDisclosurePDF(false);
        }
    }, [acceptedCmeDisclosure, urlSlug, membershipStatus]);

    useEffect(() => {
        // dispatch(fetchFeedInBackground(userEmail,feed.articleList))
        return () => {
            dispatch(resetJcDetails())
        }
    }, []);

    return (
        <>
            <Hidden mdDown>
                <BootstrapDialog
                    open={(showCMEDisclosurePDF && cmeDownloadUrl !== null)}
                    onClose={
                        () => {
                            if (acceptedCmeDisclosure === false) {
                                return
                            } else {
                                setShowCMEDisclosurePDF(false)
                            }

                        }
                    }
                >
                    <DialogContentCMEDisclosure
                        JCID={JCID}
                        urlSlug={urlSlug}
                        userEmail={userEmail}
                        cmeDisclosurePdfUrl={cmeDownloadUrl}
                        acceptedCMEDisclosure={acceptedCmeDisclosure}
                        closeIconHandler={() => setShowCMEDisclosurePDF(false)}
                    />
                </BootstrapDialog>
                <MainCard>
                    {/* {journalclub.error && <Typography>Error in getting data {journalclub.error?.data}</Typography>} */}
                    {isFetchingDetails && <CenteredContainer><CircularProgress /></CenteredContainer>}
                    {isFetchingDetails === false && (
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        maxWidth: '60%',
                                    }}
                                >
                                    <JCContentTitle title={jcDetails?.name}></JCContentTitle>
                                    <Spacer height={"8px"} />
                                    <JCDescription>{jcDetails?.description}</JCDescription>
                                </Box>

                                {
                                    jcDetails?.isCmeAccrediting && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-end',
                                                alignItems: 'flex-end'

                                            }}
                                        >
                                            <CMEAccreditingClub />
                                            {
                                                (jcDetails?.membershipStatus === MEMBERSHIP_STATUS.JOINED) ? (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-end',
                                                            alignItems: 'center',
                                                            marginTop: '8px',
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="H3_RALEWAY"
                                                            sx={{
                                                                fontWeight: 600,
                                                                color: JC_COLORS.JC_ICON,
                                                                marginRight: '12px'
                                                            }}
                                                        >
                                                            Your CME / CE Credits:
                                                        </Typography>
                                                        <PublicPrivateStatusText>
                                                            {parseFloat(jcUserDetails?.userCmeCredits ?? 0).toFixed(1).toString()}
                                                        </PublicPrivateStatusText>
                                                    </Box>

                                                ) : <></>
                                            }

                                        </Box>
                                    )
                                }


                            </Box>



                            <Row sx={{ justifyContent: "space-between", alignItems: "center", marginTop: '12px' }}>
                                <Row sx={{ justifyContent: 'space-between', alignItems: "center", flexWrap: 'wrap', maxWidth: "60%" }}>
                                    <Row sx={{ alignItems: 'center' }}>
                                        {
                                            jcDetails?.isPublic && (
                                                <PublicJCIcon width={'20px'} height={'20px'} />
                                            )
                                        }
                                        {
                                            jcDetails?.isPublic === false && (
                                                <PrivateJCIcon width={'20px'} height={'20px'} />
                                            )
                                        }
                                        <PublicPrivateStatusText sx={{ marginLeft: "7px" }}>
                                            {jcDetails?.isPublic && "Public Club"}
                                            {jcDetails?.isPublic === false && "Private Club"}
                                        </PublicPrivateStatusText>

                                    </Row>

                                    <ContributorsReadersText
                                        sx={{
                                            marginTop: "0px",
                                            marginBottom: "0px",
                                            marginLeft: { md: "16px", lg: "24px", xl: "24px" },
                                            marginRight: { md: "24px", lg: "24px", xl: "24px" }
                                        }}>
                                        {parseContributorsAndReaders(jcDetails)}
                                    </ContributorsReadersText>
                                    {
                                        (jcDetails?.membershipStatus === MEMBERSHIP_STATUS.JOINED) ? (
                                            <Typography
                                                variant="BODY_COPY2_RALEWAY"
                                                sx={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                    width: 'fit-content',
                                                    display: `${(cmeDownloadUrl === null || isCmeAccrediting === false) ? 'none' : 'block'}`,
                                                    marginTop: { md: "12px", lg: "0px", xl: "0px" }
                                                }}
                                                onClick={() => setShowCMEDisclosurePDF(true)}
                                            >
                                                Read Disclosure
                                            </Typography>

                                        ) : <></>
                                    }


                                </Row>
                                {
                                    jcDetails?.membershipStatus === MEMBERSHIP_STATUS.INVITED && (
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                            <Typography variant="H3_RALEWAY" sx={{ fontWeight: 600, color: JC_COLORS.JC_ICON, marginBottom: '16px' }}>You are invited to become a {jcUserDetails.role}</Typography>
                                            <JCInviteButtons JCID={JCID} userEmail={userEmail} />
                                        </Box>
                                    )
                                }
                                {
                                    (
                                        (userEmail === null || userEmail === undefined) ||
                                        (isAuthenticated === false)
                                    ) && (
                                        <ChangeJCRoleButton
                                            JCID={""}
                                            userEmail={userEmail}
                                            currentRole={""}
                                            membershipStatus={""}
                                        />
                                    )
                                }
                                {
                                    (
                                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.JOINED ||
                                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.REQUESTED ||
                                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED
                                    ) && (
                                        <ChangeJCRoleButton
                                            JCID={JCID}
                                            userEmail={userEmail}
                                            currentRole={jcUserDetails?.role}
                                            membershipStatus={jcDetails?.membershipStatus}
                                        />
                                    )
                                }
                                
                                {
                                    (
                                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.ADD ||
                                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.LEFT
                                    ) && (
                                        <JoinJCButton
                                            JCID={JCID}
                                            userEmail={userEmail}
                                            journalClubName={jcDetails?.name}
                                            isJCPublic={jcDetails?.isPublic}

                                        />
                                    )
                                }
                            </Row>




                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    marginTop: '18px',
                                    marginBottom: partnersAndAffiliatesList?.length === 0 ? '48px' : '4px',
                                }}>
                                <Box sx={{ width: partnersAndAffiliatesList?.length === 0 ? '100%' : '60%' }}>
                                    {
                                        courseDirectorsList?.length > 0 && (
                                            <Box
                                                sx={{
                                                    borderTop: '2px solid #E1E1E1',
                                                    padding: '19px 0px',
                                                    //borderRight: partnersAndAffiliatesList.length === 0 ? 'none' : '2px solid #E1E1E1',
                                                }}>
                                                <CourseDirectors
                                                    courseDirectorsList={courseDirectorsList}
                                                />
                                            </Box>
                                        )
                                    }




                                </Box>

                                <Box sx={{ width: partnersAndAffiliatesList?.length === 0 ? '0%' : '40%' }}>
                                    {
                                        (partnersAndAffiliatesList?.length > 0) && (
                                            <Box
                                                sx={{
                                                    borderTop: '2px solid #E1E1E1',
                                                    paddingTop: '19px',
                                                    paddingLeft: '45px',
                                                    borderLeft: '2px solid #E1E1E1'
                                                }}>
                                                <PartnersAffiliates
                                                    partnersAffiliatesList={partnersAndAffiliatesList}
                                                    showFiveElements={accreditorsAndSupportersList !== null && accreditorsAndSupportersList?.length > 0}
                                                />
                                            </Box>

                                        )
                                    }
                                </Box>
                            </Box>



                            {
                                accreditorsAndSupportersList?.length > 0 && (
                                    <>
                                        <Box
                                            sx={{
                                                borderTop: '2px solid #E1E1E1',
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: 'flex-start',
                                                justifyContent: 'space-between',
                                                padding: '19px 0px 0px 0px',
                                            }}
                                        >
                                            <SectionTitle>
                                                Accreditors/Supporters
                                            </SectionTitle>

                                        </Box>
                                        <AccreditorsAndSupporters
                                            accreditorsAndSupportersList={accreditorsAndSupportersList}
                                            hideReadDisclosureButton={false}
                                        />
                                    </>

                                )
                            }
                            {
                                userEmail && (
                                    <Box sx={{ marginTop: "12px" }}>
                                        <JCActions userType={jcUserDetails?.role} />
                                    </Box>
                                )
                            }
                        </Box>
                    )}
                </MainCard>
            </Hidden>
            <Hidden mdUp>
                {/* Non jc Member view */}
                {
                    (
                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.ADD ||
                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.INVITED ||
                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.REQUESTED ||
                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED ||
                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.LEFT ||
                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.REMOVED
                    ) && (
                        <JCDetailsFullScreenMobile
                            appTitle={jcDetails?.name}
                            jcDetails={jcDetails}
                        >
                            <Box sx={{ padding: "64px 16px 16px 16px", bgcolor: JC_COLORS.JC_WHITE }}>
                                <Row sx={{ justifyContent: 'flex-start', borderBottom: '1px solid #848484', paddingBottom: '12px' }}>
                                    <Box sx={{ maxWidth: '70%', wordWrap: 'break-word' }}>
                                        <JCDescription>
                                            {jcDetails?.description}
                                        </JCDescription>
                                    </Box>
                                </Row>

                                <Box sx={{ margin: '18px 0px 11px 0px', width: '65%' }}>
                                    {jcDetails?.isCmeAccrediting && <CMEAccreditingClub />}
                                </Box>

                                <Row sx={{ justifyContent: 'space-between', margin: '15px 0px' }}>
                                    <Row sx={{ alignItems: 'center' }}>
                                        {
                                            jcDetails?.isPublic && (
                                                <PublicJCIcon width={'20px'} height={'20px'} />
                                            )
                                        }
                                        {
                                            jcDetails?.isPublic === false && (
                                                <PrivateJCIcon width={'20px'} height={'20px'} />
                                            )
                                        }
                                        <PublicPrivateStatusText sx={{ marginLeft: "7px" }}>
                                            {
                                                jcDetails?.isPublic ? "Public Club" : "Private Club"
                                            }

                                        </PublicPrivateStatusText>
                                    </Row>
                                    <ContributorsReadersText sx={{ ml: 4 }}>
                                        {parseContributorsAndReaders(jcDetails)}
                                    </ContributorsReadersText>
                                </Row>

                                <Row sx={{ justifyContent: 'flex-start' }}>
                                    {
                                        (
                                            jcDetails?.membershipStatus === MEMBERSHIP_STATUS.JOINED ||
                                            jcDetails?.membershipStatus === MEMBERSHIP_STATUS.REQUESTED ||
                                            jcDetails?.membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED
                                        ) && (
                                            <ChangeJCRoleButton
                                                JCID={JCID}
                                                userEmail={userEmail}
                                                currentRole={jcUserDetails?.role}
                                                membershipStatus={jcDetails?.membershipStatus}
                                            />
                                        )
                                    }
                                    {
                                        (
                                            jcDetails?.membershipStatus === MEMBERSHIP_STATUS.ADD ||
                                            jcDetails?.membershipStatus === MEMBERSHIP_STATUS.LEFT
                                        ) && (
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                <JoinJCButton
                                                    JCID={JCID}
                                                    userEmail={userEmail}
                                                    journalClubName={jcDetails?.name}
                                                    isJCPublic={jcDetails?.isPublic}
                                                    sx={{ marginTop: "17px" }}
                                                />
                                            </Box>

                                        )
                                    }
                                    {
                                        (jcDetails?.membershipStatus === MEMBERSHIP_STATUS.INVITED) && (
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                <Typography variant="H3_RALEWAY" sx={{ fontWeight: 600, color: JC_COLORS.JC_ICON, margin: '16px 0px' }}>You are invited to become a {jcUserDetails.role}</Typography>
                                                <JCInviteButtons JCID={JCID} userEmail={userEmail} />
                                            </Box>

                                        )
                                    }

                                </Row>



                                <Box sx={{ marginTop: '16px' }}>
                                    {
                                        courseDirectorsList?.length > 0 && (
                                            <Box sx={{ borderTop: '1px solid #848484', padding: '12px 0px', marginBottom: '12px' }}>
                                                <CourseDirectors courseDirectorsList={[...courseDirectorsList]} />
                                            </Box>
                                        )
                                    }
                                    {
                                        partnersAndAffiliatesList?.length > 0 && (
                                            <Box sx={{ borderTop: '1px solid #848484', padding: '12px 0px', marginBottom: '12px' }}>
                                                <PartnersAffiliates partnersAffiliatesList={[...partnersAndAffiliatesList]} />
                                            </Box>
                                        )
                                    }
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start',
                                            flexWrap: 'wrap',
                                            width: "100%",
                                        }}
                                    >
                                        <SectionTitle>
                                            Accreditors/Supporters
                                        </SectionTitle>

                                    </Box>
                                    {
                                        accreditorsAndSupportersList?.length > 0 && (
                                            <Box sx={{ borderTop: '1px solid #848484', padding: '12px 0px', marginBottom: '12px' }}>
                                                <AccreditorsAndSupporters
                                                    accreditorsAndSupportersList={[...accreditorsAndSupportersList]}
                                                    hideReadDisclosureButton={true}
                                                />
                                            </Box>
                                        )
                                    }


                                </Box>
                            </Box>
                        </JCDetailsFullScreenMobile>
                    )

                }
                {/* Member view for mobile */}
                {
                    (
                        jcDetails?.membershipStatus === MEMBERSHIP_STATUS.JOINED
                    ) && (
                        <JCDetailsSingleTrayMobile />

                    )
                }


            </Hidden>
        </>

    )
}

function parseContributorsAndReaders(jcDetails) {
    let contributorsText;
    let readersText;
    if (
        (jcDetails?.contributors === undefined) ||
        (jcDetails?.contributors === null)
    ) {
        contributorsText = "0 Contributer"
    } else {
        contributorsText = jcDetails.contributors === 1 ? `1 Contributor` : `${jcDetails.contributors} Contributors`;
    }

    if (
        (jcDetails.readers === undefined) ||
        (jcDetails?.readers === null)
    ) {
        readersText = "0 Reader"
    } else {
        readersText = jcDetails.readers === 1 ? `1 Reader` : `${jcDetails.readers} Readers`
    }

    return contributorsText === "" ? readersText : `${contributorsText} | ${readersText}`;

}

function parseCourseDirectors(jcDetails) {
    let { adminMembers } = jcDetails;
    return adminMembers ? [...adminMembers.filter(obj => obj.role === 'Course Director')] : [];
}

function parseCmeSupporters(jcDetails) {
    let { institutions } = jcDetails;
    return institutions ? institutions.filter(obj => obj.cmeSupporter) : [];
}

function parsePartners(jcDetails) {
    let { institutions } = jcDetails;
    return institutions ? institutions.filter(obj => obj.status === INSTITUTION_STATUS.PARTNER || obj.status === 'InstitutionStatus.PARTNER') : [];
}

function parseAffiliates(jcDetails) {
    let { institutions } = jcDetails;
    return institutions ? institutions.filter(obj => obj.status === INSTITUTION_STATUS.AFFILIATE || obj.status === 'InstitutionStatus.AFFILIATE') : []
}
