import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Button,
    Grid,
    InputAdornment,
    Divider,
    Link,
    styled,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';
import CustomDropdownDateRange from '../../../Feed/components/CustomDropdownDateRange';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
    InvitedIcon,
    InviteRejectedIcon,
    JoinedIcon,
    LeftIcon,
    RequestedIcon,
    RequestRejectedIcon
} from '../../../../assets'
import { useSelector, useDispatch } from "react-redux";
import { fetchMembershipReports } from '../../../../api';
import { JC_COLORS } from '../../../../styles/constants';
import { REPORTS_NAV, REPORT_ROWS_PER_PAGE } from '../../../../constants/constants';
import { updateReportNav, updateMemberId, updateCurrentTab } from '../../../../state/actions/jcreports';
import { CenterFlexBox, UserCell, InstitutionCell, InlineBox, SummaryBox, StyledTextField, StyledSearchButton, DownloadReportButton, EnhancedTableHead, MembershipHeadCells } from './ReportHelpers';
import CustomDropdown from '../../../JCDetails/components/CustomDropdown';
import ReportTable from './ReportTable';


const MembershipReport = (props) => {
    const { reportRootFilter, rootDateRange } = useSelector((state) => state.jcreports)
    const [dateRangeLabel, setDateRangeLabel] = useState(reportRootFilter)
    const [userRole, setUserRole] = useState("All Users")
    const [value, setValue] = useState('')
    const [queryParameters, setQueryParameters] = useState(null)
    const [membershipData, setMembershipData] = useState({})
    const [userStatus, setUserStatus] = useState("All Status")
    const [dateRange, setDateRange] = useState(rootDateRange)
    const [SearchText, setSearchText] = useState("")
    const dispatch = useDispatch()
    const { jcDetails } = useSelector((state) => state.journalclub)

    // report table states
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [isLoading, setIsLoading] = useState(true)

    let currentLoginUser = localStorage.getItem("jcid");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleRowClick = (event) => {
    };

    const handleChangePage = (event, changedValue) => {
        setPage(changedValue);
    };
    const handleMemberClick = (memberData) => {
        dispatch(updateCurrentTab(REPORTS_NAV.MEMBERSHIP))
        dispatch(updateMemberId(memberData))
        dispatch(updateReportNav("Member"))
    }

    const emptyRows = 0;

    const handleSearch = () => {
        setIsLoading(true)
        setSearchText(value)
        var queryParams = {
            authUserEmail: props.userEmail,
            numRecordsPerPage: REPORT_ROWS_PER_PAGE,
            pageNumber: 1,
            sort: order,
            orderBy: orderBy,
            term: value
        }
        if (userStatus !== "All Status") {
            queryParams = { ...queryParams, status: userStatus }
        }
        if (userRole !== "All Users") {
            queryParams = { ...queryParams, role: userRole.slice(0, -1) }
        }
        setQueryParameters(queryParams)
        fetchData(queryParams)
    }
    const resetPageNumber = (val, callback) => {
        setPage(1)
        callback(val)
    }

    useEffect(() => {
        setIsLoading(true)
        var queryParams = {
            authUserEmail: props.userEmail,
            numRecordsPerPage: REPORT_ROWS_PER_PAGE,
            pageNumber: page,
            sort: order,
            orderBy: orderBy,
        }
        if(SearchText !== ""){
            queryParams = { ...queryParams, term: SearchText }
        }
        // console.log(userStatus,":: userStatus")
        if (userStatus !== "All Status") {
            queryParams = { ...queryParams, status: userStatus }
        }
        if (userRole !== "All Users") {
            queryParams = { ...queryParams, role: userRole.slice(0, -1) }
        }
        console.log(queryParams, "::queryParams")
        setQueryParameters(queryParams)
        fetchData(queryParams)
    }, [dateRange, userStatus, page, userRole, order, orderBy, SearchText])

    const fetchData = (queryParams) => {
        if (dateRange.startDate && dateRange.endDate) {
            queryParams = { ...queryParams, ...dateRange }
            setQueryParameters(queryParams)
        }
        fetchMembershipReports(jcDetails?.JCID, queryParams).then(response => {
            if (response?.data) {
                setMembershipData(response.data)
                setIsLoading(false)
            }
        }).catch(error => {
            console.error(error)
        })
    }

    return (
        <React.Fragment>
            <Box sx={{ textAlign: 'left' }} my={-1} mx={-0.8}>
                <Link sx={{ cursor: 'pointer', textDecoration: 'None' }} onClick={() => dispatch(updateReportNav("Reports"))} className='report-nav-root_text'>
                    <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
                    <Typography variant="BODY_COPY2_RALEWAY">
                        {REPORTS_NAV.ROOT}
                    </Typography>
                </Link>
            </Box>


            <Box sx={{ textAlign: 'left' }} mt={2} mb={2}>
                <InlineBox>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant="H1_BITTER" color="primary" sx={{ fontWeight: '700 !important' }}>
                            {REPORTS_NAV.MEMBERSHIP}
                        </Typography>
                    </Box>
                </InlineBox>
                <InlineBox sx={{ float: 'right' }}>
                    <CustomDropdownDateRange isHeading={false} isWhite={true}
                        dateRangeLabel={dateRangeLabel}
                        changeDateRangeLabel={(value) => setDateRangeLabel(value)}
                        changeDateRangeHandler={(value) => resetPageNumber(value, setDateRange)}
                    />
                </InlineBox>
            </Box>
            {/* Membership Summary */}
            {membershipData &&
                <Grid container spacing={{ xs: 1.5 }} sx={{ backgroundColor: JC_COLORS.JC_RED, borderRadius: '4px', padding: '24px', width: '100%!important', margin: '0px!important' }}>
                    <Grid item xs={12} sx={{ paddingTop: '0px!important' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={membershipData.numAllJoinedUsers} title="Joined"> <JoinedIcon width="28px" height="28px" /> </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={membershipData.numAllInvitedUsers} title="Invited"> <InvitedIcon width="28px" height="28px" />  </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={membershipData.numAllRequestedUsers} title="Requested"> <RequestedIcon width="28px" height="28px" />  </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={membershipData.numAllLeftUsers} title="Left"> <LeftIcon width="28px" height="28px" />  </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={membershipData.numAllInviteRejectedUsers} title="Invite Rejected"> <InviteRejectedIcon width="28px" height="28px" />  </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={membershipData.numAllRequestRejectedUsers} title="Request Rejected"> <RequestRejectedIcon width="28px" height="28px" />  </SummaryBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {/*Membership Summary Ends */}
            <Box mt={3} sx={{ textAlign: 'left' }}>
                {membershipData &&
                    <Typography variant="BODY_ARTICLE_RALEWAY" sx={{ fontWeight: '700!important', marginRight: '12px' }}> {membershipData.numberOfRow !== 0 ? membershipData.numberOfRow && (membershipData.numberOfRow !== 1 ? membershipData.numberOfRow + " Members Found" : membershipData.numberOfRow + " Member Found") : "No Result Found"}</Typography>
                }
                {isLoading &&
                    <CircularProgress color='primary' sx={{ width: '20px!important', height: '20px!important' }} />
                }
            </Box>
            <Box sx={{ textAlign: 'left' }} mb={1}>
                <InlineBox mt={2}>
                    <StyledTextField
                        value={value}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                        placeholder={"Search Member"}
                        autoComplete='off'
                        inputProps={{
                            style: {
                                fontFamily: 'Raleway',
                                fontWeight: 600,
                            }
                        }}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                            endAdornment: (
                                <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                                    <CloseIcon style={{ display: `${value === "" ? 'none' : 'block'}` }} onClick={() => { setSearchText(""); setValue("") }} />
                                </InputAdornment>),
                        }}
                        onChange={(e) => { setValue(e.target.value ? e.target.value : ""); }}
                    ></StyledTextField>
                    <StyledSearchButton variant="outlined" onClick={() => handleSearch()} sx={{ marginLeft: '11px' }}>
                        Search
                    </StyledSearchButton>
                </InlineBox>
                <InlineBox sx={{ float: 'right' }} mt={2}>
                    <InlineBox ml={1}>
                        <CustomDropdown
                            selectedLabel={userRole}
                            listOfLabels={["All Users", "Course Directors", "Administrators", "Contributors", "Readers"]}
                            changeLabel={(val) => resetPageNumber(val, setUserRole)}
                        />
                    </InlineBox>
                    <InlineBox ml={1}>
                        <CustomDropdown
                            selectedLabel={userStatus}
                            listOfLabels={["All Status", "Joined", "Invited", "Requested", "Left","Removed", "Invite Rejected", "Request Rejected"]}
                            changeLabel={(val) => resetPageNumber(val, setUserStatus)}
                        />
                    </InlineBox>
                    <DownloadReportButton JCID={jcDetails.JCID} parameter={"members"} queryParameters={queryParameters} currentLoginUser={currentLoginUser} downloadName={"Membership_Activity"} dateRange={dateRange} />
                </InlineBox>
            </Box>
            {/*Report Table */}
            {membershipData && membershipData.members &&
                <Box mt={3} mb={3}>
                    <Box sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none",
                                        fontFamily: 'Raleway',
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        borderRadius: '4px'
                                    },
                                    minWidth: 750
                                }}
                                aria-labelledby="tableTitle"
                                size="medium"
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={MembershipHeadCells}
                                />

                                <TableBody sx={{ position: 'relative' }}>
                                    <Backdrop
                                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute', backgroundColor: 'transparent' }}
                                        open={isLoading}
                                    >
                                        <CircularProgress color='primary' />
                                    </Backdrop>
                                    {membershipData.members.map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <React.Fragment>
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleRowClick(event)}
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "#FFF7F6!important"
                                                        },
                                                        backgroundColor: "#F3F3F3!important"
                                                    }}
                                                    className={`${row.status !== "Joined" && "disableTableRow"}`}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ fontFamily: 'Raleway!important' }}
                                                    >
                                                        <UserCell name={row.term} onClick={() => handleMemberClick(row)} />
                                                    </TableCell>
                                                    <TableCell align="left"> <InstitutionCell name={row.institution} /></TableCell>
                                                    <TableCell align="left"><Typography variant="H3_RALEWAY" color="#262626">{row.role}</Typography> </TableCell>
                                                    <TableCell align="left"><Typography variant="H3_RALEWAY" color="#262626" sx={row.status !== "Joined" && { fontStyle: "italic" }}>{row.status}</Typography></TableCell>
                                                </TableRow>
                                                <Box sx={{ minHeight: '12px' }}></Box>
                                            </React.Fragment>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={3} sx={{ marginLeft: 'calc( 50% - 80px)' }}>
                            <Pagination count={Math.ceil(membershipData.numberOfRow / REPORT_ROWS_PER_PAGE)} color="primary" page={page} onChange={handleChangePage} />
                        </Box>
                    </Box>
                </Box>
            }
        </React.Fragment>
    )
}

export default MembershipReport
