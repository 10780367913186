import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Hidden, Box, Typography, Grid, IconButton, AppBar, Toolbar, CircularProgress, styled, Dialog, DialogTitle, DialogContent, DialogActions, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomDropdownDateRange from '../../Feed/components/CustomDropdownDateRange';
import CustomDropdownArticleType from '../../Feed/components/CustomDropdownArticleType';
import JCContentTitle from '../components/JCContentTitle';
import { ArticleCardView, ArticleCardViewSkeleton, JC_Avatar, TopDrawer, Spacer } from '../../../common/components';
import CenteredActionButton from '../../../common/components/CenteredActionButton';

import { AuthContext } from '../../../context/AuthContext';
import ToggleButton from '../components/ToggleButton';

import { logout } from '../../../utils';
import axios from 'axios';
import * as api from '../../../api';
import { JC_COLORS } from '../../../styles/constants';
import { MEMBERSHIP_STATUS, OPTIONS_SORT_ARTICLES_BY_TYPE } from "../../../constants/constants";
import { SortIconFilled } from '../../../assets/SVG';
import CustomRadioDateRange from '../../Feed/components/CustomRadioDateRange';
import CustomRadioArticleType from '../../Feed/components/CustomRadioArticleType';
import JCInviteButtons from '../components/JCInviteButtons';
import JoinJCButton from '../components/JoinJCButton';
import connectionInstance from '../../../utils/connection';



const StyledGrid = styled(Grid)(({ theme }) => ({
    marginBottom: '16px',

    [theme.breakpoints.up('md')]: {
        width: "100%",
        padding: '0px 0px 16px 0px',
        margin: '0 auto',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0px 0px 54px 0px',

    }
}));

const CenteredContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        margin: '0 auto',
        width: '600px',
        margin: "70px auto",
    },
    [theme.breakpoints.down('md')]: {
        height: '100vh',
        margin: '0 auto',
        ...theme.typography.BODY_COPY1_RALEWAY_MOBILE,
    }
}));

const StyledText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H1_BITTER,
    },
    [theme.breakpoints.down('md')]: {
        ...theme.typography.H1_BITTER,
    }

}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        '& .MuiDialogContent-root': {
            padding: "25px",
        },
        '& .MuiDialogActions-root': {
            padding: "25px",
        },
        '& .MuiDialog-paper': {
            width: '70%',
            //height: '68%'
        }
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialog-paper': {
            width: '80%',
            height: 'auto',
        }
    },

}));

const PublishedTab = ({ JCID, urlSlug }) => {
    const theme = useTheme();
    const { journalclub, feed } = useSelector((state) => state);
    const { jcDetails, jcUserDetails, jcContent, jcList } = journalclub;

    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const navigate = useNavigate();

    const [loadingArticles, setLoadingArticles] = useState(false);
    const [articleList, setArticleList] = useState([]);
    const [numberOfUnreadArticles, setNumberOfUnreadArticles] = useState(0);
    const [hasMoreArticles, setHasMoreArticles] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const observer = useRef();

    const [unreadOnly, setUnreadOnly] = useState(false);
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const [dateRangeLabel, setDateRangeLabel] = useState('All Time');
    const [orderByLabel, setOrderByLabel] = useState("Most Recent");
    const [sortDrawerOpened, setSortDrawerOpened] = useState(false);

    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [selectedPMID, setSelectedPMID] = useState("");
    const encodeGetParams = params => Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
    useEffect(() => {
        let cancel;
        setLoadingArticles(true);
        setPageNumber(1);
        setArticleList([]);
        try {
            var filterQueryParams = {
                unreadOnly,
                orderBy: OPTIONS_SORT_ARTICLES_BY_TYPE[orderByLabel],
                pageNumber: pageNumber,
            }
            if (dateRange.startDate && dateRange.endDate) {
                filterQueryParams = { ...filterQueryParams, ...dateRange }
            }
            connectionInstance.get(
                `${process.env.REACT_APP_API_URL}/jc/${JCID}/${userEmail}/articles/published?${encodeGetParams(filterQueryParams)}`,
                encodeURI({
                    cancelToken: new axios.CancelToken(c => cancel = c),
                })
            ).then(res => {
                const { numberOfUnreadArticles, next } = res.data;
                setArticleList([...new Set([...res.data.articleList])]);
                setNumberOfUnreadArticles(numberOfUnreadArticles);
                setHasMoreArticles(next ? true : false);
                setLoadingArticles(false)
            }).catch(error => {
                console.log(error);
                if (error?.response && error?.response?.status === 401) {
                    console.log(error)
                }
                setArticleList([]);
                setLoadingArticles(false);
            });

        } catch (error) {
            setArticleList([]);
            setLoadingArticles(false);
        }


    }, [JCID, unreadOnly, orderByLabel, dateRange, dateRangeLabel]);

    useEffect(() => {
        let cancel;
        setLoadingArticles(true);

        try {
            var filterQueryParams = {
                unreadOnly,
                orderBy: OPTIONS_SORT_ARTICLES_BY_TYPE[orderByLabel],
                pageNumber: pageNumber
            }
            if (dateRange.startDate && dateRange.endDate) {
                filterQueryParams = { ...filterQueryParams, ...dateRange }
            }
            connectionInstance.get(
                `${process.env.REACT_APP_API_URL}/jc/${JCID}/${userEmail}/articles/published?${encodeGetParams(filterQueryParams)}`,
                encodeURI({
                    cancelToken: new axios.CancelToken(c => cancel = c),
                })
            ).then(res => {
                const { numberOfUnreadArticles, next } = res.data;
                setArticleList([...new Set([...articleList, ...res.data.articleList])]);
                setNumberOfUnreadArticles(numberOfUnreadArticles);
                setHasMoreArticles(next ? true : false);
                setLoadingArticles(false)
            }).catch(error => {
                console.log(error);
                if (error?.response && error?.response?.status === 401) {
                    console.log(error)
                }
                setArticleList([]);
                setLoadingArticles(false);
            });

        } catch (error) {
            setArticleList([]);
            setLoadingArticles(false);
        }


    }, [pageNumber]);

    const lastArticleElementRef = useCallback(node => {
        if (loadingArticles) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreArticles && loadingArticles === false) {
                setPageNumber((pageNumber) => pageNumber + 1);
                if (loadingArticles) return

            }
        });
        if (node) observer.current.observe(node)
    }, [loadingArticles]);



    return (
        <>
            {
                (jcDetails.isPublic === false && jcDetails.membershipStatus !== 'Joined') && (

                    <Typography
                        variant="H2_RALEWAY"
                        sx={{
                            fontWeight: 600,
                            margin: "34px auto",
                        }}
                    >
                        {jcDetails.name} is a private club. You need to be a member of this club to view published articles.
                    </Typography>

                )
            }
            {
                (
                    (jcDetails.isPublic === false && jcDetails.membershipStatus === MEMBERSHIP_STATUS.JOINED) ||
                    (
                        jcDetails.isPublic && (
                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITED ||
                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.ADD ||
                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.JOINED ||
                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.REQUESTED ||
                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED
                        )
                    )
                ) && (
                    <>
                        <Hidden mdDown>
                            <>
                                {loadingArticles && <Box sx={{ margin: '0 auto' }}><CircularProgress /></Box>}
                                {
                                    loadingArticles === false && (
                                        <>
                                            <JCContentTitle title={'Published Articles'} />
                                            <Box sx={{
                                                margin: '16px 0px 0px 0px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                padding: "0",
                                            }}>
                                                <CustomDropdownDateRange
                                                    dateRangeLabel={dateRangeLabel}
                                                    changeDateRangeLabel={(value) => setDateRangeLabel(value)}
                                                    changeDateRangeHandler={(value) => setDateRange(value)}
                                                />
                                                <CustomDropdownArticleType
                                                    orderByLabel={orderByLabel}
                                                    changeOrderBy={(value) => setOrderByLabel(value)}
                                                />

                                            </Box>
                                            <Box sx={{ margin: '28px 0px', display: 'flex', flexDirecion: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Typography variant="BODY_COPY1_RALEWAY">{numberOfUnreadArticles} Unread Articles </Typography>
                                                    <ToggleButton
                                                        leftSelected={unreadOnly === false}
                                                        toggleButtonHandler={() => setUnreadOnly(!unreadOnly)}
                                                    />
                                                </Box>
                                            </Box>

                                        </>
                                    )
                                }

                            </>
                        </Hidden>
                        <Hidden mdUp>
                            <>
                                {loadingArticles && <Box sx={{ margin: '0 auto' }}><CircularProgress /></Box>}
                                {
                                    loadingArticles === false && (
                                        <Box
                                            sx={{
                                                margin: "16px 0px 0px 0px",
                                                paddingBottom: '17px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'flex-start',
                                                borderBottom: `1px solid ${JC_COLORS.JC_ICON}`
                                            }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',

                                                }}>
                                                <Typography variant="BODY_COPY1_RALEWAY" sx={{ marginBottom: '9px' }}>{numberOfUnreadArticles} Unread Articles </Typography>
                                                <ToggleButton
                                                    leftSelected={unreadOnly === false}
                                                    toggleButtonHandler={() => setUnreadOnly(!unreadOnly)}
                                                />

                                            </Box>
                                            <IconButton onClick={() => setSortDrawerOpened(true)}>
                                                <SortIconFilled />
                                            </IconButton>

                                            <TopDrawer
                                                open={sortDrawerOpened}
                                                onOpen={() => setSortDrawerOpened(true)}
                                                onClose={() => setSortDrawerOpened(false)}
                                            >
                                                <AppBar
                                                    position="fixed"
                                                    sx={{

                                                        zIndex: theme.zIndex.drawer + 1,
                                                        bgcolor: JC_COLORS.JC_RED,
                                                        color: JC_COLORS.JC_WHITE,
                                                        display: 'block',

                                                    }}
                                                >
                                                    <Toolbar>
                                                        <Typography variant="H2_BITTER" sx={{ fontWeight: 700 }}>Sort</Typography>
                                                    </Toolbar>
                                                </AppBar>
                                                <Box sx={{ margin: "80px 16px 16px 16px" }}>
                                                    <Typography
                                                        sx={{
                                                            textDecoration: 'underline',
                                                            color: JC_COLORS.JC_RED,
                                                            fontFamily: 'Raleway',
                                                            fontSize: '0.75rem',
                                                            lineHeight: '1.125rem',
                                                            fontWeight: '600',
                                                            marginTop: "3px",
                                                            marginBottom: "0.9rem",

                                                        }}
                                                    >
                                                        Clear All
                                                    </Typography>


                                                    <CustomRadioArticleType
                                                        orderByLabel={orderByLabel}
                                                        changeArticleTypeHandler={(val) => setOrderByLabel(val)}
                                                    />

                                                    <Spacer height={"2.1rem"} />

                                                    <Typography sx={{
                                                        fontSize: '12px',
                                                        lineHeight: '14px',
                                                        fontWeight: 700,
                                                        fontFamily: 'Raleway',
                                                    }}>Date Range</Typography>

                                                    <Spacer height={"1.1rem"} />

                                                    <CustomRadioDateRange
                                                        // dateRange={dateRange}
                                                        dateRangeInput={dateRange}
                                                        dateRangeLabel={dateRangeLabel}
                                                        changeDateRangeLabel={(val) => setDateRangeLabel(val)}
                                                        changeDateRangeHandler={(val) => setDateRange(val)}
                                                    />

                                                    <Spacer height={"4rem"} />

                                                    <CenteredActionButton onClick={() => setSortDrawerOpened(false)}>Sort Now</CenteredActionButton>

                                                </Box>

                                            </TopDrawer>

                                        </Box>
                                    )
                                }

                            </>

                        </Hidden>
                        <StyledGrid
                            container
                        >
                            {
                                (articleList !== undefined) && (articleList.map((obj, index) => {
                                    if (articleList.length === index + 1) {
                                        return (
                                            <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                                                key={obj.PMID}
                                                ref={lastArticleElementRef}
                                                onClick={
                                                    () => {
                                                        if (
                                                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITED ||
                                                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.ADD ||
                                                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED
                                                        ) {
                                                            setSelectedPMID(obj.PMID)
                                                            setOpenDialogBox(true);
                                                        } else {
                                                            navigate(`/jc/${urlSlug}/${obj.PMID}`)
                                                        }
                                                    }
                                                }
                                                sx={{
                                                    cursor: 'pointer',
                                                    padding: {
                                                        xs: '0', 
                                                        sm: '0', 
                                                        md: '0 0 16px 0', 
                                                        lg: `0 ${index % 2 === 0 ? '16px' : 0} 16px 0`, 
                                                        xl: `0 ${index % 2 === 0 ? '16px' : 0} 16px 0`
                                                      }
                                                }}
                                            >
                                                <ArticleCardView
                                                    isLoading={loadingArticles}
                                                    journalSource={obj.sourceName}
                                                    journalIssue={obj.issue}
                                                    showCME={obj.showCME}
                                                    isSaved={obj.savedByUser}
                                                    journalTitle={obj.title}
                                                    authors={obj.authors}
                                                    publicationDate={obj.publicationdate}
                                                    keywords={obj.keywords}
                                                    numberOfPractices={obj.numberOfPracticeImpacting}
                                                    numberOfPracticeImpactingInUserJCs={obj.numberOfPracticeImpactingInUserJCs}
                                                    numberOfLikes={obj.numberOfLikes}
                                                    numberOfLikesInUserJCs={obj.numberOfLikesInUserJCs}
                                                    numberOfComments={obj.numberOfComments}
                                                    numberOfCommentsInUserJCs={obj.numberOfCommentsInUserJCs}
                                                    numberOfNotes={obj.numberOfNotes}
                                                    piByUser={obj.PIByUser}
                                                    isLiked={obj.likedByUser}
                                                    isCommented={obj.numberOfComments > 0}
                                                    isRead={obj.readByUser}
                                                    noteAddedByUser={obj.noteAddedByUser}
                                                    proposedBy={obj.proposedBy}
                                                    numberOfSupporters={obj.numberOfSupporters}
                                                    showJcDate={true}
                                                    jcStatus={obj.status}
                                                    jcStatusUpdatedOn={obj.statusUpdatedOn}
                                                    journalClubsList={obj.journalClubs.filter(obj => obj.JCID !== JCID)}
                                                    showUnderDiscussionJCs={false}
                                                    showPublishedJCs={true}
                                                    newNoteAdded={obj.newNoteAdded}
                                                    newCommentAdded={obj.newCommentAdded}
                                                    PMID={obj.PMID}
                                                    JCID={JCID}
                                                    isDeNovo={obj.isDeNovo}
                                                    isEditable={obj.isEditable}
                                                    denovoStatus={obj.status}
                                                    urlSlug={urlSlug}
                                                />
                                            </Grid>


                                        )
                                    } else {
                                        return (
                                            <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                                                key={obj.PMID}
                                                onClick={
                                                    () => {
                                                        if (
                                                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITED ||
                                                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.ADD ||
                                                            jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED
                                                        ) {
                                                            setSelectedPMID(obj.PMID)
                                                            setOpenDialogBox(true);

                                                        } else {
                                                            navigate(`/jc/${urlSlug}/${obj.PMID}`)
                                                        }
                                                    }
                                                }
                                                sx={{
                                                    cursor: 'pointer',
                                                    padding: {
                                                        xs: '0', 
                                                        sm: '0', 
                                                        md: '0 0 16px 0', 
                                                        lg: `0 ${index % 2 === 0 ? '16px' : 0} 16px 0`, 
                                                        xl: `0 ${index % 2 === 0 ? '16px' : 0} 16px 0`
                                                      }
                                                }}
                                            >

                                                <ArticleCardView
                                                    isLoading={loadingArticles}
                                                    journalSource={obj.sourceName}
                                                    journalIssue={obj.issue}
                                                    showCME={obj.showCME}
                                                    isSaved={obj.savedByUser}
                                                    journalTitle={obj.title}
                                                    authors={obj.authors}
                                                    publicationDate={obj.publicationdate}
                                                    keywords={obj.keywords}
                                                    numberOfPractices={obj.numberOfPracticeImpacting}
                                                    numberOfPracticeImpactingInUserJCs={obj.numberOfPracticeImpactingInUserJCs}
                                                    numberOfLikes={obj.numberOfLikes}
                                                    numberOfLikesInUserJCs={obj.numberOfLikesInUserJCs}
                                                    numberOfComments={obj.numberOfComments}
                                                    numberOfCommentsInUserJCs={obj.numberOfCommentsInUserJCs}
                                                    numberOfNotes={obj.numberOfNotes}
                                                    piByUser={obj.PIByUser}
                                                    isLiked={obj.likedByUser}
                                                    isCommented={obj.numberOfComments > 0}
                                                    isRead={obj.readByUser}
                                                    noteAddedByUser={obj.noteAddedByUser}
                                                    proposedBy={obj.proposedBy}
                                                    numberOfSupporters={obj.numberOfSupporters}
                                                    showJcDate={true}
                                                    jcStatus={obj.status}
                                                    jcStatusUpdatedOn={obj.statusUpdatedOn}
                                                    journalClubsList={obj.journalClubs.filter(obj => obj.JCID !== JCID)}
                                                    showUnderDiscussionJCs={false}
                                                    showPublishedJCs={true}
                                                    newNoteAdded={obj.newNoteAdded}
                                                    newCommentAdded={obj.newCommentAdded}
                                                    PMID={obj.PMID}
                                                    JCID={JCID}
                                                    isDeNovo={obj.isDeNovo}
                                                    isEditable={obj.isEditable}
                                                    denovoStatus={obj.status}
                                                    urlSlug={urlSlug}
                                                />

                                            </Grid>

                                        )
                                    }


                                }
                                )
                                )


                            }

                            {
                                loadingArticles && (
                                    [...new Array(4).keys()].map((el, index) => (
                                        <Grid
                                            key={el}
                                            item
                                            lg={6} xl={6} md={12} sm={12} xs={12}
                                            sx={{
                                                padding: {
                                                    xs: '0',
                                                    sm: '0',
                                                    md: '0 0 16px 0',
                                                    lg: `0 ${index % 2 === 0 ? '16px' : 0} 16px 0`,
                                                    xl: `0 ${index % 2 === 0 ? '16px' : 0} 16px 0`
                                                }
                                            }}
                                        >
                                            <ArticleCardViewSkeleton />
                                        </Grid>
                                    ))
                                )
                            }
                            {
                                (articleList === undefined || (!loadingArticles && articleList.length === 0)) && (
                                    <CenteredContainer>
                                        <StyledText>There are no published articles as yet.</StyledText>
                                    </CenteredContainer>
                                )
                            }
                        </StyledGrid>
                    </>
                )
            }


            <BootstrapDialog
                open={openDialogBox}
                onClose={() => setOpenDialogBox(false)}
            >
                <DialogTitle
                    sx={{
                        height: '32px',
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                        backgroundColor: JC_COLORS.JC_RED,
                        color: JC_COLORS.JC_WHITE,
                    }}
                >
                    <Typography sx={{ ...theme.typography.H3_BITTER, fontWeight: 700, }}>Join Journal Club</Typography>
                    <CloseIcon
                        sx={{
                            width: '24px',
                            height: '24px',
                            color: JC_COLORS.JC_WHITE,
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenDialogBox(false)}
                    />
                </DialogTitle>
                <DialogContent
                    sx={{
                        textAlign: 'center',
                        margin: '35px 35px 24px 35px',
                    }}
                >
                    <Typography
                        variant="H2_RALEWAY"
                        sx={{ fontWeight: 600 }}
                    >
                        {jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITED && 'You are invited to become a member of this journal club. Accept invite to view articles.'}
                        {jcDetails.membershipStatus === MEMBERSHIP_STATUS.ADD && 'You need to become a member of journal club to view articles. '}
                        {jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED && 'You need to become a member of journal club to view articles. '}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '16px' }}>
                    {
                        jcDetails.membershipStatus === MEMBERSHIP_STATUS.INVITED && (
                            <JCInviteButtons
                                JCID={JCID}
                                userEmail={userEmail}
                                nextAction={() => navigate(`/jc/${urlSlug}/${selectedPMID}`)}
                            />
                        )
                    }
                    {
                        jcDetails.membershipStatus === MEMBERSHIP_STATUS.ADD && (
                            <JoinJCButton
                                JCID={JCID}
                                userEmail={userEmail}
                                journalClubName={jcDetails.name}
                                isJCPublic={jcDetails.isPublic}
                                nextAction={() => navigate(`/jc/${urlSlug}/${selectedPMID}`)}
                            />
                        )
                    }
                </DialogActions>
            </BootstrapDialog>
        </>
    )
}

export default PublishedTab

const JournalClubsComponent = ({ articleJcList, currJCID }) => {
    const theme = useTheme();
    const { jcList } = useSelector((state) => state.journalclub);

    const articlePublishedIn = articleJcList.filter(obj => obj.articleStatus === 'Published' && obj.JCID !== currJCID);
    const articlePublishedInAndUserIsMember = articlePublishedIn.filter(obj => obj.isUserAMember);

    const jcColors = jcList.reduce((acc, curr) => {
        acc[curr.JCID] = curr.iconColor
        return acc;
    }, {});

    const restOfJc = articlePublishedIn.length - articlePublishedInAndUserIsMember.length;
    let restOfJcText = ""
    if (restOfJc === 0) {
        restOfJcText = ''
    } else if (restOfJc === 1) {
        restOfJcText = `${articlePublishedInAndUserIsMember.length > 0 ? '+' : ''}` + `1 JC`
    } else {
        restOfJcText = `${articlePublishedInAndUserIsMember.length > 0 ? '+' : ''}` + `${restOfJc} JCs`
    }
    return (
        <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Typography
                sx={{
                    color: JC_COLORS.JC_ICON,
                    ...theme.typography.BODY_COPY2_RALEWAY,
                    fontWeight: 700,
                    marginRight: '4px',
                }}>
                {(articlePublishedIn.length > 0) ? 'in' : ''}
            </Typography>
            {
                articlePublishedInAndUserIsMember.map(obj => (
                    <JC_Avatar
                        key={obj.JCID}
                        iconColor={jcColors[obj.JCID]}
                        title={obj.name}
                        size={20}
                        sx={{
                            marginRight: '4px',
                            fontSize: '15px',
                            width: '24px',
                            height: '24px',
                        }}
                    />)
                )
            }
            <Typography
                sx={{
                    color: JC_COLORS.JC_ICON,
                    ...theme.typography.BODY_COPY2_RALEWAY,
                    fontWeight: 700
                }}>{restOfJcText}</Typography>
        </Box>
    )

}