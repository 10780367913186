import React from 'react'
import {Box} from '@mui/material'; 

const Row = ({ children, sx={} }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                ...sx, 

            }}>
            {children}
        </Box>
    )
}

export default Row; 