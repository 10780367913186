import { Box, Button } from "@mui/material";

const CenteredActionButton = ({ onClick, children, disabled = false }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        }}
    >
        <Button
            variant="contained"
            sx={{
                //width: "112px",
                height: "32px",
                padding: "4px 10px",
                borderRadius: '4px',
                fontFamily: 'Raleway',
                fontWeight: 'bold',
                fontSize: '0.75rem',
                lineHeight: '1.25rem',
                textTransform: 'none',
                display: 'inline-block'
            }}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Button>
    </Box>
)

export default CenteredActionButton