import React from 'react';
import {
    Box,
    Typography,
    Avatar,
    Button,
    Grid,
    Chip,
    Divider,
    Link,
    TextField,
    styled,
    InputAdornment,
    Backdrop,   
} from '@mui/material';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import HeightIcon from '@mui/icons-material/Height';
import Pagination from '@mui/material/Pagination';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { JC_COLORS } from '../../../../styles/constants';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { CommentIcon, LikeIcon, NoteIcon, ViewsIcon, SupportArticle, PIIcon, ViewIconGrey } from '../../../../assets';
import PracticeIcon from '../../../../assets/PracticeIcon';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { fetchDownloadReport,fetchUserActivityDownloadRoport, url } from '../../../../api';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link as RouteLInk } from 'react-router-dom';
import { ACTIVITY_DEFAULTS, DEFAULT_JC } from '../../../../constants/constants';

export const CenterFlexBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center'
}));


export const InlineBox = styled(Box)(({ theme }) => ({
    display: 'inline-block',
}));

export const BlackDot = ({ size }) => {
    return (
        <Box sx={{
            height: `${size ? size : '14px'}`,
            width: `${size ? size : '14px'}`,
            backgroundColor: '#000000',
            borderRadius: '50%',
            display: 'inline-block'
        }}>
        </Box>
    )
}

export const DownloadReportButton = ({ JCID, parameter, queryParameters, currentLoginUser, downloadName, dateRange,variant="contained",label="Download Report" }) => {
    const [responseData, setResponseData] = useState("")
    useEffect(() => {
        if(queryParameters){
        fetchDownloadReport(JCID, parameter, currentLoginUser, queryParameters).then(response => {
            if (response?.data) {
                setResponseData(response)
            }
        }).catch(error => {
            console.error(error)
        })
        }
    }, [queryParameters, JCID, currentLoginUser, parameter])

    return (
        <Box sx={{ display: "inline-block", cursor: "pointer" }} ml={1}>
            <Button variant={variant} color="primary" sx={{ fontFamily: "Raleway", fontWeight: "700", textTransform: 'none', height: '40px' }} >
                <Link sx={{ color: variant==="contained" ? "#ffffff" : JC_COLORS.JC_RED, cursor: 'pointer', textDecoration: 'None', "&:hover": { textDecorationColor: '#262626' } }} target="_blank" download={dateRange.startDate ? `${dateRange.startDate}-${dateRange.endDate}_${downloadName}` : `AllTime_${downloadName}`} href={"data:text/csv;charset=utf-8" + encodeURI(responseData.data)}>
                    {label}
                </Link>
            </Button>
        </Box>
    )
}
export const DownloadActivityReportButton = ({ userEmail, queryParameters,downloadName, dateRange,variant="contained",label="Download Report" }) => {
    const [responseData, setResponseData] = useState("")
    useEffect(() => {
        if(queryParameters){
        fetchUserActivityDownloadRoport(userEmail, queryParameters).then(response => {
            if (response?.data) {
                setResponseData(response)
            }
        }).catch(error => {
            console.error(error)
        })
        }
    }, [queryParameters])

    return (
        <Box sx={{ display: "inline-block", cursor: "pointer" }} ml={1}>
            <Button variant={variant} color="primary" sx={{ fontFamily: "Raleway", fontWeight: "700", textTransform: 'none', height: {xs:'32px',md:'40px'} }} >
                <Link sx={{ color: variant==="contained" ? "#ffffff" : JC_COLORS.JC_RED, cursor: 'pointer', textDecoration: 'None', "&:hover": { textDecorationColor: '#262626' } }} target="_blank" download={dateRange.startDate ? `${dateRange.startDate}-${dateRange.endDate}_${downloadName}` : `AllTime_${downloadName}`} href={"data:text/csv;charset=utf-8" + encodeURI(responseData.data)}>
                    {label}
                </Link>
            </Button>
        </Box>
    )
}

export const ManageButton = ({label,onClick,variant="contained"}) => {
    return (
        <Box sx={{ display: "inline-block", cursor: "pointer" }} ml={1}>
            <Button variant={variant} color="primary" sx={{ fontFamily: "Raleway", fontWeight: "700", textTransform: 'none', height: '36px' }} onClick={onClick}>
                <Link sx={{ color: variant==="contained" ? "#ffffff" : JC_COLORS.JC_RED, cursor: 'pointer', textDecoration: 'None', "&:hover": { textDecorationColor: '#262626' } }} >
                    {label}
                </Link>
            </Button>
        </Box>
    )
}

export const NumberBox = ({ numberOf, title, top }) => {
    return (
      <Box>
        <Box>
          <Typography variant="H1_BITTER">
            {numberOf ? numberOf < 10 ? "0" + numberOf : numberOf : "00"}
          </Typography>
        </Box>
        <Box>
          <Typography variant="BODY_COPY2_RALEWAY" color={top ? "#ffffff" : JC_COLORS.JC_ICON}>
            {title}
          </Typography>
        </Box>
      </Box>
    )
  }

export const SummaryBox = ({ numberOf, title, children,isfloat = false}) => {
    return (
        <CenterFlexBox ml={2.5}>
            <InlineBox> {children}</InlineBox>
            <InlineBox ml={1.5} sx={{minWidth:{xs:'28px',md:'36px'}}}>
                <Typography variant="H1_BITTER" color={JC_COLORS.JC_WHITE}>
                    {numberOf === null ? "-" : 
                    numberOf && isfloat ? numberOf.toFixed(1) :
                    numberOf && !isfloat ? numberOf < 10 ? "0" + numberOf : numberOf : "00"}
                </Typography>
            </InlineBox>
            <InlineBox ml={1.5} sx={{ textAlign: 'left' }}>
                <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_WHITE}>
                    {title}
                </Typography>
            </InlineBox>
        </CenterFlexBox>
    )
}

export const UserCell = ({ name, contracted = false, onClick, isDisabled = false  }) => {
    return (
        <CenterFlexBox ml={contracted ? 0 : 3} onClick={ isDisabled ? null : onClick}>
            <InlineBox mr={1}>
                <Avatar variant="square" alt="Benjamin Scricia" src={""}
                    sx={{
                        height: "32px",
                        width: "32px",
                        borderRadius: "2px!important",
                    }}
                >{name ? name[0].toUpperCase() : ''}</Avatar>
            </InlineBox>
            <InlineBox>
                <Link sx={{ cursor: isDisabled ? 'default' : 'pointer', textDecoration: 'None', "&:hover": { textDecoration: isDisabled ? 'none' : 'underline', textDecorationColor: '#262626' } }}>
                    <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '700!important' }}>
                        {name ? name : '-'}
                    </Typography>
                </Link>
            </InlineBox>
        </CenterFlexBox>
    )
}
export const ArtcleTitleCell = ({ title, urlSlug, PMID, clickable = true,isMobile = false,isDenovoArticle = false,maxWidth = '25ch' }) => {
    return (
        <CenterFlexBox ml={3} sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: maxWidth}} mt={2} mb={isMobile ? 0 : 2}>
            <InlineBox sx={{maxWidth: isDenovoArticle ? '24ch' : maxWidth,overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            {clickable ?
                <RouteLInk to={urlSlug ? `/jc/${urlSlug}/${PMID}` : `/article/${PMID}` } target="_blank" className='reportTabArticleLink'>
                    <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '700!important'}}>
                        {title}
                    </Typography>
                </RouteLInk>
                :
                <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '700!important' }}>
                    {title}
                </Typography>
            }
            </InlineBox>
            {isDenovoArticle &&
            <InlineBox sx={{ fontFamily:'Raleway',fontSize:'12px',backgroundColor:JC_COLORS.JC_RED,padding:'2px 6px',color:'#FFFFFF',borderRadius:'4px'}} ml={0.5}>
                ORIGINAL
            </InlineBox>
            }
        </CenterFlexBox>
    )
}
export const CommentAndNoteHandler = ({ urlSlug, PMID, CNNID, hasReply, CCID }) => {
    return (
        <Box ml={3} sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '30ch', color: `${JC_COLORS.JC_RED}!important` }} mt={2} mb={2}>
            {
                hasReply ?
                    // <Link sx={{ cursor: 'pointer', textDecoration: 'None', "&:hover": { textDecoration: 'underline', textDecorationColor: '#262626' } }} target="_blank" href={url + "/" + PMID + "#" + CNNID + "-" + hasReply + "-" + CCID}>
                    <RouteLInk to={{
                        pathname: `/jc/${urlSlug}/${PMID}`,
                        hash: `#${CNNID}-${hasReply}-${CCID}`
                    }} target="_blank"
                        style={{ color: JC_COLORS.JC_RED }}
                    >
                        <Typography variant="BODY_ARTICLE_RALEWAY" sx={{ fontWeight: '700!important' }}>
                            VIEW
                        </Typography>
                    </RouteLInk>
                    :
                    // <Link sx={{ cursor: 'pointer', textDecoration: 'None', "&:hover": { textDecoration: 'underline', textDecorationColor: '#262626' } }} target="_blank" href={url + "/" + PMID + "#" + CNNID}>
                    <RouteLInk to={{
                        pathname: `/jc/${urlSlug}/${PMID}`,
                        hash: `#${CNNID}`
                    }} target="_blank"
                        style={{ color: JC_COLORS.JC_RED }}
                    >
                        <Typography variant="BODY_ARTICLE_RALEWAY" sx={{ fontWeight: '700!important' }}>
                            VIEW
                        </Typography>
                    </RouteLInk>
            }

        </Box>
    )
}

export const InstitutionCell = ({ name }) => {
    return (
        <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '20ch', textAlign: 'left' }}>
            <Typography variant="BODY_COPY2_RALEWAY" color="#262626" sx={{ fontWeight: '700!important' }}>
                {name ? name : "-"}
            </Typography>
        </Box>
    )
}

export const BreadCrumbs = ({label,onClick}) => {
    return (
        <Link sx={{ cursor: 'pointer', textDecoration: 'None' }} onClick={onClick} className='report-nav-root_text'>
            <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
            <Typography variant="BODY_COPY2_RALEWAY">
                {label}
            </Typography>
        </Link>
    )
}

export const EnhancedTableHead = (props) => {
    const { headCells, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Tooltip title={headCell.label} arrow >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={headCell.sortable ? createSortHandler(headCell.id) : null}
                                hideSortIcon={true}
                                sx={{
                                    fontFamily: 'Raleway', fontSize: '16px', fontWeight: '600', color: '#838383',
                                    "& .MuiTableSortLabel-icon": {
                                        color: "#EF5B50!important",
                                    },
                                    "& .Mui-active": {
                                        color: "#EF5B50!important",
                                    }
                                }}
                            >
                                {headCell.isIconLabel ? IconHeadCell[headCell.label] : headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                                {orderBy !== headCell.id && headCell.sortable ? (
                                    <HeightIcon fontSize="small" sx={{ color: "#838383", marginLeft: '3px' }} />
                                ) : null}

                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired,
};

export const JCChip = ({ jcName, jcColor,nameColor = '#262626',isMobile = false,maxWidth= 'auto' }) => {
    return (
      <Chip avatar={
          <Avatar sx={{bgcolor: jcColor,color: '#FFFFFF!important',fontFamily: 'RALEWAY!important',fontWeight: '600!important',fontSize: isMobile ? '12px!important' : '14px!important',textTransform:'uppercase'}}>
            {jcName.replace(/The |the |a |A |an |An /, '').trim().charAt(0)}
          </Avatar>
        }
        label={jcName}
        style={{backgroundColor:'transparent',fontFamily: 'RALEWAY',fontSize: isMobile ? '12px' : '16px',color: nameColor,maxWidth: maxWidth}}
        sx={{'& .MuiChip-label': {width: { xs: '75vw', md: 'auto' },fontWeight:{xs:'700',md:'600'}}}}
      />
    );
  };

export const JCTableRow = ({children,onRowClick}) => {
    return (
        <React.Fragment>
            <TableRow
                hover
                onClick={onRowClick}
                tabIndex={-1}
                sx={{
                '&:hover': {
                    backgroundColor: '#FFF7F6!important',
                    '& .MuiChip-root': {
                    backgroundColor: '#FFF7F6!important',
                    },
                },
                backgroundColor: '#F3F3F3!important',
                '& .MuiChip-root': {
                    backgroundColor: '#F3F3F3!important',
                    },
                }}
            >
            {children}
            </TableRow>
            <Box sx={{ minHeight: '12px' }}></Box>
        </React.Fragment>
    )
}

export const JCBackdrop = ({open}) => {
    return (
    <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
          backgroundColor: 'transparent',
        }}
        open={open}
      >
        <CircularProgress color="primary" />
    </Backdrop>  
    )
}

export const JCReportSearch = ({value,onChange,autoComplete,placeholder,onClearInput,onSearch,sx={}}) => {
    return (
        <React.Fragment>
        <StyledTextField
            value={value}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
            placeholder={placeholder}
            autoComplete={autoComplete}
            inputProps={{
              style: {
                fontFamily: 'Raleway',
                fontWeight: 600,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                  <CloseIcon
                    style={{ display: `${value === '' ? 'none' : 'block'}` }}
                    onClick={onClearInput}
                  />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
                onChange(e.target.value ? e.target.value : '');
            }}
            sx={sx}
          ></StyledTextField>
          <StyledSearchButton
            variant="outlined"
            onClick={onSearch}
            sx={{ marginLeft: '11px' }}
          >
            Search
          </StyledSearchButton>
          
        </React.Fragment>
    )
}

export const DefaultSelectedJC = {
    name : "All Journal Clubs",
    JCID : DEFAULT_JC
}
export const DefaultSelectedJCList = [
    {
        name : ACTIVITY_DEFAULTS.ALL,
        JCID : '',
        labelView : true,
        apiKey : ACTIVITY_DEFAULTS.ALL_KEY
    },
    {
        name : ACTIVITY_DEFAULTS.FEED,
        JCID : '',
        labelView : true,
        apiKey : ACTIVITY_DEFAULTS.FEED_KEY
    },
    {
        name : ACTIVITY_DEFAULTS.ALL_JC,
        JCID : '',
        labelView : true,
        apiKey : ACTIVITY_DEFAULTS.JC_KEY
    }
]

export const MembershipHeadCells = [
    {
        id: 'memberName',
        numeric: false,
        disablePadding: false,
        sortable: true,
        isIconLabel: false,
        label: 'Member Name',
    },
    {
        id: 'memberInstitution',
        numeric: false,
        disablePadding: false,
        isIconLabel: false,
        sortable: true,
        label: 'Institutions',
    },
    {
        id: 'memberRole',
        numeric: false,
        disablePadding: false,
        isIconLabel: false,
        sortable: true,
        label: 'Role',
    },
    {
        id: 'memberStatus',
        numeric: false,
        disablePadding: false,
        isIconLabel: false,
        sortable: false,
        label: 'Status',
    },
];

export const PublishHeadCells = [
    {
        id: 'articleTitle',
        numeric: false,
        disablePadding: false,
        sortable: true,
        isIconLabel: false,
        label: 'Article',
    },
    {
        id: 'articleProposedBy',
        numeric: false,
        disablePadding: false,
        isIconLabel: false,
        sortable: true,
        label: 'Proposed by',
    },
    {
        id: 'articleStatus',
        numeric: false,
        disablePadding: false,
        isIconLabel: false,
        sortable: false,
        label: 'Status',
    },
    {
        id: 'articleComments',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Comment',
    },
];

export const EngagementHeadCells = [
    {
        id: 'articleTitle',
        numeric: false,
        disablePadding: false,
        sortable: true,
        isIconLabel: false,
        label: 'Article',
    },
    {
        id: 'numberOfViews',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Views',
    },
    {
        id: 'numberOfLikes',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Like',
    },
    {
        id: 'numberOfPracticeImpactings',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Practice Impacting',
    },
    {
        id: 'numberOfSupporters',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Support',
    },
    {
        id: 'numberOfComments',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Comment',
    },
    {
        id: 'numberOfNotes',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Note',
    },
];

export const commentNoteHeadCells = [
    {
        id: 'articleTitle',
        numeric: false,
        disablePadding: false,
        sortable: true,
        isIconLabel: false,
        label: 'Article',
    },
    {
        id: 'articleReportedBy',
        numeric: false,
        disablePadding: false,
        sortable: true,
        isIconLabel: false,
        label: 'Reported By',
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        sortable: true,
        isIconLabel: false,
        label: 'Date',
    },
    {
        id: 'commentnotelogo',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: false,
        label: '',
    },
    {
        id: 'view',
        numeric: false,
        disablePadding: false,
        sortable: false,
        isIconLabel: false,
        label: '',
    }

];

export const MemberHeadCell = [
    {
        id: 'articleTitle',
        numeric: false,
        disablePadding: false,
        sortable: true,
        isIconLabel: false,
        label: 'Article',
    },
    {
        id: 'articleCME',
        numeric: true,
        disablePadding: false,
        isIconLabel: false,
        sortable: true,
        label: 'CME/CE',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        isIconLabel: false,
        sortable: true,
        label: 'Proposed/Started on',
    },
    {
        id: 'articleStatus',
        numeric: true,
        disablePadding: false,
        isIconLabel: false,
        sortable: false,
        label: 'Status',
    },
    {
        id: 'articleComments',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Comment',
    },
    {
        id: 'articleNotes',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Note',
    },
]
export const UserActivityHeadCell = [
    {
        id: 'articleTitle',
        numeric: false,
        disablePadding: false,
        sortable: true,
        isIconLabel: false,
        label: 'Article',
    },
    {
        id: 'jcName',
        numeric: false,
        disablePadding: false,
        sortable: true,
        isIconLabel: false,
        label: 'Journal Club',
    },
    {
        id: 'articleCME',
        numeric: true,
        disablePadding: false,
        isIconLabel: false,
        sortable: true,
        label: 'CME/CE',
    },
    {
        id: 'articleStatus',
        numeric: true,
        disablePadding: false,
        isIconLabel: false,
        sortable: false,
        label: 'Published On',
    },
    {
        id: 'readDate',
        numeric: true,
        disablePadding: false,
        isIconLabel: false,
        sortable: true,
        label: 'Read',
    },
    {
        id: 'articleComments',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Comment',
    },
    {
        id: 'articleNotes',
        numeric: true,
        disablePadding: false,
        isIconLabel: true,
        sortable: true,
        label: 'Note',
    },
]
export const IconHeadCell = {
    "Views": <ViewIconGrey width="24px" height="24px" />,
    "Like": <LikeIcon width="24px" height="24px" />,
    "Practice Impacting": <PIIcon width="24px" height="24px" fillcolor="none" />,
    "Support": <SupportArticle width="24px" height="24px" />,
    "Comment": <CommentIcon width="24px" height="24px" />,
    "Note": <NoteIcon width="24px" height="24px" />
}

export const StyledTextField = styled(TextField)(({ theme }) => ({
    borderRadius: '3px',
    background: 'white',
    border: `1px solid ${JC_COLORS.JC_ICON}`,
    [theme.breakpoints.up('md')]: {
        width: '18vw',
        '& .MuiInputBase-root': {
            height: '38px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
        }
    },
    [theme.breakpoints.down('md')]: {
        width: 'calc(100vw - 132px)',
        '& .MuiInputBase-root': {
            height: '32px',
            padding: '0px 5px',
        },
    },

}));

export const StyledSearchButton = styled(Button)(({ theme }) => ({
    fontWeight: '600',
    fontFamily: 'Raleway',
    border: "1px solid " + JC_COLORS.JC_RED,
    [theme.breakpoints.up('md')]: {
        height: '40px',
        marginLeft: "16px",
    },
    [theme.breakpoints.down('md')]: {
        height: '35px',
        marginLeft: "10px",
    }

}));