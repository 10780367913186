import {
  FETCH_ARTICLE_REQUEST,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLE_FAILURE,
  UPDATE_PI_STATUS,
  UPDATE_LIKE_STATUS,
  UPDATE_COMMENT_STATUS,
  UPDATE_SAVE_STATUS,
  POST_ARTICLE_ACTION_FAILURE,
  POST_ARTICLE_ACTION_SUCCESS,
  MARK_READ,
  UPDATE_ARTICLE_STATUS,
  UPDATE_NOTE_STATUS,
  UPDATE_OPPOSE_STATUS,
  UPDATE_SUPPORT_STATUS,
  UPDATE_PERMISSION_STATUS,
  FETCH_VOTER_REQUEST,
  FETCH_VOTER_FAILURE,
  FETCH_OPPOSER_SUCCESS,
  FETCH_SUPPORTER_SUCCESS,
  OPEN_DENOVO_ARTICLE_IN_EDIT_MODE,
  CLOSE_DENOVO_ARTICLE_IN_EDIT_MODE,
  RESET_DENOVO_ARTICLE_IN_EDIT_MODE,
  UPDATE_DENOVO_ARTICLE_DATA,
  //UPDATE_DENOVO_ARTICLE_TO_DB,
  SET_DENOVO_AUTO_SAVE_TIMESTAMP,
  SET_DENOVO_EDIT_HISTORY,
  SET_DIRTY,
  SET_ERROR_IN_DENOVO_ARTICLE,
  SET_DENOVO_LOCK_STATE,
  RESET_DENOVO_LOCK_STATE,
  SET_BODY_IMAGE_FILES,
  SET_BODY_VIDEO_FILES,
  SET_BODY_DOCUMENT_FILES,
  RESET_BODY_UPLOADED_FILES,
  SET_ABSTRACT_IMAGE_FILES,
  SET_ABSTRACT_VIDEO_FILES,
  SET_ABSTRACT_DOCUMENT_FILES,
  RESET_ABSTRACT_UPLOADED_FILES,
  SET_MEDIA_UPLOADING
} from "../actions/article";

// intial state

const intialState = {
  isLoading: true,
  isAllowed: false,
  articleData: {},
  supporterList: [],
  opposerList: [],
  error: "",
  actionSuccess: "",
  actionError: "",
  voterLoading: true,
  voterError: "",
  denovoArticleInEditMode: null,
  denovoArticleData: {
    title: "",
    abstractContent: "",
    bodyContent: "",
    authors: "",
    cmeCredits: "",
    abstractEditorState: "",
    abstractUploadedImages: [],
    articleBodyEditorState: "",
    bodyUploadedImages: [],
    bodyUploadedVideos: [],
    bodyUploadedDocuments: [],
    abstractUploadedImages: [],
    abstractUploadedVideos: [],
    abstractUploadedDocuments: [],
    mediaUploading: false
  },
  denovoArticleAutoSaveTimeStamp: "",
  denovoEditHistory: {},
  dirty: false,
  errorInDenovoArticle: {
    hasError: false,
    statusCode: 0,
    errorMessage: "",
  },
  denovoLockState: {
    isDenovoArticleLockedByCurrentUser: null,
    openLockStatusDialogBox: null,
    lockStatusMessage: "",
    currentlyEditedBy: null,
  },
};

// Set up reducer

export default (state = intialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        articleData: action.payload,
      };
    case FETCH_ARTICLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_ARTICLE_STATUS:
      const utcDate = new Date().toUTCString();
      return {
        ...state,
        articleData: {
          ...state.articleData,
          status: action.payload,
          statusUpdatedOn: utcDate,
        },
      };
    case UPDATE_PERMISSION_STATUS:
      return {
        ...state,
        isAllowed : action.payload
      }
    case UPDATE_PI_STATUS:
      let currentPIs = state.articleData.numberOfPracticeImpacting;
      let currentJCPIs = state.articleData.numberOfPracticeImpactingInUserJCs;
      if (state.articleData.PIByUser) {
        currentPIs = currentPIs > 0 ? currentPIs - 1 : currentPIs;
        currentJCPIs = currentJCPIs > 0 ? currentJCPIs - 1 : currentJCPIs;
      } else {
        currentPIs += 1;
        currentJCPIs += 1;
      }
      return {
        ...state,
        articleData: {
          ...state.articleData,
          numberOfPracticeImpacting: currentPIs,
          PIByUser: !state.articleData.PIByUser,
          numberOfPracticeImpactingInUserJCs: currentJCPIs,
        },
      };

    case UPDATE_LIKE_STATUS:
      let currentLikes = state.articleData.numberOfLikes;
      let currentJCLikes = state.articleData.numberOfLikesInUserJCs;
      if (state.articleData.likedByUser) {
        currentLikes = currentLikes > 0 ? currentLikes - 1 : currentLikes;
        currentJCLikes =
          currentJCLikes > 0 ? currentJCLikes - 1 : currentJCLikes;
      } else {
        currentLikes += 1;
        currentJCLikes += 1;
      }
      return {
        ...state,
        articleData: {
          ...state.articleData,
          numberOfLikes: currentLikes,
          likedByUser: !state.articleData.likedByUser,
          numberOfLikesInUserJCs: currentJCLikes,
        },
      };

    case UPDATE_SUPPORT_STATUS:
      let currentSupporters = state.articleData.numberOfSupporters;
      state.articleData.supportedByUser
        ? (currentSupporters -= 1)
        : (currentSupporters += 1);
      return {
        ...state,
        articleData: {
          ...state.articleData,
          numberOfSupporters: currentSupporters,
          supportedByUser: !state.articleData.supportedByUser,
        },
      };
    case UPDATE_OPPOSE_STATUS:
      let currentOpposers = state.articleData.numberOfOpposers;
      state.articleData.opposedByUser
        ? (currentOpposers -= 1)
        : (currentOpposers += 1);
      return {
        ...state,
        articleData: {
          ...state.articleData,
          numberOfOpposers: currentOpposers,
          opposedByUser: !state.articleData.opposedByUser,
        },
      };
    case UPDATE_SAVE_STATUS:
      return {
        ...state,
        articleData: {
          ...state.articleData,
          savedByUser: !state.articleData.savedByUser,
        },
      };
    case UPDATE_COMMENT_STATUS:
      return {
        ...state,
        articleData: {
          ...state.articleData,
          commentedByUser: !state.articleData.commentedByUser,
        },
      };
    case UPDATE_NOTE_STATUS:
      return {
        ...state,
        articleData: {
          ...state.articleData,
          notedByUser: !state.articleData.notedByUser,
        },
      };
    case POST_ARTICLE_ACTION_SUCCESS:
      return {
        ...state,
        actionSuccess: action.payload,
      };
    case POST_ARTICLE_ACTION_FAILURE:
      return {
        ...state,
        actionError: action.payload,
      };

    case FETCH_VOTER_REQUEST:
      return {
        ...state,
        voterLoading: true,
      };

    case FETCH_VOTER_FAILURE:
      return {
        ...state,
        voterError: action.payload,
      };

    case FETCH_OPPOSER_SUCCESS:
      return {
        ...state,
        opposerList: action.payload,
      };

    case FETCH_SUPPORTER_SUCCESS:
      return {
        ...state,
        supporterList: action.payload,
      };
    case OPEN_DENOVO_ARTICLE_IN_EDIT_MODE:
      return {
        ...state,
        denovoArticleInEditMode: true,
      };
    case CLOSE_DENOVO_ARTICLE_IN_EDIT_MODE:
      return {
        ...state,
        denovoArticleInEditMode: false,
      };
    case RESET_DENOVO_ARTICLE_IN_EDIT_MODE:
      return {
        ...state,
        denovoArticleInEditMode: false,
      };
    case UPDATE_DENOVO_ARTICLE_DATA:
      return {
        ...state,
        denovoArticleData: {
          ...state.denovoArticleData,
          ...action.payload,
        },
      };
    case SET_DENOVO_AUTO_SAVE_TIMESTAMP:
      return {
        ...state,
        denovoArticleAutoSaveTimeStamp: action.payload,
      };
    case SET_DENOVO_EDIT_HISTORY:
      return {
        ...state,
        denovoEditHistory: action.payload,
      };
    case SET_DIRTY:
      return {
        ...state,
        dirty: action.payload,
      };
    case SET_ERROR_IN_DENOVO_ARTICLE:
      return {
        ...state,
        errorInDenovoArticle: {
          ...state.errorInDenovoArticle,
          ...action.payload,
        },
      };
    case SET_DENOVO_LOCK_STATE:
      console.log({ ...state.denovoLockState, ...action.payload });
      return {
        ...state,
        denovoLockState: {
          ...state.denovoLockState,
          ...action.payload,
        },
      };
    case RESET_DENOVO_LOCK_STATE:
      return {
        ...state,
        denovoLockState: {
          isDenovoArticleLockedByCurrentUser: null,
          openLockStatusDialogBox: false,
          lockStatusMessage: "",
          currentlyEditedBy: null,
        }
      }
    case SET_MEDIA_UPLOADING:
      return {
        ...state,
        denovoArticleData: {
          ...state.denovoArticleData,
          mediaUploading: action.payload,
        }
      }
    case SET_BODY_IMAGE_FILES:
      console.log("setting Body images")
      const currBodyImageFiles = state.denovoArticleData.bodyUploadedImages
      return {
        ...state,
        denovoArticleData: {
          ...state.denovoArticleData,
          bodyUploadedImages: [...currBodyImageFiles,action.payload],
        },
      };
    case SET_BODY_VIDEO_FILES:
      console.log("setting Body videos")
      const currBodyVideoFiles = state.denovoArticleData.bodyUploadedVideos
      return {
        ...state,
        denovoArticleData: {
          ...state.denovoArticleData,
          bodyUploadedVideos: [...currBodyVideoFiles,action.payload],
        },
      };
    case SET_BODY_DOCUMENT_FILES:
      console.log("setting Body documents")
      const currBodyDocumentFiles = state.denovoArticleData.bodyUploadedDocuments
      return {
        ...state,
        denovoArticleData: {
          ...state.denovoArticleData,
          bodyUploadedDocuments: [...currBodyDocumentFiles,action.payload],
        },
      };
    case RESET_BODY_UPLOADED_FILES:
      return {
        ...state,
        denovoArticleData:{
          ...state.denovoArticleData,
          bodyUploadedImages: [],
          bodyUploadedVideos: [],
          bodyUploadedDocuments: []
        }
      }
    case SET_ABSTRACT_IMAGE_FILES:
    console.log("setting Abstract images")
    const currAbstractImageFiles = state.denovoArticleData.abstractUploadedImages
    return {
      ...state,
      denovoArticleData: {
        ...state.denovoArticleData,
        abstractUploadedImages: [...currAbstractImageFiles,action.payload],
      },
    };
    case SET_ABSTRACT_VIDEO_FILES:
      console.log("setting abstract videos")
      const currAbstractVideoFiles = state.denovoArticleData.abstractUploadedVideos
      return {
        ...state,
        denovoArticleData: {
          ...state.denovoArticleData,
          abstractUploadedVideos: [...currAbstractVideoFiles,action.payload],
        },
      };
    case SET_ABSTRACT_DOCUMENT_FILES:
      console.log("setting abstract documents")
      const currAbstractDocumentFiles = state.denovoArticleData.abstractUploadedDocuments
      return {
        ...state,
        denovoArticleData: {
          ...state.denovoArticleData,
          abstractUploadedDocuments: [...currAbstractDocumentFiles,action.payload],
        },
      };
    case RESET_ABSTRACT_UPLOADED_FILES:
      return {
        ...state,
        denovoArticleData:{
          ...state.denovoArticleData,
          abstractUploadedImages: [],
          abstractUploadedVideos: [],
          abstractUploadedDocuments: []
        }
      }
    default:
      return state;
  }
};
