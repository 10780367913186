import React from 'react'
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { alpha, styled } from '@mui/material/styles';


import { Box, Chip, tableSortLabelClasses, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { JC_COLORS } from '../../styles/constants';
import { calcViewHeight, calcViewWidth } from '../../utils';

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
    backgroundColor: JC_COLORS.LIGHT_GREY,
    borderRadius: "4px",
    boxShadow: `0px 1px ${JC_COLORS.JC_ICON}`,
    [theme.breakpoints.up('md')]: {
        width: "32px",
        height: "32px",
        marginLeft: "2px",
        marginRight: "5px",
        padding: "0px 4px 0px 4px",
    },
    [theme.breakpoints.down('md')]: {
        width: "28px",
        height: "28px",
        marginBottom: "20px",
        marginLeft: "5px",
        fontWeight: '600',
        fontSize: '0.625rem',
    }

}));

const StyledChip = styled(Chip)(({ theme }) => ({
    maxWidth: "300px",
    textAlign: 'center',
    overflowWrap: 'break-word',
    whiteSpace: 'nowrap',
    textOverflow: `ellipsis ellipsis`,
    borderRadius: "4px",
    boxShadow: `0px 1px ${JC_COLORS.JC_ICON}`,
    verticalAlign: 'middle',
    "&:hover": {
        color: JC_COLORS.JC_BLACK
    },
    [theme.breakpoints.up('md')]: {
        marginLeft: '5px',
        marginBottom: '8px',
        fontFamily: 'Raleway',
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "20px",
        textAlign: "center",
    },
    [theme.breakpoints.down('md')]: {
        height: '28px',
        marginLeft: '5px',
        fontFamily: 'Raleway',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '16px',
        marginBottom: '8px',
    }
}));

const useStyles = makeStyles({
    desktop: {
        marginLeft: '5px',
        fontFamily: 'Raleway',
        fontWeight: "600",
        fontSize: "15px",
        lineHeight: "1.5rem",
        height: "24px",
        textAlign: "center",
        //padding: '4px 0px 4px 0px', 
    },

    mobile: {
        height: '20px',
        marginLeft: '5px',
        fontFamily: 'Raleway',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '0.75rem',
        marginBottom: '10px',
    },
    closeIcon: {
        backgroundColor: JC_COLORS.LIGHT_GREY,
        borderRadius: "4px",
        width: "32px",
        padding: "0px 4px 0px 4px",
        width: "20px",
        height: "20px",
        marginBottom: "20px",
        marginLeft: "5px",
        fontWeight: '600',
        fontSize: '0.625rem',
    }

})

const KeyWordChip = ({ label, onClick, selected = false, closeButtonHandler, showCloseIcon = false, disableClick}) => {
    const classes = useStyles();
    const theme = useTheme();
    const mobileMatch = useMediaQuery(theme.breakpoints.down('md'))
    const className = mobileMatch ? classes.mobile : classes.desktop;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <StyledChip
                clickable={disableClick ? false: true}
                label={label}
                size="medium"
                onClick={disableClick ? () => {}: onClick }
                sx={{
                    backgroundColor: selected ? JC_COLORS.JC_RED : JC_COLORS.LIGHT_GREY,
                    color: selected ? JC_COLORS.JC_WHITE : JC_COLORS.JC_BLACK,
                    cursor: 'pointer', 
                }}
            />
            {/* <Chip
                label={label}
                className={className}
                size="small"
                sx={{    
                    backgroundColor: selected ? JC_COLORS.JC_RED : JC_COLORS.LIGHT_GREY,
                    color: selected ? JC_COLORS.JC_WHITE : JC_COLORS.JC_BLACK,
                    boxShadow: 1,
                    borderRadius: 1,
                    maxWidth: "300px", 
                    textAlign: 'center',  
                    overflowWrap: 'break-word', whiteSpace: 'nowrap', textOverflow: `ellipsis ellipsis`
                    
                }}
                onClick={onClick}
            /> */}

            {showCloseIcon && <StyledCloseIcon onClick={() => closeButtonHandler()} />}
        </Box>
    )
}

export default KeyWordChip;


// const StyledChip = styled(Chip)(({ theme, selected }) => ({
//     boxShadow: 1,
//     borderRadius: 1,
//     backgroundColor: selected ? JC_COLORS.JC_RED : JC_COLORS.LIGHT_GREY,
//     color: selected ? JC_COLORS.JC_WHITE : JC_COLORS.JC_BLACK,
//     [theme.breakpoints.up('md')]: {
//         marginLeft: '5px',
//         fontFamily: 'Raleway',
//         fontWeight: "600",
//         fontSize: "1rem",
//     },
//     [theme.breakpoints.down('md')]: {
//         height: '20px',
//         marginLeft: '5px',
//         fontFamily: 'Raleway',
//         fontWeight: '600',
//         fontSize: '0.625rem',
//         marginBottom: '10px',
//     }

// }));