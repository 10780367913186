import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '80%',
      height: 'auto',
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      width: '30%',
      height: 'auto',
    }
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        pb: { xs: 1, sm: 0, md: 0 },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }} {...other}>
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            cursor: 'pointer',
          }}
        />
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ConfirmModal(props) {
  const { open, onClose,message,onProceed,onCancel } = props;
  const handleButtonClose = () => {
    //todo: Code for validation of confimation
    onCancel()
  }
   
  const handleProceedButtonClose = () => {
    //todo: Code for validation of confimation
    onProceed()
  }
  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }} p={{ xs: 0, sm: 2, md: 2 }}>            
              <Typography variant="H3_RALEWAY" sx={{ fontWeight: '600' }} gutterBottom> 
                {message} 
            </Typography>
            <Box mt={1}>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Box sx={{ display: 'inline-block' }}>
                  <Button variant='outlined' size="small" color="primary" onClick={handleButtonClose} >
                    No, Cancel
                  </Button>
                </Box>
                <Box sx={{ display: 'inline-block' }} mr={2}>
                  <Button variant='contained' size="small" color="primary" onClick={handleProceedButtonClose}>
                    Yes, Proceed
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </Box>

        </DialogContent>

      </BootstrapDialog>
    </div>
  );
}
