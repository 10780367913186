import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Grid, Box, Typography, Hidden, styled, CircularProgress, useTheme } from '@mui/material';
import { ArticleCardView, ArticleCardViewSkeleton } from '../../../common/components';
import { AuthContext } from '../../../context/AuthContext';
import JC_Avatar from '../../../common/components/JC_Avatar';

import { logout } from '../../../utils';
import axios from 'axios';
import * as api from '../../../api';
import { JC_COLORS } from '../../../styles/constants';
import connectionInstance from '../../../utils/connection';

const StyledGrid = styled(Grid)(({ theme }) => ({
    marginBottom: '16px',
    [theme.breakpoints.up('md')]: {
        padding: '16px 0px 0px 0px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0rem 16px 0rem 16px',
    }
}));

const CenteredContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        margin: '0 auto',
        width: '600px',
        margin: "70px auto",
    },
    [theme.breakpoints.down('md')]: {
        height: '50vh',
        margin: '0 auto',
        ...theme.typography.BODY_COPY1_RALEWAY_MOBILE,
    }
}));

const StyledText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H1_BITTER,
    },
    [theme.breakpoints.down('md')]: {
        ...theme.typography.H1_BITTER,
    }

}));


const fetchArticlesList = async (userEmail, pageNumber, query) => {
    return connectionInstance.get(`${encodeURI(`${api.SEARCH_PUBMED_URL}/${userEmail}/${pageNumber}`)}?${query}`);
}


const SearchArticlesList = ({ query, listOfSearchTerms }) => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const navigate = useNavigate();
    const [loadingArticles, setLoadingArticles] = useState(false);
    const [articleList, setArticleList] = useState([]);
    const [numberOfUnreadArticles, setNumberOfUnreadArticles] = useState(0);
    const [hasMoreArticles, setHasMoreArticles] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const observer = useRef();
  
    const { isLoading, data, isError, error, isFetching } = useQuery(['search-articles-list', userEmail, pageNumber, query], () => fetchArticlesList(userEmail, pageNumber, query));

   

    if (isLoading) {
        return (
            <>
                <CenteredContainer><CircularProgress /></CenteredContainer>
                <Grid 
                    container 
                    spacing={{ lg: 2, xl: 2, md: 2, xs: 0, sm: 0 }}
                >
                    {

                        [...new Array(100).keys()].map((el) => (
                            <Grid key={el} item lg={6} xl={6} md={12} sm={12} xs={12}>
                                <ArticleCardViewSkeleton />
                            </Grid>
                        ))
                    }
                </Grid>

            </>

        )
    }
    if (isError) {
       
        return (
            <CenteredContainer>
                <StyledText>Error!!!</StyledText>
                <StyledText>Something's Not Right</StyledText>
            </CenteredContainer>
        )
    }

   

    return (

        <>
            <Hidden mdDown>
                {(_numberOfArticlesText(data?.data?.articleList?.length))}
            </Hidden>
            {
                (data?.data?.articleList?.length === 0) && (
                    <CenteredContainer>
                        <StyledText>There are no articles for your search criteria.</StyledText>
                    </CenteredContainer>
                )
            }
            <StyledGrid
                container
                spacing={2}
            >
                {
                    (data?.data?.articleList !== undefined) && (data?.data?.articleList.map((obj, index) => {
                        if (data?.data?.articleList.length === index + 1) {
                            return (
                                <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                                    key={obj.PMID}
                                    //ref={lastArticleElementRef}
                                    onClick={() => navigate(`/article/${obj.PMID}`)}

                                    sx={{ cursor: 'pointer' }}
                                >
                                    <ArticleCardView
                                        key={obj.PMID}
                                        isLoading={isLoading}
                                        journalSource={obj.sourceName}
                                        journalIssue={obj.issue}
                                        showCME={obj.showCME}
                                        isSaved={obj.savedByUser}
                                        journalTitle={highlightText(obj.title, listOfSearchTerms.map(obj => obj.text))}
                                        authors={obj.authors}
                                        publicationDate={obj.publicationdate}
                                        keywords={obj.keywords}
                                        numberOfPractices={obj.numberOfPracticeImpacting}
                                        numberOfPracticeImpactingInUserJCs={obj.numberOfPracticeImpactingInUserJCs}
                                        numberOfLikes={obj.numberOfLikes}
                                        numberOfLikesInUserJCs={obj.numberOfLikesInUserJCs}
                                        numberOfComments={obj.numberOfComments}
                                        numberOfCommentsInUserJCs={obj.numberOfCommentsInUserJCs}
                                        numberOfNotes={0}
                                        noteAddedByUser={false}
                                        piByUser={obj.PIByUser}
                                        isLiked={obj.likedByUser}
                                        isCommented={obj.numberOfComments > 0}
                                        isRead={obj.readByUser}
                                        showJcDate={false}
                                        journalClubsList={obj.journalClubs}
                                        showUnderDiscussionJCs={true}
                                        showPublishedJCs={true}
                                        PMID={obj.PMID}
                                    />
                                </Grid>


                            )
                        } else {
                            return (
                                <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                                    key={obj.PMID}
                                    onClick={() => navigate(`/article/${obj.PMID}`)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <ArticleCardView
                                        key={obj.PMID}
                                        isLoading={isLoading}
                                        journalSource={obj.sourceName}
                                        journalIssue={obj.issue}
                                        showCME={obj.showCME}
                                        isSaved={obj.savedByUser}
                                        journalTitle={highlightText(obj.title, listOfSearchTerms.map(obj => obj.text))}
                                        authors={obj.authors}
                                        publicationDate={obj.publicationdate}
                                        keywords={obj.keywords}
                                        numberOfPractices={obj.numberOfPracticeImpacting}
                                        numberOfPracticeImpactingInUserJCs={obj.numberOfPracticeImpactingInUserJCs}
                                        numberOfLikes={obj.numberOfLikes}
                                        numberOfLikesInUserJCs={obj.numberOfLikesInUserJCs}
                                        numberOfComments={obj.numberOfComments}
                                        numberOfCommentsInUserJCs={obj.numberOfCommentsInUserJCs}
                                        numberOfNotes={0}
                                        noteAddedByUser={false}
                                        piByUser={obj.PIByUser}
                                        isLiked={obj.likedByUser}
                                        isCommented={obj.numberOfComments > 0}
                                        isRead={obj.readByUser}
                                        showJcDate={false}
                                        journalClubsList={obj.journalClubs}
                                        showUnderDiscussionJCs={true}
                                        showPublishedJCs={true}
                                        PMID={obj.PMID}
                                    />

                                </Grid>

                            )
                        }


                    }
                    )
                    )


                }

            </StyledGrid>


        </>


    )
}

export default SearchArticlesList

const _numberOfArticlesText = (number) => {
    let text = "results found";
    if (number === 0) {
        return <Typography></Typography>
    }
    if (number === 1) {
        text = "result found"
    }
    return <Typography
        sx={{
            fontFamily: 'Raleway',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '36px'
        }}>{`${number} ${text}`}</Typography>

}

const highlightText = (text, listOfStrings) => {

    const regex = new RegExp(listOfStrings.join('|'), 'gi');
    const splitText = text.split(regex);

    if (splitText.length <= 1) {
        return text;
    }
    const matches = [...text.matchAll(regex)]; // match all needs to be destructured else its return an object

    return splitText.reduce((arr, element, index) => (matches[index] ? [
        ...arr,
        element,
        <span style={{ color: JC_COLORS.JC_RED }}>
            {matches[index]}
        </span>,
    ] : [...arr, element]), []);
};

const JournalClubsComponent = ({ articleJcList }) => {
    const theme = useTheme();
    const { jcList } = useSelector((state) => state.journalclub);

    const articlePublishedIn = articleJcList.filter(obj => obj.articleStatus === 'Published');
    const articlePublishedInAndUserIsMember = articlePublishedIn.filter(obj => obj.isUserAMember);

    const jcColors = jcList.reduce((acc, curr) => {
        acc[curr.JCID] = curr.iconColor
        return acc;
    }, {});

    const restOfJc = articlePublishedIn.length - articlePublishedInAndUserIsMember.length;
    let restOfJcText = ""
    if (restOfJc === 0) {
        restOfJcText = ''
    } else if (restOfJc === 1) {
        restOfJcText = `${articlePublishedInAndUserIsMember.length > 0 ? '+' : ''}` + `1 JC`
    } else {
        restOfJcText = `${articlePublishedInAndUserIsMember.length > 0 ? '+' : ''}` + `${restOfJc} JCs`
    }

    return (
        <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Typography
                sx={{
                    color: JC_COLORS.JC_ICON,
                    ...theme.typography.BODY_COPY2_RALEWAY,
                    fontWeight: 700,
                    marginRight: '4px',
                }}>
                {(articlePublishedIn.length > 0) ? 'in' : ''}
            </Typography>
            {
                articlePublishedInAndUserIsMember.map(obj => (
                    <JC_Avatar
                        key={obj.JCID}
                        iconColor={jcColors[obj.JCID]}
                        title={obj.name}
                        size={20}
                        sx={{ marginRight: '4px' }}
                    />)
                )
            }
            <Typography
                sx={{
                    color: JC_COLORS.JC_ICON,
                    ...theme.typography.BODY_COPY2_RALEWAY,
                    fontWeight: 700
                }}>{restOfJcText}</Typography>
        </Box>
    )

}




