import React, { createContext, useState, useContext } from 'react';
import useLocalStorage from '../common/hooks/useLocalStorage';

const KeywordsContext = createContext();

const useKeywordsContext = () => {
    const context = useContext(KeywordsContext);
    if (context === undefined) {
        throw new Error('useContext Not Defined!');
    }
    return context;
};

export { useKeywordsContext, KeywordsContext };

const KeywordsContextProvider = (props) => {
    const [keywordsState, setKeywordsState] = useLocalStorage('keywordsState', {});
    const [sourcesState, setSourcesState] = useLocalStorage('sourcesState', {});

    //KEYWORDS Functions
    const addAKeyword = (keyword) => {
        setKeywordsState(
            {
                ...keywordsState,
                [keyword]: false
            }
        );
    }

    const removeAKeyword = (keyword) => {
        let tempList = Object.keys(keywordsState)?.filter(key => key !== keyword);
        setKeywordsState(
            tempList.reduce(
                (acc, curr) => {
                    acc[curr] = keywordsState[curr];
                    return acc;
                }, {}
            )
        )

    }

    const selectAKeyword = (keyword) => {
        setKeywordsState(
            {
                ...keywordsState,
                [keyword]: !keywordsState[keyword]
            }
        );
    }

    const clearAllKeywords = () => {
        setKeywordsState({})
    }

    const setAllKeywords = (keywordsArray) => {
        if(keywordsArray !== undefined) {
            let keywordsObj = keywordsArray?.reduce((acc, curr) => {
                acc[curr] = keywordsState[curr];
                return acc;
            }, {});
            setKeywordsState({ ...keywordsObj });
        }
      
       
    }

    // SOURCES Functions
    const addASource = (source) => {
        setSourcesState(
            {
                ...sourcesState,
                [source]:
                    false
            }
        );
    }

    const removeASource = (source) => {
        let tempList = Object.keys(sourcesState)?.filter(key => key !== source);
        setSourcesState(
            tempList.reduce(
                (acc, curr) => {
                    acc[curr] = sourcesState[curr];
                    return acc;
                }, {}
            )
        )

    }

    const selectASource = (source) => {
        let tempObj = Object.keys(sourcesState)?.reduce((acc, key) => {
            return { ...acc, [key]: false}
        }, {});
        tempObj = { ...tempObj, [source]: !sourcesState[source] }

        setSourcesState({ ...tempObj });
    }


    const clearAllSources = () => {
        setSourcesState({})
    }

    const setAllSources = (sourcesArray) => {
        if (sourcesArray !== undefined) {
            let sourcesObj = sourcesArray?.reduce((acc, curr) => {
                acc[curr] = sourcesState[curr];
                return acc;
            }, {});
            setSourcesState({ ...sourcesObj });
        }

    }

    return (
        <KeywordsContext.Provider value={
            {
                keywordsState, addAKeyword, removeAKeyword, selectAKeyword, clearAllKeywords, setAllKeywords,
                sourcesState, addASource, removeASource, selectASource, clearAllSources, setAllSources,
            }
        }>
            {props.children}
        </KeywordsContext.Provider>
    )

}

export default KeywordsContextProvider;

