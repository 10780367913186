import React from 'react'
import { Typography} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#262626',
    minHeight:'75vh',
    padding: theme.spacing(1),
    textAlign: 'initial',
    [theme.breakpoints.down('md')]: {
      borderRadius: 'None',
      padding: 0,
      boxShadow:'None'
    },
}));


function TermsOfService() {
  return (
    <Box sx={{ flexGrow: 1}} mx={{xs:3,md:0}}>
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{minheight:'100vh'}}>
                <Item>
                    <Box px={{xs:0, sm:0, md:3}} mt={{xs:9,md:3}}>
                        <Typography variant="H1_BITTER" component='h1' color="primary">
                            Terms of Use
                        </Typography>

                        <Box mt={2}>
                            <Typography variant="BODY_ARTICLE_RALEWAY" gutterBottom mb={2}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                sunt in culpa qui officia deserunt mollit anim id est laborum
                            </Typography>
                        </Box>
                    </Box>
                </Item>
            </Grid>
        </Grid>
    </Box>
  )
}

export default TermsOfService