import { SEARCH_RESULTS_SOURCES_SUCCESS } from "../actions/sources";

const initialState = {
    type: 'source',
    items: [],
}


export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_SOURCES":
            return {
                ...state,
                items: action.payload
            }
        case "SOURCE_CLICKED":
            //console.log("source clicked", action.payload);
            const updatedItems = state.items.map(
                (item) => item.id === action.payload.id ? 
                        { ...item, isSelected: !item.isSelected, type: state.type } : 
                        {...item, isSelected: false, type: state.type}
            ); 
            return {
                ...state,
                items: updatedItems,
            }
        case "CLEAR_ALL_SOURCES":
            return {
                ...state,
                items: [],
            };
        case "SOURCE_REMOVED":
            return {
                ...state,
                items: state.items.filter(item => item.id !== action.payload.id), 
            }
        case "SOURCE_ADDED":
            //console.log("SOURCE ADDED", action.payload); 
            let sourceTitle = action.payload; 
            if(state.items.filter(obj => obj.title === sourceTitle).length === 0){

                let updatedItems = [...state.items, { id: action.payload, title: action.payload, isSelected: false, type: state.type }];           
                
                //console.log(updatedItems); 
                return {
                    ...state,
                    items: [...new Set([...updatedItems])],   
                };
            } else {
                return state
            }  
        case "RESET_KEYWORDS":
            return initialState
        default:
            return state;
    }
};


// case "GET_SOURCES":
//     return {
//         ...state,
//         items: action.payload
//     }