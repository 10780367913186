import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Card, Hidden, Box, Button, Avatar, Typography, CircularProgress, SwipeableDrawer, AppBar, Toolbar } from "@mui/material";
import { PublicJCIcon, PrivateJCIcon } from '../../assets/SVG';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Row, Spacer, TopDrawer } from '../../common/components';
import ProfileMenu from '../../common/containers/ProfileMenu';
import { Graduate } from "../../assets/SVG";
import { JC_COLORS } from '../../styles/constants';
import ArticlesList from '../Feed/containers/ArticlesList';
import { getJCUserDetails, getJournalClubDetails, setJCContent } from '../../state/actions/journalclub';
import { AuthContext } from '../../context/AuthContext';
import * as api from "../../api"
import SocialMediaIcons from './components/SocialMediaIcons';
import CourseDirectors from './containers/CourseDirectors';
import PartnersAffiliates from './containers/PartnersAffiliates';
import AccreditorsAndSupporters from './containers/AccreditorsAndSupporters';
import JCActions from './containers/JCActions';
import { profileCheck, pxToRem } from '../../utils';
import SquareAvatar from "./components/SquareAvatar";
import CMEAccreditingClub from '../../common/components/CMEAccreditingClub';
import JCContent from './JCContent';
//import MobileLayoutMultiTray from './containers/JCDetailsMultiTrayMobile';
import { useTheme } from '@mui/material/styles';
import { JCDetails } from './JCDetails';
import { getUserDetails } from '../../state/actions/user';
import UserProfileCheck from '../UserProfileCheck';
import GuestViewJCPage from '../GuestPages/GuestViewJCPage';
import { JC_CONTENT, MEMBERSHIP_STATUS, REPORTS_NAV } from '../../constants/constants';


const MainCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: JC_COLORS.JC_WHITE,
  //minHeight: "500px",
  [theme.breakpoints.up('md')]: {
    //width: "73vw", // 1369 / 1920
    //maxHeight: "1203px",  // 1203 / 1377 or 1203 to rem 
    padding: "40px 40px 16px 40px",
    marginBottom: "10px",
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 1rem 0 1rem',
    minHeight: '100vh'
  }

}));

const JCPage = () => {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const { isAuthenticated } = auth;

  const dispatch = useDispatch();

  const location = useLocation();
  const page = location.pathname.replace(/\//, '');

  const currentUrl = location.pathname;
  const urlSlug = location.pathname.replace(/\/jc\//, '');

  const { jcDetails, jcContent, jcUserDetails } = useSelector((state) => state.journalclub);
  const { reportNav } = useSelector((state) => state.jcreports);
  const { JCID } = jcDetails;


  useEffect(() => {
    if (userEmail) {
      dispatch(getJournalClubDetails(urlSlug, userEmail));
      dispatch(getJCUserDetails(urlSlug, userEmail)); 
      dispatch(setJCContent(JC_CONTENT.DEFAULT)); 
    } else {
      console.log("fetch jc public url");
    }

  }, [urlSlug]);

 
  

  return (
    <>
      {
        (
          (userEmail === null || userEmail === undefined) ||
          (isAuthenticated === false)
        ) && (
          <GuestViewJCPage />
        )
      }
      {
        userEmail && (
          <UserProfileCheck>
            <Hidden mdDown>
              <Box
                sx={
                  (
                    reportNav === REPORTS_NAV.MEMBER ||
                    jcContent === JC_CONTENT.EDIT_JC_INFO ||
                    jcContent === JC_CONTENT.MANAGE_MEMBERS ||
                    jcContent === JC_CONTENT.MANAGE_ACCREDITORS_AND_SUPPORTERS ||
                    jcContent === JC_CONTENT.EDIT_JC_MEMBER ||
                    jcContent === JC_CONTENT.INVITE_MEMBER ||
                    jcContent === JC_CONTENT.JC_MEMBER
                  ) && { display: "none" }
                }
              >
                <JCDetails />
                <Spacer height={"11px"} />
              </Box>
              <MainCard>
                <JCContent JCID={JCID} />
              </MainCard>
            </Hidden>
            <Hidden mdUp>
              <JCDetails />
              <MainCard>
                {jcDetails.membershipStatus === MEMBERSHIP_STATUS.JOINED && <Spacer height={"57px"} />}
                {
                  userEmail && (
                    <>
                      <JCActions userType={jcUserDetails.role} />
                      <JCContent JCID={JCID} />
                    </>

                  )
                }

              </MainCard>
            </Hidden>
          </UserProfileCheck>

        )
      }

    </>


  )
}

export default JCPage