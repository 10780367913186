import React from 'react';
import { Helmet } from 'react-helmet';
import og1 from '../assets/og_images/og1.png';

const ArticlePageHelmet = ({ data }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content={data.abstract} />

      <meta property="og:type" content="jcarticle" />
      <meta property="og:title" content={data.title} />
      <meta property="og:description" content={data.abstract} />
      <meta property="og:image" content={og1} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={data.title} />
      <meta name="twitter:description" content={data.abstract} />
      <meta name="twitter:image" content={og1} />
    </Helmet>
  );
};

export default ArticlePageHelmet;
