import {combineReducers} from 'redux';
import keywords from "./keywords";
import sources from "./sources";
import article from "./article";
import user from './user';
import feed from './feed';
import journalclub from './journalclub';
import jcreports from './jcreports';
import comment from './comment'
export default combineReducers({
    article,
    keywords,
    sources,
    user,
    feed,
    journalclub,
    jcreports,
    comment,
});
