import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { Paper } from '@mui/material';
import { Hidden } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
//import Link from '@mui/material/Link';
import { border } from '@mui/system';
import { Chip } from '@mui/material';
import { Avatar } from '@mui/material';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#262626',
    padding: theme.spacing(1),
    textAlign: 'initial',
    [theme.breakpoints.down('md')]: {
      borderRadius: 'None',
      padding: 0,
      boxShadow:'None'
    },

  }));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    tabIndex={-10}
    {...props}/>))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: '#262626',
        backgroundColor:'FFFFFF',
        border:'1px solid #838383',
        borderRadius:'0px'
    },
    '& .MuiMenuItem-root':{
        backgroundColor: '#FFFFFF',
        padding: '8px',
        marginBottom:'1px'
    },
    '& .MuiMenuItem-root:hover':{
        backgroundColor: '#EF5B50',
        color: '#FFFFFF'
    },
    '& .MuiMenu-list': {
      padding: '4px',
    },

}));

export default function ActiveJC(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {journalClubsList,PMID} = props;
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
        <Item sx={{marginTop:{xs:'8px',md:'0px'},marginBottom:{xs:'8px',md:'8px'}}}>
            <Hidden mdDown>
                <Box sx={{display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center'}} mx={3} pt={1} pb={1}>
                    <Box sx={{display:'inline-block',cursor:'pointer'}}
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}>
                      <Typography variant="BODY_COPY2_RALEWAY" color="#838383">
                          This article is also active in your other journal clubs
                      </Typography>
                      <Box sx={{display:'inline-block',position:'absolute',marginTop:'-2px'}}>
                      <KeyboardArrowDownIcon />
                      </Box>
                    </Box>
                    
                </Box>
            </Hidden>
            <Hidden mdUp>
            <Box mt={0} pt={1} sx={{backgroundColor:'#F3F3F3'}}>
                <Box sx={{display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    alignItems: 'center',
                    marginTop: '-10px'
                }} mx={2}>
                    <Box sx={{display:'inline-block'}} mr={1}>
                    <Typography variant="BODY_COPY2_RALEWAY" color="#262626" sx={{fontWeight:'600'}}>
                        This article is active in other journal clubs 
                    </Typography>
                    </Box>
                    <Box sx={{display:'inline-block',marginTop:'10px'}}>
                        <KeyboardArrowDownIcon 
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        />
                    </Box>
                </Box>
            </Box>
            </Hidden>
        </Item>
      
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {journalClubsList && journalClubsList.map((JC,idx) => 
        <MenuItem disableautofocusitem="true" key={"active"+ JC.JCID}>
             <Box sx={{display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                alignItems: 'center'}} onClick={() => window.location.href = `/jc/${JC.urlSlug}/${PMID}`}>
                <Box sx={{display:'inline-block',overflow:'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width:{xs:'200px',md:'250px'}}} mr={2}>
                    <Typography variant="BODY_COPY1_RALEWAY"> {JC.name} </Typography>
                </Box>
                <Box sx={{display:'inline-block'}} pr={1}>
                    <Typography variant="BODY_COPY1_RALEWAY"> {JC.articleStatus === "Under Discussion" ? "In Review" : JC.articleStatus } </Typography>
                </Box>
              </Box>
        </MenuItem>
        )}
      </StyledMenu>
    </React.Fragment>
  );
}
