import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Hidden, Card, CircularProgress, styled } from '@mui/material';
import { CenteredContainer } from '../../common/components';
import ArticlesInDiscussionTab from './jc-content/ArticlesInDiscussionTab';
import PublishedTab from './jc-content/PublishedTab';
import SendEmailTab from './jc-content/SendEmailTab'
import ContributingMembersTab from './jc-content/ContributingMembersTab';
import ContactAdminTab from './jc-content/ContactAdminTab';
import ReportsTab from './jc-content/ReportsTab';
import { setJCContent } from '../../state/actions/journalclub';
import { getJCUserDetails, getJournalClubDetails } from '../../state/actions/journalclub';
import { JC_COLORS } from '../../styles/constants';
import { AuthContext } from '../../context/AuthContext';
import DenovoListingTab from './jc-content/DenovoListingTab';
import CheckingRootReport from './jc-content/CheckingRootReport';
import ManageTab from './jc-content/ManageTab'
import EditJCInfo from './jc-content/manage-content/EditJCInfo';
import ManageMembers from './jc-content/manage-content/ManageMembers';
import { JC_CONTENT } from '../../constants/constants';
import EditJCMember from './jc-content/manage-content/EditJCMember';
import UserReport from './jc-content/report-content/UserReport'

const MainCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: JC_COLORS.JC_WHITE,
  //minHeight: "500px",
  [theme.breakpoints.up('md')]: {
    //width: "73vw", // 1369 / 1920
    //maxHeight: "1203px",  // 1203 / 1377 or 1203 to rem 
    padding: "30px 40px 16px 40px",
    marginBottom: "10px",
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 16px 0 16px'
  }

}));

const JCContent = ({ JCID }) => {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const dispatch = useDispatch();
  const { journalclub } = useSelector((state) => state);
  const { isFetchingDetails, jcDetails, jcUserDetails, jcContent } = journalclub;

  const location = useLocation();
  const currentUrl = location.pathname;
  const urlSlug = location.pathname.replace(/\/jc\//, '');

  return (
    <>
      {isFetchingDetails && <CenteredContainer><CircularProgress /></CenteredContainer>}
      {
        isFetchingDetails === false && (
          <>
            {(jcContent === JC_CONTENT.CONTRIBUTORS) && (<ContributingMembersTab JCID={JCID} />)}
            {(jcContent === JC_CONTENT.CONTACT_ADMIN) && (<ContactAdminTab />)}
            {(jcContent === JC_CONTENT.REPORTS) && (<CheckingRootReport />)}
            {(jcContent === JC_CONTENT.MANAGE) && (<ManageTab userEmail={userEmail}/>)}
            {(jcContent === JC_CONTENT.EDIT_JC_INFO) && (<EditJCInfo />)}
            {(jcContent === JC_CONTENT.EDIT_JC_MEMBER) && (<EditJCMember JCID={JCID} userEmail={userEmail} />)}
            {(jcContent === JC_CONTENT.JC_MEMBER) && (<UserReport userEmail={userEmail} showReport={false} />)}
            {(jcContent === JC_CONTENT.INVITE_MEMBER) && (<EditJCMember isInvite={true} JCID={JCID} userEmail={userEmail} />)}
            {(jcContent === JC_CONTENT.MANAGE_MEMBERS) && (<ManageMembers JCID={JCID} userEmail={userEmail} />)}
            {(jcContent === JC_CONTENT.UNDER_DISCUSSION) && (<ArticlesInDiscussionTab JCID={JCID} urlSlug={urlSlug} />)}
            {(jcContent === JC_CONTENT.PUBLISHED) && (<PublishedTab JCID={JCID} urlSlug={urlSlug} />)}
            {(jcContent === JC_CONTENT.SEND_EMAIL) && (<SendEmailTab JCID={JCID} urlSlug={urlSlug} />)}
            {(jcContent === JC_CONTENT.DE_NOVO_ARTICLES) && (<DenovoListingTab JCID={JCID} urlSlug={urlSlug} jcUserDetails={jcUserDetails} />)}
          </>
        )
      }

    </>
  )
}

export default JCContent