import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Avatar } from '@mui/material';
import Link from '@mui/material/Link';
import Hidden from '@mui/material/Hidden';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { setSupport, setOppose, getArticleVoter } from '../../state/actions/article';
import { AuthContext } from '../../context/AuthContext';

import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import { SupportPositive, SupportedPositive, SupportNegative, SupportedNegative } from '../../assets';
import { JC_COLORS } from '../../styles/constants';
import { getUserDetails } from '../../state/actions/user';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialog-paper': {
            width: '80%',
            height: '180px',
        }
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDialog-paper': {
            width: '30%',
            height: '40%',
        }
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
                backgroundColor: JC_COLORS.JC_RED,
                color: JC_COLORS.JC_WHITE,
            }}
            {...other}
        >
            {children}
            {onClose ? (
                <CloseIcon
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: JC_COLORS.JC_WHITE
                    }}
                />
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#262626',
    padding: theme.spacing(1),
    textAlign: 'initial',
    [theme.breakpoints.down('md')]: {
        borderRadius: 'None',
        padding: 0,
        boxShadow: 'None'
    },
}));

const CenterFlexBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center'
}));

const InlineBox = styled(Box)(({ theme }) => ({
    display: 'inline-block',
}));

const SupporterBox = styled(Box)(({ theme }) => ({
    display: 'inline-block',
    backgroundColor: '#F3F3F3',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
        width: 'calc(50% - 16px)',
        minWidth: 'calc(50% - 16px)',
    }

}));

function SuppotArticle() {
    const theme = useTheme();
    const mobileMatch = useMediaQuery(theme.breakpoints.down('md'))
    const boxWidth = mobileMatch ? "88vw" : "55vw"
    const [open, setOpen] = React.useState(false)
    const [isOpposeModal, setOpposeModal] = React.useState(false)
    const { JCID } = useParams()
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const dispatch = useDispatch();
    const {
        PMID,
        numberOfSupporters,
        numberOfOpposers,
        noteAddedByUser,
        supportedByUser,
        opposedByUser,
        likedByUser,
    } = useSelector((state) => state.article.articleData);
    const {
        supporterList, opposerList
    } = useSelector((state) => state.article);

    const { user } = useSelector((state) => state);
    useEffect(() => {
        dispatch(getUserDetails(auth.user.email))
        if (showSupporterList(numberOfSupporters, supportedByUser)) {
            dispatch(getArticleVoter(JCID, PMID, userEmail, "supporters"))
        }
        if (showOpposerList(numberOfOpposers, opposedByUser)) {
            dispatch(getArticleVoter(JCID, PMID, userEmail, "opposers"))
        }
    }, [])

    const showSupporterList = (numberOfSupporters, supportedByUser) => {
        if (numberOfSupporters) {
            if (numberOfSupporters == 1 && supportedByUser) {
                return false
            }
            else {
                return true
            }
        }
        else {
            return false
        }
    }
    const showOpposerList = (numberOfOpposers, opposedByUser) => {
        if (numberOfOpposers) {
            if (numberOfOpposers == 1 && opposedByUser) {
                return false
            }
            else {
                return true
            }
        }
        else {
            return false
        }
    }
    const handleSupportClick = () => {
        dispatch(setSupport(JCID, PMID, userEmail, supportedByUser, opposedByUser, likedByUser))
    }

    const handleOpposeClick = () => {
        dispatch(setOppose(JCID, PMID, userEmail, supportedByUser, opposedByUser))
    }

    const handleSupporterOpenClick = () => {
        setOpen(true)
        setOpposeModal(false)
    }
    const showMoreVoter = (numberOfVoters) => {
        if ((numberOfVoters - 1) > 0) {
            return true
        }
        else {
            return false
        }
    }
    const handleOpposerOpenClick = () => {
        setOpen(true)
        setOpposeModal(true)
    }
    const handleSupporterCloseClick = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>

            <Item sx={{ border: '2px solid #EF5B50', marginBottom: '16px', marginLeft: { xs: '16px', md: '0px' }, marginRight: { xs: '16px', md: '0px' } }}>
                <Box id="Support" px={{ xs: 2, sm: 2, md: 3 }} py={{ xs: 2, sm: 2, md: 2 }}>

                    <Box>
                        <Typography variant="H2_RALEWAY" sx={{ fontWeight: '700' }}>
                            This article is in consideration for this Journal Club. Would you like to support it?
                        </Typography>
                    </Box>

                    <Box>
                        <Box sx={{ display: 'inline-block' }}>
                            <CenterFlexBox mt={2} mb={1}>

                                <InlineBox mr={2}>
                                    <CenterFlexBox onClick={handleSupportClick} sx={{ cursor: 'pointer' }}>
                                        <InlineBox mr={2}>
                                            {supportedByUser ?
                                                <SupportedPositive width='30px' height="29px" />
                                                :
                                                <SupportPositive width='30px' height="29px" />
                                            }
                                        </InlineBox>
                                        <InlineBox>
                                            <Typography variant="H2_RALEWAY" color="#565656">
                                                Yes
                                            </Typography>
                                        </InlineBox>
                                    </CenterFlexBox>
                                </InlineBox>

                                <InlineBox mr={2}>
                                    <CenterFlexBox onClick={handleOpposeClick} sx={{ cursor: 'pointer' }}>
                                        <InlineBox mr={2}>
                                            {opposedByUser ?
                                                <SupportedNegative width='30px' height="29px" />
                                                :
                                                <SupportNegative width='30px' height="29px" />
                                            }
                                        </InlineBox>
                                        <InlineBox>
                                            <Typography variant="H2_RALEWAY" color="#565656">
                                                NO
                                            </Typography>
                                        </InlineBox>
                                    </CenterFlexBox>
                                </InlineBox>
                            </CenterFlexBox>
                        </Box>

                        <Box mb={1}>
                            <Typography variant="BODY_COPY2_RALEWAY" color="#565656">
                                Only visible to peer evaluators
                            </Typography>
                        </Box>

                        {showSupporterList(numberOfSupporters, supportedByUser) &&
                            <SupporterBox mr={{ xs: 0, md: 2 }}>
                                <InlineBox mr={2} pt={1} pl={2} pr={2}>
                                    <Typography variant="H3_RALEWAY">
                                        Supported By
                                    </Typography>
                                </InlineBox>
                                <CenterFlexBox pt={1} pb={1} pl={2} pr={2}>
                                    <InlineBox mr={2}>
                                        <Avatar variant="square" alt="Benjamin Scricia" src={""} sx={{ height: '38px', width: '38px',borderRadius:'2px!important' }}></Avatar>
                                    </InlineBox>
                                    {supporterList[0] &&
                                        <InlineBox mr={{ xs: 2, md: 2 }}>
                                            <Typography variant="H3_RALEWAY">
                                                {supporterList[0].firstName + " " + supporterList[0].lastName}
                                            </Typography>
                                        </InlineBox>
                                    }
                                    {showMoreVoter(numberOfSupporters) &&
                                        <Box sx={{ display: 'inline-block', cursor: 'pointer' }} onClick={handleSupporterOpenClick}>
                                            <Link variant="BODY_COPY1_RALEWAY" color='#262626'>
                                                <Typography variant="BODY_COPY2_RALEWAY" sx={{ fontWeight: '700!important' }}>
                                                    {"+" + (numberOfSupporters - 1) + " More"}
                                                </Typography>
                                            </Link>
                                        </Box>
                                    }
                                </CenterFlexBox>
                            </SupporterBox>
                        }
                        {showOpposerList(numberOfOpposers, opposedByUser) &&
                            <SupporterBox mt={{ xs: 1, md: 0 }}>
                                <InlineBox mr={2} pt={1} pl={2} pr={2}>
                                    <Typography variant="H3_RALEWAY">
                                        Opposed By
                                    </Typography>
                                </InlineBox>
                                <CenterFlexBox pt={1} pb={1} pl={2} pr={2}>

                                    <InlineBox mr={2}>
                                        <Avatar variant="square" alt="Benjamin Scricia" src={""} sx={{ height: '38px', width: '38px',borderRadius:'2px!important' }}></Avatar>
                                    </InlineBox>
                                    {opposerList[0] &&
                                        <InlineBox mr={{ xs: 2, md: 2 }}>
                                            <Typography variant="H3_RALEWAY">
                                                {opposerList[0].firstName + " " + opposerList[0].lastName}
                                            </Typography>
                                        </InlineBox>
                                    }
                                    {showMoreVoter(numberOfOpposers) &&
                                        <Box sx={{ display: 'inline-block', cursor: 'pointer' }} onClick={handleOpposerOpenClick}>
                                            <Link variant="BODY_COPY1_RALEWAY" color='#262626'>
                                                <Typography variant="BODY_COPY2_RALEWAY" sx={{ fontWeight: '700!important' }}>
                                                    {"+" + (numberOfOpposers - 1) + " More"}
                                                </Typography>
                                            </Link>
                                        </Box>
                                    }
                                </CenterFlexBox>
                            </SupporterBox>
                        }

                    </Box>

                </Box>
            </Item>

            {/*Pop-up for supporters */}

            <BootstrapDialog
                onClose={handleSupporterCloseClick}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleSupporterCloseClick} >
                    <Typography variant="H3_RALEWAY" color={JC_COLORS.JC_WHITE} pl={1}>
                        {isOpposeModal ?
                            "This Article is Opposed By"
                            :
                            "This Article is Supported By"
                        }
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent>
                    <Box pl={2} pt={2}>

                        {supportedByUser && !isOpposeModal &&
                            <CenterFlexBox>
                                <InlineBox mr={2}>
                                    <Avatar
                                        variant="square"
                                        alt={"Y"}
                                        src={""}
                                        sx={{
                                            height: '38px',
                                            width: '38px',
                                            borderRadius:'2px!important'
                                        }}
                                    >
                                        {user?.userDetails?.firstName?.length > 0 ? user?.userDetails?.firstName[0] : ""}
                                    </Avatar>
                                </InlineBox>
                                <InlineBox mr={{ xs: 2, md: 2 }}>
                                    <Typography variant="H3_RALEWAY">
                                        You
                                    </Typography>
                                </InlineBox>
                            </CenterFlexBox>
                        }

                        {opposedByUser && isOpposeModal &&
                            <CenterFlexBox>
                                <InlineBox mr={2}>
                                    <Avatar
                                        variant="square"
                                        alt={"Y"}
                                        src={""}
                                        sx={{
                                            height: '38px',
                                            width: '38px',
                                            borderRadius:'2px!important'
                                        }}
                                    >
                                        {user?.userDetails?.firstName?.length > 0 ? user?.userDetails?.firstName[0] : ""}
                                    </Avatar>
                                </InlineBox>
                                <InlineBox mr={{ xs: 2, md: 2 }}>
                                    <Typography variant="H3_RALEWAY">
                                        You
                                    </Typography>
                                </InlineBox>
                            </CenterFlexBox>
                        }

                        {isOpposeModal && (opposerList && opposerList.length > 0) &&
                            opposerList.map((opposer, idx) =>
                                <CenterFlexBox mt={1} key={idx}>
                                    <InlineBox mr={2}>
                                        <Avatar
                                            variant="square"
                                            alt="Benjamin Scricia"
                                            src={""}
                                            sx={{
                                                height: '38px',
                                                width: '38px',
                                                borderRadius:'2px!important'
                                            }}
                                        >
                                            {opposer?.firstName?.length > 0 ? opposer?.firstName[0] : ""}
                                        </Avatar>
                                    </InlineBox>
                                    <InlineBox mr={{ xs: 2, md: 2 }}>
                                        <Typography variant="H3_RALEWAY">
                                            {opposer?.firstName + " " + opposer?.lastName}
                                        </Typography>
                                    </InlineBox>
                                </CenterFlexBox>

                            )}

                        {!isOpposeModal && (supporterList && supporterList.length > 0) &&
                            supporterList.map((supporter, idx) =>
                                <CenterFlexBox mt={1} key={idx}>
                                    <InlineBox mr={2}>
                                        <Avatar
                                            variant="square"
                                            alt={supporter?.firstName + " " + supporter?.lastName}
                                            src={""}
                                            sx={{
                                                height: '38px',
                                                width: '38px',
                                                borderRadius:'2px!important'
                                            }}
                                        >
                                            {supporter?.firstName?.length > 0 ? supporter?.firstName[0] : ""}
                                        </Avatar>
                                    </InlineBox>
                                    <InlineBox mr={{ xs: 2, md: 2 }}>
                                        <Typography variant="H3_RALEWAY">
                                            {supporter?.firstName + " " + supporter?.lastName}
                                        </Typography>
                                    </InlineBox>
                                </CenterFlexBox>

                            )}

                    </Box>
                </DialogContent>
            </BootstrapDialog>




        </React.Fragment>
    )
}

export default SuppotArticle