import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Avatar,
  Link,
  InputAdornment,
  Backdrop,
} from '@mui/material';
import CustomDropdownDateRange from '../../../Feed/components/CustomDropdownDateRange';
import { MemberHeadCell } from './ReportHelpers';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { CommentedIcon, LikedIcon, NotedIcon, ClubIcon, PublishIcon, ViewsIcon, SupportArticle, DenovoReadIcon } from '../../../../assets'
import ProposeIcon from '../../../../assets/ProposeIcon';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useSelector, useDispatch } from "react-redux";
import { fetchJCReports, fetchUserRoport, postInviteAction } from '../../../../api';
import { JC_COLORS } from '../../../../styles/constants';
import { updateReportNav, getMemberData, updateCurrentTab, updateMemberId } from '../../../../state/actions/jcreports';
import CustomDropdown from '../../../JCDetails/components/CustomDropdown';
import ReportTable from './ReportTable';
import SearchIcon from '@mui/icons-material/Search';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import { JC_CONTENT,MEMBERSHIP_STATUS,REPORTS_NAV, REPORT_ROWS_PER_PAGE } from '../../../../constants/constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { ReactComponent as Licensed } from '../../../../assets/Licensed.svg';

import CircularProgress from '@mui/material/CircularProgress';

import PracticeIcon from '../../../../assets/PracticeIcon';
import { fetchEngagementReports } from '../../../../api';
import { CenterFlexBox, ArtcleTitleCell, InstitutionCell, InlineBox, SummaryBox, StyledTextField, StyledSearchButton, DownloadReportButton, EnhancedTableHead, EngagementHeadCells, BlackDot } from './ReportHelpers';
import { CenteredContainer } from '../../../../common/components';
import { setJCContent } from "../../../../state/actions/journalclub";
import ConfirmModal from '../manage-content/ConfirmModal';

const UserReport = ({userEmail,showReport= true}) => {
  const { currentMemberData } = useSelector((state) => state.jcreports);
  const { reportRootFilter, rootDateRange } = useSelector((state) => state.jcreports);
  const [dateRangeLabel, setDateRangeLabel] = useState(reportRootFilter)
  const [value, setValue] = useState('')
  const [memberData, setMemberData] = useState({})
  const [dateRange, setDateRange] = useState(rootDateRange)
  const [SearchText, setSearchText] = useState("")
  const [confirmModal,setConfirmModal] = useState(false)
  const [rootLoading,setRootLoading] = useState(true)
  const dispatch = useDispatch()
  const { jcDetails } = useSelector((state) => state.journalclub);
  const { currentTab } = useSelector((state) => state.jcreports);
  const [queryParameters, setQueryParameters] = useState(null)


  // report table states
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState(true)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(1)
  };

  const handleRowClick = (event) => {
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const emptyRows = 0

  const handleSearch = () => {
    setIsLoading(true)
    setSearchText(value)
    var queryParams = {
      authUserEmail: userEmail,
      numRecordsPerPage: REPORT_ROWS_PER_PAGE,
      pageNumber: 1,
      sort: order,
      orderBy: orderBy,
      term: value
    }
    setQueryParameters(queryParams)
    fetchData(queryParams)
  }

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  },[rootLoading])

  useEffect(() => {
    setIsLoading(true)
    if(!memberData){
      setRootLoading(true)
    }
    var queryParams = {
      authUserEmail: userEmail,
      numRecordsPerPage: REPORT_ROWS_PER_PAGE,
      pageNumber: page,
      sort: order,
      orderBy: orderBy,
    }
    if(SearchText !== ""){
      queryParams = { ...queryParams, term: SearchText }
    }
    setQueryParameters(queryParams)
    fetchData(queryParams)
  }, [dateRange, page, order, orderBy, SearchText])

  const fetchData = (queryParams) => {
    if (dateRange.startDate && dateRange.endDate) {
      queryParams = { ...queryParams, ...dateRange }
      setQueryParameters(queryParams)
    }
    const MemberId = currentMemberData.memberId ? currentMemberData.memberId : currentMemberData.id
    fetchUserRoport(jcDetails?.JCID, queryParams, MemberId).then(response => {
      if (response?.data) {
        setMemberData(response.data)
        setIsLoading(false)
        setRootLoading(false)
      }
    }).catch(error => {
      console.error(error)
    })
  }

  const handleAcceptRequest = () => {
    // Todo: Accept request
    postInviteAction(jcDetails?.JCID,memberData?.member?.email,"accept",{"authUserEmail":userEmail}).then(res => {
      dispatch(updateCurrentTab(JC_CONTENT.MANAGE_MEMBERS))
      dispatch(updateMemberId(memberData?.member))
      dispatch(setJCContent(JC_CONTENT.EDIT_JC_MEMBER))
    }).catch(error => {
      console.error(error)
    })
  }

  const handleRejectRequest = () => {
    // Todo: Reject request
    postInviteAction(jcDetails?.JCID,memberData?.member?.email,"reject",{"authUserEmail":userEmail}).then(res => {
      dispatch(setJCContent(JC_CONTENT.MANAGE_MEMBERS))
    }).catch(error => {
      console.error(error)
    })
  }

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'left' }} my={-1} mx={-0.8}>
        { showReport &&
        <Link mr={2} sx={{ cursor: 'pointer', textDecoration: 'None' }} onClick={() => dispatch(updateReportNav("Reports"))} className='report-nav-root_text' >
          <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
          <Typography variant="BODY_COPY2_RALEWAY">
            {REPORTS_NAV.ROOT}
          </Typography>
        </Link>
        }
        { !showReport &&
          <Link sx={{ cursor: 'pointer', textDecoration: 'None'}} onClick={() => {dispatch(setJCContent(JC_CONTENT.MANAGE))}} className='report-nav-root_text'>
              <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
              <Typography variant="BODY_COPY2_RALEWAY" sx={{textTransform: 'capitalize'}}>
                  {JC_CONTENT.MANAGE.toLowerCase()}
              </Typography>
          </Link>
        }
        <Link sx={{ cursor: 'pointer', textDecoration: 'None' }} onClick={() => showReport ? dispatch(updateReportNav(currentTab)) : dispatch(setJCContent(currentTab))} className='report-nav-root_text'>
          <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
          <Typography variant="BODY_COPY2_RALEWAY" sx={{textTransform: 'capitalize'}}>
            {currentTab.toLowerCase().replaceAll("_"," ")}
          </Typography>
        </Link>
      </Box>
      <Box sx={{ textAlign: 'left' }} mt={3}>
        <Typography variant="H1_BITTER" color="#838383" sx={{ fontWeight: '700 !important' }}>
          {jcDetails.name}
        </Typography>
      </Box>

      {rootLoading && 
        <Box sx={{minHeight:'400px'}}>
          <CenteredContainer><CircularProgress /></CenteredContainer>
        </Box>
      }

      {/* Member Profile */}
      {/* {isLoading && <CenteredContainer><CircularProgress /></CenteredContainer>} */}
      {memberData && memberData.member &&
        <Grid container mt={3}>
          <Grid item xs={2.5} >
            <Avatar variant="square" alt="Benjamin Scricia" src={""}
              sx={{
                height: "170px",
                width: "170px",
                borderRadius: "2px!important",
                fontSize: "100px"
              }}
            >{memberData.member.term[0].toUpperCase()}</Avatar>
          </Grid>

          <Grid item xs={9.5} sx={{ textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>

            <Box ml={4.5} sx={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
              <Typography variant="H1_BITTER" color="#262626" sx={{ fontWeight: '700', flex: '80%' }}>
                {memberData.member.term}
              </Typography>
              {memberData.member.is_licensed_professional &&
                <Box sx={{ backgroundColor: JC_COLORS.JC_RED, display: "flex", alignItems: 'center', padding: "5px 5px 5px 8px", marginRight: "36px", flex: "45%", height: "30px", borderRadius: "4px 0 0 4px" }}>
                  <Licensed width={"31px"} height={'25px'} />
                  <Typography variant="H3_RALEWAY" color="#ffffff" sx={{ marginLeft: "5px", fontWeight: '700', marginRight: '5px' }}>
                    Licensed Professional
                  </Typography>
                </Box>}
            </Box>
            <Box ml={4.5} mt={2}>
              <Typography variant="H2_RALEWAY" color="#262626" sx={{ fontWeight: '600' }}>
                {memberData.member.institution}
              </Typography>
            </Box>
            <Box ml={4.5} mt={2}>
              <Typography variant="BODY_ARTICLE_RALEWAY" color="#838383" sx={{ fontWeight: '600' }}>
                {memberData.member.role}
              </Typography>
            </Box>
            <Box ml={4.5} mt={2}>
              <InlineBox mr={3}>
                <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '700' }}>
                  {memberData.member.profession}
                </Typography>
              </InlineBox>
              <BlackDot size={"10px"} />
              <InlineBox ml={3}>
                <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '600' }}>
                  {memberData.member.country}
                </Typography>
              </InlineBox>
            </Box>
          </Grid>
          {/*Memeber Description */}
          <Box mt={3} sx={{ textAlign: 'left' }} mb={showReport ? 0 : 3}>
            <Typography variant="BODY_ARTICLE_RALEWAY" >
              {memberData.member.about ? memberData.member.about : ""}
            </Typography>
          </Box>
        </Grid>
      }
      {memberData && memberData.member && memberData.member.status === MEMBERSHIP_STATUS.REQUESTED &&
      <Box mt={1}>
        <Divider />
        <Box sx={{textAlign:'left'}} mt={2}>
          <MessageIcon sx={{ position: 'relative', top: '8px' }}/>
          <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '700!important',marginLeft:'8px' }}>
            MEMBERSHIP REQUEST NOTE BY USER
          </Typography>
          <Box mt={2} mb={15}>
            <Typography variant="BODY_ARTICLE_RALEWAY" >
              {memberData.member.requestNote ?? " "}
            </Typography>
          </Box>
        </Box>
      </Box>
      }
      {memberData && memberData.member && memberData.member.status !== MEMBERSHIP_STATUS.REQUESTED &&
      <>
      {/* Member Summary */}
      <Box sx={{ borderTop: '2.5px solid #E1E1E1', textAlign: 'left', paddingTop: '16px' }} mt={2} mb={2}>
        <InlineBox sx={{ float: 'right' }}>
          <CustomDropdownDateRange isHeading={false} isWhite={true}
            dateRangeLabel={dateRangeLabel}
            changeDateRangeLabel={(value) => setDateRangeLabel(value)}
            changeDateRangeHandler={(value) => setDateRange(value)}
          />
        </InlineBox>
      </Box>
      <Grid container spacing={{ xs: 1.5 }} sx={{ backgroundColor: JC_COLORS.JC_RED, borderRadius: '4px', padding: '24px', width: '100%!important', margin: '0px !important' }}>
        <Grid item xs={12} sx={{ paddingTop: '0px!important' }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <SummaryBox numberOf={memberData.numAllArticlesRead} title="Articles Read"> <ViewsIcon width="28px" height="28px" /> </SummaryBox>
            </Grid>
            <Grid item xs={4}>
              <SummaryBox numberOf={memberData.numAllCMECredits} title="CME/CE Credits Accumulated" isfloat={true}> <ClubIcon width="28px" height="28px" />  </SummaryBox>
            </Grid>
            <Grid item xs={4}>
              <SummaryBox numberOf={memberData.numAllArticlesProposed} title="Articles Proposed"> <ProposeIcon className="white-icon" />  </SummaryBox>
            </Grid>
            <Grid item xs={4}>
              <SummaryBox numberOf={memberData.numAllArticlesPublished} title="Articles Published"> <PublishIcon width="28px" height="28px" />  </SummaryBox>
            </Grid>
            <Grid item xs={4}>
              <SummaryBox numberOf={memberData.numAllStartedDenovoArticles} title="Started Orignial Articles"> <DenovoReadIcon width="28px" height="28px" />  </SummaryBox>
            </Grid>
            <Grid item xs={4}>
              <SummaryBox numberOf={memberData.numAllArticlesLikes} title="Total Likes"> <LikedIcon className="white-icon" /> </SummaryBox>
            </Grid>
            <Grid item xs={4}>
              <SummaryBox numberOf={memberData.numAllArticlesComments} title="Total Comments"> <CommentedIcon className="white-icon" /> </SummaryBox>
            </Grid>
            <Grid item xs={4}>
              <SummaryBox numberOf={memberData.numAllArticlesNotes} title="Total Notes"> <NotedIcon className="white-icon" /> </SummaryBox>
            </Grid>
            <Grid item xs={4}>
              <SummaryBox numberOf={memberData.numAllArticlesPI} title="Total Practise Impacting Points"> <PracticeIcon width="28px" height="28px" fillcolor="#FFFFFF" /> </SummaryBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/*Membership Summary Ends */}
      <Box mt={3} sx={{ textAlign: 'left' }}>
        {memberData &&
          <Typography variant="BODY_ARTICLE_RALEWAY" sx={{ fontWeight: '700!important', marginRight: '12px' }}> {memberData.numberOfRow !== 0 ? memberData.numberOfRow && (memberData.numberOfRow !== 1 ? memberData.numberOfRow + " Articles Found" : memberData.numberOfRow + " Article Found") : "No Result Found"}</Typography>
        }
        {isLoading &&
          <CircularProgress color='primary' sx={{ width: '20px!important', height: '20px!important' }} />
        }
      </Box>

      <Box sx={{ textAlign: 'left' }} mt={4} mb={1}>
        <InlineBox>
          <StyledTextField
            value={value}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch()
              }
            }}
            placeholder={"Search Article"}
            autoComplete='off'
            inputProps={{
              style: {
                fontFamily: 'Raleway',
                fontWeight: 600,
              }
            }}
            InputProps={{
              startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                  <CloseIcon style={{ display: `${value === "" ? 'none' : 'block'}` }} onClick={() => { setSearchText(""); setValue("") }} />
                </InputAdornment>),
            }}
            onChange={(e) => { setValue(e.target.value ? e.target.value : ""); }}
          ></StyledTextField>
          <StyledSearchButton variant="outlined" onClick={() => handleSearch()} sx={{ marginLeft: '11px' }}>
            Search
          </StyledSearchButton>
        </InlineBox>
        <InlineBox sx={{ float: 'right' }}>
          <DownloadReportButton JCID={jcDetails.JCID} parameter={"member"} queryParameters={queryParameters} currentLoginUser={currentMemberData.memberId} downloadName={"Member_Profile"} dateRange={dateRange} />
        </InlineBox>
      </Box>

      {memberData && memberData.member_articles &&
        <Box mt={3} mb={3}>
          {/* <ReportTable headCells={MemberHeadCell} data={memberData} /> */}
          <Box sx={{ width: '100%' }}>
            <TableContainer>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: '16px',
                    borderRadius: '4px'
                  },
                  minWidth: 750
                }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={MemberHeadCell}
                />

                <TableBody sx={{ position: 'relative' }}>
                  <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute', backgroundColor: 'transparent' }}
                    open={isLoading}
                  >
                    <CircularProgress color='primary' />
                  </Backdrop>
                  {memberData.member_articles.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <React.Fragment>
                        <TableRow
                          hover
                          onClick={(event) => handleRowClick(event)}
                          tabIndex={-1}
                          key={row.name}
                          sx={{
                            "&:hover": {
                              backgroundColor: "#FFF7F6!important"
                            },
                            backgroundColor: "#F3F3F3!important"
                          }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ fontFamily: 'Raleway!important' }}
                          >
                            <ArtcleTitleCell title={row.title} urlSlug={jcDetails.urlSlug} PMID={row.PMID} isDenovoArticle={row.isDenovoArticle} />
                          </TableCell>
                          <TableCell align="center"> {row.cmeCredits}</TableCell>
                          <TableCell align="center">{row.proposed_by === currentMemberData.memberId ? row.proposedStartedOn : "-"}</TableCell>
                          <TableCell align="center"><Typography variant="H3_RALEWAY" color="#262626" sx={row.proposed_by === currentMemberData.memberId ? row.status === "Under Discussion" ? { color: '#EF5B50 !important', fontStyle: "italic" } : row.status === "Rejected" && { fontStyle: "italic" } : { color: "#262626" }}>{row.proposed_by === currentMemberData.memberId ? row.status === "Under Discussion" ? "In-Review" : row.status : "-"}</Typography></TableCell>
                          <TableCell align="center">{row.numberOfComments}</TableCell>
                          <TableCell align="center">{row.numberOfNotes}</TableCell>
                        </TableRow>
                        <Box sx={{ minHeight: '12px' }}></Box>
                      </React.Fragment>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={3} sx={{ marginLeft: 'calc( 50% - 80px)' }}>
              <Pagination count={Math.ceil(memberData.numberOfRow / REPORT_ROWS_PER_PAGE)} color="primary" page={page} onChange={handleChangePage} />
            </Box>
          </Box>
        </Box>
      }
    </>
    }
    {memberData && memberData.member && memberData.member.status === MEMBERSHIP_STATUS.REQUESTED &&
    <Box sx={{ display: 'flex',position:'fixed',bottom:'80px', justifyContent: "center", width: "71.666667%",maxWidth:'1030px',backgroundColor:'#FFF7F6' }} mx={-5} pt={1.5} pb={1.5}>
      <Box sx={{ display: 'inline-block' }}>
        <Button variant='outlined' size="small" color="primary" sx={{fontFamily:'Raleway'}} onClick={() => setConfirmModal(true)}>
          REJECT REQUEST 
        </Button>
      </Box>
      <Box sx={{ display: 'inline-block' }} ml={2}>
        <Button variant='contained' size="small" color="primary" sx={{fontFamily:'Raleway'}} onClick={handleAcceptRequest}>
          ACCEPT REQUEST 
        </Button>
      </Box>
    </Box>
    }

    <ConfirmModal 
      open = {confirmModal}
      onClose = {() => setConfirmModal(false)}
      onCancel = {() => setConfirmModal(false)}
      onProceed = {handleRejectRequest}
      message = {`You are about to reject ${memberData?.member?.term}’s request to join this journal club?`}
    />

    </React.Fragment>
  )
}

export default UserReport

