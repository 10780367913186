import React from 'react';
import { Hidden, Box } from '@mui/material';
import JCContentTitle from '../components/JCContentTitle';

const ContactAdminTab = () => {
  return (
    <>
      <Hidden mdDown>
        <JCContentTitle title={'Contact Admin'} />
        <Box></Box>
      </Hidden>
      <Hidden mdUp>
        <Box></Box>
      </Hidden>
    </>
  )
}

export default ContactAdminTab