import React, { useMemo, useState, useEffect } from 'react';
import { Box, Typography, Grid, Hidden, Avatar, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { Row } from '../../../common/components';
import { styled } from '@mui/material/styles';
import { JC_COLORS } from '../../../styles/constants';
import { pxToRem } from '../../../utils';

import SectionTitle from '../components/SectionTitle';
import AvatarNameRow from '../components/AvatarNameRow';
import AvatarName from '../components/AvatarName';
import SquareAvatar from '../components/SquareAvatar';
import DialogContentCMEDisclosure from '../jc-content/manage-content/DialogContentCMEDisclosure';
import { MEMBERSHIP_STATUS } from '../../../constants/constants';

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',

    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column'

    }

}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogTitle-root': {
        padding: 0,
        margin: 0,
    },
    '& .MuiDialogContent-root': {
        padding: 0,
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            borderRadius: '0px',
            margin: 0,
        }
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDialog-paper': {
            width: '40%',
            height: '68%'
        }
    },
}));



const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        //color: '#bdbdbd',
        color: '#838383'
    },
    [`& .${tooltipClasses.tooltip}`]: {
        //backgroundColor: '#bdbdbd',
        backgroundColor: '#838383'
    },
}));

const AccreditorsAndSupporters = ({
    accreditorsAndSupportersList,
    hideReadDisclosureButton = false

}) => {
    const [openDialog, setOpenDialog] = useState(false);

    const sortedAccreditorsAndSupportersList = useMemo(() => {
        return accreditorsAndSupportersList.sort(
            (institutionA, institutionB) => institutionA?.priority - institutionB?.priority
        )
    }, [accreditorsAndSupportersList]);


    return (
        <>


            <Hidden mdDown>


                <Box
                    sx={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                    }}>
                    {
                        sortedAccreditorsAndSupportersList.length > 0 && (
                            <InfoBox
                                name={`${sortedAccreditorsAndSupportersList[0].name ? sortedAccreditorsAndSupportersList[0].name : ""}`}
                                logoUrl={sortedAccreditorsAndSupportersList[0].logoUrl ?? ""}
                                type={sortedAccreditorsAndSupportersList[0].status}
                            />
                        )
                    }
                    {
                        sortedAccreditorsAndSupportersList.length > 1 && (
                            <InfoBox
                                name={`${sortedAccreditorsAndSupportersList[1].name ? sortedAccreditorsAndSupportersList[1].name : ""}`}
                                logoUrl={sortedAccreditorsAndSupportersList[1].logoUrl ?? ""}
                                type={sortedAccreditorsAndSupportersList[1].status}
                            />
                        )
                    }
                    {
                        sortedAccreditorsAndSupportersList.length > 2 && (
                            <>
                                <InfoBox
                                    name={`${sortedAccreditorsAndSupportersList[2].name ? sortedAccreditorsAndSupportersList[2].name : ""}`}
                                    logoUrl={sortedAccreditorsAndSupportersList[2].logoUrl ?? ""}
                                    type={sortedAccreditorsAndSupportersList[2].status}
                                />
                                <RestOfCMESupporters
                                    names={sortedAccreditorsAndSupportersList.slice(3, sortedAccreditorsAndSupportersList.length)}
                                    openDialogHandler={() => setOpenDialog(true)}
                                />
                                <BootstrapDialog
                                    open={openDialog}
                                    onClose={() => setOpenDialog(false)}
                                >
                                    <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Typography
                                            variant="H3_BITTER"
                                            sx={{
                                                color: JC_COLORS.JC_ICON,
                                                fontWeight: 700,
                                                margin: '38px 0px 0px 38px'
                                            }}
                                        >
                                            Accreditors/Supporters
                                        </Typography>
                                        <IconButton onClick={() => setOpenDialog(false)} sx={{ marginRight: '17px' }}>
                                            <CloseIcon
                                                sx={{
                                                    width: '32px',
                                                    height: '32px',
                                                    color: JC_COLORS.JC_ICON,
                                                }}
                                            />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent
                                        sx={{
                                            marginRight: '17px',
                                            marginLeft: '38px',
                                            '&::-webkit-scrollbar': { width: '6px' },
                                            '&::-webkit-scrollbar-track': { backgroundColor: JC_COLORS.JC_WHITE, borderRadius: '9px', },
                                            '&::-webkit-scrollbar-thumb': { backgroundColor: "#BDBDBD", borderRadius: '9px' },
                                        }}
                                    >
                                        <Box>
                                            <Box sx={{ height: '19px' }}></Box>
                                            {
                                                sortedAccreditorsAndSupportersList.map((obj, i) => (
                                                    <Box key={i} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '9px' }}>
                                                        <SquareAvatar
                                                            name={sortedAccreditorsAndSupportersList[i].name}
                                                            logoUrl={sortedAccreditorsAndSupportersList[i].logoUrl}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Raleway',
                                                                marginLeft: '15px',
                                                                fontSize: pxToRem(16),
                                                                lineHeight: pxToRem(22),

                                                            }}
                                                        >
                                                            {sortedAccreditorsAndSupportersList[i].name}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Raleway',
                                                                marginLeft: '8px',
                                                                fontSize: pxToRem(12),
                                                                lineHeight: pxToRem(16),

                                                            }}
                                                        >
                                                            {sortedAccreditorsAndSupportersList[i].status}
                                                        </Typography>
                                                    </Box>
                                                ))
                                            }

                                        </Box>

                                    </DialogContent>
                                </BootstrapDialog>
                            </>

                        )
                    }
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    {
                        sortedAccreditorsAndSupportersList.map((obj, i) => (
                            <AvatarNameRow
                                key={i}
                                name={`${obj?.name} ` + `(${obj?.status})`}
                                logoUrl={obj?.logoUrl}
                            />
                        ))
                    }
                </Box>
            </Hidden>
        </>


    )
}

export default AccreditorsAndSupporters;

const InfoBox = ({ name, logoUrl, type }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginRight: '12px',
                maxWidth: "25%"

            }}
        >
            <SquareAvatar
                name={name}
                logoUrl={logoUrl}
            >
            </SquareAvatar>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <BootstrapTooltip
                    title={name}
                    placement="top"
                >
                    <div>
                        <AvatarName
                            sx={{
                                display: "-webkit-inline-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {name}
                        </AvatarName>
                    </div>
                </BootstrapTooltip>
                <Typography
                    variant="BODY_COPY2_RALEWAY"
                    color={JC_COLORS.JC_ICON}
                    sx={{
                        fontWeight: '700!important',
                        marginLeft: "15px",
                    }}

                >
                    {type}
                </Typography>
            </Box>
        </Box>
    )

}

const RestOfCMESupporters = ({ names, openDialogHandler }) => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
            {
                names.length > 0 && (
                    <Box onClick={openDialogHandler} sx={{ cursor: 'pointer' }}>
                        <AvatarName sx={{ marginLeft: "0px" }}>{`+${names.length} More`}</AvatarName>
                    </Box>

                )
            }
        </Box>
    );
};


const LogoNameRow = ({ name, logoUrl }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '10px',
                marginRight: '15px',
                maxWidth: '400px',
            }}>
            <Avatar
                variant="square"
                alt={name}
                src={logoUrl}
                sx={{
                    width: '85px',
                    height: '85px',
                    fontSize: '40px',
                    lineHeight: '36px',
                }}>{name.length > 0 ? name[0] : ''}</Avatar>
            <Typography
                sx={{
                    marginLeft: '15px',
                    fontFamily: 'Raleway',
                    fontSize: '24px',
                    lineHeight: '36px',
                    maxWidth: '268px',
                    display: 'inline',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    overflowWrap: 'break-word',
                    textOverflow: `ellipsis`,
                }}>{name}</Typography>
        </Box>
    );
}


/* <Grid 
                        container 
                        justifyContent={"flex-start"}
                    >
                        {
                            sortedAccreditorsAndSupportersList.length > 0 && (
                                <Grid item sx={3}>
                                    <InfoBox
                                        name={`${sortedAccreditorsAndSupportersList[0].name ? sortedAccreditorsAndSupportersList[0].name : ""}`}
                                        logoUrl={sortedAccreditorsAndSupportersList[0].logoUrl ?? ""}
                                        type={sortedAccreditorsAndSupportersList[0].status}
                                    />

                                </Grid>
                            )
                        }
                        {
                            sortedAccreditorsAndSupportersList.length > 1 && (
                                <Grid item sx={3}>
                                    <InfoBox
                                        name={`${sortedAccreditorsAndSupportersList[1].name ? sortedAccreditorsAndSupportersList[1].name : ""}`}
                                        logoUrl={sortedAccreditorsAndSupportersList[1].logoUrl ?? ""}
                                        type={sortedAccreditorsAndSupportersList[1].status}
                                    />

                                </Grid>
                            )
                        }
                        {
                            sortedAccreditorsAndSupportersList.length > 2 && (
                                <Grid item xs={3} sx={{ border: "2px solid red"}}>
                                    <InfoBox
                                        name={`${sortedAccreditorsAndSupportersList[2].name ? sortedAccreditorsAndSupportersList[2].name : ""}`}
                                        logoUrl={sortedAccreditorsAndSupportersList[2].logoUrl ?? ""}
                                        type={sortedAccreditorsAndSupportersList[2].status}
                                    />
                                </Grid>
                            )
                        }
                        {
                            sortedAccreditorsAndSupportersList.length > 3 && (
                                <Grid item sx={3}>
                                    <RestOfCMESupporters
                                        names={sortedAccreditorsAndSupportersList.slice(3, sortedAccreditorsAndSupportersList.length)}
                                        openDialogHandler={() => setOpenDialog(true)}
                                    />
                                    <BootstrapDialog
                                        open={openDialog}
                                        onClose={() => setOpenDialog(false)}
                                    >
                                        <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Typography
                                                variant="H3_BITTER"
                                                sx={{
                                                    color: JC_COLORS.JC_ICON,
                                                    fontWeight: 700,
                                                    margin: '38px 0px 0px 38px'
                                                }}
                                            >
                                                Accreditors/Supporters
                                            </Typography>
                                            <IconButton onClick={() => setOpenDialog(false)} sx={{ marginRight: '17px' }}>
                                                <CloseIcon
                                                    sx={{
                                                        width: '32px',
                                                        height: '32px',
                                                        color: JC_COLORS.JC_ICON,
                                                    }}
                                                />
                                            </IconButton>
                                        </DialogTitle>
                                        <DialogContent
                                            sx={{
                                                marginRight: '17px',
                                                marginLeft: '38px',
                                                '&::-webkit-scrollbar': { width: '6px' },
                                                '&::-webkit-scrollbar-track': { backgroundColor: JC_COLORS.JC_WHITE, borderRadius: '9px', },
                                                '&::-webkit-scrollbar-thumb': { backgroundColor: "#BDBDBD", borderRadius: '9px' },
                                            }}
                                        >
                                            <Box>
                                                <Box sx={{ height: '19px' }}></Box>
                                                {
                                                    sortedAccreditorsAndSupportersList.map((obj, i) => (
                                                        <Box key={i} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '9px' }}>
                                                            <SquareAvatar
                                                                name={sortedAccreditorsAndSupportersList[i].name}
                                                                logoUrl={sortedAccreditorsAndSupportersList[i].logoUrl}
                                                            />
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Raleway',
                                                                    marginLeft: '15px',
                                                                    fontSize: pxToRem(16),
                                                                    lineHeight: pxToRem(22),

                                                                }}
                                                            >
                                                                {sortedAccreditorsAndSupportersList[i].name}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Raleway',
                                                                    marginLeft: '8px',
                                                                    fontSize: pxToRem(12),
                                                                    lineHeight: pxToRem(16),

                                                                }}
                                                            >
                                                                {sortedAccreditorsAndSupportersList[i].status}
                                                            </Typography>
                                                        </Box>
                                                    ))
                                                }

                                            </Box>

                                        </DialogContent>
                                    </BootstrapDialog>

                                </Grid>

                            )
                        }
                    </Grid> */