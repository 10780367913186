export function setToken(key, value) {
  localStorage.setItem([key], value);
}

export function getToken(key) {
  if (localStorage.getItem([key])) {
    return localStorage.getItem([key]);
  }
  return null;
}

export function removeTokenFromStorage(key) {
  localStorage.removeItem([key]);
}
