import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Box, Hidden, Typography, Button, Checkbox, CircularProgress, Link, styled } from '@mui/material';
//Grid, List, ListItem, ListItemIcon, ListItemText, styled, } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import Circle from '@mui/icons-material/Circle';
import ErrorDialogBox from '../common/components/ErrorDialogBox';
import { JC_COLORS } from '../styles/constants';
import { useNavigate } from 'react-router';
import { AuthContext } from '../context/AuthContext';
import { getUserDetails, setIsNewUser } from '../state/actions/user';
import { TERMS_OF_SERVICE_PDF, PRIVACY_POLICY_PDF } from '../constants/constants';
import * as api from '../api';
import { logout } from '../utils';


const HeaderBox = styled(Box)(({ theme }) => ({

    [theme.breakpoints.up('md')]: {
        margin: "0px auto",
        paddingTop: "45px",
    },
    [theme.breakpoints.down('md')]: {
        margin: "0px auto 10px",
        paddingTop: "20px",
    }

}));

const MainText = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    width: "100%",
    borderBottom: '2px solid #E1E1E1',
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H1_BITTER,
        paddingBottom: '24px',
        margin: '20px 0px',
    },
    [theme.breakpoints.down('md')]: {
        ...theme.typography.BODY_COPY1_RALEWAY,
        paddingBottom: '12px',
        margin: '10px 0px',
    }
}));

const JCBillOfRightsText = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    textTransform: 'uppercase',
    color: JC_COLORS.JC_ICON,
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H2_RALEWAY,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: "14px",
        lineHeight: "18px",
    }
}));

const StyledCircleIcon = styled(Circle)(({ theme }) => ({
    color: JC_COLORS.JC_BLACK,
    [theme.breakpoints.up('md')]: {
        width: "12px", height: "12px",
    },
    [theme.breakpoints.down('md')]: {
        width: "6px", height: "6px",
    }
}))

const ListItemsText = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    color: JC_COLORS.JC_BLACK,
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H2_RALEWAY,
        //marginLeft: '24px',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: "12px",
        lineHeight: "18px",
        //marginLeft: '12px',
        marginTop: 0,
        paddingTOp: 0,

    },

}));

const CheckBoxText = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H2_RALEWAY,
        marginLeft: "12px"
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '18px',
        marginLeft: '16px',

    }
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: '26px',
        height: '26px',
    },
    [theme.breakpoints.down('md')]: {
        width: '18px',
        height: '18px',
    }
}))

const ButtonContainer = styled(Box)(({ theme }) => ({

    [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: "35%",
        margin: "20px auto",
        "& > *:nth-child(2)": {
            marginLeft: '20px',
        }

    },
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: "10px auto",
    }
}));



const TermsAndConditionsPage = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);

    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const { user } = useSelector((state) => state);
    const { isFetching, isUpdating, userDetails, isNewUser } = user;

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const location = useLocation();
    const content = location.pathname.replace(/\//, '');

    const [userCreated, setUserCreated] = useState(null);
    const [userUpdated, setUserUpdated] = useState(null);




    const handleAcceptTermsAndConditions = async (acceptedTnC) => {

        const data = {
            firstName: "",
            lastName: "",
            country: "",
            profession: "Administration",
            acceptedTermsAndConditions: acceptedTnC
        }
        if (user.isUpdating === false && isNewUser) {
            //POST 
            try {
                let res = await api.createNewUser({ ...data, userEmail: auth.user.email })
                if (res?.data === "User created") {
                    dispatch(setIsNewUser(false))
                    window.location = "/profile"
                } else {
                    setOpenErrorDialog(true);
                    setErrorMessage("Error While creating user " + res?.data + ` Status: ${res?.status}`);
                }
            } catch (error) {
                setOpenErrorDialog(true);
                setErrorMessage("Error While creating user.... " + error?.response?.data + ` Status: ${error?.response?.status}`);
            }

        } else {
            //PATCH
            try {
                let res = await api.updateUserDetails({ acceptedTermsAndConditions: acceptedTnC }, auth.user.email);
                if (res?.data === "User updated") {
                    window.location = "/profile"
                } else {
                    setOpenErrorDialog(true);
                    setErrorMessage("Error While updating.... " + res.data + ` Status: ${res.status}`);
                }
            } catch (error) {
                setOpenErrorDialog(true);
                setErrorMessage("Error While updating.... " + error.response.data + ` Status: ${error.response.status}`);
            }


        }
    }

    useEffect(() => {
        dispatch(getUserDetails(userEmail))
    }, [content]);

    useEffect(() => {
        if (isFetching === false) {
            setIsChecked(Boolean(userDetails?.acceptedTermsAndConditions));
        }
    }, [isFetching]);





    return (
        <>
            <ErrorDialogBox
                open={openErrorDialog}
                closeDialogHandler={() => setOpenErrorDialog(false)}
                errorMessage={errorMessage}
            />


            <Box sx={{ backgroundColor: "#FFFFFF", height: '100vh' }}>
                <HeaderBox>
                    <Hidden mdDown>
                        <JCHeaderPink width={500} height={65} />
                    </Hidden>

                    <Hidden mdUp>
                        <JCHeaderPink width={300} height={45} />
                    </Hidden>

                </HeaderBox>
                {
                    (user.isFetching) && (
                        <Box sx={{ mt: "10vw" }}><CircularProgress /></Box>
                    )
                }
                {
                    (user.isFetching === false) && (
                        <>
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        width: "80vw",
                                        margin: "0px auto",

                                    }}
                                >
                                    <MainText>
                                        {/* Please read through Terms of Service and Privacy Policy */}
                                    </MainText>


                                    <JCBillOfRightsText>
                                        Journal Club Bill of Rights
                                    </JCBillOfRightsText>
                                    <ul>
                                        <li>
                                            <ListItemsText>
                                                No one will sell data obtained from this platform.
                                            </ListItemsText>
                                        </li>
                                        <li>
                                            <ListItemsText sx={{ listStyle: 'disc outside none' }}>
                                                No one will engage in promotional activity on this platform.
                                            </ListItemsText>
                                        </li>
                                        <li style={{ listStyle: 'disc outside none' }}>
                                            <ListItemsText>
                                                Journal Club, Inc. does not add, modify or influence the content on this platform.
                                            </ListItemsText>
                                        </li>
                                    </ul>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            margin: '0px 0px 0px 4px',
                                        }}
                                    >
                                        <StyledCheckbox
                                            checked={isChecked}
                                            onChange={() => setIsChecked(!isChecked)}
                                            disabled={Boolean(userDetails?.acceptedTermsAndConditions)}
                                        />
                                        <CheckBoxText>
                                            By checking this box, I understand and agree to the <Link target="_blank" href={TERMS_OF_SERVICE_PDF} sx={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}>Terms Of Service</Link> as well as the <Link target="_blank" href={PRIVACY_POLICY_PDF} sx={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }} >Privacy Policy</Link> of this site.
                                        </CheckBoxText>


                                    </Box>
                                </Box>
                                {
                                    Boolean(userDetails?.acceptedTermsAndConditions) === false && (
                                        <ButtonContainer>
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                //onClick={() => navigate("https://www.journal.club/")}
                                                sx={{ margin: '6px 0px 0px 0px', minWidth: "200px", width: 'fit-content' }}
                                                onClick={
                                                    () => {
                                                        setIsChecked(false);
                                                    }
                                                }
                                            >
                                                DON'T ACCEPT
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                disabled={
                                                    isChecked === false
                                                }
                                                onClick={() => handleAcceptTermsAndConditions(isChecked)}
                                                sx={{ margin: '6px 0px 0px 0px', minWidth: "200px", width: 'fit-content' }}
                                            >
                                                Accept and Continue
                                            </Button>
                                        </ButtonContainer>

                                    )
                                }
                                {
                                    Boolean(userDetails?.acceptedTermsAndConditions) && (
                                        <ButtonContainer>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                //onClick={() => navigate("https://www.journal.club/")}
                                                sx={{ margin: '6px 0px 0px 0px', minWidth: "200px", width: 'fit-content' }}
                                                onClick={
                                                    () => {
                                                        window.location = "/profile"
                                                    }
                                                }
                                            >
                                                DONE
                                            </Button>
                                        </ButtonContainer>

                                    )
                                }


                            </Box>
                            <Box
                                sx={{
                                    width: '80vw',
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '10vw',
                                    backgroundColor: JC_COLORS.JC_WHITE,
                                    paddingTop: '16px',
                                    borderTop: '2px solid #E1E1E1',
                                }}
                            >
                                <Typography
                                    variant="BODY_COPY2_RALEWAY"
                                    sx={{
                                        textAlign: 'center',
                                    }}
                                    gutterBottom
                                >
                                    Journal Club &copy; 2022
                                </Typography>

                            </Box>

                        </>

                    )
                }


            </Box>




        </>

    )
}

export default TermsAndConditionsPage

const JCHeaderPink = ({ width, height }) => {
    return (
        <svg width={width ?? "649"} height={height ?? "97"} viewBox="0 0 649 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4449_23527)">
                <path d="M39.178 67.6575H30.1089V37.5875H-0.0849609V28.5556H39.178V67.6575Z" fill="#EF5B50" />
                <path d="M31.4377 29.8792L-0.0361328 61.2238L6.37669 67.6104L37.8505 36.2657L31.4377 29.8792Z" fill="#EF5B50" />
                <path d="M66.9022 67.6575H57.8331V9.97714H-0.0849609V0.945251H66.9022V67.6575Z" fill="#EF5B50" />
                <path d="M59.1586 2.26632L31.29 30.0205L37.7029 36.407L65.5714 8.65282L59.1586 2.26632Z" fill="#EF5B50" />
                <path d="M19.7329 96.7823C4.22348 96.7823 -0.0849609 85.652 -0.0849609 74.5118V74.4475H8.96923V74.5118C8.96923 82.7618 9.71464 88.3987 19.7329 88.3987C29.7511 88.3987 30.1189 82.7618 30.1189 74.5118H39.1731C39.1731 85.652 35.2473 96.7823 19.7329 96.7823Z" fill="#EF5B50" />
                <path d="M90.04 65.4453V58.8137C93.163 60.6801 96.7342 61.6718 100.376 61.6841C107.433 61.6841 110.514 58.4178 110.514 50.3014V9.52184C110.514 7.54225 110.116 7.14633 109.122 7.14633H102.463V1.40552H127.509V7.14633H121.645C120.552 7.14633 120.055 7.83919 120.055 9.52184V50.2025C120.055 62.9708 114.39 68.3157 102.066 68.3157C97.8823 68.3497 93.7537 67.3643 90.04 65.4453Z" fill="#EF5B50" />
                <path d="M129 43.0561C129 28.2092 139.138 17.9153 154.046 17.9153C168.556 17.9153 178.495 28.1102 178.495 43.0561C178.495 58.002 168.457 68.2959 153.648 68.2959C138.939 68.2959 129 58.1208 129 43.0561ZM169.053 43.551V42.6602C169.053 30.4857 163.587 23.9531 153.847 23.9531C144.008 23.9531 138.541 30.5847 138.541 42.6602V43.551C138.541 55.6265 143.908 62.2582 153.747 62.2582C163.587 62.2582 169.053 55.5474 169.053 43.551Z" fill="#EF5B50" />
                <path d="M231.17 67.128C227.475 67.1245 223.785 67.4224 220.138 68.0188V57.6259C216.958 64.5545 211.193 68.3157 203.541 68.3157C193.801 68.3157 188.136 63.3667 188.136 51.6871V27.1402C188.136 25.1606 187.639 24.8637 186.744 24.8637H181.079V19.1229H197.677V51.3902C197.677 59.2096 201.155 61.7831 207.019 61.7831C215.169 61.7831 220.039 54.8545 220.039 44.2637V27.1402C220.039 25.1606 219.542 24.8637 218.648 24.8637H212.689V19.1229H229.486V58.7147C229.486 60.7933 229.883 61.3871 230.977 61.3871H236.741V67.128H231.17Z" fill="#EF5B50" />
                <path d="M256.116 29.4167C258.601 21.2014 263.769 17.9351 269.434 17.9351C276.292 17.9351 280.168 22.2902 280.168 28.3279C280.168 33.3759 277.286 36.6422 273.012 36.6422C271.634 36.6387 270.311 36.0984 269.327 35.1367C268.342 34.175 267.775 32.8681 267.745 31.4953C267.762 30.2959 268.183 29.137 268.939 28.2038C269.696 27.2707 270.745 26.6174 271.919 26.3484C271.422 25.3586 270.031 24.1708 267.546 24.1708C259.993 24.1708 256.315 31.6932 256.315 42.9769V59.1106C256.315 61.0902 256.812 61.3871 257.707 61.3871H263.769V67.1279H239.817V61.3673H245.383C246.377 61.3673 246.873 61.0704 246.873 59.0908V27.5361C246.873 25.4575 246.476 24.8637 245.383 24.8637H239.618V19.1228H244.99C248.719 19.13 252.442 18.832 256.121 18.232L256.116 29.4167Z" fill="#EF5B50" />
                <path d="M300.04 29.4167C303.226 21.8943 309.184 17.9351 317.036 17.9351C327.173 17.9351 332.341 23.6759 332.341 35.0586V59.1106C332.341 61.0902 332.838 61.3871 333.832 61.3871H339.408V67.1279H316.35V61.3673H321.518C322.412 61.3673 322.909 61.0704 322.909 59.0908V35.3357C322.909 27.9122 320.127 24.5469 313.567 24.5469C305.417 24.5469 300.15 31.3765 300.15 44.0459V59.0908C300.15 61.0704 300.647 61.3673 301.641 61.3673H306.908V67.1081H283.651V61.3673H289.217C290.211 61.3673 290.708 61.0704 290.708 59.0908V27.5361C290.708 25.4575 290.315 24.8637 289.217 24.8637H283.651V19.1228H289.023C292.717 19.1261 296.405 18.8282 300.05 18.232L300.04 29.4167Z" fill="#EF5B50" />
                <path d="M354.505 26.3484C356.095 27.3382 357.089 29.1198 357.089 31.3963C357.089 34.8606 354.505 37.1371 351.126 37.1371C347.25 37.1371 344.765 34.6626 344.765 30.5055C344.765 22.0922 354.505 17.9351 365.934 17.9351C379.749 17.9351 385.812 22.7851 385.812 34.4647V59.1106C385.812 60.6943 386.209 61.3871 387.203 61.3871H392.67V67.1279H378.557C377.563 64.4555 377.066 61.783 376.569 57.7249C373.885 63.9606 366.63 68.3157 358.381 68.3157C349.337 68.3157 343.572 62.8718 343.572 55.6463C343.572 44.2637 354.803 39.8096 376.37 37.2361V34.5637C376.37 26.9422 372.693 23.7749 364.543 23.7749C359.872 23.7551 356.592 24.6657 354.505 26.3484ZM376.37 46.0453V42.0663C360.667 43.8479 352.716 46.6194 352.716 54.1418C352.716 58.8928 356.294 62.0602 361.661 62.0602C370.506 62.08 376.37 55.5473 376.37 46.0255V46.0453Z" fill="#EF5B50" />
                <path d="M395.562 61.3674H401.227C402.022 61.3674 402.619 61.0704 402.619 59.0908V9.81878C402.619 7.74021 402.221 7.14633 401.227 7.14633H395.363V1.40552H400.929C404.655 1.40557 408.375 1.10101 412.05 0.494904V59.0908C412.05 61.0704 412.547 61.3674 413.442 61.3674H418.908V67.1082H395.562V61.3674Z" fill="#EF5B50" />
                <path d="M446.836 34.3657C446.836 13.382 459.558 0.217743 477.348 0.217743C487.485 0.217743 494.741 5.06774 497.722 12.9861L499.511 1.4055H505.385V25.4575H497.533C495.049 12.3922 488.39 6.7504 478.352 6.7504C465.133 6.7504 456.884 16.1535 456.884 33.079V35.4545C456.884 52.479 465.233 61.783 478.451 61.783C489.483 61.783 497.832 56.3392 499.322 43.6698L506.081 44.5606C504.789 58.1208 495.148 68.3157 478.252 68.3157C459.458 68.2959 446.836 55.7453 446.836 34.3657Z" fill="#EF5B50" />
                <path d="M511.239 61.3674H516.904C517.699 61.3674 518.295 61.0704 518.295 59.0908V9.81878C518.295 7.74021 517.898 7.14633 516.904 7.14633H511.04V1.40552H516.606C520.338 1.40665 524.065 1.10209 527.747 0.494904V59.0908C527.747 61.0704 528.244 61.3674 529.139 61.3674H534.605V67.1082H511.249L511.239 61.3674Z" fill="#EF5B50" />
                <path d="M586.769 67.128C583.075 67.1247 579.387 67.4226 575.742 68.0188V57.6259C572.561 64.5545 566.797 68.3157 559.144 68.3157C549.404 68.3157 543.739 63.3667 543.739 51.6871V27.1402C543.739 25.1606 543.242 24.8637 542.348 24.8637H536.692V19.1229H553.29V51.3902C553.29 59.2096 556.769 61.7831 562.632 61.7831C570.782 61.7831 575.652 54.8545 575.652 44.2637V27.1402C575.652 25.1606 575.155 24.8637 574.261 24.8637H568.298V19.1229H585.094V58.7147C585.094 60.7933 585.492 61.3871 586.585 61.3871H592.349V67.128H586.769Z" fill="#EF5B50" />
                <path d="M607.843 56.1412C606.75 60.5953 605.16 66.2371 605.06 67.1279H599.395C599.793 64.3565 599.992 59.6055 599.992 50.6973V9.81876C599.992 7.74019 599.693 7.14631 598.6 7.14631H592.846V1.4055H598.213C601.974 1.41168 605.73 1.11376 609.443 0.514679V29.3177C612.624 22.1912 618.786 17.9351 627.333 17.9351C640.651 17.9351 649.094 27.833 649.094 43.0759C649.094 58.3188 640.348 68.3157 626.936 68.3157C617.693 68.2959 611.024 63.6637 607.843 56.1412ZM639.344 43.7688V42.482C639.344 30.8024 633.381 24.3688 624.342 24.3688C615.595 24.3688 609.433 30.9014 609.433 42.482V43.7688C609.433 55.2504 615.695 61.882 624.342 61.882C633.386 61.8622 639.344 55.4286 639.344 43.7688Z" fill="#EF5B50" />
            </g>
            <defs>
                <clipPath id="clip0_4449_23527">
                    <rect width="649" height="97" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}


/* <List>
                        <ListItem>
                            <StyledCircleIcon />
                            <ListItemsText>
                                No one will sell data obtained from this platform.
                            </ListItemsText>
                        </ListItem>
                        <ListItem>
                            <StyledCircleIcon />
                            <ListItemsText >
                                No one will engage in promotional activity on this platform.
                            </ListItemsText>
                        </ListItem>
                        <ListItem alignItems="center">
                            <StyledCircleIcon sx={{ border: '2px solid red'}}/>
                            
                            <ListItemsText>
                                Journal Club, Inc. does not add, modify or influence the content on this platform.
                            </ListItemsText>
                        </ListItem>


                    </List> */