import React from 'react';
import { FormControl, FormControlLabel, RadioGroup, Radio, Grid, Box, Typography, Button, Select, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { OPTIONS_SORT_ARTICLES_BY_TYPE } from '../../../constants/constants';
import { JC_COLORS } from '../../../styles/constants';
import { Row } from '../../../common/components';


const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    height: '42px',
    minWidth: '272px',
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

const StyledLabel = styled(Box)(({ theme }) => ({
    width: "272px",
    height: '32px',
    marginBottom: "4px",
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0.5rem',
}));

const CustomDropdownArticleType = ({ orderByLabel, changeOrderBy }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <StyledButton
                variant="outlined"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {orderByLabel}
            </StyledButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <FormControl>

                    <RadioGroup
                        default={orderByLabel}
                        name="sort-by-type"
                        value={orderByLabel}
                        //onChange={(event) => setOrderBy(event.target.value)}
                        onChange={(event) => {
                            changeOrderBy(event.target.value)
                            handleClose()
                        }}
                    >
                        {
                            Object.keys(OPTIONS_SORT_ARTICLES_BY_TYPE).map((el, i) => (
                                <FormControlLabel
                                    key={el}
                                    value={el}
                                    control={
                                        <Radio
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    display: 'none',
                                                },

                                            }}
                                        />
                                    }
                                    label={
                                        <StyledLabel
                                            sx={{
                                                backgroundColor: el === orderByLabel ? JC_COLORS.JC_RED : '#F3F3F3',
                                                color: el === orderByLabel ? JC_COLORS.JC_WHITE : JC_COLORS.JC_ICON,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Raleway',
                                                    fontWeight: '600',
                                                    fontSize: '0.75rem',
                                                    lineHeight: '0.875rem',
                                                }}
                                            >
                                                {el}
                                            </Typography>
                                            {(el === orderByLabel) && (<CheckIcon />)}

                                        </StyledLabel>

                                    }
                                />
                            ))
                        }

                    </RadioGroup>
                </FormControl>
            </Menu>
        </>

    )
}

export default CustomDropdownArticleType