import React from 'react';
import { Typography } from '@mui/material';
import { JC_COLORS } from '../../../styles/constants';

const JCContentTitle = ({ title, sx }) => {
    return (
        <Typography
            variant="H1_BITTER_DESKTOP"
            sx={{
                textAlign: "left",
                color: JC_COLORS.JC_RED,  
                fontWeight: 700,
                ...sx,
            }}
        >
            {title}
        </Typography>
    )
}

export default JCContentTitle