import React, { useEffect, useState } from 'react';
import { InputLabel, TextField, Avatar, FormControl, Select, Box, Typography, MenuItem, DialogTitle, DialogContent, styled, useTheme, Grid, Switch, IconButton, Button, Checkbox, CircularProgress } from '@mui/material';
import { JC_COLORS } from '../../../../styles/constants';
import { CenteredActionButton, CenteredContainer } from '../../../../common/components';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useDropzone } from 'react-dropzone';

import CloseIcon from '@mui/icons-material/Close';
import { addInstitution, addJcInstitution, editInstitution, fetchInstitutions } from '../../../../api';
import { PrivateJCIcon, PublicJCIcon } from '../../../../assets/SVG';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { INSTITUTION_STATUS } from '../../../../constants/constants';
import { uploadToS3FromUrl } from '../../../../utils/JCWysiwygEditor';
import ErrorDialogBox from '../../../../common/components/ErrorDialogBox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const SwitchWithText = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
        marginTop: '20px',
        marginBottom: '12px',
    },
    [theme.breakpoints.down('md')]: {

    }

}));

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#FFFFFF'
    },
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: JC_COLORS.JC_RED,
                opacity: 1,
                border: 0,
            },
        },
        '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
                backgroundColor: JC_COLORS.LIGHT_GREY,
                opacity: 1,
            }
        }

    },
    [theme.breakpoints.up('md')]: {
        width: "50px",
        height: "24px",
        padding: 0,
        margin: "0 11px",
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: "12px",
            height: "12px",
            margin: "0 3px"
        },
        '& .MuiSwitch-track': {
            borderRadius: "12px",
            //backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
            backgroundColor: JC_COLORS.JC_ICON,
            opacity: 1,
        },
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: "6px 0px 6px 0px",
            '&.Mui-checked': {
                transform: 'translateX(32px)',
            }
        }
    },
    [theme.breakpoints.down('md')]: {
        width: "32px",
        height: "18px",
        padding: 0,
        margin: "0 5.5px",
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: "8px",
            height: "8px",
            margin: "1px 5px"
        },
        '& .MuiSwitch-track': {
            borderRadius: "6px",
            backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
            opacity: 1,
        },
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: "4px 0px 4px 0px",
            '&.Mui-checked': {
                transform: 'translateX(16px)',
            }
        },
    }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    borderRadius: '3px',
    background: 'white',
    border: `1px solid ${JC_COLORS.JC_ICON}`,
    [theme.breakpoints.up('md')]: {
        width: '23vw',
        '& .MuiInputBase-root': {
            height: '42px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
        }
    },
    [theme.breakpoints.down('md')]: {
        width: '75vw',
        '& .MuiInputBase-root': {
            height: '34px',
            padding: '0px 5px',
        },
    },

}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: '26px',
        height: '26px',
    },
    [theme.breakpoints.down('md')]: {
        width: '18px',
        height: '18px',
    }
}));

const CheckBoxText = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        lineHeight: '26px',
        fontFamily: 'Raleway',
        marginLeft: "12px"
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '18px',
        marginLeft: '16px',

    }
}));

const filter = createFilterOptions();

const DialogContentAddOrEditEntity = ({
    userEmail,
    relationTypes,
    saveButtonHandler,
    closeIconHandler
}) => {
    const theme = useTheme();

    const [isFetchingAllInstitutions, setIsFetchingAllInstitutions] = useState(false);
    const [allInstitutions, setAllInstitutions] = useState([]);

    const [institutionName, setInstitutionName] = useState("");
    const [institutionRelation, setInstitutionRelation] = useState("");
    const [institution, setInstitution] = useState(null);
    const [authorisedToUseNameForInstitute, setAuthorisedToUseNameForInstitute] = useState(false);

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("Error");

    const [isAddingNewInstitution, setIsAddingNewInstitution] = useState(false);
    const [isUpdatingInstitution, setIsUpdatingInstitution] = useState(false);


    const [uploadedFiles, setUploadedFiles] = useState([]);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: "image/jpg",
        onDrop: acceptedFiles => {
            console.log(acceptedFiles);
            setUploadedFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    function fetchAllInstitutions() {
        setIsFetchingAllInstitutions(true)
        fetchInstitutions()
            .then(response => {
                setAllInstitutions(response?.data?.institutionList);
                setIsFetchingAllInstitutions(false);
            }
            ).catch(error => {
                const errorMsg = error.message
            })

    }


    useEffect(() => {
        fetchAllInstitutions();

    }, []);

    const addNewInstitution = async () => {
        // POST request
        let fileObject = (acceptedFiles?.length > 0 ? acceptedFiles[0] : null);
        let logoFilename = fileObject?.path;  
        setIsAddingNewInstitution(true);
        try {

            if (relationTypes === INSTITUTION_STATUS.PARTNER || fileObject === null) {
                let payloadParams = {
                    name: institutionName,
                    authUserEmail: userEmail,
                }
                let res = await addInstitution({ ...payloadParams })
                let { institutionID } = res?.data
                saveButtonHandler(
                    {
                        ...institution,
                        institutionID,
                        status: INSTITUTION_STATUS.PARTNER,
                        logoFileName: fileObject?.path,
                        logoFilePreview: fileObject?.preview,
                        fileObject: fileObject
                    }
                );

            } else {

                let payloadParams = {
                    name: institutionName,
                    logoFilename: fileObject?.path,
                    authUserEmail: userEmail,
                }
                let res = await addInstitution({ ...payloadParams });
                const { message, payload, url, institutionID } = res?.data;
                console.log("Before upload", institution?.logoUrl);
                try {
                    let res1 = await uploadToS3FromUrl(url, { file: fileObject }, payload)
                    if (res1) {
                        setIsUpdatingInstitution(false);
                        setIsFetchingAllInstitutions(true);
                        fetchInstitutions().then(response => {
                            let updatedInstitutionList = response?.data?.institutionList
                            let selectedInstitution = updatedInstitutionList.find(obj => obj.name === institutionName)
                            console.log("------");
                            console.log("After upload", selectedInstitution?.logoUrl);
                            setIsFetchingAllInstitutions(false);
                            setAllInstitutions(updatedInstitutionList);
                            setInstitution(selectedInstitution);
                            saveButtonHandler(
                                {
                                    ...institution,
                                    institutionID,
                                    logoUrl: selectedInstitution?.logoUrl,
                                    status: institutionRelation,
                                    logoFileName: fileObject?.path,
                                    logoFilePreview: selectedInstitution?.logoUrl,
                                    fileObject: fileObject
                                }
                            )
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                } catch (err) {
                    console.log(err)
                    setOpenErrorDialog(true);
                    setErrorMessage(err?.response?.data?.message);
                    setErrorStatusCode(err?.response?.status);
                }

            }


        } catch (error) {
            console.log(error)
            setOpenErrorDialog(true);
            setErrorMessage(error?.response?.data?.message);
            setErrorStatusCode(error?.response?.status);

        }



    };

    const updateInstitutionLogo = async () => {
        setIsUpdatingInstitution(true);
        let fileObject = (acceptedFiles?.length > 0 ? acceptedFiles[0] : null);
        let logoFilename = fileObject?.path; 
        // PATCH request
        let res = await editInstitution(institutionName, {
            logoFilename,
            authUserEmail: userEmail,
        })
        console.log(res?.data);
        const { message, payload, url } = res?.data;
        console.log("Before Upload", institution?.logoUrl);
        try {
            let res1 = await uploadToS3FromUrl(url, { file: fileObject }, payload);
            if (res1) {
                setIsUpdatingInstitution(false);
                setIsFetchingAllInstitutions(true);
                fetchInstitutions().then(response => {
                    let updatedInstitutionList = response?.data?.institutionList
                    let selectedInstitution = updatedInstitutionList.find(obj => obj.name === institutionName)
                    console.log("------");
                    console.log("After upload", selectedInstitution?.logoUrl);
                    setIsFetchingAllInstitutions(false);
                    setAllInstitutions(updatedInstitutionList);
                    setInstitution(selectedInstitution);
                    saveButtonHandler(
                        {
                            ...institution,
                            logoUrl: selectedInstitution?.logoUrl,
                            status: institutionRelation,
                            logoFileName: fileObject?.path,
                            logoFilePreview: selectedInstitution?.logoUrl,
                            fileObject: fileObject,
                            updatedLogo: true,
                        }
                    )
                }).catch(error => {
                    console.log(error)
                })
            }
        }
        catch (err) {
            console.log(err)
            setOpenErrorDialog(true);
            setErrorMessage(err?.response?.data?.message);
            setErrorStatusCode(err?.response?.status);
        }

    };

    return (
        <>
            <ErrorDialogBox
                open={openErrorDialog}
                closeDialogHandler={() => setOpenErrorDialog(false)}
                errorMessage={errorMessage}
                title={`Error ${errorStatusCode}`}
            >
                <Typography>{errorMessage}</Typography>
            </ErrorDialogBox>
            <DialogTitle
                sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    backgroundColor: JC_COLORS.JC_RED,
                    color: JC_COLORS.JC_WHITE,
                    ...theme.typography.H3_BITTER,
                    fontWeight: 700,

                }}
            >
                {
                    relationTypes === INSTITUTION_STATUS.PARTNER ? "Add Partner": "Add Accreditor/Supporter"
                }
                <CloseIcon
                    sx={{
                        width: '24px',
                        height: '24px',
                        color: JC_COLORS.JC_WHITE,
                    }}
                    onClick={closeIconHandler}
                />
            </DialogTitle>
            <DialogContent>

                {
                    isFetchingAllInstitutions ?
                        (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '400px'
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <InputLabel
                                    required
                                    sx={{
                                        color: JC_COLORS.JC_ICON,
                                        fontWeight: 700,
                                        fontFamily: "Raleway",
                                        marginTop: '25px',
                                        marginBottom: "8px"
                                    }}
                                >
                                    Institution Name
                                </InputLabel>
                                <FormControl>

                                    <Autocomplete
                                        freeSolo
                                        fullWidth
                                        value={institutionName}
                                        onChange={
                                            (event, newValue) => {
                                                console.log(event?.target?.value, newValue)
                                                setInstitution(newValue)
                                                if (newValue) {
                                                    let re = /Add "(.*?)"/;
                                                    let name = newValue.name.match(re);
                                                    setInstitutionName(name ? name[1] : newValue.name);

                                                } else {
                                                    setInstitutionName(event?.target?.value?.name);

                                                }

                                            }
                                        }
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => inputValue === option.name);
                                            if (inputValue !== '' && !isExisting) {

                                                filtered.push({
                                                    institutionID: null,
                                                    logoUrl: null,
                                                    name: `${inputValue}`,
                                                    newEntry: true,
                                                });
                                            }

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        options={allInstitutions}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.name;
                                        }}
                                        renderOption={
                                            (props, option) => {
                                                if (option?.newEntry) {
                                                    return (
                                                        <li
                                                            {...props}
                                                            style={{
                                                                display: 'inline-block',
                                                            }}

                                                        >Add
                                                            <span
                                                                style={{
                                                                    fontStyle: 'italic',
                                                                    fontWeight: 700,

                                                                }}>
                                                                &nbsp;{`"${option.name}"`}
                                                            </span>
                                                        </li>
                                                    )
                                                }
                                                return (
                                                    <li
                                                        {...props}
                                                    >
                                                        {option.name}
                                                    </li>
                                                )
                                            }
                                        }
                                        sx={{

                                            //width: "100%",
                                            marginTop: "0",
                                            padding: 0,
                                            '& .MuiInputBase-root': {
                                                width: "450px",
                                                height: '45px',
                                                padding: "0px 0px 0px 4px",
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                "& > *": {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: "0px",
                                                },
                                            },
                                            '& .MuiFormControl-root': {
                                                padding: '0px',
                                            },
                                        }}

                                        renderInput={
                                            (params) => (
                                                <TextField
                                                    variant="outlined"
                                                    {...params}
                                                    placeholder={""}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        onKeyDown: (e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }
                                                        }
                                                    }}
                                                />
                                            )
                                        }
                                    />
                                </FormControl>


                                {
                                    relationTypes === INSTITUTION_STATUS.PARTNER ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                margin: '42px 0px 0px 4px',
                                            }}
                                        >
                                            <StyledCheckbox
                                                checked={authorisedToUseNameForInstitute}
                                                onChange={() => setAuthorisedToUseNameForInstitute(!authorisedToUseNameForInstitute)}

                                            />
                                            <CheckBoxText>
                                                We are authorized to use the name of this institute for our journal club.
                                            </CheckBoxText>


                                        </Box>
                                    ) : (
                                        <>
                                            <InputLabel
                                                sx={{
                                                    color: JC_COLORS.JC_ICON,
                                                    fontWeight: 700,
                                                    fontFamily: "Raleway",
                                                    marginBottom: "8px",
                                                    marginTop: '28px',
                                                }}
                                            >
                                                Institution Logo
                                            </InputLabel>
                                            <Box
                                                sx={{
                                                    position: "relative"
                                                }}
                                                {...getRootProps({ className: 'dropzone' })}
                                            >
                                                <Avatar
                                                    variant="square"
                                                    src={uploadedFiles?.length > 0 ? uploadedFiles[0].preview : institution?.logoUrl}
                                                    sx={{
                                                        height: "120px",
                                                        width: "120px",
                                                        margin: "0px 0px 12px 0px",
                                                        cursor: 'pointer',
                                                        backgroundColor: JC_COLORS.JC_WHITE,
                                                        //color: JC_COLORS.JC_ICON,
                                                        color: 'rgba(0, 0, 0, 0.1)',
                                                        border: `1px solid ${JC_COLORS.JC_ICON}`,
                                                        fontSize: "40px",
                                                        lineHeight: "54px",
                                                        fontWeight: "700",
                                                    }}

                                                >
                                                    {institution?.name ? institution?.name[0].toUpperCase() : "Logo"}
                                                </Avatar>
                                                {
                                                    uploadedFiles?.length === 0 ? (
                                                        <IconButton
                                                            sx={{
                                                                position: "absolute",
                                                                top: "70%",
                                                                left: "18%",
                                                                zIndex: "10",
                                                                opacity: "1",
                                                                backgroundColor: JC_COLORS.LIGHT_GREY,
                                                                color: JC_COLORS.JC_BLACK,
                                                            }}
                                                        >
                                                            <input
                                                                {...getInputProps()}
                                                            />
                                                            <PhotoCameraIcon

                                                            />
                                                        </IconButton>

                                                    ) : <></>
                                                }

                                            </Box>
                                            <InputLabel
                                                required
                                                sx={{
                                                    color: JC_COLORS.JC_ICON,
                                                    fontWeight: 700,
                                                    fontFamily: "Raleway",
                                                    marginBottom: "11px",
                                                    marginTop: '28px'
                                                }}
                                            >
                                                Institution Type
                                            </InputLabel>
                                            <FormControl>
                                                <Select
                                                    value={institutionRelation || ""}
                                                    onChange={(event) => setInstitutionRelation(event.target.value)}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    placeholder="select"
                                                    //IconComponent={() => dropDownMenuOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    displayEmpty
                                                    MenuProps={MenuProps}
                                                    required
                                                    sx={{
                                                        height: "28px",
                                                        width: "23vw",
                                                        border: `1px solid ${JC_COLORS.JC_ICON}`,
                                                    }}
                                                >
                                                    <MenuItem value={""}>
                                                        <Typography
                                                            variant="BODY_ARTICLE_RALEWAY"
                                                        >
                                                            -Select-
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem value={INSTITUTION_STATUS.ACCREDITOR}>
                                                        <Typography
                                                            variant="BODY_ARTICLE_RALEWAY"
                                                        >
                                                            Accreditor
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem value={INSTITUTION_STATUS.SUPPORTER}>
                                                        <Typography
                                                            variant="BODY_ARTICLE_RALEWAY"
                                                        >
                                                            Supporter
                                                        </Typography>
                                                    </MenuItem>

                                                    {
                                                        relationTypes === INSTITUTION_STATUS.PARTNER && (
                                                            <>
                                                                <MenuItem value={"Partner"}>
                                                                    <Typography>Partner</Typography>
                                                                </MenuItem>
                                                                <MenuItem value={"Affiliate"} disabled>
                                                                    <Typography>Affilitate</Typography>
                                                                </MenuItem>
                                                            </>
                                                        )
                                                    }


                                                </Select>

                                            </FormControl>
                                        </>
                                    )
                                }


                                <Box
                                    sx={{
                                        margin: "27px 0px 18px 0px"
                                    }}
                                >
                                    <Typography
                                        variant="BODY_ARTICLE_RALEWAY"
                                        sx={{ marginBottom: "8px" }}
                                    >
                                        {((institution?.newEntry === true) && (isAddingNewInstitution === true)) ? `Adding ${institutionName}. Please wait...` : ''}
                                        {isUpdatingInstitution === true ? `Updating ${institutionName}. Please wait...` : ''}.
                                    </Typography>

                                    <CenteredActionButton
                                        onClick={
                                            async () => {
                                                if (institution?.newEntry === true) {
                                                    // 1. Add a new New isntition to the main institutions DB, 2. Then send the data to parent after getting the institution ID of new element 
                                                    addNewInstitution();
                                                } else {
                                                    // 1. Update the main institutions DB with new logo, 2. Then send the data to parent 
                                                    if (acceptedFiles?.length > 0) {
                                                        updateInstitutionLogo();
                                                    } else {
                                                        // Send the data to parent (institutionID is present already in the institution object)
                                                        saveButtonHandler(
                                                            {
                                                                ...institution,
                                                                status: relationTypes === INSTITUTION_STATUS.PARTNER ? INSTITUTION_STATUS.PARTNER : institutionRelation,
                                                                logoFileName: null,
                                                                logoFilePreview: null,
                                                                fileObject: null
                                                            }
                                                        )

                                                    }



                                                }


                                            }
                                        }
                                        disabled={
                                            (
                                                relationTypes === INSTITUTION_STATUS.PARTNER
                                            ) ? (
                                                institutionName === "" ||
                                                authorisedToUseNameForInstitute === false ||
                                                isAddingNewInstitution === true
                                            ) : (
                                                institutionName === "" ||
                                                institutionRelation === "" ||
                                                isAddingNewInstitution === true ||
                                                isUpdatingInstitution === true
                                            )

                                        }
                                    >
                                        SAVE
                                    </CenteredActionButton>

                                </Box>
                            </>
                        )
                }



            </DialogContent>
        </>


    );
}

export default DialogContentAddOrEditEntity;


