import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Divider,
} from '@mui/material';
import CustomDropdownDateRange from '../../../Feed/components/CustomDropdownDateRange';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useSelector, useDispatch } from "react-redux";
import { fetchJCReports } from '../../../../api';
import { REPORTS_NAV } from '../../../../constants/constants';
import { JC_COLORS } from '../../../../styles/constants';
import { updateReportNav, updateRootFilter, getReportRootData, updateRootDateRange } from '../../../../state/actions/jcreports';
import { InlineBox } from './ReportHelpers';

const RootReport = (props) => {
  const dispatch = useDispatch()
  // const [dateRange,setDateRange] = useState({ startDate: null, endDate: null })
  const { jcDetails } = useSelector((state) => state.journalclub);
  const { reportRootData, reportRootFilter, rootDateRange } = useSelector((state) => state.jcreports);

  useEffect(() => {
    var queryParams = {
      authUserEmail: props.userEmail,
    }
    if (rootDateRange.startDate && rootDateRange.endDate) {
      queryParams = { ...queryParams, ...rootDateRange }
    }
    dispatch(getReportRootData(jcDetails?.JCID, queryParams))
    // fetchJCReports(jcDetails?.JCID,queryParams).then(response => {
    //   if(response?.data){
    //     setReportData(response.data)
    //   }
    // }).catch(error =>{
    //   console.error(error)
    // })
  }, [rootDateRange])

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'left' }} mt={{ xs: 2, md: 0 }} mb={4}>
          <InlineBox>
              <Box sx={{ textAlign: 'left' }}>
              <Typography variant="H1_BITTER" color="primary" sx={{ fontWeight: '700 !important' }}>
              Reports
              </Typography>
              </Box>
          </InlineBox>
          <InlineBox sx={{ float: 'right' }}>
            <CustomDropdownDateRange isHeading={false} isWhite={true}
            dateRangeLabel={reportRootFilter}
            changeDateRangeLabel={(value) => dispatch(updateRootFilter(value))}
            changeDateRangeHandler={(value) => dispatch(updateRootDateRange(value))}
            />
          </InlineBox>
      </Box>
      {/* Report Sumary*/}
      <Grid container mb={{ xs: 8, md: 3 }} sx={{ backgroundColor: `${JC_COLORS.JC_RED}`, color: "#ffffff", borderRadius: '4px', paddingTop: '10px', paddingBottom: '10px' }}>
        <Grid item xs={12} md={8} mt={2} mb={3}>
          <Grid container spacing={{ xs: 1.5 }}>
            <Grid item xs={6} md={2.4}>
              <NumberBox numberOf={reportRootData.numAllReaders} title="Readers" top={true} />
            </Grid>
            <Grid item xs={6} md={2.4}>
              <NumberBox numberOf={reportRootData.numAllContributors} title="Contributors" top={true} />
            </Grid>
            <Grid item xs={6} md={2.4}>
              <NumberBox numberOf={reportRootData.numAllCourseDirectors} title="Course Directors" top={true} />
            </Grid>
            <Grid item xs={6} md={2.4}>
              <NumberBox numberOf={reportRootData.numAllAdministrators} title="Administrators" top={true} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
      {/* Report Description*/}
      <Grid container spacing={{ xs: 2 }} mb={2}>
        <Grid item xs={12} md={9.5}>
          <Grid container spacing={{ xs: 2 }}>
            <Grid item xs={6} md={6}>
              <DescriptionBox dataArray={{ "Users Joined": reportRootData.numAllJoinedUsers, "Users Left": reportRootData.numAllLeftUsers }} columnSize={5} title="Membership Activity" onClick={() => dispatch(updateReportNav(REPORTS_NAV.MEMBERSHIP))} />
            </Grid>
            <Grid item xs={6} md={6}>
              <DescriptionBox dataArray={{ "Articles In-Review": reportRootData.numAllProposedArticles, "Articles Published": reportRootData.numAllPublishedArticles }} columnSize={6} title="Publishing Activity" onClick={() => dispatch(updateReportNav(REPORTS_NAV.PUBLISH))} />
            </Grid>
            <Grid item xs={12}>
              <DescriptionBox dataArray={{ "Total Views": reportRootData.numAllArticleViews, "Total Likes": reportRootData.numAllArticleLikes, "Total Practice Impacting Points": reportRootData.numAllArticlePracticeImpactings, "Total Comments": reportRootData.numAllComments }} columnSize={2.2} title="Engagement" ArrowColum={0.8} onClick={() => dispatch(updateReportNav(REPORTS_NAV.ENGAGEMENT))} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2.5}>
          <DescriptionBox dataArray={{ "Comments": reportRootData.numAllReportedComments, "Notes": reportRootData.numAllReportedNotes }} columnSize={12} title="Flagged Notes and Comments" isVerticle={true} ArrowColum={12} onClick={() => dispatch(updateReportNav(REPORTS_NAV.FLAGGED))} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default RootReport

export const NumberBox = ({ numberOf, title, top }) => {
  return (
    <Box>
      <Box>
        <Typography variant="H1_BITTER">
          {numberOf ? numberOf < 10 ? "0" + numberOf : numberOf : "00"}
        </Typography>
      </Box>
      <Box>
        <Typography variant="BODY_COPY2_RALEWAY" color={top ? "#ffffff" : JC_COLORS.JC_ICON}>
          {title}
        </Typography>
      </Box>

    </Box>
  )
}

const DescriptionBox = ({ dataArray, columnSize, title, onClick, isVerticle = false, ArrowColum = 1.5 }) => {
  const [isHover, setHover] = useState(false)
  const dataBoxs = Object.keys(dataArray)

  return (
    <Grid container spacing={{ xs: 1.5 }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={onClick} sx={{ width: '100%!important', margin: '0px!important', height: "100%" }}>
      <Grid item xs={ArrowColum === 12 ? 12 : 12 - ArrowColum} sx={{ backgroundColor: "#F3F3F3", borderRadius: '4px', cursor: 'pointer' }}>
        <Grid container spacing={{ xs: 1.5 }} mt={4} mb={2.5}>
          <Grid item xs={12} ml={isVerticle ? 0.5 : 2} mr={2} mt={1.5} sx={{ textAlign: isVerticle ? 'center' : 'left' }}>
            <Typography variant="H2_RALEWAY">
              {title}
            </Typography>
          </Grid>
          {dataBoxs.map(item =>
            <Grid item xs={item === "Total Practice Imapcting Points" ? 4 : columnSize} sx={{ paddingLeft: isVerticle ? '0px!important' : '12px' }}>
              <NumberBox numberOf={dataArray[item]} title={item} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={ArrowColum} sx={{ backgroundColor: isHover ? "#EF5B50!important" : "#F3F3F3!important", padding: '0px!important', paddingTop: isVerticle ? "6px!important" : '0px', borderTopRightRadius: isVerticle ? '0px' : '4px!important', borderBottomRightRadius: '4px!important', borderBottomLeftRadius: isVerticle ? '4px' : '0px', transition: 'all 0.2s ease-in', cursor: 'pointer' }}>
        <ArrowForwardOutlinedIcon sx={{ color: isHover ? '#FFFFFF' : "838383", position: 'relative', top: 'calc(50% - 12px)' }} />
      </Grid>
    </Grid>
  )
}