import React, { createContext, useState, useContext } from 'react';
import { getToken } from '../utils/authToken';
import { token_name_list } from '../constants/constants';
import jwt_decode from 'jwt-decode';

/**
 * Provider for user context which holds user value
 */
const AuthContext = createContext();

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useContext Not Defined!');
  }
  return context;
};

export { useAuthContext, AuthContext };

const AuthContextProvider = (props) => {
  const [isAuthenticated, setAuthenticated] = useState(
    !!getToken(token_name_list.ACCESS_TOKEN)
  );
  let initUserData = {};
  let initUserRoles = [];
  if (getToken(token_name_list.ID_TOKEN)) {
    initUserData = jwt_decode(getToken(token_name_list.ID_TOKEN));
    // initUserData.masterQSE = (getToken('masterQSE') === 'true');
    // initUserRoles = initUserData['cognito:groups'];
  }
  const [user, setUser] = useState(initUserData);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setAuthenticated,
        setUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
