import React from 'react'
import { Box, Button, FormControl, FormControlLabel, RadioGroup, Radio, Typography, Menu, MenuItem, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { pxToRem, stringToColor } from '../../../utils';
import { JC_COLORS } from '../../../styles/constants';
import { JCChip,DefaultSelectedJCList } from '../../JCDetails/jc-content/report-content/ReportHelpers';
import { bgcolor } from '@mui/system';
import { DEFAULT_JC } from '../../../constants/constants';

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontWeight: 700,
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  textTransform: 'capitalize',
  [theme.breakpoints.up('md')]: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    height: '42px',
    minWidth: '142px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(20),
    height: '28px',
    minWidth: '150px',
  },
}));

const StyledLabel = styled(Box)(({ theme }) => ({
  width: "272px",
  height: '32px',
  marginBottom: "4px",
  borderRadius: '2px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 0.5rem',
}));

const JCListDropdown = ({ selectedJC,listOfJC,jcList,changeSelectedJC }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getJCIconColor = (JCID, name) => {
    return (
      jcList.filter((JC) => JC.JCID === JCID)[0]?.iconColor ??
      stringToColor(name ?? '')
    );
  };

  return (
    <>
      <StyledButton
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selectedJC.labelView ? selectedJC.name : (
            <JCChip jcName={selectedJC.name} jcColor={getJCIconColor(selectedJC.JCID,selectedJC.name)} isMobile={true} nameColor={JC_COLORS.JC_RED} />  
        )}
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <RadioGroup
          default={selectedJC.name}
          value={selectedJC.name}
          onChange={(event) => {
            changeSelectedJC({
                name : event.target.value,
                JCID : listOfJC.filter(JC => JC.name === event.target.value)[0].JCID,
                apiKey : listOfJC.filter(JC => JC.name === event.target.value)[0]?.apiKey ?? "JC",
                labelView : listOfJC.filter(JC => JC.name === event.target.value)[0]?.labelView ?? false
            })
            handleClose()
          }}
        >
          { listOfJC &&
            listOfJC.map((JC, i) => (
              <FormControlLabel
                key={JC.name}
                value={JC.name}
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': {
                        display: 'none',
                      },
                    }}
                  />
                }
                label={
                  <StyledLabel
                    sx={{
                      backgroundColor: JC.name === selectedJC.name ? JC_COLORS.JC_RED : '#F3F3F3',
                      color: JC.name === selectedJC.name ? JC_COLORS.JC_WHITE : JC_COLORS.JC_ICON,
                    }}
                  >
                    { JC.labelView ? (
                    <Typography
                      sx={{
                        fontFamily: 'Raleway',
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '0.875rem',
                        textTransform: 'capitalize',
                        marginLeft:'10px',
                      }}
                    >
                    {JC.name}
                    </Typography>
                    )
                    :(
                        <JCChip jcName={JC.name} jcColor={getJCIconColor(JC.JCID,JC.name)} isMobile={true} nameColor={JC.name === selectedJC.name ? JC_COLORS.JC_WHITE : JC_COLORS.JC_ICON} maxWidth='calc(100% - 24px)' />
                    )}
                    {(JC.name === selectedJC.name) && (<CheckIcon />)}
                    </StyledLabel>

                }
              />

            ))
          }

        </RadioGroup>

      </Menu>
    </>

  )
}

export default JCListDropdown