import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Link,
    Divider
} from '@mui/material';
import { styled } from '@mui/system';
import { useLocation, useParams } from 'react-router';
import { TERMS_OF_SERVICE_PDF, PRIVACY_POLICY_PDF } from '../../constants/constants';
import { JC_COLORS } from '../../styles/constants';


const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: JC_COLORS.JC_RED,
    fontFamily: 'Raleway',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
        padding: '8px 12px',
        width: 'auto',
        minWidth:'110px',
        height: '38px',
        borderRadius: '4px',
        fontSize: '15px',
        lineHeight: '26px',
        '&:hover': {
            backgroundColor: 'white',
            color: JC_COLORS.JC_RED,
        }

    },
    [theme.breakpoints.down('md')]: {
        padding: '4px 10px',
        minWidth:'80px',
        width: 'auto',
        height: '32px',
        fontSize: '12px',
        lineHeight: '20px',
    }
}));

const GuestSidebar = () => {
    const { page } = useParams();
    const location = useLocation();

    return (
        <Box>
            <Grid container spacing={{ xs: 2 }} pl={3.5} pr={3.5} sx={{ backgroundColor: "#EF5B50", textAlign: 'left', borderRadius: '4px', color: '#FFFFFF', width: '100%!important', margin: '0px!important',paddingLeft:'12px!important' }} className="guest-sidebar">
                <Grid item xs={12} mt={3} sx={{paddingTop:'0px!important'}}>
                    <Typography variant="H1_BITTER" >
                        Share insights
                        with institutions and experts of
                        your choice
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="BODY_ARTICLE_RALEWAY" >
                        The first networked platform where institutions, associations and individual experts can create and disseminate ongoing monetizable content on any medical or biomedical topic.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box mb={1}>
                        <Typography variant="BODY_ARTICLE_RALEWAY" >
                            Want to join  Journal Club?
                        </Typography>
                    </Box>
                    <Box>
                        <StyledButton
                            variant="contained"
                            onClick={
                                () => {
                                    window.location = `${process.env.REACT_APP_AWS_DOMAIN}/signup?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
                                }
                            }
                        >
                            SIGN UP
                        </StyledButton>
                    </Box>
                </Grid>
                <Grid item xs={12} mb={0.5}>
                    <Box mb={1}>
                        <Typography variant="BODY_ARTICLE_RALEWAY" >
                            Already have an account?
                        </Typography>
                    </Box>
                    <Box>
                        <StyledButton
                            variant="contained"
                            onClick={
                                () => {
                                    localStorage.setItem('usr_landing_url', location.pathname)
                                    window.location = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
                                }
                            }
                        >
                            LOGIN
                        </StyledButton>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ border: '1px solid #FFFFFF' }} />
                </Grid>
            </Grid>
            <Box sx={{ backgroundColor: "#EF5B50", color: '#FFFFFF', textAlign: 'center', borderRadius: '4px' }} pt={2} pb={2.5} my={-0.3}>
                <Box>
                    <Box sx={{ display: 'inline-block' }} mx={1}>
                        <Link
                            href={PRIVACY_POLICY_PDF}
                            target="_blank"
                            sx={{
                                color: JC_COLORS.JC_WHITE,
                                textDecorationColor: `${page === "privacy-policy" ? JC_COLORS.JC_RED : JC_COLORS.JC_WHITE}`
                            }}>
                            <Typography
                                variant="BODY_COPY2_RALEWAY"
                                color={page === "privacy-policy" ? JC_COLORS.JC_RED : JC_COLORS.JC_WHITE}
                                gutterBottom
                            >
                                Privacy Policy
                            </Typography>
                        </Link>
                    </Box>
                    <Box sx={{ display: 'inline-block' }} mx={1}>
                        <Link
                            href={TERMS_OF_SERVICE_PDF}
                            target="_blank"
                            sx={{
                                color: JC_COLORS.JC_WHITE,
                                textDecorationColor: `${page === "terms-of-service" ? JC_COLORS.JC_RED : JC_COLORS.JC_WHITE}`
                            }}>
                            <Typography
                                variant="BODY_COPY2_RALEWAY"
                                color={page === "terms-of-service" ? JC_COLORS.JC_RED : JC_COLORS.JC_WHITE}
                                gutterBottom
                            >
                                Terms of Service
                            </Typography>
                        </Link>
                    </Box>

                </Box>

                <Box mt={1}>
                    <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_WHITE} gutterBottom>
                        Journal Club &copy; 2022
                    </Typography>
                </Box>

                <Box mt={1}>
                    <Link href='#' sx={{ color: JC_COLORS.JC_WHITE, textDecorationColor: JC_COLORS.JC_WHITE }}>
                        <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_WHITE} gutterBottom>
                            NCBI’s Disclaimer and Copyright Notice
                        </Typography>
                    </Link>

                </Box>
            </Box>
        </Box>
    )
}

export default GuestSidebar