import React, { useState, useEffect, useContext } from 'react';
import { Grid, Hidden, Box, Typography, Icon, CircularProgress } from '@mui/material';
import { styled } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import JCContentTitle from '../components/JCContentTitle';
import * as api from "../../../api";
import { AuthContext } from '../../../context/AuthContext';
import { JC_COLORS } from '../../../styles/constants';
import { pxToRem } from '../../../utils';
import SquareAvatar from '../components/SquareAvatar';
import AvatarName from '../components/AvatarName';
import { CenteredContainer } from '../../../common/components';
import { USER_ROLES } from '../../../constants/constants';


const NumberOfContributors = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'flex-start',
  fontFamily: 'Raleway',
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    color: JC_COLORS.JC_BLACK,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21.13),
    marginTop: '16px',

  },
  [theme.breakpoints.down('md')]: {
    color: JC_COLORS.JC_ICON,
    fontSize: pxToRem(10),
    lineHeight: pxToRem(14),
  }
}));

const GreyBox = styled(Box)(({ theme }) => ({
  backgroundColor: JC_COLORS.LIGHT_GREY,
  [theme.breakpoints.up('md')]: {
    //minHeight: '72px',
    marginBottom: '29px',
    padding: '11px 14px',
    borderRadius: '8px',
    display: 'grid',
    gridTemplateColumns: `repeat(2, 1fr)`,

  },
  [theme.breakpoints.down('md')]: {
    //minHeight: '46px',
    marginBottom: '8px',
    padding: '4px',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

  }
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontWeight: 600,
  color: JC_COLORS.JC_BLACK,
  [theme.breakpoints.up('md')]: {
    fontSize: '15px',
    lineHeight: '17.61px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    lineHeight: '16px',
  }
}))


const ContributingMembersTab = ({ JCID }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [contributingMembersList, setContributingMembersList] = useState([]);
  const [error, setError] = useState(null);

  async function getContributingMembersList() {
    setIsLoading(true);
    try {
      const res = await api.fetchContributingMembers(JCID);
      if (res.data === 'No members') {
        setContributingMembersList([]);
      } else {
        setContributingMembersList(parseContributingMembers(res.data));
      }
      setIsLoading(false);
      // console.log(data); 
    } catch (error) {
      console.log(error);
      //setError(error);
      //setIsLoading(false);
    }
  }

  useEffect(() => {
    getContributingMembersList();
  }, [])


  return (
    <>
      <Hidden mdDown>
        {
          isLoading && <CenteredContainer><CircularProgress /></CenteredContainer>
        }
        {
          isLoading === false && <JCContentTitle title={'Contributing Members'} />
        }
        {
          isLoading === false && (
            <NumberOfContributors>{contributingMembersList?.length} Contributors</NumberOfContributors>
          )
        }
        <Box sx={{ marginTop: '16px' }}>
          {
            contributingMembersList.map((obj, i) => (
              <GreyBox key={i}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <SquareAvatar name={`${obj.firstName} ${obj.lastName}`} logoUrl={obj.logoUrl}></SquareAvatar>
                  <AvatarName>{`${obj.firstName} ${obj.lastName}`}</AvatarName>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <StyledText>{obj.profession ?? ""}</StyledText>
                  <Box sx={{ width: "9px", height: "9px", margin: '0px 13px', backgroundColor: JC_COLORS.JC_BLACK, borderRadius: '50%' }}></Box>
                  <StyledText>{obj.institution ?? ""}</StyledText>
                </Box>
              </GreyBox>
            )
            )
          }
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box sx={{ padding: '0px 13px' }}>
          {
            isLoading === false && (
              <NumberOfContributors>{contributingMembersList?.length} Contributors</NumberOfContributors>
            )
          }

          <Box sx={{ marginTop: '9px' }}>
            {
              contributingMembersList?.map((obj, i) => (
                <GreyBox key={i}>
                  <SquareAvatar name={`${obj.firstName} ${obj.lastName}`} logoUrl={obj.logoUrl}></SquareAvatar>
                  <Box sx={{ marginLeft: '12px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <Typography sx={{ fontFamily: 'Raleway', fontWeight: 600, fontSize: pxToRem(12), lineHeight: pxToRem(18) }}>{`${obj.firstName} ${obj.lastName}`}</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography sx={{ fontFamily: 'Raleway', fontSize: '12px', lineHeight: '14px' }}>{obj.profession ?? ""}</Typography>
                      <Box sx={{ width: "4px", height: "4px", margin: '0px 8px', backgroundColor: JC_COLORS.JC_BLACK, borderRadius: '50%' }}></Box>
                      <Typography sx={{ fontFamily: 'Raleway', fontSize: '12px', lineHeight: '14px' }}>{obj.institution ?? ""}</Typography>
                    </Box>
                  </Box>
                </GreyBox>
              ))
            }
          </Box>
        </Box>

      </Hidden>
    </>
  )
}

export default ContributingMembersTab

function parseContributingMembers(memberDetails) {
  return memberDetails?.filter(obj => obj.role === USER_ROLES.CONTRIBUTOR);
}