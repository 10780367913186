import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { useMediaQuery, Chip, CircularProgress, TextField, InputAdornment } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { JC_COLORS } from '../styles/constants';
import { useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    FormLabel,
    MenuItem,
    FormControl,
    Select, InputLabel, Divider,
    Checkbox,
    FormControlLabel,
    Input,
    styled,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import ErrorDialogBox from '../common/components/ErrorDialogBox';
import { countryList } from '../utils/CountryList';
import { updateUserDetails } from '../state/actions/user';
import { fetchAreaOfInterest, fetchInstitutions, fetchProfessions, fetchUserDetails } from '../api';
import JCHeader from '../common/components/JCHeader';
import { AuthContext } from '../context/AuthContext';
import { pxToRem } from '../utils';
import * as api from '../api';
import JCSnackbar from '../common/components/JCSnackbar';
import { fetchJcUserData } from "../context/UserDataContext";
import { useJcUserData, useAddJcUserData, useUpdateJcUserData } from '../common/hooks/useJcUserData';

const useStyles = makeStyles({
    inputlabel: {
        fontFamily: 'Raleway!important',
        fontStyle: 'normal!important',
        fontWeight: '700!important',
        lineHeight: '26px!important',
        fontSize: '18px!important',
        marginBottom: '10px!important',
    },
    inputlabelMobile: {
        fontFamily: 'Raleway!important',
        fontStyle: 'normal!important',
        fontWeight: '700!important',
        fontSize: '12px!important',
        minHeight: '22px!important',
        marginBottom: '6px!important'
    },
    textfield: {
        width: '100%',
        '& .MuiInputBase-input': {
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '26px',
            padding: '10px',

        },
    },
    textfieldMobile: {
        width: '100%',
        '& .MuiInputBase-input': {
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '12px',
            padding: '6px',
        },
        '& .MuiSelect-select': {
            height: '21px!important',
            paddingTop: '4px',
            paddingBottom: '4px'
        }
    },
    desktop: {
        marginTop: '8px!important',
        marginLeft: '8px!important',
        padding: '4px!important',
        fontFamily: 'Raleway!important',
        fontWeight: "600",
        fontSize: "0.938rem!important",
    },

    mobile: {
        marginTop: '8px!important',
        marginLeft: '5px!important',
        height: '20px',
        fontFamily: 'Raleway!important',
        fontWeight: '600',
        fontSize: '0.625rem!important',
    }
});

const H3RalewayText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 600,
    color: JC_COLORS.JC_BLACK,
    [theme.breakpoints.up('md')]: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(26),
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(12),
        lineHeight: pxToRem(14.74),
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(mobileMatch, country, countryName, theme) {
    return {
        fontSize: mobileMatch ? '12px' : '18px',
        fontWeight:
            countryName?.indexOf(country) === -1
                ? '600'
                : '700',
        fontFamily: 'Raleway',
        minHeight: '22px'
    };
}

const filter = createFilterOptions();

let appState = JSON.parse(localStorage.getItem('appState'));

function CompleteProfile() {
    const classes = useStyles()
    const theme = useTheme()
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { user } = useSelector((state) => state);
    const dispatch = useDispatch();
    const { userDetails } = user;
    const mobileMatch = useMediaQuery(theme.breakpoints.down('md'))
    const keywordClass = mobileMatch ? classes.mobile : classes.desktop
    const [itemsList, setItemsList] = useState([])
    const [ProfessionList, setProfessionList] = useState([]);
    const [institutionsList, setInstitutionsList] = useState([]);
    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [emptyAOI, setAOIError] = useState(true)
    const [lastName, setLastName] = useState('')
    const [countryName, setCountry] = useState('')
    const [professionName, setProfession] = useState('');
    const [institutionName, setInstitutionName] = useState('');
    const [isLicensedProfessional, setIsLicensedProfessional] = useState(false);
    const [aboutMe, setAboutMe] = useState("");
    const [isFetchingUserDetails, setIsFetchingUserDetails] = useState(false);
    const [areasOfInterest, setAreasOfInterest] = useState([]);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isNewUser,setIsNewUser] = useState(false)

    var prevId = ""
    const textRegex = new RegExp('[0-9]+')


    const handleOnSubmit = async (event) => {
        event.preventDefault();
        const filterList = itemsList.filter(item => item.isSelected)
        const finalList = filterList.map(item => item.title).filter((value, index, self) => self.indexOf(value) === index)
        
        var data = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            country: countryName,
            profession: professionName,
            areasOfInterest: finalList.join("|"),
            isLicensedProfessional: isLicensedProfessional ? isLicensedProfessional : false,
            institution: institutionName,
            status: 'Active',
        }
        if(aboutMe && aboutMe.trim()){
            data = {...data,aboutMe: aboutMe.trim()}
        }

        try {
            let res = await api.updateUserDetails(data, auth.user.email);
            if (res.data === "User updated") {
                if(isNewUser){
                    navigate("/join-a-journal-club");
                }else {
                    navigate("/feed");
                }   
            } else {
                setOpenErrorDialog(true);
                setErrorMessage("Error While updating.... " + res.data + ` Status: ${res.status}`);
            }
        } catch (error) {
            console.log(error.response)
            setOpenErrorDialog(true);
            setErrorMessage("Error While updating.... " + error.response.data + ` Status: ${error.response.status}`);
        }

    }

    useEffect(()=>{
        console.log(isNewUser,":: isNewUser")
    },[isNewUser])


    useEffect(() => {
        document.body.style.backgroundColor = JC_COLORS.JC_WHITE
        var itemsListModified = []
        setIsFetchingUserDetails(true);
        function getUserDetails() {
            fetchUserDetails(auth.user.email).then(response => {
                if (response.data) {
                    const userData = response.data
                    setFirstName(userData?.firstName)
                    setLastName(userData?.lastName)
                    setCountry(userData?.country)
                    setProfession(userData?.profession)
                    setInstitutionName(userData?.institution);
                    setIsLicensedProfessional(userData?.isLicensedProfessional);
                    setAboutMe(userData?.aboutMe ?? "");
                    if( userData && !userData.firstName && !userData.lastName && (userData.areasOfInterest === null || userData.areasOfInterest.length < 1)){
                        setIsNewUser(true)
                    }
                    var myAreaOfInterest = []
                    if (userData?.areasOfInterest.length > 1) {
                        myAreaOfInterest = userData?.areasOfInterest.filter(item => item != "General Medicine")[0]
                    }
                    else {
                        myAreaOfInterest = userData?.areasOfInterest[0]
                    }
                    const PrevListModified = itemsListModified.map(aItem => aItem.title === myAreaOfInterest ? { ...aItem, isSelected: true } : aItem)
                    const PrevIdList = PrevListModified.filter(item => item.title == myAreaOfInterest)
                    window.prevId = PrevIdList[0].id
                    setItemsList(PrevListModified)
                    setAOIError(false)
                }
                setIsFetchingUserDetails(false);
            }
            ).catch(error => {
                const errorMsg = error.message
                setIsFetchingUserDetails(false);
            })
        }
        function getAreaOfInterest() {
            fetchAreaOfInterest().then(response => {
                const getItemsRemote = [...response.data.AreaOfInterestList]
                itemsListModified = getItemsRemote.map((el, i) => ({ id: el + i, title: el, isSelected: false }))
                setItemsList(itemsListModified)
                getUserDetails()
            }
            ).catch(error => {
                const errorMsg = error.message
            })
        }
        function getProfessionList() {
            fetchProfessions().then(response => {
                setProfessionList(response.data.ProfessionList)
            }
            ).catch(error => {
                const errorMsg = error.message
            })
        }
        function getInstitutionsList() {
            fetchInstitutions().then(response => {
                setInstitutionsList(response?.data?.institutionList)
            }
            ).catch(error => {
                const errorMsg = error.message
            })
        }
        getInstitutionsList()
        getProfessionList()
        getAreaOfInterest()
        return () => document.body.style.backgroundColor = JC_COLORS.LIGHT_GREY
    }, [])



    const areaOfInterestClick = (id) => {
        const newList = itemsList.map(aItem => aItem.id === id ? { ...aItem, isSelected: !aItem.isSelected } : aItem)
        const removeUpdatedList = newList.map(aItem => aItem.id === window.prevId ? { ...aItem, isSelected: false } : aItem)
        setAOIError(removeUpdatedList.filter(item => item.isSelected).length === 0)
        setItemsList(removeUpdatedList)
        window.prevId = id
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
        setFirstNameError(false)
        if (event.target.value === '' || event.target.value.match(textRegex)) {
            setFirstNameError(true)
        }
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
        setLastNameError(false)
        if (event.target.value === '' || event.target.value.match(textRegex)) {
            setLastNameError(true)
        }
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value)
    };

    const handleProfessionChange = (event) => {
        setProfession(event.target.value)
    }
    const handleInstitutionChange = (event) => {
        setInstitutionName(event.target.value);

    }

    return (
        <React.Fragment>
            <ErrorDialogBox
                open={openErrorDialog}
                closeDialogHandler={() => setOpenErrorDialog(false)}
                errorMessage={errorMessage}
            />
            <JCHeader greet="true"></JCHeader>
            {/*Todo: Header   */}
            {
                (isFetchingUserDetails || user.isFetching) && (
                    <Box sx={{ mt: "10vw" }}><CircularProgress /></Box>
                )
            }

            {
                (
                    user.isFetching === false && isFetchingUserDetails === false) && (
                    <Box component="form"
                        sx={{
                            textAlign: "left",
                            marginLeft: {
                                xs: '13px',
                                md: '20vw'
                            },
                            marginTop: {
                                xs: '10vw',
                                md: '5vw'
                            },
                            marginRight: {
                                xs: '13px',
                                md: '20vw'
                            },
                            backgroundColor: JC_COLORS.JC_WHITE,
                            '& .MuiTextField-root': {},
                        }}
                        validate
                        autoComplete="off"
                        onSubmit={handleOnSubmit} mb={2}>
                        <Grid container justify="space-around" spacing={{ xs: 2, md: 3 }}>

                            <Grid item xs={12}>
                                <Typography variant="H1_BITTER" color="primary" sx={{ fontWeight: '700!important' }}>
                                    Complete your Profile
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6} pr={{ md: 5 }}>
                                <InputLabel
                                    required
                                    className={mobileMatch ? classes.inputlabelMobile : classes.inputlabel}
                                    sx={{ color: JC_COLORS.JC_ICON }}
                                >
                                    First Name
                                </InputLabel>
                                <FormControl className={mobileMatch ? classes.textfieldMobile : classes.textfield}>
                                    <OutlinedInput placeholder={"First Name"} type='text' value={firstName} onChange={handleFirstNameChange} error={firstNameError} required />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} pr={{ md: 5 }}>
                                <InputLabel
                                    required
                                    className={mobileMatch ? classes.inputlabelMobile : classes.inputlabel}
                                    sx={{ color: JC_COLORS.JC_ICON }}
                                >
                                    Last Name
                                </InputLabel>
                                <FormControl className={mobileMatch ? classes.textfieldMobile : classes.textfield}>
                                    <OutlinedInput placeholder={"Last Name"} type='text' value={lastName} onChange={handleLastNameChange} error={lastNameError} required />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6} pr={{ md: 5 }}>
                                <InputLabel
                                    required
                                    className={mobileMatch ? classes.inputlabelMobile : classes.inputlabel}
                                    sx={{ color: JC_COLORS.JC_ICON }}
                                >
                                    Institution/Organization

                                </InputLabel>


                                <FormControl className={mobileMatch ? classes.textfieldMobile : classes.textfield}>
                                    <Autocomplete
                                        freeSolo
                                        fullWidth
                                        value={institutionName}
                                        onChange={
                                            (event, newValue) => {
                                                console.log(event?.target?.value, newValue)
                                                if (newValue) {
                                                    let re = /Add "(.*?)"/;
                                                    let name = newValue.name.match(re);
                                                    setInstitutionName(name ? name[1] : newValue.name);
                                                } else {
                                                    setInstitutionName(event?.target?.value?.name);
                                                }

                                            }
                                        }
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            const { inputValue } = params;
                                            // Suggest the creation of a new value
                                            const isExisting = options.some((option) => inputValue === option.name);
                                            if (inputValue !== '' && !isExisting) {

                                                filtered.push({
                                                    institutionID: null,
                                                    logoUrl: null,
                                                    name: `${inputValue}`,
                                                    newEntry: true,
                                                });
                                            }

                                            return filtered;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        options={institutionsList}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            // Add "xxx" option created dynamically
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            // Regular option
                                            return option.name;
                                        }}
                                        renderOption={
                                            (props, option) => {
                                                if (option?.newEntry) {
                                                    return (
                                                        <li
                                                            {...props}
                                                            style={{
                                                                display: 'inline-block',
                                                            }}

                                                        >Add
                                                            <span
                                                                style={{
                                                                    fontStyle: 'italic',
                                                                    fontWeight: 700,

                                                                }}>
                                                                &nbsp;{`"${option.name}"`}
                                                            </span>
                                                        </li>
                                                    )
                                                }
                                                return (
                                                    <li
                                                        {...props}
                                                    >
                                                        {option.name}
                                                    </li>
                                                )
                                            }
                                        }
                                        sx={{
                                            width: "100%",
                                            marginTop: "0",
                                            padding: 0,
                                            '& .MuiInputBase-root': {
                                                height: '45px',
                                                padding: "0px 0px 0px 4px",
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                "& > *": {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: "0px",
                                                },
                                            },
                                            '& .MuiFormControl-root': {
                                                padding: '0px',
                                            },
                                        }}

                                        renderInput={
                                            (params) => (
                                                <TextField
                                                    variant="outlined"
                                                    {...params}
                                                    placeholder={""}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        onKeyDown: (e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                            }
                                                        }
                                                    }}
                                                />
                                            )
                                        }


                                    />

                                </FormControl>

                            </Grid>

                            <Grid item xs={12} md={6} pr={{ md: 5 }}>
                                <InputLabel
                                    required
                                    className={mobileMatch ? classes.inputlabelMobile : classes.inputlabel}
                                    sx={{ color: JC_COLORS.JC_ICON }}
                                >
                                    Profession
                                </InputLabel>
                                <FormControl className={mobileMatch ? classes.textfieldMobile : classes.textfield}>
                                    <Select
                                        value={professionName}
                                        onChange={handleProfessionChange}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        placeholder="select"
                                        displayEmpty
                                        MenuProps={MenuProps} required>
                                        <MenuItem value="">
                                            <Typography sx={{
                                                fontSize: { xs: "12px", md: "18px" },
                                                fontFamily: "Raleway",
                                                fontWeight: "700",
                                                lineHeight: "26px",
                                                color: "#838383"
                                            }}>
                                                ~Select~
                                            </Typography>
                                        </MenuItem>
                                        {ProfessionList.map((profession) => (
                                            <MenuItem
                                                key={profession}
                                                value={profession}
                                                style={getStyles(mobileMatch, profession, professionName, theme)}>
                                                {profession}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6} pr={{ md: 5 }}>
                                <InputLabel
                                    required
                                    className={mobileMatch ? classes.inputlabelMobile : classes.inputlabel}
                                    sx={{ color: JC_COLORS.JC_ICON }}
                                >
                                    Country
                                </InputLabel>
                                <FormControl className={mobileMatch ? classes.textfieldMobile : classes.textfield}>
                                    <Select
                                        value={countryName}
                                        onChange={handleCountryChange}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        displayEmpty
                                        MenuProps={MenuProps} required>
                                        <MenuItem value="">
                                            <Typography sx={{
                                                fontSize: { xs: "12px", md: "18px" },
                                                fontFamily: "Raleway",
                                                fontWeight: "700",
                                                color: "#838383"
                                            }}>
                                                ~Select~
                                            </Typography>
                                        </MenuItem>
                                        {countryList.map((country) => (
                                            <MenuItem
                                                key={country}
                                                value={country}
                                                style={getStyles(mobileMatch, country, countryName, theme)}>
                                                {country}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6} pr={{ md: 5 }}
                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}
                            >
                                <FormControlLabel

                                    label={
                                        <H3RalewayText>
                                            I am a licensed professional
                                        </H3RalewayText>}
                                    control={
                                        <Checkbox
                                            checked={isLicensedProfessional}
                                            onChange={() => setIsLicensedProfessional(!isLicensedProfessional)}
                                        />
                                    }

                                />



                            </Grid>

                            <Grid item xs={12}>
                                <InputLabel
                                    className={mobileMatch ? classes.inputlabelMobile : classes.inputlabel}
                                    sx={{ color: JC_COLORS.JC_BLACK }}
                                >
                                    About Me
                                </InputLabel>
                                <TextField
                                    //placeholder="Optional message here"
                                    value={aboutMe}
                                    multiline={true}
                                    //autoFocus={true}
                                    fullWidth={true}
                                    minRows={6}
                                    inputProps={{ maxLength: 1024 }}
                                    helperText={aboutMe ? `${aboutMe?.length}/1024` : `0/1024`}
                                    onChange={(event) => setAboutMe(event.target.value)}

                                ></TextField>

                            </Grid>

                            <Grid item xs={12}>
                                <InputLabel
                                    required
                                    className={mobileMatch ? classes.inputlabelMobile : classes.inputlabel}
                                    sx={{ color: JC_COLORS.JC_BLACK }}
                                >
                                    Areas of Interest
                                </InputLabel>
                                <Box mt={1} mx={-1}>
                                    {
                                        itemsList.map(
                                            (item) => (
                                                <Chip
                                                    className={keywordClass}
                                                    key={item.id}
                                                    label={item.title}
                                                    size="small"
                                                    sx={{
                                                        borderRadius: 1,
                                                        boxShadow: 1,
                                                        backgroundColor: item.isSelected ? JC_COLORS.JC_RED : JC_COLORS.LIGHT_GREY,
                                                        color: item.isSelected ? JC_COLORS.JC_WHITE : JC_COLORS.JC_BLACK,
                                                        "&:hover": {
                                                            borderColor: item.isSelected ? JC_COLORS.JC_RED : JC_COLORS.LIGHT_GREY,
                                                            backgroundColor: item.isSelected ? JC_COLORS.JC_RED : JC_COLORS.LIGHT_GREY
                                                        }
                                                    }}
                                                    onClick={() => areaOfInterestClick(item.id)}
                                                />
                                            )
                                        )
                                    }
                                </Box>
                            </Grid>

                        </Grid>

                        {
                            user.isUpdating && (
                                <Box sx={{ mt: "10vw" }}><CircularProgress /></Box>
                            )
                        }
                        <Box sx={{ textAlign: 'center' }} mt={2} mb={2} href="/feed">
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                                disabled={
                                    firstNameError ||
                                    lastNameError ||
                                    emptyAOI ||
                                    institutionName === "" ||
                                    institutionName === null ||
                                    institutionName === undefined ||
                                    professionName === "" ||
                                    countryName === ""
                                }
                            >
                                Done
                            </Button>
                        </Box>



                        <Divider />
                        <Box pt={2} sx={{ textAlign: 'center' }}>
                            <Typography variant="BODY_COPY2_RALEWAY" gutterBottom>
                                Journal Club &copy; 2022
                            </Typography>
                        </Box>

                    </Box>
                )
            }

        </React.Fragment >
    );
}

export default CompleteProfile