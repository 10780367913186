import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { createMemoryHistory } from "history";

import { Card, Box, Typography, Switch, Hidden, Grid, Paper, CircularProgress, Button, Select, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import { alpha, styled } from '@mui/material/styles';

import { Row, Column, Spacer, KeyWordChip } from '../../../common/components';
import { KeyWordsList } from '../../../common/containers';
import useDebounce from "../../../common/hooks/useDebounce";
import { JC_COLORS } from '../../../styles/constants.js';
import { OPTIONS_SORT_ARTICLES_BY_TIME, OPTIONS_SORT_ARTICLES_BY_TYPE } from '../../../constants/constants';
import { calcViewWidth, calcViewHeight, calcRem, profileCheck } from '../../../utils';
import ArticlesList from './ArticlesList';

import { capitalize } from '@mui/material';
import CustomRadioDateRange from '../components/CustomRadioDateRange';
import SetupKeywords from '../../../common/components/SetupKeywords';
import CenteredContainer from '../../../common/components/CenteredContainer';
import CustomDropdownDateRange from '../components/CustomDropdownDateRange';
import CustomDropdownArticleType from '../components/CustomDropdownArticleType';

import { AuthContext } from '../../../context/AuthContext';
import { setKeywords } from '../../../state/actions/keywords';
import { setSources } from "../../../state/actions/sources";
import { updateFeedQueryParameters, getUserSavedArticles, setContent } from '../../../state/actions/feed';
import { getUserDetails } from '../../../state/actions/user';
import UserProfileCheck from '../../UserProfileCheck';

const MainCard = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: JC_COLORS.JC_WHITE,
    [theme.breakpoints.up('md')]: {
        //width: "100vw", // 1369 / 1920
        //maxHeight: "75rem",  // 1203 / 1377 or 1203 to rem
        padding: "30px 0rem 54px 0rem",
    },
    [theme.breakpoints.down('md')]: {
        padding: '0rem 0rem 54px 0rem',
    }

}));

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#FFFFFF'
    },
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: JC_COLORS.JC_RED,
                opacity: 1,
                border: 0,
            },
        },
    },
    [theme.breakpoints.up('md')]: {
        width: "50px",
        height: "24px",
        padding: 0,
        margin: "0 11px",
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: "12px",
            height: "12px",
            margin: "0 3px"
        },
        '& .MuiSwitch-track': {
            borderRadius: "12px",
            backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
            opacity: 1,
        },
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: "6px 0px 6px 0px",
            '&.Mui-checked': {
                transform: 'translateX(32px)',
            }
        }
    },
    [theme.breakpoints.down('md')]: {
        width: "32px",
        height: "18px",
        padding: 0,
        margin: "0 5.5px",
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: "8px",
            height: "8px",
            margin: "1px 5px"
        },
        '& .MuiSwitch-track': {
            borderRadius: "6px",
            backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
            opacity: 1,
        },
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: "4px 0px 4px 0px",
            '&.Mui-checked': {
                transform: 'translateX(16px)',
            }
        },
    }




}));

const UnreadArticlesToggleButton = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
        marginTop: '30px',
        marginBottom: '5px',
        marginLeft: '40px',
        alignItems: 'flex-start',
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        borderBottom: "1px solid #838383",
        paddingBottom: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: "12px 15px 0px 15px",
        "*": {
            fontSize: '12px',
            lineHeight: '14.74px',
        }
    }

}));

const SwitchWithText = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
        marginTop: '12px',
    },
    [theme.breakpoints.down('md')]: {

    }

}));

const FlexEndText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: 'flex-end',
        margin: '20px 40px 12px 0px',
        fontWeight: 'bold',
        color: JC_COLORS.JC_RED,
        textDecoration: 'underline',
        cursor: 'pointer',
        ...theme.typography.BODY_COPY2_RALEWAY,
    },
    [theme.breakpoints.down('md')]: {
        display: 'none',
    }
}));


let appState = JSON.parse(localStorage.getItem("appState"));


const SavedContent = () => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const dispatch = useDispatch();

    const { feed } = useSelector((state) => state);
    const { isFetchingFeed } = feed;

    const [dateRange, setDateRange] = useState(appState ? appState.feedQueryParams.dateRange : feed.queryParameters.dateRange);
    const [dateRangeLabel, setDateRangeLabel] = useState(appState ? appState.feedQueryParams.dateRangeLabel : feed.queryParameters.dateRangeLabel);
    const [orderByLabel, setOrderByLabel] = useState(appState ? appState.feedQueryParams.orderByLabel : feed.queryParameters.orderByLabel);

   
    useEffect(() => {
        if (isFetchingFeed) return
        
        let orderBy = OPTIONS_SORT_ARTICLES_BY_TYPE[orderByLabel];
        let queryParams = {
            orderBy,
            pageNumber: 1
        }

        const { startDate, endDate } = dateRange;
        queryParams = startDate ? { ...queryParams, startDate } : { ...queryParams };
        queryParams = endDate ? { ...queryParams, endDate } : { ...queryParams };


        queryParams = {
            ...queryParams,
            dateRangeLabel,
            dateRange,
            orderByLabel,
            orderBy,

        }
        //console.log(queryParams); 
        dispatch(updateFeedQueryParameters(queryParams));
        var filterQueryParams = { pageNumber: 1, orderBy }
          if(startDate && endDate){
            filterQueryParams = {...filterQueryParams,...dateRange}
          }

        dispatch(getUserSavedArticles(userEmail, filterQueryParams));

    }, [dateRange, dateRangeLabel, orderByLabel]);



    return (
        <UserProfileCheck>
            <MainCard>
                <Hidden mdDown>
                    <Typography
                        variant="H1_BITTER_DESKTOP"
                        fontWeight='bold'
                        color="primary"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{ padding: "0 0 16px 40px" }}
                    >
                        Saved Articles
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: "0 40px",


                    }}>
                        <CustomDropdownDateRange
                            dateRangeLabel={dateRangeLabel}
                            changeDateRangeLabel={(value) => setDateRangeLabel(value)}
                            changeDateRangeHandler={(value) => setDateRange(value)}
                        />
                        <CustomDropdownArticleType
                            orderByLabel={orderByLabel}
                            changeOrderBy={(value) => setOrderByLabel(value)}
                        />
                    </Box>
                    <Spacer height={"11px"} />
                </Hidden>

                <Hidden mdUp>
                    <Box sx={{ marginTop: "72px" }} />
                </Hidden>
                {(feed.isFetchingFeed) && <CenteredContainer><CircularProgress /></CenteredContainer>}

                <Hidden mdDown>
                    <Spacer height={"32px"} />
                </Hidden>

                <ArticlesList />
            </MainCard>
        </UserProfileCheck>

    );
}
export default SavedContent


