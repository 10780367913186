import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Grid,
    InputAdornment,
    Divider,
    Link,
    styled,
    Backdrop,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';
import CustomDropdownDateRange from '../../../Feed/components/CustomDropdownDateRange';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector, useDispatch } from "react-redux";
import { fetchPublishReports } from '../../../../api';
import ProposeIcon from '../../../../assets/ProposeIcon';
import { PublishIcon } from '../../../../assets';
import { JC_COLORS } from '../../../../styles/constants';
import { REPORTS_NAV, REPORT_ROWS_PER_PAGE } from '../../../../constants/constants';
import { updateMemberId, updateReportNav, updateCurrentTab } from '../../../../state/actions/jcreports';
import { CenterFlexBox, UserCell, ArtcleTitleCell, InstitutionCell, InlineBox, SummaryBox, StyledTextField, StyledSearchButton, DownloadReportButton, EnhancedTableHead, PublishHeadCells } from './ReportHelpers';
import CustomDropdown from '../../../JCDetails/components/CustomDropdown';
import ReportTable from './ReportTable';
import { ReactComponent as Inreview } from "../../../../assets/Inreview.svg"

const PublishingReport = (props) => {
    const { reportRootFilter, rootDateRange } = useSelector((state) => state.jcreports);
    const dispatch = useDispatch()
    const [dateRangeLabel, setDateRangeLabel] = useState(reportRootFilter)
    const [articleStatusType, setArticleStatusType] = useState("All Articles")
    const [value, setValue] = useState('')
    const [publishingData, setPublishingData] = useState({})
    const [dateRange, setDateRange] = useState(rootDateRange)
    const [SearchText, setSearchText] = useState("")
    const { jcDetails } = useSelector((state) => state.journalclub);
    const [queryParameters, setQueryParameters] = useState(null)
    // report table states
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [isLoading, setIsLoading] = useState(true)

    let currentLoginUser = localStorage.getItem("jcid");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleRowClick = (event) => {
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    // const emptyRows = page > 1 ? Math.max(0, publishingData?.articles.length / REPORT_ROWS_PER_PAGE) : 0;

    const emptyRows = 0;
    const handleSearch = () => {
        setIsLoading(true)
        setSearchText(value)
        var queryParams = {
            authUserEmail: props.userEmail,
            numRecordsPerPage: REPORT_ROWS_PER_PAGE,
            pageNumber: 1,
            sort: order,
            orderBy: orderBy,
            term: value
        }
        if (articleStatusType !== "All Articles") {
            queryParams = { ...queryParams, status: articleStatusType === "In-Review" ? "Under Discussion" : articleStatusType }
        }
        setQueryParameters(queryParams)
        fetchData(queryParams)
    }

    const resetPageNumber = (val, callback) => {
        setPage(1)
        callback(val)
    }

    const handleMemberClick = (memberData) => {
        dispatch(updateCurrentTab(REPORTS_NAV.PUBLISH))
        dispatch(updateMemberId(memberData))
        dispatch(updateReportNav("Member"))
    }

    useEffect(() => {
        setIsLoading(true)
        var queryParams = {
            authUserEmail: props.userEmail,
            numRecordsPerPage: REPORT_ROWS_PER_PAGE,
            pageNumber: page,
            sort: order,
            orderBy: orderBy,
        }
        if(SearchText !== ""){
            queryParams = { ...queryParams, term: SearchText }
        }
        if (articleStatusType !== "All Articles") {
            queryParams = { ...queryParams, status: articleStatusType === "In-Review" ? "Under Discussion" : articleStatusType }
        }
        setQueryParameters(queryParams)
        fetchData(queryParams)
    }, [dateRange, articleStatusType, page, order, orderBy, SearchText])

    const fetchData = (queryParams) => {
        if (dateRange.startDate && dateRange.endDate) {
            queryParams = { ...queryParams, ...dateRange }
            setQueryParameters(queryParams)
        }
        fetchPublishReports(jcDetails?.JCID, queryParams).then(response => {
            if (response?.data) {
                setPublishingData(response.data)
                setIsLoading(false)
            }
        }).catch(error => {
            console.error(error)
        })
    }

    return (
        <React.Fragment>
            <Box sx={{ textAlign: 'left' }} my={-1} mx={-0.8}>
                <Link sx={{ cursor: 'pointer', textDecoration: 'None' }} onClick={() => dispatch(updateReportNav("Reports"))} className='report-nav-root_text'>
                    <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
                    <Typography variant="BODY_COPY2_RALEWAY">
                        {REPORTS_NAV.ROOT}
                    </Typography>
                </Link>
            </Box>

            <Box sx={{ textAlign: 'left' }} mt={2} mb={2}>
                <InlineBox>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant="H1_BITTER" color="primary" sx={{ fontWeight: '700 !important' }}>
                            {REPORTS_NAV.PUBLISH}
                        </Typography>
                    </Box>
                </InlineBox>
                <InlineBox sx={{ float: 'right' }}>
                    <CustomDropdownDateRange isHeading={false} isWhite={true}
                        dateRangeLabel={dateRangeLabel}
                        changeDateRangeLabel={(value) => setDateRangeLabel(value)}
                        changeDateRangeHandler={(value) => resetPageNumber(value, setDateRange)}
                    />
                </InlineBox>
            </Box>

            {/*Publishing Summary*/}
            {publishingData &&
                <Grid container spacing={{ xs: 1.5 }} sx={{ backgroundColor: JC_COLORS.JC_RED, borderRadius: '4px', padding: '24px', width: '100%!important', margin: '0px!important' }}>
                    <Grid item xs={12} sx={{ paddingTop: '0px!important' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={publishingData.numAllProposedArticles} title="Articles In-Review"> <Inreview width="28px" height="28px" className="white-icon" /> </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={publishingData.numAllPublishedArticles} title="Articles Published"> <PublishIcon width="28px" height="28px" />  </SummaryBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {/*Publishing Summary Ends */}
            <Box mt={3} sx={{ textAlign: 'left' }}>
                {publishingData &&
                    <Typography variant="BODY_ARTICLE_RALEWAY" sx={{ fontWeight: '700!important', marginRight: '12px' }}> {publishingData.numberOfRow !== 0 ? publishingData.numberOfRow && (publishingData.numberOfRow !== 1 ? publishingData.numberOfRow + " Articles Found" : publishingData.numberOfRow + " Article Found") : "No Result Found"}</Typography>
                }
                {isLoading &&
                    <CircularProgress color='primary' sx={{ width: '20px!important', height: '20px!important' }} />
                }
            </Box>
            <Box sx={{ textAlign: 'left' }} mt={2} mb={1}>
                <InlineBox>
                    <StyledTextField
                        value={value}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                        placeholder={"Search Article/Member"}
                        autoComplete='off'
                        inputProps={{
                            style: {
                                fontFamily: 'Raleway',
                                fontWeight: 600,
                            }
                        }}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                            endAdornment: (
                                <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                                    <CloseIcon style={{ display: `${value === "" ? 'none' : 'block'}` }} onClick={() => { setSearchText(""); setValue("") }} />
                                </InputAdornment>),
                        }}
                        onChange={(e) => { setValue(e.target.value ? e.target.value : ""); }}
                    ></StyledTextField>
                    <StyledSearchButton variant="outlined" onClick={() => handleSearch()} sx={{ marginLeft: '11px' }}>
                        Search
                    </StyledSearchButton>
                </InlineBox>
                <InlineBox sx={{ float: 'right' }}>
                    <InlineBox ml={1}>
                        <CustomDropdown
                            selectedLabel={articleStatusType}
                            listOfLabels={["All Articles", "Published", "In-Review", "Rejected"]}
                            changeLabel={(val) => resetPageNumber(val, setArticleStatusType)}
                        />
                    </InlineBox>
                    <DownloadReportButton JCID={jcDetails.JCID} parameter={"articles"} queryParameters={queryParameters} currentLoginUser={currentLoginUser} downloadName={"Publishing_Activity"} dateRange={dateRange} />
                </InlineBox>
            </Box>

            {publishingData && publishingData.articles &&
                <Box mt={3} mb={3}>
                    <Box sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none",
                                        fontFamily: 'Raleway',
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        borderRadius: '4px'
                                    },
                                    minWidth: 750
                                }}
                                aria-labelledby="tableTitle"
                                size="medium"
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={PublishHeadCells}
                                />

                                <TableBody sx={{ position: 'relative' }}>
                                    <Backdrop
                                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute', backgroundColor: 'transparent' }}
                                        open={isLoading}
                                    >
                                        <CircularProgress color='primary' />
                                    </Backdrop>
                                    {publishingData.articles.map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <React.Fragment>
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleRowClick(event)}
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "#FFF7F6!important"
                                                        },
                                                        backgroundColor: "#F3F3F3!important"
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ fontFamily: 'Raleway!important' }}
                                                    >
                                                        <ArtcleTitleCell title={row.title} urlSlug={jcDetails.urlSlug} PMID={row.PMID} isDenovoArticle={row.isDenovoArticle} />
                                                    </TableCell>
                                                    <TableCell align="left"> <UserCell name={row.proposedBy ? row.proposedBy.firstName + " " + row.proposedBy.lastName : "-"} contracted={true} onClick={() => handleMemberClick(row.proposedBy)} /></TableCell>
                                                    <TableCell align="left"><Typography variant="H3_RALEWAY" color="#262626" sx={row.status === "Under Discussion" ? { color: '#EF5B50 !important', fontStyle: "italic" } : row.status === "Rejected" && { fontStyle: "italic" }}>{row.status === "Under Discussion" ? "In-Review" : row.status}</Typography></TableCell>
                                                    <TableCell align="center">{row.numberOfComments}</TableCell>
                                                </TableRow>
                                                <Box sx={{ minHeight: '12px' }}></Box>
                                            </React.Fragment>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={3} sx={{ marginLeft: 'calc( 50% - 80px)' }}>
                            <Pagination count={Math.ceil(publishingData.numberOfRow / REPORT_ROWS_PER_PAGE)} color="primary" page={page} onChange={handleChangePage} />
                        </Box>
                    </Box>
                </Box>
            }

        </React.Fragment>
    )
}

export default PublishingReport

