import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Chip,
  Avatar,
  Paper,
  Link,
  InputAdornment,
  Backdrop,
  useMediaQuery,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CircularProgress from '@mui/material/CircularProgress';
import { CenteredContainer } from '../../common/components';
import {
  CommentedIcon,
  CommentIcon,
  LikedIcon,
  NotedIcon,
  NoteIcon,
  ClubIcon,
  PublishIcon,
  ViewsIcon,
  SupportArticle,
  DenovoReadIcon,
  SortIconMobile,
} from '../../assets';
import SearchIcon from '@mui/icons-material/Search';
import MessageIcon from '@mui/icons-material/Message';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import ProposeIcon from '../../assets/ProposeIcon';
import PracticeIcon from '../../assets/PracticeIcon';
import Hidden from '@mui/material/Hidden';
import { JC_COLORS } from '../../styles/constants';
import {
    ACTIVITY_DEFAULTS,
    DEFAULT_JC,
  JC_CONTENT,
  MEMBERSHIP_STATUS,
  REPORTS_NAV,
  REPORT_ROWS_PER_PAGE,
} from '../../constants/constants';
import {
  CenterFlexBox,
  ArtcleTitleCell,
  InstitutionCell,
  InlineBox,
  SummaryBox,
  StyledTextField,
  StyledSearchButton,
  DownloadActivityReportButton,
  EnhancedTableHead,
  UserActivityHeadCell,
  BlackDot,
  JCChip,
  DefaultSelectedJCList
} from '../JCDetails/jc-content/report-content/ReportHelpers';
import { getJournalClubDetails, getJCUserDetails, getJournalClubs } from '../../state/actions/journalclub';
import CustomDropdownDateRange from '../Feed/components/CustomDropdownDateRange';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../JCDetails/components/CustomDropdown';
import JCListDropdown from './component/JCListDropdown';
import {stringToColor} from '../../utils';
import { fetchUserActivityRoport } from '../../api';
import { useTheme } from '@emotion/react';
import { ReactComponent as Edit } from '../../assets/Edit.svg';
import { JCTopDrawer,JCTopDateRangeDrawer,JCTableSortDrawer } from './component/JCTopDrawer';
import { useNavigate } from 'react-router';
import { getUserDetails } from '../../state/actions/user';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#262626',
  padding: theme.spacing(1),
  textAlign: 'left',
  minHeight: '100vh',
  margin: '0px 11px',
  padding: '24px 36px',
  [theme.breakpoints.down('md')]: {
    borderRadius: 'None',
    boxShadow: 'None',
    margin: '0px 0px',
    padding: '72px 12px 24px 12px',
  },
}));

const DefaultSelectedJC = {
    name : "All Activities",
    JCID : '',
    labelView : true,
    apiKey : 'All'   
}
const ArticleStatusList = ['All Articles','Published','In-Review','Rejected']

function UserActivity({userEmail}) {
  const [userActivityData, setUserActivityData] = useState({});
  const [dateRangeLabel, setDateRangeLabel] = useState('All Time');
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [value, setValue] = useState('');
  const [SearchText, setSearchText] = useState('');
  const [rootLoading, setRootLoading] = useState(true);
  const [isValidReport,setValidReport] = useState(false)
  const [selectedJC,setSelectedJC] = useState({})
  const [displayList,setDisplayList] = useState([])
  const [articleStatusType, setArticleStatusType] = useState('All Articles');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const theme = useTheme()
  const mobileMatch = useMediaQuery(theme.breakpoints.down('md'))
  const { jcList,isFetchingJournalClubs,jcDetails } = useSelector((state) => state.journalclub);
  const {userDetails} = useSelector((state) => state.user);
  const [queryParameters, setQueryParameters] = useState(null);

  // report table states
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState(true);

  // Top Drawer state
  const [selectJCDrawer,setSelectJCDrawer] = useState(false)
  const [dateRangeDrawer,setDateRangeDrawer] = useState(false)
  const [sortTableDrawer,setSortTableDrawer] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(1);
  };
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const resetPageNumber = (val, callback) => {
    setPage(1);
    callback(val);
  };
  const handleRowClick = (event) => {
    // Todo:: handle Row click
  };
  const handleSearch = () => {
    setIsLoading(true)
    setSearchText(value)
    var queryParams = {
      authUserEmail: userEmail,
      numRecordsPerPage: REPORT_ROWS_PER_PAGE,
      pageNumber: 1,
      sort: order,
      orderBy: orderBy,
      term: value
    }
    setQueryParameters(queryParams)
    fetchData(queryParams)
  }
  const getJCIconColor = (JCID, title) => {
    return (
      jcList.filter((JC) => JC.JCID === JCID)[0]?.iconColor ??
      stringToColor(title ?? '')
    );
  };

  useEffect(() => {
    dispatch(getJournalClubs(userEmail))
    dispatch(getUserDetails(userEmail))   
  }, []);
  
  useEffect(() => {
    if(jcList && isFetchingJournalClubs === false){
        const listOfJC = jcList.filter(obj => obj.membershipStatus && (
            obj.membershipStatus === MEMBERSHIP_STATUS.JOINED || 
            obj.membershipStatus === MEMBERSHIP_STATUS.REMOVED || 
            obj.membershipStatus === MEMBERSHIP_STATUS.LEFT 
        ))
        var tempDisplayList = []
        if(listOfJC.length === 0){
            tempDisplayList = DefaultSelectedJCList.filter(JC => JC.apiKey === ACTIVITY_DEFAULTS.FEED_KEY)
            setSelectedJC(DefaultSelectedJCList[1])
        }else if(listOfJC.length === 1){
            tempDisplayList = DefaultSelectedJCList.filter(JC => JC.apiKey != ACTIVITY_DEFAULTS.JC_KEY)
            tempDisplayList = [...tempDisplayList,...listOfJC]
            setSelectedJC(DefaultSelectedJCList[0])
        }else{
            tempDisplayList = [...DefaultSelectedJCList,...listOfJC]
            setSelectedJC(DefaultSelectedJCList[0])
        }
        setDisplayList(tempDisplayList)
    }  
  }, [jcList,isFetchingJournalClubs]);

  useEffect(() => {
    setIsLoading(true)
    if(!userActivityData){
      setRootLoading(true)
    }
    var queryParams = {
      authUserEmail: userEmail,
      numRecordsPerPage: REPORT_ROWS_PER_PAGE,
      pageNumber: page,
      sort: order,
      orderBy: orderBy,
    }
    if(SearchText !== ""){
      queryParams = { ...queryParams, term: SearchText }
    }
    if(displayList.length !== 0){
        fetchData(queryParams)
    }
  }, [dateRange, page, order, orderBy, SearchText,selectedJC,articleStatusType])

  const fetchData = (queryParams) => {
    if (dateRange.startDate && dateRange.endDate) {
      queryParams = { ...queryParams, ...dateRange }
    }
    if(selectedJC){
        queryParams = { ...queryParams,activityFilter: selectedJC.apiKey,JCID: selectedJC.JCID }
      }
    if (articleStatusType !== "All Articles") {
        queryParams = { ...queryParams, articleStatus: articleStatusType === "In-Review" ? "Under Discussion" : articleStatusType }
    }
    setQueryParameters(queryParams)
    fetchUserActivityRoport(userEmail, queryParams).then(response => {
      if (response?.data) {
        if( !response.data.numAllArticlesRead && queryParams.activityFilter === ACTIVITY_DEFAULTS.FEED_KEY && displayList.length <= 1){
            setValidReport(false)
        }else {
            setValidReport(true)
            setUserActivityData(response.data)
        }
        setIsLoading(false)
        setRootLoading(false)
      }
    }).catch(error => {
      console.error(error)
    })
  }
  return (
    <Item>
      <Box mx={-1} mb={2}>
        <Link
          sx={{ cursor: 'pointer', textDecoration: 'None' }}
          onClick={() => navigate(-1)}
          className="report-nav-root_text"
        >
          <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
          <Typography variant="BODY_COPY2_RALEWAY">Go Back</Typography>
        </Link>
      </Box>
      {/*My Activity Title */}
      <Hidden mdDown>
        <Box mb={4}>
          <InlineBox>
            <Box>
              <Typography
                variant="H1_BITTER"
                color="primary"
                sx={{ fontWeight: '700 !important' }}
              >
                My Activity
              </Typography>
            </Box>
          </InlineBox>
          {isValidReport &&
          <InlineBox sx={{ float: 'right' }}>
            <InlineBox>
                <JCListDropdown selectedJC={selectedJC} listOfJC={displayList} jcList={jcList} changeSelectedJC={(value) => resetPageNumber(value,setSelectedJC)}/>
            </InlineBox>
            <InlineBox ml={1}>
                <CustomDropdownDateRange
                isHeading={false}
                isWhite={true}
                dateRangeLabel={dateRangeLabel}
                changeDateRangeLabel={(value) => setDateRangeLabel(value)}
                changeDateRangeHandler={(value) => resetPageNumber(value,setDateRange)}
                />
            </InlineBox>
          </InlineBox>
          }
        </Box>
      </Hidden>
      {rootLoading && 
        <Box sx={{minHeight:'400px'}}>
          <CenteredContainer><CircularProgress /></CenteredContainer>
        </Box>
      }
      {isValidReport &&
      <Hidden mdUp>
        <Box mb={2}>
            <Button variant="outlined" size="medium" endIcon={<Edit width="16px" height="18px" />} sx={{fontFamily:'Raleway',fontWeight:'600',textTransform:'none',minHeight:'39px'}} onClick={() => setDateRangeDrawer(true)}>
                { dateRangeLabel }
            </Button>
            <InlineBox sx={{float:'right'}}>
                <Button variant="outlined" size="small" endIcon={<Edit width="16px" height="18px" />} sx={{fontFamily:'Raleway',fontWeight:'600',textTransform:'none',minHeight:'39px'}} onClick={() => setSelectJCDrawer(true)}>
                {selectedJC.labelView ? selectedJC.name : (
                    <JCChip jcName={selectedJC.name} jcColor={getJCIconColor(selectedJC.JCID,selectedJC.name)} isMobile={true} nameColor={JC_COLORS.JC_RED} maxWidth="20ch" />  
                )}
                </Button>
            </InlineBox>
        </Box>
      </Hidden>
        }
      {/*My Activity Summary */}
      {isValidReport &&
      <Grid
        container
        spacing={{ xs: 1.5 }}
        sx={{
          backgroundColor: JC_COLORS.JC_RED,
          borderRadius: '4px',
          padding: { xs: '18px 0px', md: '24px' },
          width: '100%!important',
          margin: '0px !important',
        }}
      >
        <Grid item xs={12} sx={{ paddingTop: '0px!important' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <SummaryBox numberOf={userActivityData.numAllArticlesRead} title="Articles Read">
                <ViewsIcon width={mobileMatch ? '24px':'28px'} height={mobileMatch ? '24px':'28px'} />{' '}
              </SummaryBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SummaryBox
                numberOf={userActivityData.numAllCMECredits}
                title="CME/CE Credits Accumulated"
                isfloat={true}
              >
                <ClubIcon height={mobileMatch ? '24px':'28px'} width={mobileMatch ? '24px':'28px'} />
              </SummaryBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SummaryBox numberOf={userActivityData.numAllArticlesProposed} title="Articles Proposed">
                <ProposeIcon className="activity-white-icon" />{' '}
              </SummaryBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SummaryBox numberOf={userActivityData.numAllArticlesPublished} title="Articles Published">
                <PublishIcon width={mobileMatch ? '24px':'28px'} height={mobileMatch ? '24px':'28px'} />{' '}
              </SummaryBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SummaryBox numberOf={userActivityData.numAllStartedDenovoArticles} title="Started Original Articles">
                <DenovoReadIcon width={mobileMatch ? '24px':'28px'} height={mobileMatch ? '24px':'28px'} />
              </SummaryBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SummaryBox numberOf={userActivityData.numAllArticlesLikes} title="Total Likes">
                <LikedIcon className="activity-white-icon" />
              </SummaryBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SummaryBox numberOf={userActivityData.numAllArticlesComments} title="Total Comments">
                {' '}
                <CommentedIcon className="activity-white-icon" />{' '}
              </SummaryBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SummaryBox numberOf={userActivityData.numAllArticlesNotes} title="Total Notes">
                {' '}
                <NotedIcon className="activity-white-icon" />{' '}
              </SummaryBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <SummaryBox
                numberOf={userActivityData.numAllArticlesPI}
                title="Total Practice Impacting Points"
              >
                <PracticeIcon width={mobileMatch ? '24px':'28px'} height={mobileMatch ? '24px':'28px'} fillcolor="#FFFFFF"/>
              </SummaryBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    }
      {/*My Activity Summary ends */}

      <Box mt={3} sx={{ textAlign: 'left' }}>
        {userActivityData && isValidReport && (
          <Typography
            variant="BODY_ARTICLE_RALEWAY"
            sx={{ fontWeight: '700!important', marginRight: '12px' }}
          >
            {' '}
            {userActivityData.numberOfRow !== 0
              ? userActivityData.numberOfRow &&
                (userActivityData.numberOfRow !== 1
                  ? userActivityData.numberOfRow + ' Articles Found'
                  : userActivityData.numberOfRow + ' Article Found')
              : 'No Result Found'}
          </Typography>
        )}
        {isLoading && (
          <CircularProgress
            color="primary"
            sx={{ width: '20px!important', height: '20px!important' }}
          />
        )}
      </Box>

      {isValidReport &&
      <Hidden mdUp>
          <CenterFlexBox mt={2} sx={{justifyContent:'right'}}>
            {selectedJC.name != ACTIVITY_DEFAULTS.FEED && selectedJC.name != ACTIVITY_DEFAULTS.ALL &&
            <CustomDropdown
                selectedLabel={articleStatusType}
                listOfLabels={ArticleStatusList}
                changeLabel={(val) => resetPageNumber(val, setArticleStatusType)}
            />
            }
            <DownloadActivityReportButton
                queryParameters={queryParameters}
                userEmail={userEmail}
                downloadName={'Activity'}
                dateRange={dateRange}
            />
            <InlineBox ml={0.5}>
                <SortIconMobile height="40px" width="28px" onClick={() => setSortTableDrawer(true)}/>
            </InlineBox>

          </CenterFlexBox>  
      </Hidden>
      }

      {isValidReport &&
      <Box sx={{ textAlign: 'left' }} mt={{xs:2,md:4}} mb={1}>
        <InlineBox>
          <StyledTextField
            value={value}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            placeholder={'Search Article'}
            autoComplete="off"
            inputProps={{
              style: {
                fontFamily: 'Raleway',
                fontWeight: 600,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                  <CloseIcon
                    style={{ display: `${value === '' ? 'none' : 'block'}` }}
                    onClick={() => {
                      setSearchText('');
                      setValue('');
                    }}
                  />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setValue(e.target.value ? e.target.value : '');
            }}
          ></StyledTextField>
          <StyledSearchButton
            variant="outlined"
            onClick={() => handleSearch()}
            sx={{ marginLeft: '11px' }}
          >
            Search
          </StyledSearchButton>
        </InlineBox>
        <Hidden mdDown>
        <InlineBox sx={{ float: 'right' }}>
          {selectedJC.name != ACTIVITY_DEFAULTS.FEED && selectedJC.name != ACTIVITY_DEFAULTS.ALL &&
          <InlineBox ml={1}>
            <CustomDropdown
              selectedLabel={articleStatusType}
              listOfLabels={ArticleStatusList}
              changeLabel={(val) => resetPageNumber(val, setArticleStatusType)}
            />
          </InlineBox>
          }
          <DownloadActivityReportButton
            queryParameters={queryParameters}
            userEmail={userEmail}
            downloadName={'Activity'}
            label={"Download Activity Report"}
            dateRange={dateRange}
          />
        </InlineBox>
        </Hidden>
      </Box>
        }
      {isValidReport && userActivityData && userActivityData.member_articles && (
        <Box mt={3} mb={3}>
          <Box sx={{ width: '100%' }}>
            <TableContainer>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: 'none',
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: '16px',
                    borderRadius: '4px',
                  },
                  minWidth: mobileMatch ? 300 : 750,
                }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <Hidden mdDown>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={UserActivityHeadCell}
                />
                </Hidden>

                <TableBody sx={{ position: 'relative' }}>
                  <Backdrop
                    sx={{
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                      position: 'absolute',
                      backgroundColor: 'transparent',
                    }}
                    open={isLoading}
                  >
                    <CircularProgress color="primary" />
                  </Backdrop>
                  {!mobileMatch && userActivityData.member_articles.map((row, index) => {
                    const labelId = `enhanced-table-desktop-${index}`;
                    return (
                      <React.Fragment>
                        <TableRow
                          hover
                          onClick={(event) => handleRowClick(event)}
                          tabIndex={-1}
                          key={labelId}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#FFF7F6!important',
                              '& .MuiChip-root': {
                                backgroundColor: '#FFF7F6!important',
                              },
                            },
                            backgroundColor: '#F3F3F3!important',
                            '& .MuiChip-root': {
                              backgroundColor: '#F3F3F3!important',
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ fontFamily: 'Raleway!important' }}
                          >
                            <ArtcleTitleCell
                              title={row.title}
                              urlSlug={row.jcSlug}
                              PMID={row.PMID}
                              isDenovoArticle={row.isDenovoArticle}
                              maxWidth='30ch'
                            />
                          </TableCell>
                          <TableCell>
                            {row.jcName ? <JCChip jcName={row.jcName} jcColor={getJCIconColor(row.JCID, row.jcName)} bgColor="#F3F3F3" maxWidth="30ch"/> : "-"}
                          </TableCell>
                          <TableCell align="center">{row.cmeCredits ? row.cmeCredits : "-"}</TableCell>
                          <TableCell align="center">
                            <Typography variant="H3_RALEWAY" color="#262626" sx={row.proposed_by === userDetails.id ? row.status === "Under Discussion" ? { color: '#EF5B50 !important', fontStyle: "italic" } : row.status === "Rejected" && { fontStyle: "italic" } : { color: "#262626" }}>
                            { !row.jcSlug ? row.statusUpdatedOn :
                                row.proposed_by === userDetails.id ? row.status === "Under Discussion" ? "In-Review" : row.status === "Published" ? row.statusUpdatedOn : row.status : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">{row.readDate ? row.readDate : '-' }</TableCell>
                          <TableCell align="center">
                            {row.numberOfUserComments}
                          </TableCell>
                          <TableCell align="center">
                            { row.numberOfUserNotes === null ? "-" : row.numberOfUserNotes}
                          </TableCell>
                        </TableRow>
                        <Box sx={{ minHeight: '12px' }}></Box>
                      </React.Fragment>
                    );
                  })}
                  {mobileMatch && userActivityData.member_articles.map((row, index) => {
                    const labelId = `enhanced-table-mobile-${index}`;
                    return (
                      <React.Fragment>
                        <TableRow
                          hover
                          onClick={(event) => handleRowClick(event)}
                          tabIndex={-1}
                          key={labelId}
                          sx={{
                            backgroundColor: '#F3F3F3!important',
                            '& .MuiChip-root': {
                              backgroundColor: '#F3F3F3!important',
                            },
                          }}
                        >
                          <Box sx={{ fontFamily: 'Raleway!important',padding:'none' }} pb={2}>
                            <ArtcleTitleCell
                              title={row.title}
                              urlSlug={row.jcSlug}
                              PMID={row.PMID}
                              isMobile={true}
                              maxWidth='30ch'
                            />
                            {row.jcName &&
                            <Box ml={2}>
                            <JCChip
                              jcName={row.jcName}
                              jcColor={getJCIconColor(row.JCID, row.jcName)}
                              bgColor="#F3F3F3"
                              maxWidth="50ch"
                              isMobile={true}
                            />
                            </Box>
                            }
                            <Box ml={3}>
                            <InlineBox>
                                <Typography variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'600!important'}}>
                                    { row.cmeCredits ? row.cmeCredits : "-" }{' '} CME/CE Credits
                                </Typography>
                            </InlineBox>
                            <InlineBox sx={{float:'right'}} mr={3}>
                                <Typography variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'600!important'}}>
                                    { row.readDate ? row.readDate : "-" }
                                </Typography>
                            </InlineBox>
                            </Box>
                            <Box ml={3}>
                            <InlineBox>
                                <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_ICON} sx={{fontWeight:'600!important'}}>
                                    Published On:
                                </Typography>
                            </InlineBox>
                            <InlineBox sx={{float:'right'}} mr={3}>
                                <Typography variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'600!important'}}>
                                { !row.jcSlug ? row.statusUpdatedOn :
                                    row.proposed_by === userDetails.id ? row.status === "Under Discussion" ? "In-Review" : row.status === "Published" ? row.statusUpdatedOn : row.status : "-"
                                }
                                </Typography>
                            </InlineBox>
                            </Box>
                            {(row.numberOfUserComments != 0 || row.numberOfNotes !=0 ) &&
                            <Box ml={3}>
                                <CenterFlexBox>
                                    <InlineBox mr={1} mt={1}>
                                        <CommentIcon width="20px" height="20px" />
                                    </InlineBox>   
                                    <Typography variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'600!important'}}>
                                        { row.numberOfUserComments }
                                    </Typography>
                                    <InlineBox ml={3} mr={1} mt={0.5}>
                                        <NoteIcon width="20px" height="20px" />
                                    </InlineBox>
                                    <Typography variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'600!important'}}>
                                        { row.numberOfUserNotes === null ? "-" : row.numberOfUserNotes}
                                    </Typography>                                    
                                </CenterFlexBox>
                            </Box>
                            }
                          </Box>
                        </TableRow>
                        <Box sx={{ minHeight: '12px' }}></Box>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={3} sx={{ display:'flex',justifyContent:'center'}} mb={{xs:8,md:3}}>
              <Pagination
                count={Math.ceil(
                  userActivityData.numberOfRow / REPORT_ROWS_PER_PAGE
                )}
                color="primary"
                page={page}
                onChange={handleChangePage}
                sx={{'& .MuiPaginationItem-root':{fontFamily: 'Raleway'}}}
              />
            </Box>
          </Box>
        </Box>
      )}
        {!isValidReport && !rootLoading && <NoActivityReport />}
      {/* Top drawers */}
      <JCTopDrawer title={"Filter Journal Clubs"} selectedJC={selectedJC} listOfJC={displayList} jcList={jcList} changeSelectedJC={(value) => resetPageNumber(value,setSelectedJC)} drawerOpen={selectJCDrawer} onClose={() => setSelectJCDrawer(false)} />
      <JCTopDateRangeDrawer title={"Filter Date Range"} dateRange={dateRange} dateRangeLabel={dateRangeLabel} drawerOpen={dateRangeDrawer} onClose={() => setDateRangeDrawer(false)} setDateRange={(value) => resetPageNumber(value,setDateRange)} setDateRangeLabel={(value) => setDateRangeLabel(value)} />
      <JCTableSortDrawer title={"Sort Articles"} order={order} orderBy={orderBy} setOrder={(value) => resetPageNumber(value,setOrder)} setOrderBy={(value) => resetPageNumber(value,setOrderBy)} UserActivityHeadCell={UserActivityHeadCell} drawerOpen={sortTableDrawer} onClose={() => setSortTableDrawer(false)} />
    </Item>
  );
}

export default UserActivity;

const NoActivityReport = () => {
    const navigate = useNavigate()
    return (
      <Box sx={{backgroundColor: '#EF5B50',borderRadius: '4px',color: '#FFFFFF'}} mt={{ xs: 2, md: 4 }}>
        <Box p={{ xs: 2, md: 3 }}>
          <Box>
            <Typography variant="H2_BITTER">
             Seems like you have not started your activity.
            </Typography>
          </Box>
  
          <Box mt={{ xs: 1, md: 2 }}>
            <Typography variant="H3_RALEWAY">
            Start your activity by exploring your feed or by becoming member of different Journal Clubs.
            </Typography>
          </Box>
          <Box mt={{ xs: 3, md: 3 }}>
            <Box sx={{ display: 'inline-block' }}>
                <Button variant='outlined' size="small" color="primary" onClick={() => navigate('/feed')} 
                sx={{border:'1px solid #FFFFFF',color:'#FFFFFF',textTransform:'none',fontFamily: 'Raleway',
                    fontSize: {
                        xs: '12px',
                        md: '12px'
                    },
                    fontWeight: '700',
                    "&.MuiButtonBase-root:hover": {
                        border:'1px solid #FFFFFF'
                    }
                }}
                >
                    Explore Feed
                </Button>
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={2}>
                <Button variant='contained' size="small" color="primary" onClick={() => navigate('/join-a-journal-club')} sx={{
                    border:'1px solid #FFFFFF',
                    backgroundColor: '#FFFFFF',
                    textTransform:'none',
                    color: '#EF5B50',
                    fontFamily: 'Raleway',
                    fontSize: {
                        xs: '12px',
                        md: '12px'
                    },
                    fontWeight: '700',
                    "&.MuiButtonBase-root:hover": {
                        bgcolor: "#FFFFFF"
                    }
                }}>
                    Join Journal Club
                </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };