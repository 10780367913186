import { SET_ABSTRACT_DOCUMENT_FILES, SET_ABSTRACT_IMAGE_FILES, SET_ABSTRACT_VIDEO_FILES, SET_BODY_DOCUMENT_FILES, SET_BODY_IMAGE_FILES, SET_BODY_VIDEO_FILES } from "../state/actions/article";
import { SET_IMAGE_FILES,SET_VIDEO_FILES,SET_DOCUMENT_FILES } from '../state/actions/comment';
import { CommentHistory,CommentCustomSkin,linkPreviewSettings } from "./editorBasics";
import { store } from '../index';
import { v4 } from 'uuid';

// Module for Comment,Notes and reply
export const CommentModules = {
  toolbar: {
    container: '#toolbar',
  },
  linkPreview: {
    link: {
      trigger: /[\s]/,
      find: /https?:\/\/[\S]+|(www\.[\S]+)/gi,
      transform: function (value, noProtocol) {
        return noProtocol ? 'http://' + value : value
      },
      format: 'link'
    }
  },
  customskin: CommentCustomSkin,
  history: CommentHistory,
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const url = window.URL.createObjectURL(file);
        setTimeout(function () {
          const newId = v4()
          store.dispatch({ type: SET_IMAGE_FILES, payload: { id: newId, src: url,file: file } });
          return resolve({ id: newId,alt: file.name, src: url });
        }, 1000);
      });
    },
  },
  videoUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const url = window.URL.createObjectURL(file);
        setTimeout(function () {
          const newId = v4()
          store.dispatch({ type: SET_VIDEO_FILES, payload: { id: newId, src: url,file: file } });
          return resolve({ id: newId, src: url });
        }, 1000);
      });
    },
  },
  attachmentUploader: {
    accept: `application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf`,
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const url = window.URL.createObjectURL(file);
        setTimeout(function () {
          const newId = v4()
          store.dispatch({ type: SET_DOCUMENT_FILES, payload: { id: newId, src: url,file: file } });
          return resolve({ id: newId, src: url });
        }, 1000);
      });
    },
  },
};
// Module for article body in orignial article
export const ArticleBodyModule = {
    toolbar: {
      container: '#toolbar',
    },
    linkPreview: linkPreviewSettings,
    customskin: CommentCustomSkin,
    history: CommentHistory,
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const url = window.URL.createObjectURL(file);
          setTimeout(function () {
            const newId = v4()
            store.dispatch({ type: SET_BODY_IMAGE_FILES, payload: { id: newId, src: url,file: file } });
            return resolve({ id: newId,alt: file.name, src: url });
          }, 1000);
        });
      },
    },
    videoUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const url = window.URL.createObjectURL(file);
          setTimeout(function () {
            const newId = v4()
            store.dispatch({ type: SET_BODY_VIDEO_FILES, payload: { id: newId, src: url,file: file } });
            return resolve({ id: newId, src: url });
          }, 1000);
        });
      },
    },
    attachmentUploader: {
      accept: `application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf`,
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const url = window.URL.createObjectURL(file);
          setTimeout(function () {
            const newId = v4()
            store.dispatch({ type: SET_BODY_DOCUMENT_FILES, payload: { id: newId, src: url,file: file } });
            return resolve({ id: newId, src: url });
          }, 1000);
        });
      },
    },
};

// Module for article abstract/summary in orignial article
export const ArticleAbstractModule = {
    toolbar: {
      container: '#toolbar',
    },
    customskin: CommentCustomSkin,
    linkPreview: linkPreviewSettings,
    history: CommentHistory,
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const url = window.URL.createObjectURL(file);
          setTimeout(function () {
            const newId = v4()
            store.dispatch({ type: SET_ABSTRACT_IMAGE_FILES, payload: { id: newId, src: url,file: file } });
            return resolve({ id: newId,alt: file.name, src: url });
          }, 1000);
        });
      },
    },
    videoUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const url = window.URL.createObjectURL(file);
          setTimeout(function () {
            const newId = v4()
            store.dispatch({ type: SET_ABSTRACT_VIDEO_FILES, payload: { id: newId, src: url,file: file } });
            return resolve({ id: newId, src: url });
          }, 1000);
        });
      },
    },
    attachmentUploader: {
      accept: `application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf`,
      upload: (file) => {
        return new Promise((resolve, reject) => {
          const url = window.URL.createObjectURL(file);
          setTimeout(function () {
            const newId = v4()
            store.dispatch({ type: SET_ABSTRACT_DOCUMENT_FILES, payload: { id: newId, src: url,file: file } });
            return resolve({ id: newId, src: url });
          }, 1000);
        });
      },
    },
};