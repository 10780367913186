import {
  BottomAppBar,
  CenteredActionButton,
} from '../../common/components';
import { NavbarDesktop, NavbarMobile } from '../../common/containers';
import { Hidden, Box, Grid } from '@mui/material';
import UserActivity from './UserActivity';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

function UserActivityPage() {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  return (
    <>
      <Hidden mdDown>
        <NavbarDesktop />
        <Box
          sx={{
            margin: '88px auto auto auto',
            maxWidth: '1440px',
          }}
        >
          <Grid container direction="row" justifyContent="center">
            <Grid item md={12}>
              <UserActivity userEmail={userEmail}/>
            </Grid>
          </Grid>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <NavbarMobile title={'My Activity'} />
        <Box>
          <UserActivity userEmail={userEmail}/>
        </Box>
        {userEmail && <BottomAppBar />}
      </Hidden>
    </>
  );
}

export default UserActivityPage;
