import {
    FETCHING_JOURNALCLUBS,
    FETCH_JOURNALCLUBS_SUCCESS,
    FETCH_JOURNALCLUBS_FAILURE,
    FETCHING_JOURNALCLUB_DETAILS,
    FETCHING_USER_ROLE_IN_JC,
    FETCH_JOURNALCLUB_DETAILS_SUCCESS,
    FETCH_JOURNALCLUB_DETAILS_FAILURE,
    SET_JC_CONTENT,
    FETCH_JC_USER,
    JOINED_JC,
    LEFT_JC,
    JC_INVITE_ACCEPTED,
    JC_INVITE_REJECTED,
    RESET_JC_ACTION,
    FETCHING_JC_PUBLICDATA,
    FETCH_JC_PUBLICDATA_SUCCESS,
    FETCH_JC_PUBLICDATA_FAILURE,
    RESET_JC_DETAILS, 
} from "../actions/journalclub";

const initialState = {
    isFetchingJournalClubs: null,
    isFetchingDetails: null,
    isFetchingUserRoleInJC: null,
    jcList: [],
    jcDetails: {},
    jcUserDetails: {},
    jcContent: "",
    error: null,
    jcAction: null, // JOIN/LEAVE/ACCEPT/REJECT
    jcDetailsPublicData: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_JOURNALCLUBS:
            return {
                ...state,
                isFetchingJournalClubs: true,
            }
        case FETCH_JOURNALCLUBS_SUCCESS:
            return {
                ...state,
                jcList: action.payload,
                isFetchingJournalClubs: false,
            }
        case FETCH_JOURNALCLUBS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetchingJournalClubs: false,
            }
        case FETCHING_JOURNALCLUB_DETAILS:
            return {
                ...state,
                isFetchingDetails: true,
            }
        case FETCHING_USER_ROLE_IN_JC:
            return {
                ...state,
                isFetchingUserRoleInJC: true,
            }
        case FETCH_JOURNALCLUB_DETAILS_SUCCESS:
            return {
                ...state,
                jcDetails: action.payload,
                isFetchingDetails: false,
            }
        case FETCH_JOURNALCLUB_DETAILS_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetchingDetails: false,
            }
        case FETCH_JC_USER:
            return {
                ...state,
                isFetchingUserRoleInJC: false,
                jcUserDetails: action.payload
            }
        case SET_JC_CONTENT:
            return {
                ...state,
                jcContent: action.payload,
            }
        case JOINED_JC:
            return {
                ...state,
                jcAction: action.payload
            }
        case LEFT_JC:
            return {
                ...state,
                jcAction: action.payload
            }
        case JC_INVITE_ACCEPTED:
            return {
                ...state,
                jcAction: action.payload
            }
        case JC_INVITE_REJECTED:
            return {
                ...state,
                jcAction: action.payload
            }
        case RESET_JC_ACTION:
            return {
                ...state,
                jcAction: null
            }
        case FETCHING_JC_PUBLICDATA:
            return {
                ...state,
                isFetchingDetails: true,
            }
        case FETCH_JC_PUBLICDATA_SUCCESS:
            return {
                ...state,
                jcDetailsPublicData: action.payload,
                isFetchingDetails: false
            }
        case FETCH_JC_PUBLICDATA_FAILURE:
            return {
                ...state,
                error: action.payload,
                isFetchingDetails: false,
            }
        case RESET_JC_DETAILS: 
            return {
                ...state, 
                jcDetails: {}, 
            }
        default:
            return state;
    }
}