import React from "react";

const LikeIcon = ({className,fillcolor}) => {
    return (
        <svg className={className} width="28" height="27" viewBox="0 0 28 27" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
        <path d="M26.1387 23.1426C26.1387 22.5772 25.9583 22.0537 25.6499
         21.6201C26.4822 21.1542 27.0467 20.2836 27.0467 19.2854C27.0467 
         18.7017 26.8531 18.1605 26.5257 17.7182C27.3161 17.2436 27.8449
          16.3957 27.8449 15.4284C27.8449 14.7385 27.5746 14.1081 27.1325 
          13.631C27.7374 13.1366 28.125 12.3979 28.125 11.5713C28.125 10.08
           26.8686 8.87487 25.3333 8.87487H18.7917V5.66997C18.7917 4.4025 18.6093
            3.65236 18.1036 2.93148L18.1028 2.93049C17.9438 2.70841 17.7557
            2.49313 17.5417 2.25072L17.5414 2.25048C17.2423 1.91335 16.9077
            1.53339 16.5122 0.962792C16.0415 0.279561 15.2514 -0.125 14.4 
            -0.125C13.0127 -0.125 11.875 0.965641 11.875 2.31427V5.66047L10.6411 
            9.22634L9.44745 10.3785C9.34932 9.53106 8.60275 8.87484 7.70657 
            8.87484H1.62681C0.664812 8.87484 -0.125 9.62996 -0.125 10.5685V25.4313C-0.125
            26.3698 0.664746 27.125 1.62681 27.125H7.70802C8.67002 27.125 9.45983 26.3699 
            9.45983 25.4313V25.0248L10.9813 25.7583C10.9814 25.7584 10.9814 25.7584 10.9815
            25.7584C11.0908 25.8116 11.2122 25.839 11.3333 25.839H23.347C24.8823 25.839
             26.1387 24.6339 26.1387 23.1426ZM7.87681 10.5684L7.87652 25.3208L7.87537
            25.3209V25.4312C7.87537 25.5171 7.80661 25.5892 7.70691 25.5892H1.62716C1.52788
            25.5892 1.4587 25.516 1.4587 25.4312V10.5684C1.4587 10.4836 1.52788 10.4105
            1.62716 10.4105H7.70836C7.80763 10.4105 7.87681 10.4836 7.87681 10.5684ZM13.4182
            6.02958L13.4182 6.02959L13.4189 6.02749C13.444 5.95053 13.4584 5.86944 13.4584
            5.78556V2.31416C13.4584 1.82026 13.8755 1.41059 14.4 1.41059C14.7249 1.41059
            15.0215 1.56308 15.197 1.81795L15.1974 1.81848C15.648 2.4658 16.0318 2.90054
            16.3388 3.24826L16.3396 3.24921L16.3396 3.24923C16.3544 3.26597 16.369 3.28247 
            16.3834 3.29874C16.5485 3.48549 16.6872 3.64232 16.8005 3.80158C17.0521 4.16219 
            17.2083 4.57565 17.2083 5.66987V9.64262C17.2083 10.0706 17.5674 10.4105 18
            10.4105H25.3333C26.0045 10.4105 26.5416 10.9361 26.5416 11.5712C26.5416 12.2063
            26.0045 12.7319 25.3333 12.7319H25.0532C24.6207 12.7319 24.2616 13.0717 24.2616
            13.4997C24.2616 13.9277 24.6207 14.2677 25.0533 14.2677C25.7245 14.2677 26.2616 
            14.7933 26.2616 15.4284C26.2616 16.0635 25.7245 16.5891 25.0533 
            16.5891H24.2533C23.8207 16.5891 23.4616 16.929 23.4616 17.357C23.4616 
            17.785 23.8207 18.1248 24.2533 18.1248C24.9245 18.1248 25.4616 18.6504 
            25.4616 19.2855C25.4616 19.9206 24.9245 20.4462 24.2533 20.4462H23.3468C22.9142 
            20.4462 22.5551 20.7861 22.5551 21.2141C22.5551 21.6421 22.9142 21.9819 23.3468 
            21.9819C24.018 21.9819 24.5551 22.5075 24.5551 23.1426C24.5551 23.7777 24.018 
            24.3033 23.3468 24.3033H11.5194L9.45839 23.3098V12.5335L11.8922 10.1867C11.9787 
            10.1032 12.0463 10.0002 12.085 9.88635C12.085 9.88624 12.085 9.88614 12.0851 
            9.88603L13.4182 6.02958Z" fill="#838383" stroke="#838383" strokeWidth="0.25"/>
        </svg>

          
    )
}
export default LikeIcon