import { Alert, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Avatar } from '@mui/material';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';

import { AuthContext } from '../../context/AuthContext';
import { Collapse } from '@mui/material';
import LikeIcon from '../../assets/LikeIcon';
import { CommentIcon,NoteIcon,LikedIcon } from '../../assets';
import JCSwitch from './JcSwitch';
import CommentOptions from './CommentOptions';
import CommentModal from './CommentModal';
import { url as API_URL } from '../../api';
import { fetchReplies,postCommentActions } from '../../api';
import { convertToRender } from '../../utils/JCWysiwygEditor';
import { useLocation } from 'react-router';
import { ReportProblem } from '@mui/icons-material';

import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { JC_COLORS } from '../../styles/constants';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
      '& .MuiDialog-paper': {
          width: '80%',
          height: '180px',
      }
  },
  [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
          width: '30%',
          height: '40%',
      }
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
      <DialogTitle
          sx={{
              display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
              backgroundColor: JC_COLORS.JC_RED,
              color: JC_COLORS.JC_WHITE,
          }}
          {...other}
      >
          {children}
          {onClose ? (
              <CloseIcon
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      color: JC_COLORS.JC_WHITE
                  }}
              />
          ) : null}
      </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const useStyles = makeStyles({
  commentIcons: {
    marginRight: '6px',
    height: '24px',
    width: '24px',
  },
  moreOption : {
    display:'inline-block',
    float:'right',
    marginTop:'-5px'
  }
});



function CommentCard(props) {
  const{rootNotes, comments, commentData} = props
  const dispatch = useDispatch();
  const {newCount} = useSelector((state) => state.comment);
  const [expand, setExpand] = useState();
  const [_replies, _setReplies] = useState([])
  //const [rootComments, setRootComments] = useState(JSON.parse(localStorage.getItem('jc-comments'))?JSON.parse(localStorage.getItem('jc-comments')):comments)
 
  //const [DATA, setDATA] = useState(JSON.parse(localStorage.getItem('jc-comments'))?JSON.parse(localStorage.getItem('jc-comments')):[])

  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  
  let location = useLocation()
  
  // const [autol, setAutol] = useState(localStorage.getItem('JC-refresh-for-comments')===null?false:localStorage.getItem('JC-refresh-for-comments'))

  // useEffect(()=>{
  //   console.log('REFRESH RAN')
  //     localStorage.setItem('JC-refresh-for-comments', JSON.stringify(false))
  //     const _jcId = window.location.pathname.split('/').length === 3? 'J00000000/': window.location.pathname.split('/').reverse()[1] + '/'
  //     const _refresh = JSON.parse(localStorage.getItem('jc_refresh_comments'))
  //     localStorage.setItem('jc_refresh_comments', JSON.stringify({refresh: true}))
  //     // console.log(API_COMMENTS_URLS.get_comments + _jcId + window.location.pathname.split('/').reverse()[0] + '/' + userEmail); 
  //     console.log('fetching comments ')
  //   fetch(API_URL + '/comments/' + _jcId + window.location.pathname.split('/').reverse()[0] + '/' + userEmail, {
  //     headers: {
  //       'Authorization' : 'Bearer ' + localStorage.id_token
  //     },
  //     method:'get',
  //   })
  //   .then(res => {
  //     // console.log('fresh res: ', res)
  //     return res.text()
  //   })
  //   .then(data =>{
  //     // console.log(data, 'from comments list')
  //     if(data !== 'No comments found'){
  //       // NOTE: NOTE: setting root comments
  //       const _res = JSON.parse(data)
  //       // console.log('setting rootssssssss with ', _res)
  //       // setDATA(_res)
  //       setRootComments(_res?.filter(d => !d.isNote && d.referenceCommentID === null))
  //       setDATA(_res)
  //       // localStorage.setItem('jc_refresh_comments', JSON.stringify({refresh: false}))
  //       // localStorage.setItem('JC-refresh-for-comments', JSON.stringify(false))
  //       // _setRefresh(prevState => false)
  //     }
  //     else{
  //       console.warn(DATA, ' comments list is empty.')
  //     }
  //   })
  //   .catch(err => console.warn(err))
  //   return () => {}
  // }, [])

  // useEffect(()=>{
  //   // console.warn('LOCAL STORAGE : ', localStorage.getItem('JC-refresh-for-comments'))
  //   if(localStorage.getItem('JC-refresh-for-comments') === 'true'){
  //     console.log('TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTRURRRRRRRRRRRRRRRRRRRRRRRRRRRRRRUUUUUUUUUUUUUUUEEEEEEEEEEEEEEEEEEE!')
  //     // window.location.reload()
  //     // NOTE: this-worked
  //     const _jcId = window.location.pathname.split('/').length === 3? 'J00000000/': window.location.pathname.split('/').reverse()[1] + '/'
  //     fetch(API_URL + '/comments/' + _jcId + window.location.pathname.split('/').reverse()[0] + '/' + userEmail, {
  //       headers: {
  //         'Authorization' : 'Bearer ' + localStorage.id_token
  //       },
  //       method:'get',
  //     })
  //     .then(res => {
  //       return res.text()
  //     })
  //     .then(data =>{
  //       if(data !== 'No comments found'){
  //         // NOTE: NOTE: setting root comments
  //         const _res = JSON.parse(data)
  //         setRootComments(_res?.filter(d => !d.isNote && d.referenceCommentID === null))
  //         setDATA(_res)
         
  //       }
  //       else{
  //         console.warn(DATA, ' comments list is empty.')
  //       }
  //     })
  //     .catch(err => console.warn(err))
  //     // localStorage.getItem('JC-refresh-for-comments')
  //   }
  // }, [autol])

  useEffect(() => {
    if(newCount && expand !== undefined){
      getRepliesFromAPI(commentData.commentID)
    }
  },[newCount])


  
  
  const getRepliesFromAPI = _commentID => {
    fetchReplies(_commentID,userEmail)
    .then(response => {
      const res = response
      if (response.data !== 'No comments found') {
        _setReplies(response.data.filter(comment => comment.referenceCommentID !== null))
      }
      else {
        _setReplies([])
      }
    })
    .catch(err => {console.warn('Error occurred while fetching replies: ', err) 
  })
  }

  // useEffect(() => {
  //   // let ReportedComment = location.hash.split('#')
  //   // let childCommentId = ReportedComment[1].split("-")
  // },[])
  return(
    <Box>
      <NormalComment data={commentData} replyCount={commentData.replies} setExpand={() => { getRepliesFromAPI(commentData.commentID); setExpand(!expand)}} />
      <Collapse in={expand}>                
      { _replies.length === 0? null: _replies.map(r => <ReplyComment data={r} key={r.commentID} isOpen={false}/>)}
      </Collapse>
    </Box> 
  )
}

const ReplyComment = (props) => {
  const { data, isOpen } = props
  const classes = useStyles()
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email; 
  const [open,setOpen] = useState(isOpen)
  const [likedByUser,setLiked] = useState(data.userLiked)
  const [num_likes,setNumLikes] = useState(data.num_likes)
  let location = useLocation();
  const [flag,setFlag] = useState(false)
  const [openReport,setOpenReport] = useState(false)
  const handleReportClick = () =>{
    console.log("handle report has been clicked")
    setOpenReport(true)
  }

  const handleLikeClick = (commentID) => {
    const likeVerb = likedByUser ? 'unlike' : 'like'
    postCommentActions(commentID,userEmail,likeVerb).then(response => {
      setLiked(!likedByUser)
      if(likeVerb === 'unlike'){
        setNumLikes(num_likes - 1)
      }
      else{
        setNumLikes(num_likes + 1)
      }
      }).catch(error => {
          const errorMsg = error.message
      })
  }

  const handleReplyClick = () => {
    setOpen(true)
  }
  const handleReplyClose = () => {
    setOpen(false)
  }
  
  // NOTE: setting AWS media downloadUrl

  const [_body, _setBody] = useState(data.text)

  useEffect(()=>{
    
    // var parser = new DOMParser();
    // var doc = parser.parseFromString(data.text, "text/html");

    // var allImg = doc.body.querySelectorAll('img')
    // allImg.forEach(function(item){
    //     const imgId = item.getAttribute('id')
    //     if(data.commentMedia.filter(media => media.fileName === imgId).length > 0){
    //       const newSrc = data.commentMedia.filter(media => media.fileName === imgId)[0].downloadUrl
    //       item.setAttribute('src',newSrc)
    //     }
    // })
    // var anchorTags = doc.body.querySelectorAll('a')
    // anchorTags.forEach(function(aItem){
    //   const aId = aItem.getAttribute('id')
    //   if(data.commentMedia.filter(amedia => amedia.fileName === aId).length > 0){
    //     const newSrc = data.commentMedia.filter(amedia => amedia.fileName === aId)[0].downloadUrl
    //     aItem.setAttribute('href',newSrc)
    //   }
    // })
    // var videoTags = doc.body.querySelectorAll('video')
    // videoTags.forEach(function(vItem){
    //   const vId = vItem.getAttribute('id')
    //   if(data.commentMedia.filter(vmedia => vmedia.fileName === vId).length > 0){
    //     const newSrc = data.commentMedia.filter(vmedia => vmedia.fileName === vId)[0].downloadUrl
    //     vItem.setAttribute('src',newSrc)
    //   }
    // })
    let finalBody = convertToRender(data.text,data.commentMedia)
    _setBody(prevState => finalBody)
  }, [data])

  useEffect(() => {
    if(location.hash){
      let ReportedComment = location.hash.split('#')
    let childCommentId = ReportedComment[1].split("-")
    if(childCommentId[2] === data.commentID){
      setFlag(true)
    }
    goToSection(childCommentId[2])
    }
    
  },[])

  const goToSection=(id)=>{
    const targetSection = document.getElementById(id);
    if(targetSection != null) {
      window.scrollTo({
        top:targetSection.offsetTop-200,
        behavior:"smooth",
      });
    }
  }

  return (
    <React.Fragment>
      <Grid container wrap="nowrap" spacing={2} ml={4} sx={{width:'calc(100% - 32px)'}} id={data.commentID}>
        <Grid item>
        <Avatar variant="square" alt="Benjamin Scricia" src={""} sx={{height:'38px',width:'38px',borderRadius:'2px!important'}}></Avatar>
        </Grid>
        <Grid justifyContent="left" item xs zeroMinWidth>
          <Typography variant='H2_RALEWAY' sx={{'textTransform':'capitalize'}}>
            {/* Sham Sundar */}
            {/* {data.author.toString()} */}
            { data.author }
          </Typography>
          <Box sx={{display:'flex',alignItems:'center',float:'right','& .MuiMenu-paper':{width:'10ch'}}}>
          {flag && <Box className='reportlogoCommentNote'><ReportProblem /></Box>}
            <CommentOptions id={data.commentID} data={data} postedByCurrentUser={data.postedByCurrentUser} reportedByUser={data.reportedByUser} isReply={true}/>
          </Box>
          <Box my={-1}>
            <p dangerouslySetInnerHTML={{ __html: _body }} style={{ textAlign: "left",fontFamily:'Raleway' }} className="jc-comment">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
              luctus ut est sed faucibus. Duis bibendum ac ex vehicula laoreet.
              Suspendisse congue vulputate lobortis. Pellentesque at interdum
              tortor. Quisque arcu quam, malesuada vel mauris et, posuere
              sagittis ipsum.  */}
            </p>
          </Box>
          <Box sx={{display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'left',
            alignItems: 'center'}} mt={2} mb={2}>

            <Box sx={{display:'inline-block',cursor:'pointer'}} onClick={() => handleLikeClick(data.commentID)}>
              {likedByUser ? <LikedIcon className={classes.commentIcons} fill="#FFFFFF"/> : <LikeIcon className={classes.commentIcons} />}
            </Box>
            <Box sx={{display:'inline-block', 'marginRight': '24px'}}>
                <Typography variant="BODY_COPY2_RALEWAY" component="span">
                ({num_likes === undefined ? 0 : num_likes})
                </Typography>
              </Box>
            {/* <Box sx={{display:'inline-block',cursor:'pointer'}} mr={2} ml={2} onClick={handleReplyClick}>
              <Link variant="BODY_COPY1_RALEWAY" color='#262626'>
                <Typography variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'700!important'}}>
                  Reply
                </Typography>
              </Link>
            </Box> */}
            {/* NOTE: uniquwe commentId, like ObjectId */}
            <CommentModal open={open} onClose={handleReplyClose} isReply={true} commentOn={data.id} isNote={data.isNote}/>
            <Box sx={{display:'inline-block', marginTop:'-2px'}} mr={2}>
              <Typography variant="BODY_COPY2_RALEWAY" color="#262626"
                align='right' pb={1.5}>
                {moment.utc(data.creationTime).local().fromNow()}
              </Typography>
            </Box>
          </Box>
          
        </Grid>
      </Grid>
      {
    openReport && <ReportPopUp open={openReport} setOpen={setOpenReport}/>
  }
    </React.Fragment>
  )
}

const NormalComment = (props) => {
  const { data } = props
  // console.log(data, ' data from individual comments and replyCount: ', replyCount)
  // console.log('_______DATA to normal comment: ', data)
  let _item = data.commentMedia
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;

  let insertUrl = _item ? _item[0]?.downloadUrl : ''
  const classes = useStyles()
  const [open,setOpen] = useState(false)
  const [likedByUser,setLiked] = useState(data.userLiked)
  const [num_likes,setNumLikes] = useState(data.num_likes)
  const [flag, setFlag] = useState(false)
  const [openReport,setOpenReport] = useState(false)
  let location = useLocation();

  const handleLikeClick = (commentID) => {
    const likeVerb = likedByUser ? 'unlike' : 'like'
    postCommentActions(commentID,userEmail,likeVerb).then(response => {
      setLiked(!likedByUser)
      if(likeVerb === 'unlike'){
        setNumLikes(num_likes - 1)
      }
      else{
        setNumLikes(num_likes + 1)
      }
      }).catch(error => {
          const errorMsg = error.message
      })
  }

  const handleReplyClick = () => {
    setOpen(true)
  }
  const handleReplyClose = () => {
    // localStorage.setItem('JC-refresh-for-comments', JSON.stringify('false'))
    setOpen(false)
  }
  
  useEffect(()=>{
    if(insertUrl !== undefined){
      _item = data.commentMedia
      insertUrl = _item ? _item[0]?.downloadUrl : ''
    }
    return () => {}
  }, [data])
  const [_body, _setBody] = useState(data.text)

  useEffect(()=>{
    // var parser = new DOMParser();
    // var doc = parser.parseFromString(data.text, "text/html");

    // var allImg = doc.body.querySelectorAll('img')
    // allImg.forEach(function(item){
    //     const imgId = item.getAttribute('id')
    //     if(data.commentMedia.filter(media => media.fileName === imgId).length > 0){
    //       const newSrc = data.commentMedia.filter(media => media.fileName === imgId)[0].downloadUrl
    //       item.setAttribute('src',newSrc)
    //     }
    // })
    // var anchorTags = doc.body.querySelectorAll('a')
    // anchorTags.forEach(function(aItem){
    //   const aId = aItem.getAttribute('id')
    //   if(data.commentMedia.filter(amedia => amedia.fileName === aId).length > 0){
    //     const newSrc = data.commentMedia.filter(amedia => amedia.fileName === aId)[0].downloadUrl
    //     aItem.setAttribute('href',newSrc)
    //   }
    // })
    // var videoTags = doc.body.querySelectorAll('video')
    // videoTags.forEach(function(vItem){
    //   const vId = vItem.getAttribute('id')
    //   if(data.commentMedia.filter(vmedia => vmedia.fileName === vId).length > 0){
    //     const newSrc = data.commentMedia.filter(vmedia => vmedia.fileName === vId)[0].downloadUrl
    //     vItem.setAttribute('src',newSrc)
    //   }
    // })

    let finalBody = convertToRender(data.text,data.commentMedia)
    _setBody(prevState => finalBody)
  }, [data, insertUrl, _item])

  useEffect(() => {
    if(location.hash){
    let ReportedComment = location.hash.split('#')
    let childCommentId = ReportedComment[1].split("-")
    if(ReportedComment[1] === data.commentID){
      setFlag(true)
    }
    if(childCommentId[0] === data.commentID && childCommentId[1]){
      props.setExpand()
      console.log("i am being called")
      goToSection(childCommentId[0])
    }
    goToSection(ReportedComment[1])}
  },[])

  const goToSection=(id)=>{
    const targetSection = document.getElementById(id);
    if(targetSection != null) {
      window.scrollTo({
        top:targetSection.offsetTop-200,
        behavior:"smooth",
      });
    }
  }

  const handleReportClick = () =>{
    console.log("handle report has been clicked")
    setOpenReport(true)
  }

  return (
    <React.Fragment>
          
    <Grid container wrap="wrap" spacing={2} sx={{overflowWrap: 'break-word'}} id={data.commentID}>
                      
    <Grid item>
    <Avatar variant="square" alt="Benjamin Scricia" src={""} sx={{height:'38px',width:'38px',borderRadius:'2px!important'}}></Avatar>
    </Grid>
    <Grid justifyContent="left" item xs zeroMinWidth>
      <Typography variant='H2_RALEWAY' sx={{'textTransform':'capitalize'}}>
        {data.author.toString()}
      </Typography>
      <Box sx={{display: "flex", alignItems: "center",float:'right'}}>
        {flag && <Box className='reportlogoCommentNote'><ReportProblem /></Box>}
        <CommentOptions id={data.commentID} data={data} postedByCurrentUser={data.postedByCurrentUser} reportedByUser={data.reportedByUser} isNote={data.isNote}/>
      </Box>
      {/*Comment Body*/}
      <Box my={-1}>
        <p dangerouslySetInnerHTML={{ __html: _body }} style={{ textAlign: "left",fontFamily:'Raleway' }} className="jc-comment">  
        </p>
      </Box>
      <Box sx={{display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'left',
        alignItems: 'center'}} mt={2} mb={2}>
        <Box sx={{display:'inline-block',cursor:'pointer'}} onClick={() => handleLikeClick(data.commentID)}>
          {likedByUser ? <LikedIcon className={classes.commentIcons} fill="#FFFFFF"/> : <LikeIcon className={classes.commentIcons} />}
        </Box>
        <Box sx={{display:'inline-block'}}>
            <Typography variant="BODY_COPY2_RALEWAY" component="span">
                ({num_likes === undefined ? 0 : num_likes })
            </Typography>
          </Box>
        <Box sx={{display:'inline-block',cursor:'pointer'}} mr={2} ml={2} onClick={handleReplyClick}>
          <Link variant="BODY_COPY1_RALEWAY" color='#262626'>
            <Typography variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'700!important'}}>
              Reply
            </Typography>
          </Link>
        </Box>
        
        <CommentModal open={open} onClose={handleReplyClose} isReply={true} commentOn={data.commentID}/>
        
        <Box sx={{display:'inline-block',cursor:'pointer'}} mr={2} onClick={props.setExpand}>
          <Link variant="BODY_COPY1_RALEWAY" color='#262626'>
            <Typography variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'700!important'}}>
              {data.replies === 1?'1 Reply':`${data.replies} Replies`}
            </Typography>
          </Link>
        </Box>
        <Box sx={{display:'inline-block'}} mr={2}>
          <Typography variant="BODY_COPY2_RALEWAY" color="#262626"
            align='right' pb={1.5}>
            {moment.utc(data.creationTime).local().fromNow()}
          </Typography>
        </Box>
      </Box>
    </Grid>
  </Grid>

  {
    openReport && <ReportPopUp open={openReport} setOpen={setOpenReport}/>
  }
  
    </React.Fragment>
  )
}


const ReportPopUp = ({open,setOpen}) => {
  const handleSupporterCloseClick = () => {
    setOpen(false)
}
  return(
    <>
      <BootstrapDialog
                onClose={handleSupporterCloseClick}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleSupporterCloseClick} >
                    <Typography variant="H3_RALEWAY" color={JC_COLORS.JC_WHITE} pl={1}>
                        Reported By
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent>
                    {/* {look at SuppoetArticle.js for referance} */}
                </DialogContent>
            </BootstrapDialog>
    </>
  )
}


export default CommentCard

const UserRoleLabel = ({userRole}) =>{
  return (
    <Box sx={{display:'inline-block'}} ml={{xs:1,md:2}}>
      <Box sx={{
        height: '10px',
        width: '10px',
        backgroundColor: '#EF5B50',
        borderRadius: '50%',
        display: 'inline-block'
      }} mr={{xs:0.5,md:1}}></Box>
      <Box sx={{display:'inline-block'}}>
        <Typography variant='BODY_COPY2_RALEWAY' color="#565656">
          {userRole}
         </Typography> 
      </Box>

    </Box>
  )
}

const ReplyUserLabel = ({userName}) =>{
  return (
    <Box sx={{display:'inline-block'}} ml={{xs:1,sm:2,md:3}}>
      <Typography variant='BODY_COPY2_RALEWAY' color="#262626">
        {"Reply to " + userName }
        </Typography> 
    </Box>
  )
}