import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Paper,
  Link,
  InputAdornment,
  Backdrop,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CircularProgress from '@mui/material/CircularProgress';
import { CenteredContainer } from '../../../common/components';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import {
  MEMBERSHIP_STATUS,
  REPORT_ROWS_PER_PAGE,
} from '../../../constants/constants';
import {
  CenterFlexBox,
  ArtcleTitleCell,
  UserCell,
  InstitutionCell,
  InlineBox,
  SummaryBox,
  StyledTextField,
  StyledSearchButton,
  EnhancedTableHead,
  JCTableRow,
  BlackDot,
  JCBackdrop,
  JCReportSearch,
} from '../../JCDetails/jc-content/report-content/ReportHelpers';
import CustomDropdownDateRange from '../../Feed/components/CustomDropdownDateRange';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../../JCDetails/components/CustomDropdown';
import { fetchAllUserActivityRoport } from '../../../api';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router';
import { getUserDetails } from '../../../state/actions/user';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#262626',
  padding: theme.spacing(1),
  textAlign: 'left',
  minHeight: '100vh',
  margin: '0px 11px',
  padding: '24px 36px',
  [theme.breakpoints.down('md')]: {
    borderRadius: 'None',
    boxShadow: 'None',
    margin: '0px 0px',
    padding: '72px 12px 24px 12px',
  },
}));

const UserStatusList = ['All Users', 'Active', 'Inactive', 'Invited'];

export const PlatformUsersHeadCells = [
  {
    id: 'userName',
    numeric: false,
    disablePadding: false,
    sortable: true,
    isIconLabel: false,
    label: 'User Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    isIconLabel: false,
    sortable: true,
    label: 'Email',
  },
  {
    id: 'userStatus',
    numeric: true,
    disablePadding: false,
    isIconLabel: false,
    sortable: false,
    label: 'Status',
  },
  {
    id: 'terms',
    numeric: true,
    disablePadding: false,
    isIconLabel: false,
    sortable: false,
    label: 'T&C Accepted?',
  },
  {
    id: 'profile',
    numeric: true,
    disablePadding: false,
    isIconLabel: false,
    sortable: false,
    label: 'Profile Filled?',
  },
  {
    id: 'lastActive',
    numeric: false,
    disablePadding: false,
    isIconLabel: false,
    sortable: true,
    label: 'Last Activity',
  },
];

function PlatformUsers({ userEmail }) {
  const [platformUsersData, setPlatformUsersData] = useState({});
  const [dateRangeLabel, setDateRangeLabel] = useState('All Time');
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [value, setValue] = useState('');
  const [SearchText, setSearchText] = useState('');
  const [rootLoading, setRootLoading] = useState(true);
  const [userStatusType, setUserStatusType] = useState('All Users');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileMatch = useMediaQuery(theme.breakpoints.down('md'));
  const { jcList, isFetchingJournalClubs, jcDetails } = useSelector(
    (state) => state.journalclub
  );
  const { userDetails } = useSelector((state) => state.user);
  const [queryParameters, setQueryParameters] = useState(null);

  // report table states
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(1);
  };
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const resetPageNumber = (val, callback) => {
    setPage(1);
    callback(val);
  };
  const handleRowClick = (event) => {
    // Todo:: handle Row click
  };
  const handleSearch = () => {
    setIsLoading(true);
    setSearchText(value);
    var queryParams = {
      authUserEmail: userEmail,
      numRecordsPerPage: REPORT_ROWS_PER_PAGE,
      pageNumber: 1,
      sort: order,
      term: value,
    };
    setQueryParameters(queryParams);
    fetchData(queryParams);
  };

  useEffect(() => {
    dispatch(getUserDetails(userEmail));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    var queryParams = {
      authUserEmail: userEmail,
      numRecordsPerPage: REPORT_ROWS_PER_PAGE,
      pageNumber: page,
      sort: order,
    };
    if (SearchText !== '') {
      queryParams = { ...queryParams, term: SearchText };
    }
    fetchData(queryParams);
  }, [dateRange, page, order, orderBy, SearchText, userStatusType]);

  const fetchData = (queryParams) => {
    if (dateRange.startDate && dateRange.endDate) {
      queryParams = { ...queryParams, ...dateRange };
    }
    if (userStatusType !== UserStatusList[0]) {
      queryParams = { ...queryParams, status: userStatusType };
    }
    if (orderBy) {
      queryParams = { ...queryParams, orderBy: orderBy };
    }
    setQueryParameters(queryParams);
    fetchAllUserActivityRoport(userEmail, queryParams)
      .then((response) => {
        if (response?.data) {
          setPlatformUsersData(response.data);
          setIsLoading(false);
          setRootLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <Item>
      <Box mx={-1} mb={2}>
        <Link
          sx={{ cursor: 'pointer', textDecoration: 'None' }}
          onClick={() => navigate(-1)}
          className="report-nav-root_text"
        >
          <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
          <Typography variant="BODY_COPY2_RALEWAY">Go Back</Typography>
        </Link>
      </Box>
      {/*User Activity Title */}
      <Box mb={2}>
        <InlineBox>
          <Box>
            <Typography
              variant="H1_BITTER"
              color="primary"
              sx={{ fontWeight: '700 !important' }}
            >
              All User Activity
            </Typography>
          </Box>
        </InlineBox>
      </Box>
      <Box mt={2} sx={{ textAlign: 'left' }}>
        {platformUsersData && (
          <Typography
            variant="BODY_ARTICLE_RALEWAY"
            sx={{ fontWeight: '700!important', marginRight: '12px' }}
          >
            {' '}
            {platformUsersData.numberOfRows !== 0
              ? platformUsersData.numberOfRows &&
                (platformUsersData.numberOfRows !== 1
                  ? platformUsersData.numberOfRows + ' Users Found'
                  : platformUsersData.numberOfRows + ' User Found')
              : 'No Result Found'}
          </Typography>
        )}
        {isLoading && (
          <CircularProgress
            color="primary"
            sx={{ width: '20px!important', height: '20px!important' }}
          />
        )}
      </Box>

      <Box sx={{ textAlign: 'left' }} mt={{ xs: 2, md: 4 }} mb={1}>
        <InlineBox>
          <JCReportSearch
            value={value}
            onSearch={() => {
              if (value) {
                handleSearch();
              }
            }}
            onClearInput={() => {
              setSearchText('');
              setValue('');
            }}
            placeholder={'Search User Name/User Email'}
            autoComplete="off"
            onChange={setValue}
            sx={{ width: '30vw!important' }}
          />
        </InlineBox>
        <InlineBox sx={{ float: 'right' }}>
          <InlineBox ml={1}>
            <CustomDropdown
              selectedLabel={userStatusType}
              listOfLabels={UserStatusList}
              changeLabel={(val) => resetPageNumber(val, setUserStatusType)}
            />
          </InlineBox>
          <InlineBox ml={1}>
            <CustomDropdownDateRange
              isHeading={false}
              isWhite={true}
              dateRangeLabel={dateRangeLabel}
              changeDateRangeLabel={(value) => setDateRangeLabel(value)}
              changeDateRangeHandler={(value) =>
                resetPageNumber(value, setDateRange)
              }
            />
          </InlineBox>
        </InlineBox>
      </Box>
      {platformUsersData && platformUsersData.userList && (
        <Box mt={3} mb={3}>
          <Box sx={{ width: '100%' }}>
            <TableContainer>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: 'none',
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: '16px',
                    borderRadius: '4px',
                  },
                  minWidth: mobileMatch ? 300 : 750,
                }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={PlatformUsersHeadCells}
                />

                <TableBody sx={{ position: 'relative' }}>
                  <JCBackdrop open={isLoading} />
                  {platformUsersData.userList.map((row, index) => {
                    const labelId = `enhanced-table-desktop-${index}`;
                    return (
                      <JCTableRow
                        onRowClick={(event) => handleRowClick(event)}
                        key={labelId}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <UserCell
                            name={row.firstName + ' ' + row.lastName}
                            isDisabled={true}
                          />
                        </TableCell>

                        <TableCell align="left">
                          {row.userEmail ? row.userEmail : '-'}
                        </TableCell>
                        <TableCell align="center"> {row.status}</TableCell>
                        <TableCell align="center">
                          {row.acceptedTermsAndConditions ? 'Yes' : '-'}
                        </TableCell>
                        <TableCell align="center">
                          {row.profileCompleted ? 'Yes' : '-'}
                        </TableCell>
                        <TableCell align="left">
                          {row.lastActive
                            ? moment.utc(row.lastActive).local().fromNow()
                            : '-'}
                        </TableCell>
                      </JCTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              mt={3}
              sx={{ display: 'flex', justifyContent: 'center' }}
              mb={{ xs: 8, md: 3 }}
            >
              <Pagination
                count={Math.ceil(
                  platformUsersData.numberOfRows / REPORT_ROWS_PER_PAGE
                )}
                color="primary"
                page={page}
                onChange={handleChangePage}
                sx={{ '& .MuiPaginationItem-root': { fontFamily: 'Raleway' } }}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Item>
  );
}

export default PlatformUsers;
