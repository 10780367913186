import React, { useState, useCallback, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { Autocomplete, TextField, Paper, InputAdornment, CircularProgress, Typography, Box, Hidden, Button } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import axios from 'axios';
import * as api from '../../api';
import { JC_COLORS } from '../../styles/constants';
import { logout } from '../../utils';
import connectionInstance from '../../utils/connection';

const StyledAutocomplete = styled(Autocomplete)(({ theme, bgcolor }) => ({
    borderRadius: '3px',
    background: 'white',
    [theme.breakpoints.up('md')]: {
        width: '23vw',
        '& .MuiInputBase-root': {
            height: '40px',
        },
    },
    [theme.breakpoints.down('md')]: {
        width: '75vw',
        '& .MuiInputBase-root': {
            height: '35px',
            padding: '0px',
        },
    },
    '& .MuiFormControl-root': {
        padding: '0px',
    },

    '& .MuiOutlinedInput-root': {
        padding: "0px 10px",
        "& > *": {
            display: 'flex',
            alignItems: 'center',

        },
        // '& fieldset': {
        //     border: '2px solid #838383',
        // },
        '&:hover fieldset': {
            borderColor: JC_COLORS.JC_RED,
        },
        '&.Mui-focused fieldset': {
            borderColor: JC_COLORS.JC_RED
        },
    },

}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    borderRadius: '3px',
    background: 'white',
    '& .MuiOutlinedInput-input': {
        fontFamily: 'Raleway',
        fontWeight: '600',
    },
    [theme.breakpoints.up('md')]: {
        width: '23vw',
        '& .MuiInputBase-root': {
            height: '42px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
        }
    },
    [theme.breakpoints.down('md')]: {
        width: '75vw',
        height: '32px',
        '& .MuiInputBase-root': {
            height: '32px',
            padding: '0px 8px 0px 8px',
        },
    },

}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: JC_COLORS.JC_RED,
    fontFamily: 'Raleway',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
        marginLeft: '11px',
        padding: '8px 22px',
        width: '142px',
        height: '42px',
        borderRadius: '4px',
        fontSize: '15px',
        lineHeight: '26px',
        '&:hover': {
            backgroundColor: 'white',
            color: JC_COLORS.JC_RED,
        }

    },
    [theme.breakpoints.down('md')]: {
        marginLeft: '5px',
        padding: '4px 10px',
        width: '62px',
        height: '32px',
        fontSize: '12px',
        lineHeight: '20px',
    }
}));

const StyledText = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'white',
    [theme.breakpoints.up('md')]: {
        width: '85px',
        fontFamily: 'Raleway',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '17.61px',
        textAlign: 'left',
        marginLeft: '20px',
        marginRight: '43px',
        marginTop: '5px',
    },
    [theme.breakpoints.down('md')]: {
        fontFamily: 'Raleway',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '36px',
        textAlign: 'left',
    }
}))


const SearchBoxArticles = ({ placeholder }) => {
    // const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const dispatch = useDispatch();
    // const { user } = useSelector((state) => state);
    // const { userEmail } = user.userDetails;
    const [optionsList, setOptionsList] = React.useState([]);
    const [loadingOptionsList, setLoadingOptionsList] = useState(false);
    const [value, setValue] = React.useState("");
    const [query, setQuery] = React.useState("");

    const location = useLocation();
    const page = location.pathname.replace(/\//, '');

    return (
        <>
            <Hidden mdDown>
                <Box sx={{ display: 'flex', flexDirection: 'row', alingItems: 'center' }}>
                    <StyledTextField
                        value={value}
                        placeholder={"Search PubMed"}
                        autoComplete='off'
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                        }}
                        onChange={(e) => {
                            let val = e.target.value;
                            setValue(val ? val : "");
                            setQuery(val ? val : "");
                        }}
                        onKeyPress={
                            (e) => {
                                if (e.key === "Enter") {
                                    setQuery(query);
                                }
                            }
                        }
                    ></StyledTextField>
                    <StyledButton
                        variant="contained"
                        onClick={
                            () => {
                                navigate(`/search`, { state: { title: `${query}` } });
                                setValue("");
                            }
                        }
                    >
                        Search
                    </StyledButton>
                    {/* <StyledText onClick={() => navigate('/advanced-search')}>
                        ADVANCED SEARCH
                    </StyledText> */}


                </Box>

            </Hidden>
            <Hidden mdUp>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '14px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alingItems: 'center' }}>
                        <StyledTextField
                            placeholder={"Search PubMed"}
                            value={value}
                            autoComplete='off'
                            InputProps={{
                                startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                                endAdornment: (query.length > 0 && <InputAdornment position="end" onClick={() => setQuery("")}><Close /></InputAdornment>)
                            }}
                            onChange={(e) => {
                                let val = e.target.value;
                                setValue(val ? val : ""); 
                                setQuery(val ? val : "");
                            }}
                            onKeyPress={
                                (e) => {
                                    if (e.key === "Enter") {
                                        setQuery(query);
                                    }
                                }
                            }
                        ></StyledTextField>
                        <StyledButton
                            variant="contained"
                            onClick={
                                () => { 
                                    navigate(`/search`, { state: { title: `${query}` } }); 
                                    setValue("")
                                }
                            }
                        >
                            Search
                        </StyledButton>
                    </Box>
                    {/* <StyledText onClick={() => navigate('/advanced-search')}>
                        ADVANCED SEARCH
                    </StyledText> */}

                </Box>
            </Hidden>
        </>





    )

}

export default SearchBoxArticles;

{/* <StyledAutocomplete
                        freeSolo
                        fullWidth
                        loading={query.length > 0 && loadingOptionsList}
                        filterOptions={(x) => x}
                        value={value}
                        onChange={(e) => setValue(e.target.innerText)}
                        options={optionsList}
                        getOptionLabel={(option) => option.title || query}
                        renderOption={
                            (props, option) => {
                                return (

                                    <a
                                        key={option.PMID}
                                        style={{
                                            textDecoration: 'none',
                                            color: JC_COLORS.JC_BLACK,
                                            fontFamily: 'Raleway',
                                            fontWeight: '600',
                                            fontSize: `${optionsList.length === 0 ? '12px' : '24px'}`,
                                            lineHeight: `${optionsList.length === 0 ? '18px' : '36px'}`,
                                            margin: '5px 17px',
                                            display: "-webkit-box",
                                            WebkitLineClamp: "2",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",

                                        }}
                                        href={`/article/${option.PMID}`}
                                        {...props}
                                    >
                                        {option.title}
                                    </a>
                                )
                            }
                        }
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{
                                    backgroundColor: JC_COLORS.LIGHT_GREY,
                                    border: optionsList.length === 0 ? 'none' : `2px solid ${JC_COLORS.JC_ICON}`,
                                    borderRadius: '6px',
                                }}
                            >
                                {children}
                            </Paper>
                        )}

                        renderInput={(params) => {
                            params.InputProps.startAdornment = (
                                <>
                                    <InputAdornment position="start"><SearchIcon /></InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            );
                            // params.InputProps.endAdornment = (
                            //     <>
                            //         {
                            //             (loadingOptionsList && query.length > 0) && (
                            //                 <InputAdornment position="end">
                            //                     <CircularProgress color="primary" size={24} />
                            //                 </InputAdornment>
                            //             )
                            //         }

                            //     </>
                            // );

                            params.InputProps.style = {
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                            }
                            return (
                                <TextField
                                    {...params}
                                    // label="Search"
                                    placeholder={placeholder ?? "Search"}
                                    variant="outlined"
                                    onChange={(e) => {
                                        let val = e.target.value;
                                        setQuery(val ? val : "");
                                    }}
                                    onKeyPress={
                                        (e) => {
                                            if (e.key === "Enter") {
                                                setQuery(query);
                                            }
                                        }
                                    }
                                />

                            )
                        }

                        }


                    ></StyledAutocomplete> */}