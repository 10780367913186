import React, { useState, useContext } from 'react'
import { Box, Hidden, Link, styled, Typography } from '@mui/material';
import { JC_COLORS } from '../../../styles/constants';
import { fetchDefaults } from '../../../api';
import { useEffect } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { GOOGLE_FORM_FOR_MISSING_ARTICLES } from '../../../constants/constants';

const MainBox = styled(Box)(({ theme }) => ({
    backgroundColor: JC_COLORS.LIGHT_GREY,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
        maxWidth: '40%',
        padding: '14px 28px 14px 28px',
        borderRadius: '4px',
    },
    [theme.breakpoints.down('md')]: {
        margin: "8px 16px 0px 16px",
        padding: '7px 10px',
    }

}));

const MessageText = styled(Typography)(({ theme }) => ({

    fontStyle: 'italic',
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
    },
    [theme.breakpoints.down('md')]: {
    }
}))



const FeedDescription = ({ areasOfInterest }) => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const GOOGLE_FORM_LINK = `https://docs.google.com/forms/d/e/1FAIpQLSeDxnTb8Ja-UlAQdG9p1NqQHE6KpgjBitRY-6XzJyUVr_iF0Q/viewform?entry.575240079=${userEmail}`

    const [message, setMessage] = useState("");
    useEffect(() => {
        fetchDefaults()
            .then(
                res => {
                    if (res.status === 200) {
                        let skippedAreasOfInterests = res?.data?.skippedAreasOfInterests;
                        let defaultAreasOfInterests = res?.data?.defaultAreasOfInterests;
                        let msg = getMessage(areasOfInterest, skippedAreasOfInterests, defaultAreasOfInterests);
                        setMessage(msg);
                    } else {
                        let msg = getMessage(areasOfInterest, [], []);
                        setMessage(msg);
                    }
                },
                error => {
                    let msg = getMessage("_", [], []);
                    setMessage(msg);
                }
            );
    }, [areasOfInterest]);

    return (
        <MainBox>
            <MessageText variant="BODY_COPY2_RALEWAY">{message}</MessageText>
            <Hidden mdDown>
                <MessageText
                    variant="BODY_COPY2_RALEWAY"
                    sx={{
                        margin: "16px 0px 0px 0px"
                    }}
                >
                    Didn't find the articles that you are looking for?&nbsp;
                    <Link
                        variant="BODY_COPY2_RALEWAY"
                        color={JC_COLORS.JC_BLACK}
                        sx={{
                           
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}
                        href={GOOGLE_FORM_LINK}
                        target="_blank"
                    >
                        Contact Us
                    </Link>

                </MessageText>

            </Hidden>
            <Hidden mdUp>
                <MessageText
                    variant="BODY_COPY2_RALEWAY"
                    sx={{
                        margin: "16px 0px 0px 0px"
                    }}
                >
                    Didn't find the articles that you are looking for?&nbsp;
                    <Link
                        variant="BODY_COPY2_RALEWAY"
                        color={JC_COLORS.JC_BLACK}
                        sx={{
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}
                        href={GOOGLE_FORM_LINK}
                        target="_blank"
                    >
                        Contact Us
                    </Link>
                </MessageText>

            </Hidden>

        </MainBox>
    )
}

export default FeedDescription

function getMessage(areasOfInterest, skippedAreasOfInterests, defaultAreasOfInterests) {
    // To do
    // if there are more than 2 areas of interest and one is in skippedAreasOfInterest and other is not 
    let firstAreaOfInterest = (areasOfInterest !== null && areasOfInterest?.length > 0) ? areasOfInterest[0] : '_';
    if (
        skippedAreasOfInterests.includes(firstAreaOfInterest) ||
        defaultAreasOfInterests.includes(firstAreaOfInterest)
    ) {
        return `This feed contains all articles from high impact factor journals in ${combineListOfStrings(areasOfInterest)}.`
    } else {
        return `This feed contains all articles from high impact factor journals in ${combineListOfStrings(areasOfInterest)} as well as ${combineListOfStrings(areasOfInterest)} articles from ${combineListOfStrings(defaultAreasOfInterests)} journals.`
    }

}

function combineListOfStrings(listOfStrings) {
    if ((listOfStrings !== null && listOfStrings?.length > 0)) {
        if (listOfStrings.length === 1) {
            return listOfStrings[0]
        } else if (listOfStrings.length === 2) {
            return listOfStrings.join(' and ')
        } else {
            return listOfStrings.slice(0, listOfStrings.length - 1).join(', ') + ', and ' + listOfStrings[listOfStrings.length - 1]
        }
    } else {
        return '_'
    }


}
