import React, { useState, useContext } from 'react';
import { Hidden, Box, Button, Menu, Typography, styled, Dialog, DialogTitle, DialogContent, MenuItem, IconButton } from '@mui/material';
import { Row } from '../../../common/components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { getJournalClubs, getJCUserDetails, getJournalClubDetails, setJCContent } from '../../../state/actions/journalclub';
import { pxToRem } from '../../../utils';
import { acceptJCInvite, rejectJCInvite, joinJC, leaveJC } from '../../../api';
import { JC_COLORS } from '../../../styles/constants';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { AuthContext } from '../../../context/AuthContext';
import { JC_CONTENT } from '../../../constants/constants';

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 700,
    textTransform: 'none',

    [theme.breakpoints.up('md')]: {
        fontSize: '0.75rem',
        lineHeight: '0.875rem',
        //height: '42px',
        minWidth: '142px',


    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(12),
        lineHeight: pxToRem(20),
        //height: '28px',
        minWidth: 'auto',
    },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiDialogContent-root': {
        padding: "11px 4px",
    },
    '& .MuiDialogTitle-root': {
        padding: "10px 4px 0px 0px",
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialog-paper': {
            width: '80%',
            height: 'auto',
        }
    },

}));

const H3RalewayText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 600,
    color: JC_COLORS.JC_ICON,
    [theme.breakpoints.up('md')]: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(26),
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(10),
        lineHeight: pxToRem(11.74),
    }
}));

const StyledMenu = styled(Box)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        // marginTop: theme.spacing(1),
        // minWidth: 180,
        color: '#262626',
        backgroundColor: 'FFFFFF',
        border: '1px solid #838383',
        borderRadius: '0px'
    },
    '& .MuiMenuItem-root': {
        backgroundColor: '#FFFFFF',
        //padding: '8px',
        marginBottom: '1px'
    },
    '& .MuiMenuItem-root:hover': {
        backgroundColor: '#EF5B50',
        color: '#FFFFFF'
    },
    '& .MuiMenu-list': {
        //padding: '4px',
    },

}));

const ChangeJCRoleButton = ({ JCID, userEmail, currentRole, membershipStatus, nextAction }) => {
    const auth = useContext(AuthContext);
    const { isAuthenticated } = auth; 
    const dispatch = useDispatch();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(false);
    const [openChangeJCRoleDialogBox, setOpenChangeJCRoleDialogBox] = useState(false);



    if (membershipStatus === 'Requested' || membershipStatus === 'Invite Rejected') {
        return (
            <StyledButton
                variant="contained"
                sx={{
                    backgroundColor: JC_COLORS.JC_ICON,
                    color: JC_COLORS.JC_WHITE,
                    width: 'fit-content',
                    display: 'inline-block',
                    fontStyle: 'italic',
                    textTransform: 'uppercase',
                }}
            >
                {membershipStatus === "Requested" ? "Request Sent" : membershipStatus}
            </StyledButton>
        )
    }

    if (membershipStatus === 'Joined' || (
        (userEmail === null || userEmail === undefined) ||
        (isAuthenticated === false)
      )) {
        return (
            <>
                <Hidden mdDown>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography variant="H3_RALEWAY" sx={{ fontWeight: 600, color: JC_COLORS.JC_ICON,marginRight:'12px' }}>You are a</Typography>
                        <StyledButton
                            variant="outlined"
                            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',minHeight:'36px' }}
                            endIcon={userEmail && <MoreVertIcon />}
                            onClick={
                                (event) => {
                                    if (userEmail) {
                                        if (currentRole === "Course Director") {
                                            setAnchorEl(null)
                                        } else {
                                            setAnchorEl(event.currentTarget)
                                        }
                                    }

                                }
                            }
                        >
                            {userEmail ? currentRole : "Guest"}

                        </StyledButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            sx={{
                                width: '100%',
                                border: `1px solid ${JC_COLORS.JC_ICON}`,
                            }}
                        >
                            <StyledButton
                                variant="contained"
                                endIcon={<CheckIcon />}
                                onClick={
                                    async () => {
                                        try {
                                            const response = await leaveJC(JCID, userEmail)
                                            if (response) {
                                                dispatch(getJournalClubDetails(JCID, userEmail))
                                                dispatch(getJCUserDetails(JCID, userEmail));
                                                dispatch(getJournalClubs(userEmail));
                                                dispatch(setJCContent(JC_CONTENT.DEFAULT)); 
                                                setAnchorEl(null);
                                            }
                                            if (nextAction) {
                                                nextAction()
                                            }
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }
                                }
                                sx={{ margin: "0px 4px" }}
                            >
                                Leave Journal Club
                            </StyledButton>


                        </Menu>

                    </Box>
                </Hidden>
                <Hidden mdUp>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <H3RalewayText sx={{ color: '#848484', marginBottom: '4px' }}>You are a</H3RalewayText>
                        <Row>
                            <StyledButton variant="outlined">{userEmail ? currentRole : "Guest"}</StyledButton>
                            {
                                userEmail && (
                                    <H3RalewayText
                                        sx={{
                                            color: '#848484',
                                            textDecoration: 'underline',
                                            marginLeft: '8px'
                                        }}
                                        onClick={
                                            () => {
                                                if (userEmail) {
                                                    if (currentRole === "Course Director") {
                                                        return
                                                    } else {
                                                        setOpenChangeJCRoleDialogBox(true)
                                                    }
                                                }

                                            }
                                        }
                                    >
                                        Edit Status
                                    </H3RalewayText>

                                )
                            }

                        </Row>
                        <StyledDialog
                            open={openChangeJCRoleDialogBox}
                            onClose={() => setOpenChangeJCRoleDialogBox(false)}
                        >
                            <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <CloseIcon
                                    sx={{
                                        width: '24px',
                                        height: '24px',
                                        color: JC_COLORS.JC_ICON,
                                    }}
                                    onClick={() => setOpenChangeJCRoleDialogBox(false)}
                                />
                            </DialogTitle>
                            <DialogContent>

                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '100%',
                                        margin: '8px 0px 0px 0px',
                                        padding: '8px 16px',
                                        fontFamily: 'Raleway',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        backgroundColor: JC_COLORS.JC_RED,
                                        color: JC_COLORS.JC_WHITE,
                                        borderRadius: '2px',
                                        justifyContent: 'flex-start',

                                    }}
                                    onClick={
                                        async () => {
                                            try {
                                                const response = await leaveJC(JCID, userEmail)
                                                if (response) {
                                                    setAnchorEl(null);
                                                    window.location.reload(); 
                                                    
                                                }
                                            } catch (error) {
                                                console.log(error);
                                            }
                                        }
                                    } 
                                >
                                    Leave Journal Club
                                </Button>
                            </DialogContent>

                        </StyledDialog>

                    </Box>
                </Hidden>


            </>


        )
    }

    else {
        return <Box></Box>
    }


}

export default ChangeJCRoleButton