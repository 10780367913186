import * as api from "../../api";
import { getJournalClubs } from "../../state/actions/journalclub";
import moment from "moment";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  convertFileArraytoPost,
  addWrapperToContent,
  uploadToS3FromUrl,
  getFileObjectsToUpload
} from "../../utils/JCWysiwygEditor";
import { useNavigate } from "react-router";

// Actions
export const FETCH_ARTICLE_REQUEST = "FETCH_ARTICLE_REQUEST";
export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_FAILURE = "FETCH_ARTICLE_FAILURE";

export const FETCH_VOTER_REQUEST = "FETCH_VOTER_REQUEST";
export const FETCH_SUPPORTER_SUCCESS = "FETCH_SUPPORTER_SUCCESS";
export const FETCH_OPPOSER_SUCCESS = "FETCH_OPPOSER_SUCCESS";
export const FETCH_VOTER_FAILURE = "FETCH_VOTER_FAILURE";

// Actions for engagements and actions
export const POST_ARTICLE_ACTION_SUCCESS = "POST_ARTICLE_ACTION_SUCCESS";
export const POST_ARTICLE_ACTION_FAILURE = "POST_ARTICLE_ACTION_FAILURE";

export const MARK_READ = "MARK_READ";
export const UPDATE_ARTICLE_STATUS = "UPDATE_ARTICLE_STATUS";
export const UPDATE_PI_STATUS = "UPDATE_PI_STATUS";
export const UPDATE_COMMENT_STATUS = "UPDATE_COMMENT_STATUS";
export const UPDATE_LIKE_STATUS = "UPDATE_LIKE_STATUS";
export const UPDATE_SAVE_STATUS = "UPDATE_SAVE_STATUS";
export const UPDATE_SUPPORT_STATUS = "UPDATE_SUPPORT_STATUS";
export const UPDATE_OPPOSE_STATUS = "UPDATE_OPPOSE_STATUS";
export const UPDATE_NOTE_STATUS = "UPDATE_NOTE_STATUS";
export const UPDATE_PERMISSION_STATUS = "UPDATE_PERMISSION_STATUS";

// Actions for Denovo Article
export const OPEN_DENOVO_ARTICLE_IN_EDIT_MODE =
  "OPEN_DENOVO_ARTICLE_IN_EDIT_MODE";
export const CLOSE_DENOVO_ARTICLE_IN_EDIT_MODE =
  "CLOSE_DENOVO_ARTICLE_IN_EDIT_MODE";
export const RESET_DENOVO_ARTICLE_IN_EDIT_MODE =
  "RESET_DENOVO_ARTICLE_IN_EDIT_MODE";
export const UPDATE_DENOVO_ARTICLE_DATA = "UPDATE_DENOVO_ARTICLE_DATA";
// export const UPDATE_DENOVO_ARTICLE_TO_DB = 'UPDATE_DENOVO_ARTICLE_TO_DB'
export const SET_DENOVO_AUTO_SAVE_TIMESTAMP = "SET_DENOVO_AUTO_SAVE_TIMESTAMP";
export const SET_DENOVO_EDIT_HISTORY = "SET_DENOVO_EDIT_HISTORY";
export const SET_DIRTY = "SET_DIRTY";
export const SET_ERROR_IN_DENOVO_ARTICLE = "SET_ERROR_IN_DENOVO_ARTICLE";
export const SET_DENOVO_LOCK_STATE = "SET_DENOVO_LOCK_STATE";
export const RESET_DENOVO_LOCK_STATE = 'RESET_DENOVO_LOCK_STATE'; 

// export const FETCHING_DENOVO_EDIT_HISTORY = 'FETCHING_DENOVO_EDIT_HISTORY'
// export const FETCH_DENOVO_EDIT_HISTORY_SUCCESS = 'FETCH_DENOVO_EDIT_HISTORY_SUCCESS'
// export const FETCH_DENOVO_EDIT_HISTORY_FAILURE = 'FETCH_DENOVO_EDIT_HISTORY_FAILURE'

// Article body media
export const SET_BODY_IMAGE_FILES = 'SET_BODY_IMAGE_FILES'
export const SET_BODY_VIDEO_FILES = 'SET_BODY_VIDEO_FILES'
export const SET_BODY_DOCUMENT_FILES = 'SET_BODY_DOCUMENT_FILES'
export const RESET_BODY_UPLOADED_FILES = 'RESET_BODY_UPLOADED_FILES'

// Article body media
export const SET_ABSTRACT_IMAGE_FILES = 'SET_ABSTRACT_IMAGE_FILES'
export const SET_ABSTRACT_VIDEO_FILES = 'SET_ABSTRACT_VIDEO_FILES'
export const SET_ABSTRACT_DOCUMENT_FILES = 'SET_ABSTRACT_DOCUMENT_FILES'
export const RESET_ABSTRACT_UPLOADED_FILES = 'RESET_ABSTRACT_UPLOADED_FILES'

export const SET_MEDIA_UPLOADING =  'SET_MEDIA_UPLOADING'

//Action Methods

export const fetchArticleRequest = () => {
  return {
    type: FETCH_ARTICLE_REQUEST,
  };
};

export const fetchArticleSuccess = (article) => {
  return {
    type: FETCH_ARTICLE_SUCCESS,
    payload: article,
  };
};

export const fetchArticleFailure = (error) => {
  return {
    type: FETCH_ARTICLE_FAILURE,
    payload: error,
  };
};

export const fetchVoterRequest = () => {
  return {
    type: FETCH_VOTER_REQUEST,
  };
};

export const fetchSupporterSuccess = (supporter) => {
  return {
    type: FETCH_SUPPORTER_SUCCESS,
    payload: supporter,
  };
};
export const fetchOpposerSuccess = (opposer) => {
  return {
    type: FETCH_OPPOSER_SUCCESS,
    payload: opposer,
  };
};

export const fetchVoterFailure = (error) => {
  return {
    type: FETCH_VOTER_FAILURE,
    payload: error,
  };
};

export const markRead = () => {
  return {
    type: MARK_READ,
  };
};
export const updateArticleStatus = (status) => {
  return {
    type: UPDATE_ARTICLE_STATUS,
    payload: status,
  };
};

export const updatePermissionStatus = (status) => {
  return {
    type: UPDATE_PERMISSION_STATUS,
    payload: status,
  };
};

export const updateLikeStatus = () => {
  return {
    type: UPDATE_LIKE_STATUS,
  };
};
export const updatePIStatus = () => {
  return {
    type: UPDATE_PI_STATUS,
  };
};
export const updateCommentStatus = () => {
  return {
    type: UPDATE_COMMENT_STATUS,
  };
};
export const updateNoteStatus = () => {
  return {
    type: UPDATE_NOTE_STATUS,
  };
};
export const updateSaveStatus = () => {
  return {
    type: UPDATE_SAVE_STATUS,
  };
};
export const updateSupportStatus = () => {
  return {
    type: UPDATE_SUPPORT_STATUS,
  };
};
export const updateOpposeStatus = () => {
  return {
    type: UPDATE_OPPOSE_STATUS,
  };
};
export const postArticleActionSuccess = (successMsg) => {
  return {
    type: POST_ARTICLE_ACTION_SUCCESS,
    payload: successMsg,
  };
};

export const postArticleActionFailure = (error) => {
  return {
    type: POST_ARTICLE_ACTION_FAILURE,
    payload: error,
  };
};

export const getPMID = () => {
  return {
    type: "GET_PMID",
  };
};

export const resetBodyUploadedFiles = () => {
  return {
    type: RESET_BODY_UPLOADED_FILES,
  };
};
export const resetAbstractUploadedFiles = () => {
  return {
    type: RESET_ABSTRACT_UPLOADED_FILES,
  };
};
export const setMediaUploading = (data) => {
  return {
    type: SET_MEDIA_UPLOADING,
    payload: data
  }
}
// get article from remote api
export const getArticle = (PMID, userEmail, refresh = true) => {
  return (dispatch) => {
    if (refresh) {
      dispatch(fetchArticleRequest());
    }
    api
      .fetchArticle(PMID, userEmail)
      .then((response) => {
        const article = response.data;
        dispatch(fetchArticleSuccess(article));
        dispatch(
          markArticleAsRead(
            "J00000000",
            article.PMID,
            userEmail,
            article.readByUser
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        if(error?.response?.status === 404){
          dispatch(fetchArticleFailure(errorMsg));
        }
        console.error(error)
      });
  };
};

// get article from Journal Club
export const getJournalClubArticle = (
  JCID,
  PMID,
  userEmail,
  refresh = true
) => {
  return (dispatch) => {
    if (refresh) {
      dispatch(fetchArticleRequest());
    }
    api
      .fetchJournalClubArticle(JCID, PMID, userEmail)
      .then((response) => {
        const article = response.data;
        dispatch(fetchArticleSuccess(article));
        dispatch(
          markArticleAsRead(JCID, article.PMID, userEmail, article.readByUser)
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        if(error?.response?.status === 404){
          dispatch(fetchArticleFailure(errorMsg));
        }
        console.error(error)
      });
  };
};

// get voters of article
export const getArticleVoter = (JCID, PMID, userEmail, voter) => {
  return (dispatch) => {
    dispatch(fetchVoterRequest());
    api
      .fetchArticleVoters(JCID, PMID, userEmail, voter)
      .then((response) => {
        const voterData = response.data;
        if (voter === "supporters") {
          dispatch(fetchSupporterSuccess(voterData.supporterList));
        } else {
          dispatch(fetchOpposerSuccess(voterData.opposerList));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchVoterFailure(errorMsg));
      });
  };
};

export const markArticleAsRead = (JCID, PMID, userEmail, readStatus) => {
  return (dispatch) => {
    api
      .postArticleActions(PMID, userEmail, JCID, "read")
      .then((response) => {
        dispatch(markRead());
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(postArticleActionFailure(errorMsg));
      });
  };
};

export const setPracticeImpacted = (
  PMID,
  userEmail,
  JCID,
  PIStatus,
  likedByUser
) => {
  return (dispatch) => {
    dispatch(updatePIStatus());
    if (likedByUser === false && PIStatus === false) {
      dispatch(updateLikeStatus());
    }
    const PIVerb = PIStatus ? "unPI" : "PI";
    api
      .postArticleActions(PMID, userEmail, JCID, PIVerb)
      .then((response) => {
        dispatch(postArticleActionSuccess("Rated as PI Succesfully"));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updatePIStatus());
        dispatch(updateLikeStatus());
        dispatch(postArticleActionFailure(errorMsg));
      });
  };
};

export const setLiked = (
  PMID,
  userEmail,
  JCID,
  likeStatus,
  supportedByUser
) => {
  return (dispatch) => {
    dispatch(updateLikeStatus());
    const likeVerb = likeStatus ? "unlike" : "like";
    api
      .postArticleActions(PMID, userEmail, JCID, likeVerb)
      .then((response) => {
        dispatch(postArticleActionSuccess("Liked this article Succesfully"));
        if (likeVerb === "like" && supportedByUser === false) {
          dispatch(updateSupportStatus());
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateLikeStatus());
        dispatch(postArticleActionFailure(errorMsg));
      });
  };
};

export const setArticleStatus = (PMID, userEmail, JCID, articleStatus) => {
  return (dispatch) => {
    let articleVerb = articleStatus === "Published" ? "publish" : "reject";
    api
      .postArticleActions(PMID, userEmail, JCID, articleVerb)
      .then((response) => {
        dispatch(updateArticleStatus(articleStatus));
        dispatch(getJournalClubArticle(JCID, PMID, userEmail, false));
        dispatch(getJournalClubs(userEmail));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(postArticleActionFailure(errorMsg));
      });
  };
};

export const setSupport = (
  JCID,
  PMID,
  userEmail,
  supportStatus,
  opposeStatus,
  likedByUser
) => {
  return (dispatch) => {
    dispatch(updateSupportStatus());
    if (opposeStatus) {
      dispatch(updateOpposeStatus());
    }
    const supportVerb = supportStatus ? "unsupport" : "support";
    api
      .postArticleActions(PMID, userEmail, JCID, supportVerb)
      .then((response) => {
        dispatch(
          postArticleActionSuccess("Supported this article Succesfully")
        );
        if (supportVerb === "support" && likedByUser === false) {
          dispatch(updateLikeStatus());
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateSupportStatus());
        dispatch(postArticleActionFailure(errorMsg));
      });
  };
};

export const setOppose = (
  JCID,
  PMID,
  userEmail,
  supportStatus,
  opposeStatus
) => {
  return (dispatch) => {
    dispatch(updateOpposeStatus());
    if (supportStatus) {
      dispatch(updateSupportStatus());
    }
    const opposeVerb = opposeStatus ? "unoppose" : "oppose";
    api
      .postArticleActions(PMID, userEmail, JCID, opposeVerb)
      .then((response) => {
        dispatch(postArticleActionSuccess("Opposed this article Succesfully"));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateOpposeStatus());
        dispatch(postArticleActionFailure(errorMsg));
      });
  };
};

export const setComment = (PMID, userEmail, JCID) => {
  return (dispatch) => {
    dispatch(updateCommentStatus);
  };
};
export const setNote = (PMID, userEmail, JCID) => {
  return (dispatch) => {
    dispatch(updateNoteStatus);
  };
};

export const setSaved = (PMID, userEmail, JCID, saveStatus) => {
  return (dispatch) => {
    dispatch(updateSaveStatus());
    const saveVerb = saveStatus ? "unsave" : "save";
    api
      .postArticleActions(PMID, userEmail, JCID, saveVerb)
      .then((response) => {
        dispatch(postArticleActionSuccess("saved this article Succesfully"));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateSaveStatus());
        dispatch(postArticleActionFailure(errorMsg));
      });
  };
};

export const openDenovoArticleInEditMode = () => {
  return {
    type: OPEN_DENOVO_ARTICLE_IN_EDIT_MODE,
  };
};

export const closeDenovoArticleInEditMode = () => {
  return {
    type: CLOSE_DENOVO_ARTICLE_IN_EDIT_MODE,
  };
};

export const resetDenovoArticleInEditMode = () => {
  return {
    type: RESET_DENOVO_ARTICLE_IN_EDIT_MODE,
  };
};

export const updateDenovoArticleData = (denovoArticleData) => {
  return {
    type: UPDATE_DENOVO_ARTICLE_DATA,
    payload: denovoArticleData,
  };
};

export const setDenovoAutosaveTimestamp = (timeStampString) => {
  return {
    type: SET_DENOVO_AUTO_SAVE_TIMESTAMP,
    payload: timeStampString,
  };
};

export const setDenovoEditHistory = (data) => {
  return {
    type: SET_DENOVO_EDIT_HISTORY,
    payload: data,
  };
};

export const getDenovoEditHistory = (JCID, PMID) => async (dispatch) => {
  try {
    const { data } = await api.fetchDenovoEditHistory(JCID, PMID);
    dispatch(setDenovoEditHistory(data));
    //let lastEditTime = data?.editors?.length > 0 ? data?.editors[0].lastEdited: '';
    //dispatch(setDenovoAutosaveTimestamp(moment(lastEditTime).toISOString()));
  } catch (error) {
    console.log(error);
  }
};

export const setDirty = (data) => {
  return {
    type: SET_DIRTY,
    payload: data,
  };
};

export const updateDenovoArticleToDB =
  ({ denovoArticleData, JCID, PMID, userEmail,articleData }) =>
  async (dispatch) => {
    try {
      const finalabstractObject = addWrapperToContent(denovoArticleData.abstractEditorState,articleData?.abstractMedia)
      const finalBodyObject = addWrapperToContent(denovoArticleData.articleBodyEditorState,articleData?.bodyMedia)
      const abstractfileListToUpload = getFileObjectsToUpload(
          denovoArticleData.abstractUploadedImages,
          denovoArticleData.abstractUploadedVideos,
          denovoArticleData.abstractUploadedDocuments,
          finalabstractObject.fileIdListToUpload
        )
      const bodyfileListToUpload = getFileObjectsToUpload(
          denovoArticleData.bodyUploadedImages,
          denovoArticleData.bodyUploadedVideos,
          denovoArticleData.bodyUploadedDocuments,
          finalBodyObject.fileIdListToUpload
        )
      var queryParams = {
          authUserEmail: userEmail,
          title: denovoArticleData.title,
          abstractContent: finalabstractObject.wrappedContent,
          abstractMedia: convertFileArraytoPost(abstractfileListToUpload),
          existingAbstractMedia : finalabstractObject.UploadedAndExistIDLIst.join("|"),
          bodyContent: finalBodyObject.wrappedContent,
          bodyMedia: convertFileArraytoPost(bodyfileListToUpload),
          existingBodyMedia:finalBodyObject.UploadedAndExistIDLIst.join("|"),
          authors: denovoArticleData.authors,
          cmeCredits: denovoArticleData.cmeCredits,
          PMID,
          getLock: false,
      }
      var filteredQueryParams = Object.fromEntries(Object.entries(queryParams).filter(([k,v]) => v !== ""));
      console.log(filteredQueryParams,"Filtered")
      let res = await api.patchDenovoArticle(JCID, userEmail,filteredQueryParams);
      //console.log("Sending patch request for denovo");
      if (res?.status === 201) {
        if(abstractfileListToUpload.length > 0 || bodyfileListToUpload.length > 0){
          dispatch(setMediaUploading(true))
          if(abstractfileListToUpload.length > 0){
            const response = await upoloadArrayOfFile(abstractfileListToUpload,res.data.abstractMediaUploadData)
          }
          if(bodyfileListToUpload.length > 0){
            const response = await upoloadArrayOfFile(bodyfileListToUpload,res.data.bodyMediaUploadData)
          }
          dispatch(setMediaUploading(false))
        }
        dispatch(closeDenovoArticleInEditMode());
        dispatch(setDenovoAutosaveTimestamp(moment().toISOString()));
        dispatch(setDirty(false));
        dispatch(
          setDenovoLockState({
            isDenovoArticleLockedByCurrentUser: false,
            openLockStatusDialogBox: false,
            currentlyEditedBy: null, 
          })
          );
        dispatch(getJournalClubArticle(JCID, PMID, userEmail)); 
        
      } else {
        dispatch(resetDenovoLockState()); 
      }
    } catch (error) {
      let errorMessage;
      // console.log("Error While uploading");
      // console.log(error);  
      if (typeof (error?.response?.data?.message) === 'string') {
        errorMessage = error?.response?.data?.message;
      } else {
        errorMessage = "Error while uploading"
        + JSON.stringify(error, Object.getOwnPropertyNames(error));
      }
      dispatch(
        setErrorInDenovoArticle({
          hasError: true,
          errorMessage,
          statusCode: error?.response?.status,
        })
      );
    }
  };

export const autoSaveDenovoArticleToDB =
  ({ denovoArticleData, JCID, PMID, userEmail,articleData }) =>
  async (dispatch) => {
    try {
      const finalabstractObject = addWrapperToContent(denovoArticleData.abstractEditorState,articleData?.abstractMedia,denovoArticleData.abstractUploadedImages)
      const finalBodyObject = addWrapperToContent(denovoArticleData.articleBodyEditorState,articleData?.bodyMedia,denovoArticleData.bodyUploadedImages)
      let res = await api.patchDenovoArticle(JCID, userEmail, {
        title: denovoArticleData.title,
          abstractContent: finalabstractObject.wrappedContent,
          abstractMedia: convertFileArraytoPost(finalabstractObject.imageListToUpload),
          existingAbstractMedia : finalabstractObject.UploadedAndExistIDLIst,
          bodyContent: finalBodyObject.wrappedContent,
          bodyMedia: convertFileArraytoPost(finalBodyObject.imageListToUpload),
          existingBodyMedia:finalBodyObject.UploadedAndExistIDLIst,
          authors: denovoArticleData.authors,
          cmeCredits: denovoArticleData.cmeCredits,
          PMID,
          getLock: true,
      });
      //console.log("Sending patch request for denovo");
      if (res?.status === 201) {
        if(finalabstractObject.imageListToUpload.length > 0){
          const response = await upoloadArrayOfFile(finalabstractObject.imageListToUpload,res.data.abstractMediaUploadData)
        }
        if(finalBodyObject.imageListToUpload.length > 0){
          const response = await upoloadArrayOfFile(finalBodyObject.imageListToUpload,res.data.bodyMediaUploadData)
        }
        dispatch(setDenovoAutosaveTimestamp(moment().toISOString()));
        dispatch(getJournalClubArticle(JCID, PMID, userEmail, false));
        dispatch(setDirty(false));
      }
    }  catch (error) {
      console.log(error);
      let errorMessage;
      if (typeof (error?.response?.data?.message) === 'string') {
        errorMessage = error?.response?.data?.message;
      } else {
        errorMessage =
          JSON.stringify(error?.response?.data);
      }
      dispatch(
        setErrorInDenovoArticle({
          hasError: true,
          errorMessage,
          statusCode: error?.response?.status,
        })
      );
    }
};

export const setErrorInDenovoArticle = (errorJSON) => {
  return {
    type: SET_ERROR_IN_DENOVO_ARTICLE,
    payload: errorJSON,
  };
};

export const setDenovoLockState = (data) => {
  return {
    type: SET_DENOVO_LOCK_STATE,
    payload: data,
  };
};

export const resetDenovoLockState = () => {
  return {
    type: RESET_DENOVO_LOCK_STATE, 
  }
}; 

export const acquireLockAndPerformNextAction =
  ({ JCID, PMID, userEmail, nextAction }) =>
    async (dispatch) => {
      try {
        let lockResponse = await api.acquireLock(JCID, PMID, userEmail);
        if (lockResponse?.status === 200) {
          let { lockedBy, lockAcquired } = lockResponse?.data;
          let userRoleInJCResponse = await api.fetchUserRoleInJC(
            JCID,
            lockedBy
          );
          if (lockAcquired === true) { 
            dispatch(
              setDenovoLockState({
                isDenovoArticleLockedByCurrentUser: true,
                currentlyEditedBy: userRoleInJCResponse?.data
              })
            );
            if (nextAction !== null || nextAction !== undefined) {
              nextAction();
            }
          } else {
            let userFullName = `${userRoleInJCResponse?.data?.firstName} ${userRoleInJCResponse?.data?.lastName}`;
            dispatch(
              setDenovoLockState({
                isDenovoArticleLockedByCurrentUser: false,
                openLockStatusDialogBox: true,
                lockStatusMessage: `The article is currently locked by ${userFullName}`,
                currentlyEditedBy: userRoleInJCResponse?.data
              })
            );
          }
        } else {
          let lockStatusMessage = JSON.stringify({
            ...lockResponse?.data,
            status: lockResponse?.status,
          });
          dispatch(
            setDenovoLockState({
              isDenovoArticleLockedByCurrentUser: false,
              openLockStatusDialogBox: true,
              lockStatusMessage,
              currentlyEditedBy: null,
            })
          );
        }
      } catch (error) {
        let errorMessage =
          error?.response?.data?.message ||
          JSON.stringify({
            ...error?.response?.data,
            status: error?.response?.status,
          });
        dispatch(
          setDenovoLockState({
            isDenovoArticleLockedByCurrentUser: false,
            openLockStatusDialogBox: true,
            lockStatusMessage: errorMessage,
            currentlyEditedBy: null, 
          })
        );
      } 
  };

export const acquireLockAndEditDenovoArticle =
  ({ JCID, PMID, userEmail, nextAction }) =>
    async (dispatch) => {
      try {
        let lockResponse = await api.acquireLock(JCID, PMID, userEmail);
        if (lockResponse?.status === 200) {
          let { lockedBy, lockAcquired } = lockResponse?.data;
          let userRoleInJCResponse = await api.fetchUserRoleInJC(
            JCID,
            lockedBy
          );
          if (lockAcquired === true) { 
            dispatch(openDenovoArticleInEditMode());
            dispatch(
              setDenovoLockState({
                isDenovoArticleLockedByCurrentUser: true,
                currentlyEditedBy: userRoleInJCResponse?.data
              })
            );
            if (nextAction !== null || nextAction !== undefined) {
              nextAction();
            }
          } else {
            let userFullName = `${userRoleInJCResponse?.data?.firstName} ${userRoleInJCResponse?.data?.lastName}`;
            dispatch(
              setDenovoLockState({
                isDenovoArticleLockedByCurrentUser: false,
                openLockStatusDialogBox: true,
                lockStatusMessage: `The article is currently locked by ${userFullName}`,
                currentlyEditedBy: userRoleInJCResponse?.data
              })
            );
          }
        } else {
          let lockStatusMessage = JSON.stringify({
            ...lockResponse?.data,
            status: lockResponse?.status,
          });
          dispatch(
            setDenovoLockState({
              isDenovoArticleLockedByCurrentUser: false,
              openLockStatusDialogBox: true,
              lockStatusMessage,
              currentlyEditedBy: null,
            })
          );
        }
      } catch (error) {
        let errorMessage =
          error?.response?.data?.message ||
          JSON.stringify({
            ...error?.response?.data,
            status: error?.response?.status,
          });
        dispatch(
          setDenovoLockState({
            isDenovoArticleLockedByCurrentUser: false,
            openLockStatusDialogBox: true,
            lockStatusMessage: errorMessage,
            currentlyEditedBy: null, 
          })
        );
      } 
  };

export const createNewDenovoArticle = (userEmail,firstName,lastName,JCID,urlSlug,navigate) =>
async (dispatch) => {
  console.log(" In function call")
  const emptyDenovoData = {
      authUserEmail: userEmail,
      title: "[Untitled]",
      abstractContent: "",
      bodyContent: "",
      authors: `${firstName ?? ""} ${lastName ?? ""}`,
      cmeCredits: '0'
  }
  let res = await api.postDenovoArticle(JCID, userEmail, { ...emptyDenovoData });
  let pm_id = res?.data?.pm_id;
  if (res?.status === 201 || pm_id !== null) {
      dispatch(
          setDenovoLockState({
              isDenovoArticleLockedByCurrentUser: true,
              openLockStatusDialogBox: false,
              currentlyEditedBy: null,
          })
      );
      navigate(`/jc/${urlSlug}/${pm_id}`, {
          state: {
              editIconOnDenovoCardClicked: true
          },
      });
  }
}
// function for uploading an array of image
const upoloadArrayOfFile = async (fileListToUpload, mediaObject) => {
  const promises = fileListToUpload.map((uploadedFile) => {
      const currFile = mediaObject.filter((file) => file.fileId === uploadedFile.id)[0]
      return uploadToS3FromUrl(currFile.url,uploadedFile,currFile.upload_data)
    });
  return Promise.all(promises);
};

// const handleArrayOfImage = async (fileListToUpload, commentID) => {
//   const promises = fileListToUpload.map((uploadedFile) => {
//     return handleImageUploadS3_DEMO(commentID, uploadedFile);
//   });  
//   return Promise.all(promises);
// };
