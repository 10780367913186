import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
    Box,
    Typography,
    Link,
    Grid,
    FormControl,
    OutlinedInput,
    Switch,
    InputLabel,
    Select,
    MenuItem,
    Button,
    TextField,
    useMediaQuery,
    IconButton,
    Checkbox,
    InputAdornment,
    capitalize
} from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { JC_CONTENT, PRIVACY_POLICY_PDF, NEWSLETTER_PUBLICATION_FREQUENCY, TERMS_OF_SERVICE_PDF, USER_ROLES } from "../../../../constants/constants";
import { getJournalClubDetails, setJCContent } from "../../../../state/actions/journalclub";
import { useSelector } from "react-redux";
import { JC_COLORS } from "../../../../styles/constants";
import { InlineBox } from "../report-content/ReportHelpers";
import { PrivateJCIcon, PublicJCIcon } from "../../../../assets/SVG";
import { CenteredActionButton, KeyWordChip } from "../../../../common/components";
import JCContentTitle from "../../components/JCContentTitle";
import { createJournalClub, editJournalClub, fetchAreaOfInterest } from "../../../../api";
import { useNavigate, useParams } from "react-router";
import ErrorDialogBox from "../../../../common/components/ErrorDialogBox";
import { AuthContext } from "../../../../context/AuthContext";


const SwitchWithText = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
        marginTop: '20px',
        marginBottom: '12px',
    },
    [theme.breakpoints.down('md')]: {

    }

}));

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#FFFFFF'
    },
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: JC_COLORS.JC_RED,
                opacity: 1,
                border: 0,
            },
        },
        '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
                backgroundColor: JC_COLORS.LIGHT_GREY,
                opacity: 1,
            }
        }

    },
    [theme.breakpoints.up('md')]: {
        width: "50px",
        height: "24px",
        padding: 0,
        margin: "0 11px",
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: "12px",
            height: "12px",
            margin: "0 3px"
        },
        '& .MuiSwitch-track': {
            borderRadius: "12px",
            backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
            opacity: 1,
        },
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: "6px 0px 6px 0px",
            '&.Mui-checked': {
                transform: 'translateX(32px)',
            }
        }
    },
    [theme.breakpoints.down('md')]: {
        width: "32px",
        height: "18px",
        padding: 0,
        margin: "0 5.5px",
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: "8px",
            height: "8px",
            margin: "1px 5px"
        },
        '& .MuiSwitch-track': {
            borderRadius: "6px",
            backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
            opacity: 1,
        },
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: "4px 0px 4px 0px",
            '&.Mui-checked': {
                transform: 'translateX(16px)',
            }
        },
    }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    borderRadius: '3px',
    background: 'white',
    border: `1px solid ${JC_COLORS.JC_ICON}`,
    [theme.breakpoints.up('md')]: {
        width: '95%',
        '& .MuiInputBase-root': {
            height: '42px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
        }
    },
    [theme.breakpoints.down('md')]: {
        width: '75vw',
        '& .MuiInputBase-root': {
            height: '34px',
            padding: '0px 5px',
        },
    },

}));

const StyledAddButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        padding: '8px 22px',
        width: '77px',
        height: '46px',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: JC_COLORS.JC_RED,
        fontFamily: 'Raleway',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '26px',
        '&:hover': {
            backgroundColor: 'white',
            color: JC_COLORS.JC_RED,
        }

    },
    [theme.breakpoints.down('md')]: {
        textTransform: 'none',
        padding: '4px 10px',
        width: '45px',
        height: '35px',
        borderRadius: '4px',
        backgroundColor: 'white',
        color: JC_COLORS.JC_RED,
        fontFamily: 'Raleway',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '20px',

    }
}));

const FlexWrappedList = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
        width: "90%",
        padding: '20px 0px',
    },
    [theme.breakpoints.down('md')]: {
        width: '90%',
        marginTop: '18px',
        padding: "10px"
    }
}));

const ListItemsText = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    color: JC_COLORS.JC_BLACK,
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H3_RALEWAY,
        //marginLeft: '24px',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: "12px",
        lineHeight: "18px",
        //marginLeft: '12px',
        marginTop: 0,
        paddingTOp: 0,

    },

}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: '26px',
        height: '26px',
    },
    [theme.breakpoints.down('md')]: {
        width: '18px',
        height: '18px',
    }
}));

const CheckBoxText = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H3_RALEWAY,
        marginLeft: "12px"
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '18px',
        marginLeft: '16px',

    }
}));

function getStyles(mobileMatch, topic, topicName, theme) {
    return {
        fontSize: mobileMatch ? '12px' : '18px',
        fontWeight:
            topicName?.indexOf(topic) === -1
                ? '600'
                : '700',
        fontFamily: 'Raleway',
        minHeight: '22px'
    };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            // "&.MuiMenuItem-root.Mui-selected": {
            //     backgroundColor: "yellow"
            // },
            // "&.MuiMenuItem-root:hover": {
            //     backgroundColor: "pink"
            // },
            // "&.MuiMenuItem-root.Mui-selected:hover": {
            //     backgroundColor: "red"
            // }
        },
    },
};

const WARNING_TEXT_FOR_EDITING_JC_NAME = "You are changing the name of an existing Journal Club. If the Journal Club's link has been shared on social media, those links will stop working.";


const EditJCInfo = ({ create = false }) => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const mobileMatch = useMediaQuery(theme.breakpoints.down('md'));

    const { journalclub } = useSelector((state) => state)
    const { jcDetails } = journalclub;

    const [jcName, setJcName] = useState(create === true ? "" : (jcDetails?.name ?? ""));
    const [topic, setTopic] = useState("");
    const [jcTopics, setJcTopics] = useState(create === true ? [] : (jcDetails?.topics?.map(obj => obj.topic) ?? []))
    const [isJcPublic, setIsJcPublic] = useState(jcDetails?.isPublic || false);
    const [jcDescription, setJcDescription] = useState(create === true ? "" : (jcDetails?.description ?? ""));
    const [userRoleInJC, setUserRoleInJC] = useState(null);
    const [newsletterPublicationFrequency, setNewsletterPublicationFrequency] = useState(create === true ? "Never" : (jcDetails?.publicationFrequency ?? "Never"));
    const [reminderEmailFrequency, setReminderEmailFrequency] = useState(create === true ? 0 : (jcDetails?.reminderEmailFrequency ?? 0));
    const [areaOfInterestList, setAreaOfInterestList] = useState([]);
    const [selectedAreaOfInterest, setSelectedAreaOfInterest] = useState(create === true ? "" : jcDetails?.areaOfInterest);
    const [isCheckedBillOfRights, setIsCheckedBillOfRights] = useState(false);

    const [dropDownMenuOpened, setDropDownMenuOpened] = useState(false);
    const [data, setData] = useState({});
    const [isFormCompleted, setIsFormCompleted] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [showWarningForEditingJcName, setShowWarningForEditingJcName] = useState(false);



    useEffect(() => {
        window.scrollTo({ top: 0, behaviour: 'smooth' });
        fetchAreaOfInterest()
            .then(response => {
                setAreaOfInterestList(response.data.AreaOfInterestList)
            }
            ).catch(error => {
                const errorMsg = error.message;
            })
    }, []);


    useEffect(() => {
        // in the edit mode if user tries to edit the jc name show warning

        if (!create && (jcDetails?.name !== jcName)) {
            setShowWarningForEditingJcName(true);
        } else {
            setShowWarningForEditingJcName(false);
        }

        let numReminderEmailFrequency = Number(reminderEmailFrequency);
        let tempData = {
            email: userEmail,
            userRoleInJC,
            name: jcName,
            areaOfInterest: selectedAreaOfInterest,
            description: jcDescription,
            isPublic: isJcPublic,
            isCmeAccrediting: (create === true ? false : (jcDetails?.isCmeAccrediting || false)),
            areReaderCommentsAllowed: true,
            publicationFrequency: newsletterPublicationFrequency,
            reminderEmailFrequency: numReminderEmailFrequency,
            topicsJC: ""
            //userRoleInJC,
            //topicsJC: jcTopics.join("|"),
        };
        setData(tempData);
        let validJcName = jcName?.length > 0;
        let validJcDescription = jcDescription?.length > 0;
        let validSelectedAreaOfInterest = selectedAreaOfInterest?.length > 0;
        //let validJcTopics = jcTopics.length > 0;
        let validUserRole = (create === true ? (userRoleInJC !== null && userRoleInJC?.length > 0) : true)


        let selectedNewsletterFrequencyObject = NEWSLETTER_PUBLICATION_FREQUENCY[newsletterPublicationFrequency?.toUpperCase()]
        let validReminderEmailFrequency;
        if (
            (newsletterPublicationFrequency === NEWSLETTER_PUBLICATION_FREQUENCY["NEVER"].value) ||
            (newsletterPublicationFrequency === "") ||
            (newsletterPublicationFrequency === null) ||
            (newsletterPublicationFrequency === undefined)
        ) {
            validReminderEmailFrequency = true;
            setReminderEmailFrequency(0);
        } else {

            validReminderEmailFrequency = (
                (numReminderEmailFrequency >= selectedNewsletterFrequencyObject?.min) &&
                (numReminderEmailFrequency <= selectedNewsletterFrequencyObject?.max) &&
                (Number.isInteger(numReminderEmailFrequency))

            )
        }


        if (create === true) {
            if (
                validJcName &&
                validJcDescription &&
                validSelectedAreaOfInterest &&
                validUserRole &&
                validReminderEmailFrequency &&
                (isCheckedBillOfRights === true)
                //validJcTopics &&
            ) {
                setIsFormCompleted(true)
            } else {
                setIsFormCompleted(false);
            }
        } else {
            if (validJcName && validJcDescription && validSelectedAreaOfInterest && validReminderEmailFrequency) {
                setIsFormCompleted(true);
            } else {
                setIsFormCompleted(false);
            }
        }



    }, [
        jcName,
        selectedAreaOfInterest,
        jcDescription,
        isJcPublic,
        userRoleInJC,
        isCheckedBillOfRights,
        newsletterPublicationFrequency,
        reminderEmailFrequency,
        create
    ])

    const handleAreaOfInterestChange = (event) => {
        setSelectedAreaOfInterest(event.target.value);
    }

    const addJcTopic = () => {
        const topicAlreadyPresent = jcTopics.filter(el => el.toLowerCase() === topic.toLowerCase()).length > 0

        if (
            (topic.length > 0) && (topicAlreadyPresent === false)
        ) {
            setJcTopics([...new Set([...jcTopics, topic])]);
            setTopic("");
        }

    }

    const removeJcTopic = (val) => {
        const filteredJcTopics = jcTopics.filter(el => el !== val)
        setJcTopics([...new Set([...filteredJcTopics])]);
    }

    return (
        <Box sx={{ minHeight: "624px" }}>
            <Box
                sx={{
                    textAlign: 'left',
                    marginTop: `${mobileMatch ? '70px' : '0px'}`
                }}
                my={-1}
                mx={-0.8}
            >
                <Link
                    sx={{
                        cursor: 'pointer',
                        textDecoration: 'None'
                    }}
                    onClick={
                        () => {
                            if (create === true) {
                                navigate("/join-a-journal-club")
                            } else {
                                dispatch(setJCContent(JC_CONTENT.MANAGE))
                            }
                        }
                    }
                    className='report-nav-root_text'
                >
                    <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
                    {
                        create === true ? (
                            <Typography variant="BODY_COPY2_RALEWAY">
                                All Journal Clubs
                            </Typography>
                        ) : (
                            <Typography variant="BODY_COPY2_RALEWAY">
                                Manage
                            </Typography>
                        )
                    }



                </Link>
            </Box>
            <Box sx={{ textAlign: 'left', marginTop: "20px" }}>
                <JCContentTitle title={create === true ? "Create Journal Club" : "Journal Club Information"} />
            </Box>

            <Grid container sx={{ marginTop: "43px" }} columnSpacing={4}>
                <Grid item xs={12} md={6} className='managetab-content' >
                    <InputLabel
                        required
                        sx={{
                            color: JC_COLORS.JC_ICON,
                            fontWeight: 700,
                            fontFamily: "Raleway",
                            marginBottom: "11px"
                        }}
                    >
                        Journal Club Name
                    </InputLabel>
                    <FormControl>
                        <TextField
                            required
                            placeholder={"Journal Club Name"}
                            type='text'
                            autoComplete='off'
                            helperText={jcName ? `${jcName?.length}` : ``}
                            value={jcName}
                            onChange={(event) => setJcName(event.target.value)}
                            error={""}
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: '42px',
                                },
                            }}
                        />
                    </FormControl>


                    <Typography
                        sx={{
                            color: JC_COLORS.JC_RED,
                            textAlign: 'left',
                            fontSize: '12px',
                            lineHeight: '14px',
                            marginTop: "8px",
                        }}
                    >
                        {showWarningForEditingJcName ? WARNING_TEXT_FOR_EDITING_JC_NAME : ""}
                    </Typography>

                    <Typography
                        sx={{
                            color: JC_COLORS.JC_RED,
                            textAlign: 'left',
                            fontSize: '12px',
                            lineHeight: '14px',
                            marginTop: "8px",
                        }}
                    >
                        {jcName.length === 0 ? "Name is required." : ""}
                    </Typography>



                </Grid>
                <Grid item xs={12} md={6} className='managetab-content'>
                    <InputLabel
                        required
                        sx={{
                            color: JC_COLORS.JC_ICON,
                            fontWeight: '700 !important',
                            fontFamily: "Raleway",
                            marginBottom: "11px"
                        }}
                    >
                        Journal Club Visibility
                    </InputLabel>
                    <SwitchWithText>
                        <InlineBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <PrivateJCIcon />
                            <Typography
                                variant="H3_RALEWAY"
                                ml={1}
                            >
                                Private
                            </Typography>
                        </InlineBox>
                        <IOSSwitch
                            checked={isJcPublic}
                            onChange={() => setIsJcPublic(!isJcPublic)}
                        //disabled={(feed.isFetchingFeed || user.isFetching)}
                        />
                        <InlineBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <PublicJCIcon />
                            <Typography
                                variant="H3_RALEWAY"
                                ml={1}
                            >
                                Public
                            </Typography>
                        </InlineBox>
                    </SwitchWithText>
                    {/* <Typography
                            variant="BODY_ARTICLE_RALEWAY"
                            color={isJcPublic ? JC_COLORS.JC_WHITE : "#838383"}
                            sx={{
                                fontSize: '15px !important'
                            }}
                        >
                            Only people you invite will be able to access this journal club
                        </Typography> */}
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} className='managetab-content'>
                    <InputLabel
                        required
                        sx={{
                            color: JC_COLORS.JC_ICON,
                            fontWeight: '700 !important',
                            fontFamily: "Raleway",
                            marginBottom: "11px"
                        }}
                    >
                        Journal Club Description
                    </InputLabel>

                    <FormControl>
                        <TextField
                            required
                            multiline={true}
                            minRows={3}
                            placeholder={"Journal Club Description"}
                            type='text'
                            inputProps={{ maxLength: 200 }}
                            helperText={jcDescription ? `${jcDescription?.length}/200` : `0/200`}
                            value={jcDescription}
                            onChange={(event) => setJcDescription(event.target.value)}
                            error={""}
                        />
                    </FormControl>
                    {
                        jcDescription.length === 0 && (
                            <Typography
                                sx={{
                                    color: JC_COLORS.JC_RED,
                                    textAlign: 'left',
                                    fontSize: '12px',
                                    lineHeight: '14px',
                                    marginTop: "8px",
                                }}
                            >
                                Description is required.
                            </Typography>
                        )

                    }

                </Grid>
            </Grid>

            <Grid container columnSpacing={4}>
                <Grid item xs={12} md={6} className='managetab-content'>
                    <InputLabel
                        required
                        sx={{
                            color: JC_COLORS.JC_ICON,
                            fontWeight: '700 !important',
                            fontFamily: "Raleway",
                            marginBottom: "11px"
                        }}
                    >
                        Area Of Interest
                    </InputLabel>
                    <FormControl>
                        <Select
                            value={selectedAreaOfInterest}
                            onChange={handleAreaOfInterestChange}
                            onOpen={() => setDropDownMenuOpened(true)}
                            onClose={() => setDropDownMenuOpened(false)}
                            IconComponent={
                                () => dropDownMenuOpened ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )
                            }

                            inputProps={{ 'aria-label': 'Without label' }}
                            placeholder="select"
                            displayEmpty
                            MenuProps={MenuProps}
                            required
                            sx={{
                                height: "42px",
                                //width: "23vw",
                                border: `1px solid ${JC_COLORS.JC_ICON}`,
                                paddingRight: "8px",
                            }}
                        //IconComponent={() => <KeyboardArrowDownIcon />}
                        >
                            <MenuItem
                                value={""}
                                style={getStyles(mobileMatch, "All Medical Topics", selectedAreaOfInterest, theme)}
                            >
                                - Select -
                            </MenuItem>
                            {
                                areaOfInterestList && areaOfInterestList.map(
                                    (item) => {
                                        return (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                                style={getStyles(mobileMatch, item, selectedAreaOfInterest, theme)}
                                            >
                                                {item}
                                            </MenuItem>
                                        )
                                    }
                                )
                            }

                        </Select>

                    </FormControl>
                    {
                        selectedAreaOfInterest.length === 0 && (
                            <Typography
                                sx={{
                                    color: JC_COLORS.JC_RED,
                                    textAlign: 'left',
                                    fontSize: '12px',
                                    lineHeight: '14px',
                                    marginTop: "8px",
                                }}
                            >
                                Please select an area of interest for the journal club.
                            </Typography>
                        )

                    }

                </Grid>
                <Grid item xs={12} md={6} className='managetab-content' sx={{ display: "none" }}>
                    <InputLabel
                        required
                        sx={{
                            color: JC_COLORS.JC_ICON,
                            fontWeight: '700 !important',
                            fontFamily: "Raleway",
                            marginBottom: "11px"
                        }}
                    >
                        Topic
                    </InputLabel>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <StyledTextField
                            value={topic}
                            placeholder={"Add a topic"}
                            autoComplete='off'
                            onChange={
                                (event) => {
                                    let val = event.target.value;
                                    setTopic(val);
                                }
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            topic.length > 0 && (
                                                <IconButton
                                                    onClick={() => setTopic("")}
                                                >
                                                    <CloseIcon />
                                                </IconButton>)
                                        }

                                    </InputAdornment>
                                ),
                            }}
                        ></StyledTextField>
                        <StyledAddButton
                            variant="outlined"
                            sx={{ marginLeft: '4px' }}
                            onClick={addJcTopic}
                        >
                            Add
                        </StyledAddButton>

                    </Box>
                    {
                        jcTopics.length > 0 ? (
                            <Box>
                                <FlexWrappedList>
                                    {
                                        jcTopics.map(obj => (
                                            <KeyWordChip
                                                key={obj}
                                                label={obj}
                                                showCloseIcon={true}
                                                selected={false}
                                                // onClick={() => clickSearchTerm(obj)}
                                                closeButtonHandler={() => removeJcTopic(obj)}
                                            />)
                                        )
                                    }

                                </FlexWrappedList>
                            </Box>
                        ) : (
                            <Typography
                                sx={{
                                    color: JC_COLORS.JC_RED,
                                    textAlign: 'left',
                                    fontSize: '12px',
                                    lineHeight: '14px',
                                    marginTop: "8px",
                                }}
                            >
                                Please add atleast one topic for the journal club.
                            </Typography>
                        )
                    }



                </Grid>
            </Grid>

            <Grid container columnSpacing={4}>
                <Grid item xs={12} md={6} className='managetab-content'>
                    <InputLabel
                        required
                        sx={{
                            color: JC_COLORS.JC_ICON,
                            fontWeight: '700 !important',
                            fontFamily: "Raleway",
                            marginBottom: "11px"
                        }}
                    >
                        Frequency Of Journal Club Publication
                    </InputLabel>
                    <FormControl>
                        <Select
                            value={newsletterPublicationFrequency || ""}
                            onChange={(event) => setNewsletterPublicationFrequency(event.target.value)}
                            onOpen={() => setDropDownMenuOpened(true)}
                            onClose={() => setDropDownMenuOpened(false)}
                            IconComponent={() => dropDownMenuOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            inputProps={{ 'aria-label': 'Select' }}
                            placeholder="select"
                            displayEmpty
                            MenuProps={MenuProps}
                            required
                            sx={{
                                height: "42px",
                                //width: "23vw",
                                border: `1px solid ${JC_COLORS.JC_ICON}`,
                                paddingRight: "8px"
                            }}
                        >
                            <MenuItem value={""}>
                                <Typography>-Select-</Typography>
                            </MenuItem>
                            {
                                Object.keys(NEWSLETTER_PUBLICATION_FREQUENCY).map((key, i) => {
                                    return (
                                        <MenuItem
                                            key={key}
                                            value={NEWSLETTER_PUBLICATION_FREQUENCY[key].value}>
                                            <Typography
                                                variant="BODY_ARTICLE_RALEWAY"
                                                sx={{
                                                    textTransform: 'capitalize'
                                                }}
                                            >
                                                {NEWSLETTER_PUBLICATION_FREQUENCY[key].value}
                                            </Typography>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {
                    (
                        (newsletterPublicationFrequency !== "") &&
                        (newsletterPublicationFrequency !== NEWSLETTER_PUBLICATION_FREQUENCY.NEVER.value)
                    ) ? (
                        <Grid item xs={12} md={6} className='managetab-content'>
                            <InputLabel
                                sx={{
                                    color: JC_COLORS.JC_ICON,
                                    fontWeight: '700 !important',
                                    fontFamily: "Raleway",
                                    marginBottom: "11px"
                                }}
                            >
                                {`Daily Reminder Notification (0 for none).`}
                            </InputLabel>

                            <input
                                type="number"
                                step="1"
                                min={NEWSLETTER_PUBLICATION_FREQUENCY[newsletterPublicationFrequency.toUpperCase()]?.min}
                                max={NEWSLETTER_PUBLICATION_FREQUENCY[newsletterPublicationFrequency.toUpperCase()]?.max}
                                value={reminderEmailFrequency}
                                onChange={
                                    (event) => {
                                        let val = event.target.value;
                                        setReminderEmailFrequency(val ?? "0")
                                    }
                                }
                                style={{
                                    lineHeight: "32px",
                                    width: "64px"
                                }}
                            />
                            {
                                (
                                    (reminderEmailFrequency < NEWSLETTER_PUBLICATION_FREQUENCY[newsletterPublicationFrequency.toUpperCase()].min) ||
                                    (reminderEmailFrequency > NEWSLETTER_PUBLICATION_FREQUENCY[newsletterPublicationFrequency.toUpperCase()].max)
                                ) && (
                                    <Typography
                                        sx={{
                                            color: JC_COLORS.JC_RED,
                                            textAlign: 'left',
                                            fontSize: '12px',
                                            lineHeight: '14px',
                                            marginTop: "8px",

                                        }}
                                    >
                                        {
                                            `Daily reminder email frequency should be in the range ${NEWSLETTER_PUBLICATION_FREQUENCY[newsletterPublicationFrequency.toUpperCase()].min} and ${NEWSLETTER_PUBLICATION_FREQUENCY[newsletterPublicationFrequency.toUpperCase()].max}`
                                        }
                                    </Typography>

                                )
                            }
                            {
                                (Number.isInteger(Number(reminderEmailFrequency)) === false) && (
                                    <Typography
                                        sx={{
                                            color: JC_COLORS.JC_RED,
                                            textAlign: 'left',
                                            fontSize: '12px',
                                            lineHeight: '14px',
                                            marginTop: "8px",

                                        }}
                                    >
                                        {
                                            `Number of days should be an integer.`
                                        }
                                    </Typography>

                                )
                            }

                        </Grid>
                    ) : <p></p>
                }

            </Grid>
            {
                create === true && (
                    <>
                        <Grid container columnSpacing={4}>
                            <Grid item xs={12} md={6} className='managetab-content'>
                                <InputLabel
                                    required
                                    sx={{
                                        color: JC_COLORS.JC_ICON,
                                        fontWeight: '700 !important',
                                        fontFamily: "Raleway",
                                        marginBottom: "11px"
                                    }}
                                >
                                    Your role in this journal club
                                </InputLabel>
                                <FormControl>
                                    <Select
                                        value={userRoleInJC || ""}
                                        onChange={(event) => setUserRoleInJC(event.target.value)}
                                        onOpen={() => setDropDownMenuOpened(true)}
                                        onClose={() => setDropDownMenuOpened(false)}
                                        IconComponent={() => dropDownMenuOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        inputProps={{ 'aria-label': 'Select' }}
                                        placeholder="select"
                                        displayEmpty
                                        MenuProps={MenuProps}
                                        required
                                        sx={{
                                            height: "42px",
                                            //width: "23vw",
                                            border: `1px solid ${JC_COLORS.JC_ICON}`,
                                            paddingRight: "8px"
                                        }}
                                    >
                                        <MenuItem value={""}>
                                            <Typography>-Select-</Typography>
                                        </MenuItem>
                                        <MenuItem value={USER_ROLES.COURSE_DIRECTOR}>
                                            <Typography
                                                variant="BODY_ARTICLE_RALEWAY"
                                            >
                                                {USER_ROLES.COURSE_DIRECTOR}
                                            </Typography>

                                        </MenuItem>
                                        <MenuItem value={USER_ROLES.ADMINISTRATOR}>
                                            <Typography
                                                variant="BODY_ARTICLE_RALEWAY">
                                                {USER_ROLES.ADMINISTRATOR}
                                            </Typography>
                                        </MenuItem>

                                    </Select>
                                </FormControl>
                                {
                                    (userRoleInJC === null || userRoleInJC === "") && (
                                        <Typography
                                            sx={{
                                                color: JC_COLORS.JC_RED,
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                lineHeight: '14px',
                                                marginTop: "8px",
                                            }}
                                        >
                                            Your role in this journal club is required.
                                        </Typography>
                                    )

                                }
                            </Grid>
                        </Grid>
                        <Grid container sx={{ marginTop: "10px" }}>
                            <Grid item xs={12} className='managetab-content' >

                                <InputLabel
                                    required
                                    sx={{
                                        color: JC_COLORS.JC_ICON,
                                        fontWeight: '700 !important',
                                        fontFamily: "Raleway",

                                    }}
                                >
                                    Journal Club Bill Of Rights
                                </InputLabel>
                                <ul>
                                    <li>
                                        <ListItemsText>
                                            No one will sell data obtained from this platform.
                                        </ListItemsText>
                                    </li>
                                    <li>
                                        <ListItemsText sx={{ listStyle: 'disc outside none' }}>
                                            No one will engage in promotional activity on this platform.
                                        </ListItemsText>
                                    </li>
                                    <li style={{ listStyle: 'disc outside none' }}>
                                        <ListItemsText>
                                            Journal Club, Inc. does not add, modify or influence the content on this platform.
                                        </ListItemsText>
                                    </li>
                                </ul>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        margin: '0px 0px 0px 4px',
                                    }}
                                >
                                    <StyledCheckbox
                                        checked={isCheckedBillOfRights}
                                        onChange={() => setIsCheckedBillOfRights(!isCheckedBillOfRights)}
                                    />
                                    <CheckBoxText>
                                        By checking this box, I understand and agree to the <Link target="_blank" href={TERMS_OF_SERVICE_PDF} sx={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }}>Terms Of Service</Link> as well as the <Link target="_blank" href={PRIVACY_POLICY_PDF} sx={{ textDecoration: 'none', color: 'blue', cursor: 'pointer' }} >Privacy Policy</Link> of this site.
                                    </CheckBoxText>


                                </Box>
                            </Grid>
                        </Grid>
                    </>

                )
            }



            <Box sx={{ marginTop: "95px", marginBottom: '103px' }}>

                <CenteredActionButton
                    onClick={
                        async () => {
                            try {
                                if (create === true) {
                                    let res = await createJournalClub(data);
                                    if (res?.status === 201) {
                                        dispatch(setJCContent(JC_CONTENT.MANAGE))
                                        navigate(`/jc/${res?.data}`);
                                        
                                    }
                                    console.log(res)

                                } else {
                                    let urlSlug = jcDetails?.urlSlug || params?.urlSlug;
                                    let res = await editJournalClub(urlSlug, data)
                                    if (res?.status === 201) {
                                        let newUrlSlug = res?.data?.urlSlug;
                                        setShowWarningForEditingJcName(false);
                                        dispatch(setJCContent(JC_CONTENT.MANAGE));
                                        dispatch(getJournalClubDetails(newUrlSlug, userEmail, false));
                                        if(newUrlSlug !== urlSlug) {
                                            navigate(`/jc/${newUrlSlug}`);
                                        }
                                        
                                    }
                                   

                                }
                            } catch (error) {

                                setOpenErrorDialog(true);
                                setErrorMessage(`Error: ${error?.response?.data}, Status: ${error?.response?.status}`);
                            }

                        }
                    }
                    disabled={!isFormCompleted}
                >
                    <Typography
                        variant="BODY_ARTICLE_RALEWAY"
                        color={JC_COLORS.JC_WHITE}
                        sx={{
                            fontWeight: '700 !important',

                        }}
                    >
                        {create === true ? "PROCEED AND CREATE" : "Save"}
                    </Typography>
                </CenteredActionButton>
            </Box>
            <ErrorDialogBox
                open={openErrorDialog}
                closeDialogHandler={() => setOpenErrorDialog(false)}
                title={"Error in sending data"}
            >
                <Typography>{errorMessage}</Typography>
            </ErrorDialogBox>
        </Box >
    )
}

export default EditJCInfo; 