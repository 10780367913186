import React from "react";

const PracticeIcon = ({className,fillcolor,...props}) => {
    return (
        <svg width="32" {...props} className={className} height="27" viewBox="0 0 32 31" fill={fillcolor} xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9305 5.63374L15.9277 3.08608L15.0525 5.68032L12.9626 11.8748L5.95066 12.2212L3.28999 12.3527L5.37991 14.0045L10.6261 18.1511L8.88348 24.3493L8.14207 26.9864L10.408 25.4472L15.9748 21.666L21.5585 25.8035L24.0508 27.6502L23.1064 24.6955L21.0166 18.1573L26.5954 14.0235L28.8322 12.3659L26.0519 12.2213L19.3871 11.8748L16.9305 5.63374Z" stroke={fillcolor=="None" ? "#838383" : fillcolor} strokeWidth="2"/>
        </svg>
          
    )
}
export default PracticeIcon