import React from 'react'
import JCHeader from "../common/components/JCHeader";
import { Box, Button } from "@mui/material"
import { styled } from '@mui/system';
import { JC_COLORS } from '../styles/constants';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: JC_COLORS.JC_RED,
  fontFamily: 'Raleway',
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    marginLeft: '11px',
    padding: '8px 22px',
    width: '142px',
    height: '42px',
    borderRadius: '4px',
    fontSize: '15px',
    lineHeight: '26px',
    '&:hover': {
      backgroundColor: 'white',
      color: JC_COLORS.JC_RED,
    }

  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '5px',
    padding: '4px 10px',
    width: '62px',
    height: '32px',
    fontSize: '12px',
    lineHeight: '20px',
    marginBottom: '16px'
  }
}));

const LandingPage = () => {
  return (
    <Box sx={{display:'none'}}>
      <JCHeader greet={true} />
    </Box>
  )
}

export default LandingPage