import React, { useContext, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { Navigate, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { USER_STATUS } from '../constants/constants';
import { useKeywordsContext } from '../context/KeywordsContext';
import { logout } from '../utils';
import { getUserDetails } from '../state/actions/user';
import { AuthContext } from '../context/AuthContext';
import * as api from '../api';


const UserProfileCheck = ({ children }) => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const handleError = useErrorHandler();

    useEffect(() => {
        if (userEmail) {
            api.fetchUserDetails(userEmail)
                .then(
                    (res) => {
                        let data = res?.data;
                        if (data?.acceptedTermsAndConditions === null || data?.acceptedTermsAndConditions === false) {
                            window.location = "/terms-and-conditions";
                        } else if (
                            data?.areasOfInterest === null || data?.areasOfInterest?.length < 1 ||
                            data?.profession === null || data?.profession?.length < 1 ||
                            data?.insitution === null || data?.institution?.length < 1
                        ) {
                            window.location = "/profile";
                        } else {
                            //console.log(res);
                        }
                    },
                    (error) => {
                        handleError("Error !!!")
                    }
                )
                .catch(error => console.log(error))

        }

    }, []);

    return (
        <>{children}</>
    )
}

export default UserProfileCheck