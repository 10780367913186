import React, { useState } from 'react';
import CustomRadioDateRange from './CustomRadioDateRange';
import { FormControl, FormControlLabel, RadioGroup, Radio, Grid, Box, TextField, Typography, Button, Select, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/system';

import { Row, Spacer } from '../../../common/components';
import { OPTIONS_SORT_ARTICLES_BY_TIME } from '../../../constants/constants';
import { JC_COLORS } from '../../../styles/constants';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';


const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    height: '42px',
    minWidth: '142px',
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));


const StyledFormControl = styled(FormControl)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: "375px",

    }

}));

const StyledDateInput = styled(TextField)(({ theme }) => ({
    padding: 0,
    backgroundColor: JC_COLORS.JC_GREY,
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.down('md')]: {}
}));



const CustomDropdownDateRange = ({ dateRangeLabel, changeDateRangeLabel, changeDateRangeHandler,isHeading = true,isWhite = false, filterCount = 8 }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState(OPTIONS[dateRangeLabel]);
    const [option, setOption] = useState(dateRangeLabel);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                {isHeading &&
                <Typography variant="H3_BITTER" fontWeight='bold' sx={{ marginRight: "16px", fontSize: "1rem", lineHeight: "1.5rem" }}>Date Range</Typography>
                }
                <StyledButton
                    variant="outlined"
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{color:'#EF5B50',backgroundColor:'#FFFFFF', border: isWhite ?? '1px solid #FFFFFF',"&.MuiButtonBase-root:hover": {bgcolor: '#FFFFFF'}}}
                >
                    {option}
                </StyledButton>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <StyledFormControl>
                        <RadioGroup
                            name="sort-by-time"
                            onChange={
                                (event) => {
                                    setOption(event.target.value);
                                    changeDateRangeLabel(event.target.value);
                                    changeDateRangeHandler(OPTIONS[event.target.value]);
                                    if (event.target.value !== 'CUSTOM') {
                                        handleClose();
                                    }

                                }
                            }
                        >
                            <Grid container spacing={0.5}>
                                {
                                    Object.keys(OPTIONS_SORT_ARTICLES_BY_TIME).slice(0,filterCount).map((el, i) => (
                                        <Grid item sm={6} xs={6} key={i}>
                                            <FormControlLabel
                                                value={el}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                display: 'none',
                                                            },

                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Row
                                                        sx={{
                                                            width: "9rem",
                                                            height: '2rem',
                                                            borderRadius: '2px',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            padding: '0 0.5rem',
                                                            backgroundColor: el === option ? JC_COLORS.JC_RED : '#F3F3F3',
                                                            color: el === option ? JC_COLORS.JC_WHITE : JC_COLORS.JC_ICON,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Raleway',
                                                                fontWeight: '600',
                                                                fontSize: '0.75rem',
                                                                lineHeight: '0.875rem',
                                                            }}
                                                        >
                                                            {el}
                                                        </Typography>
                                                        {el === option && (<CheckIcon />)}

                                                    </Row>

                                                }
                                            />
                                        </Grid>
                                    ))
                                }
                                {
                                    option === 'CUSTOM' && (
                                        <>
                                            <Grid item sm={6} xs={6} key={`from-date`}>
                                                <Box sx={{ ml: "10px" }}>
                                                    <Typography
                                                        sx={{
                                                            color: JC_COLORS.JC_ICON,
                                                            fontSize: "1rem",
                                                            lineHeight: "1.5rem",
                                                            fontWeight: "800"
                                                        }}
                                                    >
                                                        From
                                                    </Typography>
                                                    <MobileDatePicker
                                                        //fullWidth
                                                        disableCloseOnSelect={false}
                                                        value={startDate}
                                                        onChange={(date) => setStartDate(Date.parse(moment(date).format('YYYY-MM-DD')))}
                                                        inputFormat="DD.MM.yyyy"
                                                        orientation="portrait"
                                                        renderInput={(params) => {
                                                            params.InputProps.sx = {
                                                                height: "32px",
                                                                width: "158px",
                                                                borderRadius: "2px",
                                                                fontSize: '0.8rem',
                                                                lineHeight: '1.2rem',
                                                            };
                                                            params.InputProps.placeholder = "DD.MM.YYYY";

                                                            return (
                                                                <StyledDateInput
                                                                    variant="outlined"
                                                                    {...params}
                                                                />
                                                            )
                                                        }
                                                        }
                                                        okText=""
                                                        cancelText=""
                                                        toolbarTitle="From"
                                                        showToolbar={false}
                                                        keyboardButtonProps={{ disabled: true, style: { display: 'none' } }}

                                                    />

                                                </Box>

                                            </Grid>
                                            <Grid item sm={6} xs={6} key={`to-date`}>
                                                <Box sx={{ mr: "20px", ml: "5px" }}>
                                                    <Typography
                                                        sx={{
                                                            color: JC_COLORS.JC_ICON,
                                                            fontSize: "1rem",
                                                            lineHeight: "1.5rem",
                                                            fontWeight: "800"
                                                        }}
                                                    >
                                                        To
                                                    </Typography>
                                                    <MobileDatePicker
                                                        disableCloseOnSelect={false}
                                                        value={endDate}
                                                        onChange={(date) => setEndDate(Date.parse(moment(date).format('YYYY-MM-DD')))}
                                                        inputFormat="DD.MM.yyyy"
                                                        orientation="portrait"
                                                        //placeholder={"DD.MM.YYYY"}
                                                        renderInput={(params) => {
                                                            params.InputProps.sx = {
                                                                height: "32px",
                                                                width: "158px",
                                                                borderRadius: "2px",
                                                                fontSize: '0.8rem',
                                                                lineHeight: '1.2rem',
                                                            };
                                                            params.InputProps.placeholder = "DD.MM.YYYY";
                                                            return (
                                                                <StyledDateInput
                                                                    variant="outlined"
                                                                    {...params}
                                                                />
                                                            )
                                                        }
                                                        }
                                                        okText=""
                                                        cancelText=""
                                                        toolbarTitle="To"
                                                        showToolbar={false}
                                                        keyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </>
                                    )
                                }


                            </Grid>
                            {
                                option === 'CUSTOM' && (
                                    <Box sx={{
                                        marginTop: "16px",
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center'
                                    }}>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                width: "92px",
                                                height: "42px"
                                            }}
                                            onClick={
                                                () => {
                                                    changeDateRangeLabel("CUSTOM");
                                                    changeDateRangeHandler(
                                                        {
                                                            startDate: moment(startDate).format('MM/DD/YYYY'),
                                                            endDate: moment(endDate).format('MM/DD/YYYY')
                                                        }
                                                    );
                                                    handleClose();
                                                }
                                            }
                                        >Apply</Button>
                                    </Box>
                                )
                            }
                        </RadioGroup>
                    </StyledFormControl >

                </Menu>
            </Box>
        </LocalizationProvider>

    )
}

export default CustomDropdownDateRange


const dateToday = moment.utc().format('L');
const date1Week = moment.utc().subtract(7, 'days').format('L');
const date15Days = moment.utc().subtract(15, 'days').format('L');
const date1Month = moment.utc().subtract(1, 'month').format('L');
const date3Months = moment.utc().subtract(3, 'month').format('L');
const date6Months = moment.utc().subtract(6, 'month').format('L');
const date1Year = moment.utc().subtract(1, 'year').format('L');

const OPTIONS = {
    "Past 1 Week": { startDate: date1Week, endDate: dateToday },
    "Past 15 Days": { startDate: date15Days, endDate: dateToday },
    "Past 1 Month": { startDate: date1Month, endDate: dateToday },
    "Past 3 Months": { startDate: date3Months, endDate: dateToday },
    "Past 6 Months": { startDate: date6Months, endDate: dateToday },
    "Past 1 Year": { startDate: date1Year, endDate: dateToday },
    "CUSTOM": {},
    "All Time": { startDate: null, endDate: null },
};