import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Box, Avatar, Typography, Menu, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { JC_COLORS } from '../../styles/constants';
import AvatarNameRow from '../../pages/JCDetails/components/AvatarNameRow';
import Spacer from './Spacer';
import { getDenovoEditHistory } from '../../state/actions/article';
import { useTheme } from '@emotion/react';


const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: 'none',
    "&:hover": {
        border: 'none',
        backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('md')]: {
        height: '42px',
        minWidth: '302px',
    },

    [theme.breakpoints.down('md')]: {
        backgroundColor: '#F3F3F3',
        width: '100%',
    }

}));

const SquareAvatar = styled(Avatar)(({ theme }) => ({
    borderRadius: '2px!important',
    [theme.breakpoints.up('md')]: {
        width: '38px',
        height: '38px',
        fontSize: '30px',
        lineHeight: '36px',
    },
    [theme.breakpoints.down('md')]: {
        width: '28px',
        height: '28px',
        fontSize: '24px',
        lineHeight: '36px',
    }
}))

const DenovoEditHistoryComponent = ({ JCID, PMID }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        if (denovoEditHistory?.editors?.length > 1) {
            setAnchorEl(event.currentTarget);
        }

    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const { denovoEditHistory } = useSelector((state) => state.article);
    const { jcDetails } = useSelector((state) => state.journalclub);
    const dispatch = useDispatch();

    return (
        <>
            <StyledButton
                variant="outlined"
                onClick={handleClick}
                endIcon={(denovoEditHistory?.editors?.length > 1 && (open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />))}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',

                }}
            >
                <Typography
                    variant="BODY_COPY2_RALEWAY"
                    sx={{
                        display: 'inline-block',
                        textAlign: 'left',
                        color: JC_COLORS.JC_BLACK,
                        fontWeight: '600',
                    }}>
                    Last Edited by
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        color: JC_COLORS.JC_BLACK,
                        marginLeft: '5px',

                    }}>
                    <SquareAvatar
                        variant="square"
                        name={denovoEditHistory?.editors?.length > 0 ? denovoEditHistory?.editors[0].fullName : ""}
                    >
                    </SquareAvatar>
                    <Typography
                        variant="BODY_COPY2_RALEWAY"
                        sx={{
                            marginLeft: '9px',
                            textAlign: 'left',
                        }}>
                        {denovoEditHistory?.editors?.length > 0 && denovoEditHistory?.editors[0].fullName}
                    </Typography>

                </Box>
            </StyledButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Box sx={{ padding: "13px 16px", minWidth: '272px' }}>
                    <Typography
                        sx={{
                            fontFamily: "Raleway",
                            fontWeight: "700",
                            [theme.breakpoints.up('md')]: {
                                fontSize: '15px',
                                lineHeight: '17.61px',
                            },
                            [theme.breakpoints.down('md')]: {
                                fontSize: '10px',
                                lineHeight: '11.74px',
                            }

                        }}
                    >
                        Other Contributors
                    </Typography>
                    <Spacer height="12px" />
                    {
                        (typeof (denovoEditHistory) === "object") && denovoEditHistory?.editors?.map((item, i) => (
                            <ListElement
                                key={i}
                                name={item?.fullName}
                                editedOn={item?.lastEdited}
                            />
                        )
                        )

                    }
                </Box>
            </Menu>


        </>
    )
}

export default DenovoEditHistoryComponent

const ListElement = ({ name, editedOn }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '7px',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',

                }}>
                <SquareAvatar variant="square" name={name?.length > 0 ? name[0] : ""}></SquareAvatar>
                <Typography
                    variant="BODY_COPY2_RALEWAY"
                    sx={{
                        marginLeft: '9px',
                        maxWidth: '90%',
                        display: "-webkit-inline-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}>{name}</Typography>

            </Box>
            <Typography
                variant="BODY_COPY2_RALEWAY"
                color={JC_COLORS.JC_ICON}
            >
                {moment.utc(editedOn).local().fromNow()}
            </Typography>


        </Box>
    )
}

