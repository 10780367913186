import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { Hidden, Box, Accordion, AccordionSummary,CircularProgress, AccordianDetails,Button, Typography, Paper, Avatar, styled, AccordionDetails, IconButton, TextField, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Backdrop from '@mui/material/Backdrop';
import { JC_COLORS } from '../../styles/constants';
import { ChevronLeft } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CommentNotes from "./CommentNotes";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { AuthContext } from '../../context/AuthContext';
import { useNavigate, useParams, Navigate, useLocation, } from 'react-router';
import { htmlToJCEditor } from '../../utils/JCWysiwygEditor';
import { getJournalClubArticle, setDirty, closeDenovoArticleInEditMode, updateDenovoArticleData, setErrorInDenovoArticle, autoSaveDenovoArticleToDB, updateDenovoArticleToDB, resetDenovoLockState } from '../../state/actions/article';
import { isEditorEmpty, formats,CommentCustomSkin,CommentHistory,updateInFilesEditor,wrapIntoPara, DOCUMENT_EDIT_CONSTANT } from '../../utils/editorBasics';
import DeNovoArticleStatus from './DeNovoArticleStatus';
import Spacer from './Spacer';
import { InlineBox } from '../../pages/JCDetails/jc-content/report-content/ReportHelpers';
import AutoSummary from './AutoSummary';
import { v4 } from 'uuid';
import { IgniteWysiwygEditor } from 'ignite-editor';
import 'ignite-editor/dist/index.css';
import { ArticleAbstractModule, ArticleBodyModule } from '../../utils/editorModules';

const editorStyle = {
    bordeRadius: '10px',
    width: '100%',
    padding: '0px 8px',
    marginTop: '-20px',
    minHeight: '280px',
}

const toolbarStyle = {
    padding: '6px 5px 0',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'flex-start',
    background: 'white',
    flexWrap: 'wrap',
    marginBottom: '5px',
    userSelect: 'none',
    position: 'relative',
    width: '100%',
    top: '-18px',
    left: '0px',
    zIndex: '4',
}

const StyledAccordionContainer = styled(Box)(({ theme }) => ({
    borderRadius: '4px',
    backgroundColor: '#F3F3F3',
    [theme.breakpoints.up('md')]: {
        marginBottom: '8px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0px 15px 8px 15px',
    }
}))

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    "&.MuiAccordion-root:before": {
        backgroundColor: "white"
    },
    [theme.breakpoints.up('md')]: {
        marginBottom: '8px',
    }
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
    ...theme.typography.H2_BITTER,
    color: JC_COLORS.JC_ICON,
    width: '100%',
    textAlign:'left',
    [theme.breakpoints.up('md')]: {


    },
    [theme.breakpoints.down('md')]: {

    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#262626',
    padding: theme.spacing(1),
    textAlign: 'initial',
    [theme.breakpoints.down('md')]: {
        borderRadius: 'None',
        padding: 0,
        boxShadow: 'None'
    },
}));

const StyledMultilineTextField = styled(TextField)(({ theme }) => ({
    ...theme.typography.H1_BITTER,
    "&&&:before": {
        borderBottom: "none"
    },
    "&&:after": {
        borderBottom: "none"
    },

}));

const OneLineText = styled(Typography)(({ theme, expanded }) => ({
    display: expanded ? "none" : "-webkit-inline-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
        width: '90%',
    },
    [theme.breakpoints.down('md')]: {

    }

}));


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    const MIN_VAL = 0;
    const MAX_VAL = 10;
    const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            allowNegative={false}
            fixedDecimalScale
            thousandSeparator
            isNumericString
            prefix=""
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const ArticleDenovoView = ({
    JCID,
    PMID,
    journalClubName,
    articleTitle,
    articleAbstract,
    articleAuthors,
    articleBody,
    bodyMedia,
    abstractMedia,
    articleCmeCredits,
}) => {
    const theme = useTheme();

    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { articleData, denovoArticleInEditMode, denovoArticleData } = useSelector((state) => state.article);
    const { jcDetails } = useSelector((state) => state.journalclub);

    const [title, setTitle] = useState(articleTitle);
    const [authors, setAuthors] = useState((articleAuthors && articleAuthors?.length > 0) ? articleAuthors.join(", ") : "");
    const [cmeCredits, setCmeCredits] = useState(articleCmeCredits);
    const mobileMatch = useMediaQuery(theme.breakpoints.down('md'))
    // Abstract states
    const [abstractEditorState, setAbstractEditorState] = useState('<p></p>')
    const abstractEditorRef = useRef();
    // Article Body states
    const [articleBodyEditorState, setArticleBodyEditorState] = useState('<p></p>')
    const bodyEditorRef = useRef();

    const [expanded, setExpanded] = useState('title-panel');
    const [summaryOpen,setSummaryOpen] = useState(false)
    const [writeBodyOpen,setWriteBodyOpen] = useState(false)

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        UpdateDocumentOnLoad()
    };

    useEffect(() => {
        const handleScroll = () => {
          const sourceElement = document.querySelector('span.ql-color.ql-picker.ql-color-picker');
          var positionObject = {}
          if(sourceElement) {
            positionObject = sourceElement.getBoundingClientRect();
          }
          const targetElement = document.querySelector('span.ql-picker-options');
          if(targetElement && sourceElement) {
            targetElement.style.top = `${positionObject.top + 38}px`;
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);
     
    const UpdateDocumentOnLoad = () => {
        setTimeout(()=> {
            if(denovoArticleData.bodyUploadedDocuments){
                denovoArticleData.bodyUploadedDocuments.map((uploadFile) =>{
                updateInFilesEditor(uploadFile,true)
              })
            }
            if(denovoArticleData.abstractUploadedDocuments){
                denovoArticleData.abstractUploadedDocuments.map((uploadFile) =>{
                updateInFilesEditor(uploadFile,true)
              })
            }
            if(bodyMedia){
                bodyMedia.map((media) => {
                    const inputFile = {
                        id: media.fileId + DOCUMENT_EDIT_CONSTANT,
                        src: media.downloadUrl,
                        file : {
                            name: media.fileName,
                            size : media.fileSize
                        }
                    }
                    updateInFilesEditor(inputFile,true)
                })
            }
            if(abstractMedia){
                abstractMedia.map((media) => {
                    const inputFile = {
                        id: media.fileId + DOCUMENT_EDIT_CONSTANT,
                        src: media.downloadUrl,
                        file : {
                            name: media.fileName,
                            size : media.fileSize
                        }
                    }
                    updateInFilesEditor(inputFile,true)
                })
            }
        },100)
    }

    // Error handling for fields
    const [errorsInTitle, setErrorsInTitle] = useState(false);
    const [errorsInAuthors, setErrorsInAuthors] = useState(false);

    useEffect(() => {
        if (articleAbstract && articleAbstract.length > 0) {
            setAbstractEditorState(htmlToJCEditor(articleAbstract,abstractMedia))
        }
        if (articleBody && articleBody.length > 0) {
            setArticleBodyEditorState(htmlToJCEditor(articleBody,bodyMedia))
        }
    }, [articleAbstract,articleBody])


    const abstractEditorStateChange = editorState => {
        setAbstractEditorState(editorState)
    }

    const articleBodyEditorStateChange = editorState => {
        setArticleBodyEditorState(editorState)
    }


    useEffect(() => {
        setErrorsInTitle(checkErrorsInTitle(title));
        setErrorsInAuthors(checkErrorsInAuthors(authors));

        dispatch(
            updateDenovoArticleData(
                {
                    title,
                    abstractEditorState: abstractEditorState,
                    articleBodyEditorState: articleBodyEditorState,
                    authors,
                    cmeCredits: cmeCredits > 10 ? 10 : cmeCredits,
                }
            )
        );
        dispatch(setDirty(true));

    }, [title, authors, cmeCredits, abstractEditorState, articleBodyEditorState])
     
    const addTextToArticleBody = (text) => {
        setArticleBodyEditorState((prevState) => prevState + wrapIntoPara(text))
        setExpanded('article-body-panel')
        UpdateDocumentOnLoad()
    }

    const addTextToSummary = (text) => {
        setAbstractEditorState(wrapIntoPara(text));
        setExpanded('abstract-panel')
        UpdateDocumentOnLoad()
    };

    const handleConvertSummay = () => {
        setSummaryOpen(true)
    }
    const handleSummerizeClick = () =>{
        setWriteBodyOpen(true)
    }

    const toolbarOptions = [
        ['image','video','localvideo','attachment'],
        [
          { header: 1 },
          { header: 2 },
          'bold',
          'italic',
          'underline',
          { list: 'ordered' },
          { list: 'bullet' },
          { script: 'sub' },
          { script: 'super' },
          { indent: '-1' },
          { indent: '+1' },
          { color: [] },
          'link',
        ],
      ];

    return (
        <React.Fragment>
            <Hidden mdDown>
                <Item
                    sx={{
                        margin: '0px 0px 8px 0px',
                    }}
                    elevation={0}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                onClick={
                                    () => {
                                        dispatch(
                                            updateDenovoArticleToDB({
                                                denovoArticleData,
                                                JCID: jcDetails?.JCID,
                                                PMID,
                                                userEmail,
                                                articleData
                                            })
                                        );
                                    }
                                }
                            >
                                <ChevronLeft
                                    style={{
                                        width: '21px',
                                        height: '23px',
                                        fontSize: '24px',
                                        lineHeight: '32px',
                                    }}
                                />
                            </IconButton>
                            <Typography
                                variant="H2_BITTER"
                                sx={{
                                    display: 'inline-block',
                                    wordWrap: 'break-word',
                                    textTransform: 'capitalize'
                                }}
                            >
                                {journalClubName}
                            </Typography>

                        </Box>


                    </Box>
                </Item>

            </Hidden>

            <Hidden mdUp>

                <Box
                    sx={{
                        alignItems: 'flex-start',
                    }}
                >
                    <Spacer height={"18px"} />
                    {
                        articleData?.isDeNovo === true && (
                            <>
                                <DeNovoArticleStatus
                                    currentUserEditingStatus={denovoArticleInEditMode}
                                    hideEditButton={true}
                                />
                            </>

                        )
                    }

                </Box>
            </Hidden>

            <StyledAccordionContainer>
                <StyledAccordion
                    elevation={0}
                    onChange={handleChange('title-panel')}
                    expanded={expanded === 'title-panel' ? true : false}
                >
                    {
                        title.length > 0 && (
                            <AccordionSummary>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }} >
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                                        <SectionHeader>
                                            Title*
                                        </SectionHeader>

                                        {
                                            expanded === 'title-panel' ? (
                                                <StyledExpandLessIcon />
                                            ) : (
                                                <StyledExpandMoreIcon />
                                            )
                                        }

                                    </Box>

                                    <OneLineText
                                        //variant="H1_BITTER"
                                        expanded={expanded === "title-panel"}
                                        sx={{
                                            fontFamily: 'Bitter',
                                            fontWeight: '500',
                                            fontSize: '20px',
                                            lineHeight: '36px',
                                        }}
                                    >
                                        {title}
                                    </OneLineText>
                                </Box>
                            </AccordionSummary>
                        )
                    }
                    <AccordionDetails
                        sx={{
                            alignItems: 'flex-start',
                        }}>
                        <StyledMultilineTextField
                            error={errorsInTitle}
                            helperText={errorsInTitle ? "Title cannot be empty" : ""}
                            fullWidth
                            multiline
                            autoFocus
                            variant="standard"
                            minRows={15}
                            onFocus={
                                (event) =>
                                    event.currentTarget.setSelectionRange(
                                        event.currentTarget.value.length,
                                        event.currentTarget.value.length
                                    )
                            }
                            placeholder={"Title"}
                            inputProps={{
                                style: {
                                    ...theme.typography.H1_BITTER,
                                    fontWeight: '500',
                                    fontSize: mobileMatch ? "22px" : "30px",
                                    lineHeight: mobileMatch ? "28px" : "40px"
                                }
                            }}
                            InputProps={{
                                disableUnderline: true
                            }}

                            value={title}
                            onChange={
                                (event) => {
                                    //setIsTypingTitle(true); 
                                    setTitle(event.target.value)
                                }
                            }
                        />
                    </AccordionDetails>
                </StyledAccordion>
            </StyledAccordionContainer>

            <StyledAccordionContainer>
                <StyledAccordion
                    elevation={0}
                    onChange={handleChange('article-body-panel')}
                    expanded={expanded === 'article-body-panel' ? true : false}
                >
                    <AccordionSummary>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                            <SectionHeader>
                                Article Body*
                                <InlineBox sx={{float:'right',marginRight:'8px' }} >
                                    <Button variant="outlined" size="small" sx={{fontFamily: 'Raleway',fontWeight: '700',textTransform:'none'}} onClick={handleSummerizeClick} >
                                    Summarize PDFs
                                    </Button>
                                </InlineBox>
                            </SectionHeader>
                            {
                                expanded === 'article-body-panel' ? (
                                    <StyledExpandLessIcon />
                                ) : (
                                    <StyledExpandMoreIcon />
                                )
                            }
                        </Box>
                    </AccordionSummary>
                    {
                        expanded === 'article-body-panel' && (
                            <AccordionDetails>
                                <Box>
                                    <IgniteWysiwygEditor
                                    theme="snow"
                                    rootClass="denovo-editor"
                                    value={articleBodyEditorState}
                                    onChange={articleBodyEditorStateChange}
                                    placeholder={'Start writing...'}
                                    modules={ArticleBodyModule}
                                    formats={formats}
                                    toolbarOptions={toolbarOptions}
                                    allowUnsafeUrls={true}
                                    bounds="#toolbar"
                                    ref={bodyEditorRef}
                                    />
                                </Box>
                            </AccordionDetails>
                        )
                    }
                </StyledAccordion>
            </StyledAccordionContainer>
            
            <StyledAccordionContainer>
                <StyledAccordion
                    elevation={0}
                    onChange={handleChange('authors-panel')}
                    expanded={expanded === 'authors-panel' ? true : false}
                >
                    <AccordionSummary>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }} >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                                <SectionHeader>
                                    Authors*
                                </SectionHeader>
                                {
                                    expanded === 'authors-panel' ? (
                                        <StyledExpandLessIcon />
                                    ) : (
                                        <StyledExpandMoreIcon />
                                    )
                                }
                            </Box>

                            <OneLineText
                                variant="BODY_ARTICLE_RALEWAY"
                                expanded={expanded === "authors-panel"}
                                sx={{ marginTop: '4px' }}
                            >
                                {authors}
                            </OneLineText>
                        </Box>
                    </AccordionSummary>
                    {
                        expanded === "authors-panel" && (
                            <AccordionDetails
                                sx={{
                                    alignItems: 'flex-start',
                                }}
                            >
                                <StyledMultilineTextField
                                    multiline
                                    fullWidth
                                    minRows={15}
                                    error={errorsInAuthors}
                                    helperText={errorsInAuthors ? "An article needs atleast one author" : ""}
                                    variant="standard"
                                    onFocus={
                                        (event) =>
                                            event.currentTarget.setSelectionRange(
                                                event.currentTarget.value.length,
                                                event.currentTarget.value.length
                                            )
                                    }
                                    inputProps={{
                                        style: {
                                            ...theme.typography.BODY_ARTICLE_RALEWAY,
                                            fontWeight: 500,
                                        }
                                    }}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    value={authors}
                                    onChange={(event) => setAuthors(event.target.value)}
                                />

                            </AccordionDetails>
                        )
                    }
                </StyledAccordion>
            </StyledAccordionContainer>

            <StyledAccordionContainer>
                <StyledAccordion
                    elevation={0}
                    onChange={handleChange('abstract-panel')}
                    expanded={expanded === 'abstract-panel' ? true : false}
                >
                    <AccordionSummary>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }} >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                                <SectionHeader>
                                    Summary*
                                    <InlineBox sx={{float:'right',marginRight:'8px' }} >
                                        <Button variant="outlined" size='small' sx={{fontFamily: 'Raleway',fontWeight: '700',textTransform:'none'}} onClick={handleConvertSummay} >
                                        Auto-Summarize
                                        </Button>
                                    </InlineBox>

                                </SectionHeader>

                                {
                                    expanded === 'abstract-panel' ? (
                                        <StyledExpandLessIcon />
                                    ) : (
                                        <StyledExpandMoreIcon />
                                    )
                                }

                            </Box>
                        </Box>
                    </AccordionSummary>
                    {
                        expanded === 'abstract-panel' && (
                            <AccordionDetails>
                                <Box>
                                    <IgniteWysiwygEditor
                                        theme="snow"
                                        rootClass="denovo-editor"
                                        value={abstractEditorState}
                                        onChange={abstractEditorStateChange}
                                        placeholder={'Start writing...'}
                                        modules={ArticleAbstractModule}
                                        formats={formats}
                                        toolbarOptions={toolbarOptions}
                                        allowUnsafeUrls={true}
                                        bounds="#toolbar"
                                        ref={abstractEditorRef}
                                    />
                                </Box>
                            </AccordionDetails>
                        )
                    }
                </StyledAccordion>
            </StyledAccordionContainer>

            <StyledAccordionContainer>
                <StyledAccordion
                    elevation={0}
                    onChange={handleChange('cme-credits-panel')}
                    expanded={expanded === 'cme-credits-panel' ? true : false}
                >
                    <AccordionSummary>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                            <SectionHeader>
                                CME / CE Credits*
                            </SectionHeader>
                            {
                                expanded === 'cme-credits-panel' ? (
                                    <StyledExpandLessIcon />
                                ) : (
                                    <StyledExpandMoreIcon />
                                )
                            }
                        </Box>
                    </AccordionSummary>
                    {
                        expanded === 'cme-credits-panel' && (
                            <AccordionDetails
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    variant="BODY_COPY2_RALEWAY"
                                    color={JC_COLORS.JC_ICON}
                                >
                                    CME / CE credits for this article
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    value={cmeCredits}
                                    error={(cmeCredits > 10 || cmeCredits < 0)}
                                    helperText={(cmeCredits > 10 || cmeCredits < 0) ? "CME Credits should be in the range 0-10" : ""}
                                    onChange={
                                        (event) => {
                                            let val = event.target.value;
                                            let reg = /\d+\.\d+/
                                            setCmeCredits(val)
                                        }
                                    }
                                    sx={{ marginLeft: '9px' }}
                                    inputProps={{
                                        style: {
                                            fontSize: '18px',
                                            lineHeight: '36px',
                                            fontWeight: '600',
                                            color: JC_COLORS.JC_ICON,
                                        }
                                    }}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                    }}

                                />
                                {/* <NumberFormat
                                    value={cmeCredits}
                                    error={(cmeCredits > 10 || cmeCredits < 0)}
                                    helperText={(cmeCredits > 10 || cmeCredits < 0) ? "CME Credits should be in the range 0-10" : ""}
                                    //getInputRef={ref}
                                    onValueChange={(value) => setCmeCredits(value.floatValue)}
                                    allowNegative={false}
                                    //isAllowed={withValueLimit}
                                    fixedDecimalScale
                                    thousandSeparator
                                    isNumericString
                                    prefix=""
                                /> */}
                            </AccordionDetails>

                        )
                    }

                </StyledAccordion>
            </StyledAccordionContainer>

            <StyledAccordionContainer>
                <Box
                    sx={{
                        height: '0px',
                        border: '3px solid #FFFFFF',
                        [theme.breakpoints.up('md')]: '8px',
                        [theme.breakpoints.down('md')]: '0px',
                    }}
                ></Box>
            </StyledAccordionContainer>

            <StyledAccordionContainer>
                <StyledAccordion
                    elevation={0}
                    onChange={handleChange('comment-note-panel')}
                    sx={{
                        '& .MuiAccordionDetails-root': { padding: '0px!important' },
                        '& .MuiPaper-root': { marginBottom: '0px!important', maxWidth: '100%' },
                    }}
                >
                    <AccordionSummary>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }} >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                                <SectionHeader>
                                    Notes/Comments
                                </SectionHeader>

                                {
                                    expanded === 'comment-note-panel' ? (
                                        <StyledExpandLessIcon />
                                    ) : (
                                        <StyledExpandMoreIcon />
                                    )
                                }

                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ marginTop: "-16px", maxWidth: 'calc(100vw - 30px)' }}>
                        {
                            expanded === 'comment-note-panel' && (
                                <CommentNotes />
                            )
                        }

                    </AccordionDetails>
                </StyledAccordion>
            </StyledAccordionContainer>

        { summaryOpen &&
        <AutoSummary open={summaryOpen} onClose={() => setSummaryOpen(false)} addToEditor={addTextToSummary} articleBodyState={articleBodyEditorState} />
        }
        { writeBodyOpen &&
        <AutoSummary open={writeBodyOpen} onClose={() => setWriteBodyOpen(false)} isArticleBody={true} addToEditor={addTextToArticleBody} />
        }
        <Backdrop open={denovoArticleData.mediaUploading} sx={{displa:'flex',flexDirection:'column',zIndex:'99999999!important'}}>
          <CircularProgress sx={{color:'#FFFFFF'}}/>
          <Box mt={2}>
            <Typography variant="H3_RALEWAY" sx={{color:'#FFFFFF'}} >Posting Article...</Typography>
          </Box>
        </Backdrop>
        </React.Fragment>
    )
}

export default ArticleDenovoView

const StyledExpandMoreIcon = () => {
    return (
        <ExpandMoreIcon
            style={{
                backgroundColor: JC_COLORS.JC_ICON,
                color: JC_COLORS.JC_WHITE,
                borderRadius: '50%',
            }}

        />
    )
}


const StyledExpandLessIcon = () => {
    return (
        <ExpandLessIcon
            style={{
                backgroundColor: JC_COLORS.JC_ICON,
                color: JC_COLORS.JC_WHITE,
                borderRadius: '50%',
            }}

        />
    )
}

function checkErrorsInTitle(title) {
    return title.length === 0;
}

function checkErrorsInAuthors(authors) {
    return authors.length === 0;
}

