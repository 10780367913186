import React, { useEffect,useState,useContext } from 'react';
import { Card, Box, ListItem,IconButton,CardActions,CardContent, Divider, Icon, Typography, Link, Button, Drawer, useTheme, CircularProgress } from '@mui/material';
import { JCDialogLarge, JCDialogLargeTitle } from './JCDialogBox';
import DialogContent from '@mui/material/DialogContent';
import { AuthContext } from '../../context/AuthContext';
import { CopyIcon } from '../../assets';
import { postDenovoSummary } from '../../api'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { JC_COLORS } from '../../styles/constants';
import { InlineBox,BreadCrumbs } from '../../pages/JCDetails/jc-content/report-content/ReportHelpers'
import JCSnackbar from './JCSnackbar';
import { CovertEditorToText } from '../../utils/JCWysiwygEditor';
import { v4 } from "uuid";

const WA_NAV = {
    COMMING_SOON: 'comming soon',
    REWRITE : 'rewrite',
    TEXT_RESULT : 'text result',
    PDF_RESULT: 'pdf result'
}

function AutoSummary({open,onClose,addToEditor,isArticleBody=false,articleBodyState=null}) {

  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const [WANav,setWANav] = useState(WA_NAV.REWRITE)
  const [uploadFiles,setUploadFiles] = useState([])
  const [toastOpen,setToastOpen] = useState(false)
  const [articleSummary,setArticleSummmary] = useState('')
  const [toastMessage,setToastMessgae] = useState('')
  const [fileUploadError,setFileUploadError] = useState('')
  const [isLoading,setLoading] = useState(false)

  const handleRewrite = () =>{
      setWANav(WA_NAV.TEXT_RESULT)
  }
  const handleOnClose = () => {
      setWANav(WA_NAV.REWRITE)
      setFileUploadError('')
      onClose()
  }

  async function handleGenerateSummary(event){
    event.preventDefault();
    setWANav(WA_NAV.TEXT_RESULT);
  }
  

  const getSummaryFromBody = () => {
    const inputText = CovertEditorToText(articleBodyState)
    setLoading(true)
    postDenovoSummary(userEmail,'text',{'uploadFile' : inputText}).then(res => {
        if(res.data.message){
            setArticleSummmary(res.data.message)        
        }else {
          setFileUploadError(true)
        }
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        setFileUploadError(true)
      })
  }

  useEffect(()=> {
      if(!isArticleBody){
          getSummaryFromBody()
      }
  },[])

  const handleFileUpload = (event) => {
    event.preventDefault();
    const tempList = uploadFiles
    const newFiles = Array.from(event.target.files);
    const existingFilenames = uploadFiles.map((file) => file.name);
    const newFilenames = newFiles.map((file) => file.name);
    const invalidFiles = newFiles.filter((file) => file.type !== "application/pdf");
    const invalidFileNames = invalidFiles.map((file) => file.name);
    let ErrorMessage = ''
    if (invalidFiles.length > 0) {
        ErrorMessage = "Only .pdf files allowed!"
    } 

    const duplicates = newFilenames.filter((filename) =>
      existingFilenames.includes(filename)
    );
    if(duplicates.length > 0){
        ErrorMessage = ErrorMessage + ` Duplicate files: ${duplicates.join(", ")}`
    }
    if(ErrorMessage){
        setFileUploadError(ErrorMessage)
    }
    else{
        setFileUploadError('')
    }
    const uniqueNewFiles = newFiles.filter(
      (file) => !duplicates.includes(file.name) && !invalidFileNames.includes(file.name)
    );

    setUploadFiles((prevFiles) => [...prevFiles, ...uniqueNewFiles]);
    
  }
    
  const handleRemoveFile = (fileName) =>{
    const tempList = uploadFiles.filter(file => file.name !== fileName)
    setUploadFiles(tempList)
  }


  return (
    <JCDialogLarge
        open={open}
        onClose={handleOnClose}
        aria-labelledby="auto-summary-title"
    >
        <JCDialogLargeTitle sx={{background: '#EF5B50', color: '#fff'}} id="auto-summary-title" onClose={handleOnClose} >
        <Typography variant="H3_RALEWAY" sx={{fontWeight:600, color:'#ffffff'}}>
            {isArticleBody ? "Summarize PDFs": "Auto-Summarize" }
        </Typography>
        </JCDialogLargeTitle>

        <DialogContent sx={{backgroundColor:'#E5E5E5',height:'100%'}} >
        <Box p={2}>
            {WANav === WA_NAV.REWRITE &&
            <Box>
                <Typography variant="BODY_COPY2_RALEWAY" sx={{fontStyle:'italic'}}>
                By clicking on the Auto Summarize button or generating responses by uploading a PDF,
                you agree that it is your responsibility to ensure that the automated content is accurate and appropriate.
                Journal Club, Inc. does not bear any responsibility for the content you publish on this platform.
                Please review our <a href="https://www.journal.club/Terms_of_Service.pdf" target="_blank" style={{color: "#000"}}>Terms of Service.</a>
                </Typography>
                { isArticleBody &&
                <Box mt={5} sx={{textAlign:'left'}}>
                    <Box sx={{minHeight: '40px'}}>
                    {uploadFiles && uploadFiles.map((file)=>
                        <InlineBox sx={{backgroundColor: "#D9D9D9"}} mr={1}mt={1} key={file.name} >
                            <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_BLACK} ml={1} sx={{fontFamily:'Raleway',fontWeight:'600'}}>{file.name}</Typography>
                            <IconButton aria-label="remove" size="medium" onClick={() => handleRemoveFile(file.name)}>
                                <HighlightOffIcon sx={{color: JC_COLORS.JC_BLACK}} />
                            </IconButton>
                        </InlineBox>
                        )
                    }
                    </Box>

                    <Box mt={3}>
                        <Button
                            variant="text"
                            startIcon={<AddCircleIcon size="large"/>}
                            component="label"
                            size="large" sx={{textTransform:'none',backgroundColor:'transperant'}}
                        >
                            <Typography variant="H3_RALEWAY" color={JC_COLORS.JC_ICON}>Add PDFs</Typography>
                            <input
                                type="file"
                                accept="application/pdf"
                                multiple
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                            />
                        </Button>
            
                        {fileUploadError && (
                            <Box mt={1}>
                            <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_RED}> { fileUploadError } </Typography> 
                            </Box> 
                            )
                        }
                    </Box>
                    <Box sx={{textAlign:'center'}} mt={2}>
                        <Button variant="contained" size="small" sx={{fontFamily: 'Raleway',fontWeight: '700',textTransform:'none'}} disabled={uploadFiles.length === 0} onClick={handleGenerateSummary}>
                            Generate Summary
                        </Button>
                    </Box>
                </Box>
                }
                {!isArticleBody && 
                <Box mt={3}>
                    { isLoading && <Box textAlign="center"><CircularProgress color="primary" /></Box>}
                    {articleSummary &&
                    <Card>
                        <CardContent>
                            <Typography variant="BODY_COPY2_RALEWAY" color="text.secondary" gutterBottom>
                            { articleSummary }
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions sx={{ justifyContent: 'space-between'}}>
                        <Button size="small" sx={{fontFamily:'Raleway',textDecoration:'underline',color:'#262626'}} 
                            onClick={() => {
                                setToastMessgae("Applied To Article Summary!")
                                addToEditor(articleSummary)
                                handleOnClose()
                                setToastOpen(true)
                            }}
                        >
                          APPLY TO SUMMARY
                        </Button>
                        <IconButton aria-label="copy" size="medium" 
                            onClick={() => {
                                setToastMessgae("Text Copied!")
                                navigator.clipboard.writeText(articleSummary)
                                setToastOpen(true)
                            }}>
                            <CopyIcon fontSize="inherit" />
                        </IconButton>
                        </CardActions>
                    </Card>
                    }
                    {(fileUploadError) && (
                        <DisplayError message={"Failed to generate summary or article body is empty!"} />
                        )
                    }
                </Box>
                }
            </Box>
            }
            {WANav === WA_NAV.TEXT_RESULT &&
            <Box>
                <Box>
                    <BreadCrumbs label={"Back"} onClick={() => {
                        setWANav(WA_NAV.REWRITE)
                        }} 
                    />
                </Box>
                { isLoading && <Box textAlign="center"><CircularProgress color="primary" /></Box>}
                {uploadFiles && uploadFiles.map(file => 
                    <DisplayCard 
                    userEmail = {userEmail}
                    summaryFile = {file}
                    onAppend = {(summary) => {
                        setToastMessgae("Summary Added To Article!")
                        addToEditor(summary)
                        setToastOpen(true)
                    }}
                    onCopy = {(summary) => {
                        setToastMessgae("Text Copied!")
                        navigator.clipboard.writeText(summary)
                        setToastOpen(true)
                    }}
                    onRemove = {() => {
                        setToastMessgae("Removed!")
                        setToastOpen(true)
                    }} 
                    key = {file.name}
                    />
                )}
            </Box>
            }
            
        </Box>
        </DialogContent>
        <JCSnackbar open={toastOpen} onClose={() => setToastOpen(false)} message={toastMessage} />
    </JCDialogLarge>
  )
}
export default AutoSummary

const DisplayError = ({message}) => {
    return (
        <Box mt={2}>
            <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_RED}> { message } </Typography> 
        </Box> 
    )
}

const DisplayCard = ({userEmail,summaryFile,onAppend,onCopy,onRemove}) => {
    const [cardLoading,setCardLoading] = useState(false)
    const [isRemoved,setRemoved] = useState(false)
    const [summary,setSummary] = useState('')
    const [summaryAdded,setSummaryAdded] = useState(false)
    const [summaryError,setSummaryError] = useState('')
    
    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    }

    const getSummary = (file) => { 
        setCardLoading(true)
        getBase64(file).then(data => {
            postDenovoSummary(userEmail,"pdf",{'uploadFile' : data}).then(res => {
              console.log(res,"Complete Response")
              if(res?.data?.status === 200 && res?.data?.message){
                setSummary(res.data.message)
              }else {
                setSummaryError(res?.data?.message)
              }
              setCardLoading(false)
            }).catch(error => {
                console.log(error,":: This is error")
                setCardLoading(false)
                setSummaryError("Try splitting the PDF and re-summarizing")
            })
        })
    }

    useEffect(()=>{
        getSummary(summaryFile)
    },[])

    return (
    <Box mt={2} mb={{xs:2,md:3}} sx={{display : isRemoved ? 'None' : 'block'}}>
        <Box mb={1}>
            <Divider />
        </Box>
        <InlineBox sx={{minHeight:'32px'}}>
            <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_BLACK} sx={{fontWeight:'600'}}>{summaryFile.name}</Typography>
            {!summaryAdded && (
                <IconButton aria-label="remove" size="medium" onClick={() => {
                    setRemoved(true)
                    onRemove()
                }}>
                    <HighlightOffIcon sx={{color: JC_COLORS.JC_BLACK}} />
                </IconButton>
            )}
        </InlineBox>
        <Card mt={1}>
            <CardContent>
                {summaryError && <DisplayError message={summaryError} /> }
                { cardLoading && <Box textAlign="center" mt={1} mb={1} ><CircularProgress color="primary" /></Box>}
                { !summaryError && !cardLoading && !summaryAdded &&
                <Typography variant="BODY_COPY2_RALEWAY" color="text.secondary">
                    {summary}
                </Typography>
                }
                { summaryAdded && 
                    <Typography variant="BODY_COPY2_RALEWAY" color="#4caf50" sx={{fontWeight:'700'}}>Summary added to article</Typography>
                }
            </CardContent>
            { !summaryAdded && 
            <React.Fragment>
                <Divider />
                <CardActions sx={{ justifyContent: 'space-between'}}>
                {!summaryAdded &&
                <Button size="small" sx={{fontFamily:'Raleway',textDecoration:'underline',color:'#262626'}} 
                    onClick={() => {
                        setSummaryAdded(true)
                        onAppend(summary)
                    }}
                    disabled={!summary}
                >
                    APPEND TO ARTICLE BODY
                </Button>
                }
                <IconButton aria-label="copy" size="medium" onClick={() => onCopy(summary)} disabled={!summary}>
                    <CopyIcon fontSize="inherit" />
                </IconButton>
                </CardActions>
            </React.Fragment>
            }
        </Card>
    </Box> 
    )
}