import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import {
    InputLabel,
    TextField,
    Avatar,
    FormControl,
    Select,
    Box,
    Typography,
    MenuItem,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Link,
    Checkbox,
    styled,
    useTheme,
    Hidden,
    useMediaQuery
} from '@mui/material';
import { JC_COLORS } from '../../../../styles/constants';
import CloseIcon from '@mui/icons-material/Close';
import samplePDF from "./sample.pdf";
import { Navigate, useNavigate } from 'react-router';
import { acceptCmeDisclosure } from '../../../../api';
import ErrorDialogBox from '../../../../common/components/ErrorDialogBox';
import { useDispatch } from 'react-redux';
import { getJournalClubDetails, setJCContent } from '../../../../state/actions/journalclub';
import { JC_CONTENT } from '../../../../constants/constants';



const CheckBoxText = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H2_RALEWAY,
        marginLeft: "12px",
        fontWeight: 700,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '18px',
        marginLeft: '16px',

    }
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: '26px',
        height: '26px',
    },
    [theme.breakpoints.down('md')]: {
        width: '18px',
        height: '18px',
    }
}));

// pdfjs.GlobalWorkerOptions.workerSrc = 
//     `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFDocument = ({ url }) => {
    const theme = useTheme();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    const memoisedFileObj = useMemo(() => {
        return { url: url }
    }, [url])

    const isMobileMatch = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Document
            renderMode="svg"
            file={memoisedFileObj}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
        // options={{ workerSrc: "/pdf.worker.js" }}
        >
            <center>
                {
                    Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={isMobileMatch ? 350 : 800}

                            />
                        )
                    )
                }
            </center>
        </Document>
    )
}


const DialogContentCMEDisclosure = ({ JCID, urlSlug, userEmail, cmeDisclosurePdfUrl, acceptedCMEDisclosure, closeIconHandler }) => {

    const theme = useTheme();
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(acceptedCMEDisclosure);

    const dispatch = useDispatch();

    const [pdfUrl, setPdfUrl] = useState("");

    useEffect(() => {
        setPdfUrl(cmeDisclosurePdfUrl);
    }, [])


    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("Error");


    const toggleChecked = () => {
        setIsChecked(!isChecked);
    }

    const handleAccept = async () => {
        try {
            let res = await acceptCmeDisclosure(JCID, userEmail);
            console.log(res.data);
            dispatch(
                getJournalClubDetails(
                    urlSlug,
                    userEmail,
                    false,
                )
            );
            closeIconHandler();


        } catch (error) {
            console.log(error.response);
            setOpenErrorDialog(true);
            setErrorMessage(error?.response?.data?.message);
            setErrorStatusCode(error?.response?.status);
        }

    }

    const handleReject = () => {
        closeIconHandler();
        setIsChecked(false);
        navigate("/feed");
    }


    return (
        <>
            <ErrorDialogBox
                open={openErrorDialog}
                closeDialogHandler={() => setOpenErrorDialog(false)}
                errorMessage={errorMessage}
                title={`Error ${errorStatusCode}`}
            >
                <Typography>{errorMessage}</Typography>
            </ErrorDialogBox>
            <DialogTitle
                sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    backgroundColor: JC_COLORS.JC_RED,
                    color: JC_COLORS.JC_WHITE,
                    ...theme.typography.H3_BITTER,
                    fontWeight: 700,

                }}
            >
                Disclosure Statement
                {
                    acceptedCMEDisclosure && (
                        <CloseIcon
                            sx={{
                                width: '24px',
                                height: '24px',
                                color: JC_COLORS.JC_WHITE,
                                //display: `${cmeDisclosurePdfUrl === null? "none": "block"}`
                            }}
                            onClick={closeIconHandler}
                        />

                    )
                }


            </DialogTitle>
            <DialogContent>


                <Box minHeight={"600px"}>
                    {
                        pdfUrl ? (
                            <PDFDocument url={pdfUrl} />
                        ) : (
                            <Typography
                                variant="H3_RALEWAY"
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "30%",
                                    margin: "0 auto",
                                }}
                            >
                                CME Disclousure is not available for this journal club.
                            </Typography>
                        )
                    }

                </Box>
            </DialogContent>
            {
                acceptedCMEDisclosure === false && (
                    <DialogActions
                        sx={{
                            backgroundColor: JC_COLORS.LIGHT_GREY,
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}

                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justiftContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <StyledCheckbox
                                checked={isChecked}
                                onChange={toggleChecked}
                            //disabled={Boolean(userDetails?.acceptedTermsAndConditions)}
                            />
                            <CheckBoxText>
                                I understand and agree to these terms.
                            </CheckBoxText>
                        </Box>

                        {/* <Hidden mdDonw>

                        </Hidden>
                        <Hidden mdUp>

                        </Hidden> */}

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'row-reverse', md: 'row' },
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    margin: { xs: '12px 0px 0px 12px', md: '6px 32px 0px 0px' },
                                    //minWidth: "200px", 
                                    width: 'fit-content'
                                }}

                                disabled={(!isChecked || cmeDisclosurePdfUrl === null)}
                                onClick={handleAccept}
                            >
                                Accept
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    margin: { xs: '12px 0px 0px 0px', md: '6px 0px 0px 0px' },
                                    //minWidth: "200px", 
                                    width: 'fit-content'
                                }}

                                onClick={handleReject}
                            >
                                Don't Accept
                            </Button>
                        </Box>
                    </DialogActions>

                )
            }

        </>
    );
}

export default DialogContentCMEDisclosure;