import React, { useState, useEffect, useContext } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Card, Paper, Hidden, Box, Typography, InputAdornment, TextField, Button, Tabs, Tab, tabsClasses, styled } from '@mui/material';
import { KeyWordChip, Row, Spacer } from '../../common/components';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import JCContentTitle from '../JCDetails/components/JCContentTitle';
import SearchBox from "../EditKeywordsOrSources/components/SearchBox";
import { KeyWordsList } from '../../common/containers';
import { JC_COLORS } from '../../styles/constants';
import * as api from "../../api";
import { SearchOutlined } from '@mui/icons-material';
import { logout, profileCheck } from '../../utils';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import SearchArticlesList from './containers/SearchArticlesList';
import useLocalStorage from '../../common/hooks/useLocalStorage';
import { getUserDetails } from '../../state/actions/user';
import UserProfileCheck from '../UserProfileCheck';
import { local_storage_keys } from '../../constants/constants';



const MainCard = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: JC_COLORS.JC_WHITE,
  [theme.breakpoints.up('md')]: {
    maxWidth: '70vw',
    padding: "40px 40px 54px 40px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    padding: '80px 0rem 0rem 0rem',
    margin: 0,
  }

}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: '3px',
  background: 'white',
  border: `1px solid ${JC_COLORS.JC_ICON}`,
  '& .MuiOutlinedInput-input': {
    fontFamily: 'Raleway',
    fontWeight: '600',
  },
  [theme.breakpoints.up('md')]: {
    width: '23vw',
    '& .MuiInputBase-root': {
      height: '42px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    }
  },
  [theme.breakpoints.down('md')]: {
    width: '75vw',
    '& .MuiInputBase-root': {
      height: '34px',
      padding: '0px 5px',
    },
  },

}));

const TabsList = styled(Tabs)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [`& .${tabsClasses.scrollButtons}`]: {
    '&.Mui-disabled': { opacity: 0.3 },

  },
  '& .MuiTabs-indicator': { backgroundColor: "#FFFFFF" },
  [theme.breakpoints.up('md')]: {
    width: "80%",
    "*": {
      marginRight: "5px",
      marginBottom: "3px",
    }
  },
  [theme.breakpoints.down('md')]: {
    width: "100%",
    "*": {
      marginRight: "5px",
    }
  },
}));

const StyledAddButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '8px 22px',
    width: '77px',
    height: '42px',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: JC_COLORS.JC_RED,
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '26px',
    '&:hover': {
      backgroundColor: 'white',
      color: JC_COLORS.JC_RED,
    }

  },
  [theme.breakpoints.down('md')]: {
    textTransform: 'none',
    padding: '4px 10px',
    width: '45px',
    height: '35px',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: JC_COLORS.JC_RED,
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20px',

  }
}));

const FlexWrappedList = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  border: `1px solid ${JC_COLORS.JC_ICON}`,
  borderRadius: "4px",
  [theme.breakpoints.up('md')]: {
    width: "90%",
    minHeight: "100px",
    padding: '20px',
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
    marginTop: '18px',
    minHeight: "31px",
    padding: "10px"
  }
}));

const StyledSearchButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '8px 22px',
    width: '100px',
    borderRadius: '4px',
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '26px',

  },
  [theme.breakpoints.down('md')]: {
    textTransform: 'none',
    padding: '4px 10px',
    width: '62px',
    borderRadius: '4px',
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '22px',

  }
}));

const queryClient = new QueryClient();


const SearchArticlesPage = () => {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const location = useLocation();
  const navigate = useNavigate();
  const page = location.pathname.replace(/\//, '');
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();


  const [searchParams, setSearchParams] = useSearchParams();

  const [value, setValue] = React.useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [listOfSearchTerms, setListOfSearchTerms] = useLocalStorage(local_storage_keys.LIST_OF_SEARCH_TERMS, []);
  const [searchFields, setSearchFields] = useState({
    title: true,
    author: false,
    pmid: false,
    keyword: false,
  });

  const [apiQueryParamString, setApiQueryParamString] = useState("");


  const addSearchTerm = (term) => {
    if (listOfSearchTerms.filter(item => item.text.toLowerCase() === term.toLowerCase()).length > 0) {
      return
    }
    const searchFieldTitle = getSelectedSearchFieldTitle(searchFields);
    setListOfSearchTerms([...listOfSearchTerms, { isSelected: true, text: term, ...searchFieldTitle }]);
  }

  const clickSearchTerm = (term) => {
    let updatedList = listOfSearchTerms.map(item => item.text === term.text ? { ...item, isSelected: !item.isSelected } : { ...item });
    setListOfSearchTerms(updatedList);
  }

  const removeSearchTerm = (obj) => {
    setListOfSearchTerms(listOfSearchTerms.filter(term => term.text !== obj.text));
  }

  const clickSearchField = (field) => {
    setSearchFields({
      title: false,
      author: false,
      pmid: false,
      keyword: false,
      [field]: !searchFields[field]
    });
  }

  function handleSearch() {
    setApiQueryParamString(generateApiQueryString(listOfSearchTerms));
  }



  useEffect(() => {
    dispatch(getUserDetails(userEmail))
    let title = location.state?.title ?? ""
    if (page === 'search' && title.length > 0) {
      addSearchTerm(title);
      navigate(location.pathname, { replace: true });
      setApiQueryParamString(`terms=${title}${encodeURIComponent(`[Title/Abstract]`)}&operator=AND`);

    } else {
      handleSearch()
    }
  }, [page]);



  useEffect(() => {
    // atleast one search-field to be always selected. Default: title
    let fieldValues = Object.values(searchFields).filter(val => val === true);
    if (fieldValues.length === 0) {
      setSearchFields({
        title: true,
        author: false,
        pmid: false,
        keyword: false,
      })
    }

  }, [searchFields])

  return (
    <UserProfileCheck>
      <>
        <Hidden mdDown>
          <MainCard>
            <JCContentTitle title={page === 'advanced-search' ? 'Advanced Search' : 'Search'} sx={{ marginBottom: "44px" }}></JCContentTitle>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '36px' }}>
              <Typography>Search In</Typography>
              <KeyWordChip
                label={"Title / Abstract"}
                showCloseIcon={false}
                selected={searchFields["title"]}
                onClick={() => clickSearchField("title")}
              />
              <KeyWordChip
                label={"Authors"}
                showCloseIcon={false}
                selected={searchFields["author"]}
                onClick={() => clickSearchField("author")}
              />
              <KeyWordChip
                label={"PMID"}
                showCloseIcon={false}
                selected={searchFields["pmid"]}
                onClick={() => clickSearchField("pmid")}
              />
              <KeyWordChip
                label={"Keywords"}
                showCloseIcon={false}
                selected={searchFields["keyword"]}
                onClick={() => clickSearchField("keyword")}
              />
            </Box>

            <Box sx={{ marginBottom: '26px' }}>
              <StyledTextField
                value={value}
                placeholder={"Search PubMed"}
                autoComplete='off'
                InputProps={{
                  startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                  endAdornment: (<InputAdornment position="end" sx={{ cursor: 'pointer' }}><CloseIcon onClick={() => setSearchTerm("")} /></InputAdornment>)
                }}
                onChange={
                  (e) => {
                    let val = e.target.value;
                    setValue(val ? val : "");
                    setSearchTerm(val ? val : "");
                  }
                }
              ></StyledTextField>
              <StyledAddButton
                variant="outlined"
                sx={{ marginLeft: '11px' }}
                onClick={() => {
                  if (searchTerm.length > 0 && searchTerm.trim().length > 0) {
                    addSearchTerm(searchTerm)
                    setValue("");
                    setSearchTerm("");
                  }
                }
                }
              >
                Add
              </StyledAddButton>
              <StyledSearchButton
                variant="contained"
                onClick={() => handleSearch()}
                sx={{ marginLeft: '11px' }}
              >
                Search
              </StyledSearchButton>
            </Box>

            <Row sx={{ alignItems: 'flex-end', justifyContent: 'flex-start', width: '100%' }}>
              <FlexWrappedList>
                {
                  listOfSearchTerms.map(obj => (
                    <KeyWordChip
                      key={obj.text}
                      label={obj.text + ' ' + obj.fieldTitle}
                      showCloseIcon={true}
                      selected={obj.isSelected}
                      onClick={() => clickSearchTerm(obj)}
                      closeButtonHandler={() => removeSearchTerm(obj)}
                    />)
                  )
                }

              </FlexWrappedList>

            </Row>




            <Spacer height={"61px"} />
            <QueryClientProvider client={queryClient}>
              {
                apiQueryParamString.length > 0 && (
                  <SearchArticlesList
                    query={apiQueryParamString}
                    listOfSearchTerms={listOfSearchTerms}
                  />
                )
              }

            </QueryClientProvider>





          </MainCard>
        </Hidden>
        <Hidden mdUp>
          <MainCard>

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ width: "100px", marginLeft: "10px", marginBottom: "8px", fontFamily: "Raleway", fontWeight: 700, fontSize: "15px", lineHeight: "18px" }}>Search In: </Typography>

              <TabsList
                value={Object.values(searchFields).indexOf(true)}
                variant="scrollable"
                scrollButtons={true}
              >

                <KeyWordChip
                  label={"Title / Abstract"}
                  showCloseIcon={false}
                  //selected={searchFields["titleAbstract"]}
                  onClick={() => clickSearchField("title")}
                />
                <KeyWordChip
                  label={"Authors"}
                  showCloseIcon={false}
                  //selected={searchFields["author"]}
                  onClick={() => clickSearchField("author")}
                />
                <KeyWordChip
                  label={"PMID"}
                  showCloseIcon={false}
                  //selected={searchFields["pmid"]}
                  onClick={() => clickSearchField("pmid")}
                />
                <KeyWordChip
                  label={"Keywords"}
                  showCloseIcon={false}
                  //selected={searchFields["keyword"]}
                  onClick={() => clickSearchField("keyword")}
                />

              </TabsList>
            </Box>
            <Box sx={{ marginBottom: '17px' }}>
              <StyledTextField
                value={value}
                placeholder={"Search PubMed"}
                autoComplete='off'
                InputProps={{
                  startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                  endAdornment: (<InputAdornment position="end"><CloseIcon onClick={() => setSearchTerm("")} /></InputAdornment>)
                }}
                onChange={
                  (e) => {
                    let val = e.target.value;
                    setValue(val ? val : "");
                    setSearchTerm(val ? val : "");
                  }
                }
              ></StyledTextField>
              <StyledAddButton
                variant="outlined"
                sx={{ marginLeft: '4px' }}
                onClick={() => {
                  if (searchTerm.length > 0 && searchTerm.trim().length > 0) {
                    addSearchTerm(searchTerm);
                    setSearchTerm("");
                    setValue("");
                  }

                }
                }
              >
                Add
              </StyledAddButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <FlexWrappedList>
                {
                  listOfSearchTerms.map(obj => (
                    <KeyWordChip
                      key={obj.text}
                      label={obj.text + ' ' + obj.fieldTitle}
                      showCloseIcon={true}
                      selected={obj.isSelected}
                      onClick={() => clickSearchTerm(obj)}
                      closeButtonHandler={() => removeSearchTerm(obj)}
                    />)
                  )
                }

              </FlexWrappedList>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '14px', marginRight: '10px' }}>
              <StyledSearchButton
                variant="contained"
                onClick={() => handleSearch()}
                sx={{ marginLeft: '20px' }}
              >
                Search
              </StyledSearchButton>
            </Box>

            <Box sx={{ height: "29px", borderBottom: "1px solid #838383", margin: '0px 10px' }}></Box>
            <QueryClientProvider client={queryClient}>
              {
                apiQueryParamString.length > 0 && (
                  <SearchArticlesList
                    query={apiQueryParamString}
                    listOfSearchTerms={listOfSearchTerms}
                  />
                )
              }

            </QueryClientProvider>




          </MainCard>
        </Hidden>
      </>

    </UserProfileCheck>

  );



}

export default SearchArticlesPage

function getSelectedOption(obj) {
  for (let key in obj) {
    if (obj[key] === true) {
      return key
    }
  }
}

function getSelectedSearchFieldTitle(obj) {
  let key = Object.keys(obj).filter(key => obj[key])[0];
  if (key === 'title') {
    return { fieldTitle: '[Title/Abstract]', fieldKey: key }
  } else if (key === 'pmid') {
    return { fieldTitle: '[PMID]', fieldKey: key }
  } else if (key === 'keyword') {
    return { fieldTitle: '[MeSH Terms]', fieldKey: key }
  } else if (key === 'author') {
    return { fieldTitle: '[Author]', fieldKey: key }
  }
}

const highlightText = (text, listOfStrings) => {

  const regex = new RegExp(listOfStrings.join('|'), 'gi');
  const splitText = text.split(regex);

  if (splitText.length <= 1) {
    return text;
  }
  const matches = [...text.matchAll(regex)];

  return splitText.reduce((arr, element, index) => (matches[index] ? [
    ...arr,
    element,
    <span style={{ color: JC_COLORS.JC_RED }}>
      {matches[index]}
    </span>,
  ] : [...arr, element]), []);
}

const generateApiQueryString = (listOfSearchTerms) => {
  if (listOfSearchTerms.length === 0) {
    return ""
  } else {

    let selectedTerms = listOfSearchTerms.filter(obj => obj.isSelected)

    let queryString = "";
    if (selectedTerms.length === 0) {
      queryString = listOfSearchTerms.map(obj => 'terms=' + encodeURIComponent(obj.text) + encodeURIComponent(obj.fieldTitle)).join('&');
      queryString += '&operator=OR';
      return queryString;
    } else {
      queryString = selectedTerms.map(obj => 'terms=' + encodeURIComponent(obj.text) + encodeURIComponent(obj.fieldTitle)).join('&');
      queryString += '&operator=AND'
      return queryString;
    }

  }
}
