import React from 'react';
import { Avatar } from '@mui/material';
import { styled } from '@mui/system';
import { JC_COLORS } from '../../../styles/constants';

const ResponsiveAvatar = styled(Avatar)(({ theme }) => ({
    //backgroundColor: JC_COLORS.LIGHT_GREY, 
    //color: JC_COLORS.JC_BLACK, 
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
        width: '45px',
        height: '45px',
        fontSize: '30px',
        lineHeight: '36px',

    },
    [theme.breakpoints.down('md')]: {
        width: '35px',
        height: '35px',
        fontSize: '24px',
        lineHeight: '36px',
    }
}));

const SquareAvatar = ({ name = "", logoUrl = "", sx, imgProps }) => {
    return (
        <ResponsiveAvatar
            variant="square"
            alt={name}
            src={logoUrl}
            imgProps={{ ...imgProps }}
            sx={{ ...sx }}
        >
            {name.length > 0 ? name[0] : ''}
        </ResponsiveAvatar>
    )
}

export default SquareAvatar