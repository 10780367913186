import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
//import jwt from 'jsonwebtoken'; 
import jwt_decode from "jwt-decode";
import queryString from 'query-string';
import { useAuthContext } from '../context/AuthContext';
import PropTypes from 'prop-types';
import { token_name_list } from '../constants/constants';
import { setToken } from '../utils/authToken';
import * as api from "../api";
import { useNavigate, Navigate } from 'react-router-dom';
import { logout } from '../utils';

import { getUserDetails, fetchingUserDetails, setIsNewUser, fetchUserDetailsSuccess, setUserDetails } from "../state/actions/user";
import { getJournalClubs } from '../state/actions/journalclub';
import { useErrorHandler } from "react-error-boundary";
import { generateAWSCognitoIdentity } from '../utils/awsCognitoUtils';


// import { setUserAuthData } from '../state/actions/user';
let appState = JSON.parse(localStorage.getItem('appState'));

const SigninCallback = ({ history, location }) => {
  const locationSearch = window.location.search;
  const userContext = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const handleError = useErrorHandler();


  const authUserNavigator = async (userEmail) => {
    try {

      dispatch(fetchingUserDetails());
      const responseUserDetails = await api.fetchUserDetails(userEmail);
      console.log("users/useremail response", responseUserDetails);
      if (responseUserDetails) {
        const { data } = responseUserDetails;
        if (appState) {
          // RELOGIN THE USER with old state
          console.log("RELOGIN THE USER with old state")
          dispatch(setUserDetails(data, appState));

        } else {
          console.log("USER LOGIN on this browser for first time")
          dispatch(setUserDetails(data))
        }
        navigate("/")
      } else {
        handleError(`${responseUserDetails.status} Error. ${JSON.stringify(responseUserDetails)}`)
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        // NEW USER Registration
        dispatch(setIsNewUser(true));
        dispatch(fetchUserDetailsSuccess({}));
        navigate("/terms-and-conditions");
      } else {
        handleError(`${error?.response?.status} Error. ${JSON.stringify(error?.response)}`)
      }

    }

  }

  useEffect(() => {

    const init = async () => {
      console.log("INIT")
      try {
        const values = queryString.parse(locationSearch);
        if (values && values.code) {
          const params = new URLSearchParams();
          params.append('grant_type', 'authorization_code');
          params.append('code', values.code);
          params.append('client_id', process.env.REACT_APP_AWS_COGNITO_CLIENT_ID);
          params.append('redirect_uri', process.env.REACT_APP_AWS_REDIRECT_URI);

          const res = await axios.post(`${process.env.REACT_APP_AWS_DOMAIN}oauth2/token`, params, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          });
          if (res && res.data) {
            const userData = jwt_decode(res.data.id_token);
            setToken(token_name_list.ACCESS_TOKEN, res.data.access_token);
            setToken(token_name_list.REFRESH_TOKEN, res.data.refresh_token);
            setToken(token_name_list.ID_TOKEN, res.data.id_token);
            generateAWSCognitoIdentity(true).then(_res => {
              userContext.setAuthenticated(true);
              userContext.setUser(userData);

              api.fetchUserDetails(userData.email).then(responseUserDetails => {
              if (responseUserDetails?.status === 200) {
                const { data } = responseUserDetails;               
                dispatch(setUserDetails(data))                
                navigate("/")
              } else {
                handleError(`${responseUserDetails.status} Error. ${JSON.stringify(responseUserDetails)}`)
              }
            }).catch(error => {
              if (error?.response?.status === 404) {
                // NEW USER Registration
                dispatch(setIsNewUser(true));
                dispatch(fetchUserDetailsSuccess({}));
                navigate("/terms-and-conditions");
              } else {
                handleError(`${error.response?.status} Error. ${JSON.stringify(error?.response)}`)
              }
            })
          })
          }
        }
      } catch (err) {
        logout()
        console.log(err);
      }
    }

    init();
  }, []);

  return (
    <div className="call-back-container flex">
      {/* <Loader message="Redirecting" /> */}
      Redirecting...


    </div>
  );
};

SigninCallback.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SigninCallback;
