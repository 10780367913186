import * as api from "../../api"; 

// Actions
export const FETCH_COMMENTNOTES_REQUEST = 'FETCH_ARTICLE_REQUEST'
export const FETCH_COMMENTNOTES_SUCCESS = 'FETCH_ARTICLE_SUCCESS'
export const FETCH_COMMENTNOTES_FAILURE = 'FETCH_ARTICLE_FAILURE'

export const SET_COMMENT_DATA = 'SET_COMMENT_DATA'
export const SET_NOTE_DATA = 'SET_NOTE_DATA'

export const SET_NEW_COUNT = 'SET_NEW_COUNT'
export const SET_IMAGE_FILES = 'SET_IMAGE_FILES'
export const SET_VIDEO_FILES = 'SET_VIDEO_FILES'
export const SET_DOCUMENT_FILES = 'SET_DOCUMENT_FILES'
export const RESET_UPLOADED_FILES = 'RESET_UPLOADED_FILES'


//Action Methods

export const fetchCommentNotesRequest = () =>{
    return {
        type: FETCH_COMMENTNOTES_REQUEST
    }
}

export const fetchCommentNotesSuccess = (commentsData) =>{
    return {
        type: FETCH_COMMENTNOTES_SUCCESS,
        payload: commentsData
    }
}

export const fetchCommentNotesFailure = (error) =>{
    return {
        type: FETCH_COMMENTNOTES_FAILURE,
        payload: error
    }
}
export const setComments = (comments) =>{
    return {
        type: SET_COMMENT_DATA,
        payload: comments
    }
}
export const setNotes = (notes) =>{
    return {
        type: SET_NOTE_DATA,
        payload: notes
    }
}

export const setNewCount = () => {
    return {
        type: SET_NEW_COUNT
    }
}
export const setImageFiles = (newFiles) =>{
    return {
        type: SET_IMAGE_FILES,
        payload: newFiles
    }
}
export const setVideoFiles = (newFiles) =>{
    return {
        type: SET_VIDEO_FILES,
        payload: newFiles
    }
}
export const setDocumentFiles = (newFiles) =>{
    return {
        type: SET_DOCUMENT_FILES,
        payload: newFiles
    }
}
export const resetUplodedFiles = (newFiles) => {
    return {
        type: RESET_UPLOADED_FILES
    }
}
// Getting comments and notes

export const getCommentAndNotes = (JCID,PMID,userEmail,refresh = true) =>{
    return (dispatch)=>{
        if(refresh){
            dispatch(fetchCommentNotesRequest())
        }
        api.fetchCommentAndNotes(JCID,PMID,userEmail)
        .then(response=>{
            const CommentAndNotes = response.data
            const Comments = CommentAndNotes.filter(comment => comment.isNote === false)
            const Notes = CommentAndNotes.filter(note => note.isNote === true)
            dispatch(fetchCommentNotesSuccess(CommentAndNotes))
            dispatch(setComments(Comments))
            dispatch(setNotes(Notes))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCommentNotesFailure(errorMsg))
        })
    }

}