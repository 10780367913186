import React, { useState, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Box, Grid, Typography, Paper,Divider, Hidden, Backdrop } from '@mui/material';
import { styled, useTheme } from "@mui/material/styles";
import EngagementComponent from '../../common/components/EngagementComponent';
import ActionComponent from '../../common/components/ActionComponent';
import CircularProgress from '@mui/material/CircularProgress';
import GuestSidebar from './GuestSidebar';
import { JC_COLORS } from '../../styles/constants';
import moment from 'moment/moment';
import { useRef } from 'react';
import { useEffect } from 'react';
import { MEMBERSHIP_STATUS } from '../../constants/constants';
import JCInviteButtons from '../../pages/JCDetails/components/JCInviteButtons';
import JoinJCButton from '../../pages/JCDetails/components/JoinJCButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArticlePublicData, fetchJcArticlePublicData } from '../../api';
import { AuthContext } from '../../context/AuthContext';
import { generateAWSCognitoIdentity,isCurrentCognitoIdentityExpired } from '../../utils/awsCognitoUtils';
import { getJcPublicData } from '../../state/actions/journalclub';
import ArticlePageHelmet from '../../seo/ArticlePageHelmet'
import { convertToRender } from '../../utils/JCWysiwygEditor';
import ErrorFallback from '../../common/components/ErrorFallback';
import { updatePermissionStatus } from '../../state/actions/article';

import {WorkProgessIcon} from '../../assets'


const NOT_AUTH = 'Not authorised'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    color: "#262626",
    padding: theme.spacing(1),
    textAlign: "initial",
    [theme.breakpoints.down("md")]: {
        borderRadius: "None",
        padding: 0,
        boxShadow: "None",
    },
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        borderBottom: '2px solid #E1E1E1',
        paddingBottom: '14px',
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        borderTop: '2px solid #E1E1E1',
        paddingTop: '9px',
        marginTop: '22px',
    }
}));



const GuestViewArticleDetailed = ({ isJCPage, jcMembershipStatus, userEmail, jcDetails }) => {
    const auth = useContext(AuthContext);
    const { isAuthenticated } = auth;

    const location = useLocation();
    const theme = useTheme();
    const isMobileMatch = theme.breakpoints.down('md');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { journalclub } = useSelector((state) => state);
    const { jcDetailsPublicData } = journalclub;
    const [boxHeight,setBoxHeight] = useState(0);
    const [isLoading,setIsLoading] = useState(false)
    const [isPermissionDenied,setPermissionDenied] = useState(false)

    const { JCID: urlSlug, PMID } = useParams();

    const abstractStartRef = useRef();
    // useEffect(() => {
    //     console.log(abstractStartRef.current.offsetWidth, abstractStartRef.current.offsetTop)
    // }, [abstractStartRef])


    const [articleData, setArticleData] = useState({});

    const fetchData = async (urlSlug, PMID) => {
        if (urlSlug) {
            console.log("In JC CALL")
            try {
                let response = await fetchJcArticlePublicData(urlSlug, PMID);
                setArticleData(response?.data);
                setIsLoading(false)
            } catch (error) {
                if(error){ 
                    if(error.response.status === 403 && error.response.data === NOT_AUTH){
                        setIsLoading(false)
                        setPermissionDenied(true)
                    }
                }
                // Show an Error Pop up/Error message if 404 (Not Found) occurs. Else Akash will complain that articles are not appearing. 
                console.log("Error while getting jc article");
                console.log(error);
            }

        }
        else {
            console.log("In Article CALL")
            try {
                let response = await fetchArticlePublicData(PMID);
                setArticleData(response.data);
                setIsLoading(false)
            } catch (error) {
                // Show an Error Pop up/Error message if 404 (Not Found) occurs. Else Akash will complain that articles are not appearing. 
                console.log("Error while getting article");
            }

        }

    }

    const actionsAfterIdentityGeneration = () => {
        if(isJCPage){
            dispatch(getJcPublicData(urlSlug))
        }
        fetchData(urlSlug, PMID)
    }

    useEffect(() => {
        setIsLoading(true)
        generateAWSCognitoIdentity(false)
        actionsAfterIdentityGeneration()
    }, []);

    useEffect(() => {
        setIsLoading(true)
        generateAWSCognitoIdentity(false).then(res => {
            actionsAfterIdentityGeneration();
        })   
    }, [urlSlug, PMID]);

    useEffect(() => {
        var rightNavElement = document.querySelector(".guest-sidebar");
        if(rightNavElement){
          const jcOffset = isJCPage ? 6 : 86
          setBoxHeight(rightNavElement.offsetHeight + jcOffset)
        }          
    }, [document.querySelector(".guest-sidebar")?.offsetHeight]);



    return isLoading ? (
        <Box>
          <CircularProgress color='primary' sx={{ position: 'fixed', right: { xs: '45%', md: '40%' }, top: '40%' }} />
        </Box>
        ):
        (
        <Box
            my={{
                xs: isJCPage ? 7 : 7,
                md: 0,
            }}
        >
            {articleData &&
            <ArticlePageHelmet data={articleData} />
            }
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={9}
                    sx={{
                        minWidth: { xs: "100vw", md: "50vw", xl: "40vw" },
                    }}>
                    {
                        isJCPage && (
                            <Item
                                sx={{
                                    marginBottom: "8px",
                                    backgroundColor: { xs: JC_COLORS.LIGHT_GREY, sm: JC_COLORS.LIGHT_GREY, md: JC_COLORS.JC_WHITE }
                                }}
                            >

                                <Box px={{ xs: 0, sm: 0, md: 3 }} pt={2} mx={{ xs: 2, md: 0 }}>
                                    <Typography
                                        variant="H2_BITTER"
                                        sx={{
                                            display: "inline-block",
                                            wordWrap: "break-word",
                                            textTransform: "capitalize",
                                            marginBottom: "16px",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            window.location = location.pathname.replace(`/${articleData?.PMID}`, '')
                                        }}
                                    >
                                        { jcDetailsPublicData?.name }
                                    </Typography>

                                </Box>
                            </Item>
                        )
                    }



                    {isPermissionDenied ? <PermissionDenied boxHeight={boxHeight} /> :
                    (

                    <Item sx={{ marginBottom: "0px",minHeight:{xs : 'auto',md:boxHeight} }}>
                        <Box px={{ xs: 0, sm: 0, md: 3 }} pt={2} mx={{ xs: 2, md: 0 }}>
                            {
                                articleData?.sourceName && (
                                    <Typography
                                        variant="BODY_COPY1_RALEWAY"
                                        color="#565656"
                                    >
                                        {articleData?.sourceName}
                                        {
                                            articleData?.issue && (
                                                <Typography variant="BODY_COPY2_RALEWAY">
                                                    {" (" + articleData?.issue + ")"}
                                                </Typography>
                                            )
                                        }
                                    </Typography>
                                )
                            }
                            <Typography
                                variant="H1_BITTER"
                                component="h1"
                                mt={1}
                            >
                                {articleData?.title}
                            </Typography>

                            <StyledBox>
                                {
                                    articleData?.publicationdate &&
                                    !isJCPage && (
                                        <Typography
                                            variant="BODY_COPY2_RALEWAY"
                                            color="#838383"
                                            sx={{
                                                display: "block",
                                            }}
                                            mt={0.5}
                                            pb={1.5}
                                        >
                                            {
                                                " Published " +
                                                moment(
                                                    articleData?.publicationdate,
                                                    "MM/DD/YYYY"
                                                ).fromNow()
                                            }
                                        </Typography>
                                    )
                                }
                            </StyledBox>
                            {articleData?.authors && (
                                <Box
                                    pt={{ xs: 1.5, md: 2 }}
                                    pb={1}
                                    sx={{ lineHeight: "14px" }}
                                >
                                    <Typography
                                        variant="H3_RALEWAY"
                                        sx={{ color: { xs: "#262626", md: "#838383" } }}
                                    >
                                        {articleData?.authors.join(", ")}
                                    </Typography>
                                </Box>
                            )}

                            <Box
                                sx={{
                                    marginTop: {
                                        xs: '14px', sm: '14px', md: '27px', lg: '27px', xl: '27px'
                                    },
                                    marginBottom:{ xs: '8px', md: '12px' },

                                }}
                                ref={abstractStartRef}
                            >
                                <Typography variant="H2_BITTER" gutterBottom>
                                {articleData?.isDeNovo ? "SUMMARY" : "ABSTRACT" }
                                </Typography>
                            </Box>

                            {articleData?.abstract && (
                                <Box
                                    mb={{ xs: 5, sm: 5, md: 8 }}
                                >
                                    <Typography
                                        variant="BODY_ARTICLE_RALEWAY"
                                        gutterBottom
                                        mb={1}
                                        sx={{
                                            display: "-webkit-box",
                                            WebkitLineClamp: "5",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            "&:before": {
                                                content: '""',
                                                width: abstractStartRef?.current?.offsetWidth,
                                                height: { xs: '10rem', sm: '10rem', md: '12rem', lg: '12rem', xl: '12rem' },
                                                position: 'absolute',
                                                background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%)',
                                            }

                                        }}
                                    >
                                        {articleData?.abstract}
                                    </Typography>

                                </Box>
                            )}
                            {articleData.abstractContent && (
                                <Box mb={2} mt={{ xs: 1, md: 1.5 }}>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: convertToRender(
                                        articleData.abstractContent,
                                        articleData.abstractMedia
                                      ),
                                    }}
                                    style={{ textAlign: "left", fontFamily: "Raleway",width: abstractStartRef?.current?.offsetWidth ,position:'relative' }}
                                    className="devono-guest-abstract"
                                  ></p>
                                </Box>
                              )}

                            {
                                (
                                    (jcMembershipStatus !== null || jcMembershipStatus !== undefined) &&
                                    (userEmail !== null || userEmail !== undefined)
                                ) && (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '16px',marginTop:'70px' }}>
                                        <Typography
                                            variant="H2_RALEWAY"
                                            sx={{ fontWeight: 600, fontStyle: 'italic', marginBottom: "16px" }}
                                        >
                                            {jcMembershipStatus === MEMBERSHIP_STATUS.INVITED && `You are invited to become a member of this ${jcDetails?.name}. Accept invite to view this article.`}
                                            {jcMembershipStatus === MEMBERSHIP_STATUS.ADD && `You need to become a member of ${jcDetails?.name} to view this article. `}
                                            {jcMembershipStatus === MEMBERSHIP_STATUS.LEFT && `You need to become a member of ${jcDetails?.name} to view this article. `}
                                            {jcMembershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED && `You need to become a member of ${jcDetails?.name} to view this article. `}
                                            {jcMembershipStatus === MEMBERSHIP_STATUS.REQUESTED && `You need to become a member of ${jcDetails?.name} to view this article. `}
                                            {jcMembershipStatus === MEMBERSHIP_STATUS.REQUEST_REJECTED && `Your membership for ${jcDetails?.name} has been rejected. Please contact the Club's Admin or Course Director to get access to this Club.`}
                                            {jcMembershipStatus === MEMBERSHIP_STATUS.REMOVED && `Your membership for ${jcDetails?.name} has been cancelled. Please contact the Club's Admin or Course Director to get access to this Club.`}
                                        </Typography>

                                        {
                                            jcMembershipStatus === MEMBERSHIP_STATUS.INVITED && (
                                                <JCInviteButtons
                                                    JCID={jcDetails?.JCID}
                                                    userEmail={userEmail}
                                                    nextAction={() => navigate(location.pathname)}
                                                />
                                            )
                                        }

                                        {
                                            (
                                                jcMembershipStatus === MEMBERSHIP_STATUS.ADD ||
                                                jcMembershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED ||
                                                jcMembershipStatus === MEMBERSHIP_STATUS.LEFT
                                            ) && (
                                                <JoinJCButton
                                                    JCID={jcDetails?.JCID}
                                                    userEmail={userEmail}
                                                    journalClubName={jcDetails?.name}
                                                    isJCPublic={jcDetails?.isPublic}
                                                    nextAction={() => navigate(location.pathname)}
                                                />
                                            )
                                        }
                                    </Box>

                                )
                            }
                        </Box>
                    </Item>
                    )}

                    <Hidden mdUp>
                        <Box sx={{ margin: "0px 13px 13px 13px" }}>
                            {
                                (
                                    (userEmail === null || userEmail === undefined) ||
                                    (isAuthenticated === false)
                                ) && (
                                    <GuestSidebar />
                                )
                            }
                        </Box>
                    </Hidden>

                </Grid>

                <Hidden mdDown>
                    <Grid
                        item
                        xs={12}
                        md={2.7}
                        className="rightnav"
                        style={{ top: "64" }}
                        sx={{
                            marginLeft: "11px",
                            positon: "sticky",
                            flexGrow: "1",
                            flexBasis: "300px",
                            position: "sticky",
                            // top: '64px',
                            alignSelf: "start",
                            // maxHeight: '100vh',
                            overflow: "scroll",
                            paddingBottom: "30px",
                            msOverflowStyle: "none",
                            scrollbarWidth: "none",
                            "&::-webkit-scrollbar": { display: "none" },
                        }}
                    >
                        <Grid container direction="column" spacing={1}>
                            <EngagementComponent isDisabled={true} />
                            <ActionComponent isDisabled={true} />
                        </Grid>

                    </Grid>

                </Hidden>


            </Grid>

        </Box >
    )
}

export default GuestViewArticleDetailed

const PermissionDenied = ({boxHeight}) => {
    return (
    <Item sx={{ marginBottom: "0px",minHeight:{xs : 'auto',md:boxHeight} }}>
        <Box px={{ xs: 0, sm: 0, md: 3 }} pt={2} mx={{ xs: 2, md: 0 }}>
        <Box sx={{textAlign:'center',marginTop:'10%',marginBottom:'5%',marginLeft:'auto',marginRight:'auto'}}>
            <WorkProgessIcon className="workinprogress-logo"/>
            <Box mt={{xs:2,md:0}} mb={{xs:4,md:0}}>
                <Typography variant="H1_RALEWAY">
                This article is still under process and is yet to be published into this Journal Club.
                </Typography>
            </Box>
        </Box>
        </Box>
    </Item>
    )
}