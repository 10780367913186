import React,{useContext,useState,useEffect} from 'react';
import { AuthContext } from '../../../context/AuthContext';
import {
  Box,
  Typography,
  Grid,
  Hidden,
  Button,
  Menu,
  MenuItem,
  FormControl,
  TextField,
  OutlinedInput,
  Select, InputLabel, Divider,
  Checkbox,
  FormControlLabel,
  Input,
  styled,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DENOVO_SEND_EMAILTO_LIST } from '../../../constants/constants';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { postDenovoEmail } from "../../../api"

const InputWrapper = styled(Box)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    width: '100%',
    fontFamily: 'Raleway',
    fontSize: '16px'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px'
  }
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    tabIndex={-10}
    {...props} />))(({ theme }) => ({
      '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: '#262626',
        backgroundColor: 'FFFFFF',
        border: '1px solid #838383',
        borderRadius: '0px'
      },
      '& .MuiMenuItem-root': {
        backgroundColor: '#FFFFFF',
        padding: '8px',
        marginBottom: '1px'
      },
      '& .MuiMenuItem-root:hover': {
        backgroundColor: '#EF5B50',
        color: '#FFFFFF'
      },
      '& .MuiMenu-list': {
        padding: '4px',
      },

    }));

const SendEmailTab = () => {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [sendTo, setSendTo] = useState('')
  const [subject, setSubject] = useState('')
  const [emailBody, setEmailBody] = useState('')
  const [isSendDisabled, setSendDisabled] = useState(true)
  const [sendError, setSendError] = useState(false)
  const [toastSentMsg, setSentMsg] = useState(false)
  const { jcDetails } = useSelector((state) => state.journalclub);
  const sendToList = Object.keys(DENOVO_SEND_EMAILTO_LIST)


  useEffect(() => {
    if (sendTo === "" || subject.trim() === "" || emailBody.trim() === "") {
      setSendDisabled(true)
    }
    else {
      setSendDisabled(false)
    }

  }, [sendTo, subject, emailBody])


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const addToSendList = (item) => {
    setSendTo(item)
    handleClose()
  }
  const resetEmailInfo = () => {
    setSendTo('')
    setSubject('')
    setEmailBody('')
    setSendError(false)
  }

  const handleSendButton = () => {
    setSendError(false)
    const data = {
      "to": DENOVO_SEND_EMAILTO_LIST[sendTo],
	    "subject": subject,
	    "body": emailBody,
      "authUserEmail": userEmail, 
    }
    const params = new URLSearchParams();
    params.append("to", DENOVO_SEND_EMAILTO_LIST[sendTo]);
    params.append("subject", subject );
    params.append("body", emailBody);
    params.append("authUserEmail",userEmail)
    postDenovoEmail(jcDetails?.JCID,data).then(response =>{
      resetEmailInfo()
      setSentMsg(true)
    }).catch(error => {
      setSendError(true)
      console.error(error);
    });
  }

  return (
    <>
      <Box sx={{ textAlign: 'left' }} mt={{ xs: 2, md: 0 }}>
        <Typography variant="H1_BITTER" color="primary" sx={{ fontWeight: '700 !important' }}>
          Send Email to Members
        </Typography>
      </Box>
      <Grid container spacing={{ xs: 1.5 }} mb={{ xs: 8, md: 0 }}>
        <Grid item xs={12} mt={3}>
          <InputWrapper>
            <Box sx={{ border: '1.8px solid #838383', borderRadius: '4px', cursor: 'pointer', textAlign: 'left' }} pl={1} pr={1} pb={1} onClick={handleClick}>
              <Typography variant="BODY_ARTICLE_RALEWAY" color="#838383" sx={{ fontWeight: '600!important' }}>
                To
              </Typography>
              <Box sx={{ display: 'inline-block', position: 'relative', top: '8px' }}>
                <KeyboardArrowDownIcon
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                />
              </Box>
              <Box sx={{ display: 'inline-block' }} ml={2}>
                <Typography variant="BODY_ARTICLE_RALEWAY" sx={{ fontWeight: '600!important' }}>
                  {sendTo}
                </Typography>
              </Box>
            </Box>
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <OutlinedInput placeholder="Subject" type='text' value={subject} onChange={e => setSubject(e.target.value)} required sx={{ fontWeight: '600!important' }} />
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <TextField placeholder="Your Message here" value={emailBody} onChange={e => setEmailBody(e.target.value)} disableUnderline multiline={true} fullWidth={true} minRows={8}>
            </TextField>
          </InputWrapper>
        </Grid>
        {sendError &&
          <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <Typography variant="BODY_COPY2_RALEWAY" color="primary" sx={{ fontWeight: '600!important' }}>
              Invalid fields! ( Note: Email body should not contain html tags)
            </Typography>
          </Grid>
        }
        <Grid item xs={12} sx={{ textAlign: 'left' }} mb={2}>
          <Button variant="contained" size="medium" color="primary" sx={{ fontFamily: 'Raleway' }} disabled={isSendDisabled} onClick={handleSendButton}>
            Send Email
          </Button>
        </Grid>
      </Grid>

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {sendToList && sendToList.map((item, idx) =>
          <MenuItem disableautofocusitem="true" key={item} onClick={() => addToSendList(item)}>
            <Box pr={1}>
              <Typography variant="BODY_COPY1_RALEWAY">{item}</Typography>
            </Box>
          </MenuItem>
        )}
      </StyledMenu>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={toastSentMsg}
          autoHideDuration={2000}
          onClose={() => setSentMsg(false)}
          key={'bottom' + 'center'}
        >
          <SnackbarContent sx={{
            backgroundColor: '#262626',
            fontFamily: 'Raleway',
            fontSize: '14px',
            padding: '0',
            paddingLeft: '20px',
            minWidth: '120px!important'
          }}
            message="Email Sent!"
          />
        </Snackbar>
      </div>
    </>
  )
}

export default SendEmailTab