import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Hidden, Box, Typography, CircularProgress, useTheme, styled, useScrollTrigger, Dialog, } from '@mui/material';
import { useLocation } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PublicJCIcon, PrivateJCIcon, FilterIcon } from '../../../assets/SVG';

import { CenteredContainer, CenteredActionButton, TopDrawer, Row } from '../../../common/components';
import CMEAccreditingClub from '../../../common/components/CMEAccreditingClub';
import { JC_COLORS } from '../../../styles/constants';
import { pxToRem } from '../../../utils';
import ProfileMenu from '../../../common/containers/ProfileMenu';

import JoinJCButton from '../components/JoinJCButton';
import ChangeJCRoleButton from '../components/ChangeJCRoleButton';
import JCInviteButtons from '../components/JCInviteButtons';
import CourseDirectors from '../containers/CourseDirectors';
import PartnersAffiliates from '../containers/PartnersAffiliates';
import AccreditorsAndSupporters from '../containers/AccreditorsAndSupporters';

import { AuthContext } from '../../../context/AuthContext';
import { MEMBERSHIP_STATUS, INSTITUTION_STATUS, JC_CONTENT } from '../../../constants/constants';
import { getJCUserDetails, getJournalClubDetails, resetJcDetails, setJCContent } from '../../../state/actions/journalclub';
import DialogContentCMEDisclosure from '../jc-content/manage-content/DialogContentCMEDisclosure';
import SectionTitle from '../components/SectionTitle';
import HelpMenu from "../../../common/containers/HelpMenu";


const Puller = styled(Box)(({ theme }) => ({
    width: 35,
    height: 4,
    borderRadius: 3,
    backgroundColor: 'white',
}));

const JCDescription = styled(Typography)(({ theme }) => ({
    //fontFamily: 'Raleway',
    ...theme.typography.H3_RALEWAY,
    fontWeight: 600,
    color: JC_COLORS.JC_ICON,
    textAlign: 'left',
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: "hidden",
    [theme.breakpoints.up('md')]: {
        lineHeight: '1rem',
        //        fontSize: pxToRem(14),
        //        lineHeight: pxToRem(20),
    },
    [theme.breakpoints.down('md')]: {
        lineHeight: '0.8rem',
        //        fontSize: pxToRem(12),
        //        lineHeight: pxToRem(16),
    }

}));

const PublicPrivateStatusText = styled(Typography)(({ theme }) => ({
    ...theme.typography.H3_RALEWAY,
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
        //fontSize: pxToRem(18),
        //lineHeight: pxToRem(21.13),
        color: JC_COLORS.JC_BLACK,
    },
    [theme.breakpoints.down('md')]: {
        //fontSize: pxToRem(10),
        //lineHeight: pxToRem(11.74),
        color: JC_COLORS.JC_ICON,

    }
}));

const ContributorsReadersText = styled(Typography)(({ theme }) => ({
    ...theme.typography.H3_RALEWAY,
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
        //fontSize: pxToRem(18),
        //lineHeight: pxToRem(21.13),
        color: JC_COLORS.JC_BLACK,
    },
    [theme.breakpoints.down('md')]: {
        //fontSize: pxToRem(10),
        //lineHeight: pxToRem(11.74),
        color: JC_COLORS.JC_ICON,
    }
}));

const H3RalewayText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 600,
    color: JC_COLORS.JC_ICON,
    [theme.breakpoints.up('md')]: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(26),
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(10),
        lineHeight: pxToRem(11.74),
    }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: "25px",
    },
    '& .MuiDialogActions-root': {
        padding: "25px",
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialog-paper': {
            width: '90%',
            maxWidth: '100%',
            height: '80%',
            maxHeight: '100%',
            borderRadius: '0px',
            margin: 0,
        }
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDialog-paper': {
            borderRadius: '18px',
            width: '90vw',
            minWidth: '900px'
            //width: '70%',
            //height: '68%'
        }
    },
}));

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const JCDetailsSingleTrayMobile = () => {

    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const theme = useTheme();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);

    const dispatch = useDispatch();
    const { journalclub } = useSelector((state) => state);
    const { isFetchingDetails, jcDetails, jcContent, jcUserDetails, error } = journalclub;
    const { JCID, acceptedCmeDisclosure, cmeDownloadUrl, isCmeAccrediting, membershipStatus } = jcDetails;

    const [courseDirectorsList, setCourseDirectorsList] = useState([]);
    const [accreditorsAndSupportersList, setAccreditorsAndSupportersList] = useState([]);
    const [partnersAndAffiliatesList, setPartnersAndAffiliatesList] = useState([]);
    const [partnersList, setPartnersList] = useState([]);
    const [affiliatesList, setAffiliatesList] = useState([]);

    const [showCMEDisclosurePDF, setShowCMEDisclosurePDF] = useState(false);

    const location = useLocation();
    const currentUrl = location.pathname;
    const urlSlug = location.pathname.replace(/\/jc\//, '');

    const openDrawerHandler = () => {
        setOpenDrawer(true)
    }

    const closeDrawerHandler = () => {
        setOpenDrawer(false)
    }

    useEffect(() => {
        if (currentUrl.startsWith('/jc')) {
            setOpenDrawer(false);
        }
    }, [currentUrl]);



    useEffect(() => {
        if (isFetchingDetails === false) {   
            setCourseDirectorsList(parseCourseDirectors(jcDetails));
            setPartnersList(parsePartners(jcDetails));
            setAffiliatesList(parseAffiliates(jcDetails));
            setAccreditorsAndSupportersList(jcDetails?.institutions?.filter(obj => (
                obj?.status === INSTITUTION_STATUS.ACCREDITOR ||
                obj?.status === INSTITUTION_STATUS.SUPPORTER
            )) || []);
            setPartnersAndAffiliatesList(jcDetails?.institutions?.filter(obj => (
                obj?.status === INSTITUTION_STATUS.PARTNER ||
                obj?.status === INSTITUTION_STATUS.AFFILIATE
            )) || []);
        }


    }, [jcDetails]);

    useEffect(() => {
        if (
            (acceptedCmeDisclosure === false) &&
            (membershipStatus === MEMBERSHIP_STATUS.JOINED) &&
            (cmeDownloadUrl !== null)
        ) {
            setShowCMEDisclosurePDF(true)
        } else {
            setShowCMEDisclosurePDF(false);
        }
    }, [cmeDownloadUrl, acceptedCmeDisclosure, membershipStatus]);

    useEffect(() => {
        return () => {
            dispatch(resetJcDetails())
        }
    }, []);

    return (
		<>
			<Hidden mdUp>
				<BootstrapDialog
					open={showCMEDisclosurePDF}
					onClose={() => {
						if (acceptedCmeDisclosure === false) {
							return;
						} else {
							setShowCMEDisclosurePDF(false);
						}
					}}>
					<DialogContentCMEDisclosure
						JCID={JCID}
						urlSlug={urlSlug}
						userEmail={userEmail}
						cmeDisclosurePdfUrl={cmeDownloadUrl}
						acceptedCMEDisclosure={acceptedCmeDisclosure}
						closeIconHandler={() => setShowCMEDisclosurePDF(false)}
					/>
				</BootstrapDialog>
				<ElevationScroll>
					<AppBar
						position="fixed"
						sx={{
							[theme.breakpoints.down("sm")]: {
								width: "100%"
							},
							zIndex: `${theme.zIndex.drawer + 1}!important`,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							padding: "0px 18px 0px 18px"
						}}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-start"
							}}>
							<Typography
								sx={{
									fontFamily: "Raleway",
									fontWeight: 700,
									fontSize: pxToRem(14),
									lineHeight: pxToRem(16.8),
									color: JC_COLORS.JC_WHITE,
									textAlign: "left"
								}}>
								{jcDetails?.name}
							</Typography>
							{openDrawer && anchorEl === false && (
								<KeyboardArrowUpIcon
									sx={{
										width: "27px",
										height: "24px",
										color: JC_COLORS.JC_WHITE
									}}
									onClick={() => setOpenDrawer(false)}
								/>
							)}
							{anchorEl && (
								<KeyboardArrowUpIcon
									sx={{
										width: "27px",
										height: "24px",
										color: JC_COLORS.JC_WHITE
									}}
									onClick={() => setAnchorEl(false)}
								/>
							)}
							{openDrawer === false && (
								<KeyboardArrowDownIcon
									sx={{
										width: "27px",
										height: "24px",
										color: JC_COLORS.JC_WHITE
									}}
									onClick={() => setOpenDrawer(true)}
								/>
							)}
						</Box>
						<Box sx={{ margin: "7px 0px 7px 0px" }}>
							<ProfileMenu width={"36px"} height={"36px"} />
						</Box>
						<Box sx={{ margin: "7px 0px 7px 0px" }}>
							<HelpMenu mobile={true} />
						</Box>
					</AppBar>
				</ElevationScroll>
				<TopDrawer
					open={openDrawer}
					onClose={() => closeDrawerHandler()}
					onOpen={() => openDrawerHandler()}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							backgroundColor: JC_COLORS.JC_RED,
							color: JC_COLORS.JC_WHITE
						}}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-start",
								paddingLeft: "8px"
							}}>
							<Typography
								sx={{
									fontFamily: "Raleway",
									fontWeight: 700,
									fontSize: pxToRem(14),
									lineHeight: pxToRem(16.8),
									color: JC_COLORS.JC_WHITE
								}}>
								{jcDetails?.name}
							</Typography>
							{openDrawer && anchorEl === false && (
								<KeyboardArrowUpIcon
									sx={{
										width: "27px",
										height: "24px",
										color: JC_COLORS.JC_WHITE
									}}
									onClick={() => setOpenDrawer(false)}
								/>
							)}
							{anchorEl && (
								<KeyboardArrowUpIcon
									sx={{
										width: "27px",
										height: "24px",
										color: JC_COLORS.JC_WHITE
									}}
									onClick={() => setAnchorEl(false)}
								/>
							)}
							{openDrawer === false && (
								<KeyboardArrowDownIcon
									sx={{
										width: "27px",
										height: "24px",
										color: JC_COLORS.JC_WHITE
									}}
									onClick={() => setOpenDrawer(true)}
								/>
							)}
						</Box>
						<Box sx={{ margin: "7px 13px 7px 0px" }}>
							<ProfileMenu width={"36px"} height={"36px"} />
						</Box>
						<Box sx={{ margin: "7px 0px 7px 0px" }}>
							<HelpMenu mobile={true} />
						</Box>
					</Box>
					{isFetchingDetails && (
						<CenteredContainer>
							<CircularProgress />
						</CenteredContainer>
					)}
					{isFetchingDetails === false && (
						<Box
							sx={{
								padding: "16px 16px 27px 16px",
								bgcolor: JC_COLORS.JC_RED,
								borderRadius: "0px 0px 8px 8px"
							}}>
							<Box
								sx={{
									padding: "16px 16px 16px 16px",
									bgcolor: JC_COLORS.JC_WHITE,
									borderRadius: "4px"
								}}>
								<Row
									sx={{
										justifyContent: "flex-start",
										borderBottom: "1px solid #848484",
										paddingBottom: "12px"
									}}>
									<Box
										sx={{
											maxWidth: "70%",
											wordWrap: "break-word"
										}}>
										<JCDescription>
											{jcDetails?.description}
										</JCDescription>
									</Box>
								</Row>

								<Row
									sx={{
										justifyContent: "space-between",
										marginTop: "15px"
									}}>
									<Row sx={{ alignItems: "center" }}>
										{jcDetails?.isPublic ? (
											<PublicJCIcon
												width={"20px"}
												height={"20px"}
											/>
										) : (
											<PrivateJCIcon
												width={"20px"}
												height={"20px"}
											/>
										)}
										<PublicPrivateStatusText
											sx={{ marginLeft: "7px" }}>
											{jcDetails?.isPublic
												? "Public Club"
												: "Private Club"}
										</PublicPrivateStatusText>
									</Row>
									<ContributorsReadersText sx={{ ml: 4 }}>
										{parseContributorsAndReaders(jcDetails)}
									</ContributorsReadersText>
								</Row>

								<Box sx={{ margin: "18px 0px 11px 0px" }}>
									{jcDetails?.isCmeAccrediting && (
										<CMEAccreditingClub />
									)}
								</Box>
								{jcDetails?.membershipStatus ===
								MEMBERSHIP_STATUS.JOINED ? (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center"
										}}>
										<Box
											sx={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "flex-start",
												alignItems: "center",
												marginTop: "8px",
												marginBottom: "8px"
											}}>
											<H3RalewayText
												sx={{
													color: "#848484",
													marginRight: "8px"
												}}>
												Your CME / CE Credits:
											</H3RalewayText>
											<PublicPrivateStatusText>
												{parseFloat(
													jcUserDetails?.userCmeCredits ??
														0
												)
													.toFixed(1)
													.toString()}
											</PublicPrivateStatusText>
										</Box>
										{jcDetails?.isCmeAccrediting ? (
											<Typography
												variant="BODY_COPY2_RALEWAY"
												sx={{
													textDecoration: "underline",
													cursor: "pointer",
													width: "fit-content",
													display: `${
														cmeDownloadUrl ===
															null ||
														isCmeAccrediting ===
															false
															? "none"
															: "block"
													}`
												}}
												onClick={() =>
													setShowCMEDisclosurePDF(
														true
													)
												}>
												Read Disclosure
											</Typography>
										) : (
											<></>
										)}
									</Box>
								) : (
									<></>
								)}

								<Box>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "flex-start",
											alignItems: "center",
											marginTop: "4px"
										}}>
										{jcDetails?.membershipStatus ===
											MEMBERSHIP_STATUS.JOINED && (
											<ChangeJCRoleButton
												JCID={jcDetails?.JCID}
												userEmail={userEmail}
												currentRole={jcUserDetails.role}
												membershipStatus={
													jcDetails.membershipStatus
												}
											/>
										)}
										{jcDetails?.membershipStatus ===
											MEMBERSHIP_STATUS.ADD && (
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "flex-start"
												}}>
												<JoinJCButton
													JCID={jcDetails?.JCID}
													userEmail={userEmail}
													journalClubName={
														jcDetails.name
													}
													isJCPublic={
														jcDetails.isPublic
													}
													sx={{ marginTop: "17px" }}
												/>
											</Box>
										)}
										{jcDetails?.membershipStatus ===
											MEMBERSHIP_STATUS.INVITED && (
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "flex-start"
												}}>
												<Typography
													variant="H3_RALEWAY"
													sx={{
														fontWeight: 600,
														color: JC_COLORS.JC_ICON,
														marginBottom: "16px"
													}}>
													You are invited to become a{" "}
													{jcUserDetails.role}
												</Typography>
												<JCInviteButtons
													JCID={jcDetails?.JCID}
													userEmail={userEmail}
												/>
											</Box>
										)}
									</Box>
								</Box>
							</Box>
							{anchorEl && (
								<Box
									sx={{
										marginTop: "16px",
										padding: "16px 16px 16px 16px",
										bgcolor: JC_COLORS.JC_WHITE,
										borderRadius: "4px"
									}}>
									{courseDirectorsList?.length > 0 && (
										<CourseDirectors
											courseDirectorsList={[
												...courseDirectorsList
											]}
										/>
									)}
									{partnersAndAffiliatesList?.length && (
										<Box
											sx={{
												borderTop: "1px solid #848484",
												padding: "12px 0px",
												marginBottom: "12px"
											}}>
											<PartnersAffiliates
												partnersAffiliatesList={
													partnersAndAffiliatesList
												}
											/>
										</Box>
									)}

									{accreditorsAndSupportersList?.length >
										0 && (
										<>
											<Box
												sx={{
													borderTop:
														"1px solid #848484",
													display: "flex",
													flexDirection: "row",
													alignItems: "flex-start",
													justifyContent:
														"space-between",
													padding: "12px 0px 0px 0px"
												}}>
												<SectionTitle>
													Accreditors/Supporters
												</SectionTitle>
											</Box>
											<AccreditorsAndSupporters
												accreditorsAndSupportersList={
													accreditorsAndSupportersList
												}
												urlSlug={urlSlug}
												userEmail={userEmail}
												JCID={JCID}
												acceptedCmeDisclosure={
													acceptedCmeDisclosure
												}
												membershipStatus={
													jcDetails?.membershipStatus
												}
												cmeDownloadUrl={cmeDownloadUrl}
												isCmeAccrediting={
													isCmeAccrediting
												}
											/>
										</>
									)}
								</Box>
							)}
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									mt: "17px"
								}}
								onClick={() => {
									if (anchorEl === true) {
										setAnchorEl(false);
									} else {
										setAnchorEl(true);
									}
								}}>
								<Puller />
							</Box>
						</Box>
					)}
				</TopDrawer>
			</Hidden>
		</>
	);
}

export default JCDetailsSingleTrayMobile;

function parseContributorsAndReaders(jcDetails) {
	let contributorsText;
	let readersText;
	if (
		jcDetails?.contributors === undefined ||
		jcDetails?.contributors === null
	) {
		contributorsText = "0 Contributer";
	} else {
		contributorsText =
			jcDetails.contributors === 1
				? `1 Contributor`
				: `${jcDetails.contributors} Contributors`;
	}

	if (jcDetails.readers === undefined || jcDetails?.readers === null) {
		readersText = "0 Reader";
	} else {
		readersText =
			jcDetails.readers === 1
				? `1 Reader`
				: `${jcDetails.readers} Readers`;
	}

	return contributorsText === ""
		? readersText
		: `${contributorsText} | ${readersText}`;
}

function parseCourseDirectors(jcDetails) {
	let { adminMembers } = jcDetails;
	return adminMembers
		? [...adminMembers.filter((obj) => obj.role === "Course Director")]
		: [];
}

function parseCmeSupporters(jcDetails) {
	let { institutions } = jcDetails;
	return institutions ? institutions.filter((obj) => obj.cmeSupporter) : [];
}

function parsePartners(jcDetails) {
	let { institutions } = jcDetails;
	return institutions
		? institutions.filter(
				(obj) =>
					obj.status === INSTITUTION_STATUS.PARTNER ||
					obj.status === "InstitutionStatus.PARTNER"
		  )
		: [];
}

function parseAffiliates(jcDetails) {
	let { institutions } = jcDetails;
	return institutions
		? institutions.filter(
				(obj) =>
					obj.status === INSTITUTION_STATUS.AFFILIATE ||
					obj.status === "InstitutionStatus.AFFILIATE"
		  )
		: [];
}