import React, { useState } from 'react';
import { Box, Hidden, Avatar, Typography, Grid, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { JC_COLORS } from '../../../styles/constants';
import { pxToRem } from '../../../utils';
import SectionTitle from '../components/SectionTitle';
import AvatarNameRow from '../components/AvatarNameRow';
import AvatarName from '../components/AvatarName';
import SquareAvatar from '../components/SquareAvatar';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      borderRadius: '0px',
      margin: 0,
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      width: '40%',
      height: '68%'
    }
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    //color: '#bdbdbd',
    color: '#838383',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    //backgroundColor: '#bdbdbd',
    backgroundColor: '#838383'
  },

}));

const CourseDirectors = ({ courseDirectorsList }) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false)
  return (
    <>
      <Hidden mdDown>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <SectionTitle>Course Directors</SectionTitle>

          <Grid
            container
            direction={theme.breakpoints.up('lg') ? 'row' : 'column'}
            sx={{
              //display: 'flex',
              //flexDirection: 'row',
              //justifyContent: 'space-between'
            }}>
            <Grid item lg={6} xl={6}>
              {
                courseDirectorsList.length > 0 && (
                  <AvatarNameRow
                    name={`${courseDirectorsList[0].firstName} ${courseDirectorsList[0].lastName}`}
                    logoUrl={courseDirectorsList[0].logoUrl}
                  />
                )
              }

            </Grid>
            <Grid item lg={6} xl={6}>
              {
                courseDirectorsList.length > 1 && (
                  <AvatarNameRow
                    name={`${courseDirectorsList[1].firstName} ${courseDirectorsList[1].lastName}`}
                    logoUrl={courseDirectorsList.length > 1 ? courseDirectorsList[1].logoUrl : ''}
                  />
                )
              }

            </Grid>


          </Grid>
          <Grid
            container
            direction={theme.breakpoints.up('xl') ? 'row' : 'column'}
          //sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Grid item lg={6} xl={6}>
              {
                courseDirectorsList.length > 2 && (
                  <AvatarNameRow
                    name={courseDirectorsList.length > 2 ? `${courseDirectorsList[2].firstName} ${courseDirectorsList[2].lastName}` : ''}
                    logoUrl={courseDirectorsList.length > 2 ? courseDirectorsList[2].logoUrl : ''}
                  />
                )
              }

            </Grid>
            <Grid item lg={6} xl={6}>
              {
                courseDirectorsList.length > 3 && (
                  <RestOfCourseDirectors
                    names={courseDirectorsList.slice(3, courseDirectorsList.length)}
                    openDialogHandler={() => setOpenDialog(true)}
                  />
                )
              }

            </Grid>


            {
              courseDirectorsList.length > 3 && (
                <BootstrapDialog
                  open={openDialog}
                  onClose={() => setOpenDialog(false)}
                >
                  <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="H3_BITTER" sx={{ color: JC_COLORS.JC_ICON, fontWeight: 700, margin: '16px 0px 0px 30px' }}>Course Directors</Typography>
                    <IconButton onClick={() => setOpenDialog(false)} sx={{ marginRight: '5px' }}>
                      <CloseIcon
                        sx={{
                          width: '32px',
                          height: '32px',
                          color: JC_COLORS.JC_ICON,
                        }}
                      />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      marginRight: '16px',
                      marginLeft: '38px',
                      marginTop: '0px',
                      '&::-webkit-scrollbar': { width: '6px' },
                      '&::-webkit-scrollbar-track': { backgroundColor: JC_COLORS.JC_WHITE, borderRadius: '9px', },
                      '&::-webkit-scrollbar-thumb': { backgroundColor: "#BDBDBD", borderRadius: '9px' },
                    }}
                  >
                    <Box>

                      {
                        courseDirectorsList.map((obj, i) => (
                          <Box key={i} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '9px' }}>
                            <SquareAvatar
                              name={`${courseDirectorsList[i].firstName} ${courseDirectorsList[i].lastName}`}
                              logoUrl={courseDirectorsList[i].logoUrl}
                            />
                            <Typography
                              sx={{
                                fontFamily: 'Raleway',
                                marginLeft: '15px',
                                fontSize: pxToRem(16),
                                lineHeight: pxToRem(22),

                              }}
                            >{`${courseDirectorsList[i].firstName} ${courseDirectorsList[i].lastName}`}</Typography>
                          </Box>
                        ))
                      }

                    </Box>
                  </DialogContent>
                </BootstrapDialog>

              )
            }
          </Grid>



        </Box>

      </Hidden>
      <Hidden mdUp>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',

          }}
        >
          <SectionTitle>Course Directors</SectionTitle>
          <Grid container>
            {
              courseDirectorsList.map((obj, i) => (
                <Grid item xs={6} sm={6} key={i}>
                  <AvatarNameRow
                    name={`${obj.firstName} ${obj.lastName}`}
                    logoUrl={obj.logoUrl}
                  />
                </Grid>
              ))
            }
          </Grid>

        </Box>
      </Hidden>
    </>

  )
}

export default CourseDirectors;


const RestOfCourseDirectors = ({ names, openDialogHandler }) => {
  if (names.length === 1) {
    return (
      <AvatarNameRow
        name={`${names[0].firstName ? names[0].firstName : ""}` + ` ${names[0].lastName ? names[0].lastName : ""}`}
        logoUrl={names[0].logoUrl ?? ""}
      />
    )
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
      {
        names.length > 0 && (
          <BootstrapTooltip
            title={`${names[0].firstName ? names[0].firstName : ""}` + ` ${names[0].lastName ? names[0].lastName : ""}`}
            placement="top">
            <div>
              <SquareAvatar
                name={`${names[0].firstName ? names[0].firstName : ""}`}
                logoUrl={names[0].logoUrl ?? ""}
                sx={{ marginRight: '5px' }}
              >
              </SquareAvatar>
            </div>
          </BootstrapTooltip>

        )
      }
      {
        names.length > 1 && (
          <BootstrapTooltip
            title={`${names[1].firstName ? names[1].firstName : ""}` + ` ${names[1].lastName ? names[1].lastName : ""}`}
            placement="top">
            <div>
              <SquareAvatar
                name={`${names[1]?.firstName ? names[1].firstName[0] : ""}`}
                logoUrl={names[1].logoUrl ?? ""}
                sx={{ marginRight: '5px' }}
              >
              </SquareAvatar>
            </div>
          </BootstrapTooltip>
        )
      }
      {
        names.length > 2 && (
          <BootstrapTooltip
            title={`${names[2].firstName ? names[2].firstName : ""}` + ` ${names[2].lastName ? names[2].lastName : ""}`}
            placement="top">
            <div>
              <SquareAvatar
                name={`${names[2]?.firstName ? names[2].firstName[0] : ""}`}
                logoUrl={names[2].logoUrl ?? ""}>
              </SquareAvatar>
            </div>
          </BootstrapTooltip>

        )
      }
      {
        names.length > 3 && (
          <Box onClick={openDialogHandler} sx={{ cursor: 'pointer' }}>
            <AvatarName >{`+${names.length - 3} More`}</AvatarName>
          </Box>

        )
      }
    </Box>
  );
};



// const AvatarNameRow = ({ name, logoUrl }) => {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center',
//         marginBottom: '10px',
//         marginRight: '15px',
//         maxWidth: '400px',
//       }}>
//       <ResponsiveAvatar variant="square" alt={name} src={logoUrl}>{name.length > 0 ? name[0] : ''}</ResponsiveAvatar>
//       <CourseDirectorName>{name}</CourseDirectorName>
//     </Box>
//   );
// }