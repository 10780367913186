import React,{useEffect, useState} from 'react'
import ReactDomServer from 'react-dom/server';

import { fetchSitemap } from '../../api';
import { generateAWSCognitoIdentity } from '../../utils/awsCognitoUtils';

import axios from 'axios';

//import xml2js from "xml2js"; 
//var XMLParser = require("react-xml-parser"); 

const fetchSitemapData = async () => {
  try {
    let response = await fetchSitemap(); 
    console.log(response?.data); 
    return response?.data; 
  } catch(error) {
    console.log(error)
  }
  // fetchSitemap().then(response => {
  //   //let parser = new xml2js.Parser();
  //   //var xml = new XMLParser().parseFromString(response?.data)
  //   //console.log(xml)
  //   console.log("---------------")
  //   console.log(response?.data)
  //   return response?.data
  // }
  // ).catch(error =>{
  //     console.log(error,":: error while fecting sitemap")
  // })
}


const sitemapUrl = `${process.env.REACT_APP_API_URL}/public/sitemap`; 

const connectionInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 30000,
  headers: {
    'Content-Type': "application/xml; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

function Sitemap(props) {
  const [data,setData] = useState('');

  useEffect(() => {
    const DOMParse = new DOMParser();
    let xmlDoc;
    console.log("in sitemap use effect");
    generateAWSCognitoIdentity(true).then(res =>{
      fetchSitemap().then(response => {
          //xmlDoc = DOMParse.parseFromString(response.data, 'text/xml');
          setData(response.data)
        }).catch(error =>{
          console.log(error,":: error while fecting sitemap")
        })
  })
  },[])
  //return React.createElement(data, {}, null)
  return (
    <span dangerouslySetInnerHTML={{ __html: data }}/>
  )
  // return ReactDomServer.renderToStaticMarkup(data)
  
 
}

export default Sitemap

// export default async (req, res) =>{

//     try {

//         // const smStream = new SitemapStream({
//         //     hostname: `http://localhost:3000`,
//         //     cacheTime: 600000,
//         //   });
               
//         // const jobs=[list]
//         // jobs.forEach(job => {
//         // smStream.write({
//         //     url: `/jobs/${job.slug}`,
//         //     changefreq: 'daily',
//         //     priority: 0.9
//         // });
//         // });

//         // smStream.end();

//         const sitemapOutput = await fetchSitemap()
//         console.log(sitemapOutput.data,":: Site data")
//         return sitemapOutput.data
//         console.log(res,"response")
//         res.writeHead(200, {
//         'Content-Type': 'application/xml'
//         });

//         res.end(sitemapOutput);
//     }
//     catch (e) {
//         console.log(e)
//         res.send(JSON.stringify(e))
//     }
// }
