import React from "react";

const ProposeIcon = ({className}) => {
    return (
        <svg className={className} width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.14546 9.35454L6.06061 9.26968L5.97576 9.35454L0.915147 14.4151L0.830294 14.5L0.915147 14.5849L5.97576 19.6455L6.06061 19.7303L6.14546 19.6455L7.25478 18.5361L7.33964 18.4513L7.25478 18.3664L4.29225 15.4039H13.5961V24.7078L10.6336 21.7452L10.5487 21.6604L10.4639 21.7452L9.35454 22.8545L9.26968 22.9394L9.35454 23.0242L14.4151 28.0849L14.5 28.1697L14.5849 28.0849L19.6455 23.0242L19.7303 22.9394L19.6455 22.8545L18.5361 21.7452L18.4513 21.6604L18.3664 21.7452L15.4039 24.7078V15.4039H24.7077L21.7452 18.3664L21.6604 18.4513L21.7452 18.5361L22.8545 19.6455L22.9394 19.7303L23.0242 19.6455L28.0849 14.5849L28.1697 14.5L28.0849 14.4151L23.0242 9.35454L22.9394 9.26968L22.8545 9.35454L21.7452 10.4639L21.6604 10.5487L21.7452 10.6336L24.7077 13.5961H15.4039V4.29225L18.3664 7.25478L18.4513 7.33964L18.5361 7.25478L19.6455 6.14546L19.7303 6.06061L19.6455 5.97576L14.5849 0.915147L14.5 0.830294L14.4151 0.915147L9.35454 5.97576L9.26968 6.06061L9.35454 6.14546L10.4639 7.25478L10.5487 7.33964L10.6336 7.25478L13.5961 4.29225V13.5961H4.29225L7.25478 10.6336L7.33964 10.5487L7.25478 10.4639L6.14546 9.35454Z" fill="#838383" stroke="#838383" strokeWidth="0.24"/>
</svg>

    )
}
export default ProposeIcon