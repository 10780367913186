import React, { useState } from 'react';
import { SwipeableDrawer, Box } from '@mui/material';
import { JC_COLORS } from '../../styles/constants';

const TopDrawer = ({ children, open, onOpen, onClose }) => {
    return (
        <SwipeableDrawer
            anchor={'top'}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            PaperProps={{
                sx: {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                }
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    backgroundColor: JC_COLORS.JC_WHITE,
                    


                }}
            >
                {children}
            </Box>

        </SwipeableDrawer>
    )
}

export default TopDrawer