import React from 'react'
import { Card, styled } from "@mui/material";
import { JC_COLORS } from '../../styles/constants';


const MainCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: JC_COLORS.JC_WHITE,
    //minHeight: "500px",
    [theme.breakpoints.up('md')]: {
      //width: "73vw", // 1369 / 1920
      //maxHeight: "1203px",  // 1203 / 1377 or 1203 to rem 
      padding: "40px 40px 16px 40px",
      marginBottom: "10px",
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 1rem 0 1rem',
      minHeight: '100vh'
    }
  
  }));


const CardJCContent = ({children}) => {
  return (
    <MainCard>{children}</MainCard>
  )
}

export default CardJCContent