import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { Grid, Hidden, Box, Typography, Icon, TextField, InputAdornment, Button, CircularProgress, Toolbar, IconButton, toolbarClasses, FormControl, FormControlLabel, RadioGroup, Radio, AppBar, useTheme, capitalize, } from '@mui/material';
import { styled } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import axios from 'axios';

import JCContentTitle from '../components/JCContentTitle';
import * as api from "../../../api";
import { AuthContext } from '../../../context/AuthContext';
import { JC_COLORS } from '../../../styles/constants';
import { pxToRem } from '../../../utils';
import SquareAvatar from '../components/SquareAvatar';
import AvatarName from '../components/AvatarName';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { ArticleCardView, ArticleCardViewSkeleton, DenovoStartWritingCard, CenteredActionButton, TopDrawer } from '../../../common/components';
import connectionInstance from '../../../utils/connection';
import CustomDropdown from '../components/CustomDropdown';
import { FilterIcon, FilterIconFilled } from '../../../assets/SVG';
import { DENOVO_ARTICLE_STATUS } from '../../../constants/constants';
import { openDenovoArticleInEditMode, setDenovoLockState } from '../../../state/actions/article';
import { useSelector } from 'react-redux';
import ErrorDialogBox from '../../../common/components/ErrorDialogBox';

const StyledText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 600,
    color: JC_COLORS.JC_BLACK,
    [theme.breakpoints.up('md')]: {
        fontSize: '15px',
        lineHeight: '17.61px',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '16px',
    }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    borderRadius: '3px',
    background: 'white',
    border: `1px solid ${JC_COLORS.JC_ICON}`,
    [theme.breakpoints.up('md')]: {
        width: '33vw',
        '& .MuiInputBase-root': {
            height: '42px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
        }
    },
    [theme.breakpoints.down('md')]: {
        width: '75vw',
        '& .MuiInputBase-root': {
            height: '32px',
            padding: '0px 5px',
        },
    },

}));

const StyledSearchButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        padding: '8px 22px',
        width: '100px',
        borderRadius: '4px',
        fontFamily: 'Raleway',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '26px',

    },
    [theme.breakpoints.down('md')]: {
        textTransform: 'none',
        padding: '4px 10px',
        width: '62px',
        borderRadius: '4px',
        fontFamily: 'Raleway',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '22px',

    }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
    marginBottom: '16px',
    [theme.breakpoints.up('md')]: {
        padding: '16px 0px 54px 0px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0rem 0px 54px 0px',
    }
}));

const CenteredContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        margin: '0 auto',
        width: '600px',
        margin: "70px auto",
    },
    [theme.breakpoints.down('md')]: {
        height: '50vh',
        margin: '0 auto',
        ...theme.typography.BODY_COPY1_RALEWAY_MOBILE,
    }
}));

const OPTIONS_DENOVO_ARTICLE_FILTER = {
    all: "All Articles",
    under_discussion: 'Draft Articles',
    published: "Published Articles",
    rejected: "Rejected Articles",
};

const encodeGetParams = params => Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

const DenovoListingTab = ({ JCID, urlSlug, jcUserDetails }) => {
    const theme = useTheme();
    const [value, setValue] = useState("");
    const [filter, setFilter] = useState("all");
    const [query, setQuery] = useState('');
    const [filterDrawerOpened, setFilterDrawerOpened] = useState(false);

    const handleSearch = () => { setQuery(value); }

    return (
        <>
            <Hidden mdDown>
                <JCContentTitle title={'Original Articles'} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '24px 0px 32px 0px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',

                        }}>
                        <StyledTextField
                            value={value}
                            placeholder={"Enter Search Term"}
                            autoComplete='off'
                            inputProps={{
                                style: {
                                    fontFamily: 'Raleway',
                                    fontWeight: 600
                                }
                            }}
                            InputProps={{
                                startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <CloseIcon
                                            style={{
                                                display: `${value === "" ? 'none' : 'block'}`
                                            }}
                                            onClick={
                                                () => {
                                                    setValue("");
                                                    setQuery("");
                                                }
                                            }
                                        />
                                    </InputAdornment>
                                )
                            }}
                            onChange={
                                (e) => {
                                    let val = e.target.value;
                                    setValue(val ? val : "");
                                }
                            }
                        ></StyledTextField>
                        <StyledSearchButton
                            variant="outlined"
                            onClick={() => handleSearch()}
                            sx={{ marginLeft: '11px' }}
                        >
                            Search
                        </StyledSearchButton>
                    </Box>
                    <CustomDropdown
                        selectedLabel={OPTIONS_DENOVO_ARTICLE_FILTER[filter]}
                        listOfLabels={Object.keys(OPTIONS_DENOVO_ARTICLE_FILTER).map(el => OPTIONS_DENOVO_ARTICLE_FILTER[el])}
                        changeLabel={
                            (val) => {
                                let tempVal = val.replace(" Articles", "").toLowerCase()
                                if (tempVal === 'draft') {
                                    setFilter("under_discussion")
                                } else {
                                    setFilter(tempVal)
                                }

                            }
                        }
                    />

                </Box>

            </Hidden>
            <Hidden mdUp>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        margin: '33px 0px 17px 14px',
                    }}>
                    <StyledTextField
                        value={value}
                        placeholder={"Enter Search Term"}
                        autoComplete='off'
                        inputProps={{
                            style: {
                                fontFamily: 'Raleway',
                                fontWeight: 600,
                            }

                        }}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <CloseIcon
                                        style={{
                                            display: `${value === "" ? 'none' : 'block'}`
                                        }}
                                        onClick={
                                            () => {
                                                setValue("");
                                                setQuery("");
                                            }
                                        }
                                    />
                                </InputAdornment>),
                        }}
                        onChange={
                            (e) => {
                                let val = e.target.value;
                                setValue(val ? val : "");
                            }
                        }
                    ></StyledTextField>
                    <StyledSearchButton
                        variant="outlined"
                        onClick={() => handleSearch()}
                        sx={{ marginLeft: '11px' }}
                    >
                        Search
                    </StyledSearchButton>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: `1px solid ${JC_COLORS.JC_ICON}`
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Raleway',
                            fontSize: '10px',
                            lineHeight: '16px',
                            fontStyle: 'italic',
                            textAlign: 'left',
                            margin: '15px 0px 15px 14px',
                        }}
                    >
                        Currently showing {OPTIONS_DENOVO_ARTICLE_FILTER[filter].toLowerCase()}
                    </Typography>

                    <IconButton onClick={() => setFilterDrawerOpened(true)}>
                        <FilterIconFilled
                            width={"21"}
                            height={"21"}
                        />
                    </IconButton>

                    <TopDrawer
                        open={filterDrawerOpened}
                        onOpen={() => setFilterDrawerOpened(true)}
                        onClose={() => setFilterDrawerOpened(false)}
                    >
                        <AppBar
                            position="fixed"
                            sx={{

                                zIndex: theme.zIndex.drawer + 1,
                                bgcolor: JC_COLORS.JC_RED,
                                color: JC_COLORS.JC_WHITE,
                                display: 'block',

                            }}
                        >
                            <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="H2_BITTER" sx={{ fontWeight: 700 }}>Filter</Typography>
                                <IconButton onClick={() => setFilterDrawerOpened(false)}>
                                    <CloseIcon
                                        width={"21"}
                                        height={"21"}
                                        style={{ color: 'white' }}
                                    />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Box sx={{ margin: "80px 16px 16px 16px", height: '100vh' }}>

                            <FormControl>
                                <RadioGroup
                                    onChange={(event) => setFilter(event.target.value)}
                                    value={filter}
                                >
                                    {
                                        Object.keys(OPTIONS_DENOVO_ARTICLE_FILTER).map((el, i) => (
                                            <FormControlLabel
                                                key={i}
                                                value={el}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: "1rem",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Raleway',
                                                            fontWeight: '600',
                                                            fontSize: '0.75rem',
                                                            lineHeight: '0.875rem',
                                                            textTransform: 'capitalize',
                                                        }}
                                                    >
                                                        {OPTIONS_DENOVO_ARTICLE_FILTER[el]}
                                                    </Typography>
                                                }
                                            />
                                        ))
                                    }

                                </RadioGroup>
                            </FormControl>
                            <CenteredActionButton
                                onClick={() => setFilterDrawerOpened(false)}
                            >
                                Apply Filter
                            </CenteredActionButton>

                        </Box>

                    </TopDrawer>
                </Box>

            </Hidden>
            <DenovoArticlesList
                JCID={JCID}
                urlSlug={urlSlug}
                articleTypeLabel={filter}
                query={query}
                jcUserDetails={jcUserDetails}
            />
        </>
    )
}

export default DenovoListingTab


const fetchDenovoArticlesList = async (userEmail, pageNumber, query) => {
    return connectionInstance.get(`${api.SEARCH_PUBMED_URL}/${userEmail}/${pageNumber}?${query}`);
}

const DenovoArticlesList = ({ JCID, urlSlug, articleTypeLabel, query, jcUserDetails }) => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loadingArticles, setLoadingArticles] = useState(false);
    const [articleList, setArticleList] = useState([]);
    const [numberOfUnreadArticles, setNumberOfUnreadArticles] = useState(0);
    const [hasMoreArticles, setHasMoreArticles] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [isError, setIsError] = useState(false);

    const { jcDetails } = useSelector((state) => state.journalclub);
    const { denovoLockState } = useSelector((state) => state.article);
    const {
        isDenovoArticleLockedByCurrentUser,
        openLockStatusDialogBox,
        lockStatusMessage,
    } = denovoLockState;
    //const { isLoading, data, isError, error, isFetching } = useQuery(['search-articles-list', userEmail, pageNumber, query], () => fetchDenovoArticlesList(userEmail, pageNumber, query));
    const observer = useRef();
    
    useEffect(() => {
        let cancel;
        setLoadingArticles(true);
        setArticleList([]);
        try {
            var filterQueryParams = {
                pageNumber: 1,
                isDeNovo: true,
                searchTerm: query
            }
            
            connectionInstance.get(
                encodeURI(`${getArticlesListUrl(userEmail, JCID, articleTypeLabel)}?${encodeGetParams(filterQueryParams)}`,
                {
                    cancelToken: new axios.CancelToken(c => cancel = c),
                })
            ).then(res => {
                const { numberOfUnreadArticles, next } = res.data;
                setArticleList([...new Set([...res.data.articleList])]);
                setNumberOfUnreadArticles(numberOfUnreadArticles);
                setHasMoreArticles(next ? true : false);
                setLoadingArticles(false)
            }).catch(error => {
                console.log(error);
                if (error?.response && error?.response?.status === 401) {
                    console.log(error)
                    setIsError(true);
                }
                setArticleList([]);
                setLoadingArticles(false);
            });

        } catch (error) {
            setIsError(true);
            setArticleList([]);
            setLoadingArticles(false);
        }


    }, [JCID, query, articleTypeLabel]);

    useEffect(() => {
        let cancel;
        setLoadingArticles(true);

        try {
            var filterQueryParams = {
                pageNumber,
                isDeNovo: true,
                searchTerm: query
            }
            
            connectionInstance.get(
                encodeURI(`${getArticlesListUrl(userEmail, JCID, articleTypeLabel)}?${encodeGetParams(filterQueryParams)}`,
                {
                    cancelToken: new axios.CancelToken(c => cancel = c),
                })
            ).then(res => {
                const { numberOfUnreadArticles, next } = res.data;
                setArticleList([...new Set([...articleList, ...res.data.articleList])]);
                setNumberOfUnreadArticles(numberOfUnreadArticles);
                setHasMoreArticles(next ? true : false);
                setLoadingArticles(false)
            }).catch(error => {
                console.log(error);
                if (error?.response && error?.response?.status === 401) {
                    console.log(error)
                }
                setArticleList([]);
                setLoadingArticles(false);
            });

        } catch (error) {
            setArticleList([]);
            setLoadingArticles(false);
        }


    }, [pageNumber]);


    const lastArticleElementRef = useCallback(node => {
        if (loadingArticles) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreArticles && loadingArticles === false) {
                setPageNumber((pageNumber) => pageNumber + 1);
                if (loadingArticles) return

            }
        });
        if (node) observer.current.observe(node)
    }, [loadingArticles]);

    const createNewDenovoArticle = async () => {
        const emptyDenovoData = {
            authUserEmail: userEmail,
            title: "[Untitled]",
            abstractContent: "",
            bodyContent: "",
            authors: `${jcUserDetails?.firstName ?? ""} ${jcUserDetails?.lastName ?? ""}`,
            cmeCredits: '0'
        }
        let res = await api.postDenovoArticle(JCID, userEmail, { ...emptyDenovoData });
        let pm_id = res?.data?.pm_id;
        if (res?.status === 201 || pm_id !== null) {
            dispatch(
                setDenovoLockState({
                    isDenovoArticleLockedByCurrentUser: true,
                    openLockStatusDialogBox: false,
                    currentlyEditedBy: null,
                })
            );
            navigate(`/jc/${urlSlug}/${pm_id}`, {
                state: {
                    editIconOnDenovoCardClicked: true  // No need for dispatch(openDenovoArticleInEditMode());
                },
            });

        }

    }

    const handleRemoveArticle = (removePMID) =>{
        const tempList = articleList.filter(article => article.PMID !== removePMID)
        setArticleList(tempList)
    }

    if (loadingArticles) {
        return (
            <>
                {/* <CenteredContainer><CircularProgress /></CenteredContainer> */}
                <Grid
                    container
                    spacing={{ lg: 2, xl: 2, md: 2, xs: 0, sm: 0 }}
                >
                    <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                        onClick={() => createNewDenovoArticle()}
                    >
                        <DenovoStartWritingCard />
                    </Grid>
                    {

                        [...new Array(100).keys()].map((el) => (
                            <Grid key={el} item lg={6} xl={6} md={12} sm={12} xs={12}>
                                <ArticleCardViewSkeleton />
                            </Grid>
                        ))
                    }
                </Grid>

            </>

        )
    }
    if (isError) {

        return (
            <CenteredContainer>
                <StyledText>Error!!!</StyledText>
                <StyledText>Something's Not Right</StyledText>
            </CenteredContainer>
        )
    }

    return (

        <>
            <StyledGrid
                container
                spacing={{ lg: 2, xl: 2, md: 2, xs: 0, sm: 0 }}
            >
                <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                    onClick={() => createNewDenovoArticle()}
                >
                    <DenovoStartWritingCard />
                </Grid>
                {
                    (articleList !== undefined) && (articleList?.map((obj, index) => {
                        if (articleList.length === index + 1) {
                            return (
                                <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                                    key={obj.PMID}
                                    ref={lastArticleElementRef}
                                    onClick={() => navigate(`/jc/${urlSlug}/${obj.PMID}`)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <ArticleCardView
                                        key={obj.PMID}
                                        isLoading={loadingArticles}
                                        journalSource={obj.sourceName}
                                        journalIssue={obj.issue}
                                        showCME={obj.showCME}
                                        isSaved={obj.savedByUser}
                                        journalTitle={obj.title}
                                        authors={obj.authors}
                                        publicationDate={obj.publicationdate}
                                        keywords={obj.keywords}
                                        numberOfPractices={obj.numberOfPracticeImpacting}
                                        numberOfPracticeImpactingInUserJCs={obj.numberOfPracticeImpactingInUserJCs}
                                        numberOfLikes={obj.numberOfLikes}
                                        numberOfLikesInUserJCs={obj.numberOfLikesInUserJCs}
                                        numberOfComments={obj.numberOfComments}
                                        numberOfCommentsInUserJCs={obj.numberOfCommentsInUserJCs}
                                        numberOfNotes={0}
                                        noteAddedByUser={false}
                                        proposedBy={obj.proposedBy}
                                        numberOfSupporters={obj.numberOfSupporters}
                                        piByUser={obj.PIByUser}
                                        isLiked={obj.likedByUser}
                                        isCommented={obj.numberOfComments > 0}
                                        isRead={obj.readByUser}
                                        showJcDate={true}
                                        jcStatus={obj.status === 'Under Discussion' ? 'Started' : obj.status}
                                        jcStatusUpdatedOn={obj.statusUpdatedOn}
                                        journalClubsList={obj.journalClubs}
                                        showUnderDiscussionJCs={true}
                                        showPublishedJCs={true}
                                        JCID={JCID}
                                        PMID={obj.PMID}
                                        isDeNovo={obj.isDeNovo}
                                        isEditable={obj.isEditable}
                                        denovoStatus={obj.status}
                                        urlSlug={urlSlug}
                                        proposedByCurrentUser={obj.proposedByCurrentUser}
                                        onRemove = {() => handleRemoveArticle(obj.PMID)}
                                    />
                                </Grid>


                            )
                        } else {
                            return (
                                <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                                    key={obj.PMID}
                                    onClick={() => navigate(`/jc/${urlSlug}/${obj.PMID}`)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <ArticleCardView
                                        key={obj.PMID}
                                        isLoading={loadingArticles}
                                        journalSource={obj.sourceName}
                                        journalIssue={obj.issue}
                                        showCME={obj.showCME}
                                        isSaved={obj.savedByUser}
                                        journalTitle={obj.title}
                                        authors={obj.authors}
                                        publicationDate={obj.publicationdate}
                                        keywords={obj.keywords}
                                        numberOfPractices={obj.numberOfPracticeImpacting}
                                        numberOfPracticeImpactingInUserJCs={obj.numberOfPracticeImpactingInUserJCs}
                                        numberOfLikes={obj.numberOfLikes}
                                        numberOfLikesInUserJCs={obj.numberOfLikesInUserJCs}
                                        numberOfComments={obj.numberOfComments}
                                        numberOfCommentsInUserJCs={obj.numberOfCommentsInUserJCs}
                                        numberOfNotes={0}
                                        noteAddedByUser={false}
                                        proposedBy={obj.proposedBy}
                                        numberOfSupporters={obj.numberOfSupporters}
                                        piByUser={obj.PIByUser}
                                        isLiked={obj.likedByUser}
                                        isCommented={obj.numberOfComments > 0}
                                        isRead={obj.readByUser}
                                        showJcDate={true}
                                        jcStatus={obj.status === 'Under Discussion' ? 'Started' : obj.status}
                                        jcStatusUpdatedOn={obj.statusUpdatedOn}
                                        journalClubsList={obj.journalClubs}
                                        showUnderDiscussionJCs={true}
                                        showPublishedJCs={true}
                                        JCID={JCID}
                                        PMID={obj.PMID}
                                        isDeNovo={obj.isDeNovo}
                                        isEditable={obj.isEditable}
                                        denovoStatus={obj.status}
                                        urlSlug={urlSlug}
                                        proposedByCurrentUser={obj.proposedByCurrentUser}
                                        onRemove = {() => handleRemoveArticle(obj.PMID)}
                                    />

                                </Grid>

                            )
                        }
                    }
                    )
                    )


                }

            </StyledGrid>

            {
                (articleList?.length === 0) && (
                    <CenteredContainer>
                        <StyledText>
                            {noDevoArticlesMessage({ searchQuery: query, denovoStatusLabel: articleTypeLabel })}
                        </StyledText>
                    </CenteredContainer>
                )
            }
            <ErrorDialogBox
                open={openLockStatusDialogBox}
                closeDialogHandler={() => {
                    dispatch(
                        setDenovoLockState({
                            openLockStatusDialogBox: false,
                        })
                    );
                }}
                title={"Article Lock Status"}
            >
                <Typography
                    sx={{
                        ...theme.typography.H3_RALEWAY,
                        fontWeight: 500,
                        marginTop: "16px",
                    }}
                >
                    {lockStatusMessage}
                </Typography>
            </ErrorDialogBox>

        </>
    )

}

function noDevoArticlesMessage({ searchQuery, denovoStatusLabel }) {
    if (denovoStatusLabel === "all") {
        if (searchQuery === "") {
            return `There are no articles.`;
        } else {
            return `There are no articles for your search criteria.`
        }
    } else if (denovoStatusLabel === "under_discussion") {
        if (searchQuery === "") {
            return `There are no draft articles.`;
        } else {
            return `There are no draft articles for your search criteria.`
        }
    } else {
        if (searchQuery === "") {
            return `There are no ${denovoStatusLabel ?? ""} articles.`;
        } else {
            return `There are no ${denovoStatusLabel ?? ""} articles for your search criteria.`
        }
    }

}

function getArticlesListUrl(userEmail, JCID, articleTypeLabel) {
    let label;
    if (articleTypeLabel === 'Under Review') {
        label = 'under_discussion';
    } else if (articleTypeLabel === 'Recent Activities') {
        label = 'all'
    } else if (articleTypeLabel === 'Rejected') {
        label = 'rejected'
    } else if (articleTypeLabel === 'Published') {
        label = 'published'
    }
    else {
        label = articleTypeLabel.toLowerCase();
    }

    const JC_ARTICLES_LIST_API = `${process.env.REACT_APP_API_URL}/jc/${JCID}/${userEmail}/articles/${label}`

    return JC_ARTICLES_LIST_API
}

const _numberOfArticlesText = (number) => {
    let text = "results found";
    if (number === 0) {
        return <Typography></Typography>
    }
    if (number === 1) {
        text = "result found"
    }
    return <Typography
        sx={{
            fontFamily: 'Raleway',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '36px'
        }}>{`${number} ${text}`}</Typography>

}


