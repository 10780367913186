const JC_COLORS = {
    JC_RED: "#EF5B50",
    JC_BLACK: "#262626",
    JC_WHITE: "#FFFFFF",
    JC_ICON: "#838383",
    LIGHT_GREY: "#F3F3F3",
    PUBLISHED_GREEN: "#4CAD00",
    REJECTED_RED: "#C31306"
};

const JC_ICON = {
    border: "1px solid #838383",
    boxSizing: "border-box",
    borderRadius: "4px",
};

const FONT_FAMILY_BITTER = {
    fontFamily: "Bitter"
};

const FONT_FAMILY_RALEWAY = {
    fontFamily: 'Raleway'
};

const JC_DESKTOP_FONT = {
    BITTER: {
        H1: {
            fontSize : "30px",
            lineHeight: "40px"
        },
        H2: {
            fontSize: "24px",
            lineHeight: "32px"
        },
        H3: {
            fontSize: "20px",
            lineHeight: "28px"
        },
    },
    RALEWAY: {

        H1: {
            fontSize: "40px",
            lineHeight: "54px"
        },
        H2: {
            fontSize: "24px",
            lineHeight: "36px"
        },
        H3: {
            fontSize: "18px",
            lineHeight: "26px"
        },
        BODY_COPY1: {
            fontSize: "24px",
            lineHeight: "36px"

        },
        BODY_COPY2: {
            fontSize: "15px",
            lineHeight: "20px"
        },
        BUTTON_MEDIUM: {
            fontSize: "18px"
        },
        BUTTON_SMALL: {
            fontSize: "15px"
        }
    }

};

const JC_MOBILE_FONT = {
    BITTER: {
        H1: {
            fontSize: "18px",
            lineHeight: "24px"
        },
        H2: {
            fontSize: "14px",
            lineHeight: "19px"
        },
        H3: {
            fontSize: "12px",
            lineHeight: "16px"
        },
        BODY_COPY2: {
            fontSize: "10px",
            lineHeight: "14px"
        },
    },
    RALEWAY: {
        BODY_COPY1: {
            fontSize: "12px",
            lineHeight: "18px"

        },
        BODY_COPY2: {
            fontSize: "10px",
            lineHeight: "14px"
        },
        BODY_COPY3: {
            fontSize: "8px",
        },
        BUTTON_SMALL: {
            fontSize: "15px"
        }
    }
};

const JC_ICONS_COLORS_LIST = [
    '#FFB800', 
    '#0075FF',
    '#FF0000', 
    '#AD00FF', 
    '#FF00E5', 
    '#626262', 
    '#35A600', 
    '#FF7A00', 
    '#8C0202', 
    '#299863', 
    '#02398C', 
    '#00CBB3', 
    '#900068', 
    '#000000', 
    '#B29F6D', 
    '#67A1C2',  
];

export { JC_COLORS, JC_ICON, JC_DESKTOP_FONT, JC_MOBILE_FONT, FONT_FAMILY_BITTER, FONT_FAMILY_RALEWAY, JC_ICONS_COLORS_LIST };
