import axios from 'axios';
import axiosRetry from 'axios-retry';
import { logout } from '.';
import { token_name_list } from '../constants/constants';
import { getToken, setToken, removeTokenFromStorage } from './authToken';
import { generateAWSCognitoIdentity, generateSignedRequest,reGenerateAWSCognitoIdentity } from './awsCognitoUtils';

const tokenUrl = `${process.env.REACT_APP_AWS_DOMAIN}/oauth2/token`;
const ERROR_MSG = "The security token included in the request is expired";
const ERROR_MSG_INVALID = "The security token included in the request is invalid.";
const ERROR_MSG_INVALID2 = "The security token included in the request is invalid";

const connectionInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin' : '*',
  },
});

export const getNewToken = async () => {
  try {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', getToken(token_name_list.REFRESH_TOKEN));
    params.append('client_id', process.env.REACT_APP_AWS_COGNITO_CLIENT_ID);
    const res = await axios.post(tokenUrl, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
    if (res && res.data) {
      setToken(token_name_list.ACCESS_TOKEN, res.data.access_token);
      setToken(token_name_list.ID_TOKEN, res.data.id_token);
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};


connectionInstance.interceptors.request.use( // set request interceptors before sending request
  (config) => {
    //const idToken = getToken(token_name_list.ID_TOKEN);
    let request = {
      method: config.method.toUpperCase(),
      url: config.url,
      data: config.data,
    }
    if(config.method.toUpperCase() === "POST" || config.method.toUpperCase() === "PATCH"){
      request = {
        ...request,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }
    }
    let signedRequest = generateSignedRequest(request);
    config.headers = { ...signedRequest.headers }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

connectionInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if(!error){
      return Promise.reject(error);
    }
    if(axiosRetry.isRetryableError(error)) {
      if(error.config.url.includes("userFeed")){ 
        error.config.retry = error.config.retry ? error.config.retry + 1 : 1
        if(error.config.retry <= 3){
          return retryRequest(error.config)
        }else if(window.location.pathname === "/feed"){
          logout()
        }
      }
      return Promise.reject(error);
    }

    if (error && error.response.status !== 401 && error.response.status !== 403) {
      // Rejecting other than 401 and 403
      return Promise.reject(error);
    }
    
    if (error && error.response.status === 401 && error.config.url === tokenUrl) {
      // Note: If Refesh token expires take User to login page and regenerate tokens
      logout()
      return Promise.reject(error);
    }

    if (error.response.status === 403 && ![ERROR_MSG,ERROR_MSG_INVALID,ERROR_MSG_INVALID2].includes(error.response.data.message)){
      // Rejecting 403 for other reasons
      return Promise.reject(error);
    }

    if (error.response.status === 403 && error.config && !error.config.__isRetryRequest){
      return getAuthToken().then(response => {
        let request = {
          method: error.config.method.toUpperCase(),
          url: error.config.url,
          data: error.config.data,
        }
        if(error.config.method.toUpperCase() === "POST" || error.config.method.toUpperCase() === "PATCH"){
          request = {
            ...request,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        }
        let signedRequest = generateSignedRequest(request);
        error.config.headers = { ...signedRequest.headers }
        error.config.__isRetryRequest = true;
        return retryRequest(error.config);
      });
    }

    try {
      // Generate New token
      const tokenResponse = await getNewToken();
      if (tokenResponse.data.id_token) {
        const { config } = error;
        const res = await reGenerateAWSCognitoIdentity(true)
        let request = {
          method: config.method.toUpperCase(),
          url: config.url,
          data: config.data,
        }
        if(config.method.toUpperCase() === "POST" || config.method.toUpperCase() === "PATCH"){
          request = {
            ...request,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          }
        }
        let signedRequest = generateSignedRequest(request);
        config.headers = { ...signedRequest.headers }
        return await axios.request(config);
      }
      // Logout if tokenResponse doesn't  contain id_token
      logout()
      return Promise.reject(error);
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

export default connectionInstance;

let authTokenRequest;

// This function makes a call to get the auth token
// or it returns the same promise as an in-progress call to get the auth token
function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = reGenerateAWSCognitoIdentity(true);
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }
  return authTokenRequest;
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

// retry request
const retryRequest = config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      connectionInstance(config)
        .then(resolve)
        .catch(reject);
    }, 100)
  })
}



