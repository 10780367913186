import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { JC_COLORS } from '../../../styles/constants';
import { styled } from '@mui/material/styles';
import { Box, Button, Hidden, Tabs, tabsClasses, Typography } from "@mui/material";
import { pxToRem } from '../../../utils';
import { setJCContent, SET_JC_CONTENT } from '../../../state/actions/journalclub';
import { JC_CONTENT, JC_TABS, MEMBERSHIP_STATUS } from '../../../constants/constants';
import { USER_ROLES } from '../../../constants/constants';
import { UserDataContext } from '../../../context/UserDataContext';
import { getUserDetails } from '../../../state/actions/user';
import { AuthContext } from '../../../context/AuthContext';


const StyledButton = styled(Button)(({ theme, selected }) => ({
    cursor: 'pointer',
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    border: `1.5px solid ${JC_COLORS.JC_RED}`,

    backgroundColor: `${selected ? JC_COLORS.JC_RED : JC_COLORS.JC_WHITE}`,
    color: `${selected ? JC_COLORS.JC_WHITE : JC_COLORS.JC_RED}`,
    "&:hover": {
        backgroundColor: `${selected ? JC_COLORS.JC_RED : JC_COLORS.JC_WHITE}`,
        //color: `${selected ? JC_COLORS.JC_WHITE : JC_COLORS.JC_RED}`,
    },
    ...theme.typography.H3_RALEWAY,
    fontWeight: 700,
    [theme.breakpoints.up('lg')]: {
        marginRight: '12px',
    },
    [theme.breakpoints.up('md')]: {
        padding: '6px 12px',
        lineHeight: pxToRem(18),
        width: 'fit-content',
        minWidth: 'min-content',
        //height: 'fit-content',   
        // minHeight: 'min-content', 
        display: 'inline-block',
        margin: "6px 12px 6px 0px",
        whiteSpace: 'nowrap',
        overflow: 'visible',
    },
    [theme.breakpoints.down('md')]: {
        display: 'inline-block',
        minWidth: '162px',
        height: '28px',
        padding: '4px',
        marginRight: '7px',
        //minWidth: '150px',
        //fontSize: pxToRem(12),
        //lineHeight: pxToRem(20),
    }

}));


const TabsList = styled(Tabs)(({ theme }) => ({
    [`& .${tabsClasses.scrollButtons}`]: {
        '&.Mui-disabled': { opacity: 0.3 },

    },
    '& .MuiTabs-indicator': { backgroundColor: "#FFFFFF" },

    [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: "100%",
        // "*": {
        //     marginRight: "5px",
        //     marginBottom: "3px",
        // }

    },
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        padding: '4px 14px',

    }

}));

const JCActions = () => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const dispatch = useDispatch();
    const { journalclub, user } = useSelector((state) => state);
    const { jcDetails, jcContent, jcUserDetails } = journalclub;
    const { userDetails } = user;

    // const { isLoading, data, isError, error, isFetching } = useContext(UserDataContext);
    // const { superAdmin } = data?.data; 

    const location = useLocation();
    const [actions, setActions] = useState([]);
    const [selectedAction, setSelectedAction] = useState(JC_CONTENT.DEFAULT);

    useEffect(() => {
        dispatch(getUserDetails(userEmail))
    }, []);

    const setAndDispatchAction = (action) => {
        setSelectedAction(action)
        dispatch(setJCContent(action))
    }


    useEffect(() => {

        let membershipStatus = jcDetails?.membershipStatus;
        let isJcPublic = jcDetails?.isPublic;
        let userType = jcDetails?.currentUser;

        // USER TYPES
        const userIsAdmin = (
            userType === USER_ROLES.ADMINISTRATOR
        );
        const userIsCourseDirector = (
            userType === USER_ROLES.COURSE_DIRECTOR
        );
        const userIsContributor = (
            userType === USER_ROLES.CONTRIBUTOR
        );
        const userIsReader = (
            userType === USER_ROLES.READER
        )


        // MEMBERSHIP TYPES
        const isMember = (membershipStatus === MEMBERSHIP_STATUS.ADD || membershipStatus === MEMBERSHIP_STATUS.JOINED)
        const memberInvited = membershipStatus === MEMBERSHIP_STATUS.INVITED
        const membershipRequested = (membershipStatus === MEMBERSHIP_STATUS.REQUESTED)
        const memberRejectedInvite = (membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED)
        const membershipRejected = (membershipStatus === MEMBERSHIP_STATUS.REQUEST_REJECTED)
        const memberRemoved = (membershipStatus === MEMBERSHIP_STATUS.REMOVED)
        const memberLeft = (membershipStatus === MEMBERSHIP_STATUS.LEFT)

        let {
            UNDER_DISCUSSION,
            PUBLISHED,
            CONTRIBUTORS,
            SEND_EMAIL,
            DE_NOVO_ARTICLES,
            REPORTS,
            MANAGE,
        } = JC_CONTENT

        if (userDetails?.superAdmin) {
            setActions([UNDER_DISCUSSION, PUBLISHED, DE_NOVO_ARTICLES, SEND_EMAIL, MANAGE, REPORTS, CONTRIBUTORS]);
        } else {
            if (memberRemoved || membershipRejected) {
                setActions([CONTRIBUTORS]);
            } else if (memberInvited || membershipRequested || memberRejectedInvite || memberLeft) {
                setActions([PUBLISHED, CONTRIBUTORS]);
            } else if (isMember && userIsReader) {
                setActions([PUBLISHED, CONTRIBUTORS]);
            } else if (isMember && userIsContributor) {
                setActions([UNDER_DISCUSSION, PUBLISHED, DE_NOVO_ARTICLES, CONTRIBUTORS]);
            } else if (isMember && userIsCourseDirector) {
                setActions([UNDER_DISCUSSION, PUBLISHED, DE_NOVO_ARTICLES, SEND_EMAIL, MANAGE, REPORTS, CONTRIBUTORS]);
            } else if (isMember && userIsAdmin) {
                setActions([UNDER_DISCUSSION, PUBLISHED, DE_NOVO_ARTICLES, SEND_EMAIL, MANAGE, REPORTS, CONTRIBUTORS]);
            } else {
                setActions([CONTRIBUTORS]);
            }

        }

        // Default selected

        if (
            (jcContent === JC_CONTENT.DEFAULT || jcContent === "") &&
            (membershipStatus)
        ) {
            if(userDetails?.superAdmin) {
                setAndDispatchAction(MANAGE); 
            } else if (memberInvited) {
                setAndDispatchAction(PUBLISHED)
            } else if (isMember && userIsReader) {
                setAndDispatchAction(PUBLISHED)
            } else if (isMember && userIsContributor) {
                setAndDispatchAction(UNDER_DISCUSSION)
            } else if (isMember && userIsCourseDirector) {
                setAndDispatchAction(UNDER_DISCUSSION)
            } else if (isMember && userIsAdmin) {
                setAndDispatchAction(REPORTS)
            } else if (memberRemoved || memberLeft || memberRejectedInvite) {
                setAndDispatchAction(CONTRIBUTORS)
            } else if (membershipRejected) {
                setAndDispatchAction(CONTRIBUTORS)
            } else {
                setAndDispatchAction(CONTRIBUTORS);
            }
        }

    }, [jcDetails, jcContent, userDetails]);

   


    if (actions === []) {
        return <></>
    }

    return (
        <>
            <Hidden mdDown>
                {/* {isContainerOverflow ? "yes" : "no"} */}
                {
                    (actions.length === 0) && (
                        <Box></Box>
                    )
                }
                {
                    (actions.length > 0 && actions.length <= 4) && (
                        <Box
                            sx={{
                                bgcolor: JC_COLORS.LIGHT_GREY,
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: { md: 'flex-start', lg: 'flex-start', xl: 'flex-start' },
                                padding: '11px 0px 11px 11px',
                                borderRadius: '4px',
                                width: "100%",
                            }}>

                            {
                                actions.map(action => (
                                    <StyledButton
                                        key={action}
                                        onClick={
                                            () => {
                                                setAndDispatchAction(action)

                                            }
                                        }
                                        variant={jcContent === action ? "contained" : "outlined"}
                                        selected={jcContent === action}

                                    >
                                        {action}
                                    </StyledButton>)
                                )
                            }

                        </Box>
                    )
                }
                {
                    (actions?.length > 4) && (
                        <TabsList
                            value={actions.indexOf(jcContent)}
                            variant="scrollable"
                            scrollButtons={true}
                            sx={{
                                bgcolor: JC_COLORS.LIGHT_GREY,
                                padding: '11px 0px',
                                borderRadius: '4px',
                            }}

                        >
                            {
                                actions.map(action => (
                                    <StyledButton
                                        key={action}
                                        onClick={
                                            () => {
                                                setAndDispatchAction(action)

                                            }
                                        }
                                        selected={jcContent === action}
                                    >
                                        {action}
                                    </StyledButton>)
                                )
                            }

                        </TabsList>
                    )
                }



            </Hidden>
            <Hidden mdUp>
                <TabsList
                    value={actions.indexOf(jcContent)}
                    variant="scrollable"
                    scrollButtons={true}

                >
                    {
                        actions.map(action => (
                            <StyledButton
                                key={action}
                                onClick={
                                    () => {
                                        setAndDispatchAction(action)

                                    }
                                }
                                selected={jcContent === action}
                            >
                                {action}
                            </StyledButton>)
                        )
                    }


                </TabsList>

            </Hidden>
        </>

    )
}

export default JCActions