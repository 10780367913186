import React, { useEffect, useState, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Typography, Skeleton, Hidden, Paper, IconButton, Card, Button, Chip, List, Tabs, Grid, AvatarGroup, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, useTheme } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';
import moment from 'moment';

import Row from "./Row";
import KeyWordChip from './KeyWordChip';
import Spacer from "./Spacer";
import JC_Avatar from './JC_Avatar';
import { AuthContext } from '../../context/AuthContext';

import { JC_COLORS, JC_ICON } from '../../styles/constants';
import { calcRem, calcPublishedAgo } from '../../utils';
import * as api from '../../api';


// import SaveIcon from "../../assets/SaveIcon";
// import LikeIcon from "../../assets/LikeIcon";
// import { LikedIcon } from "../../assets";
import PracticeIcon from '../../assets/PracticeIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  SaveIcon,
  SaveIconFilled,
  PIIcon,
  PIIconFilled,
  LikeIcon,
  LikeIconFilled,
  CommentIcon,
  CommentIconFilled,
  NoteIcon,
  NoteIconFilled,
  NewArticleIcon,
} from "../../assets/SVG";
import { DENOVO_ARTICLE_STATUS, USER_ROLES } from '../../constants/constants';
import { acquireLockAndEditDenovoArticle, openDenovoArticleInEditMode } from '../../state/actions/article';


const MainCard = styled(Card)(({ theme, isread }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: 0,
  backgroundColor: `${isread === 'true' ? "#F7F7F7" : JC_COLORS.JC_WHITE}`,
  opacity: `${isread === 'true' ? 0.7 : 1}`,
  [theme.breakpoints.up('md')]: {
    //maxWidth: "620px",
    minWidth: "50%",
    marginRight: "0px",
    minHeight: "288px",
    ...JC_ICON,
    //filter: "drop-shadow(4px 8px 10px rgba(0, 0, 0, 0.25))",
    padding: "20px 0px 20px 32px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // marginTop: "32px",
    // marginRight: "32px",

  },
  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
    //borderTop: "1px solid #838383", 
    borderBottom: "1px solid #838383",
    width: "100%",
    padding: `${isread === 'true' ? '18px 3px' : '18px 0px'}`,
    // marginRight: `10px`,  

  }
}));


const CME_ICON = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontWeight: 'bold',
  backgroundColor: JC_COLORS.JC_RED,
  color: JC_COLORS.JC_WHITE,
  borderRadius: '3px',
  textAlign: 'center',
  verticalAlign: 'middle',
  padding: '4px 6px',
  [theme.breakpoints.up('md')]: {
    fontSize: '10px',
    lineHeight: '11.74px',
    marginRight: '15px',
  },
  [theme.breakpoints.down('md')]: {

    fontSize: '8px',
    lineHeight: '9.39px',
    marginRight: '12.75px',
  }

}));

const JournalSourceRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    paddingRight: "26px",
  },
  [theme.breakpoints.down('md')]: {
    paddingRight: "0px",
  }
}));

const JournalTitle = styled(Box)(({ theme }) => ({

  textAlign: 'left',
  fontWeight: '600',
  fontFamily: 'BITTER',
  [theme.breakpoints.up('md')]: {
    paddingRight: "26px",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    fontSize: '1.2rem',
    lineHeight: '1.7rem',
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // "&:hover": {
    //   display: 'block',

    // }
  },
  [theme.breakpoints.down('md')]: {
    marginTop: "8px",
    marginBottom: "8px",
    "*": {
      fontSize: '1rem',
      lineHight: '1.5rem',
    }

  }

}));

const JournalSource = styled(Typography)(({ theme }) => ({

  textAlign: 'left',
  fontWeight: '600',
  textDecoration: 'underline',
  display: "-webkit-inline-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  [theme.breakpoints.up('md')]: {

    //width: '80%',

  },
  [theme.breakpoints.down('md')]: {
    marginTop: "8px",
    marginBottom: "8px",


  }

}));

const AuthorsRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.up('md')]: {
    borderTop: '2px solid #E1E1E1',
    paddingTop: '10px',
    marginRight: '37.71px',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: "10px",
    marginTop: "8px",
  }
}));

const KeywordsContainer = styled(Box)(({ theme }) => ({

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'hidden',
  height: "32px",
  "& > *": {
    marginRight: "7px",
    alignItems: 'center',
    boxShadow: 'none',
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '26px',
    marginBottom: '19px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '11px',
    marginBottom: '11px',
  }
}))

const ActionsContainer = styled(Box)(({ theme }) => ({
  //maxWidth: '60%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  "& > *": {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    //marginRight: '25px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: "5px",
    marginBottom: "10px",
  }
}));

const StyledKeyword = styled(Button)(({ theme }) => ({
  backgroundColor: JC_COLORS.LIGHT_GREY,
  [theme.breakpoints.up('md')]: {
    marginLeft: '5px',
    fontFamily: 'Raleway',
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    padding: '8px 0px 4px 0px',
  },
  [theme.breakpoints.down('md')]: {
    height: '20px',
    marginLeft: '5px',
    fontFamily: 'Raleway',
    fontWeight: '600',
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    marginBottom: '10px',
  }

}));

const RestOfKeywordsText = styled(Typography)(({ theme }) => ({

  ...theme.typography.BODY_COPY2_RALEWAY,
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    marginTop: '10px',
    marginLeft: '10px',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: "10px",
    marginLeft: '5px',

  }
}));

const IconText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  color: JC_COLORS.JC_ICON,
  [theme.breakpoints.up('md')]: {
    // marginTop: "6px",
    // marginLeft: "6px",
    fontSize: "12px",
    lineHeight: "14px",
    marginRight: '8px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: "10px",
    lineHeight: "11.74px",
    // marginTop: "2px",
    // marginLeft: "2px",
  }
}));

const ProposedByText = styled(Typography)(({ theme }) => ({
  backgroundColor: JC_COLORS.JC_WHITE,
  boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)`,
  borderRadius: '4px',
  ...theme.typography.BODY_COPY2_RALEWAY,
  fontWeight: 600,
  display: 'inline-block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    color: JC_COLORS.JC_ICON,
    padding: '4px 8px',
  },
  [theme.breakpoints.down('md')]: {
    color: JC_COLORS.JC_BLACK,
    backgroundColor: '#FFF7F6',
    padding: '4px 10px',
    maxHeight: '50px',
  }

}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    //color: '#bdbdbd',
    color: '#838383',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    //backgroundColor: '#bdbdbd',
    backgroundColor: '#838383'
  },

}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& .MuiDialogContent-root': {
      padding: "25px",
    },
    '& .MuiDialogActions-root': {
      padding: "25px",
    },
    '& .MuiDialog-paper': {
      width: '70%',
      //height: '68%'
    }
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
      width: '80%',
      height: 'auto',
    }
  },

}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontWeight: 700,
  textTransform: 'none',
  width: 'max-content',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '26px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    lineHeight: '16px',
  }
}));

const StyledDraftLabel = styled(Typography)(({ theme }) => ({
  backgroundColor: JC_COLORS.JC_RED,
  color: JC_COLORS.JC_WHITE,
  fontFamily: 'Raleway',
  fontWeight: '700',
  borderRadius: '2px',
  textTransform: 'uppercase',
  height: 'max-content',
  [theme.breakpoints.up('md')]: {
    fontSize: '15px',
    lineHeight: '18px',
    padding: '2px 9px',
    marginRight: '32px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '10px',
    lineHeight: '11.74px',
    padding: '3px 4px',
  }
}));




const ICON_DIMENSIONS = {
  MOBILE: {
    width: 24,
    height: 24,
  }
}

const PI_ICON_DIMENSIONS = {
  DESKTOP: {
    width: 24,
    height: 24
  },
  MOBILE: {
    width: 24,
    height: 24,
  }
}

const LIKE_ICON_DIMENSIONS = {
  DESKTOP: {
    width: 20,
    height: 20
  },
  MOBILE: {
    width: 24,
    height: 24,
  }
}

const COMMENT_ICON_DIMENSIONS = {
  DESKTOP: {
    width: 20,
    height: 20
  },
  MOBILE: {
    width: 24,
    height: 24,
  }
}

const NOTES_ICON_DIMENSIONS = {
  DESKTOP: {
    width: 20,
    height: 20
  },
  MOBILE: {
    width: 24,
    height: 24,
  }
}

const SAVE_ICON_DIMENSIONS = {
  DESKTOP: {
    width: 17,
    height: 24
  },
  MOBILE: {
    width: 24,
    height: 24,
  }
}

const EDIT_ICON_DIMENSIONS = {
  DESKTOP: {
    width: 17,
    height: 24,
  },
  MOBILE: {
    width: 24,
    height: 24,
  }
}

const DELETE_ICON_DIMENSIONS = {
  DESKTOP: {
    width: 17,
    height: 24,
  },
  MOBILE: {
    width: 24,
    height: 24,
  }
}

function ArticleCardView({
  journalSource,
  journalIssue,
  showCME,
  isSaved,
  journalTitle,
  publicationDate,
  authors = [],
  keywords = [],
  numberOfPractices,
  numberOfPracticeImpactingInUserJCs,
  numberOfLikes,
  numberOfLikesInUserJCs,
  numberOfComments,
  numberOfCommentsInUserJCs,
  numberOfNotes,
  piByUser,
  isLiked,
  isCommented,
  isRead,
  noteAddedByUser,
  proposedBy,
  numberOfSupporters,
  showJcDate = false,
  jcStatus,
  jcStatusUpdatedOn,
  journalClubsList = [],
  showPublishedJCs = true,
  showUnderDiscussionJCs = false,
  newCommentAdded = false,
  newNoteAdded = false,
  PMID,
  JCID,
  urlSlug,
  isDeNovo,
  isEditable,
  denovoStatus,
  proposedByCurrentUser,
  onRemove = null
}) {

  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isTitleOverflow, setIsTitleOverflow] = useState(undefined);
  const [isSourceOverflow, setIsSourceOverflow] = useState(undefined);
  const titleOverflowRef = useRef();
  const sourceOverflowRef = useRef();

  const [saveArticle, setSaveArticle] = useState(isSaved);
  const [pIArticle, setPIArticle] = useState(piByUser);
  const [numOfPI, setNumOfPI] = useState(numberOfPractices);
  const [numOfPIinUserJCs, setNumOfPIinUserJCs] = useState(numberOfPracticeImpactingInUserJCs);
  const [likeArticle, setLikeArticle] = useState(isLiked);
  const [numOfLikes, setNumOfLikes] = useState(numberOfLikes);
  const [numOfLikesinUserJCs, setNumOfLikesinUserJCs] = useState(numberOfLikesInUserJCs);

  const [openDeleteDenovoDialogBox, setOpenDeleteDenovoDialogBox] = useState(false);

  const { journalclub } = useSelector((state) => state)
  const { jcUserDetails } = journalclub;

  useEffect(() => {
    setSaveArticle(isSaved);
    setPIArticle(piByUser);
    setNumOfPI(numberOfPractices);
    setNumOfPIinUserJCs(numberOfPracticeImpactingInUserJCs)
    setLikeArticle(isLiked);
    setNumOfLikes(numberOfLikes);
    setNumOfLikesinUserJCs(numberOfLikesInUserJCs);

  }, [isSaved, piByUser, numberOfPractices, numberOfPracticeImpactingInUserJCs, isLiked, numberOfLikes, numberOfLikesInUserJCs])


  useEffect(() => {
    if (titleOverflowRef.current.scrollHeight > titleOverflowRef.current.clientHeight) {
      setIsTitleOverflow(true);
    } else {
      setIsTitleOverflow(false);
    }
  }, [titleOverflowRef]);

  useEffect(() => {
    if (sourceOverflowRef.current.scrollHeight > sourceOverflowRef.current.clientHeight) {
      setIsSourceOverflow(true);
    } else {
      setIsSourceOverflow(false);
    }
  }, [sourceOverflowRef]);


  async function handleIconClick({ event, PMID, JCID = "J00000000", action }) {
    event.stopPropagation();

    try {
      let res = await api.postArticleActions(PMID, userEmail, JCID, action)
      let {
        savedByUser,
        PIByUser,
        numberOfPracticeImpacting,
        numberOfPracticeImpactingInUserJCs,
        likedByUser,
        numberOfLikes,
        numberOfLikesInUserJCs
      } = res?.data?.message
      setSaveArticle(savedByUser)
      setPIArticle(PIByUser);
      setNumOfPI(numberOfPracticeImpacting);
      setNumOfPIinUserJCs(numberOfPracticeImpactingInUserJCs);
      setLikeArticle(likedByUser);
      setNumOfLikes(numberOfLikes);
      setNumOfLikesinUserJCs(numberOfLikesInUserJCs);

    } catch (error) {
      console.log(error)
    }
  }




  function handleDeleteDenovoIconClick(event) {
    event.stopPropagation();
    if (isDeNovo && isEditable) {
      setOpenDeleteDenovoDialogBox(true);
    }

  }

  function handleEditDenovoIconClick(event) {
    event.stopPropagation();
    if (isDeNovo && isEditable) {
      // dispatch(openDenovoArticleInEditMode());
      dispatch(
        acquireLockAndEditDenovoArticle({
          JCID: JCID,
          PMID: PMID,
          userEmail,
          nextAction: () => navigate(`/jc/${urlSlug}/${PMID}`,
            {
              state: {
                editIconOnDenovoCardClicked: true
              },
            },
          )
        })
      );


    }

  }

  const handleCancelDeleteDenovo = (event) => {
    event.stopPropagation();
    setOpenDeleteDenovoDialogBox(false);
  }
  const handleConfirmDeleteDenovo = (event) => {
    event.stopPropagation();
    dispatch(
      acquireLockAndEditDenovoArticle({
        JCID: JCID,
        PMID: PMID,
        userEmail,
        nextAction: () => {
          api.deleteDenovoArticle(PMID,userEmail).then(res => {
            setOpenDeleteDenovoDialogBox(false);
            onRemove()
          }).catch((error)=>{
            console.warn(error)
          }) 
        }
      })
    );
       
  }




  return (
    <>

      <MainCard elevation={0} isread={isRead.toString() ?? false}>

        {
          (proposedBy !== undefined && numberOfSupporters != undefined) && (
            <>
              <Hidden mdDown>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginRight: '38px'
                  }}
                >
                  <ProposedByText
                    sx={{
                      textOverflow: "ellipsis",
                      maxWidth: '150px',
                    }}
                  >
                    {
                      `${(isDeNovo && isEditable) ? "Started" : "Proposed"} by ${proposedBy?.firstName ?? ""} ${proposedBy?.lastName ?? ""}`}
                  </ProposedByText>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                    <ProposedByText>
                      Supported by {`${numberOfSupporters} ${numberOfSupporters > 1 ? 'peers' : 'peer'}`}
                    </ProposedByText>

                  </Box>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    height: '32px',
                    //margin: '0px 0px 24px 0px', 
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': { height: 0, }
                  }}
                >
                  <ProposedByText
                    sx={{
                      marginRight: '10px',
                      overflow: 'visible',
                    }}
                  >
                    {`${(isDeNovo && isEditable) ? "Started" : "Proposed"} by ${proposedBy?.firstName ?? ""} ${proposedBy?.lastName ?? ""}`}
                  </ProposedByText>
                  <ProposedByText sx={{ overflow: 'visible' }}>
                    Supported by {`${numberOfSupporters} ${numberOfSupporters > 1 ? 'peers' : 'peer'}`}
                  </ProposedByText>
                </Box>
              </Hidden>

            </>

          )}


        <JournalSourceRow>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}

          >
            <BootstrapTooltip placement="top-start" title={isSourceOverflow ? `${journalSource} ${journalIssue ? `(${journalIssue})` : ''}` : ""}>
              <JournalSource
                variant="BODY_COPY2_RALEWAY"
                ref={sourceOverflowRef}
              >

                {journalSource} {journalIssue ? ` (${journalIssue})` : ''}
              </JournalSource>
            </BootstrapTooltip>
          </Box>

          <Row sx={{ marginTop: '0px', justifyContent: 'center', marginLeft: '4px' }}>
            <CME_ICON sx={{ display: showCME ? 'block' : 'none' }}>CME</CME_ICON>
            <Hidden mdDown>
              {
                (
                  isDeNovo &&
                  isEditable &&
                  denovoStatus !== null &&
                  denovoStatus === DENOVO_ARTICLE_STATUS.DRAFT
                ) ? (
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <IconButton
                      onClick={(event) => handleEditDenovoIconClick(event)}
                    >
                      <EditIcon
                        style={{
                          color: JC_COLORS.JC_RED
                        }}
                      />
                    </IconButton>
                    { jcUserDetails && (jcUserDetails.role === USER_ROLES.COURSE_DIRECTOR || 
                      jcUserDetails.role === USER_ROLES.ADMINISTRATOR || proposedByCurrentUser ) && (
                    <IconButton
                      onClick={(event) => handleDeleteDenovoIconClick(event)}
                    >
                      <DeleteIcon
                        style={{
                          color: JC_COLORS.JC_RED
                        }}
                      />
                    </IconButton>
                    )}
                  </Box>
                ) : (
                  <>
                    {
                      saveArticle && (
                        <IconButton
                          onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'unsave' })}
                        >
                          <SaveIconFilled width={SAVE_ICON_DIMENSIONS.DESKTOP.width} height={SAVE_ICON_DIMENSIONS.DESKTOP.height} />
                        </IconButton>

                      )
                    }
                    {
                      !saveArticle && (
                        <IconButton
                          onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'save' })}
                          size="small"
                        >
                          <SaveIcon width={SAVE_ICON_DIMENSIONS.DESKTOP.width} height={SAVE_ICON_DIMENSIONS.DESKTOP.height} />

                        </IconButton>

                      )
                    }
                  </>
                )
              }

            </Hidden>
          </Row>
          <Hidden mdUp>
            <></>
            {
              (
                isDeNovo &&
                isEditable &&
                denovoStatus !== null &&
                denovoStatus === DENOVO_ARTICLE_STATUS.DRAFT
              ) ? (
                <></>
              ) : (
                <Box sx={{ marginRight: "5px" }}>
                  {
                    saveArticle && (
                      <IconButton
                        size="small"
                        onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'unsave' })}
                      >
                        <SaveIconFilled width={ICON_DIMENSIONS.MOBILE.width} height={ICON_DIMENSIONS.MOBILE.height} />
                      </IconButton>
                    )
                  }
                  {
                    !saveArticle && (
                      <IconButton
                        size="small"
                        onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'save' })}
                      >
                        <SaveIcon width={ICON_DIMENSIONS.MOBILE.width} height={ICON_DIMENSIONS.MOBILE.height} />
                      </IconButton>
                    )
                  }
                </Box>
              )
            }
            {
              (
                isDeNovo &&
                isEditable &&
                (jcStatus === DENOVO_ARTICLE_STATUS.PUBLISHED)
              )
              && (
                <Box sx={{ marginRight: "5px" }}>
                  {
                    saveArticle && (
                      <IconButton
                        size="small"
                        onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'unsave' })}
                      >
                        <SaveIconFilled width={ICON_DIMENSIONS.MOBILE.width} height={ICON_DIMENSIONS.MOBILE.height} />
                      </IconButton>
                    )
                  }
                  {
                    !saveArticle && (
                      <IconButton
                        size="small"
                        onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'save' })}
                      >
                        <SaveIcon width={ICON_DIMENSIONS.MOBILE.width} height={ICON_DIMENSIONS.MOBILE.height} />
                      </IconButton>
                    )
                  }
                </Box>

              )
            }


          </Hidden>
        </JournalSourceRow>

        <BootstrapTooltip placement="top-start" title={isTitleOverflow ? journalTitle : ""}>
          <JournalTitle
            ref={titleOverflowRef}
          >
            {journalTitle}
          </JournalTitle>
        </BootstrapTooltip>

        <AuthorsRow>
          <Hidden mdDown>
            {
              showJcDate && (
                <>
                  <Typography variant="BODY_COPY2_RALEWAY">{`${authors?.length > 0 ? authors[0] + ', et al.' : ''}`}</Typography>
                  <Typography variant="BODY_COPY2_RALEWAY">{`${jcStatus === 'Under Discussion' ? 'Proposed' : jcStatus} ${moment.utc(jcStatusUpdatedOn).local().fromNow()}`}</Typography>
                </>
              )
            }
            {
              showJcDate === false && (
                <>
                  <Typography variant="BODY_COPY2_RALEWAY">{`${authors?.length > 0 ? authors[0] + ', et al.' : ''}`}</Typography>
                  {publicationDate && (
                  <Typography variant="BODY_COPY2_RALEWAY">{`Published ${moment(publicationDate, "MM/DD/YYYY").fromNow()}`}</Typography>
                  )}
                </>
              )
            }
          </Hidden>
          <Hidden mdUp>
            {
              showJcDate && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography
                    variant="BODY_COPY2_RALEWAY"
                    sx={{ margin: "0 0 10px 0px" }}
                  >{`${authors?.length > 0 ? authors[0] + ', et al.' : ''}`}</Typography>
                  <Typography variant="BODY_COPY2_RALEWAY">{`${jcStatus === 'Under Discussion' ? 'Proposed' : jcStatus} ${moment.utc(jcStatusUpdatedOn).local().fromNow()}`}</Typography>
                </Box>
              )
            }
            {
              showJcDate === false && (
                <Typography
                  variant="BODY_COPY2_RALEWAY"
                >
                  {`${authors?.length > 0 ? authors[0] + ', et al.' + ` / ${calPublishedAgoMobile(publicationDate)}` : ''}`}
                </Typography>
              )
            }
            <PublishedJCIcons journalClubsList={journalClubsList} />


          </Hidden>
        </AuthorsRow>


        <Hidden mdDown>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              {
                pIArticle && (
                  <IconButton
                    size="small"
                    onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'unPI' })}
                  >
                    <PIIconFilled width={PI_ICON_DIMENSIONS.DESKTOP.width} height={PI_ICON_DIMENSIONS.DESKTOP.height} />
                  </IconButton>)
              }
              {
                !pIArticle && (
                  <IconButton
                    size="small"
                    onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'PI' })}
                  >
                    <PIIcon width={PI_ICON_DIMENSIONS.DESKTOP.width} height={PI_ICON_DIMENSIONS.DESKTOP.height} />
                  </IconButton>
                )
              }
              <IconText>
                {
                  calcNumberOfPIText(
                    {
                      numberOfPractices: numOfPI,
                      numberOfPracticeImpactingInUserJCs: numOfPIinUserJCs
                    }
                  )
                }
              </IconText>
              {
                likeArticle && (
                  <IconButton
                    size="small"
                    onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'unlike' })}
                  >
                    <LikeIconFilled width={LIKE_ICON_DIMENSIONS.DESKTOP.width} height={LIKE_ICON_DIMENSIONS.DESKTOP.height} />
                  </IconButton>
                )
              }
              {
                !likeArticle && (
                  <IconButton
                    size="small"
                    onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'like' })}
                  >
                    <LikeIcon width={LIKE_ICON_DIMENSIONS.DESKTOP.width} height={LIKE_ICON_DIMENSIONS.DESKTOP.height} />
                  </IconButton>
                )
              }
              <IconText>
                {
                  calcNumberOfLikeText(
                    {
                      numberOfLikes: numOfLikes,
                      numberOfLikesInUserJCs: numOfLikesinUserJCs
                    }
                  )
                }
              </IconText>
            </Box>
            <Box
              sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                flexGrow: 3,
                marginRight: '5px',
              }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                {
                  isCommented && (
                    <IconButton>
                      <CommentIconFilled
                        width={COMMENT_ICON_DIMENSIONS.DESKTOP.width}
                        height={COMMENT_ICON_DIMENSIONS.DESKTOP.height}
                      />
                    </IconButton>
                  )
                }
                {
                  !isCommented && (
                    <IconButton>
                      <CommentIcon
                        width={COMMENT_ICON_DIMENSIONS.DESKTOP.width}
                        height={COMMENT_ICON_DIMENSIONS.DESKTOP.height}
                      />
                    </IconButton>
                  )
                }
                <IconText>
                  {
                    calcNumberOfCommentsText(
                      {
                        numberOfComments,
                        numberOfCommentsInUserJCs
                      }
                    )
                  }
                </IconText>
                {
                  newCommentAdded && (
                    <CircleIcon
                      sx={{
                        width: "6px",
                        height: "6px",
                        color: JC_COLORS.JC_RED,
                        marginLeft: '4px'
                      }}
                    />
                  )
                }
                {numberOfNotes > 0 && (
                  <>
                    {
                      noteAddedByUser > 0 && (
                        <IconButton>
                          <NoteIconFilled width={NOTES_ICON_DIMENSIONS.DESKTOP.width} height={NOTES_ICON_DIMENSIONS.DESKTOP.height} />
                        </IconButton>
                      )
                    }
                    {
                      !noteAddedByUser && (
                        <IconButton>
                          <NoteIcon width={NOTES_ICON_DIMENSIONS.DESKTOP.width} height={NOTES_ICON_DIMENSIONS.DESKTOP.height} />
                        </IconButton>
                      )
                    }
                    <IconText>
                      {numberOfNotes}
                    </IconText>
                    {newNoteAdded && <CircleIcon sx={{ width: "6px", height: "6px", color: JC_COLORS.JC_RED, marginLeft: '4px' }} />}
                  </>

                )}
              </Box>
              {
                (
                  isDeNovo &&
                  isEditable &&
                  denovoStatus !== null &&
                  denovoStatus === DENOVO_ARTICLE_STATUS.DRAFT
                ) ? (
                  <StyledDraftLabel>Draft</StyledDraftLabel>
                ) : (
                  <PublishedJCIcons journalClubsList={journalClubsList} />
                )
              }



            </Box>


          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              {
                pIArticle && (
                  <IconButton
                    size="small"
                    onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'unPI' })}
                  >
                    <PIIconFilled width={PI_ICON_DIMENSIONS.MOBILE.width} height={PI_ICON_DIMENSIONS.MOBILE.height} />
                  </IconButton>)
              }
              {
                !pIArticle && (
                  <IconButton
                    size="small"
                    onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'PI' })}
                  >
                    <PIIcon width={PI_ICON_DIMENSIONS.MOBILE.width} height={PI_ICON_DIMENSIONS.MOBILE.height} />
                  </IconButton>
                )
              }
              <IconText>
                {calcNumberOfPIText({ numberOfPractices: numOfPI, numberOfPracticeImpactingInUserJCs: numOfPIinUserJCs })}
              </IconText>
              {
                likeArticle && (
                  <IconButton
                    size="small"
                    onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'unlike' })}
                  >
                    <LikeIconFilled width={LIKE_ICON_DIMENSIONS.MOBILE.width} height={LIKE_ICON_DIMENSIONS.MOBILE.height} />
                  </IconButton>
                )
              }
              {
                !likeArticle && (
                  <IconButton
                    size="small"
                    onClick={(e) => handleIconClick({ event: e, PMID: PMID, JCID: JCID, action: 'like' })}
                  >
                    <LikeIcon width={LIKE_ICON_DIMENSIONS.MOBILE.width} height={LIKE_ICON_DIMENSIONS.MOBILE.height} />
                  </IconButton>
                )
              }
              <IconText>
                {calcNumberOfLikeText({ numberOfLikes: numOfLikes, numberOfLikesInUserJCs: numOfLikesinUserJCs })}
              </IconText>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              {
                isCommented && (
                  <IconButton>
                    <CommentIconFilled width={COMMENT_ICON_DIMENSIONS.MOBILE.width} height={COMMENT_ICON_DIMENSIONS.MOBILE.height} />
                  </IconButton>
                )
              }
              {
                !isCommented && (
                  <IconButton>
                    <CommentIcon width={COMMENT_ICON_DIMENSIONS.MOBILE.width} height={COMMENT_ICON_DIMENSIONS.MOBILE.height} />
                  </IconButton>
                )
              }
              <IconText>
                {calcNumberOfCommentsText({ numberOfComments, numberOfCommentsInUserJCs })}
              </IconText>
              {newCommentAdded && <CircleIcon sx={{ width: "6px", height: "6px", color: JC_COLORS.JC_RED, marginLeft: '4px' }} />}
              {numberOfNotes > 0 && (
                <>
                  {
                    noteAddedByUser > 0 && (
                      <IconButton>
                        <NoteIconFilled width={NOTES_ICON_DIMENSIONS.MOBILE.width} height={NOTES_ICON_DIMENSIONS.MOBILE.height} />
                      </IconButton>
                    )
                  }
                  {
                    !noteAddedByUser && (
                      <IconButton>
                        <NoteIcon width={NOTES_ICON_DIMENSIONS.MOBILE.width} height={NOTES_ICON_DIMENSIONS.MOBILE.height} />
                      </IconButton>
                    )
                  }
                  <IconText>
                    {numberOfNotes}
                  </IconText>
                  {newNoteAdded && <CircleIcon sx={{ width: "6px", height: "6px", color: JC_COLORS.JC_RED, marginLeft: '4px' }} />}
                </>

              )}
            </Box>
          </Box>

          {
            (
              isDeNovo &&
              isEditable &&
              denovoStatus !== null &&
              denovoStatus === DENOVO_ARTICLE_STATUS.DRAFT
            ) && (
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <StyledDraftLabel>Draft</StyledDraftLabel>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <IconButton
                    onClick={(event) => handleEditDenovoIconClick(event)}
                  >
                    <EditIcon
                      style={{
                        color: JC_COLORS.JC_RED,
                        width: EDIT_ICON_DIMENSIONS.MOBILE.width,
                        height: EDIT_ICON_DIMENSIONS.MOBILE.height,
                      }}
                    />
                  </IconButton>
                  { jcUserDetails && (jcUserDetails.role === USER_ROLES.COURSE_DIRECTOR || 
                    jcUserDetails.role === USER_ROLES.ADMINISTRATOR || proposedByCurrentUser ) && (
                  <IconButton
                    onClick={(event) => handleDeleteDenovoIconClick(event)}
                  >
                    <DeleteIcon
                      style={{
                        color: JC_COLORS.JC_RED,
                        width: DELETE_ICON_DIMENSIONS.MOBILE.width,
                        height: DELETE_ICON_DIMENSIONS.MOBILE.height,
                      }}
                    />
                  </IconButton>
                  )}
                </Box>
              </Box>
            )
          }

        </Hidden>


        {
          showUnderDiscussionJCs && (
            <UnderDiscussionJCIcons journalClubsList={journalClubsList} />

          )
        }


      </MainCard >
      <DeleteDenovoArticleDialogBox
        openDeleteDenovoDialogBox={openDeleteDenovoDialogBox}
        onClickConfirm={(event) => handleConfirmDeleteDenovo(event)}
        onClickCancel={(event) => handleCancelDeleteDenovo(event)}
      />
    </>


  )
}

export default ArticleCardView

const useIsOverflow = (ref, callback) => {
  const [isOverflow, setIsOverflow] = useState(undefined);


  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }
      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};

export const ArticleCardViewSkeleton = () => {

  return (
    <MainCard>
      <Skeleton variant="text" width={100} height={40} />
      <Skeleton variant="text" width={300} height={100} />
      <Row
        sx={{ justifyContent: 'space-between', marginRight: "4rem" }}
      >
        <Skeleton variant="text" width={100} height={40} />
        <Skeleton variant="text" width={100} height={40} />
      </Row>
      <Row>
        <Skeleton variant="text" width={50} height={40} />
        <Spacer width={"2px"} />
        <Skeleton variant="text" width={50} height={40} />
      </Row>

      <Row>
        <Skeleton variant="text" width={25} height={25} />
        <Spacer width={"2px"} />
        <Skeleton variant="text" width={25} height={25} />
        <Spacer width={"2px"} />
        <Skeleton variant="text" width={25} height={25} />
      </Row>

    </MainCard>
  )
}


const StyledDenovoStartWritingCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    boxShadow: 0,
    padding: "20px 0px 20px 0px",
    ...JC_ICON,
    minHeight: "288px"
  },
  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
    padding: '18px 0px',
    borderBottom: `1px solid ${JC_COLORS.JC_ICON}`
  }
}));

export const DenovoStartWritingCard = () => {
  const theme = useTheme();
  return (
    <StyledDenovoStartWritingCard elevation={0} >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Hidden mdDown>
          <Box sx={{ margin: '40px 0px 0px 0px' }}>
            <NewArticleIcon />
          </Box>
          <Box sx={{ margin: '24px 0px 0px 0px' }}>
            <StyledButton
              variant="outlined"
              startIcon={
                <EditIcon />
              }
            >
              Start Writing
            </StyledButton>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box sx={{ margin: '25px 0px 0px 0px' }}>
            <NewArticleIcon
              width={'43px'}
              height={'51px'}
            />
          </Box>
          <Box sx={{ margin: '14px 0px 0px 0px' }}>
            <StyledButton
              variant="outlined"
              // onClick={handleClick}
              startIcon={
                <EditIcon
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
              }
            >
              Start Writing
            </StyledButton>
          </Box>

        </Hidden>

      </Box>
    </StyledDenovoStartWritingCard>

  )

}

const DeleteDenovoArticleDialogBox = ({ openDeleteDenovoDialogBox, onClickConfirm, onClickCancel, }) => {
  const theme = useTheme();
  return (
    <BootstrapDialog
      open={openDeleteDenovoDialogBox}
      onClose={(event) => onClickCancel(event)}
    >
      <DialogTitle
        sx={{
          height: '32px',
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
          backgroundColor: JC_COLORS.JC_RED,
          color: JC_COLORS.JC_WHITE,
        }}
      >
        <Typography
          sx={{
            ...theme.typography.H3_BITTER,
            fontWeight: 700,
          }}>
          Delete Original Article
        </Typography>
        <CloseIcon
          sx={{
            width: '24px',
            height: '24px',
            color: JC_COLORS.JC_WHITE,
            cursor: 'pointer',
          }}
          onClick={(event) => onClickCancel(event)}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center',
          margin: '35px 35px 24px 35px',
        }}
      >
        <Typography
          variant="H2_RALEWAY"
          sx={{
            fontWeight: 600,
          }}
        >
          Are you sure you want to delete this article?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '16px' }}>
        <StyledButton
          variant="outlined"
          onClick={(event) => onClickCancel(event)}
        >
          Cancel
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={(event) => onClickConfirm(event)}
        >
          Confirm
        </StyledButton>
      </DialogActions>
    </BootstrapDialog>

  )
}

const calPublishedAgoMobile = (publicationDate) => {
  if(publicationDate){
    let sentence = moment(publicationDate, "MM/DD/YYYY").fromNow() ?? '0 days ago';
    let time = sentence[0] === 'a' ? '1' : parseInt(sentence);
    let timeChar = sentence.split(' ')[1][0] ?? 'd';
    return `${time} ${timeChar}`;
  }else {
    return ''
  }
}

const UnderDiscussionJCIcons = ({ journalClubsList = [] }) => {
  const articleUnderDiscussionIn = journalClubsList.filter(obj => obj.articleStatus === "Under Discussion");
  const articleUnderDiscussionInAndUserIsMember = articleUnderDiscussionIn.filter(obj => obj.isUserAMember);

  const { jcList } = useSelector((state) => state.journalclub);
  const jcColors = jcList.reduce((acc, curr) => {
    acc[curr.JCID] = curr.iconColor
    return acc;
  }, {});

  if (articleUnderDiscussionInAndUserIsMember.length === 0) {
    return <></>
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
    >
      <Typography
        variant="BODY_COPY2_RALEWAY"
        sx={{ color: JC_COLORS.JC_RED, marginRight: '5px', fontWeight: 700 }}
      >
        The article is in discussion in
      </Typography>
      <AvatarGroup
        total={articleUnderDiscussionInAndUserIsMember.length}
        sx={{
          ".MuiAvatarGroup-avatar": {
            width: "20px",
            height: "20px",
            fontSize: '12px',
          }

        }}>
        {
          articleUnderDiscussionInAndUserIsMember.length > 0 && (
            <JC_Avatar
              iconColor={jcColors[articleUnderDiscussionInAndUserIsMember[0].JCID]}
              title={articleUnderDiscussionInAndUserIsMember[0].name}
              sx={{
                width: "20px",
                height: "20px",
                fontSize: '12px',

              }}
            />

          )
        }
        {
          articleUnderDiscussionInAndUserIsMember.length > 1 && (
            <JC_Avatar
              iconColor={jcColors[articleUnderDiscussionInAndUserIsMember[1].JCID]}
              title={articleUnderDiscussionInAndUserIsMember[1].name}
              sx={{
                width: "20px",
                height: "20px",
                fontSize: '12px',

              }}
            />

          )
        }
        {
          articleUnderDiscussionInAndUserIsMember.length > 2 && (
            <JC_Avatar
              iconColor={jcColors[articleUnderDiscussionInAndUserIsMember[2].JCID]}
              title={articleUnderDiscussionInAndUserIsMember[2].name}
              sx={{
                width: "20px",
                height: "20px",
                fontSize: '12px',

              }}
            />

          )
        }


      </AvatarGroup>



    </Box>
  )
}

const PublishedJCIcons = ({ journalClubsList = [] }) => {
  const articleStatusTrueIn = journalClubsList.filter(obj => obj.articleStatus === "Published");
  const articleStatusTrueInAndUserIsMember = articleStatusTrueIn.filter(obj => obj.isUserAMember);

  const { jcList } = useSelector((state) => state.journalclub);
  const jcColors = jcList.reduce((acc, curr) => {
    acc[curr.JCID] = curr.iconColor
    return acc;
  }, {});

  const restOfJc = articleStatusTrueIn.length - articleStatusTrueInAndUserIsMember.length;

  let restOfJcText = ""
  if (restOfJc === 0) {
    restOfJcText = ''
  } else if (restOfJc === 1) {
    restOfJcText = `${articleStatusTrueInAndUserIsMember.length > 0 ? '+1 JC' : ''}`
  } else {
    restOfJcText = `${articleStatusTrueInAndUserIsMember.length > 0 ? `+${restOfJc} JCs` : ''}`
  }



  return (
    <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography
        variant="BODY_COPY2_RALEWAY"
        sx={{ color: JC_COLORS.JC_ICON, fontWeight: 700, marginRight: '3px' }}
      >
        {(articleStatusTrueIn.length > 0) ? 'in' : ''}
      </Typography>
      <AvatarGroup
        total={articleStatusTrueInAndUserIsMember.length}
        sx={{
          ".MuiAvatarGroup-avatar": {
            width: "20px",
            height: "20px",
            fontSize: '12px',
          }
        }}
      >
        {
          articleStatusTrueInAndUserIsMember.length > 0 && (
            <JC_Avatar
              iconColor={jcColors[articleStatusTrueInAndUserIsMember[0].JCID]}
              title={articleStatusTrueInAndUserIsMember[0].name}
              sx={{

                width: "20px",
                height: "20px",
                fontSize: '12px',

              }}
            />

          )
        }
        {
          articleStatusTrueInAndUserIsMember.length > 1 && (
            <JC_Avatar
              iconColor={jcColors[articleStatusTrueInAndUserIsMember[1].JCID]}
              title={articleStatusTrueInAndUserIsMember[1].name}
              sx={{

                width: "20px",
                height: "20px",
                fontSize: '12px',

              }}
            />

          )
        }
        {
          articleStatusTrueInAndUserIsMember.length > 2 && (
            <JC_Avatar
              iconColor={jcColors[articleStatusTrueInAndUserIsMember[2].JCID]}
              title={articleStatusTrueInAndUserIsMember[2].name}
              sx={{
                width: "20px",
                height: "20px",
                fontSize: '12px',

              }}
            />

          )
        }


      </AvatarGroup>

      <Typography
        variant="BODY_COPY2_RALEWAY"
        sx={{ color: JC_COLORS.JC_ICON, fontWeight: 700 }}
      >
        {restOfJcText}
      </Typography>

    </Box>
  )

}


function calcNumberOfPIText({ numberOfPractices = 0, numberOfPracticeImpactingInUserJCs = 0 }) {
  return `${numberOfPractices} ${numberOfPracticeImpactingInUserJCs !== undefined ? '(' + numberOfPracticeImpactingInUserJCs + ')' : ''}`


}

function calcNumberOfLikeText({ numberOfLikes = 0, numberOfLikesInUserJCs = 0 }) {
  return `${numberOfLikes} ${numberOfLikesInUserJCs !== undefined ? '(' + numberOfLikesInUserJCs + ')' : ''}`

}

function calcNumberOfCommentsText({ numberOfComments = 0, numberOfCommentsInUserJCs = 0 }) {
  return `${numberOfComments} ${numberOfCommentsInUserJCs !== undefined ? '(' + numberOfCommentsInUserJCs + ')' : ''}`

}

export function prevent(fn, defaultOnly) {
  return (e, ...params) => {
    e && e.preventDefault()
    !defaultOnly && e && e.stopPropagation()
    fn(e, ...params)
  }
}