import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { setSupport, setOppose } from '../../state/actions/article';
import { AuthContext } from '../../context/AuthContext';

import { setArticleStatus, updateSupportStatus } from '../../state/actions/article';
import { getJournalClubs } from '../../state/actions/journalclub';
import { JC_COLORS } from '../../styles/constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '80%',
      height: 'auto',
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      width: '30%',
      height: 'auto',
    }
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        pb: { xs: 1, sm: 0, md: 0 },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }} {...other}>
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            cursor: 'pointer',
          }}
        />
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PublishReject(props) {

  const { open, onClose, isReject } = props;
  // const { JCID } = useParams()
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const dispatch = useDispatch();
  const {
    PMID,
    numberOfSupporters,
    numberOfOpposers,
    noteAddedByUser,
    supportedByUser,
    opposedByUser,
    isDenovo,
  } = useSelector((state) => state.article.articleData);
  const { articleData, articleError, denovoLockState } = useSelector((state) => state.article);
  const { jcDetails } = useSelector((state) => state.journalclub);

  const [isPublishAllow, setIsPublishAllow] = useState(false)
  const [emptyfieldMsg, setEmptyFieldMsg] = useState('')

  const { JCID } = useSelector((state) => state.journalclub.jcDetails)
  const handleButtonClose = () => {
    //todo: Code for validation of confimation
    onClose()
  }
  useEffect(() => {
    isPublishAllowed(articleData)
    getListOfEmptyField(articleData)
  }, [articleData])

  const isPublishAllowed = (articleData) => {
    if (articleData.isDeNovo &&
      (!articleData.title ||
        articleData.abstractContent?.length < 9 ||
        articleData.bodyContent?.length < 9 ||
        !articleData.authors)) {
      setIsPublishAllow(false)
    }
    else {
      setIsPublishAllow(true)
    }
  }
  const getListOfEmptyField = (articleData) => {
    var resultFields = ["title", "authors"].filter(field => !articleData[field])
    resultFields.push.apply(resultFields, ["abstractContent", "bodyContent"].filter(field => articleData[field]?.length < 9))
    const fieldVerb = resultFields.length > 1 ? " fields are " : " field is "
    var missingFieldMsg = "'" + resultFields.join(", ") + "'" + fieldVerb
    missingFieldMsg = missingFieldMsg.replace("abstractContent", "Summary")
    missingFieldMsg = missingFieldMsg.replace("bodyContent", "Article Body")
    setEmptyFieldMsg(missingFieldMsg)
  }
  const handleProceedButtonClose = () => {
    //todo: Code for validation of confimation
    let articleStatus = isReject ? "Rejected" : "Published"
    dispatch(setArticleStatus(PMID, userEmail, JCID, articleStatus))
    onClose()
  }
  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }} p={{ xs: 0, sm: 2, md: 2 }}>


            <Typography variant="H3_RALEWAY" sx={{ fontWeight: '600' }} gutterBottom>
              {
                jcDetails?.approved ? (
                  isReject ?
                    "This article will be rejected and will not be a part of the next newsletter."
                    : isPublishAllow ?
                      " This article will be published and will be part of the next newsletter."
                      :
                      emptyfieldMsg + "missing from your article. Please complete and publish your article again.") : (
                  "Your Journal Club is being reviewed by our team. Only Journal Clubs that have been verified and approved can publish articles."
                )
              }
            </Typography>
            <Box mt={1}>
              <DialogActions sx={{ justifyContent: 'center' }}>
                {(isPublishAllow || isReject) && (jcDetails?.approved) &&
                  <Box sx={{ display: 'inline-block' }}>
                    <Button variant='outlined' size="small" color="primary" onClick={handleButtonClose} >
                      No, Cancel
                    </Button>
                  </Box>
                }
                {(isPublishAllow || isReject) && (jcDetails?.approved) &&
                  <Box sx={{ display: 'inline-block' }} mr={2}>
                    <Button variant='contained' size="small" color="primary" onClick={handleProceedButtonClose}>
                      Yes, Proceed
                    </Button>
                  </Box>
                }
                {((!isPublishAllow && !isReject) || (jcDetails?.approved === false)) &&
                  <Box sx={{ display: 'inline-block' }}>
                    <Button variant='outlined' size="small" color="primary" onClick={onClose} sx={{ textTransform: 'none!important' }} >
                      Go Back
                    </Button>
                  </Box>
                }
              </DialogActions>
            </Box>
          </Box>

        </DialogContent>

      </BootstrapDialog>
    </div>
  );
}
