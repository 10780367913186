import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ShareIcon from '../../assets/ShareIcon';
import SaveIcon from '../../assets/SaveIcon';
import ProposeIcon from '../../assets/ProposeIcon';
import LikeIcon from '../../assets/LikeIcon';
import { CommentIcon, CommentedIcon, LikedIcon, NoteIcon, SupportArticle, NotedIcon } from '../../assets';
import PracticeIcon from '../../assets/PracticeIcon';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getJournalClubArticle, setLiked, setPracticeImpacted, setSaved, closeDenovoArticleInEditMode, updateDenovoArticleToDB } from '../../state/actions/article';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent, useTheme } from '@mui/material';
import ProposeToJC from './ProposeToJc';
import PublishReject from './PublishReject';
import SmoothScrolling from '../../utils/smoothScrolling';
import CommentModal from './CommentModal';
import * as api from '../../api';
import ErrorDialogBox from './ErrorDialogBox';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { JC_COLORS } from '../../styles/constants';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#FFFFFF',
  backgroundColor: '#FF685C'
}));

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 50,
  height: 5,
  borderRadius: 3,
  position: 'absolute',
  backgroundColor: 'white',
  top: 10,
  left: 'calc(50% - 20px)',
}));

function BottomDrawer(props) {
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const userEmail = auth.user.email;
  const { isDisabled = false } = props

  // const { window } = props;
  const [open, setOpen] = React.useState(false);
  const [proposeOpen, setProposeOpen] = React.useState(false);
  const OpenHeight = open ? '100%' : '6%'
  const dispatch = useDispatch();
  const {
    PMID,
    sourceName,
    title,
    numberOfComments,
    numberOfLikes,
    numberOfPracticeImpacting,
    likedByUser,
    PIByUser,
    savedByUser,
    commentedByUser,
    numberOfSupporters,
    numberOfOpposers,
    noteAddedByUser,
    numberOfCommentsInUserJCs,
    numberOfLikesInUserJCs,
    numberOfPracticeImpactingInUserJCs,
    numberOfNotes,
    supportedByUser,
    status,
    url
  } = useSelector((state) => state.article.articleData);
  const { jcList, jcDetails, jcUserDetails, isFetchingDetails } = useSelector((state) => state.journalclub);
  const { articleData, denovoArticleData, denovoArticleInEditMode } = useSelector((state) => state.article);

  const [toastUrlMsg, setToastUrlMsg] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const [rejectModel, setRejectModel] = React.useState(false)
  const [commentOpen, setCommentOpen] = React.useState(false)
  const [isNote, setIsNote] = React.useState(false)
  const { JCID } = useParams()
  const JCVerb = JCID ? JCID : "J00000000"
  const isJCPage = JCID ? JCID === 'J00000000' ? false : true : false

  const [isDenovoArticleLockedByCurrentUser, setIsDenovoArticleLockedByCurrentUser] = React.useState(false);
  const [openLockStatusDialogBox, setOpenLockStatusDialogBox] = React.useState(false);
  const [lockStatusMessage, setLockStatusMessage] = React.useState('');


  const CommentHandleOpen = () => {
    setCommentOpen(true)
    setIsNote(false)
  };

  const HandleNoteOpen = () => {
    setCommentOpen(true)
    setIsNote(true)
  };

  const CommentHandleClose = () => {
    setCommentOpen(false)
  }
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleOnShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: title,
          url: document.location.href,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch(error => {
          console.error('Something went wrong sharing the blog', error);
        });
    }
  };

  const handleUrlClick = () => {
    if (url.replaceAll(" ", "") === "") {
      setToastUrlMsg(true)
    }
    else {
      window.open(url, "_blank");
    }
  }
  const handleUrlClose = () => {
    setToastUrlMsg(false)
  }
  const handleProposeClickOpen = () => {
    setProposeOpen(true)
  };
  const proposeHandleClose = () => {
    setProposeOpen(false)
  }

  const handlePublishClickOpen = () => {
    setConfirmOpen(true)
    setRejectModel(false)
  };
  const handleRejectClickOpen = () => {
    setConfirmOpen(true)
    setRejectModel(true)
  };
  const handlePublishClose = () => {
    setConfirmOpen(false)
  };

  const handleCommentClick = () => {
    setOpen(false)
    goToSection("Comments")
  }

  const handleSupportClick = () => {
    setOpen(false)
    goToSection("Support")
  }
  const handleSaveAndDone = () => {
    dispatch(
      updateDenovoArticleToDB({
        denovoArticleData,
        JCID: jcDetails?.JCID,
        PMID,
        userEmail,
        articleData
      })
    );
  }
  const _updateDenovoArticleToDB = async ({ denovoArticleData, JCID, PMID }) => {
    try {
      const abstractContentRaw = convertToRaw(denovoArticleData.abstractEditorState.getCurrentContent());
      const abstractContentHTML = draftToHtml(abstractContentRaw);
      const articleBodyContentRaw = convertToRaw(denovoArticleData.articleBodyEditorState.getCurrentContent());
      const articleBodyContentHTML = draftToHtml(articleBodyContentRaw);

      let res = await api.patchDenovoArticle(
        JCID,
        userEmail,
        {
          title: denovoArticleData.title,
          abstractContent: abstractContentHTML,
          bodyContent: articleBodyContentHTML,
          authors: denovoArticleData.authors,
          cmeCredits: denovoArticleData.cmeCredits,
          PMID,
          getLock: false,
        },

      );
      console.log("Sending patch request for denovo");
      if (res?.status === 204) {
        dispatch(getJournalClubArticle(JCID, PMID, userEmail));
        dispatch(closeDenovoArticleInEditMode());
      }
    } catch (error) {
      setOpenLockStatusDialogBox(true);
      let errorMessaage = error?.response?.data?.message ||
        JSON.stringify(
          {
            ...error?.response?.data,
            status: error?.response?.status
          }
        );
      setLockStatusMessage(errorMessaage);
      dispatch(closeDenovoArticleInEditMode());

    }
  }

  const goToSection = (id) => {
    const targetSection = document.getElementById(id);
    if (targetSection != null) {
      window.scrollTo({
        top: targetSection.offsetTop - 200,
        behavior: "smooth",
      });
    }
  }
  const isAdmin = (userRole) => {
    if (userRole === "Administrator" || userRole === "Course Director") {
      return true
    }
    else {
      return false
    }
  }
  const isContributor = (userRole) => {
    if (userRole === "Administrator" || userRole === "Course Director" || userRole === "Contributor") {
      return true
    }
    else {
      return false
    }
  }

  return (
    <Root sx={{ height: OpenHeight }}>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            // todo: set height as per content size in box
            // height: `calc(72% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        container={window.document.body}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={((articleData?.isDeNovo && denovoArticleInEditMode === true) || isDisabled)}
        ModalProps={{
          keepMounted: true,
        }}

      >
        <StyledBox
          sx={{
            position: open ? 'absolute' : 'fixed',
            top: 'calc(100vh - (100vh + 65px))',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            backgroundColor: isDisabled ? JC_COLORS.JC_ICON : '#EF5B50',
          }} pl={1.4} pr={1.4} pt={articleData?.isDeNovo && denovoArticleInEditMode === true ? 1.4 : 2.5} pb={1.4}>

          {articleData && !denovoArticleInEditMode && isDisabled === false &&
            <Puller />
          }
          {open ? (<Box p={1}></Box>) : (
            <Box pl={1.2} pr={2} pb={2} my={1}
              sx={{
                pointerEvents: isDisabled ? 'none' : 'all'
              }}>

              <ArrowBackIosIcon sx={{ color: '#FFFFFF' }} onClick={() => navigate(-1)}></ArrowBackIosIcon>
              <Box
                sx={{
                  display: (articleData?.isDeNovo === true && denovoArticleInEditMode === true) ? 'none' : 'inline-block',
                  float: 'right'
                }}>

                {isJCPage && isContributor(jcUserDetails.role) &&
                  <Box sx={{ display: 'inline-block' }} onClick={handleSupportClick} mr={11}>
                    <SupportArticle className="mobile-icon mobile-save-icon" />
                    <Box sx={{ display: 'inline-block', position: 'absolute', marginTop: '-4px', marginLeft: '3px', maxWidth: '80px' }}>

                      <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" component="span" sx={{ fontSize: '12px', color: '#FFFFFF', display: 'inline-block', marginLeft: '-5px', lineHeight: '15px' }}>
                        {(numberOfSupporters + numberOfOpposers) + " peers already rated"}
                      </Typography>
                    </Box>
                  </Box>
                }

                <Box sx={{ display: 'inline-block' }} onClick={() => dispatch(setLiked(PMID, userEmail, JCVerb, likedByUser, supportedByUser))}>
                  {likedByUser ? <LikedIcon className="mobile-icon" fill="#FFFFFF" /> : <LikeIcon className="mobile-icon" />}
                  <Box sx={{ display: 'inline-block', position: 'absolute', marginTop: '2px', marginLeft: '3px' }}>

                    {
                      numberOfLikes !== undefined && (
                        <Typography
                          variant="BODY_COPY2_RALEWAY_DESKTOP"
                          component="span"
                          sx={{
                            fontSize: '12px',
                            color: '#FFFFFF',
                            display: 'inline-block',
                            marginLeft: '-5px'
                          }}>
                          {"(" + numberOfLikes + ")"}
                        </Typography>

                      )
                    }

                  </Box>
                </Box>


                <Box sx={{ display: 'inline-block', marginLeft: "25px" }} onClick={handleCommentClick}>
                  {commentedByUser ? <CommentedIcon className="mobile-icon" /> : <CommentIcon className="mobile-icon" />}
                  <Box sx={{ display: 'inline-block', position: 'absolute', marginTop: '2px', marginLeft: '3px' }}>
                    {
                      numberOfComments !== undefined && (
                        <Typography
                          variant="BODY_COPY2_RALEWAY_DESKTOP"
                          component="span"
                          sx={{
                            fontSize: '12px',
                            color: '#FFFFFF',
                            display: 'inline-block',
                            marginLeft: '-5px',
                            marginTop: '-15px'
                          }}
                          my={-2}>
                          {"(" + numberOfComments + ")"}
                        </Typography>

                      )
                    }
                  </Box>
                </Box>

                <Box sx={{ display: 'inline-block', marginLeft: "25px" }} onClick={() => dispatch(setPracticeImpacted(PMID, userEmail, JCVerb, PIByUser, likedByUser))}>
                  <PracticeIcon className="mobile-icon" fillcolor={PIByUser ? "#FFFFFF" : "None"} />
                  <Box sx={{ display: 'inline-block', position: 'absolute', marginTop: '2px', marginLeft: '3px' }}>
                    {
                      numberOfPracticeImpacting !== undefined && (
                        <Typography
                          variant="BODY_COPY2_RALEWAY_DESKTOP"
                          sx={{
                            fontSize: '12px',
                            color: '#FFFFFF',
                            display: 'inline-block',
                            marginLeft: '-5px'
                          }}
                        >
                          {"(" + numberOfPracticeImpacting + ")"}
                        </Typography>

                      )
                    }

                  </Box>
                </Box>
              </Box>

              {articleData?.isDeNovo && denovoArticleInEditMode === true &&
                <Box sx={{ display: 'inline-block', float: 'right' }} mt={-0.5}>
                  <Box sx={{ cursor: 'pointer' }}>
                    <Button target="_blank" variant="outlined" color="primary" sx={{ fontFamily: "Raleway", fontWeight: '700', color: '#FFFFFF', border: '1px solid #FFFFFF' }} onClick={handleSaveAndDone} >
                      SAVE AND DONE
                    </Button>
                  </Box>
                </Box>
              }

            </Box>)
          }
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
            backgroundColor: '#EF5B50',
          }}
          mt={-3}
        >
          <Box>
            <Grid container direction='row' spacing={2}>

              {/*Discussion Actions */}
              {isJCPage && isContributor(jcUserDetails.role) &&
                <Grid item xs={12}>
                  <Item>
                    <Box pl={1} pr={1} sx={{ textAlign: 'left' }}>
                      <Box pb={1} pt={1}>
                        <Typography variant="h7" sx={{ fontFamily: 'bitter', fontWeight: '600' }}
                          gutterBottom>
                          Discussion Actions
                        </Typography>
                      </Box>

                      <Box className="mobile-drawer-box" onClick={handleSupportClick}>
                        <Box sx={{ display: 'inline-block' }}>
                          <SupportArticle className="mobile-icon mobile-save-icon" />
                        </Box>
                        <Typography variant="BODY_COPY1_RALEWAY_DESKTOP"
                          className='drawer-typography'>
                          Support Article
                          <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" component="span" sx={{ fontSize: '12px', marginLeft: '10px' }}>
                            {(numberOfSupporters + numberOfOpposers) + " peers already rated"}
                          </Typography>
                        </Typography>
                      </Box>

                      <Box className="mobile-drawer-box" onClick={HandleNoteOpen}>
                        <Box sx={{ display: 'inline-block' }}>
                          {noteAddedByUser ?
                            <NotedIcon className="mobile-icon mobile-save-icon" />
                            :
                            <NoteIcon className="mobile-icon mobile-save-icon" />
                          }
                        </Box>
                        <Typography variant="BODY_COPY1_RALEWAY_DESKTOP"
                          className='drawer-typography'>
                          Notes
                          <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" component="span" sx={{ fontSize: '12px', marginLeft: '10px' }}>
                            {"(" + numberOfNotes + ")"}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
              }
              {/*Engagement*/}
              <Grid item xs={12}>
                <Item>
                  <Box pl={1} pr={1} sx={{ textAlign: 'left' }}>
                    <Box pb={1} pt={1}>
                      <Typography variant="h7" sx={{ fontFamily: 'bitter', fontWeight: '600' }}
                        gutterBottom>
                        Engagement
                      </Typography>
                    </Box>

                    <Box className="mobile-drawer-box" onClick={CommentHandleOpen}>
                      <Box sx={{ display: 'inline-block' }}>
                        <CommentIcon className="mobile-icon mobile-save-icon" />
                      </Box>
                      <Typography variant="BODY_COPY1_RALEWAY_DESKTOP"
                        className='drawer-typography'>
                        Comments
                        <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" component="span" sx={{ fontSize: '12px', marginLeft: '10px' }}>
                          {"(" + numberOfComments}
                          {" - " + numberOfCommentsInUserJCs + " from your JCs"}
                          {")"}
                        </Typography>
                      </Typography>
                    </Box>

                    <Box className="mobile-drawer-box" onClick={() => dispatch(setLiked(PMID, userEmail, JCVerb, likedByUser, supportedByUser))}>
                      <Box sx={{ display: 'inline-block' }}>
                        {likedByUser ? <LikedIcon className="mobile-icon mobile-save-icon" fill="#FFFFFF" /> : <LikeIcon className="mobile-icon mobile-save-icon" />}
                      </Box>
                      <Typography variant="BODY_COPY1_RALEWAY_DESKTOP"
                        className='drawer-typography'>
                        Like
                        <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" component="span" sx={{ fontSize: '12px', marginLeft: '10px' }}>
                          {"(" + numberOfLikes}
                          {" - " + numberOfLikesInUserJCs + " from your JCs"}
                          {")"}
                        </Typography>
                      </Typography>
                    </Box>

                    <Box className="mobile-drawer-box" onClick={() => dispatch(setPracticeImpacted(PMID, userEmail, JCVerb, PIByUser, likedByUser))}>
                      <Box sx={{ display: 'inline-block' }}>
                        <PracticeIcon className="mobile-icon mobile-save-icon" fillcolor={PIByUser ? "#FFFFFF" : "None"} />
                      </Box>
                      <Typography variant="BODY_COPY1_RALEWAY_DESKTOP"
                        className='drawer-typography'>
                        Practice Impacting
                        <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" component="span" sx={{ fontSize: '12px', marginLeft: '10px' }}>
                          {"(" + numberOfPracticeImpacting}
                          {" - " + numberOfPracticeImpactingInUserJCs + " from your JCs"}
                          {")"}
                        </Typography>
                      </Typography>
                    </Box>

                  </Box>
                </Item>
              </Grid>

              {/* Comment popup */}
              {
                isDisabled === false && (
                  <CommentModal
                    open={commentOpen}
                    onClose={CommentHandleClose}
                    isNote={isNote}
                    isReply={false}
                    isJCPage={isJCPage}
                    isContributor={isContributor(jcUserDetails.role)}
                  />


                )
              }
              {/* Comment pop-up end*/}

              {!articleData?.isEditable &&
                <Grid item xs={12}>
                  <Item>
                    <Box pt={1} pl={1} pr={1} sx={{ textAlign: 'left' }}>
                      <Box pb={1}>
                        <Typography variant="h7" sx={{ fontFamily: 'bitter', fontWeight: '600' }}
                          gutterBottom>
                          Actions
                        </Typography>
                      </Box>

                      {!articleData?.isDeNovo &&
                        <Box className="mobile-drawer-box" onClick={handleProposeClickOpen}>
                          <Box sx={{ display: 'inline-block' }}>
                            <ProposeIcon className="mobile-icon" />
                          </Box>
                          <Typography variant="BODY_COPY1_RALEWAY_DESKTOP"
                            className='drawer-typography'>
                            Propose to JCs
                          </Typography>
                        </Box>
                      }

                      <ProposeToJC open={proposeOpen} onClose={proposeHandleClose} />

                      <Box className="mobile-drawer-box" onClick={() => dispatch(setSaved(PMID, userEmail, JCVerb, savedByUser))}>
                        <Box sx={{ display: 'inline-block' }}>
                          <SaveIcon className="mobile-icon mobile-save-icon" fillcolor={savedByUser ? "#FFFFFF" : "None"} />
                        </Box>
                        <Typography variant="BODY_COPY1_RALEWAY_DESKTOP"
                          className='drawer-typography'>
                          Save Article
                        </Typography>
                      </Box>

                      {!articleData?.isDeNovo &&
                        <Box className="mobile-drawer-box" onClick={handleOnShareClick}>
                          <Box sx={{ display: 'inline-block' }}>
                            <ShareIcon className="mobile-icon" />
                          </Box>
                          <Typography variant="BODY_COPY1_RALEWAY_DESKTOP"
                            className='drawer-typography'>
                            Share
                          </Typography>
                        </Box>
                      }
                    </Box>
                  </Item>
                </Grid>
              }

              {!articleData?.isDeNovo &&
                <Grid item xs={12}>
                  <Item sx={{ textAlign: 'left' }}>
                    <Button variant="outlined" sx={{ color: '#FFFFFF', border: '1px solid #FFFFFF', textTransform: 'capitalize', 'fontFamily': 'Raleway', fontWeight: '600' }} onClick={handleUrlClick}>
                      View Article
                    </Button>
                  </Item>
                </Grid>
              }

              {isJCPage && isAdmin(jcUserDetails.role) && status != "Rejected" && status != "Published" &&
                <Grid item xs={12}>
                  <Item sx={{ textAlign: 'left' }}>
                    <Box sx={{ display: 'inline-block' }}>
                      <Button variant="outlined" sx={{ color: '#FFFFFF', border: '1px solid #FFFFFF', textTransform: 'capitalize', 'fontFamily': 'Raleway', fontWeight: '600' }} onClick={handlePublishClickOpen}>
                        Publish Article
                      </Button>
                    </Box>
                    <Box sx={{ display: 'inline-block', float: 'right' }} mt={1} mr={2} onClick={handleRejectClickOpen}>
                      <Link variant="BODY_COPY1_RALEWAY" color='#FFFFFF'>
                        <Typography variant="BODY_COPY2_RALEWAY" sx={{ fontWeight: '700!important' }}>
                          Reject Article
                        </Typography>
                      </Link>
                    </Box>
                  </Item>
                </Grid>
              }

            </Grid>
            <PublishReject open={confirmOpen} onClose={handlePublishClose} isReject={rejectModel} />

            <div>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={toastUrlMsg}
                autoHideDuration={2000}
                onClose={handleUrlClose}
                key={'top' + 'center'}>
                <SnackbarContent sx={{
                  backgroundColor: '#262626',
                  fontFamily: 'Raleway',
                  fontSize: '12px',
                  padding: '0',
                  paddingLeft: '10px',
                  paddingRight: '10px'
                }}
                  message={"This article is not accessible on " + sourceName}
                />
              </Snackbar>
            </div>
          </Box>
        </StyledBox>
      </SwipeableDrawer>
      <ErrorDialogBox
        open={openLockStatusDialogBox}
        closeDialogHandler={() => setOpenLockStatusDialogBox(false)}
        title={"Article Lock Status"}
      >
        <Typography
          sx={{
            ...theme.typography.H3_RALEWAY,
            fontWeight: 500,
            marginTop: "16px"
          }}

        >{lockStatusMessage}</Typography>
      </ErrorDialogBox>
    </Root>
  );
}

export default BottomDrawer;
