import React, { useState } from 'react';
import { Switch, Box, Typography, toggleButtonClasses } from '@mui/material';  
import { styled } from '@mui/material';
import { JC_COLORS } from '../../../styles/constants';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#FFFFFF'
    },
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: JC_COLORS.JC_RED,
                opacity: 1,
                border: 0,
            },
        },
    },
    [theme.breakpoints.up('md')]: {
        width: "50px",
        height: "24px",
        padding: 0,
        margin: "0 11px",
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: "12px",
            height: "12px",
            margin: "0 3px"
        },
        '& .MuiSwitch-track': {
            borderRadius: "12px",
            backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
            opacity: 1,
        },
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: "6px 0px 6px 0px",
            '&.Mui-checked': {
                transform: 'translateX(32px)',
            }
        }
    },
    [theme.breakpoints.down('md')]: {
        width: "28px",
        height: "14px",
        padding: 0,
        margin: "0 5.5px",
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: "6px",
            height: "6px",
            margin: "0 1.5px"
        },
        '& .MuiSwitch-track': {
            borderRadius: "6px",
            backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
            opacity: 1,
        },
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: "4px 0px 4px 0px",
            '&.Mui-checked': {
                transform: 'translateX(16px)',
            }
        },
    }
}));


const SwitchWithText = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
        marginTop: '1rem',
    },
    [theme.breakpoints.down('md')]: {

    }

}));

const ToggleButton = ({leftText="All", rightText="Unread", leftSelected, toggleButtonHandler}) => {
    //const [leftSelected, setleftSelected] = useState(selected); 

    return (
        <SwitchWithText>
            <Typography variant="BODY_COPY1_RALEWAY" sx={{ color: leftSelected? JC_COLORS.JC_BLACK: "#848484" }}>{leftText}</Typography>
            <IOSSwitch
                checked={leftSelected===false}
                //onChange={() => setleftSelected(!leftSelected)}
                onChange = {toggleButtonHandler}
            />
            <Typography variant="BODY_COPY1_RALEWAY" sx={{ color: leftSelected? "#848484": JC_COLORS.JC_BLACK  }}>{rightText}</Typography>
        </SwitchWithText>
    )
}

export default ToggleButton