import { Box,Button,Typography } from '@mui/material';
import React, { useEffect, useState,useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import JCHeader from '../common/components/JCHeader';
import { setVerificationEmail } from '../state/actions/user';
import { JC_COLORS } from '../styles/constants';
import { AuthContext } from "../context/AuthContext";
import { generateAWSCognitoIdentity } from '../utils/awsCognitoUtils';
import { postResendVerification } from '../api';

function ResendEmail() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('');
  const [error,setError] = useState('')

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  useEffect(()=> {
    document.body.style.backgroundColor = JC_COLORS.JC_WHITE
    if(!auth.user.email){
      generateAWSCognitoIdentity(false)
    }else{
      navigate('/feed')
    }
    return () => document.body.style.backgroundColor = JC_COLORS.LIGHT_GREY
  },[])
  const handleOnSubmit = (event) => {
    event.preventDefault()
    const payloadEmail = email.trim()
    if(!validateEmail(payloadEmail)){
        setError("Please Enter valid email!")
    }else{
        //todo: handle on submit
        postResendVerification(payloadEmail).then(res => {
          dispatch(setVerificationEmail(payloadEmail))
          setError('')  
        }).catch(error => {
          // if(error?.response?.status === 404){
          //   setError("Unable to resend,Email is not Present!")
          // }
          dispatch(setVerificationEmail(email))
          setError('')
        }).finally(() => {
          setEmail('')
          navigate('/email-sent')
        }) 
    }
  };
  const validateEmail = (vemail) => {
    return /\S+@\S+\.\S+/.test(vemail);
  };
  return (
    <React.Fragment>
    <JCHeader />
    <Box mt={{xs:'15%',md:'10%'}}>
    <div className="content_wraper content_wraper_large content">
      <h1 className="jc-landing-page-heading jc-red">Email Verification</h1>
      <p className="jc-landing-page-paragraph" style={{ fontWeight: '600' }}>
        Please enter your email address to confirm your Journal Club Membership
      </p>
      <div style={{marginTop:'32px',marginBottom:'32px'}}>
      <form>
        <input
          type="text"
          placeholder="Email Address*"
          className="jc-input"
          value={email}
          onChange={handleEmailChange}
          required
        />
        {error &&
        <div style={{marginTop:'8px'}}>
            <p className='jc-error jc-red'>{error}</p>
        </div>}
        <Box mt={4}>
          <Button variant='contained' type="submit" onClick={handleOnSubmit} sx={{height:'35px'}}>
              <Typography variant='H3_RALEWAY'>
              RESEND VERIFICATION LINK
              </Typography>
          </Button>
        </Box>
      </form>
      </div>
    </div>
    </Box>
    <footer>
        <hr size={"2px"} /> 
        <p className="footer-copyright" >Journal Club &copy; 2022</p>
    </footer>
    </React.Fragment>
  );
}

export default ResendEmail;
