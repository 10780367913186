import * as api from "../../api"; 

//Action Creators
// export const getKeyWords = () => async (dispatch) => {
//     try {
//         const { data } = await api.fetchKeyWords(); 
//         const action = {
//             type: "GET_KEYWORDS",
//             payload: data
//         }
//         dispatch(action); 
//     } catch(error) {
//         console.log(error.message); 
//     }
// }

export const setKeywords = (data) => {
    return {
        type: "SET_KEYWORDS", 
        payload: data
    }
} 

export const keywordClicked = (data) => {
    return {
        type: "KEYWORD_CLICKED", 
        payload: data
    }
}

export const editKeywords = (data) => {
    return {
        type: "EDIT_KEYWORDS", 
        payload: data
    }
}

export const removeAnyKeywords = () => {
    return {
        type: "REMOVE_ANY_KEYWORDS"
    }
}

export const clearAllKeywords = () => {
    return {
        type: "CLEAR_ALL_KEYWORDS"
    }
}

export const keywordRemoved = (data) => {
    return {
        type: "KEYWORD_REMOVED", 
        payload: data
    }
}

export const keywordAdded = (data) => {
    return {
        type: "KEYWORD_ADDED", 
        payload: data
    }
}

