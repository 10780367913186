import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { AuthContext } from '../../context/AuthContext';
import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Divider, Collapse, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThanksEmoji, CopyLink } from '../../assets';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import { Avatar } from '@mui/material';
import { postArticleActions,postCommentNotes } from '../../api';
import { getArticle, setSaved,markArticleAsRead,getJournalClubArticle } from '../../state/actions/article';
import { getJournalClubDetails,getJCUserDetails,getJournalClubs } from '../../state/actions/journalclub';
import {DEFAULT_JC} from "../../constants/constants";
import { JC_COLORS } from '../../styles/constants';
import { wrapIntoPara } from '../../utils/editorBasics';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            borderRadius: '0px',
            margin: 0,
        }
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDialog-paper': {
            width: '40%',
            height: '68%'
        }
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
                backgroundColor: JC_COLORS.JC_RED,
                color: JC_COLORS.JC_WHITE,
            }}
            {...other}
        >
            {children}
            {onClose ? (
                <CloseIcon
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: JC_COLORS.JC_WHITE,
                        cursor: 'pointer',
                    }}

                />
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const jcChip = (jcName, jcColor, nameColor) => {
    return (
        <Chip
            avatar={<Avatar sx={{ bgcolor: jcColor, color: '#FFFFFF!important', fontFamily: 'RALEWAY!important', fontWeight: '600!important', fontSize: '14px!important' }}>{jcName.replace(/The |the |a |A |an |An /, '').trim().charAt(0)}</Avatar>}
            label={jcName}
            style={{ backgroundColor: 'white', fontFamily: 'RALEWAY', fontSize: '16px', color: nameColor, fontWeight: '600' }}
            sx={{
                '& .MuiChip-label':
                {
                    width: { xs: '75vw', md: 'auto' }
                }
            }} />
    )
};
function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

const InputWrapper = styled(Box)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
      width: '100%',
      fontFamily: 'Raleway',
      fontSize: '16px',
      marginBottom: '8px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  }));

function ProposeToJC(props) {
    const { open, onClose, publishJCIds } = props;
    const [value, setValue] = useState('')
    const [proposeNote,setProposeNote] = useState('')
    const [thanksJCName,setThanksJCName] = useState('')
    const [openThanks, setOpenThanks] = React.useState(false);
    const [proceedError, setProceedError] = React.useState(false);
    const [numOfContibutorJC,setNumOfContibutorJC] = React.useState(0);
    const [proposedJCList, setProposedJCList] = React.useState([]);
    const [rejectedJCList, setRejectedJCList] = React.useState([]);
    const [notProposedJCList, setNotProposedJCList] = React.useState([]);
    const { PMID, JCID } = useParams()
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const userEmail = auth.user.email;
    const {articleData} = useSelector((state) => state.article)
    const { jcList,jcDetails } = useSelector((state) => state.journalclub);
    const JCPage = JCID ? JCID === DEFAULT_JC ? false : true : false


    useEffect(() => {
        if (value === '') {
            setProceedError(true)
        }
        if (jcList.length == 0) {
            dispatch(getJournalClubs(userEmail))
        }
        if(jcList && articleData.journalClubs && jcDetails){
            // Get ID's of Published articles
            const currentJCID = JCID ? jcDetails.JCID : DEFAULT_JC
            const newPublishJCIds = articleData.journalClubs.filter(jc => jc.articleStatus === "Published").map(jc => jc.JCID)
            const rejectedJCIDs = articleData.journalClubs.filter(jc => jc.articleStatus === "Rejected").map(jc => jc.JCID)
            // Get list of JC's in which article is not published and User's role is Contributor and above
            const modifiedList = jcList.filter(jc => (jc.currentUser === "Contributor" || jc.currentUser === "Course Director" || jc.currentUser === "Administrator") && !newPublishJCIds.includes(jc.JCID) && !rejectedJCIDs.includes(jc.JCID))
            setNumOfContibutorJC(modifiedList.length)
            const NotPublishedJCIDs = modifiedList.map(jc => jc.JCID)
            const ProposeList = articleData.journalClubs.filter(jc => NotPublishedJCIDs.includes(jc.JCID))
            const ProposedJCIDS = ProposeList.map(jc => jc.JCID)
            const NotProposeList = jcList.filter(jc=> NotPublishedJCIDs.includes(jc.JCID) && !ProposedJCIDS.includes(jc.JCID) && jc.JCID != currentJCID)
            const rejectedList = jcList.filter(jc=> rejectedJCIDs.includes(jc.JCID) && !ProposedJCIDS.includes(jc.JCID) && jc.JCID != currentJCID)  
            setProposedJCList(ProposeList)
            setNotProposedJCList(NotProposeList)
            setRejectedJCList(rejectedList)
        }
    }, [articleData.journalClubs, jcList, value,jcDetails.JCID]);

    const postNote = (JCID, PMID, userEmail) => {
        postCommentNotes(JCID, PMID, userEmail, {
            text: wrapIntoPara(proposeNote),
            referenceCommentID: '',
            rootCommentID: '',
            isNote: true 
        }).then(res => {
            console.log("Note posted")
        }).catch(error => {
            console.error("Error in posting Note")
        })
    }
    const proposeButtonHandleClose = () => {
        function proposeToJCID(PMID, userEmail, JCID) {
            setProceedError(true)
            postArticleActions(PMID, userEmail, JCID, "propose").then(response => {
                if(proposeNote){
                    postNote(JCID, PMID, userEmail) 
                }
                onClose()
                setOpenThanks(true)
                setProceedError(false)
                setThanksJCName(jcList.filter(jc => jc.JCID === value)[0].name)
                setValue('')
                setProposeNote('')
            }
            ).catch(error => {
                const errorMsg = error.message
            })
        }
        proposeToJCID(PMID, userEmail, value)

    }
    const onChangeValue = (event) => {
        setValue(event.target.value)
        setProceedError(false)
    };

    const thanksHandleClose = () => {
        setOpenThanks(false)
        if (JCPage) {
            dispatch(getJournalClubArticle(JCID, PMID, userEmail, false))
        }
        else {
            dispatch(getArticle(PMID, userEmail, false))
        }
        dispatch(getJournalClubs(userEmail))
    }

    const getJCIconColor = (JCID,title) => {
        return jcList.filter(JC => JC.JCID === JCID)[0].iconColor ?? stringToColor(title ?? "")
    }
    return (
        <React.Fragment>
            {/**Propose to JC */}
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} >
                    <Typography variant="H3_RALEWAY" pl={{ xs: 0, md: 2.2 }}>
                        Propose to your Journal Clubs
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent>
                    <Box pl={{ xs: 0, md: 2 }}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={onChangeValue}>
                            {proposedJCList && proposedJCList.map((JC, idx) =>
                                <Box key={"ud" + JC.JCID}>
                                    <Box sx={{ display: 'inline-block' }}>
                                        <FormControlLabel value={"value1" + JC.JCID} control={<Radio />} label={jcChip(JC.name, "#bdbdbd", "#bdbdbd")} disabled />
                                    </Box>
                                    <Box sx={{ display: 'inline-block' }} ml={{ xs: 5, md: 1 }} >
                                        <Typography variant='BODY_COPY2_RALEWAY' color="#bdbdbd"> Article already in Review</Typography>
                                    </Box>
                                </Box>
                            )}
                            {rejectedJCList && rejectedJCList.length > 0 && rejectedJCList.map((JC, idx) =>
                                <Box key={"r" + JC.JCID}>
                                    <Box sx={{ display: 'inline-block' }}>
                                        <FormControlLabel value={"value1" + JC.JCID} control={<Radio />} label={jcChip(JC.name, "#bdbdbd", "#bdbdbd")} disabled />
                                    </Box>
                                    <Box sx={{ display: 'inline-block' }} ml={{ xs: 5, md: 1 }} >
                                        <Typography variant='BODY_COPY2_RALEWAY' color="#bdbdbd"> Article already Rejected</Typography>
                                    </Box>
                                </Box>
                            )}
                            {notProposedJCList && notProposedJCList.map((JC, idx) =>
                                <Box key={"p" + JC.JCID}>
                                    <Box sx={{ display: 'inline-block' }}>
                                        <FormControlLabel value={JC.JCID} control={<Radio />} label={jcChip(JC.name, getJCIconColor(JC.JCID,JC.name), "#262626")} />
                                    </Box>
                                    <Collapse in={value === JC.JCID}>
                                        <InputWrapper >
                                            <TextField placeholder="Add a note to contributors (Optional)" value={proposeNote} onChange={(event) => setProposeNote(event.target.value)} disableUnderline multiline={true} fullWidth={true} minRows={4} >
                                            </TextField>
                                        </InputWrapper>
                                    </Collapse>
                                </Box>
                            )}
                        </RadioGroup>
                        {notProposedJCList && proposedJCList && notProposedJCList.length < 1 && proposedJCList.length < 1 && rejectedJCList.length < 1 && numOfContibutorJC > 0 &&
                            <Box sx={{textAlign:'center',marginTop:'30%',maxWidth:'300px',marginLeft:'auto',marginRight:'auto'}}>
                                <Typography variant="H3_RALEWAY">
                                    Already Proposed to your Journal Clubs
                                </Typography>
                            </Box>
                        }
                        {notProposedJCList && proposedJCList && notProposedJCList.length < 1 && proposedJCList.length < 1 && rejectedJCList.length < 1 && numOfContibutorJC < 1 &&
                            <Box sx={{textAlign:'center',marginTop:'30%',maxWidth:'270px',marginLeft:'auto',marginRight:'auto'}}>
                                <Typography variant="H3_RALEWAY">
                                    You need to be a Contributor of a Journal Club to propose an article to it
                                </Typography>
                            </Box>
                        }
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center',paddingBottom:'16px!important' }}>
                    <Button variant='contained' type="submit" onClick={proposeButtonHandleClose} disabled={proceedError} sx={{height:'35px'}}>
                        <Typography variant='H3_RALEWAY'>
                            Proceed
                        </Typography>
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            {/**Propose to JC */}
            {/* Thank you pop-up*/}
            <BootstrapDialog
                onClose={thanksHandleClose}
                aria-labelledby="customized-dialog-title"
                open={openThanks}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={thanksHandleClose} >
                    <Typography variant="H3_RALEWAY">
                        Propose to your Journal Clubs
                    </Typography>
                </BootstrapDialogTitle>
                <DialogContent>
                    <Box sx={{ textAlign: 'center' }} mt={{ xs: '10%', md: 0 }} pt={3} pl={2} pr={2}>
                        <ThanksEmoji />
                        <Box pl={{ xs: 2, md: 6 }} pr={{ xs: 2, md: 6 }} mt={{ xs: 1, md: 0 }} mb={2}>
                            <Typography variant="h6"
                                sx={{
                                    fontFamily: 'bitter',
                                    fontWeight: '600'
                                }}
                                gutterBottom>
                                {"Thank you for proposing this article to " + thanksJCName }
                            </Typography>
                            <Box mt={{ xs: 2, md: 0 }}>
                                <Divider variant="inset" sx={{ width: '40%', marginLeft: { xs: '80px', sm: '165px', md: '30%' }, border: '1px solid #EF5B50' }} />
                            </Box>
                        </Box>
                        <Typography variant="BODY_COPY2_RALEWAY_DESKTOP"
                            sx={{
                                fontSize: '14px',
                                lineHeight: '24px',
                                fontWeight: '500'
                            }}
                            gutterBottom mb={2}>
                            You can share this article, add an internal note or a public comment as well as rate 
                            this article as 'Practice Impacting' in the 'Under Discussion' section of the journal club.
                        </Typography>
                        <Box pt={3} pl={{ xs: 2, md: 10 }} pr={{ xs: 2, md: 10 }}>
                            <Typography variant="BODY_COPY2_RALEWAY_DESKTOP"
                                sx={{
                                    fontSize: '12px',
                                    lineHeight: '24px',
                                    fontWeight: '500',
                                    fontStyle: 'italic'
                                }}
                                gutterBottom mb={2}>
                                Please ignore this message, if you have already given an action to this article.
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
            {/* Thank you pop-up end*/}

        </React.Fragment>
    )
}

export default ProposeToJC