import * as api from "../../api";

export const FETCHING_USER_FEED = "FETCHING_USER_LIST";
export const FETCH_USER_FEED_SUCCESS = "FETCH_USER_FEED_SUCCESS";
export const FETCH_NEXT_PAGE_USER_FEED_SUCCESS = "FETCH_NEXT_PAGE_USER_FEED_SUCCESS";
export const FETCH_USER_FEED_FAILURE = "FETCH_USER_FEED_FAILURE";
export const UPDATE_FFED_QUERY_PARAMETERS = "UPDATE_FFED_QUERY_PARAMETERS";
export const SET_CONTENT = "SET_CONTENT";

export const fetchingUserFeed = () => {
    return {
        type: FETCHING_USER_FEED
    }
}

export const fetchUserFeedSuccess = (data) => {
    return {
        type: FETCH_USER_FEED_SUCCESS,
        payload: data
    }
}

export const fetchNextPageUserFeedSuccess = (data) => {
    return {
        type: FETCH_NEXT_PAGE_USER_FEED_SUCCESS,
        payload: data
    }
}

export const fetchUserFeedFailure = (data) => {
    return {
        type: FETCH_USER_FEED_FAILURE,
        payload: data
    }
}

export const getUserFeed = (userEmail, queryParameters) => async (dispatch) => {
    try {
        dispatch(fetchingUserFeed())
        const { data } = await api.fetchUserFeed(userEmail, { ...queryParameters });
        dispatch(fetchUserFeedSuccess(data));
    } catch (error) {
        if (error?.response) {
            dispatch(fetchUserFeedFailure(error?.response));
        }
    }
}

export const fetchFeedInBackground = (userEmail,articleList = null) => async (dispatch) => {
    let appState = JSON.parse(localStorage.getItem("appState"));
    var queryParams = {pageNumber: 1,unreadOnly: false,orderBy: 'publicationdate',keyword: appState.feedQueryParams.keyword,source: appState.feedQueryParams.source}
    if( appState && appState.feedQueryParams.dateRangeLabel !== "All Time"){
        queryParams = {...queryParams,...appState.feedQueryParams.dateRange}
    }
    if(articleList === null){
        console.log("Fetching Feed in backgroud")
        dispatch(getUserFeed(userEmail,queryParams))
    }  
}

export const getNextPageUserFeed = (userEmail, queryParameters) => async (dispatch) => {
    try {
        dispatch(fetchingUserFeed());
        const { data } = await api.fetchUserFeed(userEmail, { ...queryParameters });
        dispatch(fetchNextPageUserFeedSuccess(data))
    } catch (error) {
        console.log(error)
        if (error?.response) {
            dispatch(fetchUserFeedFailure(error?.response));
        }
    }
}

export const getUserSavedArticles = (userEmail, queryParameters) => async (dispatch) => {
    try {
        dispatch(fetchingUserFeed())
        const { data } = await api.fetchUserSavedArticles(userEmail, { ...queryParameters });
        dispatch(fetchUserFeedSuccess(data));
    } catch (error) {
        console.log(error)
        if (error?.response) {
            dispatch(fetchUserFeedFailure(error?.response));
        }
    }
}

export const getNextPageUserSavedArticles = (userEmail, queryParameters) => async (dispatch) => {
    try {
        dispatch(fetchingUserFeed());
        //console.log("Fetching next page");
        const { data } = await api.fetchUserSavedArticles(userEmail, { ...queryParameters });
        dispatch(fetchNextPageUserFeedSuccess(data))
    } catch (error) {
        console.log(error)
        if (error?.response) {
            dispatch(fetchUserFeedFailure(error?.response));
        }
    }
}


export const updateFeedQueryParameters = (data = {}) => {
    return {
        type: UPDATE_FFED_QUERY_PARAMETERS,
        payload: data
    }
}

export const setContent = (data) => {
    return {
        type: SET_CONTENT,
        payload: data,
    }
}
