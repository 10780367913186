import React, { useState, useEffect, useContext, useCallback } from 'react';
import {  useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Switch, Hidden, Paper, CircularProgress, Tabs, tabsClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Spacer } from '../../../common/components';
import { KeyWordsList } from '../../../common/containers';
import useDebounce from "../../../common/hooks/useDebounce";
import { JC_COLORS } from '../../../styles/constants.js';
import { OPTIONS_SORT_ARTICLES_BY_TYPE } from '../../../constants/constants';
import ArticlesList from './ArticlesList';
import CustomDropdownDateRange from '../components/CustomDropdownDateRange';
import CustomDropdownArticleType from '../components/CustomDropdownArticleType';
import { AuthContext } from '../../../context/AuthContext';
import { updateFeedQueryParameters, getUserFeed } from '../../../state/actions/feed';
import FeedDescription from '../components/FeedDescription';
import { useKeywordsContext } from '../../../context/KeywordsContext';
import * as api from '../../../api';
import { useErrorHandler } from 'react-error-boundary';
import { updateReportNav } from '../../../state/actions/jcreports';
import TabsListKeywordsAndSources from '../../../common/containers/TabsListKeywordsAndSources';
import { getUserDetails } from '../../../state/actions/user';

const MainCard = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: JC_COLORS.JC_WHITE,
  [theme.breakpoints.up('md')]: {
    //width: "100vw", // 1369 / 1920
    //maxHeight: "75rem",  // 1203 / 1377 or 1203 to rem
    padding: "30px 0rem 54px 0rem",
  },
  [theme.breakpoints.down('md')]: {
    padding: '0rem 0rem 54px 0rem',
    margin: 0,
  }

}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '&.Mui-disabled .MuiSwitch-thumb': {
    color: '#FFFFFF'
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: JC_COLORS.JC_RED,
        opacity: 1,
        border: 0,
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        backgroundColor: JC_COLORS.LIGHT_GREY,
        opacity: 1,
      }
    }

  },
  [theme.breakpoints.up('md')]: {
    width: "50px",
    height: "24px",
    padding: 0,
    margin: "0 11px",
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: "12px",
      height: "12px",
      margin: "0 3px"
    },
    '& .MuiSwitch-track': {
      borderRadius: "12px",
      backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
      opacity: 1,
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: "6px 0px 6px 0px",
      '&.Mui-checked': {
        transform: 'translateX(32px)',
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    width: "32px",
    height: "18px",
    padding: 0,
    margin: "0 5.5px",
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: "8px",
      height: "8px",
      margin: "1px 5px"
    },
    '& .MuiSwitch-track': {
      borderRadius: "6px",
      backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
      opacity: 1,
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: "4px 0px 4px 0px",
      '&.Mui-checked': {
        transform: 'translateX(16px)',
      }
    },
  }




}));

const UnreadArticlesToggleButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    marginTop: '30px',
    marginBottom: '5px',
    marginLeft: '40px',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    borderBottom: "1px solid #838383",
    paddingBottom: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: "12px 15px 0px 15px",
    "*": {
      fontSize: '12px',
      lineHeight: '14.74px',
    }
  }

}));

const SwitchWithText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    marginTop: '12px',
  },
  [theme.breakpoints.down('md')]: {

  }

}));

const FlexEndText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'flex-end',
    margin: '20px 40px 12px 0px',
    fontWeight: 'bold',
    color: JC_COLORS.JC_RED,
    textDecoration: 'underline',
    cursor: 'pointer',
    ...theme.typography.BODY_COPY2_RALEWAY,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  }
}));

const TabsList = styled(Tabs)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [`& .${tabsClasses.scrollButtons}`]: {
    '&.Mui-disabled': { opacity: 0.3 },

  },
  '& .MuiTabs-indicator': { backgroundColor: "#FFFFFF" },
  [theme.breakpoints.up('md')]: {
    width: "80%",
    "*": {
      marginRight: "5px",
      marginBottom: "3px",
    }
  },
  [theme.breakpoints.down('md')]: {
    width: "100%",
    "*": {
      marginRight: "5px",
    }
  },
}));

const FeedContent = () => {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, feed } = useSelector((state) => state);
  const { isFetching, isUpdating, userDetails } = user;
  const { isFetchingFeed, queryParameters, numberOfUnreadArticles } = feed;

  const location = useLocation();
  const content = location.pathname.replace(/\//, '');
  let appState = JSON.parse(localStorage.getItem("appState"))
  const [changedState,setChangedState] = useState(false)
  const [dateRange, setDateRange] = useState(appState ? appState.feedQueryParams.dateRange : feed.queryParameters.dateRange);
  const [dateRangeLabel, setDateRangeLabel] = useState(appState ? appState.feedQueryParams.dateRangeLabel : feed.queryParameters.dateRangeLabel);
  const [orderByLabel, setOrderByLabel] = useState(appState ? appState.feedQueryParams.orderByLabel : feed.queryParameters.orderByLabel);
  const [unreadOnly, setUnreadOnly] = useState(appState ? appState.feedQueryParams.unreadOnly : feed.queryParameters.unreadOnly);



  const { keywordsState, sourcesState, setAllKeywords, setAllSources } = useKeywordsContext();
  const [keywordAndSource, setKeywordAndSource] = useState(getSelected(keywordsState, sourcesState));

  const handleError = useErrorHandler();

  useEffect(() => {
    dispatch(updateReportNav("Reports"))

    api.fetchUserDetails(userEmail).then(
      (res) => {
        let data = res?.data;
        if (data?.acceptedTermsAndConditions === null || data?.acceptedTermsAndConditions === false) {
          window.location = "/terms-and-conditions";
        } else if (
          data?.areasOfInterest === null || data?.areasOfInterest?.length < 1 ||
          data?.profession === null || data?.profession?.length < 1 ||
          data?.insitution === null || data?.institution?.length < 1
        ) {
          window.location = "/profile";
        } else {
          setAllKeywords(data?.keywords);
          setAllSources(data?.sources);
        }
      },
      (error) => {
        handleError("Error !!!")
      }
    )
  }, []);

  useEffect(() => {
    dispatch(getUserDetails(userEmail,false));
  }, [content])


  useEffect(() => {

    if (isFetchingFeed || isFetching) return
    let orderBy = OPTIONS_SORT_ARTICLES_BY_TYPE[orderByLabel];
    let queryParams = {
      orderBy,
      unreadOnly,
      pageNumber: 1
    }

    const { startDate, endDate } = dateRange;
    queryParams = startDate ? { ...queryParams, startDate } : { ...queryParams };
    queryParams = endDate ? { ...queryParams, endDate } : { ...queryParams };

    queryParams = { ...queryParams, ...keywordAndSource };

    queryParams = {
      ...queryParams,
      dateRangeLabel,
      dateRange,
      orderByLabel,
      orderBy,
    }

    dispatch(updateFeedQueryParameters(queryParams));
    if (isFetching === false) {
      var filterQueryParams = {
        pageNumber: 1,
        unreadOnly,
        orderBy,
        ...keywordAndSource
      }
      if (startDate && endDate) {
        filterQueryParams = { ...filterQueryParams, ...dateRange }
      }
      dispatch(
        getUserFeed(userEmail, filterQueryParams)
      );
    }
  }, [dateRange, dateRangeLabel, orderByLabel, keywordAndSource, unreadOnly]);


  useDebounce(() => {
    let newAppState = JSON.parse(localStorage.getItem("appState"));
    const newKeywordsSource = getSelected(keywordsState, sourcesState)
    const oldKeywordsSource = keywordAndSource
    if(oldKeywordsSource.keyword != newKeywordsSource.keyword || oldKeywordsSource.source != newKeywordsSource.source ){
      setDirtyState(newKeywordsSource,setKeywordAndSource)
      localStorage.setItem("appState", JSON.stringify({ ...newAppState, feedQueryParams: { ...newAppState.feedQueryParams, ...newKeywordsSource } }))
    }
  },1200,[keywordsState, sourcesState]);

  const setDirtyState = (val, callback) => {
    setChangedState(true)
    callback(val)
  }

  const changeDateRangeLabel = useCallback((value) => {
    setDirtyState(value, setDateRangeLabel);
  }, [dateRangeLabel]);
  
  const changeDateRangeHandler = useCallback((value) => {
    setDirtyState(value, setDateRange);
  }, [dateRange]);

  const changeOrderBy = useCallback((value) => {
    setDirtyState(value,setOrderByLabel)
  },[orderByLabel])

  const changeUnreadOnly = useCallback(() => {
    setDirtyState(!unreadOnly,setUnreadOnly)
  },[unreadOnly])
  
  return (

    <MainCard>
      <Hidden mdDown>
        <Typography
          variant="H1_BITTER_DESKTOP"
          fontWeight='bold'
          color="primary"
          display="flex"
          justifyContent="flex-start"
          sx={{ padding: "0 0 16px 40px" }}
        >
          Feed
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: "0 40px",
        }}>
          <CustomDropdownDateRange
            dateRangeLabel={dateRangeLabel}
            changeDateRangeLabel={changeDateRangeLabel}
            changeDateRangeHandler={changeDateRangeHandler}
            filterCount={4}
          />
          <CustomDropdownArticleType
            orderByLabel={orderByLabel}
            changeOrderBy={changeOrderBy}
          />
        </Box>


        <FlexEndText onClick={() => navigate('/edit-keywords-or-sources')}>
          Edit Feed Key Words/Sources
        </FlexEndText>


        <Box>
          <Box sx={{ marginBottom: "12px" }}>
            <KeyWordsList
              type={"keyword"}
              title={"Feed Key Words"}
              itemsObj={keywordsState}
              edit={false}
              showCloseIconForChip={false}
              disableClickOnChips={isFetchingFeed ? true : false}
            />
          </Box>
          <Box>
            <KeyWordsList
              type={"source"}
              title={"Sources"}
              itemsObj={sourcesState}
              edit={false}
              showCloseIconForChip={false}
              disableClickOnChips={isFetchingFeed ? true : false}
            />

          </Box>
        </Box>


        <Spacer height={"11px"} />


      </Hidden>

      
            <Hidden mdDown>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginRight: '40px'
                }}
              >

                <UnreadArticlesToggleButton>
                  <Typography
                    variant="BODY_COPY1_RALEWAY"
                    sx={(feed.isFetchingFeed || user.isFetching) ? { display: 'none' } : { display: 'block' }}
                  >
                    {numberOfUnreadArticles} Unread Articles
                  </Typography>
                  <SwitchWithText>
                    <Typography
                      variant="BODY_COPY1_RALEWAY"
                      sx={{
                        color: unreadOnly ? "#848484" : JC_COLORS.JC_BLACK
                      }}
                    >
                      All
                    </Typography>
                    <IOSSwitch
                      checked={unreadOnly}
                      onChange={changeUnreadOnly}
                      disabled={(feed.isFetchingFeed || user.isFetching)}
                    />
                    <Typography
                      variant="BODY_COPY1_RALEWAY"
                      sx={{
                        color: unreadOnly ? JC_COLORS.JC_BLACK : "#848484"
                      }}
                    >
                      Unread
                    </Typography>
                  </SwitchWithText>
                </UnreadArticlesToggleButton>
                <FeedDescription areasOfInterest={user?.userDetails?.areasOfInterest} />
              </Box>
            </Hidden>

            <Hidden mdUp>
              <Spacer height={"64px"} />
              <TabsListKeywordsAndSources 
                disableClickOnChips={isFetchingFeed ? true : false}
              />
              <FeedDescription areasOfInterest={user?.userDetails?.areasOfInterest} />
              <UnreadArticlesToggleButton>
                <Typography
                  variant="BODY_COPY1_RALEWAY"
                >
                  {
                    (feed.isFetchingFeed || user.isFetching) ? "" : `${numberOfUnreadArticles} Unread Articles`
                  }
                </Typography>
                <SwitchWithText>
                  <Typography
                    variant="BODY_COPY1_RALEWAY"
                    sx={{
                      color: unreadOnly ? "#848484" : JC_COLORS.JC_BLACK
                    }}
                  >
                    All
                  </Typography>
                  <IOSSwitch
                    checked={unreadOnly}
                    onChange={changeUnreadOnly}
                    disabled={(feed.isFetchingFeed || user.isFetching)}
                  />
                  <Typography
                    variant="BODY_COPY1_RALEWAY"
                    sx={{
                      color: unreadOnly ? JC_COLORS.JC_BLACK : "#848484"
                    }}
                  >
                    Unread
                  </Typography>
                </SwitchWithText>
              </UnreadArticlesToggleButton>
              {
                (user.isFetching || feed.isFetchingFeed) && (
                  <Box
                    sx={{ margin: "0 auto" }}
                  >
                    <CircularProgress />
                  </Box>
                )
              }
            </Hidden>
            <ArticlesList keywordsState={keywordsState} sourcesState={sourcesState}/>

    </MainCard>



  );
}
export default FeedContent


function getSelected(keywordsStateObj, sourcesStateObj) {

  return {
    keyword: Object.keys(keywordsStateObj ?? {}).filter(item => keywordsStateObj[item]).join('|'),
    source: Object.keys(sourcesStateObj ?? {}).filter(item => sourcesStateObj[item]).join(""),
  }


}


function combineObjects(keywordsStateObj, sourcesStateObj) {
  console.log(keywordsStateObj);
  console.log(sourcesStateObj);
  let obj1 = Object.keys(keywordsStateObj).reduce((acc, curr) => {
    acc[curr] = keywordsStateObj[curr];
    return acc;
  }, {});
  let obj2 = Object.keys(sourcesStateObj).reduce((acc, curr) => {
    acc[curr] = sourcesStateObj[curr];
    return acc;
  }, {});
  console.log(obj1);
  console.log(obj2);

  return { ...obj1, ...obj2 };

}
