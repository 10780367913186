import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField, Button, Paper, InputAdornment, Box, Typography, Hidden } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import debounce from "lodash/debounce";
import axios from 'axios';
import * as api from './../../api';

import { Row, Spacer } from "./../../common/components";
import { JC_COLORS } from "./../../styles/constants";
import { logout } from './../../utils';
import { PublicJCIcon, PrivateJCIcon } from '../../assets/SVG';
import connectionInstance from '../../utils/connection';
import { StyledSearchButton } from '../../pages/JCDetails/jc-content/report-content/ReportHelpers';
import CloseIcon from '@mui/icons-material/Close';
import useDebounce from '../hooks/useDebounce';


const encodeGetParams = params => Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    padding: 0,
    borderRadius: '3px',
    [theme.breakpoints.up('md')]: {
        width: '25vw',
        maxWidth: '600px',
        '& .MuiInputBase-root': {
            height: '40px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '2px solid #838383',
            },
        }
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        //width: 'auto',
        '& .MuiInputBase-root': {
            height: '35px',
            padding: '0px',
        },
        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '4px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '1px solid #838383',

            },
        }
    },
    '& .MuiFormControl-root': {
        padding: '0px',
    },

    '& .MuiOutlinedInput-root': {
        padding: "0px 10px",
        "& > *": {
            display: 'flex',
            alignItems: 'center',

        },
        '&:hover fieldset': {
            borderColor: JC_COLORS.JC_RED,
        },
        '&.Mui-focused fieldset': {
            borderColor: JC_COLORS.JC_RED
        },
    },

}));

const StyledSearchJCListButton = styled(Button)(({ theme }) => ({
    fontWeight: '600',
    fontFamily: 'Raleway',
    border: "1px solid " + JC_COLORS.JC_RED,
    [theme.breakpoints.up('md')]: {
        height: '40px',
        marginLeft: "16px",
    },
    [theme.breakpoints.down('md')]: {
        height: '35px',
        marginLeft: "8px",
    }

}));

const AddButton = styled(Button)(({ theme }) => ({
    fontWeight: 'bold',
    border: "2px solid " + JC_COLORS.JC_RED,


    [theme.breakpoints.up('md')]: {
        height: '40px',
        marginLeft: "16px",
    },
    [theme.breakpoints.down('md')]: {
        height: '35px',
        marginLeft: "10px",
    }

}));

const StyledOption = styled(Typography)(({ theme }) => ({
    textDecoration: 'none',
    color: JC_COLORS.JC_BLACK,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('md')]: {
        margin: '8px 8px',
        width: '36vw',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0px',
        minWidth: '65vw',
        margin: '0px 0px'
    }

}));


const SearchJC = ({ searchApiUrl, placeholder, searchResultsToShow = 15, searchButtonHandler, topic, status, approved }) => {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const [optionsList, setOptionsList] = useState([]);
    const [loadingOptionsList, setLoadingOptionsList] = useState(false);
    const [value, setValue] = useState({
        name: ""
    });
    const [query, setQuery] = useState("");
    const [userIsTyping, setUserIsTyping] = useState(false);

    const getAutocompleteResults = (query) => {
        let cancel;
        setUserIsTyping(false)
        setLoadingOptionsList(true);
        setOptionsList([]);
        if (query.length > 2) {
            connectionInstance.get(
                `${searchApiUrl}?${encodeGetParams({ prefix: query, topic: topic, status: status, approved: approved })}`,
                encodeURI({
                    cancelToken: new axios.CancelToken(c => cancel = c)
                })
            ).then(res => {
                let { options } = res.data;
                options = [...new Set(options.slice(0, searchResultsToShow))];
                setOptionsList(options);
                setLoadingOptionsList(false);
            }).catch(error => {
                console.log(error);
                if (error?.response && error?.response?.status === 401) {
                    logout()
                }
            })
            return () => cancel("Cancelling request due to key-debounce")

        } else {
            setOptionsList([]);
            setLoadingOptionsList(false);
        }
    }

    useDebounce(() => {
        getAutocompleteResults(query);
    }, 1200, [query])

    useEffect(() => {
        setUserIsTyping(true)
    }, [query])


    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
            }}
            >
                <Row sx={{
                    justifyContent: 'space-between',
                    width: '100%'
                }}>

                    <StyledAutocomplete
                        freeSolo
                        fullWidth
                        clearIcon={
                            <CloseIcon
                                fontSize="small"
                                onClick={() => {
                                    setQuery("");
                                    setValue({
                                        name: ""
                                    });
                                    searchButtonHandler("")
                                    setOptionsList([]);
                                    setLoadingOptionsList(false);
                                }}
                            />
                        }
                        loading={query.length > 0 && loadingOptionsList}
                        filterOptions={(x) => x}
                        value={value.name}
                        onChange={
                            (event, newValue) => {
                                if (newValue) {
                                    setValue(newValue)
                                } else {
                                    setValue({
                                        name: ""
                                    })
                                }
                            }
                        }
                        options={optionsList}
                        renderOption={
                            (props, option) => {
                                return (
                                    <StyledOption
                                        key={option.JCID}
                                        {...props}
                                    >
                                        <JournalClubItem
                                            jcName={option?.name}
                                            isPublic={option?.isPublic}
                                            JCID={option?.JCID}
                                            urlSlug={option?.urlSlug}
                                        />
                                    </StyledOption>
                                )
                            }
                        }
                        getOptionLabel={(option) => {

                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{
                                    backgroundColor: JC_COLORS.LIGHT_GREY,
                                    border: optionsList.length === 0 ? 'none' : `2px solid ${JC_COLORS.JC_ICON}`,
                                    borderRadius: '6px',
                                }}
                            >
                                {children}
                            </Paper>
                        )}

                        renderInput={(params) => {
                            params.InputProps.startAdornment = (
                                <>
                                    <InputAdornment position="start"><SearchIcon /></InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            );
                            params.InputProps.style = {
                                fontFamily: 'Raleway',
                                fontWeight: '600',


                            }

                            return (
                                <TextField
                                    {...params}
                                    placeholder={placeholder ?? "Search"}
                                    variant="outlined"
                                    onChange={e => setQuery(e.target.value)}
                                    onKeyPress={
                                        (e) => {
                                            if (e.key === "Enter") {
                                                if (value.urlSlug) {
                                                    window.location.href = `jc/${value.urlSlug}`
                                                }
                                                setQuery("");
                                                setValue({
                                                    name: ""
                                                });
                                            }
                                        }
                                    }

                                />

                            )
                        }

                        }
                    />
                    <StyledSearchJCListButton
                        variant="outlined"
                        onClick={() => searchButtonHandler(query)}
                    >
                        Search
                    </StyledSearchJCListButton>

                </Row>
                {
                    (query.length > 0 && optionsList.length === 0) && (
                        <Typography
                            variant="BODY_COPY1_RALEWAY"
                            fontWeight='bold'
                            color="primary"
                            sx={{ mt: "4px" }}
                        >
                            {userIsTyping ? "typing..." : ""}
                            {(!userIsTyping && query.length < 3) ? "OOPS!!! Please enter atleast 3 characters" : ""}
                            {(!userIsTyping && query.length >= 3 && optionsList.length === 0) ? "OOPS!!! No Matches" : ""}
                        </Typography>
                    )
                }

            </Box>
        </>



    )

}

export default SearchJC;

const JournalClubItem = ({ jcName, isPublic, JCID, urlSlug }) => {
    return (
        <Box
            onClick={() => window.location.href = `jc/${urlSlug}`}
            sx={{ cursor: 'pointer' }}
        >
            <Box
                sx={{
                    display: 'inline-block',
                    paddingTop: '3px'
                }}
                mr={1}
            >
                {isPublic ?
                    <PublicJCIcon width="18px" height="14px" />
                    :
                    <PrivateJCIcon width="18px" height="14px" />
                }
            </Box>
            <Box
                sx={{
                    display: 'inline-block'
                }}
            >
                <Typography variant="H3_RALEWAY">
                    {jcName}
                </Typography>
            </Box>
        </Box>
    )
}