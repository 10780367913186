import React, { useState, useEffect } from 'react';
import { Grid, Box, IconButton, Avatar, Typography, } from "@mui/material";
import { JC_COLORS } from '../../../../styles/constants';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteIntegrationCommand } from '@aws-sdk/client-api-gateway';


const SortableGrid = ({ listOfObjects, handleChange, improvisedVersion=false }) => {

    const [currentList, setCurrentList] = useState(listOfObjects || []);
    const [draggedObj, setDraggedObj] = useState(null);
    const [dragOverOnIndex, setDragOverOnIndex] = useState(null);
    const [droppedOnObj, setDroppedOnObj] = useState(null);
    const [hoveringOnIndex, setHoveringOnIndex] = useState(null);

    const [dragStartedFrom, setDragStartedFrom] = useState(null); 
    const [dragDroppedOn, setDragDroppedOn] = useState(null); 

    // useEffect(() => handleChange(currentList), [currentList]);
    // useEffect(() => {
    //     setCurrentList(listOfObjects)
    // }, [listOfObjects])

    const dragStart = (event, index) => {
        setDragStartedFrom(index)
        //console.log("drag Started from ", dragStartedFrom); 
        //const obj = currentList[index];
        //setDraggedObj(obj);
    }

    const dragDrop = (event, index) => {
        setDragOverOnIndex(null);
        setDragDroppedOn(index); 
        //console.log("drag Dropped on ", dragDroppedOn)
        // swap the elements positions
        let tempList = listOfObjects; 
        let temp = tempList[dragStartedFrom]
        tempList[dragStartedFrom] = tempList[index]
        tempList[index] = temp;  
        console.log(tempList)
        //let temp = currentList.filter((obj) => obj.institutionID !== draggedObj.institutionID);
        //temp = [...temp.slice(0, index), draggedObj, ...temp.slice(index, temp.length)];
        //setCurrentList(tempList);
        handleChange(tempList)
    }

    const deleteObjAtIndex = (index) => {
        handleChange(listOfObjects.filter((obj, i) => i !== index));
        setHoveringOnIndex(null);
    }

    
    const dragOver = (event, index) => {
        event.preventDefault();
        setDragOverOnIndex(index);
        //console.log("drag Over on ", dragOverOnIndex); 
    }
    const dragEnd = (event, index) => {
        // console.log(accreditorsList[i]);
        //console.log(`drag end`);
    }

    //useEffect(() => { if (dragOverOnIndex) console.log("Drag Over on ", dragOverOnIndex); }, [dragOverOnIndex]);


    if(improvisedVersion === true) {
        return (
            <Grid container>
               
                {
                    listOfObjects?.map(
                        (obj, i) => {
                            if (dragOverOnIndex === i && i !== currentList.length - 1) {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{
                                                //border: `1px solid red`,
                                                // height: "145px"
                                                paddingBottom: `${i % 2 === 0 ? "16px" : "0px"}`,
                                                paddingRight: `${i % 2 === 0 ? "16px" : "0px"}`,
                                            }}
                                            data-id={obj?.institutionID}
                                            draggable={"true"}
                                            onDragStart={(event) => dragStart(event, i)}
                                            onDragOver={event => dragOver(event, i)}
                                            onDragEnter={event => event.preventDefault()}
                                            onDragLeave={event => event.preventDefault()}
                                            onDrop={(event) => dragDrop(event, i)}
                                            onDragEnd={(event) => dragEnd(event, i)}
                                        >
                                            <></>
                                        </Grid>
                                        {/* <Grid
                                            item
                                            xs={6}
                                            sx={{
                                                paddingBottom: `${i % 2 === 0 ? "16px" : "0px"}`,
                                                paddingRight: `${i % 2 === 0 ? "16px" : "0px"}`,
                                            }}
                                            data-id={obj?.institutionID}
                                            draggable={"true"}
                                            onDragStart={(event) => dragStart(event, i)}
                                            onDragOver={event => dragOver(event, i)}
                                            onDragEnter={event => event.preventDefault()}
                                            onDragLeave={event => event.preventDefault()}
                                            onDrop={(event) => dragDrop(event, i)}
                                            onDragEnd={(event) => dragEnd(event, i)}
                                        >
                                            <SponsorsDetails name={obj.name || "empty"} />
                                        </Grid> */}
                                    </>
    
    
                                );
                            } else {
                                return (
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{
                                            paddingBottom: `${i % 2 === 0 ? "16px" : "0px"}`,
                                            paddingRight: `${i % 2 === 0 ? "16px" : "0px"}`,
                                        }}
                                        data-id={obj?.institutionID}
                                        draggable={"true"}
                                        onDragStart={(event) => dragStart(event, i)}
                                        onDragOver={event => dragOver(event, i)}
                                        onDragEnter={event => event.preventDefault()}
                                        onDragLeave={event => event.preventDefault()}
                                        onDrop={(event) => dragDrop(event, i)}
                                        onDragEnd={(event) => dragEnd(event, i)}
                                        onMouseOver={() => setHoveringOnIndex(i)}
                                        //onMouseOut={() => setHoveringOnIndex(null)}
                                    >
    
                                        <SponsorsDetails
                                            name={obj?.name || "empty"}
                                            logoUrl={obj?.logoUrl || obj?.logoFilePreview || ""}
                                            status={obj?.status || ""}
                                            showDeleteIcon={(hoveringOnIndex === i)}
                                            deleteIconHandler={() => {
                                                deleteObjAtIndex(i); 
                                                
                                            }}
                                        />
                                    </Grid>
                                )
    
                            }
                        }
                    )
                }
            </Grid>
        )
    }

    return (
        <Grid container>
            {
                listOfObjects?.map(
                    (obj, i) => {
                        // if (dragOverOnIndex === i && i !== currentList.length - 1) {
                        //     return (
                        //         <>
                        //             <Grid
                        //                 item
                        //                 xs={6}
                        //                 sx={{
                        //                     //border: `1px solid red`,
                        //                     // height: "145px"
                        //                     paddingBottom: `${i % 2 === 0 ? "16px" : "0px"}`,
                        //                     paddingRight: `${i % 2 === 0 ? "16px" : "0px"}`,
                        //                 }}
                        //                 data-id={obj?.institutionID}
                        //                 draggable={"true"}
                        //                 onDragStart={(event) => dragStart(event, i)}
                        //                 onDragOver={event => dragOver(event, i)}
                        //                 onDragEnter={event => event.preventDefault()}
                        //                 onDragLeave={event => event.preventDefault()}
                        //                 onDrop={(event) => dragDrop(event, i)}
                        //                 onDragEnd={(event) => dragEnd(event, i)}
                        //             >
                        //                 <></>
                        //             </Grid>
                        //             <Grid
                        //                 item
                        //                 xs={6}
                        //                 sx={{
                        //                     paddingBottom: `${i % 2 === 0 ? "16px" : "0px"}`,
                        //                     paddingRight: `${i % 2 === 0 ? "16px" : "0px"}`,
                        //                 }}
                        //                 data-id={obj?.institutionID}
                        //                 draggable={"true"}
                        //                 onDragStart={(event) => dragStart(event, i)}
                        //                 onDragOver={event => dragOver(event, i)}
                        //                 onDragEnter={event => event.preventDefault()}
                        //                 onDragLeave={event => event.preventDefault()}
                        //                 onDrop={(event) => dragDrop(event, i)}
                        //                 onDragEnd={(event) => dragEnd(event, i)}
                        //             >
                        //                 <SponsorsDetails name={obj.name || "empty"} />
                        //             </Grid>
                        //         </>


                        //     );
                        // } else {
                        //     return (
                        //         <Grid
                        //             item
                        //             xs={6}
                        //             sx={{
                        //                 paddingBottom: `${i % 2 === 0 ? "16px" : "0px"}`,
                        //                 paddingRight: `${i % 2 === 0 ? "16px" : "0px"}`,
                        //             }}
                        //             data-id={obj?.institutionID}
                        //             draggable={"true"}
                        //             onDragStart={(event) => dragStart(event, i)}
                        //             onDragOver={event => dragOver(event, i)}
                        //             onDragEnter={event => event.preventDefault()}
                        //             onDragLeave={event => event.preventDefault()}
                        //             onDrop={(event) => dragDrop(event, i)}
                        //             onDragEnd={(event) => dragEnd(event, i)}
                        //             onMouseOver={() => setHoveringOnIndex(i)}
                        //             //onMouseOut={() => setHoveringOnIndex(null)}
                        //         >

                        //             <SponsorsDetails
                        //                 name={obj.name || "empty"}
                        //                 showDeleteIcon={(hoveringOnIndex === i)}
                        //                 deleteIconHandler={() => {
                        //                     setCurrentList(currentList.filter((obj, j) => i !== j))
                        //                     setHoveringOnIndex(null);
                        //                 }}
                        //             />
                        //         </Grid>
                        //     )

                        // }

                        return (
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    paddingBottom: `${i % 2 === 0 ? "16px" : "0px"}`,
                                    paddingRight: `${i % 2 === 0 ? "16px" : "0px"}`,
                                }}
                                data-id={obj?.institutionID}
                                draggable={"true"}
                                onDragStart={(event) => dragStart(event, i)}
                                onDragOver={event => dragOver(event, i)}
                                onDragEnter={event => event.preventDefault()}
                                onDragLeave={event => event.preventDefault()}
                                onDrop={(event) => dragDrop(event, i)}
                                onDragEnd={(event) => dragEnd(event, i)}
                                onMouseOver={() => setHoveringOnIndex(i)}
                                //onMouseOut={() => setHoveringOnIndex(null)}
                            >

                                <SponsorsDetails
                                    name={obj?.name || "empty"}
                                    logoUrl={obj?.logoUrl || obj?.logoFilePreview || ""}
                                    status={obj?.status || ""}
                                    showDeleteIcon={(hoveringOnIndex === i)}
                                    deleteIconHandler={() => {
                                        setCurrentList(currentList.filter((obj, j) => i !== j))
                                        setHoveringOnIndex(null);
                                    }}
                                />
                            </Grid>
                        )

                    }
                )
            }
        </Grid>
    )
}

export default SortableGrid


function SponsorsDetails({ name, status, logoUrl, showDeleteIcon = false, deleteIconHandler }) {
    const [anchorEl, setAnchorEl] = useState(false);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: `${JC_COLORS.LIGHT_GREY}`,
                borderRadius: "8px",
                cursor: 'grabbing', 
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '30%',
                }}
            >
                <IconButton>
                    <DragIndicatorIcon />
                </IconButton>
                <Avatar 
                    variant="square" 
                    src={logoUrl ?? ""}
                    sx={{
                        height: "55px",
                        width: "55px",
                        margin: "20px 20px 20px 0px",
                    }}
                >
                    {name ? name[0].toUpperCase() : ""}
                </Avatar>
            </Box>


            <Box
                sx={{
                    width: "70%"
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "space-between",
                        alignItems: 'center'
                    }}>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Typography color="#262626"
                            variant="H3_RALEWAY"
                            sx={{
                                fontWeight: '700!important'
                            }}>
                            {name}
                        </Typography>

                        <Typography
                            variant="BODY_COPY2_RALEWAY"
                            color={JC_COLORS.JC_ICON}
                            sx={{
                                fontWeight: '700!important'
                            }}
                        >
                            {status}
                        </Typography>
                    </Box>
                    {
                        showDeleteIcon && (
                            <IconButton
                                onClick={deleteIconHandler}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )
                    }


                </Box>
            </Box>



        </Box>
    )
}