import React, { useState, useEffect, useContext } from 'react';
import { Hidden, Avatar, Box, Grid, IconButton, Link, Typography, TextField, Button, Menu, Dialog, DialogTitle, DialogContent, InputLabel, Select, MenuItem, capitalize } from '@mui/material';
import moment from 'moment';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import NumberFormat from 'react-number-format';
import { styled, useTheme } from '@mui/material/styles';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Switch } from '@mui/material';

import { CenterFlexBox, InlineBox } from './report-content/ReportHelpers';
import { ReactComponent as Edit } from '../../../assets/Edit.svg'
import { ReactComponent as EditBlack } from '../../../assets/EditBlack.svg'
import { ReactComponent as AddManage } from '../../../assets/AddManage.svg'
import { ReactComponent as Delete } from '../../../assets/Delete.svg'

import { JC_COLORS } from '../../../styles/constants';
import { AddCircle, PrivateJCIcon, PublicJCIcon } from '../../../assets/SVG';

import { NumberBox } from './report-content/RootReport';
import { useDispatch, useSelector } from 'react-redux';
import { getJournalClubDetails, setJCContent } from '../../../state/actions/journalclub';
import { INSTITUTION_STATUS, JC_CONTENT, NEWSLETTER_PUBLICATION_FREQUENCY } from '../../../constants/constants';
import SectionTitle from '../components/SectionTitle';
import MoreVert from '@mui/icons-material/MoreVert';
import { pxToRem } from '../../../utils';
import SquareAvatar from '../components/SquareAvatar';
import { CenteredActionButton } from '../../../common/components';
import DialogContentAddOrEditEntity from './manage-content/DialogContentAddOrEditEntity';
import MoreIconWithFunctions from './manage-content/EditOrDeleteEntity';
import EditOrDeleteEntity from './manage-content/EditOrDeleteEntity';
import DialogContentUploadCMEDisclosure from './manage-content/DialogContentUploadCMEDisclosure';

import { setContent } from '../../../state/actions/feed';
import SortableGrid from './manage-content/SortableGrid';
import useUpdateEffect from '../../../common/hooks/useUpdateEffect';
import { addJcInstitution, updateJcInstitutions, editJournalClub, fetchInstitutions, approveJournalClub } from '../../../api';
import { AuthContext } from '../../../context/AuthContext';
import ErrorDialogBox from '../../../common/components/ErrorDialogBox';
import { uploadToS3FromUrl } from '../../../utils/JCWysiwygEditor';
import { getReportRootData } from '../../../state/actions/jcreports';

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontWeight: 700,
  textTransform: 'none',

  [theme.breakpoints.up('md')]: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    //height: '42px',
    width: '142px',


  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(20),
    //height: '28px',
    minWidth: '150px',
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& .MuiDialogContent-root': {
      padding: "25px",
    },
    '& .MuiDialogActions-root': {
      padding: "25px",
    },
    '& .MuiDialog-paper': {
      width: '70%',
      //height: '68%'
    }
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
      width: '80%',
      height: 'auto',
    }
  },

}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: '3px',
  background: 'white',
  border: `1px solid ${JC_COLORS.JC_ICON}`,
  [theme.breakpoints.up('md')]: {
    width: '23vw',
    '& .MuiInputBase-root': {
      height: '42px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    }
  },
  [theme.breakpoints.down('md')]: {
    width: '75vw',
    '& .MuiInputBase-root': {
      height: '34px',
      padding: '0px 5px',
    },
  },

}));

const StyledDialog = styled(Dialog)(({ theme }) => ({

  '& .MuiDialogContent-root': {
    padding: "11px 4px",
  },
  '& .MuiDialogTitle-root': {
    padding: "10px 4px 0px 0px",
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '80%',
      height: 'auto',
    }
  },

}));

const GreyBox = styled(Box)(({ theme }) => ({
  backgroundColor: JC_COLORS.LIGHT_GREY,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('md')]: {
    borderRadius: '8px',
    padding: "9px 20px",
    marginBottom: '10px',
  },
  [theme.breakpoints.down('md')]: {
    borderRadius: '8px',
    padding: "9px 20px",
    marginBottom: '10px',
  }

}));


const SwitchWithText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    marginTop: '20px',
    marginBottom: '12px',
  },
  [theme.breakpoints.down('md')]: {

  }

}));


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '&.Mui-disabled .MuiSwitch-thumb': {
    color: '#FFFFFF'
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: JC_COLORS.JC_RED,
        opacity: 1,
        border: 0,
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        backgroundColor: JC_COLORS.LIGHT_GREY,
        opacity: 1,
      }
    }

  },
  [theme.breakpoints.up('md')]: {
    width: "50px",
    height: "24px",
    padding: 0,
    margin: "0 11px",
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: "12px",
      height: "12px",
      margin: "0 3px"
    },
    '& .MuiSwitch-track': {
      borderRadius: "12px",
      backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
      opacity: 1,
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: "6px 0px 6px 0px",
      '&.Mui-checked': {
        transform: 'translateX(32px)',
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    width: "32px",
    height: "18px",
    padding: 0,
    margin: "0 5.5px",
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: "8px",
      height: "8px",
      margin: "1px 5px"
    },
    '& .MuiSwitch-track': {
      borderRadius: "6px",
      backgroundColor: JC_COLORS.JC_RED, //'#E9E9EA',
      opacity: 1,
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: "4px 0px 4px 0px",
      '&.Mui-checked': {
        transform: 'translateX(16px)',
      }
    },
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  const MIN_VAL = 0;
  const MAX_VAL = 10;
  const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      fixedDecimalScale
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
});

const CME_CREDITS_NUMBER_CHANGED = "CME_CREDITS_NUMBER_CHANGED"
const JC_ACCREDITON_TYPE_CHANGED = "JC_ACCREDITON_TYPE_CHANGED"
const ACCREDITOR_AND_SUPPORTER_PRIORITY_CHANGED = "ACCREDITOR_AND_SUPPORTER_PRIORITY_CHANGED"

const initialAccreditorAndSupporterState = {
  cmeCreditsNumberChanged: false,
  jcAccreditionTypeChanged: false,
  accreditorSupporterPriorityChanged: false
}

const reducerForAccreditorAndSupporter = (state, action) => {
  switch (action.type) {
    case CME_CREDITS_NUMBER_CHANGED: {
      return {
        ...state,
        cmeCreditsNumberChanged: !state.cmeCreditsNumberChanged,
      }
    }
    case JC_ACCREDITON_TYPE_CHANGED: {
      return {
        ...state,
        jcAccreditionTypeChanged: !state.jcAccreditionTypeChanged,
      }
    }
    case ACCREDITOR_AND_SUPPORTER_PRIORITY_CHANGED: {
      return {
        ...state,
        accreditorSupporterPriorityChanged: true
      }
    }
    default:
      return state
  }
}

const ManageTab = () => {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;

  const theme = useTheme();

  const dispatch = useDispatch();
  const { journalclub, jcreports, user } = useSelector((state) => state);
  const { jcDetails } = journalclub;
  const { reportRootData } = jcreports;

  const { JCID, urlSlug, acceptedCmeDisclosure, cmeDownloadUrl } = jcDetails;




  // Accredtion Info
  const [openAddEntityDialogBoxForAccreditor, setOpenAddEntityDialogBoxForAccreditor] = useState(false);
  const [accreditorsAndSupportersList, setAccreditorsAndSupportersList] = useState(
    jcDetails?.institutions?.filter(obj => (
      obj.status === INSTITUTION_STATUS.ACCREDITOR ||
      obj.status === INSTITUTION_STATUS.SUPPORTER
    )).sort((institutionA, institutionB) => institutionA?.priority - institutionB?.priority) || []
  );
  const [cmeCreditsPerArticle, setCmeCreditsPerArticle] = useState(jcDetails?.cmeCreditsPerArticle ?? 0.1);
  const [isCmeAccrediting, setIsCmeAccrediting] = useState(jcDetails?.isCmeAccrediting ?? false);

  // --- For handling the pdf file upload
  const [openUploadCMEDisclouse, setOpenUploadCMEDisclosure] = useState(false);
  const [cmeDisclosureFile, setCmeDisclosureFile] = useState(null);

  const [isUpdatingAccreditorSupporterInfo, setIsUpdatingAccreditorSupporterInfo] = useState(false);
  // End of AccreditorInfo 


  // --- For handling Save button of accreditors and supporters 
  const [isCmeCreditsNumberChanged, setIsCmeCreditsNumberChanged] = useState(false);
  const [isJcAccreditionChanged, setIsJcAccreditionChanged] = useState(false);
  const [isAccreditorSupporterPriorityChanged, setIsAccreditorSupporterPriorityChanged] = useState(false);

  // PartnerInfo
  const [openAddEntityDialogBoxForPartner, setOpenAddEntityDialogBoxForPartner] = useState(false);
  const [partnersList, setPartnersList] = useState(
    jcDetails?.institutions?.filter(obj => obj.status === INSTITUTION_STATUS.PARTNER)?.sort((institutionA, institutionB) => institutionA?.priority - institutionB?.priority) ?? []
  )
  const [isPartnerPriorityChanged, setIsPartnerPriorityChanged] = useState(false);
  const [isUpdatingPartnerInfo, setIsUpdatingPartnerInfo] = useState(false);


  // API Error handlers 
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorStatusCode, setErrorStatusCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("Error");

  async function updateInstitutionsInfo(relationTypes) {
    let institutions = [];
    let entityList = relationTypes === INSTITUTION_STATUS.PARTNER ? partnersList : accreditorsAndSupportersList;
    if (relationTypes === 'Partner') {
      // show the updating text
      setIsUpdatingPartnerInfo(true);
      institutions = partnersList.map((obj, index) => {
        let { institutionID, status } = obj;
        return `institutionId=${institutionID}&institutionRelation=${status}&priority=${index + 1}`
      }).join("|");
    } else {
      // show the updating text
      setIsUpdatingAccreditorSupporterInfo(true);
      institutions = accreditorsAndSupportersList.map((obj, index) => {
        let { institutionID, status } = obj
        return `institutionId=${institutionID}&institutionRelation=${status}&priority=${index + 1}`
      }).join('|');
    }
    try {
      let res = await updateJcInstitutions(urlSlug, {
        authUserEmail: userEmail,
        institutions,
        relationTypes
      });
      if (res?.data?.message === "success") {
        // hide the updating text
        setIsUpdatingAccreditorSupporterInfo(false);
        setIsUpdatingPartnerInfo(false);
        // hide both save buttons after updating any institution
        setIsAccreditorSupporterPriorityChanged(false);
        setIsPartnerPriorityChanged(false);
        // Retain the manage tab selection
        dispatch(setJCContent(JC_CONTENT.MANAGE));
        // update the journal club details
        dispatch(
          getJournalClubDetails(
            urlSlug,
            userEmail,
            false,
          )
        );


      } else {
        // hide the updating text
        setIsUpdatingAccreditorSupporterInfo(false);
        setIsUpdatingPartnerInfo(false);
        // open the error dialog box with a message
        setOpenErrorDialog(true);
        setErrorMessage(JSON.stringify(res?.data));
        setErrorStatusCode(res?.status);

      }

    } catch (error) {
      // hide the updating text
      setIsUpdatingAccreditorSupporterInfo(false);
      setIsUpdatingPartnerInfo(false);
      // open the error dialog box with a message
      setOpenErrorDialog(true);
      setErrorMessage(error?.response?.data?.message);
      setErrorStatusCode(error?.response?.status);

    }
  }

  async function updateCmeAccreditionInfo() {
    setIsUpdatingAccreditorSupporterInfo(true);
    let clampedCmeCreditsPerArticle = 0.1;
    if (cmeCreditsPerArticle < 0.1) {
      clampedCmeCreditsPerArticle = 0.1
    } else if (cmeCreditsPerArticle > 10) {
      clampedCmeCreditsPerArticle = 10
    } else {
      clampedCmeCreditsPerArticle = cmeCreditsPerArticle
    }
    try {
      if (
        (cmeDisclosureFile === null) ||
        (cmeDisclosureFile === undefined) ||
        (typeof (cmeDisclosureFile) === 'string') ||
        (isCmeAccrediting === false)
      ) {

        let res = await editJournalClub(jcDetails?.urlSlug, {
          isCmeAccrediting,
          cmeCreditsPerArticle: clampedCmeCreditsPerArticle,
        });
        // console.log(res.data);
        setIsCmeCreditsNumberChanged(false);
        setIsJcAccreditionChanged(false);
        setIsUpdatingAccreditorSupporterInfo(false);
        dispatch(setJCContent(JC_CONTENT.MANAGE));
        dispatch(
          getJournalClubDetails(
            urlSlug,
            userEmail,
            false,
          )
        );
      } else {
        // Pass the file to the backend only if its cmeAccrediting and fileObject is not null
        let params = {};
        if (
          (cmeDisclosureFile === null) ||
          (cmeDisclosureFile === undefined)
        ) {
          params = {
            isCmeAccrediting,
            cmeCreditsPerArticle: clampedCmeCreditsPerArticle
          }
        } else {
          params = {
            cmeFilename: cmeDisclosureFile?.name,
            isCmeAccrediting,
            cmeCreditsPerArticle: clampedCmeCreditsPerArticle
          }
        }
        let res = await editJournalClub(urlSlug, { ...params });
        const { message, payload, url } = res?.data;
        if (
          (res?.data?.message === "success") &&
          (cmeDisclosureFile !== null) &&
          (cmeDisclosureFile !== undefined) &&
          (url)
        ) {
          uploadToS3FromUrl(url, { file: cmeDisclosureFile }, payload)
            .then(res => {
              setIsUpdatingAccreditorSupporterInfo(false);
              console.log(res);
              setCmeDisclosureFile(null);
              dispatch(
                getJournalClubDetails(
                  urlSlug,
                  userEmail,
                  false,
                  dispatch(setJCContent(JC_CONTENT.MANAGE))
                )
              );
            })
            .catch(
              err => {
                setOpenErrorDialog(true);
                setErrorMessage(err?.response?.data?.message);
                setErrorStatusCode(err?.response?.status);
              }
            )
        }

      }


    } catch (error) {
      console.log(error?.response);
      setOpenErrorDialog(true);
      setErrorMessage(error?.response?.data?.message);
      setErrorStatusCode(error?.response?.status);

    }
  }

  async function superAdminApproveJournalClub() {
    try {
      let params = {
        status: true,
        authUserEmail: userEmail, 
      };
      let res = await approveJournalClub(urlSlug, { ...params });
      if(res?.data) {
        dispatch(
          getJournalClubDetails(
            urlSlug,
            userEmail,
            false,
            dispatch(setJCContent(JC_CONTENT.MANAGE))
          )
        );
      }

    } catch(error) {
      console.log(error?.response);
      setOpenErrorDialog(true);
      setErrorMessage(error?.response?.data?.message);
      setErrorStatusCode(error?.response?.status);
    }
  }

  useEffect(() => {
    let filenameString = parseFileNameFromCmeDisclosureUrl(jcDetails?.cmeDownloadUrl)
    setCmeDisclosureFile(filenameString);
    var queryParams = {
      authUserEmail: userEmail,
    }
    dispatch(getReportRootData(jcDetails?.JCID, queryParams))

  }, [jcDetails])


  return (
    <React.Fragment>
      <ErrorDialogBox
        open={openErrorDialog}
        closeDialogHandler={() => setOpenErrorDialog(false)}
        errorMessage={errorMessage}
        title={`Error ${errorStatusCode}`}
      >
        <Typography>{errorMessage}</Typography>
      </ErrorDialogBox>
      <Hidden mdDown>
        <Box className='managetab-content-box'>
          <InlineBox className='managetab_heading'>
            <Typography variant="H2_RALEWAY" color={JC_COLORS.JC_ICON}>Journal Club Information</Typography>
            <IconButton
              sx={{
                cursor: 'pointer'
              }}
            >
              <Edit
                width={'20'}
                height={'20'}
                onClick={
                  () => {
                    dispatch(setJCContent(JC_CONTENT.EDIT_JC_INFO))
                  }
                }
              />

            </IconButton>

          </InlineBox>
          <Grid container>
            <Grid item xs={12} >
              <Grid container>
                <Grid item xs={4} className='managetab-content'>
                  <Typography variant="BODY_COPY2_RALEWAY" color="#262626" sx={{ fontWeight: '700 !important', marginBottom: '8px' }} >Journal Club Name</Typography>
                  <Typography variant="BODY_COPY2_RALEWAY" color="#838383" >{jcDetails.name}</Typography>
                </Grid>
                <Grid item xs={4} className='managetab-content'>
                  <Typography variant="BODY_COPY2_RALEWAY" color="#262626" sx={{ fontWeight: '700 !important', marginBottom: '8px' }}>Area of Interest</Typography>
                  <Typography variant="BODY_COPY2_RALEWAY" color="#838383" >{jcDetails?.areaOfInterest}</Typography>
                </Grid>
                <Grid item xs={4} className='managetab-content' sx={{ display: "none" }}>
                  <Typography variant="BODY_COPY2_RALEWAY" color="#262626" sx={{ fontWeight: '700 !important', marginBottom: '8px' }}>Topic</Typography>
                  <Typography
                    variant="BODY_COPY2_RALEWAY"
                    color="#838383"

                  >
                    {jcDetails?.topics?.map(obj => obj.topic).join(", ") || ""}
                  </Typography>
                </Grid>

              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} className='managetab-content'>
                  <InlineBox sx={{ display: 'flex', alignItems: 'center' }}>
                    {jcDetails?.isPublic ? <PublicJCIcon /> : <PrivateJCIcon />}
                    <Typography
                      variant="BODY_COPY2_RALEWAY"
                      ml={1}
                    >
                      {jcDetails?.isPublic ? "Public" : "Private"}
                    </Typography>
                  </InlineBox>
                </Grid>
                <Grid item xs={8} className='managetab-content'>
                  <Typography variant="BODY_COPY2_RALEWAY" color="#262626" sx={{ fontWeight: '700 !important', marginBottom: '8px' }} >Journal Club Description</Typography>
                  <Typography variant="BODY_COPY2_RALEWAY" color="#838383" >{jcDetails?.description}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} className='managetab-content'>
                  <Typography variant="BODY_COPY2_RALEWAY" color="#262626" sx={{ fontWeight: '700 !important', marginBottom: '8px' }} >Publication Frequency</Typography>
                  <Typography variant="BODY_COPY2_RALEWAY" color="#838383" >{jcDetails?.publicationFrequency ?? "Never"}</Typography>
                </Grid>

                {
                  (
                    (jcDetails?.publicationFrequency) &&
                    (jcDetails?.publicationFrequency.toLowerCase() !== NEWSLETTER_PUBLICATION_FREQUENCY["NEVER"].value.toLowerCase())
                  ) ? (
                    <>
                      <Grid item xs={4} className='managetab-content'>
                        <Typography variant="BODY_COPY2_RALEWAY" color="#262626" sx={{ fontWeight: '700 !important', marginBottom: '8px' }} >Reminder Frequency</Typography>
                        <Typography variant="BODY_COPY2_RALEWAY" color="#838383" >{jcDetails?.reminderEmailFrequency ?? 0}</Typography>
                      </Grid>
                      <Grid item xs={4} className='managetab-content'>
                        <Typography variant="BODY_COPY2_RALEWAY" color="#262626" sx={{ fontWeight: '700 !important', marginBottom: '8px' }} >Next Publication Date</Typography>
                        <Typography
                          variant="BODY_COPY2_RALEWAY"
                          color="#838383"
                        >
                          {moment(jcDetails?.nextPublishingDate ?? "").format("LL")}
                        </Typography>
                      </Grid>
                    </>
                  ) : (<p></p>)
                }

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4} className='managetab-content'>
                  {
                    (user?.userDetails?.superAdmin && jcDetails?.approved === false) ? (
                      <Button
                        variant="contained"
                        sx={{
                          width: "fit-content",
                          fontFamily: 'Raleway',
                          fontWeight: 'bold',
                          fontSize: '0.75rem',
                          lineHeight: '1.25rem',
                          textTransform: 'none',
                        }}
                        onClick={superAdminApproveJournalClub}
                      >
                        Approve
                      </Button>

                    ): <></>
                  }

                </Grid>



              </Grid>
            </Grid>

          </Grid>
        </Box>

        <Box className='managetab-content-box' sx={{ textAlign: 'start', marginTop: "0px" }}>
          <Box
            className='managetab_heading'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyConent: 'space-between',
              alignItems: 'flex-start',
            }}
          >

            <Typography variant="H2_RALEWAY" color={JC_COLORS.JC_ICON}>Educational Accreditation</Typography>
            <Button
              endIcon={<AddCircle width={"30"} height={"30"} />}
              sx={{
                ...theme.typography.H3_RALEWAY,
                textTransform: 'none',
                fontWeight: 700,
                margin: "0px 4px",
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                color: `${JC_COLORS.JC_ICON}`,
              }}
              onClick={
                () => {
                  setOpenAddEntityDialogBoxForAccreditor(true)
                }
              }
            >
              ADD ACCREDITOR / SUPPORTER
            </Button>
          </Box>


          <Grid container mt={2} mb={2}>
            <Grid item xs={12}>
              <SortableGrid
                listOfObjects={accreditorsAndSupportersList}
                handleChange={
                  (newList) => {
                    setAccreditorsAndSupportersList([...newList])
                    setIsAccreditorSupporterPriorityChanged(true);
                  }
                }
                improvisedVersion={true}
              />

            </Grid>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <RowRadioButtonsGroup
              isCmeAccrediting={isCmeAccrediting}
              handleChange={
                (value) => {
                  setIsCmeAccrediting(value)
                  setIsCmeCreditsNumberChanged(true);
                }
              }
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',

              }}
            >
              {
                isCmeAccrediting && (
                  <Button
                    endIcon={<AddCircle width={"30"} height={"30"} />}
                    sx={{
                      ...theme.typography.H3_RALEWAY,
                      textTransform: 'none',
                      fontWeight: 700,
                      margin: "16px 4px 0px 4px",
                      display: `${typeof (cmeDisclosureFile) === 'string' ? 'none' : 'flex'}`,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      color: `${JC_COLORS.JC_ICON}`
                    }}
                    onClick={() => { setOpenUploadCMEDisclosure(true) }}
                  >
                    UPLOAD DISCLOSURE PDF
                  </Button>
                )
              }
              {
                (isCmeAccrediting && cmeDisclosureFile) && (
                  <Button
                    endIcon={
                      <IconButton
                        onClick={
                          () => {
                            setCmeDisclosureFile(null)
                          }
                        }>
                        <CloseIcon />
                      </IconButton>
                    }
                    sx={{
                      ...theme.typography.H3_RALEWAY,
                      textTransform: 'none',
                      fontWeight: 700,
                      margin: "0px 4px",
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      color: `${JC_COLORS.JC_ICON}`
                    }}
                    onClick={() => { }}
                  >
                    {
                      typeof (cmeDisclosureFile) === "string" ? cmeDisclosureFile : cmeDisclosureFile?.name
                    }

                  </Button>

                )
              }

              {
                (
                  (isCmeAccrediting && (cmeDisclosureFile === null || cmeDisclosureFile === undefined))
                ) ? (
                  <Typography
                    sx={{
                      color: JC_COLORS.JC_RED,
                      textAlign: 'left',
                      fontSize: '12px',
                      lineHeight: '14px',
                      marginTop: "8px",
                      marginLeft: "12px"

                    }}
                  >
                    No .pdf file found
                  </Typography>
                ) : <p></p>
              }


            </Box>
          </Box>

          {
            isCmeAccrediting && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start'

                }}
              >
                <Typography
                  variant="BODY_COPY2_RALEWAY"
                  color={JC_COLORS.JC_ICON}
                  sx={{ mt: "8px" }}
                >
                  CME / CE Credits for each article
                </Typography>
                <Box
                  sx={{
                    marginLeft: "8px",
                    width: "64px",
                  }}
                >
                  <input
                    type="number"
                    step="0.01"
                    min="0.1"
                    max="10"
                    value={cmeCreditsPerArticle}
                    onChange={
                      (event) => {
                        let val = event.target.value;
                        setCmeCreditsPerArticle(val)
                        setIsCmeCreditsNumberChanged(true);
                      }
                    }
                    style={{
                      height: "32px",
                      width: "64px"
                    }}
                  />
                  {
                    (cmeCreditsPerArticle < 0.1 || cmeCreditsPerArticle > 10) ? (
                      <Typography
                        sx={{
                          color: JC_COLORS.JC_RED,
                          textAlign: 'left',
                          fontSize: '12px',
                          lineHeight: '14px',
                          marginTop: "8px",

                        }}
                      >
                        CME Credits should be in the range 0.1-10
                      </Typography>

                    ) : <p></p>
                  }

                </Box>



                <Typography
                  //variant="BODY_ARTICLE_RALEWAY"
                  variant="BODY_COPY2_RALEWAY"
                  color="#838383"
                  sx={{
                    marginLeft: '24px',
                    marginTop: "8px",
                  }}
                >
                  You can assign different CME/CE values for original articles that are not based on journal publications at the time of creation.
                </Typography>

              </Box>

            )
          }
          {
            isUpdatingAccreditorSupporterInfo && (
              <Typography
                variant="BODY_ARTICLE_RALEWAY"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                Updating....Please wait
              </Typography>
            )
          }
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "fit-content",
                fontFamily: 'Raleway',
                fontWeight: 'bold',
                fontSize: '0.75rem',
                lineHeight: '1.25rem',
                textTransform: 'none',
                display: `${(isCmeCreditsNumberChanged ||
                  isJcAccreditionChanged ||
                  isAccreditorSupporterPriorityChanged ||
                  (cmeDisclosureFile === null || typeof (cmeDisclosureFile) === 'object')
                ) ?
                  'inline-block' :
                  'none'
                  }`
              }}
              onClick={
                () => {
                  if (isAccreditorSupporterPriorityChanged) {
                    //send patch request to update the accreditor-supporter priority
                    updateInstitutionsInfo("Supporter|Accreditor");
                  }
                  if (isCmeCreditsNumberChanged || isJcAccreditionChanged || cmeDisclosureFile !== null) {
                    //send patch request to update the accreditor info
                    updateCmeAccreditionInfo()
                  } else {
                    return
                  }

                }
              }
              disabled={
                (
                  (isUpdatingAccreditorSupporterInfo) ||
                  (isCmeAccrediting && (cmeCreditsPerArticle < 0.1 || cmeCreditsPerArticle > 10)) ||
                  (isCmeAccrediting && (cmeDisclosureFile === null || cmeDisclosureFile === undefined))
                )
              }
            >
              Save
            </Button>


          </Box>
        </Box>


        <Box className='managetab-content-box'>
          <InlineBox className='managetab_heading'>
            <Typography variant="H2_RALEWAY" color={JC_COLORS.JC_ICON}>Members</Typography>
            <Link>
              <Typography
                variant="BODY_ARTICLE_RALEWAY"
                sx={{ fontWeight: '700!important', fontSize: '15px !important', cursor: 'pointer', marginRight: "8px" }}
                onClick={
                  () => {
                    dispatch(setJCContent("MANAGE_MEMBERS"))
                  }
                }
              >
                MANAGE MEMBERS
              </Typography>
            </Link>
          </InlineBox>
          <Grid container>
            <Grid item xs={12} md={8} mt={2}>
              <Grid container spacing={{ xs: 1.5 }}>
                <Grid item xs={6} md={2.4}>
                  <NumberBox numberOf={reportRootData?.numAllReaders} title="Readers" top={false} />
                </Grid>
                <Grid item xs={6} md={2.4}>
                  <NumberBox numberOf={reportRootData?.numAllContributors} title="Contributors" top={false} />
                </Grid>
                <Grid item xs={6} md={2.4}>
                  <NumberBox numberOf={reportRootData?.numAllCourseDirectors} title="Course Directors" top={false} />
                </Grid>
                <Grid item xs={6} md={2.4}>
                  <NumberBox numberOf={reportRootData?.numAllAdministrators} title="Administrators" top={false} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box className='managetab-content-box' sx={{ textAlign: 'start', marginTop: '0px' }}>
          <Box
            className='managetab_heading'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              variant="H2_RALEWAY"
              color={JC_COLORS.JC_ICON}
            >
              Partners
            </Typography>
            <Button
              endIcon={<AddCircle width={"30"} height={"30"} />}
              sx={{
                ...theme.typography.H3_RALEWAY,
                textTransform: 'none',
                fontWeight: 700,
                margin: "0px 4px",
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                color: `${JC_COLORS.JC_ICON}`
              }}
              onClick={() => { setOpenAddEntityDialogBoxForPartner(true) }}
            >
              ADD PARTNER
            </Button>
          </Box>


          <Grid container mt={2} mb={2}>
            <Grid item xs={12}>
              <SortableGrid
                listOfObjects={partnersList}
                handleChange={
                  (newList) => {
                    setPartnersList([...newList])
                    setIsPartnerPriorityChanged(true);

                  }
                }
                improvisedVersion={true}
              />
            </Grid>
          </Grid>
          {
            isUpdatingPartnerInfo && (
              <Typography
                variant="BODY_ARTICLE_RALEWAY"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                Updating....Please wait
              </Typography>
            )
          }

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            {
              isPartnerPriorityChanged && (
                <Button
                  variant="contained"
                  sx={{
                    width: "fit-content",
                    fontFamily: 'Raleway',
                    fontWeight: 'bold',
                    fontSize: '0.75rem',
                    lineHeight: '1.25rem',
                    textTransform: 'none',
                    display: 'inline-block'
                  }}
                  onClick={
                    () => {
                      updateInstitutionsInfo("Partner")
                    }
                  }
                  disabled={isUpdatingPartnerInfo}
                >
                  Save
                </Button>

              )
            }

          </Box>
        </Box>

        <Box className='managetab-content-box' sx={{ textAlign: 'start' }}>
          <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", alignItems: 'center' }}>
            <Typography variant="H2_RALEWAY" color={JC_COLORS.JC_ICON}>Affiliates</Typography>
            <Typography
              variant="H3_RALEWAY"
              sx={{
                color: JC_COLORS.JC_RED,
                padding: "0 0 0 18px"
              }}
            >
              COMING SOON
            </Typography>

          </Box>

        </Box>


        <Box className='managetab-content-box' sx={{ textAlign: 'start' }}>
          <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", alignItems: 'center' }}>
            <Typography variant="H2_RALEWAY" color={JC_COLORS.JC_ICON}>Social Media Channels</Typography>
            <Typography
              variant="H3_RALEWAY"
              sx={{
                color: JC_COLORS.JC_RED,
                padding: "0 0 0 18px"
              }}
            >
              COMING SOON
            </Typography>

          </Box>

        </Box>





      </Hidden>
      <Hidden mdUp>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "150px" }}>
          <Typography variant="H2_RALEWAY"  >
            Please use a desktop to access this feature
          </Typography>
        </Box>

      </Hidden>

      <BootstrapDialog
        open={openAddEntityDialogBoxForAccreditor || openAddEntityDialogBoxForPartner}
        onClose={() => {
          if (openAddEntityDialogBoxForAccreditor === true) {
            setOpenAddEntityDialogBoxForAccreditor(false)
          }
          if (openAddEntityDialogBoxForPartner === true) {
            setOpenAddEntityDialogBoxForPartner(false)
          }

        }}
      >
        <DialogContentAddOrEditEntity
          userEmail={userEmail}
          relationTypes={
            openAddEntityDialogBoxForPartner ?
              INSTITUTION_STATUS.PARTNER : `${INSTITUTION_STATUS.SUPPORTER}|${INSTITUTION_STATUS.ACCREDITOR}`
          }
          saveButtonHandler={
            (data) => {
              if (data?.status === INSTITUTION_STATUS.ACCREDITOR || data?.status === INSTITUTION_STATUS.SUPPORTER) {
                let institutionIsPresentAlready = accreditorsAndSupportersList.find(
                  obj => (
                    obj?.institutionID === data?.institutionID &&
                    obj?.name === data?.name &&
                    obj?.status === data?.status
                  )
                );
                if (institutionIsPresentAlready) {
                  setIsAccreditorSupporterPriorityChanged(true);
                } else {
                  if (data?.updatedLogo) {
                    console.log("showing update logo on draggable element")
                    let updatedList = accreditorsAndSupportersList.map(obj => {
                      if (obj.institutionID === data?.institutionID) {
                        obj.logoUrl = data.logoUrl;
                      }
                      return obj;

                    });
                    setAccreditorsAndSupportersList([...updatedList, data]);
                  } else {
                    setAccreditorsAndSupportersList([...accreditorsAndSupportersList, data]);
                  }
                  setIsAccreditorSupporterPriorityChanged(true);
                }

              } else {
                let institutionIsPresentAlready = partnersList.find(
                  obj => (
                    obj?.institutionID === data?.institutionID &&
                    obj?.name === data?.name &&
                    obj?.status === data?.status
                  )
                );
                if (institutionIsPresentAlready) {
                  setIsPartnerPriorityChanged(true);
                } else {
                  setPartnersList([...partnersList, data])
                  setIsPartnerPriorityChanged(true);
                }

              }


              if (openAddEntityDialogBoxForAccreditor === true) {
                setOpenAddEntityDialogBoxForAccreditor(false)
              }
              if (openAddEntityDialogBoxForPartner === true) {
                setOpenAddEntityDialogBoxForPartner(false)
              }
              // fetch the jc details again to get the new list
            }
          }
          closeIconHandler={
            () => {
              if (openAddEntityDialogBoxForAccreditor === true) {
                setOpenAddEntityDialogBoxForAccreditor(false)
              }
              if (openAddEntityDialogBoxForPartner === true) {
                setOpenAddEntityDialogBoxForPartner(false)
              }
            }
          }
        />
      </BootstrapDialog>

      <BootstrapDialog
        open={openUploadCMEDisclouse}
        onClose={() => setOpenUploadCMEDisclosure(false)}
      >
        <DialogContentUploadCMEDisclosure
          urlSlug={jcDetails?.urlSlug}
          closeIconHandler={() => setOpenUploadCMEDisclosure(false)}
          uploadButtonHandler={
            (fileObject) => {
              setCmeDisclosureFile(fileObject);
              setOpenUploadCMEDisclosure(false);
            }
          }
        />
      </BootstrapDialog>
    </React.Fragment>
  )
}

export default ManageTab





function RowRadioButtonsGroup({ isCmeAccrediting, handleChange }) {
  const theme = useTheme();
  const [value, setValue] = useState(isCmeAccrediting ? "isCmeAccrediting" : "isEducational")
  return (
    <FormControl className='manage_radio_label'>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        value={value}
        onChange={
          (event) => {
            setValue(event.target.value);
            handleChange(event.target.value === "isCmeAccrediting" ? true : false);
          }
        }
      >
        <FormControlLabel value={"isCmeAccrediting"} control={<Radio />} label="CME/CE Accredited" />
        <FormControlLabel value={"isEducational"} control={<Radio />} label="Educational" />
      </RadioGroup>
    </FormControl>
  );
}


function parseFileNameFromCmeDisclosureUrl(url) {
  if (url) {
    let temp = url.split('?')[0];
    let temp1 = temp.split('/');
    let str = temp1.length > 0 ? temp1[temp1.length - 1] : ""
    return str === "undefined" ? null : decodeURI(str);
  } else {
    return
  }


}

function SponsorsDetails({ name, showDeleteIcon = false, deleteIconHandler }) {
  const [anchorEl, setAnchorEl] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: `${JC_COLORS.LIGHT_GREY}`,
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '35%',
        }}
      >
        <IconButton>
          <DragIndicatorIcon />
        </IconButton>
        <Avatar variant="square" src={""}
          sx={{
            height: "55px",
            width: "55px",
            margin: "20px 35px",
          }}
        >
          {name ? name[0].toUpperCase() : ""}
        </Avatar>
      </Box>


      <Box
        sx={{
          width: "65%"
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: 'center'
          }}>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            <Typography color="#262626"
              variant="H3_RALEWAY"
              sx={{
                fontWeight: '700!important'
              }}>
              {name}
            </Typography>

            <Typography
              variant="BODY_COPY2_RALEWAY"
              color={JC_COLORS.JC_ICON}
              sx={{
                fontWeight: '700!important'
              }}
            >
              Accreditor
            </Typography>
          </Box>
          {
            showDeleteIcon && (
              <IconButton
                onClick={deleteIconHandler}
              >
                <DeleteIcon />
              </IconButton>
            )
          }


        </Box>
      </Box>



    </Box>
  )
}

function UsersManage({ name }) {
  return (
    <CenterFlexBox style={{ margin: "0px 25px 10px 5px" }}>
      <InlineBox mr={1}>
        <Avatar variant="square" alt="Benjamin Scricia" src={""}
          sx={{
            height: "45px",
            width: "45px",
          }}
        >{name[0].toUpperCase()}</Avatar>
      </InlineBox>
      <InlineBox>
        <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '700!important', marginRight: '10px' }}>
          {name}
        </Typography>
        <EditBlack width={'15'} height={'15'} />
      </InlineBox>
    </CenterFlexBox>
  )
}

function SocialMedia({ name, connected }) {
  return (
    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
      <InlineBox mr={1}>
        <Avatar alt="Benjamin Scricia" src={""}
          sx={{
            height: "45px",
            width: "45px",
          }}
        >{name[0].toUpperCase()}</Avatar>
      </InlineBox>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '700!important' }}>
          {name}
        </Typography>
        <Typography variant="BODY_ARTICLE_RALEWAY" color="#838383" >{connected ? "Connected" : "Not Connected"}</Typography>
      </Box>
    </Grid>
  )
}

function AffiliatesManage({ name, status = null }) {
  return (
    <CenterFlexBox style={{ margin: "0px 25px 10px 5px" }}>
      <InlineBox mr={1} sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#F3F3F3', padding: '8px', borderRadius: '4px', opacity: `${status ? '0.7' : '1'}` }}>
        <Avatar alt="Benjamin Scricia" src={""}
          sx={{
            height: "35px",
            width: "35px",
          }}
        >{name[0].toUpperCase()}</Avatar>
        <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '600!important', marginLeft: '5px' }}>
          {name}
        </Typography>
      </InlineBox>
      <Box sx={{ display: 'flex', alignItem: 'center' }}>
        <Typography variant="BODY_ARTICLE_RALEWAY" color="#838383" sx={{ fontStyle: 'italic', marginRight: '8px' }} >{status && "Pending"}</Typography>
        <Delete width={'22'} height={'22'} />
      </Box>
    </CenterFlexBox>
  )
}

/* {
                cmeDownloadUrl && (
                  <Button
                    endIcon={<CloseIcon />}
                    sx={{
                      ...theme.typography.H3_RALEWAY,
                      textTransform: 'none',
                      fontWeight: 700,
                      margin: "16px 4px",
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      color: `${JC_COLORS.JC_ICON}`
                    }}
                    onClick={() => { setOpenUploadCMEDisclosure(true) }}
                  >
                    {
                      cmeDownloadUrl.split('?')[0]
                    }

                  </Button>

                )
              } */

// useEffect(() => {
//   if (dragOverOnIndex) {
//     console.log("Drag Over on ", dragOverOnIndex);
//     let emptyObj = {};
//     let temp = [...accreditorsList.filter(obj => obj.name !== 'empty')]; 
//     temp = [...accreditorsList.slice(0, hoveringOn), emptyObj, ...accreditorsList.slice(hoveringOn, accreditorsList.length)];
//     setAccreditorsList(temp); 
//   }
// }, [dragOverOnIndex]);

// useEffect(() => {
//   console.log(hoveringOnIndex)
// }, [hoveringOnIndex])

// const dragStart = (event, index) => {
//   const obj = accreditorsList[index];
//   setDraggedObj(obj);
// }
// const dragOver = (event, index) => {
//   event.preventDefault();
//   setDragOverOnIndex(index);
// }

// const dragDrop = (event, index) => {
//   setDragOverOnIndex(null);
//   let temp = accreditorsList.filter((obj) => obj.institutionID !== draggedObj.institutionID);
//   temp = [...temp.slice(0, index), draggedObj, ...temp.slice(index, temp.length)];
//   setAccreditorsList(temp);
// }

// const dragEnd = (event, index) => {
//   // console.log(accreditorsList[i]);
//   console.log(`drag end`);
// }
/* <TextField
                  variant="outlined"
                  value={cmeCreditsPerArticle}
                  error={(cmeCreditsPerArticle > 10 || cmeCreditsPerArticle < 0.1)}
                  helperText={(cmeCreditsPerArticle > 10 || cmeCreditsPerArticle < 0) ? "CME Credits should be in the range 0-10" : ""}
                  onChange={
                    (event) => {
                      let val = event.target.value;
                      let reg = /\d+\.\d+/
                      setCmeCreditsPerArticle(val)
                    }
                  }
                  sx={{ 
                    marginLeft: '9px', 
                    height: "18px", 
                  }}
                  inputProps={{
                    style: {
                      fontSize: '18px',
                      lineHeight: '36px',
                      fontWeight: '600',
                      height: "12px",
                      width: "64px",  
                      color: JC_COLORS.JC_ICON,
                    }
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}

                /> */

/* <Typography
          sx={{
            color: JC_COLORS.JC_RED,
            textAlign: 'left',
            fontSize: '10px',
            lineHeight: '14px',
          }}
        >
          COMING SOON: The data shown below is only for demo purposes.
        </Typography> */