import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router';
import { AuthContext } from '../../../context/AuthContext';

import { Box, Typography, Divider, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

import { Row, Spacer, TopDrawer } from "../../../common/components";
import useDebounce from "../../../common/hooks/useDebounce";
import SearchBox from '../components/SearchBox';
import KeyWordsList from "../../../common/containers/KeyWordsList";
import { JC_COLORS } from '../../../styles/constants';
import * as api from "../../../api";
import { clearAllKeywords, keywordAdded } from '../../../state/actions/keywords';
import { sourceAdded, clearAllSources } from '../../../state/actions/sources';
import { updateUserDetails } from '../../../state/actions/user';
import CenteredActionButton from '../../../common/components/CenteredActionButton';
import { Navigate, useNavigate } from 'react-router';
import { useKeywordsContext } from '../../../context/KeywordsContext';

const UnderlinedText = styled(Typography)(({ theme }) => ({
    textDecoration: 'underline',
    color: JC_COLORS.JC_RED,
    fontFamily: 'Raleway',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontWeight: '600',
    marginBottom: "14px",

}));


const FilterDrawer = ({ open, closeHandler, openHandler }) => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, feed, } = useSelector((state) => state);

    const [editKeywordsOrSources, setEditKeywordsOrSources] = useState(false);
    const {
        keywordsState,
        sourcesState,
        addAKeyword,
        addASource,
        clearAllKeywords,
        clearAllSources,
        setAllKeywords,
        setAllSources
    } = useKeywordsContext();
    const [keywordAndSource, setKeywordAndSource] = useState(getSelected(keywordsState, sourcesState));
    const location = useLocation();
    const content = location.pathname.replace(/\//, '');


    useEffect(() => {
        if (content === "edit-keywords-or-sources") {
            setEditKeywordsOrSources(true);
        } else {
            setEditKeywordsOrSources(false);
        }
    }, [content]);

    useDebounce(
        () => {
            setKeywordAndSource(getSelected(keywordsState, sourcesState));
        },
        1200,
        [keywordsState, sourcesState]
    );


    const updateUser = async () => {
        try {
            let res = await api.updateUserDetails({
                keywords: Object.keys(keywordsState).join("|"),
                sources: Object.keys(sourcesState).join("|")
            }, userEmail);
            if (res.data === "User updated") {
                window.location = "/feed";
            } else {
                console.log(res);
                closeHandler();

            }
        } catch (error) {
            console.log(error.response);
            closeHandler();
        }

    }

    const applyFilter = () => closeHandler()

    return (
        <TopDrawer open={open} onClose={closeHandler} onOpen={openHandler}>
            {
                user.isUpdating && (
                    <Box sx={{ padding: "5rem 1rem 1rem 1rem" }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <CircularProgress color="primary" />
                        </Box>
                    </Box>

                )
            }

            {
                (!user.isUpdating && editKeywordsOrSources) && (
                    <Box sx={{ padding: "16px 16px 16px 16px" }}>
                        <Row sx={{ justifyContent: 'space-between', paddingBottom: "16px" }}>
                            <Typography
                                sx={{
                                    fontFamily: "Raleway",
                                    fontWeight: "700",
                                    fontSize: "1rem",
                                    lineHeight: "1.5rem",
                                    textTransform: 'capitalize'
                                }}>
                                EDIT FEED AND/OR SOURCES
                            </Typography>
                            <Typography
                                color="primary"
                                sx={{
                                    fontFamily: "Raleway",
                                    fontWeight: "700",
                                    fontSize: "1rem",
                                    lineHeight: "1.5rem",
                                }}
                                onClick={() => {
                                    setEditKeywordsOrSources(false);
                                    closeHandler();

                                }}
                            >
                                Cancel
                            </Typography>

                        </Row>

                        <Box>
                            <Divider sx={{ mb: 2 }} />
                            <UnderlinedText onClick={() => clearAllKeywords()}>Clear All</UnderlinedText>
                            <SearchBox
                                searchApiUrl={api.SEARCH_KEYWORDS_URL}
                                placeholder={"Search Keyword"}
                                addButtonClickHandler={addAKeyword}
                            >
                            </SearchBox>
                            <Divider sx={{ mt: 2, mb: 1 }} />
                        </Box>


                        <KeyWordsList
                            type={"keyword"}
                            title={"Feed Journal Keywords"}
                            edit={true}
                            itemsObj={keywordsState}
                            disableClickOnChips={true}
                            showCloseIconForChip={editKeywordsOrSources}
                        />

                        <Spacer height={"32px"}></Spacer>
                        <Box>
                            <Divider sx={{ mb: 2 }} />
                            <UnderlinedText onClick={() => clearAllSources()}>Clear All</UnderlinedText>
                            <SearchBox
                                searchApiUrl={api.SEARCH_SOURCES_URL}
                                placeholder={"Search Journal Source"}
                                addButtonClickHandler={addASource}
                            >
                            </SearchBox>
                            <Divider sx={{ mt: 2, mb: 1 }} />
                        </Box>
                        <KeyWordsList
                            type={"source"}
                            title={"Feed Journal Sources"}
                            edit={true}
                            itemsObj={sourcesState}
                            disableClickOnChips={true}
                            showCloseIconForChip={editKeywordsOrSources}
                        />
                        <Divider sx={{ mb: "26px", mt: "100px" }} />
                        <CenteredActionButton onClick={() => updateUser()}>Update</CenteredActionButton>
                    </Box>
                )

            }

            {
                (!user.isUpdating && editKeywordsOrSources === false) && (
                    <Box sx={{ padding: "80px 16px 16px 16px" }}>

                        <Row sx={{ justifyContent: 'space-between' }}>
                            <UnderlinedText
                                onClick={
                                    () => {
                                        clearAllKeywords()
                                        clearAllSources()
                                    }
                                }
                            >
                                Clear All
                            </UnderlinedText>

                            <UnderlinedText onClick={() => navigate("/edit-keywords-or-sources")}>Edit</UnderlinedText>
                        </Row>

                        <KeyWordsList
                            type={"keyword"}
                            title={"Feed Journal Keywords"}
                            itemsObj={keywordsState}
                            edit={true}
                            disableClickOnChips={false}
                            showCloseIconForChip={editKeywordsOrSources}
                        />

                        <Spacer height={"32px"}></Spacer>
                        <KeyWordsList
                            type={"source"}
                            title={"Feed Journal Sources"}
                            itemsObj={sourcesState}
                            edit={true}
                            disableClickOnChips={false}
                            showCloseIconForChip={editKeywordsOrSources}
                        />
                        <Divider sx={{ mb: "26px", mt: "100px" }} />
                        <CenteredActionButton onClick={() => applyFilter()}>Apply Filter</CenteredActionButton>
                    </Box>

                )
            }

            <Spacer height={"16px"} />


        </TopDrawer >
    )
}

export default FilterDrawer;

function getSelected(keywordsStateObj, sourcesStateObj) {
    return {
        keyword: Object.keys(keywordsStateObj ?? {}).filter(item => keywordsStateObj[item]).join('|'),
        source: Object.keys(sourcesStateObj ?? {}).filter(item => sourcesStateObj[item]).join(""),
    }
}