import React, { useEffect, useContext } from 'react';
import { Hidden, Box, Card, Typography, Select, MenuItem, FormGroup, FormControlLabel, Switch, Grid, useMediaQuery } from "@mui/material";

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router';
import { createBrowserHistory } from 'history';


import { Sidebar, Navbar, KeyWordChip, Row, Column, Spacer, BottomAppBar } from '../../common/components';
import { Key } from '@mui/icons-material';

import FeedContent from "./containers/FeedContent";
import NavbarDesktop from "../../common/containers/NavbarDesktop";
import NavbarMobile from "../../common/containers/NavbarMobile";

import { calcViewHeight, calcViewWidth, calcRem } from "../../utils";
import { AuthContext } from '../../context/AuthContext';
import { OPTIONS_SORT_ARTICLES_BY_TYPE } from '../../constants/constants';
import { Navigate, useNavigate } from 'react-router';
import { getUserDetails } from "../../state/actions/user"; 
import { useErrorHandler } from "react-error-boundary"; 

function Feed() {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;

  return (
    <>
      {userEmail && <FeedContent />}
      
    </>
  )
}

export default Feed;