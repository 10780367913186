import * as api from "../../api";
import { JC_ICONS_COLORS_LIST } from "../../styles/constants";

export const FETCHING_JOURNALCLUBS = 'FETCH_JOURNALCLUBS'
export const FETCH_JOURNALCLUBS_SUCCESS = 'FETCH_JOURNALCLUBS_SUCCESS'
export const FETCH_JOURNALCLUBS_FAILURE = 'FETCH_JOURNALCLUBS_FAILURE'

export const FETCHING_JOURNALCLUB_DETAILS = 'FETCH_JOURNALCLUB_DETAILS'
export const FETCH_JOURNALCLUB_DETAILS_SUCCESS = 'FETCH_JOURNALCLUB_DETAILS_SUCCESS'
export const FETCH_JOURNALCLUB_DETAILS_FAILURE = 'FETCH_JOURNALCLUB_DETAILS_FAILURE'

export const SET_JC_CONTENT = "SET_JC_CONTENT";

export const FETCHING_USER_ROLE_IN_JC = 'FETCHING_USER_ROLE_IN_JC';
export const FETCH_JC_USER = 'FETCH_JC_USER';

export const JOINED_JC = "JOINED_JC";
export const LEFT_JC = "LEFT_JC";
export const JC_INVITE_ACCEPTED = "JC_INVITE_ACCEPTED";
export const JC_INVITE_REJECTED = "JC_INVITE_REJECTED";
export const RESET_JC_ACTION = "RESET_JC_ACTION";

export const FETCHING_JC_PUBLICDATA = 'FETCHING_JC_PUBLIC_DATA'
export const FETCH_JC_PUBLICDATA_SUCCESS = 'FETCH_JC_PUBLICDATA_SUCCESS'
export const FETCH_JC_PUBLICDATA_FAILURE = 'FETCH_JC_PUBLICDATA_FAILURE'

export const RESET_JC_DETAILS = 'RESET_JC_DETAILS'


export const fetchingJournalClubs = () => {
    return {
        type: FETCHING_JOURNALCLUBS
    }
}

export const fetchJCUser = (data) => {
    return {
        type: FETCH_JC_USER,
        payload: data
    }
}

export const fetchingJournalClubsSuccess = (data) => {
    return {
        type: FETCH_JOURNALCLUBS_SUCCESS,
        payload: data
    }
}

export const fetchingJournalClubsFailure = (data) => {
    return {
        type: FETCH_JOURNALCLUBS_FAILURE,
        payload: data
    }
}

export const fetchingJournalClubDetails = () => {
    return {
        type: FETCHING_JOURNALCLUB_DETAILS
    }
}

export const fetchingJournalClubDetailsSuccess = (data) => {
    return {
        type: FETCH_JOURNALCLUB_DETAILS_SUCCESS,
        payload: data
    }
}

export const fetchingJournalClubDetailsFailure = (data) => {
    return {
        type: FETCH_JOURNALCLUB_DETAILS_FAILURE,
        payload: data
    }
}


export const getJournalClubs = (userEmail, reloadPage=false) => async (dispatch) => {
    try {
        if(reloadPage=== true) {
            dispatch(fetchingJournalClubs())
        }   
        let jcList; 
        if(userEmail) {
            let queryParams = {
                topic: '',
                status: 'All'.toLowerCase(),
                approved: 'All'.toLowerCase(),
                pageNumber: 1,
                searchTerm: '',
                numRecordsPerPage: 100000,
            };
            const query = userEmail + "?" + Object.entries(queryParams).map(arr => arr[0] + '=' + arr[1]).join('&');
            const { data } = await api.fetchJournalClubs(query);
            jcList = data.journalClubList.filter(obj => (obj.JCID !== 'J00000000'))
            //let jcList = data.journalClubList;
            jcList = jcList.map((obj, i) => ({ ...obj, iconColor: JC_ICONS_COLORS_LIST[i] }));

        } else {
            jcList = []
        }
        dispatch(fetchingJournalClubsSuccess(jcList));
    } catch (error) {
        console.log(error)
        if (error?.response) {
            dispatch(fetchingJournalClubsFailure(error?.response));
        }

    }
}

export const getJournalClubDetails = (JCID, userEmail, reloadPage=true) => async (dispatch) => {
    try {
        if(reloadPage === true) {
            dispatch(fetchingJournalClubDetails())
        }
        const { data } = await api.fetchJournalClubDetails(userEmail, JCID);
        dispatch(fetchingJournalClubDetailsSuccess(data));
        
    } catch (error) {
        console.log(error)
        if (error?.response) {
            dispatch(fetchingJournalClubDetailsFailure(error?.response));
        }

    }
}

export const fetchingUserRoleInJC = () => {
    return {
        type: FETCHING_USER_ROLE_IN_JC
    }
}

export const getJCUserDetails = (JCID, userEmail) => async (dispatch) => {
    try {
        dispatch(fetchingUserRoleInJC());
        const { data } = await api.fetchUserRoleInJC(JCID, userEmail);
        dispatch(fetchJCUser(data));
    } catch (error) {
        if (error?.response) {
            dispatch(fetchingJournalClubDetailsFailure(error?.response));
        }

    }
}

export const setJCContent = (data) => {
    return {
        type: SET_JC_CONTENT,
        payload: data
    }
}

export const joinedJC = (data) => {
    return {
        type: JOINED_JC,
        payload: data
    }
}

export const leftJC = (data) => {
    return {
        type: LEFT_JC,
        payload: data
    }
}

export const jcInviteAccepted = (data) => {
    return {
        type: JC_INVITE_ACCEPTED,
        payload: data
    }
}

export const jcInviteRejected = (data) => {
    return {
        type: JC_INVITE_REJECTED,
        payload: data
    }
}

export const resetJcAction = () => {
    return {
        type: RESET_JC_ACTION,
    }
}

//JC PUBLIC DATA

export const fetchingJcPublicData = () => {
    return {
        type: FETCHING_JC_PUBLICDATA
    }
}

export const fetchJcPublicDataSuccess = (data) => {
    return {
        type: FETCH_JC_PUBLICDATA_SUCCESS,
        payload: data
    }
}

export const fetchJcPublicDataFailure = (data) => {
    return {
        type: FETCH_JC_PUBLICDATA_FAILURE,
        payload: data
    }
}

export const getJcPublicData = (urlSlug) => async (dispatch) => {
    try {
        dispatch(fetchingJcPublicData())
        const { data } = await api.fetchJcPublicData(urlSlug);
        dispatch(fetchJcPublicDataSuccess(data));
    } catch (error) {
        console.log(error)
        if (error?.response) {
            dispatch(fetchJcPublicDataFailure(error?.response));
        }

    }
}

export const resetJcDetails = () => {
    return {
        type: RESET_JC_DETAILS, 
    }
}