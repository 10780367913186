import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { Hidden, Box } from "@mui/material";
import JCHeader from "../common/components/JCHeader";
import { AuthContext } from '../context/AuthContext';
import { UserDataContext } from '../context/UserDataContext';
import { useErrorHandler } from "react-error-boundary";
import { logout } from '../utils';
import { getJournalClubs } from '../state/actions/journalclub';
import { JC_COLORS } from '../styles/constants';
import { USER_STATUS,MEMBERSHIP_STATUS } from '../constants/constants';
import { fetchFeedInBackground } from '../state/actions/feed';

function Home() {
  const authData = useContext(AuthContext);
  const { setAuthenticated } = authData;
  const { email: userEmail, iat, exp } = authData.user; 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, keywords, sources,journalclub,feed } = useSelector((state) => state);
  const handleError = useErrorHandler();

  const { isLoading, data, isError, error } = useContext(UserDataContext);
  let { jcList } = journalclub;

  if (isError) {
    if (error?.response?.status === 404 || error?.response?.data === 'User not found') {
      window.location = "/terms-and-conditions";
    } else {
      handleError("Error !!!");
    }
  }

  useEffect(() => {
    if(userEmail){
      dispatch(getJournalClubs(userEmail));
    }
  }, [])

  useEffect(() => {
    if (isLoading === false && journalclub.isFetchingJournalClubs === false) {
      console.log("In this page")
      if (data?.data?.status === USER_STATUS.ACTIVE) {
        if (data?.data?.acceptedTermsAndConditions === null || data?.data?.acceptedTermsAndConditions === false) {
          window.location = "/terms-and-conditions";
        } else if (
          data?.data?.areasOfInterest === null || data?.data?.areasOfInterest?.length < 1 ||
          data?.data?.profession === null || data?.data?.profession?.length < 1 ||
          data?.data?.institution === null || data?.data?.institution?.length < 1
        ) {
          window.location = "/profile";
        } else {
          let usrLandingUrl = localStorage.getItem('usr_landing_url');
          if (usrLandingUrl !== null) {
            window.location = usrLandingUrl
            localStorage.removeItem('usr_landing_url');
          } else {
            const lastVistedJC = data.data.lastVisitedJC
            const finalJCList = journalclub.jcList.filter(JC => JC.membershipStatus === MEMBERSHIP_STATUS.JOINED)
            const lastVisitedJCList = finalJCList.filter(JC => JC.JCID === lastVistedJC)

            if(lastVisitedJCList.length > 0){
              // Last visited JC where user status is joined 
              navigate(`/jc/${lastVisitedJCList[0].urlSlug}`)                                               
            }
            else if(finalJCList.length === 0){
              // If Joined Journal clubs are empty
              navigate('/join-a-journal-club')
            }
            else {
              // Select first journal club 
              navigate(`/jc/${finalJCList[0].urlSlug}`)
            }
          }
        }
      } else if (data?.data?.status === USER_STATUS.INVITED) {
        window.location = "/terms-and-conditions";
      } else if (data?.data?.status === USER_STATUS.INACTIVE) {
        setAuthenticated(false);
        window.location = "/inactive-user";
      } else {
        handleError("Error !!!");
      }
    }

  }, [isLoading,journalclub.isFetchingJournalClubs])





  return (
    <>
      <Hidden mdDown>
        <JCHeader greet={true} />
      </Hidden>
      <Hidden mdUp>
        <JCHeader greet={true} />
        <Box sx={{ margin: "0 auto", position: "absolute", top: "50%", left: "50%" }}>
          <svg width={"51"} height={"75"} viewBox="0 0 51 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9654 18.5284H11.0904V18.4034V9.9184L15.5734 5.329V18.4034V18.5284H15.6984H18H18.125V18.4034V1V0.875H18H1H0.875V1V3.35618V3.48118H1H13.7741L9.28399 8.0778H1H0.875V8.2028V10.559V10.684H1H6.73677L0.922923 16.6358L0.837602 16.7231L0.922923 16.8105L2.55037 18.4765L2.63979 18.5681L2.72921 18.4765L8.53761 12.5303V18.4034V18.5284H8.66261H10.9654ZM1 20.0523H0.875V20.1773V20.1928C0.875 21.6568 1.15005 23.1384 1.9366 24.2572C2.72848 25.3835 4.02677 26.125 6.03064 26.125C8.03413 26.125 9.30864 25.3832 10.0761 24.2556C10.8377 23.1365 11.0879 21.6551 11.0879 20.1928V20.0678H10.9629H8.66513H8.54013V20.1928C8.54013 20.7316 8.53436 21.2209 8.48694 21.654C8.43951 22.0871 8.35121 22.455 8.19155 22.7534C8.03353 23.0488 7.80341 23.2805 7.46238 23.4402C7.11842 23.6013 6.65574 23.6918 6.03064 23.6918C4.78347 23.6918 4.1536 23.3334 3.8194 22.7502C3.47374 22.1471 3.42404 21.2758 3.42404 20.1928V20.1773V20.0523H3.29904H1Z" fill={JC_COLORS.JC_RED} stroke={JC_COLORS.JC_RED} strokeWidth="0.25" />
          </svg>
        </Box>
      </Hidden>

    </>
  )
}

export default Home


