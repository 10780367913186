import React, { useState, useContext } from 'react'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router';
import { DEFAULT_JC } from "../../constants/constants"
import PracticeIcon from '../../assets/PracticeIcon';
import LikeIcon from '../../assets/LikeIcon';
import {
  CommentIcon,CommentedIcon, LikedIcon
} from '../../assets'

import { setPracticeImpacted, setLiked, getArticle, getJournalClubArticle } from '../../state/actions/article';
import { AuthContext } from '../../context/AuthContext';
import SmoothScrolling from '../../utils/smoothScrolling';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: theme.spacing(1),
  textAlign: 'initial',
}));


function EngagementComponent(props) {
  const {isDisabled = false} = props
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const { JCID } = useParams()
  const JCPage = JCID ? JCID === DEFAULT_JC ? false : true : false
  const [openShare, setOpenShare] = React.useState(false);

  const dispatch = useDispatch();
  const {
    PMID,
    numberOfComments,
    numberOfLikes,
    numberOfPracticeImpacting,
    numberOfCommentsInUserJCs,
    likedByUser,
    numberOfLikesInUserJCs,
    PIByUser,
    supportedByUser,
    numberOfPracticeImpactingInUserJCs,
    commentedByUser
  } = useSelector((state) => state.article.articleData);

  const handlePracticeImpactClick = () => {
    if(!isDisabled){
    const JCVerb = JCID ? JCID : DEFAULT_JC
    dispatch(setPracticeImpacted(PMID, userEmail, JCVerb, PIByUser,likedByUser))
    }
  }

  const handleLikeClick = () => {
    if(!isDisabled){
    const JCVerb = JCID ? JCID : DEFAULT_JC
    dispatch(setLiked(PMID, userEmail, JCVerb, likedByUser,supportedByUser))
    }
  }
  const goToSection=(id)=>{
    const targetSection = document.getElementById(id);
    if(targetSection != null) {
      window.scrollTo({
        top:targetSection.offsetTop-200,
        behavior:"smooth",
      });
    }
  }

  return (
    <React.Fragment>
      <Grid item>
        <Item sx={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" sx={{
            fontFamily: 'bitter', fontSize: '16px', fontWeight: '700', width: '100%', height: '35px', textTransform: 'none', 
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#EF5B50", cursor: 'default'
            },
            "&.Mui-disabled": {bgcolor:"#838383",color:'#FFFFFF'}
          }}
            disableElevation
            disableRipple
            disabled = {isDisabled}>
            Engagement
          </Button>

          {/*Comment */}
          <Box sx={{
            backgroundColor: '#F3F3F3', textAlign: 'left', display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            cursor: isDisabled ? 'default': 'pointer'
            }} mt={1} pt={1.5} pb={1} pl={1.8} onClick={() => goToSection("Comments")}>

            <Box sx={{ display: 'inline-block' }} >
              { commentedByUser && !isDisabled ?
              <CommentedIcon width='28px' height="27px" />
              :
              <CommentIcon width='28px' height="27px" />
              }
            </Box>

            <Typography variant="BODY_COPY1_RALEWAY_DESKTOP" className="desktop-actions"
              sx={{ marginTop: '-5px', marginLeft: "8px" }} color={isDisabled ? "#838383" : "#262626"}>
              Comment
              {!isDisabled &&
              <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" color="#838383" component="span" sx={{ fontSize: '12px' }} mx={0.5}>
                {"(" + numberOfComments + ")"}
              </Typography>
              }
              {!isDisabled &&
              <Typography variant="BODY_COPY2_RALEWAY" color="#838383" sx={{display:'block'}}>
                {numberOfCommentsInUserJCs + " from your JCs" }
              </Typography>
              }
            </Typography>
          </Box>


          {/* Like */}
          <Box sx={{
            backgroundColor: '#F3F3F3', textAlign: 'left', display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            cursor: isDisabled ? 'default': 'pointer'
          }} mt={1} pt={1} pb={1} pl={1.8} onClick={handleLikeClick}>

            <Box sx={{ display: 'inline-block' }} >
              {likedByUser && !isDisabled ? <LikedIcon width='28px' height="27px" /> : <LikeIcon width='28px' height="27px" />}
            </Box>
            <Typography variant="BODY_COPY1_RALEWAY_DESKTOP" className="desktop-actions" color={isDisabled ? "#838383" : "#262626"}
              sx={{ marginTop: '0px', marginLeft: "8px" }}>
              Like
              {!isDisabled &&
              <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" color="#838383" component="span" sx={{ fontSize: '12px' }} mx={0.5}>
                {"(" + numberOfLikes + ")"}
              </Typography>
              }
              {!isDisabled &&
              <Typography variant="BODY_COPY2_RALEWAY" color="#838383" sx={{display:'block'}} pt={0.2}>
                {numberOfLikesInUserJCs +" from your JCs"}
              </Typography>
              }
            </Typography>
          </Box>

          {/*Practice Impacting */}

          <Box sx={{
            backgroundColor: '#F3F3F3', textAlign: 'left', display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
            cursor: isDisabled ? 'default': 'pointer'
          }} mt={1} pt={1} pb={1} pl={1.8} onClick={handlePracticeImpactClick}>
            <Box sx={{ display: 'inline-block' }} >
              <PracticeIcon fillcolor={PIByUser && !isDisabled ? "#EF5B50" : "None"} width='28px' height="27px" />
            </Box>
            <Typography variant="BODY_COPY1_RALEWAY_DESKTOP" className="desktop-actions" color={isDisabled ? "#838383" : "#262626"}
              sx={{ marginTop: '0px', marginLeft: "8px" }}>
              Practice Impacting
              {!isDisabled &&
              <Typography variant="BODY_COPY2_RALEWAY_DESKTOP" color="#838383" component="span" sx={{ fontSize: '12px' }} mx={0.5}>
                {"(" + numberOfPracticeImpacting + ")"}
              </Typography>
              }
              {!isDisabled && 
              <Typography variant="BODY_COPY2_RALEWAY" color="#838383" sx={{display:'block'}} pt={0.2}>
              {numberOfPracticeImpactingInUserJCs+" from your JCs"}
              </Typography>
              }
            </Typography>
          </Box>

        </Item>
      </Grid>



    </React.Fragment>
  )
}

export default EngagementComponent