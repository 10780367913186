import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Hidden, Box, Typography, Grid, IconButton, AppBar, Toolbar, CircularProgress, styled, Dialog, DialogTitle, DialogContent, DialogActions, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { MEMBERSHIP_STATUS } from '../../../constants/constants';
import { JC_COLORS } from '../../../styles/constants';
import JCInviteButtons from './JCInviteButtons';
import JoinJCButton from './JoinJCButton';
import { AuthContext } from '../../../context/AuthContext';
import ChangeJCRoleButton from './ChangeJCRoleButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        '& .MuiDialogContent-root': {
            padding: "25px",
        },
        '& .MuiDialogActions-root': {
            padding: "25px",
        },
        '& .MuiDialog-paper': {
            width: '70%',
            //height: '68%'
        }
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialog-paper': {
            width: '80%',
            height: 'auto',
        }
    },

}));

const NonJCMemberPopUp = ({ open, onClose, jcDetails }) => {
    const theme = useTheme();
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const location = useLocation();
    const navigate = useNavigate();
    const [openDialogBox, setOpenDialogBox] = useState(open);

    const { membershipStatus } = jcDetails

    return (
        <BootstrapDialog
            open={openDialogBox}
            onClose={() => onClose()}
        >
            <DialogTitle
                sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    backgroundColor: JC_COLORS.JC_RED,
                    color: JC_COLORS.JC_WHITE,
                }}
            >
                <Typography sx={{ ...theme.typography.H3_BITTER, fontWeight: 700, }}>Join Journal Club</Typography>
                <CloseIcon
                    sx={{
                        width: '24px',
                        height: '24px',
                        color: JC_COLORS.JC_WHITE,
                        cursor: 'pointer',
                    }}
                    onClick={() => setOpenDialogBox(false)}
                />
            </DialogTitle>
            <DialogContent
                sx={{
                    textAlign: 'center',
                    margin: '35px 35px 24px 35px',
                }}
            >
                <Typography
                    variant="H2_RALEWAY"
                    sx={{ fontWeight: 600 }}
                >
                    {membershipStatus === MEMBERSHIP_STATUS.INVITED && 'You are invited to become a member of this journal club. Accept invite to view articles.'}
                    {membershipStatus === MEMBERSHIP_STATUS.ADD && 'You need to become a member of journal club to view articles. '}
                    {membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED && 'You need to become a member of Journal Club to view articles. '}
                    {membershipStatus === MEMBERSHIP_STATUS.LEFT && 'You need to become a member of Journal Club to view articles. '}
                    {membershipStatus === MEMBERSHIP_STATUS.REQUESTED && `You need to become a member of Journal Club to view this article. `}
                    {membershipStatus === MEMBERSHIP_STATUS.REQUEST_REJECTED && `Your membership for this Journal Club has been rejected. Please contact the Club's Admin or Course Director to get access to this Club.`}
                    {membershipStatus === MEMBERSHIP_STATUS.REMOVED && `Your membership for this Journal Club has been cancelled. Please contact the Club's Admin or Course Director to get access to this Club.`}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '16px' }}>
                {
                    membershipStatus === MEMBERSHIP_STATUS.INVITED && (
                        <JCInviteButtons
                            JCID={jcDetails?.JCID}
                            userEmail={userEmail}
                            nextAction={() => navigate(location.pathname)}
                        />
                    )
                }
                {
                    membershipStatus === MEMBERSHIP_STATUS.REQUESTED && (
                        <ChangeJCRoleButton
                            JCID={jcDetails?.JCID}
                            userEmail={userEmail}
                            currentRole={""}
                            membershipStatus={jcDetails.membershipStatus}
                        />
                    )
                }

                {
                    (
                        membershipStatus === MEMBERSHIP_STATUS.ADD ||
                        membershipStatus === MEMBERSHIP_STATUS.INVITE_REJECTED ||
                        membershipStatus === MEMBERSHIP_STATUS.LEFT
                    ) && (
                        <JoinJCButton
                            JCID={jcDetails?.JCID}
                            userEmail={userEmail}
                            journalClubName={jcDetails.name}
                            isJCPublic={jcDetails.isPublic}
                            nextAction={() => navigate(location.pathname)}
                        />
                    )
                }
            </DialogActions>
        </BootstrapDialog>
    )
}

export default NonJCMemberPopUp