import {
    FETCHING_USER_DETAILS,
    FETCH_USER_DETAILS_SUCCESS,
    FETCH_USER_DETAILS_FAILURE,
    UPDATING_USER_DETAILS,
    UPDATE_USER_DETAILS_SUCCESS,
    UPDATE_USER_DETAILS_FAILURE,
    SET_IS_NEW_USER,
    SET_VERIFICATION_EMAIL
} from "../actions/user"


const initialState = {
    isFetching: false,
    isUpdating: false,
    editKeywordsOrSources: false,
    userDetails: {},
    isNewUser: null, 
    error: null,
    verificationEmail : null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_USER_DETAILS:
            return {
                ...state,
                isFetching: true,
            }
        case FETCH_USER_DETAILS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                userDetails: action.payload
            }

        case FETCH_USER_DETAILS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload,
            }

        case UPDATING_USER_DETAILS:
            return {
                ...state,
                isUpdating: true,
            }
        case UPDATE_USER_DETAILS_SUCCESS:
            return {
                ...state,
                isUpdating: false,
            }
        case UPDATE_USER_DETAILS_FAILURE:
            return {
                ...state,
                isUpdating: false,
            }
        case SET_IS_NEW_USER: 
        //console.log(action.payload); 
            return {
                ...state, 
                isNewUser: action.payload, 
            }
        case SET_VERIFICATION_EMAIL: 
        //console.log(action.payload); 
            return {
                ...state, 
                verificationEmail: action.payload, 
            }
        default:
            return state;

    }

}