import AWS from "aws-sdk";
import { Signer } from 'aws-amplify';
import moment from "moment";
import { token_name_list } from "../constants/constants";
import { getToken, removeTokenFromStorage } from "./authToken";
import { getNewToken } from "./connection";
import { logout } from ".";


export const isCurrentCognitoIdentityExpired = () => {

    let currentCognitoIdentity = JSON.parse(localStorage.getItem(token_name_list.COGNITO_IDENTITY));
    if (currentCognitoIdentity?.expireTime === null) {
        return true
    }
    let DateTimeNow = moment();
    let currentCognitoIdentityExpireTime = moment(currentCognitoIdentity?.expireTime);

    console.log(DateTimeNow > currentCognitoIdentityExpireTime ? "cognito expired" : "cognito not expired")
    console.log(currentCognitoIdentityExpireTime);

    return (DateTimeNow > currentCognitoIdentityExpireTime)
}

export const isCurrentCognitoIdentityInvalid = () => {
    let currentCognitoIdentity = JSON.parse(localStorage.getItem(token_name_list.COGNITO_IDENTITY));
    return (
        (currentCognitoIdentity === null) ||
        (currentCognitoIdentity && Object.values(currentCognitoIdentity).length === 0)
    )
}

export const generateAWSCognitoIdentity = async (newTokenGenerated = false) => {
    try {
        if (newTokenGenerated === true) {
            removeTokenFromStorage(token_name_list.COGNITO_IDENTITY)
        }
        const idToken = getToken(token_name_list.ID_TOKEN)
        if (isCurrentCognitoIdentityInvalid() || isCurrentCognitoIdentityExpired()) {
            AWS.config.region = process.env.REACT_APP_AWS_REGION;
            if (idToken === null || idToken === undefined) {
                localStorage.setItem("user_type", "guest");
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
                    AccountId: process.env.REACT_APP_AWS_ACCOUNT_ID
                });

            } else {
                if (newTokenGenerated === true) {
                    localStorage.setItem("user_type", "auth")
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
                        AccountId: process.env.REACT_APP_AWS_ACCOUNT_ID,
                        Logins: {
                            [process.env.REACT_APP_AWS_AUTH_USER_POOL]: idToken
                        }
                    });
                } else {
                    localStorage.setItem("user_type", "guest");
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
                        AccountId: process.env.REACT_APP_AWS_ACCOUNT_ID
                    });
                }
            }
            // Clearing cached before AWS get
            AWS.config.credentials.clearCachedId();
            var promise = await getStoreToken(AWS.config.credentials)

        }
    } catch (error) {
        logout()
    }
}

export const reGenerateAWSCognitoIdentity = async (newTokenGenerated = false) => {
    try {
        const tokenResponse = await getNewToken();
        const idToken = tokenResponse.data.id_token
        console.log("ID token while regenerating")
        if (idToken) {
            AWS.config.region = process.env.REACT_APP_AWS_REGION;
            if (idToken === null || idToken === undefined) {
                localStorage.setItem("user_type", "guest");
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
                    AccountId: process.env.REACT_APP_AWS_ACCOUNT_ID
                });

            } else {
                if (newTokenGenerated === true) {
                    localStorage.setItem("user_type", "auth")
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
                        AccountId: process.env.REACT_APP_AWS_ACCOUNT_ID,
                        Logins: {
                            [process.env.REACT_APP_AWS_AUTH_USER_POOL]: idToken
                        }
                    });
                } else {
                    localStorage.setItem("user_type", "guest");
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
                        AccountId: process.env.REACT_APP_AWS_ACCOUNT_ID
                    });
                }
            }

            var promise = await getStoreToken(AWS.config.credentials)
        }
    } catch (error) {
        logout()
    }
}


export const getStoreToken = (AWSObject) => {
    return new Promise((resolve,reject) => {
        AWSObject.get(function () {
            localStorage.setItem(
                token_name_list.COGNITO_IDENTITY,
                JSON.stringify({
                    identityId: AWS.config.credentials.identityId,
                    expireTime: AWS.config.credentials.expireTime,
                    id: AWS.config.credentials.accessKeyId,
                    key: AWS.config.credentials.secretAccessKey,
                    token: AWS.config.credentials.sessionToken
                })
            );
            return resolve(JSON.stringify({
                identityId: AWS.config.credentials.identityId,
                expireTime: AWS.config.credentials.expireTime,
                id: AWS.config.credentials.accessKeyId,
                key: AWS.config.credentials.secretAccessKey,
                token: AWS.config.credentials.sessionToken
            }))  
        })
    })
}

export const generateSignedRequest = (request) => {
    let service_info = {
        service: 'execute-api',
        region: process.env.REACT_APP_AWS_REGION
    }
    let awsCognitoItentityCredentials = JSON.parse(localStorage.getItem(token_name_list.COGNITO_IDENTITY));
    let { id, key, token } = awsCognitoItentityCredentials

    let access_info = {
        access_key: id,
        secret_key: key,
        session_token: token
    }
    let signedRequest = Signer.sign(request, access_info, service_info)
    delete signedRequest.headers['host'];
    return signedRequest
}
