import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography, useMediaQuery, Tabs, Tab, tabsClasses, Hidden, CircularProgress } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { alpha, styled } from '@mui/material/styles';


import { KeyWordChip, Row, Spacer } from "../components";
import useDebounce from '../hooks/useDebounce';

import { JC_COLORS } from '../../styles/constants';
import { calcViewWidth, calcRem } from "../../utils";
import { keywordClicked, keywordRemoved } from '../../state/actions/keywords';
import { removeAnySources, sourceClicked, sourceRemoved } from '../../state/actions/sources';
import { useKeywordsContext } from '../../context/KeywordsContext';
import KeywordChip from '../components/KeywordChip1';


const MainBoxEditScreen = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        minHeight: '50px',
        paddingLeft: "20px",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        border: "1px solid",
        borderColor: JC_COLORS.JC_ICON,
        borderRadius: "4px",
    }

}));

const FlexWrappedList = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
        //marginTop: "16px", 
        //marginBottom: "1rem", 
        width: "100%",
        //height: "100%",
        padding: "16px 0px",
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: '18px',
    }
}));

const MainBoxUnEditScreen = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        //minWidth: '65vw',
        //minWidth: '63vw', 
        minHeight: "40px",
        border: "1px solid",
        borderColor: JC_COLORS.JC_ICON,
        borderRadius: "4px",
        paddingLeft: "20px",
        paddingRight: 0,
        marginLeft: "40px",
        marginRight: "40px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {

    }

}));

const TabsList = styled(Tabs)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [`& .${tabsClasses.scrollButtons}`]: {
        '&.Mui-disabled': { opacity: 0.3 },

    },
    '& .MuiTabs-indicator': { backgroundColor: "#FFFFFF" },
    [theme.breakpoints.up('md')]: {
        width: "80%",
        "*": {
            marginRight: "5px",
            marginBottom: "3px",
        }
    },
    [theme.breakpoints.down('md')]: {
        width: "100%",
        "*": {
            marginRight: "5px",
        }
    },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    display: 'inline',
    textAlign: 'left',
    alignSelf: 'center',
    ...theme.typography.H3_BITTER,
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
        minWidth: '20%',
        marginRight: "22px",
        // fontSize: '1rem',
        // lineHeight: '1.5rem',
    },
    [theme.breakpoints.down('md')]: {
        margin: '18px 0',
        fontSize: '12px',
        lineHeight: '16px',

    }

}));

const KeyWordsList = ({ type, title, itemsList, itemsObj, edit = false, showCloseIconForChip = false, disableClickOnChips = false }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state);
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const { selectAKeyword, removeAKeyword, selectASource, removeASource } = useKeywordsContext()


    return (
        <>
            {
                edit ?
                    <MainBoxEditScreen>
                        <Hidden mdUp>
                            <StyledTitle>
                                {title}
                            </StyledTitle>
                        </Hidden>
                        <FlexWrappedList>
                            {
                                Object.keys(itemsObj).sort().map(
                                    (item) => (
                                        <KeyWordChip
                                            key={item}
                                            label={item}
                                            showCloseIcon={showCloseIconForChip}
                                            disableClick={disableClickOnChips}
                                            selected={itemsObj[item]}
                                            onClick={
                                                type === 'keyword' ?
                                                    () => selectAKeyword(item) :
                                                    () => selectASource(item)
                                            }
                                            closeButtonHandler={
                                                type === 'keyword' ?
                                                    () => removeAKeyword(item) :
                                                    () => removeASource(item)

                                            }


                                        />
                                    )
                                )
                            }
                        </FlexWrappedList>

                    </MainBoxEditScreen> :
                    <MainBoxUnEditScreen>
                        <Hidden mdDown>
                            <Typography
                                variant="H3_BITTER"
                                fontWeight="bold"
                                sx={{
                                    fontSize: "1rem",
                                    lineHeight: "1.5rem"
                                }}
                            >
                                {title}
                            </Typography>

                        </Hidden>

                        {
                            (user.isFetching || user.isUpdating) && (
                                <TabsList>
                                    <Box sx={{ height: "35px", ml: "300px" }}>
                                        <CircularProgress />
                                    </Box>

                                </TabsList>
                            )
                        }
                        {
                            (user.isFetching === false) && (
                                <TabsList
                                    value={0}
                                    variant="scrollable"
                                    scrollButtons={true}
                                >
                                    {
                                        Object.keys(itemsObj).sort().map((item, i) => (
                                            <Box key={item} sx={{ height: "32px", mt: "2px" }}>
                                                <KeyWordChip
                                                    label={item}
                                                    showCloseIcon={false}
                                                    disableClick={disableClickOnChips}
                                                    selected={itemsObj[item]}
                                                    onClick={
                                                        type === 'keyword' ?
                                                            () => selectAKeyword(item) :
                                                            () => selectASource(item)
                                                    }
                                                    closeButtonHandler={() => { }}
                                                />
                                            </Box>


                                        ))
                                    }

                                </TabsList>
                            )
                        }

                    </MainBoxUnEditScreen>

            }
        </>


    )

}

export default KeyWordsList;




// function useDebounce() {
//     const dispatch = useDispatch();
//     const [clickTimeout, setClickTimeout] = useState("")

//     function debounce(eventFunc) {
//         clearTimeout(clickTimeout);
//         const timeout = setTimeout(() => {
//             eventFunc();
//         }, 1000)
//         setClickTimeout(timeout);
//     }
//     return debounce;
// }



