import {
    BoldIcon,
    ItalicIcon,
    ImageIcon,
    Indent,
    Outdent,
    VideoIcon,
    NumberList,
    DotList,
    PdfIcon,
    YoutubeIcon,
    Header1,
    Header2,
    Subscript,
    Superscript,
    UnderlineIcon,
    ColorPickerIcon,
    LinkIcon,
  } from '../assets/JCWysiwygIcons';

export const CommentCustomSkin = {
    skin: {
      bold: {
        tooltip: 'Bold',
        iconType: 'svg',
        icon: BoldIcon,
      },
      italic: {
        tooltip: 'Italic',
        iconType: 'svg',
        icon: ItalicIcon,
      },
      underline: {
        tooltip: 'Underline',
        iconType: 'svg',
        icon: UnderlineIcon,
      },
      header1: {
        tooltip: 'Header 1',
        iconType: 'svg',
        icon: Header1,
      },
      header2: {
        tooltip: 'Header 2',
        iconType: 'svg',
        icon: Header2,
      },
      script1: {
        tooltip: 'Subscript',
        iconType: 'svg',
        icon: Subscript,
      },
      script2: {
        tooltip: 'Superscript',
        iconType: 'svg',
        icon: Superscript,
      },
      indent1: {
        tooltip: 'Indent',
        iconType: 'svg',
        icon: Outdent,
      },
      indent2: {
        tooltip: 'Outdent',
        iconType: 'svg',
        icon: Indent,
      },
      link: {
        tooltip: 'Add Link',
        iconType: 'svg',
        icon: LinkIcon,
      },
      list1: {
        tooltip: 'Order List',
        iconType: 'svg',
        icon: NumberList,
      },
      list2: {
        tooltip: 'Unorder List',
        iconType: 'svg',
        icon: DotList,
      },
      pickerLabel:{
        tooltip: 'Color Picker',
        iconType: 'svg',
        icon: ColorPickerIcon
      },
      image: {
        tooltip: 'Image',
        iconType: 'svg',
        icon: ImageIcon,
      },
      video: {
        tooltip: 'Youtube',
        iconType: 'svg',
        icon: YoutubeIcon
      },
      localvideo: {
        tooltip: 'Upload Video',
        iconType: 'svg',
        icon: VideoIcon,
      },
      attachment: {
        tooltip: 'Document',
        iconType: 'svg',
        icon: PdfIcon,
      },
    }
}

export const CommentHistory = {
    delay: 500,
    maxStack: 100,
    userOnly: true,
}

export const DOCUMENT_EDIT_CONSTANT = "newEdit"
export const linkPreviewSettings = {
  link: {
    trigger: /[\s]/,
    find: /https?:\/\/[\S]+|(www\.[\S]+)/gi,
    transform: function (value, noProtocol) {
      return noProtocol ? 'http://' + value : value
    },
    format: 'link'
  }
}
const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function convertBytes(fileSize) {
  let itr = 0,
    num = parseInt(fileSize, 10) || 0;
  while (num >= 1024 && ++itr) {
    num = num / 1024;
  }
  return num.toFixed(num < 10 && itr > 0 ? 1 : 0) + ' ' + units[itr];
}

export function wrapIntoPara(text){
  return `<p>${text}</p>`
}
export function isEditorEmpty(editor) {
    const content = editor.getContents();
    return content.ops.length === 1 && content.ops[0].insert.trim() === '';
}
   

export function resetEditor(editor) {
    const contentLength = editor.getLength();
    editor.deleteText(0, contentLength);
}

export function createEmptyEditor(editor) {
    return editor.setText('');
}

export function loadHTMLToEditor(editor,html){
    const delta = editor.clipboard.convert({ html });
    editor.setContents(delta);
}

export const formats = [
    'header',
    'font',
    'id',
    'src',
    'data-src',
    'data-id',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'hr',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'attachment',
    'video',
    'color',
    'code-block',
    'emoji',
    'localvideo',
    'loading-media',
    'mention',
    'linkpreview',
  ];

export function updateInFilesEditor(inputFile,remove = true) {
    var iframeNode = document.getElementById(`${inputFile.id}`);
    if(iframeNode && (iframeNode.contentDocument || iframeNode.contentWindow) ){
      var doc = iframeNode.contentDocument || iframeNode.contentWindow.document;
      doc.open();
      var nodeData = `
      <div style="display: flex;flex-direction: row;min-width: 250px;padding: 10px;border-radius: 4px;background-color: #eeeeee;align-items: center;font-family: system-ui;" 
          data-fileid="${inputFile.id}">
          <div style="pointer-events: none;height: 45px;width: 45px;border-radius: 4px;">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="45px" height="45px" fill-rule="nonzero">
            <g fill="#838383" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
            <g transform="scale(5.12,5.12)"><path d="M30.39844,2h-23.39844v46h36v-33.39844zM15,28h16v2h-16zM35,36h-20v-2h20zM35,24h-20v-2h20zM30,15v-10.60156l10.60156,10.60156z">
            </path>
            </g>
            </g>
          </svg>
        </div>
        <a
          href="${inputFile.src}"
          download="${inputFile.id}"
          style="text-decoration: none;"
        >
          <div style="padding-left: 10px;text-align: left;">
            <div style="max-width: 18ch;color: #262626;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">${
              inputFile.file.name
            }</div>
            <div style="padding-top: 4px;color: #838383;">${convertBytes(inputFile.file.size)}</div>
          </div>
        </a>
        <button onclick="
          function removeAttachmentNode(element) {
            if (element && element.parentElement){
              let parentEl = element.parentElement.getAttribute('data-fileid');
              let rindex = element.parentElement.getAttribute('data-rindex');
              var iframeNode = window.parent.document.getElementById(String(parentEl));
              iframeNode.remove()
            } 
          }

          removeAttachmentNode(this);" style="border: none;align-self: flex-start;
          margin-left: auto;">
          <svg width="24px" height="24px">
            <svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="icomoon-ignore"> </g> <path d="M10.722 9.969l-0.754 0.754 5.278 5.278-5.253 5.253 0.754 0.754 5.253-5.253 5.253 5.253 0.754-0.754-5.253-5.253 5.278-5.278-0.754-0.754-5.278 5.278z" fill="#000000"> </path> </g></svg>
          </svg>
        </button>
        <div>`
      doc.write(nodeData)
      doc.close();
    }
  }

export function updateInFilesDOM(inputFile) {
  var iframeNode = document.getElementById(`${inputFile.id}`);
  if(iframeNode){
    var doc = iframeNode.contentDocument || iframeNode.contentWindow.document;
    doc.open();
    var nodeData = `
    <div style="display: flex;flex-direction: row;min-width: 250px;padding: 10px;border-radius: 4px;background-color: #eeeeee;align-items: center;font-family: system-ui;box-shadow: rgb(0 0 0 / 12%) 0 2px 6px;" 
        data-fileid="${inputFile.id}">
        <div style="pointer-events: none;height: 45px;width: 45px;border-radius: 4px;">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="45px" height="45px" fill-rule="nonzero">
          <g fill="#838383" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
          <g transform="scale(5.12,5.12)"><path d="M30.39844,2h-23.39844v46h36v-33.39844zM15,28h16v2h-16zM35,36h-20v-2h20zM35,24h-20v-2h20zM30,15v-10.60156l10.60156,10.60156z">
          </path>
          </g>
          </g>
        </svg>
        </div>
      <a
        href="${inputFile.src}"
        download="${inputFile.fileName}"
        style="text-decoration: none;"
      >
        <div style="padding-left: 10px;text-align: left;">
          <div style="max-width: 18ch;color: #262626;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">${
            inputFile.fileName
          }</div>
          <div style="padding-top: 4px;color: #838383;">${convertBytes(inputFile.fileSize)}</div>
        </div>
      </a>
      <div>`
    doc.write(nodeData)
    doc.close();
  }
}