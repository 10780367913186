import { BottomAppBar, CenteredActionButton } from '../../../common/components';
import { NavbarDesktop, NavbarMobile } from '../../../common/containers';
import { Hidden, Box, Grid,Typography } from '@mui/material';
import PlatformUsers from './AllUsers';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../../context/AuthContext';
import { getUserDetails } from '../../../state/actions/user';
import { useNavigate } from 'react-router';

function PlatformUsersPage() {
  const auth = useContext(AuthContext);
  const disaptch = useDispatch();
  const navigate = useNavigate();
  const userEmail = auth.user.email;
  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (userEmail) {
      disaptch(getUserDetails(userEmail));
    } else {
      navigate('/feed');
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userDetails).length != 0 && !userDetails.superAdmin) {
      navigate('/feed');
    }
  }, [userDetails]);

  return (
    <>
      <Hidden mdDown>
        <NavbarDesktop />
        <Box
          sx={{
            margin: '88px auto auto auto',
            maxWidth: '1440px',
          }}
        >
          <Grid container direction="row" justifyContent="center">
            <Grid item md={12}>
              <PlatformUsers userEmail={userEmail} />
            </Grid>
          </Grid>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <NavbarMobile title={'User Activity'} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "95vh"}} p={2}>
          <Typography variant="H2_RALEWAY"  >
            Please use a desktop to access this feature
          </Typography>
        </Box>
        {userEmail && <BottomAppBar />}
      </Hidden>
    </>
  );
}

export default PlatformUsersPage;
