import React, { useState } from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { JC_COLORS } from '../../styles/constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        '& .MuiDialogContent-root': {
            padding: "25px",
        },
        '& .MuiDialogActions-root': {
            padding: "25px",
        },
        '& .MuiDialog-paper': {
            width: '70%',
            //height: '68%'
        }
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialog-paper': {
            width: '80%',
            height: 'auto',
        }
    },

}));

const ErrorDialogBox = ({ open, closeDialogHandler, title, children }) => {
    const theme = useTheme();
    
    return (
        <BootstrapDialog
            open={open}
            onClose={() => closeDialogHandler()}
        >
            <DialogTitle
                sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    backgroundColor: JC_COLORS.JC_RED,
                    color: JC_COLORS.JC_WHITE,
                }}
            >
                <Typography
                    sx={{
                        ...theme.typography.H3_BITTER,
                        fontWeight: 700,
                    }}
                >
                    {title || 'Error'}
                </Typography>
                <CloseIcon
                    sx={{
                        width: '24px',
                        height: '24px',
                        color: JC_COLORS.WHITE,
                    }}
                    onClick={() => closeDialogHandler()}
                />

            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>

        </BootstrapDialog>
    )
}

export default ErrorDialogBox