import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Link,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { JC_CONTENT, USER_ROLES } from '../../../../constants/constants';
import { setJCContent } from '../../../../state/actions/journalclub';
import { useSelector } from 'react-redux';
import { JC_COLORS } from '../../../../styles/constants';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';

import JCContentTitle from '../../components/JCContentTitle';
import { fetchAreaOfInterest,postJCInviteUser, updateJCMember } from '../../../../api';

const StyledTextField = styled(TextField)(({ theme }) => ({
  fontFamily: 'Raleway!important',
  borderRadius: '3px',
  background: 'white',
  border: `1px solid ${JC_COLORS.JC_ICON}`,
  '& .MuiInputBase-input': {
    fontFamily: 'Raleway',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '26px',
    padding: '10px',
  },
  [theme.breakpoints.up('md')]: {
    width: '23vw',
    '& .MuiInputBase-root': {
      height: '42px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '75vw',
    '& .MuiInputBase-root': {
      height: '34px',
      padding: '0px 5px',
    },
  },
}));

function getStyles(mobileMatch, topic, topicName, theme) {
  return {
    fontSize: mobileMatch ? '12px' : '18px',
    fontWeight: topicName?.indexOf(topic) === -1 ? '600' : '700',
    fontFamily: 'Raleway',
    minHeight: '22px',
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditJCMember = ({ JCID,userEmail,isInvite = false,data = {} }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobileMatch = useMediaQuery(theme.breakpoints.down('md'));

  const { journalclub } = useSelector((state) => state);
  const { currentMemberData } = useSelector((state) => state.jcreports);
  const { jcDetails } = journalclub;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [userRole, setUserRole] = useState('');
  const [submitError, setSubmitError] = useState(false);
  const [successToast,setSuccessToast] = useState(false)

  useEffect(() => {
    if( !isInvite && currentMemberData ){
      const memberName = currentMemberData.term.split(' ')
      setFirstName(memberName[0])
      setLastName(memberName[1])
      setPrimaryEmail(currentMemberData.email)
      setUserRole(currentMemberData.role)
    }
  }, []);

  const handleUserRoleChange = (event) => {
    setUserRole(event.target.value);
  };

  const handleSubmitButton = () => {
    const isvalidEmail = validateEmail(primaryEmail);
    if (!validateEmail(primaryEmail)) {
      setSubmitError('Entered email is invalid!');
    }else {
      if (isInvite) {
        postJCInviteUser(JCID,userEmail,{
          "firstName" : firstName.trim(),
          "lastName" : lastName.trim(),
          "email" : primaryEmail.trim(),
          "role" : userRole,
          "authUserEmail": userEmail,
        }).then(res => {
          setSuccessToast(true)
          resetFields()
        }).catch(error => {
          if(error && error?.response?.status === 409) {
            setSubmitError("Primary Email is already existed!")
          }
        })
      } else {
        updateJCMember(JCID,primaryEmail,{
          "role" : userRole,
          "authUserEmail": userEmail,
        }).then(res => {
          setSuccessToast(true)
          dispatch(setJCContent(JC_CONTENT.MANAGE_MEMBERS))
        }).catch(error => {
          console.error(error)
        })
      }
    }
  };

  const resetFields = () => {
    setFirstName('')
    setLastName('')
    setPrimaryEmail('')
    setUserRole('')
  }

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <Box sx={{ minHeight: '624px' }}>
      <Box sx={{ textAlign: 'left' }} my={-1} mx={-0.8}>
        <Link
          sx={{
            cursor: 'pointer',
            textDecoration: 'None',
          }}
          onClick={() => {
            dispatch(setJCContent(JC_CONTENT.MANAGE));
          }}
          className="report-nav-root_text"
        >
          <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
          <Typography variant="BODY_COPY2_RALEWAY">Manage</Typography>
        </Link>
        <Link sx={{ cursor: 'pointer', textDecoration: 'None' }} onClick={() => dispatch(setJCContent(JC_CONTENT.MANAGE_MEMBERS))} className='report-nav-root_text'>
            <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
            <Typography variant="BODY_COPY2_RALEWAY">
                Manage Members
            </Typography>
        </Link>
      </Box>
      <Box sx={{ textAlign: 'left', marginTop: '20px' }}>
        <JCContentTitle
          title={isInvite ? 'Invite New Member' : 'Edit Member Information'}
        />
      </Box>

      <Box mt={2}>
        <Divider />
      </Box>

      <Box mt={4} sx={{ textAlign: 'left' }}>
        <Typography variant="H2_RALEWAY" sx={{ color: JC_COLORS.JC_ICON }}>
          {' '}
          Member Information
        </Typography>
      </Box>

      <Grid container>
        <Grid item xs={12} sx={{ marginTop: '43px', textAlign: 'left' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <InputLabel
                required
                sx={{
                  color: JC_COLORS.JC_ICON,
                  fontWeight: '700 !important',
                  fontFamily: 'Raleway',
                  marginBottom: '11px',
                }}
              >
                First Name
              </InputLabel>
              <FormControl>
                <StyledTextField
                  value={firstName}
                  placeholder={'First Name'}
                  autoComplete="off"
                  onChange={(e) => setFirstName(e.target.value)}
                  disabled={isInvite ? false : true}
                ></StyledTextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel
                required
                sx={{
                  color: JC_COLORS.JC_ICON,
                  fontWeight: '700 !important',
                  fontFamily: 'Raleway',
                  marginBottom: '11px',
                }}
              >
                Last Name
              </InputLabel>
              <FormControl>
                <StyledTextField
                  value={lastName}
                  placeholder={'Last Name'}
                  autoComplete="off"
                  onChange={(e) => setLastName(e.target.value)}
                  disabled={isInvite ? false : true}
                ></StyledTextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel
                required
                sx={{
                  color: JC_COLORS.JC_ICON,
                  fontWeight: '700 !important',
                  fontFamily: 'Raleway',
                  marginBottom: '11px',
                }}
              >
                Primary Email
              </InputLabel>
              <FormControl>
                <StyledTextField
                  value={primaryEmail}
                  placeholder={'Primary Email'}
                  autoComplete="off"
                  onChange={(e) => setPrimaryEmail(e.target.value)}
                  disabled={isInvite ? false : true}
                ></StyledTextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel
                required
                sx={{
                  color: JC_COLORS.JC_ICON,
                  fontWeight: '700 !important',
                  fontFamily: 'Raleway',
                  marginBottom: '11px',
                }}
              >
                User Role
              </InputLabel>
              <FormControl>
                <Select
                  value={userRole}
                  onChange={handleUserRoleChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                  placeholder="select"
                  displayEmpty
                  MenuProps={MenuProps}
                  required
                  sx={{
                    fontFamily: 'Raleway',
                    fontWeight: '700 !important',
                    height: '42px',
                    width: '23vw',
                    border: `1px solid ${JC_COLORS.JC_ICON}`,
                  }}
                >
                  {USER_ROLES &&
                    Object.values(USER_ROLES)
                      .filter(
                        (role) => role !== 'App User' && role !== 'Member'
                      )
                      .map((item) => {
                        return (
                          <MenuItem
                            key={item}
                            value={item}
                            style={getStyles(
                              mobileMatch,
                              item,
                              userRole,
                              theme
                            )}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {submitError && (
        <Box sx={{ textAlign: 'left' }} mt={2}>
          <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_RED}>
            {submitError}
          </Typography>
        </Box>
      )}

      <Box mt={10} sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontFamily: 'Raleway',
            fontWeight: '700',
            textTransform: 'none',
            height: '36px',
          }}
          onClick={handleSubmitButton}
          disabled={
            (firstName.trim() === '' && isInvite ) ||
            (lastName.trim() === '' && isInvite) ||
            primaryEmail.trim() === '' ||
            userRole === ''
          }
        >
          {isInvite ? 'INVITE' : 'SAVE'}
        </Button>
      </Box>

      <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={successToast}
          autoHideDuration={2000}
          onClose={() => setSuccessToast(false)}
          key={'bottom' + 'center'}
        >
          <SnackbarContent sx={{
            backgroundColor: '#262626',
            fontFamily: 'Raleway',
            fontSize: '14px',
            padding: '0',
            paddingLeft: '20px',
            minWidth: '150px!important'
          }}
            message={ isInvite ? "Invite Sent!" : "Member Updated!"}
          />
        </Snackbar>
    </Box>
  );
};

export default EditJCMember;
