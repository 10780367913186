import React, { useContext } from 'react';
import { AppBar, Hidden, Box, Typography, useTheme } from '@mui/material';

import ProfileMenu from '../../../common/containers/ProfileMenu';
import { Row } from '../../../common/components';
import { pxToRem } from '../../../utils';
import { JC_COLORS } from '../../../styles/constants';
import { AuthContext } from '../../../context/AuthContext';
import GuestNavbarMobile from '../../GuestPages/GuestNavbarMobile';
import HelpMenu from '../../../common/containers/HelpMenu';

const JCDetailsFullScreenMobile = ({ appTitle, children }) => {
    const theme = useTheme();
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    if (userEmail) {
        return (
            <Hidden mdUp>
                <AppBar
                    position='fixed'
                    sx={{
                        zIndex: theme.zIndex.drawer + 1,
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0px 16px 0px 16px',

                    }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
                        <Typography
                            sx={{
                                fontFamily: 'Raleway',
                                fontWeight: 700,
                                fontSize: pxToRem(14),
                                lineHeight: pxToRem(16.8),
                                color: JC_COLORS.JC_WHITE,
                            }}>{appTitle}</Typography>
                    </Box>
                    <Box sx={{ margin: "7px 12px 7px 0px" }}> 
                        <ProfileMenu
                            width={"36px"}
                            height={"36px"}
                        />
                    </Box>
                    <Box sx={{ margin: "7px 12px 7px 0px" }}>
                        <HelpMenu mobile={true} />
                    </Box>
                </AppBar>
                <Box>
                    {children}
                </Box>

            </Hidden>

        )
    } else {
        return (
            <Hidden mdUp>
                <GuestNavbarMobile />
                <Box>
                    {children}
                </Box>



            </Hidden>

        )

    }


}

export default JCDetailsFullScreenMobile