import { CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import {getArticle,getJournalClubArticle} from '../../state/actions/article';
import { resetUplodedFiles, setNewCount } from '../../state/actions/comment';
import { v4 } from 'uuid';
import { IgniteWysiwygEditor } from 'ignite-editor';
import 'ignite-editor/dist/index.css';
import { PdfIcon } from '../../assets/JCWysiwygIcons';

import { addWrapperToContent, getFileObjectsToUpload, convertFileArraytoPost,htmlToJCEditor } from '../../utils/JCWysiwygEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { AuthContext } from '../../context/AuthContext';
import { useParams } from 'react-router';
import { fetchSignedUrlS3, updateComment, postCommentNotes } from '../../api';
import { DEFAULT_JC } from '../../constants/constants';
import axios from 'axios';
import { isEditorEmpty, formats,CommentCustomSkin,CommentHistory,updateInFilesEditor, convertBytes } from '../../utils/editorBasics';
import { CommentModules } from '../../utils/editorModules'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root': {
    padding: '8px 24px',
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      borderRadius: '0px',
      margin: 0,
    },
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      width: '88%',
      height: '85%',
      maxWidth: '900px',
    },
  },
}));
const BootstrapDialogMedium = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root': {
    padding: '8px 24px',
  },
  '& .MuiIconButton-root': {
    padding: '0px',
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '80%',
      height: 'auto',
    },
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      width: '30%',
      height: 'auto',
    },
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.white[500],
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CommentModal(props) {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();

  const userEmail = auth.user.email.split('@').join('%40');
  const theme = useTheme();
  const mobileMatch = useMediaQuery(theme.breakpoints.down('md'));
  const {
    open,
    onClose,
    isReply,
    commentOn,
    data,
    isContributor,
    isNote,
    isEdit,
    editCommentID,
    force,
  } = props;
  const [editorState, setEditorState] = useState('')
  const [_disable, _setDisable] = useState(false);
  const [vv, setVV] = useState(0);
  const [disablePost,setDisablePost] = useState(true)
  const [showMediaLoading,setMediaLoading] = useState(false)
  const [DATA, setDATA] = useState(data === undefined ? [] : data);
  let postedAComment =
    typeof localStorage.getItem('JC_LS_comments_metadata') === 'undefined' ||
    typeof localStorage.getItem('JC_LS_comments_metadata') === 'null'
      ? localStorage.setItem('JC_LS_comments_metadata', JSON.stringify(false))
      : JSON.parse(localStorage.getItem('JC_LS_comments_metadata'));
  const { PMID, JCID } = useParams();
  const isJCPage = JCID ? (JCID === 'J00000000' ? false : true) : false;
  const [openDiscard, setOpenDiscard] = useState(false);
  const [openCommentModal, setCommentModal] = useState(false);
  const { uploadImageFiles,uploadVideoFiles,uploadDocumentFiles } = useSelector((state) => state.comment);

  const [keyboardHeight,setKeyboardHeight] = useState(0)

  const EditorRef = useRef();

  useEffect(() => {
    const handleResize = (event) => {
      console.log(window.innerHeight - window.outerHeight,"KeyBoard Height")
      const h = event.target.height
      console.log(h,"Another height")
      setKeyboardHeight(window.innerHeight - window.outerHeight);
    };

    window.addEventListener("resize", handleResize);
    window.visualViewport.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.visualViewport.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // NOTE: manually setting timeout to wait for ref to be set
    setTimeout(() => {
      EditorRef.current?.focus();
    }, 100);
    if (open === true || openCommentModal === true) {
      neutralizeBack(handleModalClose);
      setTimeout(()=> {
        if(uploadDocumentFiles){
          uploadDocumentFiles.map((uploadFile) =>{
            updateInFilesEditor(uploadFile,true)
          })
        }
      },1000)
      setTimeout(()=>{
        const targetButton = document.querySelector('button.ql-attachment')
        console.log(targetButton,"target Button")
        if(targetButton){
          targetButton.setAttribute('title','Document')
          targetButton.innerHTML = PdfIcon
        }
      },0) 
    }
  }, [EditorRef, open, openCommentModal]);

  const neutralizeBack = (callback) => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
      callback();
    };
  };

  const revivalBack = () => {
    window.onpopstate = undefined;
    window.history.back();
  };

  const toolbarOptions = [
    ['image','video','localvideo','attachment'],
    [
      { header: 1 },
      { header: 2 },
      'bold',
      'italic',
      'underline',
      { list: 'ordered' },
      { list: 'bullet' },
      { script: 'sub' },
      { script: 'super' },
      { indent: '-1' },
      { indent: '+1' },
      'link',
      { color: [] },
    ],
  ];

  useEffect(() => {
    if (isEdit === true && data) {
      setEditorState(htmlToJCEditor(data.text,data.commentMedia))
    }
  }, [isEdit, data]);

  useEffect(()=> {

  },[])

  useEffect(() => {
    postedAComment = JSON.parse(
      localStorage.getItem('JC_LS_comments_metadata')
    );
    postedAComment =
      typeof localStorage.getItem('JC_LS_comments_metadata') === 'undefined' ||
      typeof localStorage.getItem('JC_LS_comments_metadata') === 'null'
        ? localStorage.setItem('JC_LS_comments_metadata', JSON.stringify(false))
        : JSON.parse(localStorage.getItem('JC_LS_comments_metadata'));
    document.querySelector('.rdw-editor-main')?.focus();
    if (postedAComment) {
      postedAComment = false;
      localStorage.setItem('JC_LS_comments_metadata', JSON.stringify(false));
    }
    EditorRef?.current?.focus();
  }, []);

  const handleDiscardClose = () => {
    postedAComment = true;
    ResetEditorState(postedAComment);
    setOpenDiscard(false);
  };
  const handleDiscardClick = () => {
    postedAComment = true;
    ResetEditorState(postedAComment);
    setOpenDiscard(false);
  };
  const handleGoBackClick = () => {
    setOpenDiscard(false);
    setCommentModal(true);
  };

  const getQuillEditor = () =>{
    return EditorRef.current.getEditor();
  }
  const handleModalClose = () => {
    // console.log("In handle modal close inside")
    // window.history.back()
    // const editor = EditorRef.current.getEditor();
    const isDraft = !isEditorEmpty(getQuillEditor())
    console.log(isDraft,":: isDraft")
    if (isDraft && !isEdit) {
      setOpenDiscard(true);
    }
    if (isEdit) {
      dispatch(resetUplodedFiles())
      setEditorState((prevState) => '');
      _setDisable((prevState) => false);
    }
    setCommentModal(false);
    revivalBack();
    onClose();
  };

  const dispatchRefreshEvents = () => {
    if (isJCPage) {
      dispatch(getJournalClubArticle(JCID, PMID, auth.user.email, false));
    } else {
      dispatch(getArticle(PMID, auth.user.email, false));
    }
    dispatch(setNewCount());
  };

  const handleArrayOfImage = async (fileListToUpload, commentID) => {
    const promises = fileListToUpload.map((uploadedFile) => {
      return handleImageUploadS3_DEMO(commentID, uploadedFile);
    });  
    return Promise.all(promises);
  };

  const handleImageUploadS3_DEMO = (commentId, uploadedImage) => {
    return new Promise((resolve, reject) => {
      let fileData = {
        fileId : uploadedImage.id,
        fileSize : uploadedImage.file.size,
        fileName: uploadedImage.file.name,
        mimeType: uploadedImage.file.type,
      };
      fetchSignedUrlS3(commentId, fileData)
        .then((response) => {
          if (response?.data) {
            console.log(response.data, '::response in getting S3 url');
            //NOTE: NOTE: SIGNED URL AVAILABLE HERE
            const _signedUrl = response.data.url;

            // upload file start here
            const _dataToS3 = uploadedImage.file;
            const _form = new FormData();
            _form.append(
              'x-amz-algorithm',
              response.data.upload_data['x-amz-algorithm']
            );
            _form.append(
              'x-amz-credential',
              response.data.upload_data['x-amz-credential']
            );
            _form.append('x-amz-date', response.data.upload_data['x-amz-date']);
            _form.append('Policy', response.data.upload_data.policy);
            _form.append(
              'x-amz-signature',
              response.data.upload_data['x-amz-signature']
            );

            _form.append('key', response.data.upload_data.key);

            _form.append(
              'x-amz-security-token',
              response.data.upload_data['x-amz-security-token']
            );
            _form.append('file', _dataToS3);

            axios
              .post(_signedUrl, _form, {
                headers: {
                  'Content-Type': ' multipart/form-data',
                  'x-amz-acl': 'public-read',
                },
              })
              .then((r) => {
                // NOTE: S3 upload successful
                if (r.status >= 200 && r.status < 400) {
                  resolve(r.status);
                  console.log('Upload done');
                } else {
                  onClose();
                }
              })
              .catch((err) => {
                onClose();
                console.warn('An error occurred. ', err);
              });
          }
        })
        .catch((error) => {
          console.log(error, ':: error while getting s3 url');
        });
    });
  };

  const [__DATA, __setDATA] = useState([]);
  const PostButtonHandleClose = () => {
    // TODO: Handle validation of comment
    _setDisable((prevState) => true);
    const editor = EditorRef.current.getEditor();
    console.log(editorState, 'This is what editor is giving.');
    let _DATA = {};
    var resultObject = {}
    if(isEdit){  
      resultObject = addWrapperToContent(editorState,data.commentMedia)
    }else {
      resultObject = addWrapperToContent(editorState)
    }
    const fileListToUpload = getFileObjectsToUpload(uploadImageFiles,uploadVideoFiles,uploadDocumentFiles,resultObject.fileIdListToUpload)
    console.log(fileListToUpload,":: File List To Upload")
    const postMediaData = convertFileArraytoPost(fileListToUpload)
    console.log(postMediaData,"POST MEDIA DATA")

    if (
      !isEditorEmpty(editor)
    ) {
      let res = resultObject.wrappedContent
      //const _parentId = DATA.length!==0?DATA.filter(d => d.parentId === commentOn || d.id === commentOn)[0]?DATA.filter(d => d.parentId === commentOn || d.id === commentOn)[0]:null:null
      if (isEdit) {
        _DATA = {
          text: resultObject.wrappedContent,
          existingMedia: resultObject.UploadedAndExistIDLIst.join('|'),
        };
        updateComment(editCommentID, userEmail, _DATA)
          .then((response) => {
            if (resultObject.UploadedAndExistIDLIst.length !== data.commentMedia.length) {
              // Already upload to backend and still present in comment
              var imageListToDelete = [];
              data.commentMedia.map((media) => {
                if (!resultObject.UploadedAndExistIDLIst.includes(media.fileId)) {
                  imageListToDelete.push(media.fileId);
                }
              });
            }
            if (fileListToUpload && fileListToUpload.length > 0) {
              setMediaLoading(true)
              setDisablePost(true)
              handleArrayOfImage(fileListToUpload, editCommentID).then(
                (res) => {
                  setMediaLoading(false)
                  postedAComment = true;
                  setVV((prevState) => ++prevState);
                  ResetEditorState(postedAComment);
                  dispatchRefreshEvents();
                  onClose();
                }
              );
            } else {
              postedAComment = true;
              ResetEditorState(postedAComment);
              dispatchRefreshEvents();
              onClose();
            }
          })
          .catch((error) => {
            console.error(error);
          });
        // comment Update Ends
      } else {
        _DATA = {
          text: resultObject.wrappedContent,
          referenceCommentID: isReply ? commentOn : '',
          rootCommentID: isReply ? commentOn : '',
        };
        if (isNote) {
          _DATA = { ..._DATA, isNote: isNote };
        }
        const jcVerb = JCID ? JCID : DEFAULT_JC;
        postCommentNotes(jcVerb, PMID, userEmail, _DATA)
          .then((response) => {
            const _data = response.data;
            console.log('after posting a comment');
            //console.log(imageListToUpload,"Final Image List to Upload")
            if (fileListToUpload && fileListToUpload.length > 0) {
              setMediaLoading(true)
              setDisablePost(true)
              handleArrayOfImage(fileListToUpload, _data.commentID).then(
                (res) => {
                  console.log('after posting is Image');
                  setMediaLoading(false)
                  postedAComment = true;
                  setVV((prevState) => ++prevState);
                  ResetEditorState(postedAComment);
                  dispatchRefreshEvents();
                  onClose();
                }
              );
            } else {
              console.log('In the else part');
              postedAComment = true;
              ResetEditorState(postedAComment);
              dispatchRefreshEvents();
              onClose();
            }
          })
          .catch((err) => console.log(err));
      } // else end
    }
    // onClose()
  };
  const ResetEditorState = (postedAComment) => {
    setCommentModal(false);
    localStorage.setItem(
      'JC_LS_comments_metadata',
      JSON.stringify(postedAComment)
    );
    localStorage.setItem('JC-refresh-for-comments', JSON.stringify(true));
    let id2 = setTimeout(() => {
      dispatch(resetUplodedFiles())
      setEditorState((prevState) => '');
      _setDisable((prevState) => false);
      localStorage.setItem('JC-refresh-for-comments', JSON.stringify(false));
      clearTimeout(id2);
    }, 500);
  };

  const editorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(()=> {
    if(EditorRef && EditorRef.current){
      const editor = EditorRef.current.getEditor();
      setDisablePost(isEditorEmpty(editor))
    }
  },[EditorRef,editorState])

  return (
    <React.Fragment>
      <BootstrapDialog
        open={open || openCommentModal}
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
      >
        <BootstrapDialogTitle
          sx={{ background: '#EF5B50', color: '#fff' }}
          id="customized-dialog-title"
          onClose={handleModalClose}
        >
          {isEdit && (
            <Typography
              variant="H3_RALEWAY"
              sx={{ fontWeight: 600, color: '#ffffff' }}
            >
              {isReply
                ? 'Edit a Reply'
                : isNote
                ? 'Edit a Private Note'
                : 'Edit a Public Comment'}
            </Typography>
          )}
          {!isEdit && (
            <Typography
              variant="H3_RALEWAY"
              sx={{ fontWeight: 600, color: '#ffffff' }}
            >
              {isReply
                ? 'Add a Reply'
                : isJCPage && isContributor && isNote
                ? 'Add a Private Note'
                : 'Add a Public Comment'}
            </Typography>
          )}
        </BootstrapDialogTitle>
        <DialogContent>
          <Box>
            <IgniteWysiwygEditor
              theme="snow"
              rootClass="jc-editor"
              value={editorState}
              onChange={editorStateChange}
              placeholder={'Start writing...'}
              modules={CommentModules}
              formats={formats}
              toolbarOptions={toolbarOptions}
              allowUnsafeUrls={true}
              bounds="#toolbar"
              ref={EditorRef}
            />
          </Box>
        </DialogContent>
        <DialogActions pr={3} sx={{background:{xs: '#F3F3F3',md:'linear-gradient(to right, #D6D6D6 200px, #F3F3F3 0%)'}}}>
          <Button
            id="good_id"
            variant="contained"
            onClick={PostButtonHandleClose}
            sx={{ zIndex: '99999', marginRight: '8px' }}
            disabled={disablePost}
          >
            Post
          </Button>
        </DialogActions>
        <Backdrop open={showMediaLoading} sx={{displa:'flex',flexDirection:'column'}}>
          <CircularProgress sx={{color:'#FFFFFF'}}/>
          <Box mt={2}>
            <Typography variant="H3_RALEWAY" sx={{color:'#FFFFFF'}} >Posting Comment...</Typography>
          </Box>
        </Backdrop>
      </BootstrapDialog>
      
      <BootstrapDialogMedium
        onClose={handleDiscardClose}
        aria-labelledby="customized-dialog-title"
        open={openDiscard}
      >
        <BootstrapDialogTitle
          sx={{ background: '#EF5B50', color: '#fff' }}
          onClose={handleDiscardClose}
        >
          <Typography variant="H3_RALEWAY">
            {isReply
              ? 'Add a Reply'
              : isJCPage && isContributor && isNote
              ? 'Add a Private Note'
              : 'Add a Public Comment'}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }} pt={2} pl={2} pr={2}>
            <Box pl={{ xs: 2, md: 2 }} pr={{ xs: 2, md: 2 }} mb={2}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'bitter',
                  fontWeight: '600',
                }}
                gutterBottom
              >
                Are you sure you want to leave and discard your draft?
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', paddingBottom: '20px!important' }}
        >
          <Box sx={{ display: 'inline-block' }}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleGoBackClick}
              sx={{ textTransform: 'none!important' }}
            >
              Go Back
            </Button>
          </Box>
          <Box sx={{ display: 'inline-block' }} mr={2}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleDiscardClick}
              sx={{ textTransform: 'none!important' }}
            >
              Yes, Discard
            </Button>
          </Box>
        </DialogActions>
      </BootstrapDialogMedium>
    </React.Fragment>
  );
}

export default CommentModal;
