import React from 'react'
import { Box, Button, FormControl, FormControlLabel, RadioGroup, Radio, Typography, Menu, MenuItem, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { pxToRem } from '../../../utils';
import { JC_COLORS } from '../../../styles/constants';

const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontWeight: 700,
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  textTransform: 'capitalize',
  [theme.breakpoints.up('md')]: {
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    height: '42px',
    minWidth: '142px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(20),
    height: '32px',
    minWidth: '150px',
  },
}));

const StyledLabel = styled(Box)(({ theme }) => ({
  width: "272px",
  height: '32px',
  marginBottom: "4px",
  borderRadius: '2px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 0.5rem',
}));

const CustomDropdown = ({ selectedLabel, listOfLabels, changeLabel }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selectedLabel}
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <RadioGroup
          default={selectedLabel}
          value={selectedLabel}
          onChange={(event) => {
            changeLabel(event.target.value)
            handleClose()
          }}
        >
          {
            listOfLabels?.map((el, i) => (
              <FormControlLabel
                key={el}
                value={el}
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': {
                        display: 'none',
                      },

                    }}
                  />
                }
                label={
                  <StyledLabel
                    sx={{
                      backgroundColor: el === selectedLabel ? JC_COLORS.JC_RED : '#F3F3F3',
                      color: el === selectedLabel ? JC_COLORS.JC_WHITE : JC_COLORS.JC_ICON,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Raleway',
                        fontWeight: '600',
                        fontSize: '0.75rem',
                        lineHeight: '0.875rem',
                        textTransform: 'capitalize',
                      }}
                    >
                      {el}
                    </Typography>
                    {(el === selectedLabel) && (<CheckIcon />)}

                  </StyledLabel>

                }
              />

            ))
          }

        </RadioGroup>

      </Menu>
    </>

  )
}

export default CustomDropdown