import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
  Grid,
  Divider,
  Chip,
  FormControl, FormControlLabel, RadioGroup,Radio,
  useMediaQuery,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Hidden from '@mui/material/Hidden';
import {stringToColor} from '../../../utils';
import { JC_COLORS } from '../../../styles/constants';
import {
  CenterFlexBox,
  ArtcleTitleCell,
  InstitutionCell,
  InlineBox,
  SummaryBox,
  StyledTextField,
  StyledSearchButton,
  DownloadReportButton,
  EnhancedTableHead,
  UserActivityHeadCell,
  DefaultSelectedJC,
  JCChip,
} from '../../JCDetails/jc-content/report-content/ReportHelpers';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CustomRadioDateRange from '../../Feed/components/CustomRadioDateRange';

export const JCTopDrawer = ({ title,selectedJC,changeSelectedJC,listOfJC,jcList,drawerOpen, onClose }) => {

  const [localSelectedJC,setLocalSelectedJC] = React.useState(selectedJC)

  useEffect(() => {
    setLocalSelectedJC(selectedJC)
  },[selectedJC])

  const getJCIconColor = (JCID, name) => {
    return (
      jcList.filter((JC) => JC.JCID === JCID)[0]?.iconColor ??
      stringToColor(name ?? '')
    );
  };
  const handleOnClose = () => {
      onClose()
      setLocalSelectedJC(selectedJC)
  }
  const handleOnSubmit = () => {
    changeSelectedJC(localSelectedJC)
    onClose()
  }
  const list = () => (
    <Box
      sx={{ width: 'auto'}}
      role="presentation"
      onKeyDown={handleOnClose}
    >
      <JCDialogTitle id="customized-dialog-title" onClose={handleOnClose}>
        <Typography variant="H2_BITTER" pl={{ xs: 0, md: 2.2 }}>
          {title}
        </Typography>
      </JCDialogTitle>
      <Box mt={2} sx={{minHeight:'75vh'}}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          default={localSelectedJC.name}
          value={localSelectedJC.name}
          onChange={(event) => {
            setLocalSelectedJC({
                name : event.target.value,
                JCID : listOfJC.filter(JC => JC.name === event.target.value)[0].JCID,
                apiKey : listOfJC.filter(JC => JC.name === event.target.value)[0]?.apiKey ?? "JC",
                labelView : listOfJC.filter(JC => JC.name === event.target.value)[0]?.labelView ?? false
            })
          }}
        >
          {listOfJC &&
            listOfJC.map((JC, idx) => (
              <Box key={'p' + JC.name} ml={2.5}>
                <Box sx={{ display: 'inline-block' }}>
                  <FormControlLabel
                    value={JC.name}
                    control={<Radio />}
                    label={ JC.labelView ?
                        ( <Typography variant="BODY_COPY2_RALEWAY" sx={{color: JC.name === localSelectedJC.name ? JC_COLORS.JC_RED : JC_COLORS.JC_ICON,marginLeft:'8px',fontWeight:'600'}}>{ JC.name }</Typography>)
                        : 
                        <JCChip jcName={JC.name} jcColor={getJCIconColor(JC.JCID,JC.name)} isMobile={true} nameColor={JC.name === localSelectedJC.name ? JC_COLORS.JC_RED : JC_COLORS.JC_ICON} maxWidth="35ch" />  }
                  />
                </Box>
              </Box>
            ))}
        </RadioGroup>
      </Box>
      <DialogActions sx={{ justifyContent: 'center',paddingBottom:'16px!important' }}>
            <Button variant='contained' type="submit" sx={{height:'35px'}} onClick={handleOnSubmit}>
                <Typography variant='H3_RALEWAY'>
                    Filter Journal Clubs
                </Typography>
            </Button>
        </DialogActions>
    </Box>
  );

  return (
    <Box mb={5}>
      <React.Fragment>
        <Drawer
          anchor={'top'}
          open={drawerOpen}
          onClose={handleOnClose}
          sx={{ '&.MuiModal-root': { zIndex: '1251 !important' } }}
        >
          {list('top')}
        </Drawer>
      </React.Fragment>
    </Box>
  );
};

const JCDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: JC_COLORS.JC_RED,
        color: JC_COLORS.JC_WHITE,
        zIndex: '999999',
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            color: JC_COLORS.JC_WHITE,
            cursor: 'pointer',
          }}
        />
      ) : null}
    </DialogTitle>
  );
};


export const JCTopDateRangeDrawer = ({title,dateRange,dateRangeLabel,drawerOpen, onClose,setDateRangeLabel,setDateRange }) => {
    const [localDateRangeLabel,setLocalDateRangeLabel] = React.useState(dateRangeLabel)
    const [localDateRange,setLocalDateRange] = React.useState(dateRange)
    const handleOnClose = () => {
        setLocalDateRange(dateRange)
        setLocalDateRangeLabel(dateRangeLabel)
        onClose()
    }
    const handleOnSubmit = () => {
        setDateRangeLabel(localDateRangeLabel)
        setDateRange(localDateRange)
        onClose()
    }
    const list = () => (
      <Box
        sx={{ width: 'auto'}}
        role="presentation"
        onKeyDown={handleOnClose}
      >
        <JCDialogTitle id="customized-dialog-title" onClose={handleOnClose}>
          <Typography variant="H2_BITTER" pl={{ xs: 0, md: 2.2 }}>
            {title}
          </Typography>
        </JCDialogTitle>
        <Box mt={2} sx={{minHeight:'75vh'}}>
        <CustomRadioDateRange
        // dateRange={dateRange}
        dateRangeInput={localDateRange}
        dateRangeLabel={localDateRangeLabel}
        changeDateRangeLabel={(val) => setLocalDateRangeLabel(val)}
        changeDateRangeHandler={(val) => setLocalDateRange(val)}
      />
        </Box>
        <DialogActions sx={{ justifyContent: 'center',paddingBottom:'16px!important' }}>
              <Button variant='contained' type="submit" sx={{height:'35px'}} onClick={handleOnSubmit}>
                  <Typography variant='H3_RALEWAY'>
                      Filter Date Range
                  </Typography>
              </Button>
          </DialogActions>
      </Box>
    );
  
    return (
      <Box mb={5}>
        <React.Fragment>
          <Drawer
            anchor={'top'}
            open={drawerOpen}
            onClose={handleOnClose}
            sx={{ '&.MuiModal-root': { zIndex: '1251 !important' } }}
          >
            {list('top')}
          </Drawer>
        </React.Fragment>
      </Box>
    );
  };
  
export const JCTableSortDrawer = ({ title,drawerOpen,order,setOrder,orderBy,setOrderBy,onClose,UserActivityHeadCell }) => {

const tempSortArray =[
    {sortLabel: 'Highest To Lowest',sortKey : 'desc'},
    {sortLabel: 'Lowest To Highest',sortKey : 'asc'}
];   
const tempSortingLabelList = UserActivityHeadCell.flatMap(d => tempSortArray.map(v => Object.assign({}, d, v)))
const sortingLabelList = tempSortingLabelList.filter(d => d.sortable)
const [localSelectedSort,setLocalSelectedSort] = React.useState(`${sortingLabelList.filter(d => d.id === orderBy)[0]?.label} ${tempSortArray.filter(v => v.sortKey === order)[0]?.sortLabel}`)

const handleOnClose = () => {
    onClose()
    setLocalSelectedSort(`${sortingLabelList.filter(d => d.id === orderBy)[0]?.label} ${tempSortArray.filter(v => v.sortKey === order)[0]?.sortLabel}`)
}
const handleOnSubmit = () => {
    const resultDict = sortingLabelList.filter(d => `${d.label} ${d.sortLabel}` === localSelectedSort)[0]
    if(resultDict){
        setOrder(resultDict?.sortKey)
        setOrderBy(resultDict?.id)
    }
    onClose()
}
const list = () => (
    <Box
    sx={{ width: 'auto'}}
    role="presentation"
    onKeyDown={handleOnClose}
    >
    <JCDialogTitle id="customized-dialog-title" onClose={handleOnClose}>
        <Typography variant="H2_BITTER" pl={{ xs: 0, md: 2.2 }}>
        {title}
        </Typography>
    </JCDialogTitle>
    <Box mt={2} sx={{minHeight:'75vh'}}>
        <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        default={localSelectedSort}
        value={localSelectedSort}
        onChange={(event) => {
            setLocalSelectedSort(event.target.value)
        }}
        >
        { sortingLabelList.map((sortd, idx) => (
            <Box key={'p' + `${sortd.label} ${sortd.sortLabel}`} ml={2.5}>
                <Box sx={{ display: 'inline-block' }}>
                <FormControlLabel
                    value={`${sortd.label} ${sortd.sortLabel}`}
                    control={<Radio />}
                    label={<Typography variant='BODY_COPY2_RALEWAY'>{`${sortd.label} ${sortd.sortLabel}`}</Typography>}
                />
                </Box>
            </Box>
            ))}
        </RadioGroup>
    </Box>
    <DialogActions sx={{ justifyContent: 'center',paddingBottom:'16px!important' }}>
            <Button variant='contained' type="submit" sx={{height:'35px'}} onClick={handleOnSubmit}>
                <Typography variant='H3_RALEWAY'>
                    Sort Articles
                </Typography>
            </Button>
        </DialogActions>
    </Box>
);

return (
    <Box mb={5}>
    <React.Fragment>
        <Drawer
        anchor={'top'}
        open={drawerOpen}
        onClose={handleOnClose}
        sx={{ '&.MuiModal-root': { zIndex: '1251 !important' } }}
        >
        {list('top')}
        </Drawer>
    </React.Fragment>
    </Box>
);
};