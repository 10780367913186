import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch, } from "react-redux";
import { Navigate, useLocation, useNavigate } from 'react-router';
import { AppBar, Toolbar, Box, Avatar, Button, Typography, FormControl, FormControlLabel, RadioGroup, Radio, Grid, Divider, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { FilterIcon, SortIcon } from '../../assets/SVG';
import { editKeywords } from '../../state/actions/keywords';

import FilterDrawer from "../../pages/EditKeywordsOrSources/containers/FilterDrawer";
import SortDrawer from "../../pages/EditKeywordsOrSources/containers/SortDrawer";
import { capitalize } from '../../utils';
import { JC_COLORS } from '../../styles/constants';

import ProfileMenu from "./ProfileMenu";
import { AuthContext } from '../../context/AuthContext';
import HelpMenu from './HelpMenu';

const NavbarMobile = ({ title = "" }) => {

  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;

  const theme = useTheme();
  const { user, feed, keywords, sources } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [isSortDrawerOpen, setIsSortDrawerOpen] = useState(false);
  const location = useLocation();
  const content = location.pathname.replace(/\//, '');
  const [appTitle, setAppTitle] = useState(content);

  const [editKeywordsOrSources, setEditKeywordsOrSources] = useState(false);

  useEffect(() => {
    setAppTitle(content === 'saved' ? "Saved Articles" : content);

    if (content === 'join-a-journal-club') {
      setAppTitle('Join a Journal Club');
    }

    if (content === 'edit-keywords-or-sources') {
      setIsFilterDrawerOpen(true);
      setEditKeywordsOrSources(true);
      setAppTitle("");
    }
    // else if(content === 'filter') {
    //   setIsFilterDrawerOpen(true); 
    // } else if(content === 'sort') {
    //   setIsSortDrawerOpen(true);
    // } 
    else {
      setEditKeywordsOrSources(false);
    }
    //console.log(content);

  }, [content]);

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          [theme.breakpoints.down("sm")]: {
            width: "100%"
          },
          zIndex: content === 'edit-keywords-or-sources' ? 0 : theme.zIndex.drawer + 1,
          bgcolor: content === 'edit-keywords-or-sources' ? 'white' : JC_COLORS.JC_RED,
          color: content === 'edit-keywords-or-sources' ? 'black' : JC_COLORS.JC_WHITE,
          display: content.startsWith('jc') ? 'none' : 'block',

        }}>
        <Toolbar >
          <div style={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                p: 1,
                borderRadius: 1,
                alignItems: "center",
                height: "2.5rem",
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexGrow: 1,

              }}>
                <Typography
                  variant="H1_BITTER_MOBILE"
                  sx={{
                    fontWeight: 'bold',
                    textTransform: content === 'join-a-journal-club' ? 'none' : 'capitalize',
                    textAlign: 'left'
                  }}
                >
                  {title ? title : appTitle}
                </Typography>
              </Box>

              <Box
                sx={{
                  borderRadius: "1.54px",
                  marginRight: "10px",
                  display: (
                    content === 'join-a-journal-club' ||
                    content === 'create-a-journal-club' ||
                    content === 'activity' ||
                    content === 'user-activity' ||
                    content === 'saved' ||
                    content === 'search'
                  ) ? 'none' : 'block',

                }}
                onClick={() => {
                  //navigate('/filter')
                  setIsFilterDrawerOpen(true)
                }}
              >
                {
                  !(isFilterDrawerOpen || isSortDrawerOpen) && (
                    <FilterIcon onClick={() => setAppTitle('Filter')} width={"27px"} height={"27px"} />
                  )
                }

              </Box>
              <Box sx={{

                borderRadius: "1.54px",
                marginRight: "17px",
                display: (
                  content === 'join-a-journal-club' ||
                  content === 'create-a-journal-club' ||
                  content === 'activity' ||
                  content === 'user-activity' ||
                  content === 'search'
                ) ? 'none' : 'block',

              }}
                onClick={() => {
                  //navigate('/sort')
                  setIsSortDrawerOpen(true)
                }
                }
              >
                {
                  !(isFilterDrawerOpen || isSortDrawerOpen) && (
                    <SortIcon
                      onClick={() => setAppTitle('Sort')}
                      width={"27px"}
                      height={"27px"}
                    />
                  )
                }
              </Box>
              <Box>
                {
                  !(isFilterDrawerOpen || isSortDrawerOpen) && (
                    <ProfileMenu
                      width={"36px"}
                      height={"36px"}
                    />
                  )
                }
              </Box>
              <Box>
                {
                  !(isFilterDrawerOpen || isSortDrawerOpen) && (                    
                    <HelpMenu mobile={true} />
                  )
                }
              </Box>
              {
                !editKeywordsOrSources && (
                  <Box>
                    {
                      (isFilterDrawerOpen || isSortDrawerOpen) && (

                        <CloseIcon
                          onClick={() => {
                            setIsFilterDrawerOpen(false)
                            setIsSortDrawerOpen(false)
                            setAppTitle(content === 'saved' ? "Saved Articles" : content);
                          }}
                        />
                      )
                    }
                  </Box>

                )
              }
            </Box>



          </div>
        </Toolbar>
      </AppBar>
      <FilterDrawer
        open={isFilterDrawerOpen}
        closeHandler={
          () => {
            setIsFilterDrawerOpen(false);
            setAppTitle(content === 'saved' ? 'Saved Articles' : content);
            if(content === 'edit-keywords-or-sources') {
              navigate("/feed");  
            }
          }
        }
        openHandler={
          () => {
            setIsFilterDrawerOpen(true)
          }
        }
      />

      <SortDrawer
        open={isSortDrawerOpen}
        closeHandler={() => {
          setIsSortDrawerOpen(false)
          setAppTitle(content === 'saved' ? 'Saved Articles' : content)

        }}
        openHandler={() => {
          //navigate("/sort"); 
          setIsSortDrawerOpen(true)
        }}
        filterCount={content === 'saved' ? 8 : 4}
      />
    </Box>
  )


};

export default NavbarMobile; 