import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Chip, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Divider } from '@mui/material';
import { Hidden } from '@mui/material';

import { Error404 } from "../../assets"
import JCHeader from './JCHeader';
import { JC_COLORS } from '../../styles/constants';
import { ErrorIcon } from '../../assets/SVG';
import { logout } from "../../utils";

function ErrorFallback({ errorMessage, resetErrorBoundary, componentStack }) {
  useEffect(() => {
    document.body.style.backgroundColor = JC_COLORS.JC_WHITE
  }, [])

  return (
    <React.Fragment>
      <JCHeader />
      <Box sx={{ textAlign: 'center' }} mt={{ xs: '20%', sm: '10%', md: '5%' }} mr={{ xs: 5, md: 36 }} ml={{ xs: 5, md: 36 }}>

        <Box pb={2} mb={{ xs: 12, md: 0 }} >
          <Box>
            <ErrorIcon width={"300"} height={"300"}/>
          </Box>

          <Box mt={{ xs: 2, sm: 4, md: 0 }} pb={{ xs: 4, sm: 5, md: 2 }}>
            <Typography variant="H2_RALEWAY" color="primary" gutterBottom>
              Something's Not Right
            </Typography>

          </Box>
          <Box mt={{ xs: 2, sm: 4, md: 0 }} pb={{ xs: 4, sm: 5, md: 2 }}>
            <Typography variant="H1_RALEWAY" color="primary" gutterBottom>
              {errorMessage}
            </Typography>
          </Box>

          <Button
            variant='contained'
            size="small"
            color="primary"
            className="mobile-botton"
            onClick={() => logout()}
          >GO BACK TO JOURNAL CLUB</Button>

        </Box>
        <Divider />

        <Box pt={2}>
          <Typography variant="BODY_COPY2_RALEWAY" gutterBottom>
            Journal Club &copy; 2022
          </Typography>
        </Box>


      </Box>

    </React.Fragment>
  )
}

export default ErrorFallback