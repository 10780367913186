import React, { useState, useEffect } from "react";
import { Box, Link, Menu } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { JC_COLORS } from "../../styles/constants";
import { styled } from "@mui/system";
import ReactImageVideoLightbox from "react-image-video-lightbox";

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: theme.shadows[1],
		...theme.typography.BODY_COPY1_RALEWAY
	}
}));

const MyProfileLink = styled(Link)(({ theme }) => ({
	width: "100%",
	cursor: "pointer",
	color: "#262626",
	margin: "9px 8px 0px 16px",
	textDecoration: "none",
	borderBottom: `1px solid ${JC_COLORS.LIGHT_GREY}`,
	paddingBottom: "0.75rem",

	[theme.breakpoints.up("md")]: {
		minWidth: "254px",
		fontFamily: "Raleway",
		fontWeight: "600",
		fontSize: "18px",
		lineHeight: "24px"
	},
	[theme.breakpoints.down("md")]: {
		minWidth: "154px",
		...theme.typography.BODY_COPY1_RALEWAY_MOBILE
	}
}));

const HelpMenu = ({ mobile = false }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const [lightboxOpen, setLightboxOpen] = useState(false);
	const [lightboxStart, setLightboxStart] = useState(0);

	const handleHelpButtonClick = (id) => {
		setLightboxStart(id);
		setAnchorEl(null);
		setLightboxOpen(true);
	};

	useEffect(() => {
		modifyLighbox();
	}, [lightboxOpen]);

	const modifyLighbox = () => {
		const iframeNode = document.getElementsByTagName("iframe");
		if (iframeNode && iframeNode[0]) {
			iframeNode[0].width = "80%";
			iframeNode[0].height = "80%";
		}
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Box ml={1}>
				<LightTooltip title="Help">
					<HelpIcon
						fontSize={mobile ? "large" : "large"}
						onClick={handleClick}
					/>
				</LightTooltip>
			</Box>

			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "flex-start",
						minHeight: "0.688rem"
					}}>
					<MyProfileLink onClick={() => handleHelpButtonClick(1)}>
						Reader functionality
					</MyProfileLink>
					<MyProfileLink onClick={() => handleHelpButtonClick(0)}>
						Contributor functionality
					</MyProfileLink>
					<MyProfileLink onClick={() => handleHelpButtonClick(2)}>
						Course Director functionality
					</MyProfileLink>
				</Box>
			</Menu>
			{lightboxOpen && (
				<div className="lightbox-custom">
					<ReactImageVideoLightbox
						data={[
							{
								url: "/JC%20Contributor%20Capabilities.mp4",
								type: "video",
								title: "For Contributors"
							},
							{
								url: "/JC%20Reader%20Capabilities.mp4",
								type: "video",
								title: "For Reader"
							},
							{
								url: "/JC%20Course%20Diretor%20+%20Administrator%20Capabilities.mp4",
								type: "video",
								title: "For Course Director and Administrator"
							}
						]}
						startIndex={lightboxStart}
						showResourceCount={true}
						onCloseCallback={() => setLightboxOpen(false)}
						onNavigationCallback={(currentIndex) =>
							modifyLighbox()
						}></ReactImageVideoLightbox>
				</div>
			)}
		</>
	);
};

export default HelpMenu;
