import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  InputAdornment,
  Divider,
  Link,
  styled,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';
import CustomDropdownDateRange from '../../../Feed/components/CustomDropdownDateRange';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {
  InvitedIcon,
  InviteRejectedIcon,
  JoinedIcon,
  LeftIcon,
  RequestedIcon,
  RequestRejectedIcon
} from '../../../../assets'
import { useSelector, useDispatch } from "react-redux";
import { fetchCommentNotesReports } from '../../../../api';
import { JC_COLORS } from '../../../../styles/constants';
import { REPORTS_NAV, REPORT_ROWS_PER_PAGE } from '../../../../constants/constants';
import { updateReportNav, updateCurrentTab, updateMemberId } from '../../../../state/actions/jcreports';
import { CenterFlexBox, UserCell, InstitutionCell, InlineBox, SummaryBox, StyledTextField, StyledSearchButton, DownloadReportButton, EnhancedTableHead, commentNoteHeadCells, ArtcleTitleCell, CommentAndNoteHandler } from './ReportHelpers';
import CustomDropdown from '../../../JCDetails/components/CustomDropdown';
import ReportTable from './ReportTable';
import { CommentIcon, NoteIcon } from '../../../../assets/SVG';
const TYPE_CONSTANT = {
  "Comments": 0,
  "Notes": 1
}
const CommentNotesReport = (props) => {
  const { reportRootFilter, rootDateRange } = useSelector((state) => state.jcreports);
  const [dateRangeLabel, setDateRangeLabel] = useState(reportRootFilter)
  const [commentNoteStatusType, setCommentNoteStatusType] = useState("All Reports")
  const [value, setValue] = useState('')
  const [commentNotesData, setcommentNotesData] = useState({})
  const [dateRange, setDateRange] = useState(rootDateRange)
  const [SearchText, setSearchText] = useState("")
  const dispatch = useDispatch()
  const { jcDetails } = useSelector((state) => state.journalclub);
  const [queryParameters, setQueryParameters] = useState(null)


  // report table states
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [isLoading, setIsLoading] = useState(true)


  let currentLoginUser = localStorage.getItem("jcid");


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(1)
  };

  const handleMemberClick = (memberId) => {
    dispatch(updateCurrentTab(REPORTS_NAV.FLAGGED))
    dispatch(updateMemberId(memberId))
    dispatch(updateReportNav("Member"))
  }

  const handleRowClick = (event) => {
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const emptyRows = 0;

  const handleSearch = () => {
    setIsLoading(true)
    setSearchText(value)
    var queryParams = {
      authUserEmail: props.userEmail,
      numRecordsPerPage: REPORT_ROWS_PER_PAGE,
      pageNumber: 1,
      sort: order,
      orderBy: orderBy,
      term: value
    }
    if (commentNoteStatusType !== "All Reports") {
      queryParams = { ...queryParams, type: TYPE_CONSTANT[commentNoteStatusType] }
    }
    setQueryParameters(queryParams)
    fetchData(queryParams)
  }
  const resetPageNumber = (val, callback) => {
    setPage(1)
    callback(val)
  }

  useEffect(() => {
    setIsLoading(true)
    var queryParams = {
      authUserEmail: props.userEmail,
      numRecordsPerPage: REPORT_ROWS_PER_PAGE,
      pageNumber: page,
      sort: order,
      orderBy: orderBy,
    }
    if(SearchText !== ""){
      queryParams = { ...queryParams, term: SearchText }
    }
    // console.log(userStatus,":: userStatus")
    if (commentNoteStatusType !== "All Reports") {
      queryParams = { ...queryParams, type: TYPE_CONSTANT[commentNoteStatusType] }
    }
    console.log(queryParams, "::queryParams")
    setQueryParameters(queryParams)
    fetchData(queryParams)
  }, [dateRange, commentNoteStatusType, page, order, orderBy, SearchText])

  const fetchData = (queryParams) => {
    if (dateRange.startDate && dateRange.endDate) {
      queryParams = { ...queryParams, ...dateRange }
      setQueryParameters(queryParams)
    }
    fetchCommentNotesReports(jcDetails?.JCID, queryParams).then(response => {
      if (response?.data) {
        setcommentNotesData(response.data)
        setIsLoading(false)
      }
    }).catch(error => {
      console.error(error)
    })
  }
  function handleDate(strDate) {
    let parts = strDate.split('-');
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
    let parsedVal = mydate.toDateString().split(" ")
    let slicedVal = parsedVal[3].split('').splice(2).join('')
    return (`${parsedVal[1]} ${parsedVal[2]}, '${slicedVal}`);
  }



  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'left' }} my={-1} mx={-0.8}>
        <Link sx={{ cursor: 'pointer', textDecoration: 'None' }} onClick={() => dispatch(updateReportNav("Reports"))} className='report-nav-root_text'>
          <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
          <Typography variant="BODY_COPY2_RALEWAY">
            {REPORTS_NAV.ROOT}
          </Typography>
        </Link>
      </Box>


      <Box sx={{ textAlign: 'left' }} mt={2} mb={1}>
        <InlineBox>
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="H1_BITTER" color="primary" sx={{ fontWeight: '700 !important' }}>
              {REPORTS_NAV.FLAGGED}
            </Typography>
          </Box>
        </InlineBox>
        <InlineBox sx={{ float: 'right' }}>
          <CustomDropdownDateRange isHeading={false} isWhite={true}
            dateRangeLabel={dateRangeLabel}
            changeDateRangeLabel={(value) => setDateRangeLabel(value)}
            changeDateRangeHandler={(value) => resetPageNumber(value, setDateRange)}
          />
        </InlineBox>
      </Box>
      <Box mt={3} sx={{ textAlign: 'left' }}>
        {isLoading ?
          <CircularProgress color='primary' sx={{ width: '20px!important', height: '20px!important' }} />

          :
          commentNotesData &&
          <Typography variant="BODY_ARTICLE_RALEWAY" sx={{ fontWeight: '700!important', marginRight: '12px' }}>
            {commentNotesData.numberOfRow !== 0 ?
              commentNotesData.numberOfRow && commentNotesData.numberOfRow + ` ${commentNoteStatusType === "All Reports" ? commentNotesData.numberOfRow === 1 ? "Issue" : "Issues" : commentNoteStatusType === "Comments" ? commentNotesData.numberOfRow === 1 ? "Comment" : "Comments" : commentNotesData.numberOfRow === 1 ? "Note" : "Notes"} Found`
              : "No Result Found"}
          </Typography>

        }
        {/* {isLoading &&
          <CircularProgress color='primary' sx={{ width: '20px!important', height: '20px!important' }} />
        } */}
      </Box>
      <Box sx={{ textAlign: 'left' }} mt={2} mb={1}>
        <InlineBox>
          <StyledTextField
            value={value}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch()
              }
            }}
            placeholder={"Search Article/Reporter"}
            autoComplete='off'
            inputProps={{
              style: {
                fontFamily: 'Raleway',
                fontWeight: 600,
              }
            }}
            InputProps={{
              startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
              endAdornment: (
                <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                  <CloseIcon style={{ display: `${value === "" ? 'none' : 'block'}` }} onClick={() => { setSearchText(""); setValue("") }} />
                </InputAdornment>),
            }}
            onChange={(e) => { setValue(e.target.value ? e.target.value : ""); }}
          ></StyledTextField>
          <StyledSearchButton variant="outlined" onClick={() => handleSearch()} sx={{ marginLeft: '11px' }}>
            Search
          </StyledSearchButton>
        </InlineBox>
        <InlineBox sx={{ float: 'right' }}>
          <InlineBox ml={1}>
            <CustomDropdown
              selectedLabel={commentNoteStatusType}
              listOfLabels={["All Reports", "Comments", "Notes"]}
              changeLabel={(val) => resetPageNumber(val, setCommentNoteStatusType)}
            />
          </InlineBox>
          <DownloadReportButton JCID={jcDetails.JCID} parameter={"flaggednc"} queryParameters={queryParameters} currentLoginUser={currentLoginUser} downloadName={"Flagged_Comments&Notes_Activity"} dateRange={dateRange} />
        </InlineBox>
      </Box>
      {/*Report Table */}
      {commentNotesData && commentNotesData.flagged_articles &&
        <Box mt={3} mb={3}>
          <Box sx={{ width: '100%' }}>
            <TableContainer>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                    fontFamily: 'Raleway',
                    fontWeight: '600',
                    fontSize: '16px',
                    borderRadius: '4px'
                  },
                  minWidth: 750
                }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={commentNoteHeadCells}
                />

                <TableBody sx={{ position: 'relative' }}>
                  <Backdrop
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute', backgroundColor: 'transparent' }}
                    open={isLoading}
                  >
                    <CircularProgress color='primary' />
                  </Backdrop>
                  {commentNotesData.flagged_articles.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <React.Fragment>
                        <TableRow
                          hover
                          onClick={(event) => handleRowClick(event)}
                          tabIndex={-1}
                          key={row.commentId}
                          sx={{
                            "&:hover": {
                              backgroundColor: "#FFF7F6!important"
                            },
                            backgroundColor: "#F3F3F3!important",
                          }}
                          className="commentNoteTable"
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{ fontFamily: 'Raleway!important' }}
                          >
                            <ArtcleTitleCell title={row.article_title} clickable={false} isDenovoArticle={row.isDenovoArticle} />
                          </TableCell>
                          <TableCell align="left"> <UserCell name={row.reportedBy ? row.reportedBy.firstName + " " + row.reportedBy.lastName : "-"} contracted={true} onClick={() => handleMemberClick(row.reportedBy)} /></TableCell>
                          <TableCell align="left"><Typography variant="H3_RALEWAY" color="#262626">{handleDate(row.reportedDate)}</Typography> </TableCell>
                          <TableCell align="center">{row.isNote ? <NoteIcon width="24px" height="24px" /> : <CommentIcon width="24px" height="24px" />}</TableCell>
                          <TableCell align="center" className='commentNoteView'><CommentAndNoteHandler urlSlug={jcDetails.urlSlug} PMID={row.PMID} CNNID={row.parentCommentId} hasReply={row.hasReply} CCID={row.commentId} /></TableCell>
                        </TableRow>
                        <Box sx={{ minHeight: '12px' }}></Box>
                      </React.Fragment>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={3} sx={{ marginLeft: 'calc( 50% - 80px)' }}>
              <Pagination count={Math.ceil(commentNotesData.numberOfRow / REPORT_ROWS_PER_PAGE)} color="primary" page={page} onChange={handleChangePage} />
            </Box>
          </Box>
        </Box>
      }


    </React.Fragment>
  )
}

export default CommentNotesReport

