import { removeTokenFromStorage } from './authToken';
import { token_name_list, local_storage_keys } from "../constants/constants";
import { USER_STATUS } from '../constants/constants';

const logout = () => {
    removeTokenFromStorage(token_name_list.ACCESS_TOKEN);
    removeTokenFromStorage(token_name_list.REFRESH_TOKEN);
    removeTokenFromStorage(token_name_list.ID_TOKEN);
    removeTokenFromStorage(local_storage_keys.LIST_OF_SEARCH_TERMS); 
    removeTokenFromStorage(token_name_list.COGNITO_IDENTITY);
    removeTokenFromStorage('masterQSE'); 
    removeTokenFromStorage("keywordState"); 
    removeTokenFromStorage("sourcesState"); 
    window.location = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
};

// const profileCheck = (isLoading, data, isError, error) => {
//     console.log(isLoading, data, isError, error?.response?.status, error?.response?.data); 
//     if (isLoading === false) {
//         if (data?.data?.status === USER_STATUS.INACTIVE) {
//             window.location.href = "/inactive-user";
//         } else if (data?.data?.areasOfInterest === null || data?.data?.areasOfInterest?.length < 1) {
//             window.location.href = "/terms-and-conditions"; 
//         } else if (data?.data?.profession === null || data?.data?.profession?.length < 1) {
//             window.location.href = "/terms-and-conditions"; 
//         } else if (data?.data?.institution === null || data?.data?.institution?.length < 1) {
//             window.location.href = "/terms-and-conditions"; 
//         } else if (isError && (error?.response?.status === 404 || error?.response?.data === 'User not found')) {
//             window.location.href = "/terms-and-conditions";
//         } else {
//             //logout(); 
//         }
//     }
// }

// const profileCheck = (user) => {

//     const incompleteTnC =  user?.userDetails?.acceptedTermsAndConditions === null || user?.userDetails?.acceptedTermsAndConditions === false
    
//     if(user.isFetching === false) {
//         if (user?.userDetails?.status === USER_STATUS.INACTIVE) {
//             window.location.href = "/inactive-user";

//         } else if( user?.userDetails?.acceptedTermsAndConditions === null || user?.userDetails?.acceptedTermsAndConditions === false ) {
//             window.location = "/terms-and-conditions";
//         } else if (
//             user?.userDetails?.areasOfInterest === null || 
//             user?.userDetails?.areasOfInterest?.length < 1 || 
//             user?.userDetails?.profession === null || 
//             user?.userDetails?.profession?.length < 1 ||
//             user?.userDetails?.institution === null || 
//             user?.userDetails?.institution?.length < 1
//             ) {
//             window.location.href = "/profile";
//         } else if (user?.isNewUser) {
//             window.location.href = "/terms-and-conditions";
//         } 
//     }
    

// }

const desktopWidthInPixels = 1920
const desktopHeightInPixels = 1377

const calcViewWidth = (widthInPixels) => `${Math.round(100 * (widthInPixels / desktopWidthInPixels))}vw`;
const calcViewHeight = (heightInPixels) => `${Math.round(100 * (heightInPixels / desktopHeightInPixels))}vh`;
const calcRem = (fontSizeInPixels) => {
    if (fontSizeInPixels <= 3) {
        return `${(fontSizeInPixels * 0.06).toFixed(2)}rem`;
    } else if (fontSizeInPixels > 3 && fontSizeInPixels <= 28) {
        return `${(fontSizeInPixels * 0.06).toFixed(1)}rem`
    } else {
        return `${Math.ceil(fontSizeInPixels * 0.06)}rem`
    }
}

const calcPublishedAgo = ({ publicationDate, forMobile = false } = {}) => {
    const add_s = (number, str) => {
        return number === 1 ? str : `${str}s`
    }

    const dateObj = new Date(publicationDate)
    const today = new Date()
    const yearDifference = today.getFullYear() - dateObj.getFullYear()
    const monthDifference = today.getMonth() - dateObj.getMonth()
    const daysDifference = today.getDate() - dateObj.getDate()
    const hoursDifference = today.getHours() - dateObj.getHours()


    if (yearDifference === 0) {
        if (monthDifference === 0) {
            if (daysDifference === 0) {
                if (hoursDifference === 0) {
                    return forMobile ? 'now' : `Published now`
                } else {
                    return forMobile ? `${hoursDifference}h` : `Published ${hoursDifference} ago`
                }
            } else {
                return forMobile ? `${daysDifference}d` : `Published ${daysDifference} ${add_s(daysDifference, 'day')} ago`
            }
        } else {
            return forMobile ? `${monthDifference}m` : `Published ${monthDifference < 0 ? 12 - monthDifference : monthDifference} ${add_s(monthDifference, 'month')} ago`
        }
    } else {
        return forMobile ? `${yearDifference}y` : `Published ${yearDifference} ${add_s(yearDifference, 'year')} ago`
    }
}

const capitalize = (word) => word[0].toUpperCase() + word.slice(1, word.length);

const pxToRem = (pixels) => `${pixels / 16}rem`;

const stringToColor = (string) => {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}
export { calcViewWidth, calcViewHeight, calcRem, calcPublishedAgo, capitalize, pxToRem, logout,stringToColor }
