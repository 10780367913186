//NOTE: IGNORED
import { React, useState, useEffect } from 'react';


import { styled, alpha, useTheme } from '@mui/material/styles';
import { AppBar, CssBaseline, Toolbar, Box, Avatar, IconButton, Typography, InputBase, Select, FormControl, InputLabel, useMediaQuery } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import { FilterIcon, SortIcon, LogoAndTitle } from '../../assets/SVG';
import dummyAvatar from "../../assets/images/dummyAvatar.png";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
 
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: 445,
  height: 44, 
  borderRadius: 4, 
  backgroundColor: 'white', 
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(1),
  //   width: 'auto',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   display: 'none',
  // },
  // [theme.breakpoints.up('md')]: {
  //   display: 'block',
  // },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black', 

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    backgroundColor: 'white',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',

    },

  },
}));



const DesktopScreenLayout = () => {
  return (

    <Box 
      sx= {{
        height: "10px"
      }}
    >
      <AppBar 
        position="fixed" 
        //sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>

          <Box
            sx={{
              flexGrow: 1,
              marginLeft: "44px", 
            }}
          >
            <LogoAndTitle />
          </Box>

          <Box sx={{ paddingRight: '18px'}}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>


          <Box>
            <Avatar variant="square" alt="Benjamin Scricia" src={dummyAvatar}></Avatar>
          </Box>

          <Box>
            <FormControl>
              <Typography sx={{ textAlign: 'center' }}>Me</Typography>
              <Select
                variant="standard"
                sx={{
                  height: 0,
                  icon: {
                    color: 'white', 
                  }

                }}></Select>
            </FormControl>

          </Box>

        </Toolbar>
      </AppBar>
    </Box >

  )
};


const MobileScreenLayout = ({ title }) => {
  const [appTitle, setAppTitle] = useState(title);
  const theme = useTheme();
  

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" >
        <Toolbar >
          <div style={{ width: '100%', }}>
            <Box
              sx={{ display: 'flex', p: 1, borderRadius: 1, alignItems: "center", height: "41px", justifyContent: 'space-between' }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="H1_BITTER_MOBILE" sx={{ fontWeight: 'bold' }}
                >
                  {appTitle}
                </Typography>
              </Box>

              <Box
                sx={{
                  borderRadius: "1.54px",
                  marginRight: "10px",
                }}

              >
                <FilterIcon onClick={() => setAppTitle('Filter')} />
              </Box>
              <Box sx={{

                borderRadius: "1.54px",
                marginRight: "17px",
              }}>
                <SortIcon onClick={() => setAppTitle('Sort')} />
              </Box>
              <Box>
                <Avatar variant="square" alt="Benjamin Scricia" src={dummyAvatar}></Avatar>
              </Box>


            </Box>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  )


};


export default function NavBar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      {
        matches ?
          <DesktopScreenLayout /> : <MobileScreenLayout title={"Feed"} />
      }
    </>



  );
}

