import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router';
import { createBrowserHistory } from "history";
import { Box, Grid, Link, Typography, Divider, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { JC_Avatar } from '../../../common/components';
import { ArticleCardView, ArticleCardViewSkeleton } from '../../../common/components';
import { getNextPageUserFeed, updateFeedQueryParameters } from '../../../state/actions/feed';
import { fetchUserFeed } from '../../../api';
import keywords from '../../../state/reducers/keywords';
import { AuthContext } from '../../../context/AuthContext';
import { JC_COLORS } from '../../../styles/constants';

const StyledGrid = styled(Grid)(({ theme }) => ({
    //overflowY: 'scroll',
    // justifyContent: 'center',
    // alignItems: 'flex-start',
    marginBottom: '16px',

    [theme.breakpoints.up('md')]: {
        width: "100%",
        padding: '16px 24px 0px 40px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0rem 16px 0rem 16px',
    }
}));

const CenteredContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        margin: '0 auto',
        width: '600px',
        margin: "70px auto",
    },
    [theme.breakpoints.down('md')]: {
        height: '100vh',
        margin: '0 auto',
        ...theme.typography.BODY_COPY1_RALEWAY_MOBILE,
    }
}));

const StyledText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H1_BITTER,
    },
    [theme.breakpoints.down('md')]: {
        ...theme.typography.H1_BITTER,
    }

}));

const ArticlesList = ({keywordsState, sourcesState}) => {
    const theme = useTheme();
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const location = useLocation();
    let history = createBrowserHistory();
    const content = location.pathname.replace(/\//, '');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { feed, keywords, sources } = useSelector((state) => state);

    const { isFetchingFeed, hasMoreArticles,nextPage, articleList } = feed;
    const [pageNumber, setPageNumber] = useState(1);
    const [paginationHit,setPaginationHit] = useState(false)
    //console.log("ARTICLES_LIST", feed);
    const observer = useRef();


    useEffect(() => {
        const keywordAndSource = getSelected(keywordsState, sourcesState)
        const { unreadOnly, orderBy } = feed.queryParameters;
        const { startDate, endDate } = feed.queryParameters.dateRange;

        let selectedKeywords = keywords.items.filter(el => el.isSelected).map(el => el.title);
        let keyword = selectedKeywords.join('|');

        let selectedSources = sources.items.filter(el => el.isSelected).map(el => el.title);
        let source = selectedSources.length > 0 ? selectedSources[0] : "";
        
        if (pageNumber > 1 && isFetchingFeed === false) {
            var filterQueryParams = { pageNumber, unreadOnly, orderBy,...keywordAndSource }
            if(startDate && endDate){
                filterQueryParams = {...filterQueryParams,...feed.queryParameters.dateRange}
              } 
            dispatch(getNextPageUserFeed(userEmail, filterQueryParams));
        }

    }, [pageNumber,paginationHit]);

    const getSelected = (keywordsStateObj, sourcesStateObj) => {
        return {
          keyword: Object.keys(keywordsStateObj ?? {}).filter(item => keywordsStateObj[item]).join('|'),
          source: Object.keys(sourcesStateObj ?? {}).filter(item => sourcesStateObj[item]).join(""),
        }
      }



    const lastArticleElementRef = useCallback(node => {
        if (isFetchingFeed) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreArticles && isFetchingFeed === false) {
                setPageNumber(nextPage)
                setPaginationHit(!paginationHit)
                if (isFetchingFeed) return
            }
        });
        if (node) observer.current.observe(node)
    }, [isFetchingFeed]);




    return (

        <StyledGrid
            container
            spacing={{ lg: 2, xl: 2, md: 2, xs: 0, sm: 0 }}
        >
            {
                articleList && (articleList.map((obj, index) => {

                    let userIsMemberInJCsArticleIsPublishedIn = obj?.journalClubs?.filter(data => data?.isUserAMember === true && data?.articleStatus === "Published");
                    let userCannotViewArticle = userIsMemberInJCsArticleIsPublishedIn === null || userIsMemberInJCsArticleIsPublishedIn?.length === 0;
                    let userCanViewArticle = userIsMemberInJCsArticleIsPublishedIn !== null && userIsMemberInJCsArticleIsPublishedIn?.length > 0;

                    let articleJCUrl = userCanViewArticle ?
                        `/jc/${userIsMemberInJCsArticleIsPublishedIn[0].urlSlug}/${obj.PMID}` : '/jc/errorPMID';

                    if (articleList.length === index + 1) {
                        //console.log(index, articleList.length, obj);
                        return (
                            <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                                key={obj.PMID}
                                ref={lastArticleElementRef}
                                onClick={
                                    () => {
                                        if (obj?.isDeNovo) {
                                            if (userCannotViewArticle) {
                                                return
                                            } else {
                                                window.location = articleJCUrl;

                                            }

                                        } else {
                                            navigate(`/article/${obj.PMID}`, { state: { source: `${content}` } })
                                        }

                                    }
                                }
                                sx={{
                                    cursor: 'pointer',
                                    display: (obj?.isDeNovo && userCannotViewArticle) ? "none" : "block",
                                }}
                            >
                                <ArticleCardView
                                    isLoading={isFetchingFeed}
                                    journalSource={obj.sourceName}
                                    journalIssue={obj.issue}
                                    showCME={obj.showCME}
                                    isSaved={obj.savedByUser}
                                    journalTitle={obj.title}
                                    authors={obj.authors}
                                    publicationDate={obj.publicationdate}
                                    keywords={obj.keywords}
                                    numberOfPractices={obj.numberOfPracticeImpacting}
                                    numberOfPracticeImpactingInUserJCs={obj.numberOfPracticeImpactingInUserJCs}
                                    numberOfLikes={obj.numberOfLikes}
                                    numberOfLikesInUserJCs={obj.numberOfLikesInUserJCs}
                                    numberOfComments={obj.numberOfComments}
                                    numberOfCommentsInUserJCs={obj.numberOfCommentsInUserJCs}
                                    numberOfNotes={0}
                                    noteAddedByUser={false}
                                    piByUser={obj.PIByUser}
                                    isLiked={obj.likedByUser}
                                    isCommented={obj.numberOfComments > 0}
                                    isRead={obj.readByUser}
                                    showJcDate={false}
                                    journalClubsList={obj.journalClubs}
                                    showUnderDiscussionJCs={true}
                                    showPublishedJCs={true}
                                    PMID={obj.PMID}
                                />
                            </Grid>
                        )
                    } else {
                        return (
                            <Grid item lg={6} xl={6} md={12} sm={12} xs={12}
                                key={obj.PMID}
                                onClick={
                                    () => {
                                        if (obj?.isDeNovo) {
                                            if (userCannotViewArticle) {
                                                return
                                            } else {
                                                window.location = articleJCUrl;

                                            }

                                        } else {
                                            navigate(`/article/${obj.PMID}`, { state: { source: `${content}` } })
                                        }

                                    }
                                }
                                sx={{
                                    cursor: 'pointer',
                                    display: (obj?.isDeNovo && userCannotViewArticle) ? "none" : "block",
                                }}
                            >

                                <ArticleCardView
                                    isLoading={isFetchingFeed}
                                    journalSource={obj.sourceName}
                                    journalIssue={obj.issue}
                                    showCME={obj.showCME}
                                    isSaved={obj.savedByUser}
                                    journalTitle={obj.title}
                                    authors={obj.authors}
                                    publicationDate={obj.publicationdate}
                                    keywords={obj.keywords}
                                    numberOfPractices={obj.numberOfPracticeImpacting}
                                    numberOfPracticeImpactingInUserJCs={obj.numberOfPracticeImpactingInUserJCs}
                                    numberOfLikes={obj.numberOfLikes}
                                    numberOfLikesInUserJCs={obj.numberOfLikesInUserJCs}
                                    numberOfComments={obj.numberOfComments}
                                    numberOfCommentsInUserJCs={obj.numberOfCommentsInUserJCs}
                                    numberOfNotes={0}
                                    noteAddedByUser={false}
                                    piByUser={obj.PIByUser}
                                    isLiked={obj.likedByUser}
                                    isCommented={obj.numberOfComments > 0}
                                    isRead={obj.readByUser}
                                    showJcDate={false}
                                    journalClubsList={obj.journalClubs}
                                    showUnderDiscussionJCs={true}
                                    showPublishedJCs={true}
                                    PMID={obj.PMID}

                                />

                            </Grid>

                        )
                    }
                }
                )
                )


            }

            {
                isFetchingFeed && (
                    [...new Array(100).keys()].map((el) => (
                        <Grid key={el} item lg={6} xl={6} md={12} sm={12} xs={12}>
                            <ArticleCardViewSkeleton />
                        </Grid>
                    ))
                )
            }
            {
                (articleList === undefined || (!isFetchingFeed && articleList.length === 0)) && (
                    <CenteredContainer>
                        <StyledText>You are all caught up!</StyledText>
                        {/* <StyledText>There are no {feed.queryParameters.unreadOnly ? 'Unread': ''} articles in your feed {` matching${keywords.items.filter(el => el.isSelected).map(el => el.title).length > 0 > 0 ? keywords.items.filter(el => el.isSelected).map(el => el.title).join(", "): ''}`}. </StyledText> */}
                        <StyledText>{showCustomizedMessage(keywords.items.filter(obj => obj.isSelected).map(obj => obj.title), sources.items.filter(obj => obj.isSelected).map(obj => obj.title), feed.queryParameters, content)}</StyledText>
                    </CenteredContainer>
                )
            }





        </StyledGrid>





    )
}

export default ArticlesList

function showCustomizedMessage(keywords, sources, feedQueryParams, pageName) {

    const { dateRangeLabel, dateRange, orderByLabel, unreadOnly } = feedQueryParams;
    const { startDate, endDate } = dateRange;

    let keywordMessage = keywords.length > 0 ? `matching the keywords` : '';
    let sourceMessage = sources.length > 0 ? `and sources ` : '';
    let dateRangeMessage = "";

    if (dateRangeLabel === 'All Time') {
        dateRangeMessage = ` in the All-Time time frame`
    } else if (dateRangeLabel === 'CUSTOM') {
        dateRangeMessage = ` between the ${startDate} to ${endDate} time frame`
    } else {
        dateRangeMessage = ` in the ${dateRangeLabel}`
    }

    if (pageName === 'saved') {
        return `There are no ${orderByLabel} Saved Articles, ${dateRangeMessage}`
    } else {
        return ` There are no ${orderByLabel.replace("Articles", "")} ${unreadOnly ? 'unread' : ''} articles in your feed matching the keywords and sources you have entered, ${dateRangeMessage}`;
    }

}


const JournalClubsComponent = ({ articleJcList }) => {
    const theme = useTheme();
    const { jcList } = useSelector((state) => state.journalclub);

    const articlePublishedIn = articleJcList.filter(obj => obj.articleStatus === 'Published');
    const articlePublishedInAndUserIsMember = articlePublishedIn.filter(obj => obj.isUserAMember);

    const jcColors = jcList.reduce((acc, curr) => {
        acc[curr.JCID] = curr.iconColor
        return acc;
    }, {});

    const restOfJc = articlePublishedIn.length - articlePublishedInAndUserIsMember.length;

    return (
        <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            {
                articlePublishedInAndUserIsMember.map(obj => (
                    <JC_Avatar
                        key={obj.JCID}
                        iconColor={jcColors[obj.JCID]}
                        title={obj.name}
                        size={20}
                        sx={{ marginRight: '4px' }}
                    />)
                )
            }
            <Typography
                sx={{
                    color: JC_COLORS.JC_ICON,
                    ...theme.typography.BODY_COPY2_RALEWAY,
                    fontWeight: 700
                }}>{restOfJc > 0 ? `+${restOfJc} JC` : ''}</Typography>
        </Box>
    )

}


