import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchUserDetails, createNewUser, updateUserDetails } from "../../api";

export const fetchJcUserData = async (userEmail) => {
    return fetchUserDetails(userEmail);
}

export const addJcUserData = async (newJcUserData) => {
    return createNewUser(newJcUserData); 
}

export const updateJcUserData = async ({jcUserData, userEmail}) => {
    console.log("....")
    console.log(jcUserData, userEmail)
    console.log(".....")
    return updateUserDetails(jcUserData, userEmail);
}

export const useJcUserData = (onSuccess, onError, userEmail) => {
    return useQuery(
        ['user-details', userEmail], 
        () => fetchJcUserData(userEmail), 
        {
            onSuccess, 
            onError,
            enabled: !!userEmail 
        }
    );
    
}

export const useAddJcUserData = () => {
    return useMutation(addJcUserData);
}

export const useUpdateJcUserData = (onSuccess, onError) => {
    return useMutation(updateJcUserData, {onSuccess, onError}); 
}