import { Typography } from '@mui/material';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Avatar } from '@mui/material';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Collapse } from '@mui/material';
import { CommentIcon, NoteIcon, LikedIcon } from '../../assets';
import JCSwitch from './JcSwitch';
import CommentCard from './CommentCard';
import CommentModal from './CommentModal';
import { AuthContext } from '../../context/AuthContext';
import { CircularProgress } from '@mui/material';
import user from '../../state/reducers/user';
// import { useSelector } from 'react-redux';
import { url as API_URL } from '../../api';
import { fetchCommentAndNotes } from '../../api';
import { getCommentAndNotes, setComments, setNotes } from '../../state/actions/comment';
import { WifiFind } from '@mui/icons-material';
import { USER_ROLES } from '../../constants/constants'



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#262626',
  padding: theme.spacing(1),
  textAlign: 'initial',
  [theme.breakpoints.down('md')]: {
    width: '100vw',
    borderRadius: 'None',
    padding: 0,
    boxShadow: 'None'
  },

}));

const useStyles = makeStyles({
  textfield: {
    width: '95%',
    '& .MuiInputBase-input': {
      border: '1.8px solid #838383',
      borderRadius: '4px',
      fontFamily: 'Raleway',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '26px',
      padding: '7px',
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
    },
    '& .Mui-disabled': {
      color: '#838383'
    },
  },
  textfieldMobile: {
    width: '100%',
    '& .MuiInputBase-input': {
      fontFamily: 'Raleway',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '12px',
      padding: '6px',
    },
    '& .MuiSelect-select': {
      height: '21px!important',
      paddingTop: '4px',
      paddingBottom: '4px'
    }
  },
  commentIcons: {
    marginRight: '6px',
    height: '24px',
    width: '24px',
  }
});

const API_COMMENTS_URLS = {
  get_comments: 'https://v1api.dev.journal.club/comments/'
}




function CommentNotes() {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;

  const classes = useStyles()
  const theme = useTheme();
  const mobileMatch = useMediaQuery(theme.breakpoints.down('md'))
  const boxWidth = mobileMatch ? "88vw" : "55vw"
  const [open, setOpen] = useState(false)
  const [DATA, setDATA] = useState([])
  const dispatch = useDispatch();
  const { isCommentLoading, notes, comments, newCount } = useSelector((state) => state.comment);
  const { jcUserDetails } = useSelector((state) => state.journalclub);
  const { numberOfComments, numberOfNotes, newCommentAdded, newNoteAdded,isEditable } = useSelector((state) => state.article.articleData);
  const [_t, _sett] = useState(localStorage.getItem('JC-refresh-for-comments'))

  function Useforceupdate() {

    const [vv, setVV] = useState(0)
    return () => setVV(v => ++v)
  }

  const { JCID, PMID } = useParams()
  const isJCPage = JCID ? JCID === 'J00000000' ? false : true : false

  const CommentHandleOpen = () => {
    //setOpen(true)
    setOpen(true,
      () => neutralizeBack(CommentHandleClose)
    );
  };

  const CommentHandleClose = () => {
    //window.history.back()
    setOpen(false)
    localStorage.setItem('JC-refresh-for-comments', JSON.stringify(false))
  }

  const [rootNotes, setRootNotes] = useState([])
  const [rootComments, setRootComments] = useState([])
  const _jcId = window.location.pathname.split('/').length === 3 ? 'J00000000/' : window.location.pathname.split('/')[1]

  const neutralizeBack = (callback) => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      callback();
    };
  };

  const revivalBack = () => {
    window.onpopstate = undefined;
    window.history.back();
  };

  useEffect(() => {
    // NOTE: NOTE: fetching comments
    const jcVerb = JCID ? JCID : 'J00000000'
    //dispatch(getCommentAndNotes(jcVerb,PMID,userEmail))
    fetchCommentAndNotes(jcVerb, PMID, auth.user.email).then(response => {
      if (response.data !== 'No comments found') {
        setDATA(response.data)
        dispatch(setComments(response.data.filter(comment => comment.isNote === false)))
        dispatch(setNotes(response.data.filter(note => note.isNote === true)))
        setRootNotes(response.data.filter(d => d.isNote === true && d.referenceCommentID === null))
        setRootComments(response.data.filter(d => d.isNote === false && d.referenceCommentID === null))
      }
      else {
        setDATA([])
        dispatch(setComments([]))
        dispatch(setNotes([]))
        setRootNotes([])
        setRootComments([])
      }
    }).catch(error => {
      const errorMsg = error.message
    }
    )
  }, [numberOfComments, numberOfNotes, newCount])


  // useEffect(()=>{
  //   console.log(localStorage.getItem('JC-refresh-for-comments'), ' :::---------RE _ RENDERING')
  // })

  // useEffect(()=>{
  //   console.log('_settttttttt RAN')
  //   _sett(localStorage.getItem('JC-refresh-for-comments'))
  // }, [localStorage.getItem('JC-refresh-for-comments')])

  const isAdmin = (userRole) => {
    if (userRole === "Administrator" || userRole === "Course Director") {
      return true
    }
    else {
      return false
    }
  }
  const isContributor = (userRole) => {
    if (userRole === "Administrator" || userRole === "Course Director" || userRole === "Contributor") {
      return true
    }
    else {
      return false
    }
  }

  return (
    <Item sx={{ marginBottom: '16px' }}>
      <Box px={{ xs: 2, sm: 0, md: 3 }} pt={{ xs: isJCPage && isContributor(jcUserDetails.role) ? 2 : 0, sm: 0, md: isJCPage && isContributor(jcUserDetails.role) ? 2 : 0 }}>

        {/*Notes Starts COntributor and admin and course dir.*/}
        {isJCPage && isContributor(jcUserDetails.role) &&
          <Notes open={open} CommentHandleOpen={CommentHandleOpen} onClose={CommentHandleClose} isJCPage={isJCPage} isContributor={isContributor(jcUserDetails.role)} DATA={DATA} rootNotes={rootNotes} numberOfNotes={numberOfNotes} newNoteAdded={newNoteAdded} />
        }
        {/*Notes Ends */}
        {isJCPage && isContributor(jcUserDetails.role) &&
          <Box pb={1}>
            <Divider />
          </Box>
        }

        {/*Comments Starts */}
        <Comments open={open} CommentHandleOpen={CommentHandleOpen} onClose={CommentHandleClose} isJCPage={isJCPage} isContributor={isContributor(jcUserDetails.role)} data={DATA} rootComments={rootComments} numberOfComments={numberOfComments} JCID={JCID} newCommentAdded={newCommentAdded} isEditable={isEditable}/>
        {/*Comments Ends */}
      </Box>
    </Item>
  )
}

export default CommentNotes

const Notes = (props) => {
  const { DATA, rootNotes, numberOfNotes, newNoteAdded } = props
  const classes = useStyles()
  const [expand, setExpand] = useState();
  const [open, setOpen] = useState(false)
  const notesCountRef = useRef()
  const [isNewNotes, setIsNewNotes] = useState(false)

  useEffect(() => {
    let _prevNotesCount = notesCountRef.current
    if (_prevNotesCount !== null) {
      if (_prevNotesCount < rootNotes.length) {
        setIsNewNotes(prevState => true)
      }
      else setIsNewNotes(prevState => false)
    }
    return () => { }
  }, [notesCountRef])

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center'
      }} mt={1} mb={2}>
        <Box sx={{ display: 'inline-block', marginTop: '-2px', position: 'absolute' }}>
          <NoteIcon width='28px' height="27px" />
        </Box>
        <Box sx={{ display: 'inline-block' }} ml={5} mr={1}>
          <Typography variant='H2_BITTER'> NOTES </Typography>
        </Box>
        <Box sx={{ display: 'inline-block' }} mr={3}>
          <Typography variant='H2_BITTER'> ({numberOfNotes})</Typography>
        </Box>
        {newNoteAdded &&
          <Box sx={{
            height: '10px',
            width: '10px',
            backgroundColor: '#EF5B50',
            borderRadius: '50%',
            display: 'inline-block'
          }}>
          </Box>
        }
        {newNoteAdded &&
          <Box sx={{ display: 'inline-block' }} mr={2} ml={1}>
            <Typography variant='BODY_COPY2_RALEWAY' color="#565656"> New Notes Avalaible</Typography>
          </Box>
        }

      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center'
      }} id="Notes" mt={2} mb={2}>

        <Box sx={{ display: 'inline-block' }} mr={2}>
          <Avatar variant="square" alt="" src={""} sx={{ height: '42px', width: '42px', borderRadius: '2px!important' }}></Avatar>
        </Box>
        <Box className={classes.textfield} sx={{ display: 'inline-block' }} onClick={() => setOpen(true)}>
          <Box sx={{ border: '1.8px solid #838383', borderRadius: '4px', cursor: 'pointer' }} p={1}>
            <Typography variant="BODY_ARTICLE_RALEWAY" color="#838383" sx={{ fontWeight: '600!important' }}>
              Add a Private Note
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Note popup */}
      <CommentModal open={open} onClose={() => setOpen(false)} isNote={true} isReply={false} isJCPage={props.isJCPage} isContributor={props.isContributor} />
      {/* Note pop-up end*/}

      <Box style={{ 'display': rootNotes.length > 0 ? 'block' : 'none' }} sx={{ backgroundColor: '#FFF7F7' }} p={2} mt={2}>
        {rootNotes && rootNotes.length > 0 && rootNotes.map(comment =>
          <CommentCard commentData={comment} key={comment.commentID} />
        )}

      </Box>

      {/* Disabling View more notes button 
    <Box sx={{cursor:'pointer'}} mt={1} mb={3} onClick={() => setExpand(!expand)}>
      <Link variant="BODY_COPY1_RALEWAY" color='#EF5B50' style={{'display': rootNotes.length > 1? 'block':'none'}}>
        <Typography variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'700!important'}}>
          View {rootNotes.length === 1?'1 more Note': rootNotes.length + ' more Notes'}
        </Typography>
      </Link>
    </Box>
    */}
    </Box>
  )

}
const Comments = (props) => {
  const classes = useStyles()
  const { rootComments, numberOfComments, isJCPage, JCID, newCommentAdded,isEditable } = props
  const { jcDetails, jcUserDetails } = useSelector((state) => state.journalclub);
  const [expand, setExpand] = useState()
  const [open, setOpen] = useState(false)
  const notesCountRef = useRef()
  const [isNewNotes, setIsNewNotes] = useState(false)
  const [thisJCCommentCount, setJCCommentCount] = useState('0')
  const [rootCommentsFilter, setRootComments] = useState([])
  const [isfilterOn, setFilter] = useState(false)

  const CommentHandleOpen = () => {
    //setOpen(true)
    setOpen(true)
    // neutralizeBack(CommentHandleClose)
  };

  const CommentHandleClose = () => {
    setOpen(false)
    //revivalBack()
  }

  const neutralizeBack = (callback) => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      callback();
    };
  };

  const revivalBack = () => {
    window.onpopstate = undefined;
    window.history.back();
  };

  useEffect(() => {
    setJCCommentCount(rootComments.filter(comment => comment.JCID === jcDetails.JCID).length)
    setRootComments(rootComments)
  }, [rootComments])

  const isCommentAllowed = (userRole) => {
    if (!isJCPage) {
      return true
    }
    else if ([USER_ROLES.COURSE_DIRECTOR, USER_ROLES.ADMINISTRATOR, USER_ROLES.CONTRIBUTOR].includes(userRole)) {
      return true
    }
    else {
      return jcDetails.readersAllowedComments
    }

  }

  const handleToggleClick = () => {
    if (isfilterOn) {
      setRootComments(rootComments)
    }
    else {
      setRootComments(rootComments.filter(comment => comment.JCID === jcDetails.JCID))
    }
    setFilter(!isfilterOn)
  }

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center'
      }} mt={2} mb={2}>
        <Box sx={{ display: 'inline-block', marginTop: '4px', position: 'absolute' }}>
          <CommentIcon width='28px' height="27px" />
        </Box>
        <Box sx={{ display: 'inline-block' }} ml={4.5} mr={1}>
          <Typography variant='H2_BITTER'> COMMENTS </Typography>
        </Box>
        <Box sx={{ display: 'inline-block' }} mr={3}>
          <Typography variant='H2_BITTER'> ({numberOfComments})</Typography>
        </Box>
        {newCommentAdded &&
          <Box
            sx={{
              height: '10px',
              width: '10px',
              backgroundColor: '#EF5B50',
              borderRadius: '50%',
              display: 'inline-block'
            }}>
          </Box>
        }
        {newCommentAdded &&
          <Box sx={{ display: 'inline-block' }} mr={2} ml={1}>
            <Typography variant='BODY_COPY2_RALEWAY' color="#565656"> New Comments Avalaible</Typography>
          </Box>
        }

      </Box>

      {isCommentAllowed(jcUserDetails.role) &&
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          alignItems: 'center'
        }} id="Comments" mt={2} mb={2}>

          <Box sx={{ display: 'inline-block' }} mr={2}>
            <Avatar variant="square" alt="Benjamin Scricia" src={""} sx={{
              height: '42px', width: '42px', borderRadius: '2px!important'
            }}></Avatar>
          </Box>
          <Box className={classes.textfield} sx={{ display: 'inline-block' }} onClick={CommentHandleOpen}>
            <Box sx={{ border: '1.8px solid #838383', borderRadius: '4px', cursor: 'pointer' }} p={1}>
              <Typography variant="BODY_ARTICLE_RALEWAY" color="#838383" sx={{ fontWeight: '600!important' }}>
                Add a Public Comment
              </Typography>
            </Box>
          </Box>
        </Box>
      }

      <Box mt={2}>
        {props.isJCPage && thisJCCommentCount > 0 && !isEditable &&
          <JCSwitch jcCommentsCount={thisJCCommentCount} allCommentsCount={numberOfComments} handleToggleClick={handleToggleClick} />
        }

      </Box>

      <Box style={{ 'display': rootCommentsFilter.length > 0 ? 'block' : 'none' }} sx={{ backgroundColor: '#F3F3F3' }} p={2} mt={2}>
        {/* TODO: display the first comment and pass down the rest to the other comment Card */}
        {rootCommentsFilter && rootCommentsFilter.length > 0 && rootCommentsFilter.map(comment =>
          <CommentCard key={comment.commentID} commentData={comment} />
        )}
      </Box>

      {/*Disabling view more button 
      <Box sx={{cursor:'pointer'}} mt={1} mb={2} onClick={() => setExpand(!expand)}>
        <Link variant="BODY_COPY1_RALEWAY" color='#EF5B50' style={{'display': rootComments.length > 1? 'block':'none'}}>
          <Typography id='ExpandMore' variant="BODY_COPY2_RALEWAY" sx={{fontWeight:'700!important'}}>
            View {props.rootComments !== [] ? props.rootComments.length: '' } more Comments
          </Typography>
        </Link>
      </Box>
      */}

      {/* Comment popup */}
      <CommentModal force={props.force} open={open} onClose={() => setOpen(false)} isNote={false} isReply={false} isJCPage={props.isJCPage} isContributor={props.isContributor} />
      {/* Comment pop-up end*/}
    </Box>
  )

}