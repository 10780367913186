import React, { useState, useEffect } from 'react'
import { Card, Hidden, Box, Button, Avatar, Typography, CircularProgress, Grid, SwipeableDrawer, AppBar, Toolbar, Dialog, DialogTitle, DialogContent, IconButton, Input, Menu, styled } from "@mui/material";
import JCContentTitle from '../JCDetails/components/JCContentTitle';

import { PublicJCIcon, PrivateJCIcon } from '../../assets/SVG';
import { INSTITUTION_STATUS, USER_ROLES } from '../../constants/constants';
import { JC_COLORS } from '../../styles/constants';
import PartnersAffiliates from '../JCDetails/containers/PartnersAffiliates';
import CourseDirectors from '../JCDetails/containers/CourseDirectors';
import ChangeJCRoleButton from '../JCDetails/components/ChangeJCRoleButton';
import AccreditorsAndSupporters from '../JCDetails/containers/AccreditorsAndSupporters';
import JCDetailsFullScreenMobile from '../JCDetails/containers/JCDetailsFullScreenMobile';

import { Spacer, Row, CMEAccreditingClub } from "../../common/components";
import SquareAvatar from '../JCDetails/components/SquareAvatar';
import AvatarName from '../JCDetails/components/AvatarName';
import { pxToRem } from '../../utils';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getJcPublicData } from '../../state/actions/journalclub';
import { fetchJcPublicData } from '../../api';
import { generateAWSCognitoIdentity } from '../../utils/awsCognitoUtils';
import SectionTitle from '../JCDetails/components/SectionTitle';



const MainCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: JC_COLORS.JC_WHITE,
  //minHeight: "500px",
  [theme.breakpoints.up('md')]: {
    //width: "73vw", // 1369 / 1920
    maxHeight: "928px",  // 1203 / 1377 or 1203 to rem 
    padding: "30px 40px 16px 40px",
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 16px 0 16px'
  }
}));


const JCDescription = styled(Typography)(({ theme }) => ({
  //fontFamily: 'Raleway',
  ...theme.typography.H3_RALEWAY,
  fontWeight: 600,
  color: JC_COLORS.JC_ICON,
  textAlign: 'left',
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: "hidden",
  [theme.breakpoints.up('md')]: {
    lineHeight: '1rem',
    //        fontSize: pxToRem(14),
    //        lineHeight: pxToRem(20),
  },
  [theme.breakpoints.down('md')]: {
    lineHeight: '0.8rem',
    //        fontSize: pxToRem(12),
    //        lineHeight: pxToRem(16),
  }

}));

const PublicPrivateStatusText = styled(Typography)(({ theme }) => ({
  ...theme.typography.H3_RALEWAY,
  fontWeight: 600,
  [theme.breakpoints.up('md')]: {
    //fontSize: pxToRem(18),
    //lineHeight: pxToRem(21.13),
    color: JC_COLORS.JC_BLACK,
  },
  [theme.breakpoints.down('md')]: {
    //fontSize: pxToRem(10),
    //lineHeight: pxToRem(11.74),
    color: JC_COLORS.JC_ICON,

  }
}));

const ContributorsReadersText = styled(Typography)(({ theme }) => ({
  ...theme.typography.H3_RALEWAY,
  fontWeight: 600,
  [theme.breakpoints.up('md')]: {
    //fontSize: pxToRem(18),
    //lineHeight: pxToRem(21.13),
    color: JC_COLORS.JC_BLACK,
  },
  [theme.breakpoints.down('md')]: {
    //fontSize: pxToRem(10),
    //lineHeight: pxToRem(11.74),
    color: JC_COLORS.JC_ICON,
  }
}));


const NumberOfContributors = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'flex-start',
  fontFamily: 'Raleway',
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    color: JC_COLORS.JC_BLACK,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(21.13),
    marginTop: '16px',

  },
  [theme.breakpoints.down('md')]: {
    color: JC_COLORS.JC_ICON,
    fontSize: pxToRem(10),
    lineHeight: pxToRem(14),
  }
}));

const GreyBox = styled(Box)(({ theme }) => ({
  backgroundColor: JC_COLORS.LIGHT_GREY,
  [theme.breakpoints.up('md')]: {
    //minHeight: '72px',
    marginBottom: '29px',
    padding: '11px 14px',
    borderRadius: '8px',
    display: 'grid',
    gridTemplateColumns: `repeat(2, 1fr)`,

  },
  [theme.breakpoints.down('md')]: {
    //minHeight: '46px',
    marginBottom: '8px',
    padding: '4px',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

  }
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontWeight: 600,
  color: JC_COLORS.JC_BLACK,
  [theme.breakpoints.up('md')]: {
    fontSize: '15px',
    lineHeight: '17.61px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    lineHeight: '16px',
  }
}));



const GuestViewJCPage = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const urlSlug = location.pathname.replace(/\/jc\//, '');
  const { isFetchingDetails, jcDetailsPublicData } = useSelector((state) => state.journalclub);
  // const { jcDetailsPublicData } = journalclub;

  const [courseDirectorsList, setCourseDirectorsList] = useState([]);
  const [cmeSupportersList, setCmeSupportersList] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [affiliatesList, setAffiliatesList] = useState([]);
  const [partnersAndAffiliates, setPartnersAndAffiliates] = useState([]);
  const [accreditorsAndSupportersList, setAccreditorsAndSupportersList] = useState([]);

  const [contributingMembersList, setContributingMembersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [boxHeight, setBoxHeight] = useState(0)

  const actionsAfterIdentityGeneration = () => {
    if (urlSlug) {
      dispatch(getJcPublicData(urlSlug))
    }
  }

  useEffect(() => {
    generateAWSCognitoIdentity(false)
    actionsAfterIdentityGeneration(urlSlug)
  }, [])

  useEffect(() => {
    generateAWSCognitoIdentity(false).then(res => {
      actionsAfterIdentityGeneration(urlSlug)
    })
  }, [urlSlug])

  useEffect(() => {
    var TopJCInfo = document.querySelector(".guest-jc")
    var rightNavElement = document.querySelector(".guest-sidebar");
    if (rightNavElement && TopJCInfo) {
      const jcOffset = 43
      setBoxHeight(rightNavElement.offsetHeight - TopJCInfo.offsetHeight + jcOffset)
    }
  }, [document.querySelector(".guest-jc")?.offsetHeight, document.querySelector(".guest-sidebar")?.offsetHeight])

  useEffect(() => {
    setCourseDirectorsList(parseCourseDirectors(jcDetailsPublicData?.memberDetails));
    setCmeSupportersList(parseCmeSupporters(jcDetailsPublicData?.memberDetails));
    setContributingMembersList(parseContributingMembers(jcDetailsPublicData?.memberDetails));
    setPartnersList(parsePartners(jcDetailsPublicData?.institutions));
    setAffiliatesList(parseAffiliates(jcDetailsPublicData?.institutions));
    setAccreditorsAndSupportersList(jcDetailsPublicData?.institutions?.filter(obj => (
      obj?.status === INSTITUTION_STATUS.ACCREDITOR ||
      obj?.status === INSTITUTION_STATUS.SUPPORTER
    )) || []);
  }, [jcDetailsPublicData]);

  useEffect(() => {
    let tempList = []
    if (partnersList && partnersList.length > 0) {
      tempList = [...tempList, ...partnersList]
    }
    if (affiliatesList && affiliatesList.length > 0) {
      tempList = [...tempList, ...affiliatesList]
    }
    setPartnersAndAffiliates(tempList)
  }, [partnersList, affiliatesList])

  return isFetchingDetails ? (
    <Box>
      <CircularProgress color='primary' sx={{ position: 'fixed', right: { xs: '45%', md: '40%' }, top: '40%' }} />
    </Box>
  )
    : (
      <>
        <Hidden mdDown>
          <MainCard className="guest-jc">
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <JCContentTitle title={jcDetailsPublicData?.name}></JCContentTitle>
              {jcDetailsPublicData?.isCMEAccrediting && <CMEAccreditingClub />}
            </Box>

            <Row sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', maxWidth: '70%', wordWrap: 'break-word' }}>
              <JCDescription>
                {jcDetailsPublicData?.description}
              </JCDescription>
            </Row>

            <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Row sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                <Row sx={{ alignItems: 'center' }}>
                  {
                    jcDetailsPublicData?.isPublic && (
                      <PublicJCIcon width={'20px'} height={'20px'} />
                    )
                  }
                  {
                    jcDetailsPublicData?.isPublic === false && (
                      <PrivateJCIcon width={'20px'} height={'20px'} />
                    )
                  }
                  <PublicPrivateStatusText sx={{ marginLeft: "7px" }}>
                    {jcDetailsPublicData?.isPublic ? "Public Club" : "Private Club"}
                  </PublicPrivateStatusText>
                </Row>

                <ContributorsReadersText sx={{ ml: "64px" }}>
                  {parseContributorsAndReaders(jcDetailsPublicData)}
                </ContributorsReadersText>

              </Row>

              <ChangeJCRoleButton
                JCID={""}
                userEmail={null}
                currentRole={""}
                membershipStatus={""}
              />

            </Row>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                marginTop: '18px',
                marginBottom: partnersAndAffiliates?.length === 0 ? '48px' : '0px',
              }}>
              <Box sx={{ width: partnersAndAffiliates?.length === 0 ? '100%' : '60%' }}>
                {
                  courseDirectorsList?.length > 0 && (
                    <Box
                      sx={{
                        borderTop: '2px solid #E1E1E1',
                        padding: '19px 0px',
                        //borderRight: partnersAndAffiliates.length === 0 ? 'none' : '2px solid #E1E1E1',
                      }}>
                      <CourseDirectors courseDirectorsList={courseDirectorsList} />
                    </Box>
                  )
                }




              </Box>

              <Box sx={{ width: partnersAndAffiliates?.length === 0 ? '0%' : '40%' }}>
                {
                  (partnersAndAffiliates?.length > 0) && (
                    <Box
                      sx={{
                        borderTop: '2px solid #E1E1E1',
                        paddingTop: '19px',
                        paddingLeft: '45px',
                        borderLeft: '2px solid #E1E1E1'
                      }}>
                      <PartnersAffiliates
                        partnersAffiliatesList={partnersAndAffiliates}
                        hasCmeSupporters={cmeSupportersList !== null && cmeSupportersList?.length > 0}
                      />
                    </Box>

                  )
                }
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: '2px solid #E1E1E1',
                display: "flex",
                flexDirection: "row",
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                padding: '19px 0px 0px 0px',
              }}
            >
              <SectionTitle>
                Accreditors/Supporters
              </SectionTitle>
            </Box>

            {
              accreditorsAndSupportersList?.length > 0 && (
                <Box
                  sx={{
                    //borderTop: '2px solid #E1E1E1',
                    padding: '0px 0px 19px 0px',
                    //borderRight: partnersAndAffiliates.length === 0 ? 'none' : '2px solid #E1E1E1',
                  }}>
                  <AccreditorsAndSupporters
                    accreditorsAndSupportersList={accreditorsAndSupportersList}
                    hideReadDisclosureButton={true}
                  />
                </Box>

              )
            }

          </MainCard>

          <Spacer height={"11px"} />

          <MainCard sx={{ minHeight: boxHeight }}>

            <JCContentTitle title={'Contributing Members'} />
            <NumberOfContributors>{contributingMembersList?.length} Contributors</NumberOfContributors>
            <Box sx={{ marginTop: '16px' }}>
              {
                contributingMembersList?.map((obj, i) => (
                  <GreyBox key={i}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                      <SquareAvatar name={`${obj.firstName} ${obj.lastName}`} logoUrl={obj.logoUrl}></SquareAvatar>
                      <AvatarName>{`${obj.firstName} ${obj.lastName}`}</AvatarName>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                      <StyledText>{obj.profession ?? ""}</StyledText>
                      {obj.institution &&
                        <>
                          <Box sx={{ width: "9px", height: "9px", margin: '0px 13px', backgroundColor: JC_COLORS.JC_BLACK, borderRadius: '50%' }}></Box>
                          <StyledText>{obj.institution ?? ""}</StyledText>
                        </>
                      }
                    </Box>
                  </GreyBox>
                )
                )
              }
            </Box>
          </MainCard>



        </Hidden>
        <Hidden mdUp>
          <JCDetailsFullScreenMobile
            appTitle={""} // No need of app title for guest user because it has a different navbar
            jcDetails={jcDetailsPublicData}
          >
            <Box sx={{ padding: "64px 16px 16px 16px", bgcolor: JC_COLORS.JC_WHITE }}>
              <Row sx={{ justifyContent: 'flex-start', marginBottom: '17px' }}>
                <JCContentTitle title={jcDetailsPublicData?.name} />
              </Row>

              <Row sx={{ justifyContent: 'flex-start', borderBottom: '1px solid #848484', paddingBottom: '12px' }}>

                <Box sx={{ maxWidth: '70%', wordWrap: 'break-word' }}>
                  <JCDescription>
                    {jcDetailsPublicData?.description}
                  </JCDescription>
                </Box>
              </Row>

              <Box sx={{ margin: '18px 0px 11px 0px', width: '65%' }}>
                {jcDetailsPublicData?.isCMEAccrediting && <CMEAccreditingClub />}
              </Box>

              <Row sx={{ justifyContent: 'space-between', margin: '15px 0px' }}>
                <Row sx={{ alignItems: 'center' }}>
                  {
                    jcDetailsPublicData?.isPublic && (

                      <>
                        <PublicJCIcon width={'20px'} height={'20px'} />
                        <PublicPrivateStatusText sx={{ marginLeft: "7px" }}>
                          Public Club
                        </PublicPrivateStatusText>
                      </>
                    )
                  }
                  {
                    jcDetailsPublicData?.isPublic === false && (
                      <>
                        <PrivateJCIcon width={'20px'} height={'20px'} />
                        <PublicPrivateStatusText sx={{ marginLeft: "7px" }}>
                          Private Club
                        </PublicPrivateStatusText>
                      </>

                    )
                  }

                </Row>
                <ContributorsReadersText sx={{ ml: 4 }}>
                  {parseContributorsAndReaders(jcDetailsPublicData)}
                </ContributorsReadersText>
              </Row>

              <Row sx={{ justifyContent: 'flex-start' }}>
                <ChangeJCRoleButton
                  JCID={""}
                  userEmail={null}
                  currentRole={""}
                  membershipStatus={""}
                />
              </Row>



              <Box sx={{ marginTop: '16px' }}>
                {
                  courseDirectorsList?.length > 0 && (
                    <Box sx={{ borderTop: '1px solid #848484', padding: '12px 0px', marginBottom: '12px' }}>
                      <CourseDirectors
                        courseDirectorsList={[...courseDirectorsList]}
                      />
                    </Box>
                  )
                }
                {
                  partnersAndAffiliates?.length > 0 && (
                    <Box sx={{ borderTop: '1px solid #848484', padding: '12px 0px', marginBottom: '12px' }}>
                      <PartnersAffiliates
                        partnersAffiliatesList={partnersAndAffiliates}
                      />
                    </Box>
                  )
                }
                <Box
                  sx={{
                    borderTop: '1px solid #848484',
                    display: "flex",
                    flexDirection: "row",
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    padding: '12px 0px',
                  }}
                >
                  <SectionTitle>
                    Accreditors/Supporters
                  </SectionTitle>
                </Box>

                {
                  accreditorsAndSupportersList?.length > 0 && (
                    <Box sx={{ marginBottom: '12px' }}>
                      <AccreditorsAndSupporters
                        accreditorsAndSupportersList={accreditorsAndSupportersList}
                        hideReadDisclosureButton={true}
                      />
                    </Box>
                  )
                }

                <Box sx={{ padding: '0px' }}>
                  <Box sx={{ borderTop: '1px solid #848484', paddingTop: '19px' }}>
                    <Typography
                      sx={{
                        padding: '4px 10px',
                        backgroundColor: JC_COLORS.JC_RED,
                        color: JC_COLORS.JC_WHITE,
                        textAlign: 'left',
                        fontFamily: 'Raleway',
                        fontSize: '12px',
                        lineHeight: '20px',
                        width: 'fit-content',
                        borderRadius: '4px',
                        textTransform: 'uppercase',
                        marginBottom: '26px',
                      }}
                    >
                      Contributing Members
                    </Typography>

                  </Box>

                  <NumberOfContributors>
                    {contributingMembersList?.length} Contributors
                  </NumberOfContributors>

                  <Box sx={{ marginTop: '9px' }}>
                    {
                      contributingMembersList?.map((obj, i) => (
                        <GreyBox key={i}>
                          <SquareAvatar name={`${obj.firstName} ${obj.lastName}`} logoUrl={obj.logoUrl}></SquareAvatar>
                          <Box sx={{ marginLeft: '12px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                            <Typography sx={{ fontFamily: 'Raleway', fontWeight: 600, fontSize: pxToRem(12), lineHeight: pxToRem(18) }}>{`${obj.firstName} ${obj.lastName}`}</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography sx={{ fontFamily: 'Raleway', fontSize: '12px', lineHeight: '14px' }}>{obj.profession ?? ""}</Typography>
                              <Box sx={{ width: "4px", height: "4px", margin: '0px 8px', backgroundColor: JC_COLORS.JC_BLACK, borderRadius: '50%' }}></Box>
                              <Typography sx={{ fontFamily: 'Raleway', fontSize: '12px', lineHeight: '14px' }}>{obj.institution ?? ""}</Typography>
                            </Box>
                          </Box>
                        </GreyBox>
                      ))
                    }
                  </Box>
                </Box>


              </Box>
            </Box>


          </JCDetailsFullScreenMobile>
        </Hidden>
      </>
    )
}

export default GuestViewJCPage;

function parseContributorsAndReaders(jcDetails) {
  let contributorsText;
  let readersText;
  if (jcDetails.contributors === undefined) {
    contributorsText = ""
  } else {
    contributorsText = jcDetails.contributors === 1 ? `${jcDetails.contributors} Contributor` : `${jcDetails.contributors} Contributors`;
  }

  if (jcDetails.readers === undefined) {
    readersText = ""
  } else {
    readersText = jcDetails.readers === 1 ? `${jcDetails.readers} Reader` : `${jcDetails.readers} Readers`
  }

  return contributorsText === "" ? readersText : `${contributorsText} | ${readersText}`;

}

function parseCourseDirectors(memberDetails) {
  return memberDetails?.filter(obj => obj.role === USER_ROLES.COURSE_DIRECTOR);
}

function parseCmeSupporters(institutions) {
  return institutions?.filter(obj => obj.cmeSupporter);
}

function parsePartners(institutions) {
  return institutions?.filter(obj => obj.status === INSTITUTION_STATUS.PARTNER || obj.status === 'InstitutionStatus.PARTNER');
}

function parseAffiliates(institutions) {
  return institutions?.filter(obj => obj.status === INSTITUTION_STATUS.AFFILIATE || obj.status === 'InstitutionStatus.AFFILIATE')
}

function parseContributingMembers(memberDetails) {
  return memberDetails?.filter(obj => obj.role === USER_ROLES.CONTRIBUTOR);
}
