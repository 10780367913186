import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { SwipeableDrawer, Drawer, Grid,Grow,Fade,Button,Backdrop,IconButton, Box, Typography, BottomNavigation, BottomNavigationAction } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CircleIcon from '@mui/icons-material/Circle';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { styled } from '@mui/system';

import { AllIcon, FeedIcon, Feed, FilledFeed, JCIcon, FilledJCIcon, SaveIcon, SaveIconOutlined, Search, FilledSearch, CMEIcon, AddCircle } from '../../assets/SVG';
import { AddContent, AddContentFilled,CreateArticle} from '../../assets';
import { USER_ROLES, JC_CONTENT } from '../../constants/constants';
import SearchBoxArticles from "./SearchBoxArticles";
import { JC_COLORS } from '../../styles/constants';
import JC_Avatar from './JC_Avatar';
import { pxToRem } from '../../utils';
import { getJournalClubs } from '../../state/actions/journalclub';
import { AuthContext } from '../../context/AuthContext';
import { createNewDenovoArticle } from '../../state/actions/article';


const labels = {
    //0: { value: 'all', text: 'All', icon: <AllIcon /> },
    0: { value: 'saved', text: 'Saved', icon: <SaveIcon /> },
    1: { value: 'feed', text: 'Feed', icon: <FeedIcon /> },
    3: { value: 'jc', text: 'Journal Clubs', icon: <JCIcon /> },
}

const StyledLabel = styled(Typography)(({ theme, selected }) => ({
    fontWeight: 'bold',
    fontSize: '8.5px',
    lineHeight: '11.74px',
    fontFamily: 'Raleway',
    color: `${selected ? JC_COLORS.JC_RED : JC_COLORS.JC_ICON}`,
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'no-wrap',
    marginTop: "4px",
    width: "85px"
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 35,
    height: 4,
    borderRadius: 3,
    backgroundColor: 'black',
    marginTop: '14px',
}));

const SidebarTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "BITTER",
    fontWeight: "bold",
    // overflowWrap: 'break-word',
    [theme.breakpoints.up('md')]: {
        fontSize: "1rem",
        lineHeight: "1.5rem",
        maxWidth: "40%",
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(12),
        lineHeight: pxToRem(14.4),
        display: "-webkit-box",
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: 'align',
    }

}))



const BottomAppBar = () => {
    const authData = useContext(AuthContext);
    const { email: userEmail } = authData.user;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const content = location.pathname.replace(/\//, '');
    
    const [value, setValue] = useState("");
    const [optionsList, setOptionsList] = useState([]);
    const [openSearchDrawer, setOpenSearchDrawer] = useState(false);

    const { journalclub,user } = useSelector((state) => state);
    const [showSpeedDial, setShowSpeedDial] = useState(false);
    const [createContent,setCreateContent] = useState(false);
    const [createContentJCList,setCreateContentJCList] = useState([])
    const [isCreateAllowed,setCreateAllowed] = useState(false)
  
    const handleActionClick = (action) => {
      action();
      setShowSpeedDial(false);
    };

    const handleCloseSpeedDial = () => {
        setShowSpeedDial(false)
        if (content !== 'search') {
            setValue(content)
        }
    }

    const actions = [
        { icon: <Grow in={showSpeedDial} timeout={200}><Button startIcon={<CreateArticle width="20" height="20"/>} className="btn-speed-dial">Original Article</Button></Grow>, name: 'Original Article', action: () => setCreateContent(true)},
        { icon: <Grow in={showSpeedDial} timeout={300}><Button startIcon={<Feed width="20" height="20"/>} className="btn-speed-dial">From Custom Feed</Button></Grow>, name: 'From Custom Feed', action: () => navigate('/feed')},
        { icon: <Grow in={showSpeedDial} timeout={400}><Button startIcon={<SearchIcon width="20" height="20" sx={{color: JC_COLORS.JC_ICON}} />} className="btn-speed-dial">From PubMed</Button></Grow>, name: 'From PubMed', action: () => setOpenSearchDrawer(true)},
      ];

    
    const handleCreateArticleClick = (selectedJC) => {
        const userDetail = user.userDetails
        const finalJC = journalclub.jcList.filter(JC => JC.JCID === selectedJC)[0]
        setCreateContent(false)
        dispatch(createNewDenovoArticle(userEmail,userDetail.firstName,userDetail.lastName,selectedJC,finalJC.urlSlug,navigate))
 
    }
    useEffect(() => {
        if (content !== 'search') {
            setValue(content)
        }
        let reg = new RegExp(['search', 'advanced-search'].join('|'), 'gi');
        // console.log(content, reg.test(content))
        if (content === 'search' || content === 'advanced-search') {

            setOpenSearchDrawer(false)
            setValue("search")
        }
       
        dispatch(getJournalClubs(userEmail));

    }, [content]);

    useEffect(()=>{
        var tempList = []
        const { jcList } = journalclub
        tempList = jcList.filter(
            obj => (
                obj.membershipStatus && obj.membershipStatus === 'Joined' && (
                    obj.currentUser === USER_ROLES.CONTRIBUTOR || 
                    obj.currentUser === USER_ROLES.ADMINISTRATOR || 
                    obj.currentUser === USER_ROLES.COURSE_DIRECTOR
                )
            )
        )
        if(tempList && tempList.length > 0){
            setCreateAllowed(true)
        }
        setCreateContentJCList(tempList)
    },[journalclub,user])

    return (
        <div style={{ width: '100%' }} >
            <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>

                <BottomNavigation
                    showLabels
                    //value={value}
                    onChange={(event, newValue) => {
                        if (labels[newValue].value === 'feed') {
                            setShowSpeedDial(!showSpeedDial)
                        }
                        if (labels[newValue].value === 'saved') {
                            navigate('/saved')
                        }
                        if (labels[newValue].value === 'jc') {

                        }
                        if (labels[newValue].value === 'search') {
                            setOpenSearchDrawer(true)
                        }

                        setValue(labels[newValue].value);
                    }}
                >
                    <BottomNavigationAction
                        label={<StyledLabel selected={value === 'saved'}>Saved</StyledLabel>}
                        icon={value === 'saved' ? <SaveIconOutlined width={"20"} height={"25"} /> : <SaveIcon width={"20"} height={"25"} />}
                        sx={{ borderTop: value === 'saved' ? `2px solid ${JC_COLORS.JC_RED}` : 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
                    />
                    <BottomNavigationAction
                        label={<StyledLabel selected={['feed','search'].includes(value)}> Create Content</StyledLabel>}
                        icon={['feed','search'].includes(value) ? <AddContentFilled width={"25"} height={"30"} /> : <AddContent width={"25"} height={"30"} />}
                        sx={{ borderTop: ['feed','search'].includes(value) ? `2px solid ${JC_COLORS.JC_RED}` : 'none', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}
                    />
                    { showSpeedDial &&
                        <SpeedDial
                        ariaLabel="jc-add-content-speeddial"
                        icon={<SearchIcon />}
                        open={showSpeedDial}
                        onClose={handleCloseSpeedDial}
                        direction="up"
                        sx={{position:'absolute',top:isCreateAllowed ? '-180px' : '-120px','& .MuiSpeedDial-fab':{display:'none'},
                            '& .MuiSpeedDialAction-fab':{boxShadow:'none',backgroundColor:'transparent',color:'transparent'}}}
                        >
                        {actions.map((action,index) => {
                            if(action.name !== "Original Article" || isCreateAllowed){

                            return (
                            <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            onClick={() => handleActionClick(action.action)}
                            >
                            {action.button}
                            </SpeedDialAction>
                            )}
                        })}
                        </SpeedDial>
                    }                   
                    <BottomNavigationAction
                        label={<StyledLabel selected={ value.startsWith('jc') }>Journal Clubs</StyledLabel>}
                        icon={value.startsWith('jc') ? <FilledJCIcon width={"20"} height={"25"} fill={JC_COLORS.JC_RED} />: <JCIcon width={"20"} height={"25"} fill={JC_COLORS.JC_ICON} />}
                        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', }}
                    />
                    <Backdrop open={showSpeedDial} onClick={handleCloseSpeedDial} />
                </BottomNavigation>
                <Drawer
                    anchor={'bottom'}
                    open={openSearchDrawer}
                    onClose={() => setOpenSearchDrawer(false)}
                >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ height: '79px', backgroundColor: JC_COLORS.JC_RED }}
                    >

                        <Grid item xs={3}>
                            <SearchBoxArticles placeholder={"Search"} />
                        </Grid>
                    </Grid>


                </Drawer>
                <SwipeableDrawer
                    anchor={'bottom'}
                    open={createContent}
                    onClose={() => {
                        setCreateContent(false)
                        if (content !== 'search') {
                            setValue(content)
                        }
                    }}
                    PaperProps={{
                        sx: {
                            backgroundColor: JC_COLORS.LIGHT_GREY,
                        }
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: JC_COLORS.LIGHT_GREY,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '314px',
                            borderRadius: '10px 10px 0px 0px',
                            padding: '0 16px'

                        }}
                    >
                        <Box mb={1.5}>
                            <Puller />
                        </Box>
                        <Box mb={1} sx={{alignSelf: 'start'}}>
                         <Typography variant="H3_RALEWAY" sx={{fontWeight:'700'}}>Select your journal club to create original articles:</Typography>
                        </Box>
                        {
                            createContentJCList && createContentJCList.map(obj => (
                                <JCListDisplay obj={obj} value={value} key={obj.JCID} onJCListClick={() => handleCreateArticleClick(obj.JCID)} showInDiscussion={false} showPendingAction={false} />
                            ))
                        }
                       
                    </Box>
                </SwipeableDrawer>
                <SwipeableDrawer
                    anchor={'bottom'}
                    open={value === 'jc'}
                    onClose={() => setValue(content)}
                    PaperProps={{
                        sx: {
                            backgroundColor: JC_COLORS.LIGHT_GREY,
                        }
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: JC_COLORS.LIGHT_GREY,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '314px',
                            borderRadius: '10px 10px 0px 0px',
                            padding: '0 16px'

                        }}
                    >
                        <Puller />
                        <Box
                            sx={{
                                bgcolor: JC_COLORS.JC_RED,
                                color: JC_COLORS.JC_WHITE,
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '100%',
                                borderRadius: '4px',
                                margin: '22px 7px',
                                padding: '11px',
                            }}
                            onClick={() => navigate("/join-a-journal-club")}
                        >
                            <AddCircle width={"20px"} height={'20px'} fill={JC_COLORS.JC_WHITE} />
                            <Typography 
                                sx={{ 
                                    fontFamily: 'Bitter', 
                                    fontWeight: 700, 
                                    fontSize: '12px', 
                                    lineHeight: '14.4px', 
                                    marginLeft: '8.67px', 
                                    textTransform: 'uppercase', 
                                }}>Join a Journal Club</Typography>

                        </Box>

                        {
                            //journalclub.jcList.filter(obj => (obj.currentUser && obj.currentUser.length > 0)).map(obj => (
                            journalclub.jcList && journalclub.jcList.filter(obj => (obj.membershipStatus && (obj.membershipStatus === 'Joined' || obj.membershipStatus === 'Invited'))).map(obj => (
                                <JCListDisplay obj={obj} value={value} key={obj.JCID} onJCListClick={() => navigate(`/jc/${obj.urlSlug}`)} />
                            ))
                        }
                    </Box>
                </SwipeableDrawer>
            </Box>
        </div>
    )
}

export default BottomAppBar


const JCListDisplay = ({obj,value,showInDiscussion = true,showPendingAction = true,onJCListClick }) => {
    return (
        <Box
            sx={{
                bgcolor: JC_COLORS.JC_WHITE,
                width: '100%',
                borderRadius: '4px',
                margin: '0px 22px 8px 22px',
                padding: '11px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }}
            onClick={onJCListClick}
        >
            <Box
                sx={{
                    bgcolor: JC_COLORS.JC_WHITE,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%', 

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <JC_Avatar
                        iconColor={obj.iconColor}
                        title={obj.name}
                        size={17}
                        sx={{
                            fontSize: "12px"
                        }}
                    />

                    <SidebarTitle
                        color={(value === `jc/${obj.urlSlug}` ? JC_COLORS.JC_RED : JC_COLORS.JC_BLACK)}
                        sx={{
                            padding: "0 28px 0 11px",
                            width: "204px",
                            fontSize: '14px', 

                        }}
                    >
                        {obj.name}
                    </SidebarTitle>

                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                    }}
                >
                    {
                        obj.membershipStatus !== 'Invited' && showInDiscussion &&(
                            <Typography
                                sx={{
                                    color: `${JC_COLORS.JC_ICON}`,
                                    fontFamily: 'Raleway',
                                    fontWeight: 700,
                                    fontSize: "1rem",
                                    lineHeight: "1.2rem",
                                    textAlign: 'left',
                                    marginLeft: "7px",
                                    marginRight: "9px"
                                }}
                            >
                                {obj.numberOfPublishedArticles ?? 0}
                            </Typography>
                        )
                    }
                    {
                        obj.membershipStatus === 'Invited' && showInDiscussion && (
                            <CircleIcon
                                sx={{
                                    width: "7px",
                                    height: "7px",
                                    color: JC_COLORS.JC_RED,
                                    alignSelf: 'center',
                                }}
                            />
                        )
                    }
                    {obj.isCmeAccrediting ? <CMEIcon /> : <Box sx={{ width: "35px", height: "18px" }}></Box>}
                </Box>

            </Box>
            {
                (obj.membershipStatus !== 'Invited' && (obj.currentUser === "Administrator" || obj.currentUser === 'Course Director' || obj.currentUser === 'Contributor')) && showInDiscussion && (
                    <Typography
                        sx={{
                            color: `${JC_COLORS.JC_ICON}`,
                            fontFamily: 'Raleway',
                            fontWeight: 700,
                            fontSize: "12px",
                            lineHeight: "18px",
                            padding: '5px 0px 0px 28px',
                            textAlign: 'left',


                        }}>
                        {obj.inDiscussion} in discussion
                    </Typography>
                )
            }
            {
                obj.membershipStatus === 'Invited' && showPendingAction && (
                    <Typography
                        sx={{
                            color: JC_COLORS.JC_WHITE,
                            backgroundColor: JC_COLORS.JC_RED,
                            borderRadius: '2px',
                            fontStyle: 'italic',
                            fontFamily: 'Raleway',
                            fontWeight: 700,
                            fontSize: "0.6rem",
                            lineHeight: "1.1rem",
                            padding: '2px 7px 2px 4px',
                            textAlign: 'left',
                            display: 'inline-block',
                            textTransform: 'none',
                            width: 'fit-content',
                            height: 'fit-content',
                            margin: "4px 0px 0px 28px"
                        }}

                    >
                        Pending Action
                    </Typography>
                )
            }
        </Box>
    )
}