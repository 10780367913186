let moment = require("moment");

export const USER_ROLES = {
  COURSE_DIRECTOR: "Course Director",
  ADMINISTRATOR: "Administrator",
  CONTRIBUTOR: "Contributor",
  MEMBER: "Member",
  READER: "Reader",
  APPUSER: "App User",
};

export const MEMBERSHIP_STATUS = {
  INVITED: "Invited",
  INVITE_REJECTED: "Invite Rejected",
  ADD: "Add",
  JOINED: "Joined",
  REQUESTED: "Requested",
  REQUEST_REJECTED : "Request Rejected",
  REMOVED : "Removed",
  LEFT: "Left",
};

export const NEWSLETTER_PUBLICATION_FREQUENCY = {
  NEVER: { value: "Never", min: 0, max: 0},  
  WEEKLY: { value: "Weekly", min: 0, max: 6}, 
  BIWEEKLY: { value: "Biweekly", min: 0, max: 13}, 
  MONTHLY: { value: "Monthly", min: 0, max: 29},  
  QUARTERLY: { value: "Quarterly", min: 0, max: 89}, 
}

export const JC_TABS = {
  UNDER_DISCUSSION: 'UNDER DISCUSSION', 
  PUBLISHED: 'PUBLISHED', 
  CONTRIBUTORS: 'CONTRIBUTORS', 
  SEND_EMAIL: 'SEND EMAIL', 
  DE_NOVO_ARTICLES: 'ORIGINAL ARTICLES', 
  REPORTS: 'REPORTS', 
  MANAGE: 'MANAGE', 
}

// INSTITUTION_RELATION
export const INSTITUTION_STATUS = {
  PARTNER: "Partner",
  AFFILIATE: "Affiliate",
  ACCREDITOR: "Accreditor", 
  SUPPORTER: "Supporter", 
  EDUCATIONAL: "Educational", 
  NONE: "None",
};

export const USER_STATUS = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  INVITED: "Invited",
};

export const DENOVO_ARTICLE_STATUS = {
  NEW: "new_denovo",
  DRAFT: "Under Discussion",
  PUBLISHED: "Published",
};

export const DENOVO_SEND_EMAILTO_LIST = {
  "All Members": "all",
  "Course Directors": "cd",
  "Course Directors and Administrators": "cda",
  "Contributing Members": "cont",
};

export const DENOVO_AUTO_SAVE_TIME_IN_MINUTES = 3;

export const token_name_list = {
  ACCESS_TOKEN: 'access_token',
  ID_TOKEN: 'id_token',
  REFRESH_TOKEN: 'refresh_token',
  COGNITO_IDENTITY: 'cognito_identity'
};

export const local_storage_keys = {
  LIST_OF_SEARCH_TERMS: "listOfSearchTerms",
};

export const OPTIONS_SORT_ARTICLES_BY_TYPE = {
  "Most Recent": "publicationdate",
  "Highest Practice Impacting Score": "numberOfPracticeImpacting",
  "Most Liked Articles": "numberOfLikes",
  "Most Commented Articles": "numberOfComments",
  "Highest Viewed Articles": "numberOfViews",
};

const dateToday = moment().format("L");
const date1Week = moment().subtract(7, "days").format("L");
const date15Days = moment().subtract(15, "days").format("L");
const date1Month = moment().subtract(1, "month").format("L");
const date3Months = moment().subtract(3, "month").format("L");
const date6Months = moment().subtract(6, "month").format("L");
const date1Year = moment().subtract(1, "year").format("L");

export const OPTIONS_SORT_ARTICLES_BY_TIME = {
  "Past 1 Week": { startDate: date1Week, endDate: dateToday },
  "Past 15 Days": { startDate: date15Days, endDate: dateToday },
  "Past 1 Month": { startDate: date1Month, endDate: dateToday },
  "Past 3 Months": { startDate: date3Months, endDate: dateToday },
  "Past 6 Months": { startDate: date6Months, endDate: dateToday },
  "Past 1 Year": { startDate: date1Year, endDate: dateToday },
  "CUSTOM": {},
  "All Time": { startDate: null, endDate: null },
};

export const FEED_DEFAULT_DATE_RANGE = "Past 1 Month"

export const SPECIAL_AREAS_OF_INTEREST = {
  ALL_MEDICAL_TOPICS: "All medical topics",
  GENERAL_MEDICINE: "General Medicine",
};

export const DEFAULT_JC = "J00000000";
export const TOAST_MESSAGES = {
  JOIN_PRIVATE: "Request to join Journal Club sent to admin",
  JOIN_PUBLIC: "Request to join Journal Club accepted",
  ACCEPT_INVITE: "Journal Club Invite Accepted",
  REJECT_INVITE: "Journal Club Invite Rejected",
  LEAVE_JC: "Left Journal Club.",
};

export const JC_CONTENT = {
  DEFAULT: 'DEFAULT', 
  CONTRIBUTORS: 'CONTRIBUTORS', 
  CONTACT_ADMIN: 'CONTACT ADMIN', 
  REPORTS: 'REPORTS', 
  MANAGE: 'MANAGE',
  EDIT_JC_INFO: 'EDIT_JC_INFO', 
  EDIT_JC_MEMBER : 'EDIT_JC_MEMBER',
  INVITE_MEMBER : 'INVITE_MEMBER',
  JC_MEMBER : 'JC_MEMBER',
  MANAGE_ACCREDITORS_AND_SUPPORTERS: 'MANAGE_ACCREDITORS_AND_SUPPORTERS', 
  MANAGE_MEMBERS: 'MANAGE_MEMBERS',
  UNDER_DISCUSSION: 'UNDER DISCUSSION',  
  PUBLISHED: 'PUBLISHED', 
  SEND_EMAIL: 'SEND EMAIL', 
  DE_NOVO_ARTICLES: 'ORIGINAL ARTICLES', 
}

export const reportList = [
  "Suspicious, spam, fake, rumour or illegitimate",
  "Harassment or hateful speech",
  "Adult Content",
  "Intellectual Property Infringement, Defamation, or  Plagiarised",
];
export const REPORTS_NAV = {
  ROOT: "Reports",
  MEMBERSHIP: "Membership Activity",
  MEMBER: "Member",
  PUBLISH: "Publishing Activity",
  ENGAGEMENT: "Engagement",
  FLAGGED: "Flagged Notes and Comments",
};

export const ACTIVITY_DEFAULTS = {
  ALL : "All Activities",
  FEED : "Feed Activities",
  ALL_JC : "All Journal Clubs Activities",
  ALL_KEY : "All",
  FEED_KEY : "Feed",
  JC_KEY : "JC"
}


export const REPORT_ROWS_PER_PAGE = 10;
export const pdfImgUrl = "https://www.journal.club/app_images/pdf-icon.png";
export const videoImgUrl = "https://www.journal.club/app_images/video-icon.png";

export const TERMS_OF_SERVICE_PDF =
  "https://www.journal.club/Terms_of_Service.pdf";
export const PRIVACY_POLICY_PDF = "https://www.journal.club/Privacy_Policy.pdf";
export const GOOGLE_FORM_FOR_MISSING_ARTICLES =
  "https://docs.google.com/forms/d/e/1FAIpQLSeDxnTb8Ja-UlAQdG9p1NqQHE6KpgjBitRY-6XzJyUVr_iF0Q/viewform?vc=0&c=0&w=1&flr=0";


export const JC_ROWS_PER_PAGE = 10; 

export const TOOLTIP_TEXT_ALL_JC_LIST_TYPE = 'Clubs can be Public or Private. If you request to join a Private club, your request has to be approved for you to join the club';
export const TOOLTIP_TEXT_ALL_JC_LIST_MEMBERS = 'Specifies the total number of members in the club, including Course Directors, Administrators, Contributors, and Readers';
export const TOOLTIP_TEXT_ALL_JC_LIST_APPROVAL_STATUS = 'Un-approved clubs are not allowed to publish articles';
export const TOOLTIP_TEXT_ALL_JC_LIST_CME_STATUS = "Indicates if a club is CME accredited";