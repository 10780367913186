import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import HeightIcon from '@mui/icons-material/Height';
import { UserCell, EnhancedTableHead,ArtcleTitleCell} from './ReportHelpers';
import { useSelector } from "react-redux";
import { REPORT_ROWS_PER_PAGE } from '../../../../constants/constants';

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: 'Members',
  },
  {
    id: 'calories',
    numeric: true,
    disablePadding: false,
    sortable: true,
    label: 'Calories',
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    sortable: true,
    label: 'Fat (g)',
  },
  {
    id: 'carbs',
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: 'Carbs (g)',
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    sortable: false,
    label: 'Protein (g)',
  },
];

// function EnhancedTableHead(props) {
//   const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
//     props;
//   const createSortHandler = (property) => (event) => {
//     onRequestSort(event, property);
//   };

//   return (
//     <TableHead>
//       <TableRow>
//         {headCells.map((headCell) => (
//         <TableCell
//             key={headCell.id}
//             align={headCell.numeric ? 'center' : 'left'}
//             padding={headCell.disablePadding ? 'none' : 'normal'}
//             sortDirection={orderBy === headCell.id ? order : false}
//           >
//           <Tooltip title={headCell.label} arrow >
//             <TableSortLabel
//               active={orderBy === headCell.id}
//               direction={orderBy === headCell.id ? order : 'asc'}
//               onClick={ headCell.sortable ? createSortHandler(headCell.id) : null}
//               hideSortIcon={true}
//               sx={{fontFamily:'Raleway',fontSize:'16px',fontWeight:'600',color:'#838383',
//               "& .MuiTableSortLabel-icon":{
//                 color :"#EF5B50!important",
//               },
//               "& .Mui-active":{
//                 color :"#EF5B50!important",
//               }
//             }}
//             >
//             {headCell.label}
//               {orderBy === headCell.id ? (
//                 <Box component="span" sx={visuallyHidden}>
//                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
//                 </Box>
//               ) : null}
//               {orderBy !== headCell.id  && headCell.sortable ? (
//                 <HeightIcon fontSize="small" sx={{color:"#838383"}}/>
//               ) : null}

//             </TableSortLabel>
//           </Tooltip>
//         </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// }

// EnhancedTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };



function ReportTable({headCells,data}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(1);
  const { jcDetails } = useSelector((state) => state.journalclub);
  // const [dense, setDense] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(1);
  };

  const handleClick = (event) => {
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const resetPageNumber = (val,callback) => {
    setPage(1)
    callback(val)
    }
  const emptyRows = 0

  return (
    <Box sx={{ width: '100%' }}>
        <TableContainer>
          <Table
            sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                  fontFamily:'Raleway',
                  fontWeight:'600',
                  fontSize:'16px',
                  borderRadius:'4px'
                },
                minWidth: 750
              }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            
            <TableBody>
              {data.member_articles.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <React.Fragment>
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event)}
                      tabIndex={-1}
                      key={row.name}
                      sx={{
                        "&:hover": {
                        backgroundColor: "#FFF7F6!important"
                        },
                        backgroundColor: "#F3F3F3!important"
                    }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{fontFamily:'Raleway!important'}}
                      >
                      <ArtcleTitleCell title={row.title} url={jcDetails.url} PMID={row.PMID}/>
                      </TableCell>
                      <TableCell align="center"> {row.cmeCredits}</TableCell>
                      <TableCell align="center">{row.proposedStartedOn}</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">{row.numberOfComments}</TableCell>
                      <TableCell align="center">{row.numberOfNotes}</TableCell>
                    </TableRow>
                    <Box sx={{minHeight:'12px'}}></Box>
                    </React.Fragment>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={3} sx={{marginLeft:'calc( 50% - 80px)'}}>
            <Pagination count={Math.ceil(data.numberOfRow/REPORT_ROWS_PER_PAGE)} color="primary" page={page} onChange={handleChangePage} />
            {/* <Pagination count={10} color="primary" page={page + 1} onChange={handleChangePage} /> */}
        </Box>
    </Box>
  );
}

export default ReportTable
