import * as api from "../../api";
import { FEED_DEFAULT_DATE_RANGE, OPTIONS_SORT_ARTICLES_BY_TIME } from "../../constants/constants";
import { updateFeedQueryParameters } from "./feed";
import { setKeywords, editKeywords } from "./keywords";
import { setSources } from "./sources";

export const FETCHING_USER_DETAILS = "FETCHING_USER_DETAILS";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";

export const UPDATING_USER_DETAILS = "UPDATING_USER_DETAILS";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";

export const SET_IS_NEW_USER = "SET_IS_NEW_YEAR";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const SET_VERIFICATION_EMAIL = "SET_VERIFICATION_EMAIL"


//export const SET_USER_AUTH_DATA = "SET_USER_AUTH_DATA"; 

export const fetchingUserDetails = () => {
    return {
        type: FETCHING_USER_DETAILS
    }
}

export const fetchUserDetailsSuccess = (data) => {
    return {
        type: FETCH_USER_DETAILS_SUCCESS,
        payload: data
    }
}

export const fetchUserDetailsFailure = (data) => {
    return {
        type: FETCH_USER_DETAILS_FAILURE,
        payload: data
    }
}

export const setIsNewUser = (data) => {
    return {
        type: SET_IS_NEW_USER,
        payload: data
    }
}
export const setVerificationEmail = (email) => {
    return {
        type: SET_VERIFICATION_EMAIL,
        payload: email
    }
}


export const createUser = (data) => async (dispatch) => {
    try {
        console.log("sending post request");
        let res = await api.createNewUser(data);
        //console.log(res)
        if (res.data !== "User created") {
            dispatch(updateUserDetailsFailure(res.data));
        }
        if (res.data === "User created") {
            dispatch(setIsNewUser(false));
        }


    } catch (error) {
        //console.log(error.toJSON()); 
        dispatch(updateUserDetailsFailure(error))
    }
}

export const setUserDetails = (userData, appState) => async (dispatch) => {
    try {
        const jcid = localStorage.getItem('jcid');
        if (appState && (Object.entries(appState).length > 0 && userData.id === jcid)) {
            localStorage.setItem("jcid", jcid);
            console.log("Relogin", jcid, appState.keywords, appState.sources, appState.feedQueryParams)

            const prevKeywords = appState.keywords.items.length > 0 ?
                appState.keywords.items.reduce((acc, keywordObj) => {
                    acc[keywordObj.title] = keywordObj;
                    return acc;
                }) : {};
            const prevSources = appState.sources.items.length > 0 ? appState.sources.items.reduce((acc, sourceObj) => {
                acc[sourceObj.title] = sourceObj;
                return acc;
            }) : {};
            const keywords1 = userData.keywords.map(keyword => prevKeywords[keyword] ?? ({ id: keyword, title: keyword, isSelected: false, type: 'keyword' }));
            const sources1 = userData.sources.map(source => prevSources[source] ?? ({ id: source, title: source, isSelected: false, type: 'source' }));
            dispatch(setKeywords(keywords1));
            dispatch(setSources(sources1));
            dispatch(updateFeedQueryParameters(appState.feedQueryParams));
        }
        else {
            //console.log("different user", data.id);
            localStorage.setItem("jcid", userData.id);
            let { keywords, sources } = userData;
            keywords = [...new Set(keywords)];
            sources = [...new Set(sources)];
            const keywords1 = keywords.map(keyword => ({ id: keyword, title: keyword, isSelected: false, type: 'keyword' }));
            const sources1 = sources.map(source => ({ id: source, title: source, isSelected: false, type: 'source' }));
            dispatch(setKeywords(keywords1));
            dispatch(setSources(sources1));
            dispatch(updateFeedQueryParameters({
                pageNumber: 1,
                unreadOnly: false,
                dateRange: OPTIONS_SORT_ARTICLES_BY_TIME[FEED_DEFAULT_DATE_RANGE],
                startDate: "",
                endDate: "",
                dateRangeLabel: FEED_DEFAULT_DATE_RANGE,
                orderBy: 'publicationdate',
                orderByLabel: "Most Recent",
                keyword: "",
                source: "",
            }));
        }
        dispatch(fetchUserDetailsSuccess(userData));
    } catch(error) {
        console.log(error)
        dispatch(fetchUserDetailsFailure(error.response));
    }
    
}


export const getUserDetails = (userEmail,reload = true) => async (dispatch) => {
    try {
        if(reload){
            dispatch(fetchingUserDetails());
        }
        const { data } = await api.fetchUserDetails(userEmail);
        const appState = JSON.parse(localStorage.getItem('appState'));
        const jcid = localStorage.getItem('jcid');
        //console.log(jcid); 
        if (appState && (Object.entries(appState).length > 0 && data.id === jcid)) {
            localStorage.setItem("jcid", jcid);
            console.log("Relogin", jcid, appState.keywords, appState.sources, appState.feedQueryParams)

            const prevKeywords = appState.keywords.items.length > 0 ?
                appState.keywords.items.reduce((acc, keywordObj) => {
                    acc[keywordObj.title] = keywordObj;
                    return acc;
                }) : {};
            const prevSources = appState.sources.items.length > 0 ? appState.sources.items.reduce((acc, sourceObj) => {
                acc[sourceObj.title] = sourceObj;
                return acc;
            }) : {};
            const keywords1 = data.keywords.map(keyword => prevKeywords[keyword] ?? ({ id: keyword, title: keyword, isSelected: false, type: 'keyword' }));
            const sources1 = data.sources.map(source => prevSources[source] ?? ({ id: source, title: source, isSelected: false, type: 'source' }));
            dispatch(setKeywords(keywords1));
            dispatch(setSources(sources1));
            dispatch(updateFeedQueryParameters(appState.feedQueryParams));
        }
        else {
            //console.log("different user", data.id);
            localStorage.setItem("jcid", data.id);
            let { keywords, sources } = data;
            keywords = [...new Set(keywords)];
            sources = [...new Set(sources)];
            const keywords1 = keywords.map(keyword => ({ id: keyword, title: keyword, isSelected: false, type: 'keyword' }));
            const sources1 = sources.map(source => ({ id: source, title: source, isSelected: false, type: 'source' }));
            //console.log(keywords1);
            dispatch(setKeywords(keywords1));
            dispatch(setSources(sources1));
            dispatch(updateFeedQueryParameters({
                pageNumber: 1,
                unreadOnly: false,
                dateRange: OPTIONS_SORT_ARTICLES_BY_TIME[FEED_DEFAULT_DATE_RANGE],
                startDate: "",
                endDate: "",
                dateRangeLabel: FEED_DEFAULT_DATE_RANGE,
                orderBy: 'publicationdate',
                orderByLabel: "Most Recent",
                keyword: "",
                source: "",
            }));
        }

        dispatch(fetchUserDetailsSuccess(data));
    } catch (error) {

        console.log(error)
        if (error?.response && error?.response?.status === 404) {
            //console.log("is new user");
            dispatch(setIsNewUser(true));
            dispatch(fetchUserDetailsSuccess({}));
        }
        if (error?.response && error?.response?.status === 401) {
            dispatch(fetchUserDetailsFailure(error.response));
        }

    }
}

export const updatingUserDetails = () => {
    return {
        type: UPDATING_USER_DETAILS
    }
}

export const updateUserDetailsSuccess = (data) => {
    return {
        type: UPDATE_USER_DETAILS_SUCCESS,
        payload: data
    }
}

export const updateUserDetailsFailure = (data) => {
    return {
        type: UPDATE_USER_DETAILS_SUCCESS,
        payload: data
    }
}

export const updateUserDetails = (data, userEmail) => async (dispatch) => {
    try {
        dispatch(updatingUserDetails());
        let res = await api.updateUserDetails(data, userEmail);
        //console.log(res)
        if (res.data !== "User updated") {
            dispatch(updateUserDetailsFailure(res.data));
        }
        dispatch(updateUserDetailsSuccess(data));
        dispatch(editKeywords(false));
    } catch (error) {
        console.log(error)
        if (error?.response && error?.response?.status === 401) {
            dispatch(updateUserDetailsFailure(error.response));
        }
    }
}