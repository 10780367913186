import React from 'react';
import { Box } from '@mui/material';
import { JC_COLORS } from '../../styles/constants';

const SidebarLink = ({ selected, text, onClick, children, sx }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: onClick ? 'pointer' : 'default',
                padding: "0 0 0 32px",
                margin: "0px",
                height: "48px",
                borderBottom: `1px solid ${JC_COLORS.LIGHT_GREY}`,
                borderLeft: `10px solid ${selected ? JC_COLORS.JC_RED : JC_COLORS.JC_WHITE}`,
                backgroundColor: `${text === 'All' ? '#dddddd' : JC_COLORS.JC_WHITE}`,
                opacity: `${text === 'All' ? 0.5 : 1}`, 
                ...sx
                
            }}
            onClick={onClick}
        >
            {children}

        </Box>
    )
}

export default SidebarLink