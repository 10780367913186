import './App.css';

import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { ErrorBoundary } from 'react-error-boundary';
import AWS from "aws-sdk";
import ReactGA from 'react-ga4';

import { createTheme, ThemeProvider, CircularProgress, Typography } from '@mui/material';

import { Dashboard, Feed, Home } from "./pages";
import SavedContent from "./pages/Feed/containers/SavedContent";
import { SigninCallback } from "./pages";
import { Sidebar, BottomAppBar, CenteredActionButton } from "./common/components";
import { NavbarDesktop, NavbarMobile } from "./common/containers";
import { JC_COLORS, JC_DESKTOP_FONT, JC_MOBILE_FONT, FONT_FAMILY_BITTER, FONT_FAMILY_RALEWAY } from "./styles/constants";
import { ArticleDetailed, CompleteProfile } from './pages';
import Error404Component from './common/components/Error404';
import ErrorFallback from './common/components/ErrorFallback';
import LandingPage from './pages/LandingPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import JCHeader from './common/components/JCHeader';
import { CenteredContainer } from './common/components';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import TermsOfServicePage from './pages/TermsOfServicePage';
import AuthorisedPrivateRoute from './utils/authorisedPrivateRoute';
import { AuthContext } from './context/AuthContext';

import { Hidden, Box, Grid } from '@mui/material';
import AuthorisedRoute from './utils/AuthorisedRoute';
import user from './state/reducers/user';
import EditKeywordsOrSources from "./pages/EditKeywordsOrSources/EditKeywordsOrSources.jsx";
import { logout } from './utils';
import { JCDetails } from './pages/JCDetails/JCDetails';
import SearchArticlesPage from './pages/SearchArticles/SearchArticlesPage';
import JCPage from './pages/JCDetails/JCPage';
import BecomeMember from './pages/BecomeMember';
import { useErrorHandler } from "react-error-boundary";
import ProfileCompletedRoute from './utils/ProfileCompletedRoute';
import { UserDataContext } from './context/UserDataContext';
import ArticleDenovoView from './common/components/ArticleDenovoView';
import ArticleDenovoNew from './pages/ArticleDenovoNew';
import { DENOVO_ARTICLE_STATUS } from './constants/constants';
import JCDetailsSingleTrayMobile from './pages/JCDetails/containers/JCDetailsSingleTrayMobile';
import GuestNavbarMobile from './pages/GuestPages/GuestNavbarMobile';
import JCAppHelmet from './seo/JCAppHelmet';
import moment from 'moment';
import { generateAWSCognitoIdentity } from './utils/awsCognitoUtils';
import { getNewToken } from './utils/connection';
import Sitemap from './pages/XMLDataLoaders/Sitemap';
import { fetchSitemap } from './api';
import EditJCInfo from './pages/JCDetails/jc-content/manage-content/EditJCInfo';
import CardJCContent from './common/components/CardJCContent';
import ResendEmail from './pages/ResendEmail';
import EmailSent from './pages/EmailSent';
import UserActivityPage from './pages/UserActivity/UserActivityPage';
import PlatformUsersPage from './pages/SuperAdmin/PlatformUser'
import ManageJournalClubsPage from './pages/SuperAdmin/PlatformJC/ManageJournalClubsPage';

const LazyFeed = React.lazy(() => import("./pages/Feed/Feed"));

const theme = createTheme({
  palette: {
    primary: {
      main: JC_COLORS.JC_RED
    },
    secondary: {
      main: JC_COLORS.JC_WHITE
    }
  },
  typography: {
    fontWeightSemiBold: 600,
    //General Fonts
    H1_BITTER: {
      ...FONT_FAMILY_BITTER,
      ...JC_DESKTOP_FONT.BITTER.H1
    },
    H2_BITTER: {
      ...FONT_FAMILY_BITTER,
      ...JC_DESKTOP_FONT.BITTER.H2
    },
    H3_BITTER: {
      ...FONT_FAMILY_BITTER,
      ...JC_DESKTOP_FONT.BITTER.H3
    },
    H1_RALEWAY: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.H1
    },
    H2_RALEWAY: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.H2
    },
    H3_RALEWAY: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.H3
    },
    BODY_COPY1_RALEWAY: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.BODY_COPY1
    },
    BODY_COPY2_RALEWAY: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.BODY_COPY2
    },
    BODY_ARTICLE_RALEWAY: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.BODY_COPY2
    },
    BUTTON_MEDIUM_RALEWAY: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.BUTTON_MEDIUM
    },
    BUTTON_SMALL_RALEWAY: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.BUTTON_SMALL
    },
    // DESKTOP FONTS
    H1_BITTER_DESKTOP: {
      ...FONT_FAMILY_BITTER,
      ...JC_DESKTOP_FONT.BITTER.H1
    },
    H2_BITTER_DESKTOP: {
      ...FONT_FAMILY_BITTER,
      ...JC_DESKTOP_FONT.BITTER.H2
    },
    H3_BITTER_DESKTOP: {
      ...FONT_FAMILY_BITTER,
      ...JC_DESKTOP_FONT.BITTER.H3
    },
    H1_RALEWAY_DESKTOP: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.H1
    },
    H2_RALEWAY_DESKTOP: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.H2
    },
    H3_RALEWAY_DESKTOP: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.H3
    },
    BODY_COPY1_RALEWAY_DESKTOP: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.BODY_COPY1
    },
    BODY_COPY2_RALEWAY_DESKTOP: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.BODY_COPY2
    },
    BUTTON_MEDIUM_RALEWAY_DESKTOP: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.BUTTON_MEDIUM
    },
    BUTTON_SMALL_RALEWAY_DESKTOP: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_DESKTOP_FONT.RALEWAY.BUTTON_SMALL
    },
    //MOBILE FONTS
    H1_BITTER_MOBILE: {
      ...FONT_FAMILY_BITTER,
      ...JC_MOBILE_FONT.BITTER.H1
    },
    H2_BITTER_MOBILE: {
      ...FONT_FAMILY_BITTER,
      ...JC_MOBILE_FONT.BITTER.H2
    },
    H3_BITTER_MOBILE: {
      ...FONT_FAMILY_BITTER,
      ...JC_MOBILE_FONT.BITTER.H3
    },
    BODY_COPY2_BITTER_MOBILE: {
      ...FONT_FAMILY_BITTER,
      ...JC_MOBILE_FONT.BITTER.BODY_COPY2
    },
    BODY_COPY1_RALEWAY_MOBILE: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_MOBILE_FONT.RALEWAY.BODY_COPY1
    },
    BODY_COPY2_RALEWAY_MOBILE: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_MOBILE_FONT.RALEWAY.BODY_COPY2
    },
    BODY_COPY3_RALEWAY_MOBILE: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_MOBILE_FONT.RALEWAY.BODY_COPY3
    },
    BUTTON_SMALL_RALEWAY_MOBILE: {
      ...FONT_FAMILY_RALEWAY,
      ...JC_MOBILE_FONT.RALEWAY.BUTTON_SMALL
    },
  }
});


theme.typography.H1_BITTER = {
  ...FONT_FAMILY_BITTER,
  fontSize: '22px',
  lineHeight: '28px',
  fontWeight: '600',
  [theme.breakpoints.up('sm')]: {
    fontSize: '24px',
    lineHeight: '30px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '30px',
    lineHeight: '40px',
    fontWeight: '500'
  },
};
theme.typography.H1_RALEWAY = {
  ...FONT_FAMILY_RALEWAY,
  fontSize: '18px',
  lineHeight: '24px',
  fontWeight: '600',
  [theme.breakpoints.up('sm')]: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '30px',
    lineHeight: '50px',
    fontWeight: '500'
  },
};

theme.typography.H2_BITTER = {
  ...FONT_FAMILY_BITTER,
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: '700',
  [theme.breakpoints.up('sm')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: '600'
  },
};

theme.typography.H2_RALEWAY = {
  ...FONT_FAMILY_RALEWAY,
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: '700',
  [theme.breakpoints.up('sm')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: '600'
  },
};

theme.typography.H3_RALEWAY = {
  ...FONT_FAMILY_RALEWAY,
  fontSize: '12px',
  lineHeight: '10px',
  fontWeight: '600',
  [theme.breakpoints.up('sm')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '700'
  },
};

theme.typography.BODY_COPY1_RALEWAY = {
  ...FONT_FAMILY_RALEWAY,
  fontSize: '12px',
  fontWeight: '700',
  [theme.breakpoints.up('sm')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
    fontWeight: '600'
  },
};

theme.typography.BODY_COPY2_RALEWAY = {
  ...FONT_FAMILY_RALEWAY,
  fontSize: '12px',
  fontWeight: '500',
  [theme.breakpoints.up('sm')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
    fontWeight: '600'
  },
};

theme.typography.BODY_COPY2_BITTER = {
  ...FONT_FAMILY_BITTER,
  fontSize: '12px',
  fontWeight: '500',
  [theme.breakpoints.up('sm')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    fontWeight: '600'
  },
};

theme.typography.BODY_ARTICLE_RALEWAY = {
  ...FONT_FAMILY_RALEWAY,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: '500',
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500'
  },
};


ReactGA.initialize([{ trackingId: `${process.env.REACT_APP_GA_TRACKING_ID}`}]);


function App() {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const { isAuthenticated } = auth;

  const { user, feed, article, journalclub } = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  const errorHandler = (error, errorInfo) => {
    console.log("error.....", error);
    console.log("errorInfo.....", errorInfo);
  }

  useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: `${window.location.pathname + window.location.search}`});
  }, []);




  return (
    <ThemeProvider theme={theme}>
      <JCAppHelmet />

      <Router>
        <div className="app-container">

          <ErrorBoundary
            //FallbackComponent={ErrorFallback}
            fallbackRender={
              ({ error, resetErrorBoundary, componentStack }) => (
                <ErrorFallback
                  errorMessage={error}
                  resetErrorBoundary={resetErrorBoundary}
                  componentStack={componentStack}
                />
              )}
            onError={errorHandler}
          >
            <React.Suspense
              fallback={<CenteredContainer><CircularProgress /></CenteredContainer>}
            >
              <Routes>
                <Route exact path="/signin-callback" element={<SigninCallback />} />
                <Route
                  path="/"
                  element={
                    <>
                      <AuthorisedRoute>
                        <Home />
                      </AuthorisedRoute>
                    </>
                  }
                />
                <Route path="/resend-email" element={<ResendEmail />} />
                <Route path="/email-sent" element={<EmailSent />} />
                <Route
                  exact
                  path="/terms-and-conditions"
                  element={
                    <>
                      <AuthorisedRoute>
                        <TermsAndConditionsPage />
                      </AuthorisedRoute>
                    </>

                  }
                />
                <Route
                  exact
                  path="/activity"
                  element={
                    <AuthorisedRoute>
                      <UserActivityPage />
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/user-activity"
                  element={
                    <AuthorisedRoute>
                      <PlatformUsersPage />
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/manage-journal-clubs"
                  element={
                    <AuthorisedRoute>
                      <ManageJournalClubsPage />
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/profile"
                  element={
                    <>
                      <AuthorisedRoute><CompleteProfile /></AuthorisedRoute>
                    </>
                  }

                />
                <Route
                  exact
                  path="/feed"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <LazyFeed />
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/saved"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <SavedContent />
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/edit-keywords-or-sources"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <Box sx={{ maxWidth: "75vw" }}>
                          <EditKeywordsOrSources />
                        </Box>
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/search"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <SearchArticlesPage />
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/advanced-search"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <SearchArticlesPage />
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/jc/:urlSlug"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <JCPage />
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/join-a-journal-club"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <BecomeMember />
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/create-a-journal-club"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <CardJCContent>
                          <EditJCInfo create={true} />
                        </CardJCContent>
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  }
                />
                <Route
                  exact
                  path="/article/:PMID"
                  element={
                    <AuthorisedRoute>
                      {article.error ? (
                        <Error404Component />
                      ) :
                        (
                          <Box>
                            <Hidden mdDown>
                              <NavbarDesktop />
                              <Box
                                sx={{
                                  margin: '88px auto auto auto',
                                  maxWidth: '1440px'
                                }}>
                                <Grid
                                  container
                                  direction="row"
                                  sx={{
                                    justifyContent: "unset",
                                  }}>

                                  <Grid item md={3} ml={2}>
                                    <Sidebar />
                                  </Grid>
                                  <Grid item md={8.6} sx={{ marginLeft: '11px' }}>
                                    <ArticleDetailed />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Hidden>

                            <Hidden mdUp>
                              <Box mt={{ sm: 2, xs: 0 }}>
                                {
                                  (
                                    (userEmail === null || userEmail === undefined) ||
                                    (isAuthenticated === false)
                                  ) && (
                                    <GuestNavbarMobile />
                                  )}
                                <ArticleDetailed />
                              </Box>

                            </Hidden>
                          </Box>
                        )}
                    </AuthorisedRoute>
                  }
                />

                <Route
                  exact
                  path="/jc/:JCID/:PMID"
                  element={
                    <AuthorisedRoute>
                      {article.error ? (
                        <Error404Component />
                      ) :
                        (

                          <Box>
                            <Hidden mdDown>
                              <NavbarDesktop />
                              <Box
                                sx={{
                                  margin: '88px auto auto auto',
                                  maxWidth: '1440px'
                                }}>
                                <Grid
                                  container
                                  direction="row"
                                  sx={{
                                    justifyContent: "unset",
                                  }}>

                                  <Grid item md={3} ml={2}>
                                    <Sidebar />
                                  </Grid>
                                  <Grid item md={8.6} sx={{ marginLeft: '11px' }}>
                                    <ArticleDetailed />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Hidden>

                            <Hidden mdUp>
                              <Box mt={{ sm: 2, xs: 0 }}>
                                {
                                  (
                                    (userEmail === null || userEmail === undefined) ||
                                    (isAuthenticated === false)
                                  ) && (
                                    <GuestNavbarMobile />
                                  )
                                }
                                {
                                  userEmail && <JCDetailsSingleTrayMobile />
                                }

                                <ArticleDetailed />
                              </Box>

                            </Hidden>
                          </Box>
                        )}
                    </AuthorisedRoute>
                  }
                />

                <Route exact path="/privacy-policy"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <PrivacyPolicyPage />
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  } />
                <Route exact path="/terms-of-service"
                  element={
                    <AuthorisedRoute>
                      <NavbarWrapper>
                        <TermsOfServicePage />
                      </NavbarWrapper>
                    </AuthorisedRoute>
                  } />
                <Route path="*" element={<Error404Component />} />
                <Route
                  path="/inactive-user"
                  element={
                    <ErrorFallback
                      errorMessage={"User is inactive"}
                    />
                  }
                />

              </Routes>
            </React.Suspense>

          </ErrorBoundary>


        </div>
      </Router>


    </ThemeProvider >

  );
}

export default App;

const NavbarWrapper = ({ children }) => {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;

  return (
    <>
      <Hidden mdDown>
        <NavbarDesktop />
        <Box
          sx={{
            margin: '88px auto auto auto',
            maxWidth: '1440px'
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
          >
            <Grid item md={3}>
              <Sidebar />
            </Grid>
            <Grid item md={8.6} sx={{ marginLeft: '11px' }}>
              {children}
            </Grid>
          </Grid>

        </Box>
      </Hidden>

      <Hidden mdUp>
        <NavbarMobile />
        <Box>
          {children}
        </Box>
        {userEmail && <BottomAppBar />}
      </Hidden>

    </>
  )
}


// const ErrorFallbackComponent = () => {
//   return (
//     <>
//       <JCHeader />
//       <CenteredContainer>
//         <Typography variant="h3">Something went wrong </Typography>
//         <CenteredActionButton onClick={() => logout()}>Go Back</CenteredActionButton>
//       </CenteredContainer>

//     </>
//   )
// }