import React, { useState } from 'react';
import {
    Box,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    useTheme,
    IconButton
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { JC_COLORS } from '../../../../styles/constants';
import CloseIcon from '@mui/icons-material/Close';

import ErrorDialogBox from '../../../../common/components/ErrorDialogBox';

const DialogContentUploadCMEDisclosure = ({ urlSlug, closeIconHandler, uploadButtonHandler }) => {
    const theme = useTheme();

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("Error");

    const [pdfFile, setPdfFile] = useState(null); 
    const [pdfFilePreview, setPdfFilePreview] = useState(""); 
    const [dragActive, setDragActive] = useState(false);
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let preview = URL.createObjectURL(e.dataTransfer.files[0]); 
            setPdfFilePreview(preview); 
            if(e.dataTransfer.files[0].name.includes(".pdf")){
                let fileObject = e.dataTransfer.files[0];
                //console.log(fileObject, 'This is file uploaded');
                setPdfFile(fileObject);
            }
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        console.log(e.target.files); 
        e.preventDefault();
        let preview = URL.createObjectURL(e.target.files[0]); 
        setPdfFilePreview(preview); 
        if (e.target.files && e.target.files[0]) {
            let fileObject = e.target.files[0]
            //console.log(fileObject, 'This is file uploaded');
            setPdfFile(fileObject);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handleUpload = async () => {
        uploadButtonHandler(pdfFile); 
    }

    return (
        <>
            <ErrorDialogBox
                open={openErrorDialog}
                closeDialogHandler={() => setOpenErrorDialog(false)}
                errorMessage={errorMessage}
                title={`Error ${errorStatusCode}`}
            >
                <Typography>{errorMessage}</Typography>
            </ErrorDialogBox>
            <DialogTitle
                sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                    backgroundColor: JC_COLORS.JC_RED,
                    color: JC_COLORS.JC_WHITE,
                    ...theme.typography.H3_BITTER,
                    fontWeight: 700,

                }}
            >
                Upload Disclosure PDF
                <CloseIcon
                    sx={{
                        width: '24px',
                        height: '24px',
                        color: JC_COLORS.JC_WHITE,
                    }}
                    onClick={closeIconHandler}
                />
            </DialogTitle>
            <DialogContent>
                {
                    (pdfFilePreview?.length > 0 && pdfFile?.type === "application/pdf") && (
                        <Box
                            sx={{
                                padding: "10px 20px 0px 20px",
                                height: 250,
                            }}
                        >
                            <iframe
                                src={`${pdfFilePreview}#toolbar=0`}
                                width={"100%"}
                                height={"100%"}
                            />

                        </Box>

                    )
                }

               
                {
                    (pdfFile === null || pdfFile?.type !== "application/pdf") && (
                        <Box
                            sx={{
                                padding: "25px 40px 0px 40px",
                            }}
                        >
                            <Typography sx={{ fontWeight: 700 }}>Upload .pdf file</Typography>
                            {
                                (
                                    pdfFilePreview?.length > 0 && 
                                    (pdfFile !== null) && 
                                    (pdfFile?.type !== "application/pdf")
                                ) && (
                                    <Typography variant="BODY_COPY2_RALEWAY" sx={{ color: 'red' }} >
                                        Only .pdf files are accepted. Please upload .pdf file only
                                    </Typography>

                                )
                            }
                            <Box                              
                                sx={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                }}
                            >
                                    <form
                                        id="form-file-upload"
                                        onDragEnter={handleDrag}
                                        onSubmit={(e) => e.preventDefault()}
                                    >
                                        <input
                                            ref={inputRef}
                                            type="file"
                                            id="input-file-upload"
                                            multiple={false}
                                            onChange={handleChange}
                                            accept=".pdf"
                                        />
                                        <label
                                            id="label-file-upload"
                                            htmlFor="input-file-upload"
                                            className={dragActive ? 'drag-active' : ''}
                                        >
                                        <div>
                                            <CloudUploadIcon
                                                sx={{
                                                    color: `${JC_COLORS.JC_RED}`,
                                                    width: '60px',
                                                    height: '40px',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                            <p>
                                                Drag file here or
                                                <button className="upload-button" onClick={onButtonClick}>
                                                    {' '}
                                                    browse
                                                </button>
                                            </p>
                                        </div>
                                        </label>
                                        {dragActive && (
                                        <div
                                            id="drag-file-element"
                                            onDragEnter={handleDrag}
                                            onDragLeave={handleDrag}
                                            onDragOver={handleDrag}
                                            onDrop={handleDrop}
                                        ></div>
                                        )}
                                </form>

                            </Box>
                        </Box>

                    )
                }

            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {
                    (pdfFilePreview?.length > 0 && pdfFile?.type === "application/pdf") && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '16px'
                            }}
                        >
                            <Typography
                                sx={{ fontWeight: 700 }}>
                                Selected &nbsp;

                            </Typography>
                            <Box
                                sx={{
                                    borderRadius: '8px',
                                    backgroundColor: JC_COLORS.LIGHT_GREY,
                                    padding: '12px 8px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography>{pdfFile?.name}</Typography>
                                <IconButton
                                    onClick={() => setPdfFile(null)}
                                >
                                    <CloseIcon
                                        sx={{
                                            width: '16px',
                                            height: '16px'
                                        }}

                                    />
                                </IconButton>
                            </Box>
                        </Box>

                    )
                }
               

                <Button
                    variant="contained"
                    disabled={
                        (
                            (pdfFilePreview?.length === 0) ||
                            (pdfFile && (pdfFile?.type !== 'application/pdf')) ||
                            (pdfFile === null)
                        )
                    }
                    sx={{
                        marginBottom: '24px'
                    }}
                    onClick={handleUpload}
                >
                    ADD
                </Button>

            </DialogActions>

        </>
    );
}

export default DialogContentUploadCMEDisclosure;

/* <CloudUploadIcon
                                    sx={{
                                        color: `${JC_COLORS.JC_RED}`,
                                        width: '50px',
                                        height: '30px',
                                        cursor: 'pointer',
                                    }}
                                /> */

// const handleUpload = async () => {
    //     console.log(acceptedFiles[0]);
    //     setIsUploading(true);

    //     try {
    //         let res = await editJournalClub(urlSlug, { cmeFilename: acceptedFiles[0].path });
    //         console.log(res.data)
    //         const { message, payload, url } = res?.data;
    //         if (res.data.message === "success") {
    //             uploadToS3FromUrl(url, { file: acceptedFiles[0] }, payload)
    //                 .then(res => {
    //                     console.log(res)
    //                     setIsUploading(false); 
    //                     closeIconHandler();
    //                     window.location = `/jc/${urlSlug}`; 
    //                 })
    //                 .catch(
    //                     err => {
    //                         console.log(err)
    //                         setOpenErrorDialog(true);
    //                         setErrorMessage(err?.response?.data?.message);
    //                         setErrorStatusCode(err?.response?.status);
    //                     }
    //                 )
    //         }

           

    //     } catch (error) {
    //         console.log(error?.response);
    //         setOpenErrorDialog(true);
    //         setErrorMessage(error?.response?.data?.message);
    //         setErrorStatusCode(error?.response?.status);

    //     }
    // }