import { FETCH_COMMENTNOTES_REQUEST,FETCH_COMMENTNOTES_SUCCESS,FETCH_COMMENTNOTES_FAILURE,
    SET_COMMENT_DATA,SET_NOTE_DATA,SET_NEW_COUNT,SET_IMAGE_FILES,SET_VIDEO_FILES,SET_DOCUMENT_FILES,
    RESET_UPLOADED_FILES
 } from '../actions/comment'

// intial state

const intialState = {
    isCommentLoading: true,
    comments: [],
    notes: [],
    uploadImageFiles: [],
    uploadVideoFiles: [],
    uploadDocumentFiles: [],
    newCount: 0,
    error: '',
    actionSuccess: '',
    actionError: ''
}

// Set up reducer

export default (state= intialState,action) => {
    switch(action.type){
        case FETCH_COMMENTNOTES_REQUEST: 
            return {
                ...state, 
                isCommentLoading: true
            }    
        case FETCH_COMMENTNOTES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                articleData: action.payload
            }
        case FETCH_COMMENTNOTES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SET_COMMENT_DATA:
            return {
                ...state,
                comments: action.payload
            }
        case SET_NOTE_DATA:
            return {
                ...state,
                notes: action.payload
            }
        case SET_IMAGE_FILES:
            console.log("setting images")
            const currImageFiles = state.uploadImageFiles
            return {
                ...state,
                uploadImageFiles : [...currImageFiles,action.payload]
            }
        case SET_VIDEO_FILES:
            console.log("setting videos")
            const currVideoFiles = state.uploadVideoFiles
            return {
                ...state,
                uploadVideoFiles : [...currVideoFiles,action.payload]
            }
        case SET_DOCUMENT_FILES:
            console.log("setting documents")
            const currDocumentFiles = state.uploadDocumentFiles
            return {
                ...state,
                uploadDocumentFiles : [...currDocumentFiles,action.payload]
            }
        case RESET_UPLOADED_FILES:
            return {
                ...state,
                uploadImageFiles: [],
                uploadVideoFiles: [],
                uploadDocumentFiles: []
            }
        case SET_NEW_COUNT:
            const currCount = state.newCount
            return {
                ...state,
                newCount : currCount + 1
            }
        default: return state
    }
}