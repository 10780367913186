import { Box, CircularProgress, Grid, InputAdornment, Link, Pagination, Table, TableHead, TableBody, TableRow, TableCell, tableCellClasses, TableContainer, Typography, useMediaQuery, Hidden, styled, tooltipClasses, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { fetchJournalClubs } from '../../api';
import { CMEIcon, Graduate, PrivateJCIcon, PublicJCIcon } from '../../assets/SVG';
import { JC_CONTENT, JC_ROWS_PER_PAGE, TOOLTIP_TEXT_ALL_JC_LIST_APPROVAL_STATUS, TOOLTIP_TEXT_ALL_JC_LIST_CME_STATUS, TOOLTIP_TEXT_ALL_JC_LIST_MEMBERS, TOOLTIP_TEXT_ALL_JC_LIST_TYPE } from '../../constants/constants';
import { setJCContent } from '../../state/actions/journalclub';
import { CenteredContainer, CMEAccreditingClub } from '../components';
import { AuthContext } from '../../context/AuthContext';
import { ArtcleTitleCell, CenterFlexBox, EnhancedTableHead, InlineBox, JCBackdrop, JCTableRow, StyledSearchButton, StyledTextField, UserCell } from '../../pages/JCDetails/jc-content/report-content/ReportHelpers';
import CustomDropdown from '../../pages/JCDetails/components/CustomDropdown';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import SearchJC from '../components/SearchJC';
import * as api from "./../../api";
import { useTheme } from '@emotion/react';
import { JC_COLORS } from '../../styles/constants';


const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        //color: '#bdbdbd',
        color: '#838383',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        //backgroundColor: '#bdbdbd',
        backgroundColor: '#838383'
    },

}));


function AllJournalClubsList({ topic }) {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const [isFetchingJCList, setIsFetchingJCList] = useState(false);
    const [jcList, setJCList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfRows, setNumberOfRows] = useState(0);

    const [filter, setFilter] = useState('All');
    const [approvalStatus, setApprovalStatus] = useState(window.location.pathname.includes("manage-journal-clubs") ? "Unapproved" : "Approved");
    const [searchTerm, setSearchTerm] = useState("");



    const theme = useTheme();
    //const mobileMatch = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();


    const handleChangePage = (event, changedValue) => {
        setPageNumber(changedValue);
    };

    const searchButtonHandler = (value) => {
        //console.log(value)
        setSearchTerm(value);
    }

    const getJcList = (query) => {
        setIsFetchingJCList(true)
        fetchJournalClubs(query)
            .then(response => {
                const modifiedList = response.data.journalClubList.filter(JC => JC.JCID != "J00000000")
                const { numberOfRows, next } = response?.data
                setJCList(modifiedList);
                setNumberOfRows(numberOfRows);
                setIsFetchingJCList(false);
            })
            .catch(error => {
                const errorMsg = error.message;
                setIsFetchingJCList(false);
            });
    }

    useEffect(() => {
        setPageNumber(1)
        let queryParams = {
            topic: (topic ? topic : ''),
            status: filter.toLowerCase(),
            approved: approvalStatus.toLowerCase(),
            pageNumber: 1,
            searchTerm,
            numRecordsPerPage: JC_ROWS_PER_PAGE,
        };
        const query = userEmail + "?" + Object.entries(queryParams).map(arr => arr[0] + '=' + arr[1]).join('&');
        getJcList(query);
    }, [filter, approvalStatus, searchTerm, topic])

    useEffect(() => {
        let queryParams = {
            topic: topic || '',
            status: filter.toLowerCase(),
            approved: approvalStatus.toLowerCase(),
            pageNumber,
            searchTerm,
            numRecordsPerPage: JC_ROWS_PER_PAGE,
        };
        const query = userEmail + "?" + Object.entries(queryParams).map(arr => arr[0] + '=' + arr[1]).join('&');
        getJcList(query);
    }, [pageNumber])

    return (
        <>
            <Hidden mdDown>
                <Box mt={2} sx={{ textAlign: 'left' }}>
                    {
                        isFetchingJCList ?
                            <CircularProgress
                                color="primary"
                                sx={{ width: '20px!important', height: '20px!important' }}
                            /> : (
                                <Typography
                                    variant="BODY_ARTICLE_RALEWAY"
                                    sx={{ fontWeight: '700!important', marginRight: '12px' }}
                                >

                                    {
                                        `${numberOfRows > 0 ? numberOfRows : ''} ${numberOfRows === 0 ? '' : numberOfRows === 1 ? 'Journal Club Found' : 'Journal Clubs Found'}`
                                    }
                                </Typography>
                            )
                    }


                </Box>
                <Box sx={{ marginTop: "16px" }}>
                    <Box
                        sx={{
                            textAlign: 'left',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}
                        mt={{ xs: 2 }}
                    >
                        <Box
                            sx={{
                                display: 'inline-block',
                                marginTop: 1
                            }}
                        >
                            <SearchJC
                                searchApiUrl={api.SEARCH_JC_URL}
                                placeholder={"Search Journal Club"}
                                searchButtonHandler={searchButtonHandler}
                                topic={topic}
                                status={filter.toLowerCase()}
                                approved={approvalStatus.toLowerCase()}
                            >
                            </SearchJC>
                        </Box>
                        <Box
                            sx={{
                                float: 'right',
                                marginTop: 1
                            }}
                        >
                            <Box sx={{ display: 'inline-block', marginRight: 2 }}>
                                <CustomDropdown
                                    selectedLabel={filter}
                                    listOfLabels={["All", "Public", "Private"]}
                                    changeLabel={(val) => setFilter(val)}
                                />
                            </Box>
                            <Box sx={{ display: 'inline-block' }}>
                                <CustomDropdown
                                    selectedLabel={approvalStatus}
                                    listOfLabels={["All", "Approved", "Unapproved"]}
                                    changeLabel={(val) => setApprovalStatus(val)}
                                />
                            </Box>
                        </Box>

                    </Box>

                </Box>
                <Box sx={{ height: "24px" }}></Box>
                <TableContainer>
                    <Table
                        sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: 'none',
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: '16px',
                                borderRadius: '4px',
                            },
                            //minWidth: mobileMatch ? 300 : 750,
                        }}
                        aria-labelledby="tableTitle"
                        size="medium"
                    >
                        {
                            isFetchingJCList ? (
                                <CenteredContainer>
                                    <CircularProgress />
                                </CenteredContainer>
                            ) : (
                                <>
                                    {
                                        jcList.length > 0 ? (
                                            <>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            <BootstrapTooltip
                                                                title={TOOLTIP_TEXT_ALL_JC_LIST_TYPE}
                                                                placement="top">
                                                                <div>
                                                                    Type
                                                                </div>
                                                            </BootstrapTooltip>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            Journal Club Name / Description
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <BootstrapTooltip
                                                                title={TOOLTIP_TEXT_ALL_JC_LIST_MEMBERS}
                                                                placement="top">
                                                                <div>
                                                                    Members
                                                                </div>
                                                            </BootstrapTooltip>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <BootstrapTooltip
                                                                title={TOOLTIP_TEXT_ALL_JC_LIST_CME_STATUS}
                                                                placement="top">
                                                                <div>
                                                                    CME Status
                                                                </div>
                                                            </BootstrapTooltip>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <BootstrapTooltip
                                                                title={TOOLTIP_TEXT_ALL_JC_LIST_APPROVAL_STATUS}
                                                                placement="top">
                                                                <div>
                                                                    Approval Status
                                                                </div>
                                                            </BootstrapTooltip>

                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={{ position: 'relative' }}>
                                                    {
                                                        jcList.map((obj, index) => {
                                                            const labelId = `enhanced-table-desktop-${index}`;
                                                            return (
                                                                <>
                                                                    <JCTableRow
                                                                        onRowClick={(event) => { navigate(`/jc/${obj?.urlSlug}`) }}
                                                                        key={labelId}
                                                                    >
                                                                        <TableCell align="center">
                                                                            <BootstrapTooltip
                                                                                title={`${obj?.isPublic ? "Public" : "Private"}`}
                                                                                placement="top">
                                                                                <div>
                                                                                    {
                                                                                        obj?.isPublic ?
                                                                                            <PublicJCIcon width="24px" height="20px" />
                                                                                            :
                                                                                            <PrivateJCIcon width="24px" height="20px" />
                                                                                    }
                                                                                </div>
                                                                            </BootstrapTooltip>

                                                                        </TableCell>
                                                                        <TableCell
                                                                        >
                                                                            <JournalClubCell
                                                                                isJcPublic={obj.isPublic}
                                                                                jcName={obj.name}
                                                                                jcDescription={obj.description}
                                                                                onClick={() => navigate(`/jc/${obj?.urlSlug}`)}
                                                                            />

                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Typography variant="H3_RALEWAY">
                                                                                {obj?.totalMembers || '-'}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <Typography variant="H3_RALEWAY">
                                                                                {obj?.isCmeAccrediting ? <Graduate fill={JC_COLORS.JC_ICON} /> : <></>}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {obj?.approved ? <CheckIcon /> : '-'}
                                                                        </TableCell>
                                                                    </JCTableRow>
                                                                </>
                                                            );
                                                        })}
                                                </TableBody>
                                            </>

                                        ) : (
                                            <Grid item xs={12} sx={{ textAlign: 'center' }} mt={{ xs: '12%', md: 0 }} pt={2} mb={{ xs: '12%', md: 0 }} pb={2}>
                                                <Typography variant='H2_RALEWAY'>
                                                    {` No journal clubs found `}
                                                </Typography>
                                            </Grid>
                                        )
                                    }

                                </>


                            )
                        }


                    </Table>
                </TableContainer>


            </Hidden>
            <Hidden mdUp>
                <Box mt={2} sx={{ textAlign: 'left' }}>
                    {
                        isFetchingJCList ?
                            <CircularProgress
                                color="primary"
                                sx={{ width: '20px!important', height: '20px!important' }}
                            /> : (
                                <Typography
                                    variant="BODY_ARTICLE_RALEWAY"
                                    sx={{ fontWeight: '700!important', marginRight: '12px' }}
                                >

                                    {
                                        `${numberOfRows > 0 ? numberOfRows : ''} ${numberOfRows === 0 ? '' : numberOfRows === 1 ? 'Journal Club Found' : 'Journal Clubs Found'}`
                                    }
                                </Typography>
                            )
                    }


                </Box>
                <Box sx={{
                    margin: '24px 0px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                }}>
                    <Box sx={{ display: 'inline-block' }}>
                        <CustomDropdown
                            selectedLabel={filter}
                            listOfLabels={["All", "Public", "Private"]}
                            changeLabel={(val) => setFilter(val)}
                        />
                    </Box>
                    <Box sx={{ display: 'inline-block' }}>
                        <CustomDropdown
                            selectedLabel={approvalStatus}
                            listOfLabels={["All", "Approved", "Unapproved"]}
                            changeLabel={(val) => setApprovalStatus(val)}
                        />
                    </Box>
                </Box>

                <Box sx={{ marginBottom: "16px" }}>
                    <SearchJC
                        searchApiUrl={api.SEARCH_JC_URL}
                        placeholder={"Search Journal Club"}
                        searchButtonHandler={searchButtonHandler}
                        topic={topic}
                        status={filter.toLowerCase()}
                        approved={approvalStatus.toLowerCase()}
                    >
                    </SearchJC>
                </Box>
                {
                    isFetchingJCList ?
                        <CenteredContainer>
                            <CircularProgress />
                        </CenteredContainer> : (
                            <>
                                {
                                    jcList.length > 0 ? (
                                        <React.Fragment>
                                            {
                                                jcList.map((obj, index) => {
                                                    //const labelId = `enhanced-table-mobile-${index}`;
                                                    return (
                                                        <>
                                                            <Box
                                                                onClick={(event) => { navigate(`/jc/${obj?.urlSlug}`) }}
                                                                sx={{
                                                                    fontFamily: 'Raleway!important',
                                                                    padding: '16px 0px 16px 0px',
                                                                    backgroundColor: '#F3F3F3!important',
                                                                    width: "100%"
                                                                }}
                                                            >

                                                                <Box ml={3}>
                                                                    <Typography
                                                                        variant="BODY_ARTICLE_RALEWAY"
                                                                        color="#262626"
                                                                        sx={{
                                                                            fontWeight: '700!important'
                                                                        }}
                                                                    >
                                                                        {obj?.name}
                                                                    </Typography>
                                                                </Box>
                                                                <Box ml={3}>
                                                                    <Typography
                                                                        variant="BODY_COPY2_RALEWAY"
                                                                        color={JC_COLORS.JC_ICON}
                                                                        sx={{
                                                                            fontWeight: '600!important'
                                                                        }}
                                                                    >
                                                                        {obj?.description}
                                                                    </Typography>
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        justifyContent: "flex-start",
                                                                        alignItems: "center",
                                                                        margin: "16px 16px 8px 24px"
                                                                    }}
                                                                >
                                                                    {
                                                                        obj?.isPublic ?
                                                                            <PublicJCIcon width="18px" height="14px" />
                                                                            :
                                                                            <PrivateJCIcon width="18px" height="14px" />
                                                                    }
                                                                    <Typography
                                                                        variant="BODY_COPY2_RALEWAY"
                                                                        sx={{
                                                                            fontWeight: '600!important'
                                                                        }}
                                                                    >
                                                                        {obj?.isPublic ? "Public Journal Club" : "Private Journal Club"}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="BODY_COPY2_RALEWAY"
                                                                        sx={{
                                                                            fontWeight: '600!important',
                                                                            marginLeft: "16px"
                                                                        }}
                                                                    >
                                                                        {obj?.totalMembers > 0 ? `${obj?.totalMembers} members` : ''}
                                                                    </Typography>
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        marginLeft: "24px"
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: JC_COLORS.LIGHT_GREY,
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'flex-start',
                                                                            borderRadius: '4px',
                                                                            padding: '6px 0px',
                                                                        }}
                                                                    >
                                                                        {obj?.approved ? <CheckIcon sx={{ fontSize: 20 }} /> : ''}
                                                                        <Typography
                                                                            variant="BODY_COPY2_RALEWAY"
                                                                            sx={{
                                                                                fontWeight: '600!important',

                                                                            }}>
                                                                            {obj?.approved ? `Approved` : 'Unapproved'}
                                                                        </Typography>
                                                                    </Box>
                                                                    <CMEAccreditingClub />

                                                                </Box>


                                                            </Box>
                                                            <Box sx={{ minHeight: '12px' }}></Box>
                                                        </>
                                                    )
                                                }


                                                )
                                            }

                                        </React.Fragment>) : <Grid item xs={12} sx={{ textAlign: 'center' }} mt={{ xs: '12%', md: 0 }} pt={2} mb={{ xs: '12%', md: 0 }} pb={2}>
                                        <Typography variant='H2_RALEWAY'>
                                            {` No journal clubs found `}
                                        </Typography>
                                    </Grid>
                                }
                            </>
                        )

                }
            </Hidden>
            {
                (isFetchingJCList === false && jcList?.length > 0) ? (

                    <Box
                        mt={3}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Pagination
                            count={Math.ceil(numberOfRows / JC_ROWS_PER_PAGE)}
                            color="primary"
                            page={pageNumber}
                            onChange={handleChangePage}
                        />
                    </Box>


                ) : <></>
            }
        </>

    )
}

export default AllJournalClubsList

const JournalClubItem = ({ jcName, jcDescription, isPublic, isCmeAccrediting, JCID, urlSlug, approved }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    return (
        <Box
            onClick={() => {
                dispatch(setJCContent(JC_CONTENT.MANAGE));
                navigate(`/jc${urlSlug}`);

            }}
            sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <Box sx={{ display: 'inline-block', position: 'absolute', paddingTop: '2px' }}>
                {isPublic ?
                    <PublicJCIcon width="18px" height="14px" />
                    :
                    <PrivateJCIcon width="18px" height="14px" />
                }
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={3}>
                <Typography variant="H3_RALEWAY">
                    {jcName}
                </Typography>
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={2}>
                <Typography variant="H3_RALEWAY">
                    {jcDescription}
                </Typography>
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={3}>
                <Typography variant="H3_RALEWAY">
                    2 users
                </Typography>
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={3}>
                <Typography variant="H3_RALEWAY">
                    {isCmeAccrediting ? <CMEIcon /> : <></>}

                </Typography>
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={1}>
                {approved ? <CheckIcon /> : <></>}
            </Box>
        </Box>
    )


}

const JournalClubCell = ({
    jcName,
    jcDescription,
    onClick,
    contracted = false,
    isDisabled = false
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justiftContent: 'flex-start',
                cursor: 'pointer',
                width: "100%",
            }}
            onClick={onClick}
        >
            <Typography variant="BODY_ARTICLE_RALEWAY" color="#262626" sx={{ fontWeight: '700!important' }}>
                {jcName ? jcName : '-'}
            </Typography>
            <Typography
                variant="H3_RALEWAY"
                color="#838383"
                sx={{
                    fontWeight: '500!important',
                    color: JC_COLORS.JC_ICON,
                    textAlign: 'left',
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    overflow: "hidden",
                    //maxWidth: '80%',
                }}
            >
                {jcDescription ? jcDescription : ''}
            </Typography>
        </Box>

    )
}