import React from 'react'
import { styled } from '@mui/system'
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { Graduate } from '../../assets/SVG';
import { JC_COLORS } from '../../styles/constants';

const ResponsiveText = styled(Typography)(({ theme }) => ({
    //fontFamily: 'Bitter',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
        //fontSize: '18px',
        //lineHeight: '30px',
        marginLeft: '9.67px'
    },
    [theme.breakpoints.down('md')]: {
        //fontSize: '12px',
        //lineHeight: '14.4px',
        marginLeft: '8.67px'
    },
}));

const Container = styled(Box)(({ theme }) => ({
    //backgroundColor: JC_COLORS.JC_RED,
    //color: JC_COLORS.JC_WHITE,
    backgroundColor: JC_COLORS.LIGHT_GREY, 
    color: JC_COLORS.JC_RED, 
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '4px',
    padding: '6px 10px',
   

}));

const CMEAccreditingClub = () => {
    const theme = useTheme();

    return (
        <Container>
            {
                useMediaQuery(theme.breakpoints.up('md')) && (
                    <Graduate width={"24px"} height={'19px'} fill={JC_COLORS.JC_RED} />
                )
            }
            {
                useMediaQuery(theme.breakpoints.down('md')) && (
                    <Graduate width={"21px"} height={'17px'} fill={JC_COLORS.JC_RED} />
                )
            }
            <ResponsiveText variant="H3_RALEWAY">CME / CE Accrediting Club</ResponsiveText>
        </Container>
    )
}

export default CMEAccreditingClub