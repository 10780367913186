import { useState, useEffect } from "react";
import { useKeywordsContext } from "../../context/KeywordsContext";
import {Box, Tabs, tabsClasses, styled} from "@mui/material";
import { KeyWordChip } from "../components";

const TabsList = styled(Tabs)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [`& .${tabsClasses.scrollButtons}`]: {
        '&.Mui-disabled': { opacity: 0.3 },

    },
    '& .MuiTabs-indicator': { backgroundColor: "#FFFFFF" },
    [theme.breakpoints.up('md')]: {
        width: "80%",
        "*": {
            marginRight: "5px",
            marginBottom: "3px",
        }
    },
    [theme.breakpoints.down('md')]: {
        width: "100%",
        "*": {
            marginRight: "5px",
        }
    },
}));

const TabsListKeywordsAndSources = ({ disableClickOnChips = false }) => {

    const {
        keywordsState,
        sourcesState,
        selectAKeyword,
        removeAKeyword,
        selectASource,
        removeASource
    } = useKeywordsContext();

    const [combinedStateObj, setCombinedStateObj] = useState({})

    useEffect(() => {
        let obj1 = Object.keys(keywordsState)?.reduce((acc, keyword) => {
            return { ...acc, [keyword]: { type: 'keyword', state: keywordsState[keyword], name: keyword } }
        }, {});
        let obj2 = Object.keys(sourcesState)?.reduce((acc, source) => {
            return { ...acc, [source]: { type: 'source', state: sourcesState[source], name: source } }
        }, {});
        setCombinedStateObj({ ...obj1, ...obj2 });


    }, [keywordsState, sourcesState])



    return (
        <TabsList
            value={0}
            variant="scrollable"
            scrollButtons={true}
        >
            {
                Object.keys(combinedStateObj).sort().map(
                    (item, i) => (
                        <Box key={item} sx={{ height: "32px", mt: "2px" }}>
                            <KeyWordChip
                                label={item}
                                showCloseIcon={false}
                                disableClick={disableClickOnChips || false}
                                selected={combinedStateObj[item].state}
                                onClick={
                                    combinedStateObj[item].type === 'keyword' ?
                                        () => selectAKeyword(item) :
                                        () => selectASource(item)
                                }
                                closeButtonHandler={() => { }}
                            />
                        </Box>
                    )
                )
            }

        </TabsList>

    )

}

export default TabsListKeywordsAndSources; 
