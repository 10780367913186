import { setKeywords } from "../actions/keywords";

const initialState = {
    type: 'keyword',
    items: [],
    
}


export default (state = initialState, action) => {
    switch (action.type) {

        case "SET_KEYWORDS":
            //console.log("in store", action.payload); 
            return {
                ...state,
                items: action.payload
            }
        case "KEYWORD_CLICKED":
            const updatedItems = state.items.map(item => item.id === action.payload.id ? { ...item, isSelected: !item.isSelected, type: state.type } : { ...item, type: state.type });
            return {
                ...state,
                items: updatedItems,
            }
        case "CLEAR_ALL_KEYWORDS":
            return {
                ...state,
                items: [],
            };
        case "KEYWORD_REMOVED":
            return {
                ...state,
                items: state.items.filter(item => item.id !== action.payload.id)
            };
        case "KEYWORD_ADDED":
            if (state.items.filter(obj => obj.title === action.payload).length === 0) {
                let updatedItems = [...state.items, { id: action.payload, title: action.payload, isSelected: false, type: state.type }];
                return {
                    ...state,
                    items: updatedItems,
                };
            } else {
                return state
            }
        case "RESET_KEYWORDS":
            return initialState
        default:
            return state;
    }
};


// case "GET_KEYWORDS":
        //     return {
        //         ...state,
        //         items: action.payload
        //     }