import React from 'react';
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { editKeywords } from '../../state/actions/keywords';


const CenteredContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        width: '600px',
        margin: "70px auto",
    },
    [theme.breakpoints.down('md')]: {
        height: '100vh',
        margin: '0 auto',
        ...theme.typography.BODY_COPY1_RALEWAY_MOBILE,
    }
}));

const StyledText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        ...theme.typography.H1_BITTER,
    },
    [theme.breakpoints.down('md')]: {
        ...theme.typography.BODY_COPY1_RALEWAY,
    }

}));

const StyledButton = styled(Button)(({ theme }) => ({

    [theme.breakpoints.up('md')]: {
        width: '142px',
        height: '42px',
        marginTop: '52px',
        textTransform: 'none',
    },
    [theme.breakpoints.down('md')]: {

        marginTop: '29px',
        textTransform: 'uppercase',

    },
}));

const SetupKeywords = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    return (
        <CenteredContainer>
            <StyledText>
                You need to setup article feed keywords and journal sources.
            </StyledText>
            <StyledButton
                variant='contained'
                size="small"
                color="primary"
                onClick={() => navigate('/edit-keywords-or-sources')}
            >
                Set Up Now
            </StyledButton>


        </CenteredContainer>
    )
}

export default SetupKeywords