import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Divider, Link } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import { JC_COLORS } from '../../../../styles/constants';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AuthContext } from '../../../../context/AuthContext';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { postJCInviteCsv } from '../../../../api';
import { CenterFlexBox } from '../report-content/ReportHelpers';

const steps = [
  {
    label: 'Download CSV File',
    description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: 'Add user info in CSV template',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Upload CSV File',
    description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      borderRadius: '0px',
      margin: 0,
    },
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      width: '40%',
      maxWidth: '40%',
      height: '72%',
    },
  },
}));
const UPLOAD_NAV = {
  UPLOAD_ROOT: 'UPLOAD_ROOT',
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_REPORT: 'UPLOAD_REPORT',
};
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: JC_COLORS.JC_RED,
        color: JC_COLORS.JC_WHITE,
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            color: JC_COLORS.JC_WHITE,
            cursor: 'pointer',
          }}
        />
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function BulkInvite(props) {
  const { open, onClose } = props;
  const auth = useContext(AuthContext)
  const userEmail = auth.user.email; 
  const [inviteNav, setInviteNav] = useState(UPLOAD_NAV.UPLOAD_ROOT);
  const [fileUploadError,setFileUploadError] = useState('')
  const [activeStep, setActiveStep] = React.useState(0);
  const [csvFile, setCsvFile] = useState(null);
  const [inviteReport,setInviteReport] = useState({})
  var csvData = 'First Name, Last Name, Email,Role\n';
  const { jcDetails } = useSelector((state) => state.journalclub);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  
  const handleOnProceed = (e) => {
    e.preventDefault();
    if (csvFile) {
      getBase64(csvFile).then(data => {
      const formatData = data.split(",").length == 2 ? data.split(",")[1] : data
      postJCInviteCsv(jcDetails?.JCID,{'uploadFile' : formatData,'authUserEmail':userEmail}).then(res => {
        if(res.data){
          setInviteReport(res.data)
          setInviteNav(UPLOAD_NAV.UPLOAD_REPORT)
        }else {
          setFileUploadError("Invalid/Missing fields in uploaded file!")
        }
      }).catch(error => {
        setFileUploadError("Invalid/Missing fields in uploaded file!")
      })
    })
    }
  };

  const handleOnDone = () => {
    setInviteNav(UPLOAD_NAV.UPLOAD_ROOT)
    setCsvFile(null)
    setFileUploadError("")
    onClose()
  }

  const handleOnUpload = (e) => {
    e.preventDefault();
    if (csvFile) {
      setInviteNav(UPLOAD_NAV.UPLOAD_ROOT)
    } 
  };

  return (
    <React.Fragment>
      {/**Bulk Invite */}
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <Typography variant="H3_RALEWAY" pl={{ xs: 0, md: 2.2 }}>
            Bulk Invite Members
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box pl={2.2} pt={1}>
            {inviteNav === UPLOAD_NAV.UPLOAD_ROOT && (
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                sx={{
                  '& .MuiStepLabel-label, & .MuiStepIcon-text': {
                    fontFamily: 'Raleway !important',
                    fontWeight: '700 !important',
                  },
                }}
              >
                {steps.map((step, index) => (
                  <Step key={step.label} active={true}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      <Box>
                        <div>
                          {index === 0 && (
                            <Button
                              variant="outlined"
                              sx={{ mr: 1 }}
                              startIcon={<DownloadIcon />}
                            >
                              <Link
                                sx={{
                                  color: JC_COLORS.JC_RED,
                                  fontFamily: 'Raleway',
                                  fontWeight: '700',
                                  cursor: 'pointer',
                                  textDecoration: 'None',
                                  '&:hover': { textDecorationColor: '#262626' },
                                }}
                                target="_blank"
                                download={`inviteTemplate.csv`}
                                href={
                                  'data:text/csv;charset=utf-8,' +
                                  encodeURI(csvData)
                                }
                              >
                                Download Blank CSV Template
                              </Link>
                            </Button>
                          )}
                          {index === 1 && (
                            <table
                              style={{
                                fontFamily: 'Raleway',
                                fontSize:'12px',
                                minWidth: '400px',
                              }}
                            >
                              <tr style={{ backgroundColor: '#C4C4C4' }}>
                                <th style={{ padding: '8px', textAlign: 'left' }}>First Name</th>
                                <th style={{ padding: '8px', textAlign: 'left' }}>Last Name</th>
                                <th
                                  style={{ padding: '8px', textAlign: 'left' }}
                                >
                                  Email
                                </th>
                                <th
                                  style={{ padding: '8px', textAlign: 'left' }}
                                >
                                  Role
                                </th>
                              </tr>
                              <tr style={{ backgroundColor: '#F3F3F3' }}>
                                <td style={{ padding: '8px' }}>John</td>
                                <td style={{ padding: '8px' }}>Doe</td>
                                <td style={{ padding: '8px' }}>
                                  john@sample.edu
                                </td>
                                <td style={{ padding: '8px' }}>
                                  Reader
                                </td>
                              </tr>
                            </table>
                          )}
                          {index === 2 && (
                          <React.Fragment>
                            <Button
                              variant="outlined"
                              sx={{
                                mr: 1,
                                fontFamily: 'Raleway',
                                fontWeight: '700',
                              }}
                              startIcon={<UploadIcon />}
                              onClick={() => {
                                setInviteNav(UPLOAD_NAV.UPLOAD_FILE)
                                setFileUploadError("")
                              }}
                            >
                              Upload CSV
                            </Button>
                            {csvFile && csvFile.name && (
                            <Typography variant="BODY_COPY2_RALEWAY"> {csvFile.name}</Typography>
                            )}
                            {fileUploadError && (
                            <Box>
                              <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_RED}> { fileUploadError } </Typography> 
                            </Box> 
                            )}
                          </React.Fragment>
                          )}
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            )}
            {inviteNav === UPLOAD_NAV.UPLOAD_FILE && (
              <Box>
                <DragDropFile
                  onUpload={handleOnUpload}
                  csvFile={csvFile}
                  setCsvFile={setCsvFile}
                />
              </Box>
            )}
            {inviteNav === UPLOAD_NAV.UPLOAD_REPORT && 
              <Box mt={2}>
                <UploadReportBox numberOf={inviteReport.totalRows} title="Total Rows Processed"></UploadReportBox>
                <UploadReportBox numberOf={inviteReport.newUsers} title="New Users" subTitle="(an email invite has been sent)"></UploadReportBox>
                <UploadReportBox numberOf={inviteReport.existingMembers} title="Already members to this Journal Club" subTitle="(no changes made)"></UploadReportBox>
                {inviteReport.errorRows != 0 &&
                <UploadReportBox numberOf={inviteReport.errorRows} title="Error in Rows" subTitle="(Please fix and re-upload)" error={true}></UploadReportBox>
                }
                {inviteReport.errorRows != 0 &&
                <Box sx={{textAlign:'left'}} mt={2}>
                  <Button variant="outlined" sx={{ mr: 1 }} startIcon={<DownloadIcon />}>
                    <Link sx={{color: JC_COLORS.JC_RED,fontFamily: 'Raleway',fontWeight: '700',cursor: 'pointer',textDecoration: 'None','&:hover': { textDecorationColor: '#262626' }}} target="_blank" download={`inviteReport.csv`}
                    href={'data:text/csv;charset=utf-8,' +encodeURI(inviteReport.report)}>
                    Download Report
                    </Link>
                  </Button>
                  <Typography variant="BODY_COPY2_RALEWAY" color={ JC_COLORS.JC_ICON} sx={{textTransform: 'capitalize'}}>
                    To view and fix errors
                  </Typography>
                </Box>
                }

              {inviteReport.errorRows != 0 &&
                <Box sx={{ textAlign: 'left' }} mt={1} mx={-0.8}>
                  <Link sx={{ cursor: 'pointer'}} onClick={() => setInviteNav(UPLOAD_NAV.UPLOAD_ROOT)}>
                      <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
                      <Typography variant="BODY_COPY2_RALEWAY" sx={{textTransform: 'capitalize'}}>
                        GO BACK TO REUPLOAD CSV
                      </Typography>
                  </Link>
                </Box>
              }
              </Box>
            }
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', paddingBottom: '16px!important' }}
        >
          <Button
            variant="contained"
            type="submit"
            onClick={ inviteNav === UPLOAD_NAV.UPLOAD_FILE ? handleOnUpload : inviteNav === UPLOAD_NAV.UPLOAD_ROOT ? handleOnProceed : handleOnDone}
            sx={{ height: '35px' }}
            disabled={!csvFile}
          >
            <Typography variant="H3_RALEWAY">{inviteNav === UPLOAD_NAV.UPLOAD_FILE ? 'Upload' : inviteNav === UPLOAD_NAV.UPLOAD_ROOT ? 'Proceed' : 'Done'}</Typography>
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {/**Bulk to JC */}
    </React.Fragment>
  );
}

export default BulkInvite;

function DragDropFile({ onUpload, csvFile, setCsvFile }) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if(e.dataTransfer.files[0].name.includes(".csv"))
      setCsvFile(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setCsvFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <Box>
      <Box ml={2.1} mb={1}>
        <Typography variant="BODY_COPY2_RALEWAY"> Upload .CSV</Typography>
      </Box>
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleChange}
          accept=".csv"
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? 'drag-active' : ''}
        >
          <div>
            <CloudUploadIcon
              sx={{
                color: `${JC_COLORS.JC_RED}`,
                width: '60px',
                height: '40px',
                cursor: 'pointer',
              }}
            />
            <p>
              Drag file here or
              <button className="upload-button" onClick={onButtonClick}>
                {' '}
                browse
              </button>
            </p>
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
      <Box ml={2.1} mt={1}>
      {csvFile && csvFile.name && (
        <Typography variant="BODY_COPY2_RALEWAY"> {csvFile.name}</Typography>
      )}
      </Box>
    </Box>
  );
}

const UploadReportBox = ({numberOf,title,subTitle='',top=false,error=false}) => {
  return (
    <CenterFlexBox>
      <Box sx={{minWidth:'60px'}}>
      <Typography variant="H1_BITTER">
        {numberOf ? numberOf < 10 ? "0" + numberOf : numberOf : "00"}
      </Typography>
      </Box>
      <Box>
        <Box>
          <Typography variant="BODY_COPY2_RALEWAY" color={top ? "#ffffff" : JC_COLORS.JC_ICON}>
            {title}
          </Typography>
          {error &&
          <ErrorIcon sx={{color: JC_COLORS.JC_ICON,position: 'relative', top: '6px',marginLeft:'8px',width:'22px',height:'22px'}}/>
          }
        </Box>
        {subTitle &&
        <Box>
          <Typography variant="BODY_COPY2_RALEWAY" color={top ? "#ffffff" : JC_COLORS.JC_ICON}>
            {subTitle}
          </Typography>
        </Box>
        }
      </Box>
    </CenterFlexBox>
  )
}
