import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    Grid,
    InputAdornment,
    Divider,
    Link,
    styled,
    Backdrop,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import CustomDropdownDateRange from '../../../Feed/components/CustomDropdownDateRange';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector, useDispatch } from "react-redux";
import { CommentedIcon, LikedIcon, NotedIcon, ViewsIcon, SupportArticle } from '../../../../assets'
import PracticeIcon from '../../../../assets/PracticeIcon';
import { fetchEngagementReports } from '../../../../api';
import { JC_COLORS } from '../../../../styles/constants';
import { REPORTS_NAV, REPORT_ROWS_PER_PAGE } from '../../../../constants/constants';
import { updateReportNav } from '../../../../state/actions/jcreports';
import { ReactComponent as CmeHat } from "../../../../assets/CME_hat.svg"
import { CenterFlexBox, ArtcleTitleCell, InstitutionCell, InlineBox, SummaryBox, StyledTextField, StyledSearchButton, DownloadReportButton, EnhancedTableHead, EngagementHeadCells } from './ReportHelpers';

const EngagementReport = (props) => {
    const { reportRootFilter, rootDateRange } = useSelector((state) => state.jcreports);
    const dispatch = useDispatch()
    const [dateRangeLabel, setDateRangeLabel] = useState(reportRootFilter)
    const [queryParameters, setQueryParameters] = useState(null)
    const [value, setValue] = useState('')
    const [engagementData, setEngagementData] = useState({})
    const [dateRange, setDateRange] = useState(rootDateRange)
    const [SearchText, setSearchText] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const { jcDetails } = useSelector((state) => state.journalclub);
    // report table states
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(1);


    let currentLoginUser = localStorage.getItem("jcid");


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        setPage(1)
    };

    const handleRowClick = (event) => {
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const emptyRows = 0

    const handleSearch = () => {
        setIsLoading(true)
        setSearchText(value)
        var queryParams = {
            authUserEmail: props.userEmail,
            numRecordsPerPage: REPORT_ROWS_PER_PAGE,
            pageNumber: 1,
            sort: order,
            orderBy: orderBy,
            term: value
        }

        setQueryParameters(queryParams)
        fetchData(queryParams)
    }
    const resetPageNumber = (val, callback) => {
        setPage(1)
        callback(val)
    }

    useEffect(() => {
        setIsLoading(true)
        var queryParams = {
            authUserEmail: props.userEmail,
            numRecordsPerPage: REPORT_ROWS_PER_PAGE,
            pageNumber: page,
            sort: order,
            orderBy: orderBy,
        }
        if(SearchText !== ""){
            queryParams = { ...queryParams, term: SearchText }
        }
        setQueryParameters(queryParams)
        fetchData(queryParams)
    }, [dateRange, page, order, orderBy, SearchText])

    const fetchData = (queryParams) => {
        if (dateRange.startDate && dateRange.endDate) {
            queryParams = { ...queryParams, ...dateRange }
            setQueryParameters(queryParams)
        }
        fetchEngagementReports(jcDetails?.JCID, queryParams).then(response => {
            if (response?.data) {
                setEngagementData(response.data)
                setIsLoading(false)
            }
        }).catch(error => {
            console.error(error)
        })
    }

    return (
        <React.Fragment>
            <Box sx={{ textAlign: 'left' }} my={-1} mx={-0.8}>
                <Link sx={{ cursor: 'pointer', textDecoration: 'None' }} onClick={() => dispatch(updateReportNav("Reports"))} className='report-nav-root_text'>
                    <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
                    <Typography variant="BODY_COPY2_RALEWAY">
                        {REPORTS_NAV.ROOT}
                    </Typography>
                </Link>
            </Box>

            <Box sx={{ textAlign: 'left' }} mt={2} mb={2}>
                <InlineBox>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant="H1_BITTER" color="primary" sx={{ fontWeight: '700 !important' }}>
                            {REPORTS_NAV.ENGAGEMENT}
                        </Typography>
                    </Box>
                </InlineBox>
                <InlineBox sx={{ float: 'right' }}>
                    <CustomDropdownDateRange isHeading={false} isWhite={true}
                        dateRangeLabel={dateRangeLabel}
                        changeDateRangeLabel={(value) => setDateRangeLabel(value)}
                        changeDateRangeHandler={(value) => resetPageNumber(value, setDateRange)}
                    />
                </InlineBox>
            </Box>
            {/* Engagement Summary */}
            {engagementData &&
                <Grid container spacing={{ xs: 1.5 }} sx={{ backgroundColor: JC_COLORS.JC_RED, borderRadius: '4px', padding: '24px', width: '100%!important', margin: '0px!important' }}>
                    <Grid item xs={12} sx={{ paddingTop: '0px!important' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={engagementData.numAllArticleViews} title="Total views"> <ViewsIcon width="28px" height="28px" /> </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={engagementData.numAllArticleLikes} title="Total Likes"> <LikedIcon className="white-icon" /> </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={engagementData.numAllArticlePracticeImpactings} title="Total Practice Impacting Points"> <PracticeIcon width="28px" height="28px" fillcolor="#FFFFFF" /> </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={engagementData.numAllArticleSupports} title="Total Support"> <SupportArticle className="white-icon" /> </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={engagementData.numAllComments} title="Total Comments"> <CommentedIcon className="white-icon" /> </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={engagementData.numAllArticleNotes} title="Total Notes"> <NotedIcon width="28px" height="28px" className="white-icon" /> </SummaryBox>
                            </Grid>
                            <Grid item xs={4}>
                                <SummaryBox numberOf={engagementData.numAllArticleCMECredits} title="Total CME/CE credits given" isfloat={true}> <CmeHat width="28px" height="28px" /> </SummaryBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Box mt={3} sx={{ textAlign: 'left' }}>
                {engagementData &&
                    <Typography variant="BODY_ARTICLE_RALEWAY" sx={{ fontWeight: '700!important', marginRight: '12px' }}> {engagementData.numberOfRow !== 0 ? engagementData.numberOfRow && (engagementData.numberOfRow !== 1 ? engagementData.numberOfRow + " Articles Found" : engagementData.numberOfRow + " Article Found") : "No Result Found"}</Typography>
                }
                {isLoading &&
                    <CircularProgress color='primary' sx={{ width: '20px!important', height: '20px!important' }} />
                }
            </Box>

            <Box sx={{ textAlign: 'left' }} mt={2} mb={1}>
                <InlineBox>
                    <StyledTextField
                        value={value}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                        placeholder={"Search Article"}
                        autoComplete='off'
                        inputProps={{
                            style: {
                                fontFamily: 'Raleway',
                                fontWeight: 600,
                            }
                        }}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                            endAdornment: (
                                <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                                    <CloseIcon style={{ display: `${value === "" ? 'none' : 'block'}` }} onClick={() => { setSearchText(""); setValue("") }} />
                                </InputAdornment>),
                        }}
                        onChange={(e) => { setValue(e.target.value ? e.target.value : ""); }}
                    ></StyledTextField>
                    <StyledSearchButton variant="outlined" onClick={() => handleSearch()} sx={{ marginLeft: '11px' }}>
                        Search
                    </StyledSearchButton>
                </InlineBox>
                <InlineBox sx={{ float: 'right' }}>
                    <DownloadReportButton JCID={jcDetails.JCID} parameter={"articlesStats"} queryParameters={queryParameters} currentLoginUser={currentLoginUser} downloadName={"Engagement_Activity"} dateRange={dateRange} />
                </InlineBox>
            </Box>

            {engagementData && engagementData.articles &&
                <Box mt={3} mb={3}>
                    <Box sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none",
                                        fontFamily: 'Raleway',
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        borderRadius: '4px'
                                    },
                                    minWidth: 750
                                }}
                                aria-labelledby="tableTitle"
                                size="medium"
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={EngagementHeadCells}
                                />

                                <TableBody sx={{ position: 'relative' }}>
                                    <Backdrop
                                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute', backgroundColor: 'transparent' }}
                                        open={isLoading}
                                    >
                                        <CircularProgress color='primary' />
                                    </Backdrop>
                                    {engagementData.articles.map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <React.Fragment>
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleRowClick(event)}
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "#FFF7F6!important"
                                                        },
                                                        backgroundColor: "#F3F3F3!important"
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ fontFamily: 'Raleway!important' }}
                                                    >
                                                        <ArtcleTitleCell title={row.title} urlSlug={jcDetails.urlSlug} PMID={row.PMID} isDenovoArticle={row.isDenovoArticle}/>
                                                    </TableCell>
                                                    <TableCell align="center"> {row.numberOfViews}</TableCell>
                                                    <TableCell align="center"> {row.numberOfLikes}</TableCell>
                                                    <TableCell align="center"> {row.numberOfPracticeImpactings}</TableCell>
                                                    <TableCell align="center"> {row.numberOfSupporters}</TableCell>
                                                    <TableCell align="center"> {row.numberOfComments}</TableCell>
                                                    <TableCell align="center"> {row.numberOfNotes}</TableCell>
                                                </TableRow>
                                                <Box sx={{ minHeight: '12px' }}></Box>
                                            </React.Fragment>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={3} sx={{ marginLeft: 'calc( 50% - 80px)' }}>
                            <Pagination count={Math.ceil(engagementData.numberOfRow / REPORT_ROWS_PER_PAGE)} color="primary" page={page} onChange={handleChangePage} />
                        </Box>
                    </Box>
                </Box>
            }

        </React.Fragment>
    )
}

export default EngagementReport

const engagementDataRaw = {
    "articles": [
        {
            "title": "Association of Optimal Gestational Weight Gain Ranges With Perinatal Outcomes Across Body Mass Index Categories in Twin Pregnancies.",
            "numberOfPracticeImpactings": 0,
            "numberOfNotes": 0,
            "numberOfLikes": 2,
            "numberOfSupporters": 2,
            "numberOfViews": 3,
            "PMID": "35852802",
            "numberOfComments": 0
        },
        {
            "title": "Initial outcomes of a community health worker programme for patients with type 2 diabetes in refugee camps at the West Bank, occupied Palestinian territory: a retrospective, matched cohort study.",
            "numberOfPracticeImpactings": 1,
            "numberOfNotes": 0,
            "numberOfLikes": 2,
            "numberOfSupporters": 2,
            "numberOfViews": 2,
            "PMID": "35786252",
            "numberOfComments": 0
        },
        {
            "title": "How the Gender Wage Gap for Primary Care Physicians Differs by Compensation Approach : A Microsimulation Study.",
            "numberOfPracticeImpactings": 0,
            "numberOfNotes": 0,
            "numberOfLikes": 1,
            "numberOfSupporters": 2,
            "numberOfViews": 1,
            "PMID": "35849829",
            "numberOfComments": 1
        }
    ],
    "numAllReportedNotes": 0,
    "numAllArticleViews": 135,
    "numAllReportedComments": 2,
    "numAllComments": 9,
    "numAllArticleLikes": 51,
    "numAllArticlePracticeImpactings": 13
}
