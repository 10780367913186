import { Box } from '@mui/material';
import React, { useEffect, useContext } from 'react';
import { AuthContext } from "../context/AuthContext";
import { useSelector } from 'react-redux';
import JCHeader from '../common/components/JCHeader';
import { JC_COLORS } from '../styles/constants';
import { useNavigate } from 'react-router';

function EmailSent() {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const { verificationEmail } = useSelector((state) => state.user)

  useEffect(()=> {
    document.body.style.backgroundColor = JC_COLORS.JC_WHITE
    if(auth.user.email){
      navigate('/feed')
    }
    return () => document.body.style.backgroundColor = JC_COLORS.LIGHT_GREY
  },[])

  return (
    <React.Fragment>
      <JCHeader />
      <Box mt={{ xs: '15%', md: '8%' }}>
        <div className="content_wraper content_wraper_large content">
          <h1 className="jc-landing-page-heading jc-red">Email Verification</h1>
          {verificationEmail &&
          <p className="jc-landing-page-paragraph">
            The verification link has been sent to{' '}
            <span style={{ fontWeight: '600' }}>{verificationEmail}</span>
          </p>
          }
          <p className="jc-landing-page-paragraph">
            Please click the link on your email to verify your account{' '}
            <br className="hide-desktop" />
            and access the journal club platform.
          </p>
          <p
            className="jc-bodycopy"
            style={{ marginTop: '8px', marginBottom: '44px' }}
          >
            *Check your spam folders in case the email is not found in your
            inbox.
          </p>
          <hr size={'2px'} />
        </div>
        <div className="content_wraper">
          <p
            className="jc-landing-page-paragraph"
            style={{ fontSize: '16px', marginTop: '24px', fontWeight: '600' }}
          >
            Still not found the link?
          </p>
          <p className="jc-bodycopy" style={{ margin: '12px 10% 0px 10%' }}>
            It’s possible that your organizational spam filters might not be
            allowing the verification emails to come through.
          </p>
          <p className="jc-bodycopy" style={{ margin: '12px 10% 0px 10%' }}>
            You can have your email verified by sending an email to{' '}
            <a className="jc-red" href={`mailto:${process.env.REACT_APP_COGNITO_VERIFY_EMAIL}`}>
              {`${process.env.REACT_APP_COGNITO_VERIFY_EMAIL}`}
            </a>{' '}
            with the subject as <strong>"Verify Me"</strong>
          </p>
        </div>
      </Box>
    </React.Fragment>
  );
}

export default EmailSent;
