import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField, Button, Paper, InputAdornment, Box, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import debounce from "lodash/debounce";
import axios from 'axios';
import * as api from '../../../api';

import { Row, Spacer } from "../../../common/components";
import { JC_COLORS } from "../../../styles/constants";
import { logout } from '../../../utils';
import connectionInstance from '../../../utils/connection';
import { useKeywordsContext } from '../../../context/KeywordsContext';
import useDebounce from '../../../common/hooks/useDebounce';


const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    padding: 0,
    borderRadius: '3px',
    [theme.breakpoints.up('md')]: {
        width: '39vw',
        '& .MuiInputBase-root': {
            height: '40px',
        },
    },
    [theme.breakpoints.down('md')]: {
        width: '70vw',
        '& .MuiInputBase-root': {
            height: '35px',
            padding: '0px',
        },
    },
    '& .MuiFormControl-root': {
        padding: '0px',
    },

    '& .MuiOutlinedInput-root': {
        padding: "0px 10px",
        "& > *": {
            display: 'flex',
            alignItems: 'center',

        },
        '& fieldset': {
            border: '2px solid #838383',

        },
        '&:hover fieldset': {
            borderColor: JC_COLORS.JC_RED,
        },
        '&.Mui-focused fieldset': {
            borderColor: JC_COLORS.JC_RED
        },
    },

}));

const AddButton = styled(Button)(({ theme }) => ({
    fontWeight: 'bold',
    border: "2px solid " + JC_COLORS.JC_RED,


    [theme.breakpoints.up('md')]: {
        height: '40px',
        marginLeft: "16px",
    },
    [theme.breakpoints.down('md')]: {
        height: '35px',
        marginLeft: "10px",
    }

}));
const StyledOption = styled(Typography)(({ theme }) => ({
    textDecoration: 'none',
    color: JC_COLORS.JC_BLACK,
    fontFamily: 'Raleway',
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '10px 17px'
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '12px',
        lineHeight: '18px',
        margin: '16px 14px'

    }

}));



const SearchBox = ({ searchApiUrl, placeholder, searchResultsToShow = 15, addButtonClickHandler }) => {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const [optionsList, setOptionsList] = useState([]);
    const [loadingOptionsList, setLoadingOptionsList] = useState(false);
    const [value, setValue] = useState("");
    const [query, setQuery] = useState("");
    const [userIsTyping, setUserIsTyping] = useState(false);

    const { addAKeyword } = useKeywordsContext();


    const getAutocompleteResults = (query) => {
        let cancel;
        setUserIsTyping(false);
        setLoadingOptionsList(true);
        setOptionsList([]);
        if (query.length > 2) {
            connectionInstance.get(
                `${searchApiUrl}/${query}`,
                {
                    params: {}
                },
                {
                    cancelToken: new axios.CancelToken(c => cancel = c),
                }
            ).then(res => {
                //console.log(`Search results before set operation`, res.data.options);
                let { options } = res.data;
                options = [...new Set(options.slice(0, searchResultsToShow))];
                //console.log(`Search results after Set operation and applying limit`, options);
                setOptionsList(options);
                setLoadingOptionsList(false);
            }).catch(error => {
                console.log(error);
                if (error?.response && error?.response?.status === 401) {
                    logout()
                }
            })
            return () => cancel("Cancelling request due to key-debounce")

        } else {
            setOptionsList([]);
            setLoadingOptionsList(false);
        }

    }
    
    useDebounce(() => {
        getAutocompleteResults(query);
    }, 1200, [query])

    useEffect(() => {
        setUserIsTyping(true)
    }, [query])

    
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Row sx={{ justifyContent: 'flex-start' }}>

                    <StyledAutocomplete
                        freeSolo
                        fullWidth
                        loading={query.length > 0 && loadingOptionsList}
                        loadingText={<StyledOption>Loading...</StyledOption>}
                        filterOptions={(x) => x}
                        value={value}
                        onChange={(event, newValue) => setValue(newValue)}
                        options={optionsList}
                        renderOption={
                            (props, option) => {
                                return (
                                    <StyledOption
                                        key={option.PMID}
                                        {...props}
                                    >
                                        {option}
                                    </StyledOption>
                                )
                            }
                        }
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{
                                    backgroundColor: JC_COLORS.LIGHT_GREY,
                                    border: optionsList.length === 0 ? 'none' : `2px solid ${JC_COLORS.JC_ICON}`,
                                    borderRadius: '6px',
                                }}
                            >
                                {children}
                            </Paper>
                        )}

                        renderInput={(params) => {
                            params.InputProps.startAdornment = (
                                <>
                                    <InputAdornment position="start"><SearchIcon /></InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            );
                            params.InputProps.style = {
                                fontFamily: 'Raleway',
                                fontWeight: 600,
                            }




                            return (
                                <TextField
                                    {...params}
                                    placeholder={placeholder ?? "Search"}
                                    variant="outlined"
                                    onChange={e => setQuery(e.target.value)}
                                    onKeyPress={
                                        (e) => {
                                            if (e.key === "Enter") {
                                                setQuery("");
                                                setValue("");
                                            }
                                        }
                                    }
                                />

                            )
                        }

                        }
                    />

                    <AddButton
                        variant="outlined"

                        onClick={
                            () => {
                                addButtonClickHandler(value);
                                setValue("");
                            }
                            // () => { 
                            //     if (value) {
                            //         dispatch(addButtonClickHandler(value));

                            //     }
                            //     setValue("");
                            // }
                        }
                    >
                        ADD
                    </AddButton>
                </Row>
                {
                    (query.length > 0 && optionsList.length === 0) && (
                        <Typography
                            variant="BODY_COPY1_RALEWAY"
                            fontWeight='bold'
                            color="primary"
                            sx={{ mt: "4px" }}
                        >
                            {userIsTyping ? "typing..." : ""}
                            {(!userIsTyping && query.length < 3) ? "OOPS!!! Please enter atleast 3 characters" : ""}
                            {(!userIsTyping && query.length >= 3 && optionsList.length === 0) ? "OOPS!!! No Matches" : ""}
                        </Typography>
                    )
                }

            </Box>
        </>



    )

}

export default SearchBox;



/* <Autocomplete
               freeSolo
               classes={{ root: classes.root, paper: classes.paper }}
               label={label}
               filterOptions={(x) => x}
               onChange={(e) => setValue(e.target.innerText)}
               options={optionsList}
               renderInput={(params) => (
                   <TextField
                       {...params}
                       label={""}
                       onChange={e => setQuery(e.target.value)}
                       //onChange ={e => setValue(e.target.value)}
                       //onChange={(e) => handleChange(e)}
                       //onChange={(e, newInputValue) => setValue(newInputValue)}
                       sx={sx}
                       InputProps={{
                           ...params.InputProps,
                           startAdornment: (
                               <IconButton sx={{ paddingTop: "0px", paddingLeft: "2px" }}>
                                   <SearchIcon />
                               </IconButton>

                           )
                       }}
                   />
               )}
           /> */
// const mapDispatchToProps = dispatch => {
//     return {
//         addButtonClickHandler: value => dispatch(addButtonClickHandler(value))
//     }
// }

// export default connect(null, mapDispatchToProps)(SearchBox);

/* Approach using useCallback hook */
    // const getOptionsDelayed = useCallback(
    //     debounce((text, callback) => {
    //         setOptionsList([]);
    //         searchApiCallFunction(text).then(callback);
    //     }, 200),
    //     []
    // );

    // useEffect(() => {
    //     getOptionsDelayed(value, (res) => {
    //         let {data} = res;
    //         let {options} = data;
    //         setOptionsList(options);
    //     });
    // }, [value, getOptionsDelayed]);

/* Approach using cancel token inside the onChange handler */
    // const handleChange = async (e) => {
        // if (searchApiCallFunction === undefined) {
        //     return
        // }

        // if (e.target.value) {
        //     console.log(e.target.value);
        //     let cancelToken;
        //     if (cancelToken) {
        //         cancelToken.cancel("Operation cancelled due to new request")
        //     }
        //     cancelToken = new axios.CancelToken.source()
        //     const config = {
        //         headers: api.headers,
        //         cancelToken: cancelToken.token
        //     };
        //     const { data } = await axios.get(`${searchApiUrl}/${e.target.value}`, config)
        //     console.log(data);


            //let { data } = await searchApiCallFunction(e.target.value);
            //let { options } = data;
            //options = [...new Set(options.slice(0, 6))];
            //setOptionsList(options);
    //    }
    //};

