import React, { useState,useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RootReport from './report-content/RootReport';
import MembershipReport from './report-content/MembershipReport';
import UserReport from './report-content/UserReport'
import PublishingReport from './report-content/PublishingReport';
import EngagementReport from './report-content/EngagementReport';
import CommentNotesReport from './report-content/CommentNotesReport';
import { REPORTS_NAV } from '../../../constants/constants';
import { AuthContext } from '../../../context/AuthContext';


const ReportsTab = () => {
  const auth = useContext(AuthContext)
  const userEmail = auth.user.email;
  const dispatch = useDispatch();
  const { journalclub } = useSelector((state) => state);
  const { reportNav } = useSelector((state) => state.jcreports);

  return (
    <>   
      {(reportNav === REPORTS_NAV.ROOT) && (<RootReport userEmail={userEmail} />)}
      {(reportNav === REPORTS_NAV.MEMBERSHIP) && (<MembershipReport userEmail={userEmail} />)}
      {(reportNav === REPORTS_NAV.MEMBER) && (<UserReport userEmail={userEmail} />)}
      {(reportNav === REPORTS_NAV.PUBLISH) && (<PublishingReport userEmail={userEmail} />)}
      {(reportNav === REPORTS_NAV.ENGAGEMENT) && (<EngagementReport userEmail={userEmail} />)}
      {(reportNav === REPORTS_NAV.FLAGGED) && (<CommentNotesReport userEmail={userEmail} />)}
    </>
  )
}

export default ReportsTab