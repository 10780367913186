import React from 'react'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReportsTab from './ReportsTab';
import { Box, Typography } from '@mui/material';

function CheckingRootReport() {
  const theme = useTheme();
  const notTablet = useMediaQuery(theme.breakpoints.up(1024));
  return (
    <>
      {
        notTablet ? <ReportsTab /> : (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "150px" }}>
            <Typography variant="H2_RALEWAY"  >
              Please use a desktop to access this feature
            </Typography>
          </Box>)
      }
    </>
  )
}

export default CheckingRootReport