import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../context/AuthContext';
import LandingPage from "../pages/LandingPage";
import { fetchFeedInBackground } from '../state/actions/feed';


const AuthorisedRoute = ({ children }) => {
    const auth = useContext(AuthContext);
    const { isAuthenticated, setAuthenticated, user } = auth;
    const { feed } = useSelector((state) => state);
    const dispatch = useDispatch()

    const location = useLocation();
    const isJCPageOrJcArticlePage = location.pathname.replace(/\//, '').startsWith("jc");
    const isArticlePage = location.pathname.replace(/\//, '').startsWith("article");

    useEffect(()=>{
        if(isAuthenticated === true){
            dispatch(fetchFeedInBackground(user.email,feed.articleList))
        } 
    },[isAuthenticated])

    if (isAuthenticated === false && isJCPageOrJcArticlePage === false && isArticlePage === false) {
        window.location = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
        return (
            <LandingPage />
        )
    }

    return (
        <>
            {children}
        </>
    )
}

export default AuthorisedRoute
