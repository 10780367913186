import React, { useContext } from 'react';
import { useSelector } from "react-redux";
import { FormControl, Typography, Box, Link, Select, Divider, Menu, Button, IconButton, Avatar, Hidden } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { JC_COLORS } from '../../styles/constants';
import { styled } from '@mui/system';
import { logout } from '../../utils';
import { PRIVACY_POLICY_PDF, TERMS_OF_SERVICE_PDF } from '../../constants/constants';
import { UserDataContext } from '../../context/UserDataContext';

const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 700,
    height: '2.625rem',
    minWidth: '382px',
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

const MyProfileLink = styled(Link)(({ theme }) => ({
    cursor: 'pointer',
    color: "#262626",
    margin: "9px 8px 0px 16px",
    textDecoration: 'none',
    borderBottom: `1px solid ${JC_COLORS.LIGHT_GREY}`,
    paddingBottom: '0.75rem',

    [theme.breakpoints.up('md')]: {
        minWidth: '254px',
        fontFamily: 'Raleway',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '24px',
    },
    [theme.breakpoints.down('md')]: {
        minWidth: "154px",
        ...theme.typography.BODY_COPY1_RALEWAY_MOBILE,

    }
}));

const LogoutText = styled(Typography)(({ theme }) => ({
    marginTop: "16px",
    padding: "0 21px",
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
        fontFamily: 'Raleway',
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '24px',
    },
    [theme.breakpoints.down('md')]: {
        ...theme.typography.BODY_COPY1_RALEWAY_MOBILE,

    }
}));

const ProfileMenu = ({ width, height }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { isLoading, data, isError, error, isFetching } = useContext(UserDataContext);
    


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Hidden mdDown>
                <Box sx={{ ml: "15px", mr: "5px" }}>
                    <Avatar
                        variant="square"
                        alt="Benjamin Scricia"
                        src={""}
                        sx={{
                            width: width,
                            height: height,
                            backgroundColor: JC_COLORS.JC_WHITE,
                            color: JC_COLORS.JC_ICON,
                            borderRadius: '2px!important'
                        }}
                    ></Avatar>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: "5px",
                        ml: "5px",

                    }}
                >
                    <Typography sx={{ height: "16px" }}>Me</Typography>
                    <ArrowDropDownIcon onClick={handleClick} sx={{ cursor: 'pointer' }} />


                </Box>
            </Hidden>

            <Hidden mdUp>
                <Avatar
                    variant="square"
                    alt="Benjamin Scricia"
                    onClick={handleClick}
                    sx={{
                        width: width,
                        height: height,
                        backgroundColor: JC_COLORS.JC_WHITE,
                        color: JC_COLORS.JC_ICON,
                        borderRadius: '2px!important'
                    }}

                ></Avatar>
            </Hidden>


            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', minHeight: "0.688rem" }}>
                    <MyProfileLink href="/profile">
                        My Profile
                    </MyProfileLink>
                    <MyProfileLink href="/activity">
                        My Activity
                    </MyProfileLink>
                    {
                        data?.data?.superAdmin && (
                            <>
                                <MyProfileLink href="/user-activity">
                                    View All User Activity
                                </MyProfileLink>
                                <MyProfileLink href="/manage-journal-clubs">
                                    Manage Journal Clubs
                                </MyProfileLink>
                            </>
                        )
                    }


                    <Hidden mdUp>
                        <MyProfileLink href={TERMS_OF_SERVICE_PDF} target="_blank">
                            Terms of Service
                        </MyProfileLink>

                        <MyProfileLink href={PRIVACY_POLICY_PDF} target="_blank">
                            Privacy Policy
                        </MyProfileLink>
                    </Hidden>



                    <LogoutText
                        color="primary"
                        onClick={
                            () => {
                                //console.log("logging out", user.userDetails.id);
                                logout();
                            }
                        }
                    >
                        LOG OUT</LogoutText>

                    <Hidden mdUp>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: "16px 21px 18px 21px" }}>
                            <Typography variant="BODY_COPY2_RALEWAY" sx={{ color: JC_COLORS.JC_ICON }}>Journal Club © 2022</Typography>
                            <Typography variant="BODY_COPY2_RALEWAY" sx={{ color: JC_COLORS.JC_ICON, textDecoration: "underline", marginTop: "5px" }}>NCBI's Disclaimer and Copyright Notice</Typography>
                        </Box>
                    </Hidden>

                </Box>
            </Menu>
        </>





    )
}

export default ProfileMenu
