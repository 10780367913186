import { React, useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Box, Grid, Avatar, Typography, Select, FormControl, Link, Divider, Button } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';


import { LogoAndTitle } from "../../assets/SVG";
import { JCLogoNew } from '../../assets';
import { ReactComponent as LogoWhite } from "../../assets/SVG/NEW_ICONS/LogoWhite.svg";
import { JC_COLORS } from '../../styles/constants';
import SearchBoxArticles from '../components/SearchBoxArticles';

import ProfileMenu from './ProfileMenu';
import { AuthContext } from '../../context/AuthContext';
import { UserDataContext } from '../../context/UserDataContext';
import { MEMBERSHIP_STATUS } from '../../constants/constants';
import { useNavigate } from 'react-router';
import { getJournalClubs } from '../../state/actions/journalclub';
import HelpMenu from "./HelpMenu";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        border: '2px solid #838383',
        borderRadius: '3px',
        width: "23vw",
        marginRight: '10px',
        '& .MuiOutlinedInput-root': {
            height: '28px',

            backgroundColor: JC_COLORS.LIGHT_GREY,
            borderRadius: '3px',
            "& > *": {
                alignItems: 'center',
            },
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: JC_COLORS.JC_RED,
            },
            '&.Mui-focused fieldset': {
                borderColor: JC_COLORS.JC_RED,
            },
        },
    },
    paper: {
        backgroundColor: JC_COLORS.LIGHT_GREY,
        border: `2px solid ${JC_COLORS.JC_ICON}`,
        borderRadius: '6px',
    },
    icon: {
        fill: '#FFFFFF'
    },
    select: {
        height: "1rem",
        width: "2.5rem",
        "&:before": {
            borderColor: '#FFFFFF',
            border: '0px',
            opacity: '0',
        },
        "&:after": {
            borderColor: '#FFFFFF',
            border: '0px',
        },
        "&:hover": {
            borderColor: '#FFFFFF',
            border: '0px',
        },
        "*": {
            display: 'flex',
            flexDirection: 'column',
        }

    },

});

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: JC_COLORS.JC_RED,
    fontFamily: 'Raleway',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
        marginLeft: '11px',
        padding: '8px 22px',
        width: '142px',
        height: '42px',
        borderRadius: '4px',
        fontSize: '15px',
        lineHeight: '26px',
        '&:hover': {
            backgroundColor: 'white',
            color: JC_COLORS.JC_RED,
        }

    },
    [theme.breakpoints.down('md')]: {
        marginLeft: '5px',
        padding: '4px 10px',
        width: '62px',
        height: '32px',
        fontSize: '12px',
        lineHeight: '20px',
    }
}));




const NavbarDesktop = () => {
    const classes = useStyles();
    const { user, keywords, sources,journalclub } = useSelector((state) => state);
    const { isLoading, data, isError, error } = useContext(UserDataContext);
    let { jcList } = journalclub;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const { isAuthenticated } = auth;

    const handleLogoClick = () => {
        if (isLoading === false && journalclub.isFetchingJournalClubs === false) { 
            const lastVistedJC = data.data.lastVisitedJC
            const finalJCList = journalclub.jcList.filter(JC => JC.membershipStatus === MEMBERSHIP_STATUS.JOINED)
            const lastVisitedJCList = finalJCList.filter(JC => JC.JCID === lastVistedJC)

            if(lastVisitedJCList.length > 0){
            // Last visited JC where user status is joined 
            navigate(`/jc/${lastVisitedJCList[0].urlSlug}`)                                               
            }
            else if(finalJCList.length === 0){
            // If Joined Journal clubs are empty
            navigate('/join-a-journal-club')
            }
            else {
            // Select first journal club 
            navigate(`/jc/${finalJCList[0].urlSlug}`)
            }        
        }      
    }

    useEffect(() => {
        if(userEmail){
          dispatch(getJournalClubs(userEmail));
        }
    }, [])

    return (
		<Box>
			<AppBar
				position="fixed"

				//sx={{ zIndex: (theme) => theme.zIndex.drawer  }}
			>
				<Toolbar>
					<Grid
						container
						direction="row"
						justifyContent="center"
						//justifyContent={userEmail ? "center" : "flex-start"}
						marginRight={{ lg: "24px", xl: "0px" }}>
						<Grid
							item
							md={3.5}
							lg={3}
							xl={2}
							sx={{
								display: "flex",
								flexiDirection: "row",
								justifyContent: "flex-start"
							}}>
							<JCLogoNew
								width="220px"
								height="40px"
								onClick={handleLogoClick}
							/>
						</Grid>
						{userEmail && (
							<Grid
								item
								md={8}
								lg={9}
								xl={8}
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "flex-end"
								}}>
								<SearchBoxArticles placeholder={"Search"} />
								<ProfileMenu />
								<HelpMenu />
							</Grid>
						)}
						{(userEmail === null ||
							userEmail === undefined ||
							isAuthenticated === false) && (
							<Grid
								item
								md={8}
								lg={9}
								xl={8}
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "flex-end"
								}}>
								{/* <StyledButton
                                        variant="contained"
                                        onClick={
                                            () => {
                                                window.location = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
                                            }
                                        }
                                        
                                    >
                                        Login
                                    </StyledButton>
                                    <StyledButton
                                        variant="contained"
                                        onClick={
                                            () => {
                                                window.location = `${process.env.REACT_APP_AWS_DOMAIN}/signup?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;

                                            }
                                        }
                                        
                                    >
                                        Signup
                                    </StyledButton> */}
							</Grid>
						)}
					</Grid>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default NavbarDesktop;


