import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import { Card, Paper, Hidden, InputAdornment, TextField, Tabs, tabsClasses, styled } from '@mui/material';
import { CenteredContainer, KeyWordChip } from './../common/components';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import JCContentTitle from './JCDetails/components/JCContentTitle';
import { useMediaQuery, Chip, CircularProgress } from '@mui/material';
import {
    Box,
    Typography,
    Grid,
    Button,
    FormLabel,
    MenuItem,
    FormControl,
    Select, Autocomplete, InputLabel, Divider, FormControlLabel, RadioGroup, Radio
} from '@mui/material';
import { positions } from '@mui/system';
import OutlinedInput from '@mui/material/OutlinedInput';
import CustomDropdown from './JCDetails/components/CustomDropdown';
import { useSelector, useDispatch } from "react-redux";
import { JC_COLORS } from './../styles/constants';
import * as api from "./../api";
import { AuthContext } from './../context/AuthContext';
import { Row, Spacer, TopDrawer, CenteredActionButton } from "./../common/components";
import { fetchAreaOfInterest, fetchJournalClubs, fetchUserDetails } from '../api';
import { PublicJCIcon, PrivateJCIcon } from './../assets/SVG';
import { SortIconMobile } from '../assets';
import SearchJC from "./../common/components/SearchJC";
import { SPECIAL_AREAS_OF_INTEREST } from '../constants/constants';
import { profileCheck } from '../utils';
import { getUserDetails } from '../state/actions/user';
import AllJournalClubsList from '../common/containers/AllJournalClubsList';

const useStyles = makeStyles({
    inputlabel: {
        fontFamily: 'Raleway!important',
        fontStyle: 'normal!important',
        fontWeight: '700!important',
        lineHeight: '26px!important',
        fontSize: '16px!important',
        marginBottom: '10px!important',
    },
    inputlabelMobile: {
        fontFamily: 'Raleway!important',
        fontStyle: 'normal!important',
        fontWeight: '700!important',
        fontSize: '12px!important',
        minHeight: '22px!important',
        marginBottom: '6px!important'
    },
    textfield: {
        width: '100%',
        '& .MuiInputBase-input': {
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '26px',
            padding: '10px',

        },
    },
    textfieldMobile: {
        width: '100%',
        '& .MuiInputBase-input': {
            fontFamily: 'Raleway',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '12px',
            padding: '6px',
        },
        '& .MuiSelect-select': {
            height: '21px!important',
            paddingTop: '4px',
            paddingBottom: '4px'
        }
    }
})
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(mobileMatch, topic, topicName, theme) {
    return {
        fontSize: mobileMatch ? '12px' : '18px',
        fontWeight:
            topicName?.indexOf(topic) === -1
                ? '600'
                : '700',
        fontFamily: 'Raleway',
        minHeight: '22px'
    };
}
const MainCard = styled(Paper)(({ theme }) => ({
    textAlign: 'left',
    backgroundColor: JC_COLORS.JC_WHITE,
    [theme.breakpoints.up('md')]: {
        maxWidth: '70vw',
        padding: "32px 32px 32px 32px",
        //height: '80vh'

    },
    [theme.breakpoints.down('md')]: {
        padding: '80px 16px 80px 16px',
        margin: 0,
        borderRadius: 'None',
        boxShadow: 'None'
    }

}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    borderRadius: '3px',
    background: 'white',
    //   border: `1px solid ${JC_COLORS.JC_ICON}`,
    [theme.breakpoints.up('md')]: {
        width: '55%',
        '& .MuiInputBase-root': {
            height: '42px',
            fontFamily: 'Raleway!important',
        },
        '& .MuiOutlinedInput-root': {
            fontFamily: 'Raleway!important',
        }
    },
    [theme.breakpoints.down('md')]: {
        borderRadius: '0px',

        width: '75vw',
        '& .MuiInputBase-root': {
            height: '30px',
            padding: '0px',
        },
        '& .MuiOutlinedInput-root': {
            fontFamily: 'Raleway!important',
            fontSize: '12px'
        },
        '& .MuiInputAdornment-positionStart': {
            marginLeft: '4px'
        },
        '& .MuiInputAdornment-positionEnd': {
            marginRight: '4px'
        }
    },

}));



const BecomeMember = () => {
    const classes = useStyles()
    const theme = useTheme()
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const navigate = useNavigate(); 
    const location = useLocation();
    const page = location.pathname.replace(/\//, '');

    const mobileMatch = useMediaQuery(theme.breakpoints.down('md'));
    const [fetchingAreasOfInterest, setFetchingAreasOfInterest] = useState(false);
    const [itemsList, setItemsList] = useState([]);
    const [fetchingMemberJCList, setFetchingMemberJCList] = useState(false);
    const [memberJCList, setMemberJCList] = useState([]);
    const [topic, setTopic] = useState("")
    const [open, setOpen] = useState(false)
    const [sortLabel, setSortLabel] = useState('All')
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);




    function getAreaOfInterestValue(data) {
        var myAreaOfInterest = []
        if (data.length > 1) {
            myAreaOfInterest = data.filter(item => item !== SPECIAL_AREAS_OF_INTEREST.GENERAL_MEDICINE)[0]
        }
        else {
            myAreaOfInterest = data[0]
        }
        return myAreaOfInterest
    }



    useEffect(() => {
        if (mobileMatch) {
            document.body.style.backgroundColor = JC_COLORS.JC_WHITE
        }

        //getAreaOfInterest()
        setFetchingAreasOfInterest(true);

        fetchUserDetails(auth.user.email)
            .then(
                response => {
                    //console.log(response.data);
                    if (response.data) {
                        let areaOfInterestValue = getAreaOfInterestValue(response.data.areasOfInterest)
                        setTopic(areaOfInterestValue)
                        //setTopic(response.data.areasOfInterest) 
                    } else {
                        setTopic("")
                    }
                }
            )
            .then(
                data => {
                    fetchAreaOfInterest()
                        .then(response => {
                            setItemsList(response.data.AreaOfInterestList);
                            setFetchingAreasOfInterest(false);
                        }
                        ).catch(error => {
                            const errorMsg = error.message
                            setFetchingAreasOfInterest(false);
                        })

                }

            )
            .catch(error => {
                const errorMsg = error.message
            });
    }, []);

    

    const handleTopicChange = (event) => {
        setTopic(event.target.value);

    }
    const handleSortOpen = () => {
        setOpen(true)
    }
    const handleSortClose = () => {
        setOpen(false)
    }
    const applySort = () => {
        setOpen(false)
    }
    const JCAdded = () => {
        return true
    }
    return (
        <React.Fragment>

            <MainCard>
                <Hidden mdDown>
                    <Box mb={4}>
                        <Typography variant="H1_BITTER" color="primary" sx={{ fontWeight: '700!important' }} >Join a Journal Club</Typography>
                    </Box>

                    {
                        fetchingAreasOfInterest === false && (
                            <Box sx={{ display: 'inline-block', position: 'absolute' }} mr={1} mt={0}>
                                <InputLabel className={mobileMatch ? classes.inputlabelMobile : classes.inputlabel} sx={{ color: "#262626" }}>Your Selected Topic:</InputLabel>
                            </Box>
                        )
                    }

                </Hidden>
                <Hidden mdUp>

                    <Box>
                        <InputLabel className={mobileMatch ? classes.inputlabelMobile : classes.inputlabel} sx={{ color: "#262626" }}>Your Selected Topic:</InputLabel>
                    </Box>

                </Hidden>

                {
                    fetchingAreasOfInterest && <CenteredContainer><CircularProgress /></CenteredContainer>
                }
                {
                    fetchingAreasOfInterest === false && (
                        <>
                            <Box mb={3} mt={-1}>
                                <Box sx={{ display: { xs: 'block', md: 'inline-block' }, width: { xs: '100%', md: '300px' } }} ml={{ xs: 0, md: 25 }}>
                                    <FormControl className={mobileMatch ? classes.textfieldMobile : classes.textfield}>
                                        <Select
                                            value={topic}
                                            onChange={handleTopicChange}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            placeholder="select"
                                            displayEmpty
                                            MenuProps={MenuProps}
                                            required
                                        >
                                            <MenuItem
                                                value={""}
                                                style={getStyles(mobileMatch, "All Medical Topics", topic, theme)}
                                            >
                                                All Medical Topics
                                            </MenuItem>
                                            {itemsList && itemsList.map((item) => {
                                                return (
                                                    <MenuItem
                                                        key={item}
                                                        value={item}
                                                        style={getStyles(mobileMatch, item, topic, theme)}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                )
                                                // if (item.toLowerCase() === SPECIAL_AREAS_OF_INTEREST.ALL_MEDICAL_TOPICS.toLowerCase()) {
                                                //     return (
                                                //         <MenuItem
                                                //             key={item}
                                                //             value=""
                                                //             style={getStyles(mobileMatch, item, topic, theme)}
                                                //         >
                                                //             { SPECIAL_AREAS_OF_INTEREST.ALL_MEDICAL_TOPICS }
                                                //         </MenuItem>
                                                //     )
                                                // } else {
                                                //     return (
                                                //         <MenuItem
                                                //             key={item}
                                                //             value={item}
                                                //             style={getStyles(mobileMatch, item, topic, theme)}
                                                //         >
                                                //             {item}
                                                //         </MenuItem>
                                                //     )
                                                // }
                                            }

                                            )}

                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Divider />
                            <AllJournalClubsList 
                                topic={topic}
                            />
                         


                            {/*Create A Journal Club */}
                            {
                                fetchingMemberJCList === false && (
                                    <Box
                                        sx={{
                                            backgroundColor: '#EF5B50', borderRadius: '4px', color: "#FFFFFF",
                                            // width: '100%', 
                                            // position: 'absolute', 
                                            // bottom: '24px',
                                            //display: 'none'


                                        }} mt={{ xs: 2, md: 4 }}>
                                        <Box p={{ xs: 2, md: 3 }}>
                                            <Box>
                                                <Typography variant='H2_BITTER'>
                                                    Didn't find the journal club you wanted?
                                                </Typography>
                                            </Box>

                                            <Box mt={{ xs: 1, md: 1 }}>
                                                <Typography variant='H3_RALEWAY'>
                                                    Consider creating your own Journal Club
                                                </Typography>
                                            </Box>
                                            <Box mt={{ xs: 3, md: 3 }}>
                                                <Button
                                                    variant='contained'
                                                    //href="/create-a-journal-club"
                                                    sx={{
                                                        backgroundColor: '#FFFFFF',
                                                        color: '#EF5B50',
                                                        fontFamily: 'Raleway',
                                                        fontSize: {
                                                            xs: '12px',
                                                            md: '12px'
                                                        },
                                                        fontWeight: '700',
                                                        "&.MuiButtonBase-root:hover": {
                                                            bgcolor: "#FFFFFF"
                                                        }
                                                    }} 
                                                    size="medium"
                                                    onClick={() => {
                                                        navigate("/create-a-journal-club")
                                                    }}
                                                    >
                                                        CREATE JOURNAL CLUB
                                                </Button>
                                            </Box>
                                            <Box mt={{ xs: 2, md: 1.5 }}>
                                                <Typography variant='BODY_COPY2_RALEWAY' sx={{ fontWeight: '700!important' }}>
                                                    Please Note:
                                                </Typography>
                                                <Typography variant='BODY_COPY2_RALEWAY' sx={{ fontWeight: '500' }} ml={0.6}>
                                                    Creation of a Journal Club is subject to approval.
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>

                                )
                            }

                        </>
                    )
                }
            </MainCard>


            {/**Sort drawer for mobile */}
            <TopDrawer
                open={open}
                onOpen={handleSortOpen}
                onClose={handleSortClose}
            >


                <Box sx={{ padding: '5rem 1rem 1rem 1rem' }}>

                    <FormControl>

                        <RadioGroup
                            value={sortLabel}
                            //onChange={(event) => setOrderBy(event.target.value)}
                            onChange={(event) => {
                                setSortLabel(event.target.value)
                            }}
                        >

                            <FormControlLabel
                                value="All"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: "1rem",
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="BODY_COPY2_RALEWAY">All</Typography>
                                }
                            />
                            <FormControlLabel
                                value="Public"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: "1rem",
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="BODY_COPY2_RALEWAY">Public</Typography>
                                }
                            />
                            <FormControlLabel
                                value="Private"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: "1rem",
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="BODY_COPY2_RALEWAY">Private</Typography>
                                }
                            />


                        </RadioGroup>
                    </FormControl>

                    <Spacer height={"2.1rem"} />
                    <Spacer height={"4rem"} />
                    <CenteredActionButton 
                        onClick={
                            () => applySort()
                        }
                    >
                        Sort Now
                    </CenteredActionButton>
                </Box>
            </TopDrawer>
        </React.Fragment >
    );



}

export default BecomeMember

const JournalClubItem = ({ jcName, isPublic, JCID, urlSlug }) => {
    return (
        <Box onClick={() => window.location.href = `jc/${urlSlug}`} sx={{ cursor: 'pointer' }}>
            <Box sx={{ display: 'inline-block', position: 'absolute', paddingTop: '2px' }}>
                {isPublic ?
                    <PublicJCIcon width="18px" height="14px" />
                    :
                    <PrivateJCIcon width="18px" height="14px" />
                }
            </Box>
            <Box sx={{ display: 'inline-block' }} ml={3}>
                <Typography variant="H3_RALEWAY">
                    {jcName}
                </Typography>
            </Box>
        </Box>
    )
}


// <StyledTextField
// placeholder={"Search by Journal Club or URL"}
// autoComplete='off'
// InputProps={{
//     startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
//     endAdornment: (<InputAdornment position="end"><CloseIcon onClick={() => setSearchTerm("")} /></InputAdornment>)
// }}
// value={searchTerm}
// onChange={(e) => setSearchTerm(e.target.value)}
// ></StyledTextField>
// <Hidden mdDown>
//     <Button
//         variant="outlined"
//         sx={{marginLeft: '11px',
//             padding: '8px 22px',
//             width: '77px',
//             height: '42px',
//             borderRadius: '4px',
//             backgroundColor: 'white',
//             color: JC_COLORS.JC_RED,
//             fontFamily: 'Raleway',
//             fontWeight: 700,
//             fontSize: '15px',
//             lineHeight: '26px',
//             '&:hover': {
//             backgroundColor: 'white',
//             color: JC_COLORS.JC_RED,
//             }
//         }}
//         onClick={() => {
//         addSearchTerm(searchTerm)
//         setSearchTerm("")}}>
//     Add
//     </Button>
// </Hidden>
