import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery} from '@mui/material';
import { Typography } from '@mui/material';
import { JCLogo , JCLogoMobile,JCLogoNew } from '../../assets';

const useStyles = makeStyles({
    
/* CSS for Journal club shape(temporary) */
    jcShape: {
        display: 'block',
        width: '100%',
        marginTop: '-20px',
        color: '#ef5b50',
        filter: `url('#jcShapef')`,
        '&:before':{
            content: '',
            display: 'block',
            paddingTop: '15%',
            zIndex: '9',
            background: 'currentColor',
            clipPath: 'polygon(0 0, 100% 0, 97% 100%, 3% 100%)'

        }
    },
    innerBox : {
        zIndex: '9990000',
        position: 'relative'
    },
    jcSvg : {
    visibility: 'hidden',
    position: 'absolute'
  }
    
})

function JCHeader(props) {
    const classes = useStyles()
    const theme = useTheme()
    const mobileMatch = useMediaQuery(theme.breakpoints.down('sm'))
    const tabMatch = useMediaQuery(theme.breakpoints.down('md'))

    const handleLogoClick = () => {
        window.location = "https://journal.club"
    }

    const greet = () =>{
        if(props.greet){

        return (
            <Typography variant="H1_BITTER" component='h1' color="#FFFFFF" sx={{fontWeight:'700!important'}}>
                Welcome to
            </Typography>
            )
        }
    }
    return (
        <React.Fragment>
            <Box className={ mobileMatch ? "jcShape-mobile" : tabMatch ? "jcShape-tab": "jcShape"}>
                <Box className={classes.innerBox} sx={{
                    marginTop: {
                        xs:'-20%',
                        sm: props.greet ? '-14%' : '-11%',
                        md: props.greet ? '-10%' : '-8%'
                    }
                }}>
                    {greet()}
                    <a href={`${process.env.REACT_APP_LANDING_PAGE_URL}`}>
                        <JCLogoNew width="45%" height={props.greet && mobileMatch ? '26px' : '60px'} fill="#FFFFFF" />
                    </a>
                </Box>
            </Box>

            {/**Jc Shape svg */}
            <svg className={classes.jcSvg} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
                    <filter id="jcShapef"><feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />    
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                        <feComposite in="SourceGraphic" in2="jcShapef" operator="atop"/>
                    </filter>
                </defs>
            </svg>
        </React.Fragment>
    )
}

export default JCHeader