import React, { createContext, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { AuthContext } from './AuthContext';
import { fetchUserDetails } from '../api';
import { useJcUserData } from '../common/hooks/useJcUserData';

const UserDataContext = createContext();

const useUserDataContext = () => {
    const context = useContext(UserDataContext);
    if (context === undefined) {
        throw new Error('useContext Not Defined!');
    }
    return context;
};

export { useUserDataContext, UserDataContext };

export const fetchJcUserData = async (userEmail) => {
    if (userEmail) {
        return fetchUserDetails(userEmail);
    }

}

const UserDataContextProvider = (props) => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;

    const onSuccess = (data) => {
        //console.log("Succesful query", data)
    }

    const onError = (error) => {
        console.log("Error occured while making query", error);
    }

    const { isLoading, data, isError, error, isFetching } = useJcUserData(onSuccess, onError, userEmail)


    return (
        <UserDataContext.Provider value={{ isLoading, data, isError, error, isFetching }}>
            {props.children}
        </UserDataContext.Provider>
    )

}

export default UserDataContextProvider;
