import React, { useState, useRef, useEffect, useContext } from 'react'
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Card, List, Box, ListItem, ListItemIcon, ListItemButton, ListItemText, Divider, Icon, Typography, Link, Button, Drawer, useTheme, CircularProgress } from '@mui/material';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircleIcon from '@mui/icons-material/Circle';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import Row from "./Row";
import Spacer from "./Spacer";
import SidebarLink from './SidebarLink';

import { JC_COLORS } from '../../styles/constants';
import { AllIcon, Feed, FilledFeed, JCIcon, FilledJCIcon, SaveIcon, SaveIconOutlined, SaveIconFilled, CMEIcon, AddCircle } from "../../assets/SVG";
import { calcViewHeight, calcViewWidth,stringToColor } from '../../utils';

import { getJournalClubs, setJCContent } from '../../state/actions/journalclub';
import { AuthContext } from '../../context/AuthContext';

//import { Feed, FilledFeed } from "../../assets/NEW_ICONS";
import { ReactComponent as AddContent} from '../../assets/AddContent.svg';
import { ReactComponent as AddContentFilled}  from '../../assets/AddContentFilled.svg';
import { ReactComponent as CreateArticle}  from '../../assets/CreateArticle.svg';
import { ReactComponent as CreateArticleFilled}  from '../../assets/CreateArticleFilled.svg';

import JC_Avatar from './JC_Avatar';
import CenteredContainer from './CenteredContainer';
import GuestSidebar from '../../pages/GuestPages/GuestSidebar';
import { TERMS_OF_SERVICE_PDF, PRIVACY_POLICY_PDF, USER_ROLES, JC_CONTENT } from '../../constants/constants';
import { JCSmallDialog, JCSmallDialogTitle } from './JCDialogBox';
import { JCChip } from '../../pages/JCDetails/jc-content/report-content/ReportHelpers';
import { createNewDenovoArticle } from '../../state/actions/article';




const useStyles = makeStyles({

    card: {
        //paddingTop: "10px",
        //paddingBottom: "22px",
        //height: '80vh',
        //minHeight: "70vh",
        //maxHeight: '75vh',
        backgroundColor: JC_COLORS.JC_WHITE,
        //overflowY: 'scroll',
        //'&::-webkit-scrollbar': { width: 0 }


    }
});

const SidebarTitle = styled(Typography)(({ theme }) => ({
    fontFamily: "BITTER",
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    maxWidth: "60%",
    overflowWrap: 'break-word',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
}))

const Sidebar = () => {
    const classes = useStyles();
    const theme = useTheme();
    const authData = useContext(AuthContext);
    const { email: userEmail } = authData.user;
    const { isAuthenticated } = authData; 
    const mobileMatch = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { page } = useParams();

    const location = useLocation();
    const content = location.pathname.replace(/\//, '');
    const [value, setValue] = useState(content);

    const { state } = useLocation();




    const [showJournalClubs, setShowJournalClubs] = useState(false);
    const { journalclub,user } = useSelector((state) => state);
    const [createContent,setCreateContent] = useState(false);
    const [createContentJCList,setCreateContentJCList] = useState([])
    const [selectedJC,setSelectedJC]= useState("")
    let { isFetchingJournalClubs, jcList } = journalclub;
    //jcList = jcList.filter(obj => (obj.currentUser && obj.currentUser.length > 0));
    jcList = jcList.filter(
        obj => (
            obj.membershipStatus && (
                obj.membershipStatus === 'Joined' || obj.membershipStatus === 'Invited'
            )
        )
    );

    const getJCIconColor = (JCID, title) => {
        return (
          jcList.filter((JC) => JC.JCID === JCID)[0]?.iconColor ??
          stringToColor(title ?? '')
        );
      };    
    
    useEffect(()=>{
        var tempList = []
        tempList = jcList.filter(
            obj => (
                obj.membershipStatus && obj.membershipStatus === 'Joined' && (
                    obj.currentUser === USER_ROLES.CONTRIBUTOR || 
                    obj.currentUser === USER_ROLES.ADMINISTRATOR || 
                    obj.currentUser === USER_ROLES.COURSE_DIRECTOR
                )
            )
        )
        setCreateContentJCList(tempList)
        if(tempList.length > 0){
            setSelectedJC(tempList[0].JCID)
        } 
    },[journalclub,user])
    useEffect(() => {
        //console.log(content)
        if (content.startsWith('article')) {
            setValue(state?.source ?? "");
            dispatch(getJournalClubs(userEmail));
            setShowJournalClubs(true);
        } else {
            setValue(content ?? "")
            dispatch(getJournalClubs(userEmail));
            setShowJournalClubs(true);
        }

    }, [content, state]);

    useEffect(() => {
        var lastScrollTop = 0;
        function changeTop() {
            var rightNavElement = document.querySelector(".leftnav");
            var currScroll = this.scrollY
            if (rightNavElement !== null) {
                if (currScroll > lastScrollTop && rightNavElement.clientHeight > 602) {
                    rightNavElement.style.top = (602 - rightNavElement.clientHeight + 64) + "px"
                } else {
                    rightNavElement.style.top = "64px";
                }
                lastScrollTop = currScroll
            }
        }
        if (!mobileMatch) {
            window.addEventListener("scroll", changeTop, false);
        }
        return () => window.removeEventListener("scroll", changeTop, false);
    }, []);

    if (
        (userEmail === null || userEmail === undefined) || 
        isAuthenticated === false
      ) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginLeft: '11px',
                    positon: 'sticky',
                    flexGrow: '1',
                    flexBasis: '300px',
                    paddingBottom: '20px',
                    position: 'sticky',
                    alignSelf: 'start',
                    overflow: 'scroll',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' },
                }}
                className="leftnav"
            >

                <Card className={classes.card}>
                    <GuestSidebar />
                </Card>
            </Box>
        )
    } else {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginLeft: '11px',
                    positon: 'sticky',
                    flexGrow: '1',
                    flexBasis: '300px',
                    paddingBottom: '20px',
                    position: 'sticky',
                    alignSelf: 'start',
                    overflow: 'scroll',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' }
                }}
                className="leftnav"
            >

                <Card className={classes.card}>

                    <Box
                        sx={{
                            padding: "0px 0px 0px 0px",
                            height: "100%",
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': { width: 0 },
                            '&::-webkit-scrollbar-thumb': { color: 'white' },
                            scrollbarWidth: 'none',
                        }}>

                        <SidebarLink
                         selected={false}   
                        >
                            {["feed","search"].includes(value) ? <AddContentFilled width={"22"} height={"27"} /> : <AddContent width={"22"} height={"27"} />}
                            <SidebarTitle
                                sx={{ padding: "0 0 0 19px" }}
                                color={(["feed","search"].includes(value) ? JC_COLORS.JC_RED : JC_COLORS.JC_BLACK)}
                            >
                            Create Content
                            </SidebarTitle>                          
                        </SidebarLink>
                        { createContentJCList && createContentJCList.length > 0 &&
                            <SidebarLink
                            selected={false}
                            onClick={() => setCreateContent(true)}
                            >
                                <Box pl={3}>
                                    {createContent ? <CreateArticleFilled width={"22"} height={"27"} /> : <CreateArticle width={"22"} height={"27"} /> }
                                </Box> 
                                    <SidebarTitle
                                        sx={{ padding: "0 0 0 19px" }}
                                        color={(createContent ? JC_COLORS.JC_RED : JC_COLORS.JC_BLACK)}
                                    >
                                    Original Article
                                    </SidebarTitle>                           
                            </SidebarLink>
                        }
                        <SidebarLink
                            selected={value === 'feed'}
                            onClick={() => navigate("/feed")}
                        >
                            <Box pl={3}>
                            {value === "feed"? <FilledFeed width={"22"} height={"27"} /> : <Feed width={"22"} height={"27"} />}
                            </Box> 
                                <SidebarTitle
                                    sx={{ padding: "0 0 0 19px" }}
                                    color={(value === 'feed' ? JC_COLORS.JC_RED : JC_COLORS.JC_BLACK)}
                                >
                                From Custom Feed
                                </SidebarTitle>                           
                        </SidebarLink>

                        <SidebarLink
                            selected={value === 'search'}
                            onClick={() => navigate("/search")}
                        >
                            <Box pl={3}>
                                <SearchIcon width={"22"} height={"27"} sx={{color: value === 'search' ? JC_COLORS.JC_RED : JC_COLORS.JC_ICON }}/>
                            </Box> 
                                <SidebarTitle
                                    sx={{ padding: "0 0 0 19px" }}
                                    color={(value === 'search' ? JC_COLORS.JC_RED : JC_COLORS.JC_BLACK)}
                                >
                                From PubMed 
                                </SidebarTitle>                           
                        </SidebarLink>
                        
                        <SidebarLink
                            selected={false}
                            onClick={() => { }}
                            sx={{
                                borderLeft: value.startsWith('jc') ? `10px solid ${JC_COLORS.JC_RED}` : `10px solid ${JC_COLORS.JC_WHITE}`,
                                borderBottom: "2px solid white"

                            }}
                        >
                            <Spacer width="2px" />
                            {value.startsWith('jc') && <FilledJCIcon width={"16"} height={"21"} />}
                            {value.startsWith('jc') === false && <JCIcon width={"16"} height={"21"} />}
                            <SidebarTitle
                                sx={{ padding: "0 0 0 24px", }}
                                color={JC_COLORS.JC_BLACK}
                                onClick={
                                    () => {
                                        if (showJournalClubs) {
                                            setShowJournalClubs(false)
                                        } else {
                                            setShowJournalClubs(true)
                                        }

                                    }
                                }
                            >Journal Clubs</SidebarTitle>
                            {
                                showJournalClubs ? (
                                    <KeyboardArrowUpIcon
                                        sx={{
                                            width: "27px",
                                            height: "24px",
                                            color: JC_COLORS.JC_ICON
                                        }}

                                        onClick={() => setShowJournalClubs(false)}
                                    />
                                ) : (
                                    <KeyboardArrowDownIcon
                                        sx={{
                                            width: "27px",
                                            height: "24px",
                                            color: JC_COLORS.JC_ICON
                                        }}
                                        onClick={() => setShowJournalClubs(true)}
                                    />
                                )
                            }

                        </SidebarLink>
                        {
                            showJournalClubs && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Button
                                        startIcon={<AddCircle width={"30"} height={"30"} />}
                                        variant="outlined"
                                        sx={{
                                            ...theme.typography.H3_RALEWAY,
                                            textTransform: 'none',
                                            fontWeight: 700,
                                            margin: "16px 4px",
                                            width: '90%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                        }}
                                        onClick={() => navigate("/join-a-journal-club")}
                                    >
                                        Join a Journal Club
                                    </Button>
                                    {
                                        isFetchingJournalClubs && (
                                            <Box sx={{ marginTop: "54px", width: "100%" }}>
                                                <CircularProgress />
                                            </Box>)
                                    }
                                    {
                                        isFetchingJournalClubs === false && (
                                            <Box
                                                sx={{
                                                    margin: "0 auto",
                                                    width: "100%",

                                                }}>
                                                <ListOfJournalClubs
                                                    value={value}
                                                    jcList={jcList}
                                                />
                                            </Box>
                                        )
                                    }


                                </Box>
                            )


                        }
                        <SidebarLink
                            selected={value === 'saved'}
                            onClick={() => navigate("/saved")}
                            sx={{ borderTop: `1px solid ${JC_COLORS.LIGHT_GREY}` }}
                        >
                            {value === 'saved' ? <SaveIconOutlined width={"20"} height={"25"} /> : <SaveIcon width={"20"} height={"25"} />}
                            <SidebarTitle
                                sx={{ padding: "0 0 0 24px" }}
                                color={(value === 'saved' ? JC_COLORS.JC_RED : JC_COLORS.JC_BLACK)}
                            >Saved</SidebarTitle>
                        </SidebarLink>
                        <Box sx={{ height: "32px" }}></Box>



                    </Box >

                </Card>
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0
                    }}
                >
                    <SidebarFooter />
                </Box>
                <JCSmallDialog
                    open={createContent}
                    onClose={() => setCreateContent(false)}
                    aria-labelledby="create-content-title"
                >
                    <JCSmallDialogTitle id="create-content-title" onClose={() => setCreateContent(false)}>
                    </JCSmallDialogTitle>
                    <DialogContent>
                        <Box sx={{ textAlign: 'left' }} pl={{xs:0,md:5}}>            
                            <Typography variant="H3_RALEWAY" sx={{ fontWeight: '600' }} gutterBottom> 
                                Select the Journal Club to create original articles   
                            </Typography>
                            <Box mt={1}>
                                <RadioGroup
                                aria-labelledby="create-content-radio-buttons-group"
                                name="create-content-radio-buttons-group"
                                value={selectedJC}
                                onChange={(event) => setSelectedJC(event.target.value)}>

                                {createContentJCList && createContentJCList.map((JC) =>
                                    <Box key={JC.JCID}>
                                        <Box sx={{ display: 'inline-block' }}>
                                            <FormControlLabel value={JC.JCID} control={<Radio />} label={<JCChip jcName={JC.name} jcColor={getJCIconColor(JC.JCID,JC.name)} bgColor="#F3F3F3" maxWidth="35ch"/>} />
                                        </Box>
                                    </Box>
                                )}
                                </RadioGroup>
                            </Box>
                            <Box mt={1}>
                            <DialogActions sx={{ justifyContent: 'center' }}>
                                <Box sx={{ display: 'inline-block' }}>
                                <Button variant='outlined' size="small" color="primary" sx={{fontFamily:'Raleway',fontWeight:'700',textTransform:'none'}}
                                        startIcon={<EditIcon />} 
                                        onClick={() => {
                                        const userDetail = user.userDetails
                                        const finalJC = jcList.filter(JC => JC.JCID === selectedJC)[0]
                                        setCreateContent(false)
                                        dispatch(createNewDenovoArticle(userEmail,userDetail.firstName,userDetail.lastName,selectedJC,finalJC.urlSlug,navigate))
                                    }}>
                                    Start Writing
                                </Button>
                                </Box>
                                <Box sx={{ display: 'inline-block' }} mr={2}>
                                <Button variant='contained' size="small" color="primary" sx={{fontFamily:'Raleway',fontWeight:'700',textTransform:'none'}} 
                                    onClick={() => {
                                        var finalJC = jcList.filter(JC => JC.JCID === selectedJC)[0]
                                        setCreateContent(false)
                                        navigate(`/jc/${finalJC.urlSlug}`)
                                        setTimeout(()=>{
                                            dispatch(setJCContent(JC_CONTENT.DE_NOVO_ARTICLES))
                                        },0)   
                                    }}
                                >
                                    View All Articles
                                </Button>
                                </Box>
                            </DialogActions>
                            </Box>
                        </Box>
                    </DialogContent>

                </JCSmallDialog>
            </Box>
        );

    }



}
export default Sidebar;

// const SidebarLink = ({ selected, text, onClick, children }) => {
//     return (
//         <Box
//             sx={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 alignItems: 'center',
//                 cursor: 'pointer',
//                 padding: "0 0 0 32px",
//                 margin: "0px",
//                 height: "48px",
//                 borderBottom: `1px solid ${JC_COLORS.LIGHT_GREY}`,
//                 borderLeft: `10px solid ${selected ? JC_COLORS.JC_RED : JC_COLORS.JC_WHITE}`,
//                 backgroundColor: `${text === 'All' ? '#dddddd' : JC_COLORS.JC_WHITE}`,
//                 opacity: `${text === 'All' ? 0.5 : 1}`
//             }}
//             onClick={onClick}
//         >
//             {/* <Icon width={"25px"} height={"25px"} /> */}
//             {children}

//         </Box>
//     )
// }

const SidebarFooter = () => {
    const location = useLocation();
    const page = location.pathname.replace(/\//, '');

    return (
        <Box mt={4}
            sx={{
            }}>
            <Box>
                <Box sx={{ display: 'inline-block' }} mx={1}>
                    <Link
                        href={PRIVACY_POLICY_PDF}
                        target="_blank"
                        sx={{
                            color: JC_COLORS.JC_BLACK,
                            textDecorationColor: `${page === "privacy-policy" ? JC_COLORS.JC_RED : JC_COLORS.JC_ICON}`
                        }}>
                        <Typography
                            variant="BODY_COPY2_RALEWAY"
                            color={page === "privacy-policy" ? JC_COLORS.JC_RED : JC_COLORS.JC_ICON}
                            gutterBottom
                        >
                            Privacy Policy
                        </Typography>
                    </Link>
                </Box>
                <Box sx={{ display: 'inline-block' }} mx={1}>
                    <Link
                        href={TERMS_OF_SERVICE_PDF}
                        target="_blank"
                        sx={{
                            color: JC_COLORS.JC_BLACK,
                            textDecorationColor: `${page === "terms-of-service" ? JC_COLORS.JC_RED : JC_COLORS.JC_ICON}`
                        }}>
                        <Typography
                            variant="BODY_COPY2_RALEWAY"
                            color={page === "terms-of-service" ? JC_COLORS.JC_RED : JC_COLORS.JC_ICON}
                            gutterBottom
                        >
                            Terms of Service
                        </Typography>
                    </Link>
                </Box>

            </Box>

            <Box mt={1}>
                <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_ICON} gutterBottom>
                    Journal Club &copy; 2022
                </Typography>
            </Box>

            <Box mt={1}>
                <Link href='#' sx={{ color: JC_COLORS.JC_BLACK, textDecorationColor: JC_COLORS.JC_ICON }}>
                    <Typography variant="BODY_COPY2_RALEWAY" color={JC_COLORS.JC_ICON} gutterBottom>
                        NCBI’s Disclaimer and Copyright Notice
                    </Typography>
                </Link>

            </Box>


        </Box>

    )
}

const ListOfJournalClubs = ({ value, jcList }) => {
    const navigate = useNavigate()
    return (
        <>
            {
                [...jcList].map(obj => (
                    <SidebarLink
                        key={obj.JCID}
                        selected={value === `jc/${obj.urlSlug}`}
                        onClick={() => navigate(`/jc/${obj.urlSlug}`)}
                        sx={{
                            borderBottom: "2px solid white",
                            width: "100%",
                            padding: "0",
                            margin: '0px 22px 8px 0px',
                            display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
                            borderLeft: `10px solid ${JC_COLORS.JC_WHITE}`,
                        }}
                    >
                        <Box
                            key={obj.JCID}
                            sx={{
                                bgcolor: JC_COLORS.JC_WHITE,
                                width: '100%',
                                borderRadius: '4px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}
                            onClick={() => navigate(`/jc/${obj.urlSlug}`)}
                        >
                            <Box
                                sx={{
                                    bgcolor: JC_COLORS.JC_WHITE,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    marginLeft: '8px',

                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                                        // ml: '56px', 


                                    }}>
                                    {obj.isCMEAccrediting === true ? <CMEIcon /> : <Box sx={{ width: "35px", height: "18px" }}></Box>}
                                    <JC_Avatar
                                        iconColor={obj.iconColor}
                                        title={obj.name}
                                        //size={25}
                                        sx={{
                                            //margin: "0 0 0 7px",
                                            width: "25px",
                                            height: "25px",
                                            fontSize: '14px',
                                            marginLeft: "5px",
                                        }}
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        marginLeft: '7px',
                                        width: '160px',
                                        fontFamily: "Bitter",
                                        fontWeight: 500,
                                        fontSize: "1rem",
                                        lineHeight: "1.5rem",
                                        textAlign: 'left',
                                        alignSelf: 'center',
                                        justifySelf: 'start',
                                        overflowWrap: 'break-word',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        color: value.startsWith(`jc/${obj.urlSlug}`) ? JC_COLORS.JC_RED : JC_COLORS.JC_BLACK
                                    }}
                                >
                                    {obj.name}
                                </Typography>
                                {
                                    obj.membershipStatus !== 'Invited' && (
                                        <Typography
                                            sx={{

                                                color: `${JC_COLORS.JC_ICON}`,
                                                fontFamily: 'Raleway',
                                                fontWeight: 600,
                                                fontSize: "0.8rem",
                                                lineHeight: "1rem",
                                                textAlign: 'left',
                                                justifySelf: 'end',
                                                marginLeft: '7px',
                                            }}
                                        >
                                            {obj.numberOfPublishedArticles ?? 0}

                                        </Typography>
                                    )
                                }
                                {
                                    obj.membershipStatus === 'Invited' && (
                                        <CircleIcon
                                            sx={{
                                                width: "12px",
                                                height: "12px",
                                                color: JC_COLORS.JC_RED,
                                                alignSelf: 'center',
                                                justifySelf: 'end',
                                                marginLeft: '7px',
                                            }}
                                        />
                                    )
                                }

                            </Box>
                            {
                                (
                                    obj.membershipStatus !== 'Invited' && (
                                        obj.currentUser === "Administrator" ||
                                        obj.currentUser === 'Course Director' ||
                                        obj.currentUser === 'Contributor'
                                    )
                                ) && (
                                    <Typography
                                        sx={{
                                            color: `${JC_COLORS.JC_ICON}`,
                                            fontFamily: 'Raleway',
                                            fontWeight: 600,
                                            fontSize: "0.8rem",
                                            lineHeight: "1rem",
                                            padding: '5px 0px 0px 0px',
                                            textAlign: 'left',
                                            marginLeft: "80px",
                                        }}>
                                        {obj.inDiscussion} in discussion
                                    </Typography>
                                )
                            }
                            {
                                obj.membershipStatus === 'Invited' && (
                                    <Typography
                                        sx={{
                                            color: JC_COLORS.JC_WHITE,
                                            backgroundColor: JC_COLORS.JC_RED,
                                            borderRadius: '2px',
                                            fontStyle: 'italic',
                                            fontFamily: 'Raleway',
                                            fontWeight: 700,
                                            fontSize: "0.8rem",
                                            lineHeight: "1.3rem",
                                            padding: '2px 7px 2px 4px',
                                            textAlign: 'left',
                                            display: 'inline-block',
                                            textTransform: 'none',
                                            width: 'fit-content',
                                            height: 'fit-content',
                                            margin: "4px 0px 0px 80px"

                                        }}

                                    >
                                        Pending Action
                                    </Typography>
                                )
                            }




                        </Box>
                    </SidebarLink>
                ))
            }
        </>
    );
}

// const journalClubs = [

//     {
//         "name": "Harvard Journal Club for Oncology",
//         "JCID": "J000123456",
//         "url": "harvard.journal.club/oncology",
//         "isCMEAccrediting": true,
//         "isPublic": false,
//         "readersAllowedComments": true,
//         "frequencyOfPublication": "Monthly",
//         "inDiscussion": 7
//     },
//     {
//         "name": "Harvard Journal Club for Oncology One",
//         "JCID": "J000123457",
//         "url": "harvard.journal.club/oncology",
//         "isCMEAccrediting": false,
//         "isPublic": false,
//         "readersAllowedComments": true,
//         "frequencyOfPublication": "Monthly",
//         "inDiscussion": 8
//     },
//     {
//         "name": "Harvard Journal Club for Oncology Twoo",
//         "JCID": "J000123458",
//         "url": "harvard.journal.club/oncology",
//         "isCMEAccrediting": false,
//         "isPublic": false,
//         "readersAllowedComments": true,
//         "frequencyOfPublication": "Monthly",
//         "inDiscussion": 32
//     },
//     {
//         "name": "Harvard Journal Club for Oncology Three",
//         "JCID": "J000123459",
//         "url": "harvard.journal.club/oncology",
//         "isCMEAccrediting": true,
//         "isPublic": false,
//         "readersAllowedComments": true,
//         "frequencyOfPublication": "Monthly",
//         "inDiscussion": 52
//     },
//     {
//         "name": "Harvard Journal Club for Oncology Four",
//         "JCID": "J000123460",
//         "url": "harvard.journal.club/oncology",
//         "isCMEAccrediting": false,
//         "isPublic": false,
//         "readersAllowedComments": true,
//         "frequencyOfPublication": "Monthly",
//         "inDiscussion": 112
//     },
//     {
//         "name": "Harvard Journal Club for Oncology Five",
//         "JCID": "J000123461",
//         "url": "harvard.journal.club/oncology",
//         "isCMEAccrediting": false,
//         "isPublic": false,
//         "readersAllowedComments": true,
//         "frequencyOfPublication": "Monthly",
//         "inDiscussion": 1
//     }


// ];

/* <SidebarLink
                 key={obj.JCID}
                 selected={value === `jc/${obj.urlSlug}`}
                 onClick={() => navigate(`/jc/${obj.urlSlug}`)}
                 sx={{
                     borderBottom: "2px solid white",
                     width: "100%",
                     padding: "0",
                     marginBottom: "10px",
                     display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
                     borderLeft: `10px solid ${JC_COLORS.JC_WHITE}`,


                 }}
             >
                 <Box
                     sx={{
                         display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                         // ml: '56px',
                         mt: "3px",

                     }}>
                     {obj.isCMEAccrediting === true ? <CMEIcon /> : <Box sx={{ width: "35px", height: "18px" }}></Box>}
                     <JC_Avatar
                         iconColor={obj.iconColor}
                         title={obj.name}
                         //size={25}
                         sx={{
                             //margin: "0 0 0 7px",
                             width: "25px",
                             height: "25px",
                             fontSize: '14px',
                             marginLeft: "5px",
                         }}
                     />
                 </Box>
                 <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginLeft: '7px' }}>
                     <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                         <SidebarTitle
                             sx={{
                                 fontFamily: "Bitter",
                                 fontWeight: 500,
                                 width: '268px',
                                 textAlign: 'left',
                                 color: value === `jc/${obj.urlSlug}` ? JC_COLORS.JC_RED : JC_COLORS.JC_BLACK
                             }}
                         >
                             {obj.name}
                         </SidebarTitle>
                         {
                             obj.membershipStatus !== 'Invited' && (
                                 <Typography
                                     sx={{
                                         color: `${JC_COLORS.JC_ICON}`,
                                         fontFamily: 'Raleway',
                                         fontWeight: 600,
                                         fontSize: "0.8rem",
                                         lineHeight: "1rem",
                                         padding: '5px 0px 0px 0px',
                                         textAlign: 'left',
                                         marginLeft: "7px",
                                     }}
                                 >
                                     {obj.numberOfPublishedArticles ?? 0}
                                 </Typography>
                             )
                         }
                         {
                             obj.membershipStatus === 'Invited' && (
                                 <CircleIcon
                                     sx={{
                                         width: "12px",
                                         height: "12px",
                                         color: JC_COLORS.JC_RED,
                                         alignSelf: 'center',
                                     }}
                                 />
                             )
                         }
                     </Box>
                     {
                         obj.membershipStatus === 'Invited' && (
                             <Typography
                                 sx={{
                                     color: JC_COLORS.JC_WHITE,
                                     backgroundColor: JC_COLORS.JC_RED,
                                     borderRadius: '2px',
                                     fontStyle: 'italic',
                                     fontFamily: 'Raleway',
                                     fontWeight: 700,
                                     fontSize: "0.8rem",
                                     lineHeight: "1.3rem",
                                     padding: '2px 7px 2px 4px',
                                     textAlign: 'left',
                                     display: 'inline-block',
                                     textTransform: 'none',
                                     width: 'fit-content',
                                     height: 'fit-content',
                                 }}

                             >
                                 Pending Action
                             </Typography>
                         )
                     }
                     {
                         (obj.membershipStatus !== 'Invited') && (obj.currentUser === "Administrator" || obj.currentUser === 'Course Director' || obj.currentUser === 'Contributor') && (
                             <Typography
                                 sx={{
                                     color: `${JC_COLORS.JC_ICON}`,
                                     fontFamily: 'Raleway',
                                     fontWeight: 600,
                                     fontSize: "0.8rem",
                                     lineHeight: "1rem",
                                     padding: '5px 0px 0px 0px',
                                     textAlign: 'left',
                                 }}>
                                 {obj.inDiscussion} in discussion
                             </Typography>
                         )
                     }
                 </Box>
             </SidebarLink> */

// const JournalClubRow = ({ value, obj }) => {
//     const navigate = useNavigate()
//     return (
//         <SidebarLink
//             key={obj.urlSlug}
//             selected={value === `jc/${obj.urlSlug}`}
//             onClick={() => navigate(`/jc/${obj.urlSlug}`)}
//         >
//             <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                 {obj.isCMEAccrediting ? <CMEIcon /> : <Box sx={{ width: "35px", height: "18px" }}></Box>}
//                 <JC_Avatar title={obj.name} size={25} sx={{ margin: "0 0 0 7px" }} />
//                 <SidebarTitle
//                     color={(value === `jc/${obj.urlSlug}` ? JC_COLORS.JC_RED : JC_COLORS.JC_BLACK)}
//                     sx={{ padding: "0 28px 0 11px", width: "204px" }}
//                 >{obj.name}</SidebarTitle>
//                 <Typography
//                     sx={{
//                         color: `${JC_COLORS.JC_ICON}`,
//                         fontFamily: 'Raleway',
//                         fontWeight: '800',
//                         fontSize: "1rem",
//                         lineHeight: "1.2rem",
//                     }}>{obj.inDiscussion}</Typography>

//             </Box>
//         </SidebarLink>
//     )
// }

// const labels = {
//     0: { value: 'all', text: 'All', icon: <AllIcon /> },
//     1: { value: 'feed', text: 'Feed', icon: <Feed /> },
//     2: { value: 'jc', text: 'Journal Clubs', icon: <JCIcon /> },
//     3: { value: 'saved', text: 'Saved', icon: <SaveIcon /> },
//     // 4: { value: 'search', text: 'Search', icon: <SearchIcon /> },

// }
// const useIsOverflow = (ref, callback) => {
//     const [isOverflow, setIsOverflow] = React.useState(undefined);

//     React.useLayoutEffect(() => {
//         const { current } = ref;

//         const trigger = () => {
//             const hasOverflow = current.scrollHeight > current.clientHeight;

//             setIsOverflow(hasOverflow);

//             if (callback) callback(hasOverflow);
//         };

//         if (current) {
//             if ('ResizeObserver' in window) {
//                 new ResizeObserver(trigger).observe(current);
//             }
//             trigger();
//         }
//     }, [callback, ref]);

//     return isOverflow;
// };

/* {
                        showJournalClubs && (
                            <Box>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AllIcon />
                                        </ListItemIcon>
                                        <ListItemText>JC1</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AllIcon />
                                        </ListItemIcon>
                                        <ListItemText>JC2</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AllIcon />
                                        </ListItemIcon>
                                        <ListItemText>JC3</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AllIcon />
                                        </ListItemIcon>
                                        <ListItemText>JC4</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AllIcon />
                                        </ListItemIcon>
                                        <ListItemText>JC5</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AllIcon />
                                        </ListItemIcon>
                                        <ListItemText>JC6</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AllIcon />
                                        </ListItemIcon>
                                        <ListItemText>JC7</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AllIcon />
                                        </ListItemIcon>
                                        <ListItemText>JC8</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <AllIcon />
                                        </ListItemIcon>
                                        <ListItemText>JC8</ListItemText>
                                    </ListItem>



                                </List>

                            </Box>
                        )
                    } */

                    // useEffect(() => {
                    //     if (scrollRef.current.scrollHeight > scrollRef.current.clientHeight) {
                    //         setIsOverflow(true);
                    //     } else {
                    //         setIsOverflow(false);
                    //     }
                    // }, [scrollRef]);


// <Card className={classes.card}>

        //     <List
        //         // ref={scrollRef}
        //         sx={{
        //             // "& > *": {
        //             //     borderLeft: '10px solid #fffffe',
        //             // },
        //             // "& > *:hover": {
        //             //     //color: JC_COLORS.JC_RED
        //             //     borderLeft: `10px solid ${JC_COLORS.JC_RED}`,
        //             // },
        //             '&& .Mui-selected, && .Mui-selected:hover': {
        //                 //borderLeft: `10px solid ${JC_COLORS.JC_RED}`,
        //                 color: JC_COLORS.JC_RED,
        //                 backgroundColor: '#FFFFFF'
        //             }

        //         }}
        //     >
        //         <ListItemButton
        //             disabled={true}
        //             selected={value === 'all'}
        //             //onClick={() => setValue('all')}
        //             sx={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 borderLeft: value === 'all' ? `10px solid ${JC_COLORS.JC_RED}` : null,
        //             }}
        //         >
        //             <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        //                 <AllIcon
        //                     width={"40"}
        //                     height={"30"}
        //                     fill={value === 'all' ? JC_COLORS.JC_RED : null}
        //                 />
        //             </ListItemIcon>

        //             <ListItemText>
        //                 <Typography fontFamily="BITTER" fontWeight="bold" fontSize="1rem" lineHeight="1.5rem">
        //                     ALL
        //                 </Typography>
        //             </ListItemText>
        //         </ListItemButton>

        //         <Divider />

        //         <ListItemButton
        //             selected={value === 'feed'}
        //             onClick={() => navigate("/feed")}
        //             sx={{
        //                 display: 'flex',
        //                 flexDirection: 'row',
        //                 borderLeft: value === 'feed' ? `10px solid ${JC_COLORS.JC_RED}` : `10px solid #FFFFFFF`
        //             }}


        //         >
        //             <Link sx={{ textDecoration: "none", color: 'inherit', cursor: "pointer", display: 'flex', justifyContent: 'flex-end' }} href="/feed">
        //                 <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
        //                     {value === 'feed' && <FilledFeed />}
        //                     {value !== 'feed' && <Feed />}
        //                 </ListItemIcon>

        //                 <ListItemText>
        //                     <Typography fontFamily="BITTER" fontWeight="bold" fontSize="1rem" lineHeight="1.5rem">
        //                         Feed
        //                     </Typography>
        //                 </ListItemText>

        //             </Link>




        //         </ListItemButton>


        //         <Divider />

        //         <ListItemButton
        //             disabled={true}
        //             selected={value === 'jc'}
        //             //onClick={() => setValue('jc')}
        //             sx={{ display: 'flex', flexDirection: 'row' }}
        //         >
        //             <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        //                 <JCIcon
        //                     width={"40"}
        //                     height={"30"}
        //                     fill={value === 'jc' ? JC_COLORS.JC_RED : null}
        //                     stroke={value === 'jc' ? JC_COLORS.JC_RED : null}
        //                 />

        //             </ListItemIcon>
        //             <ListItemText>
        //                 <Row>
        //                     <Typography fontFamily="BITTER" fontWeight="bold" fontSize="1rem" lineHeight="1.5rem">
        //                         Journal Clubs
        //                     </Typography>

        //                     {/* {
        //                                 showJournalClubs ? (
        //                                     <KeyboardArrowUpIcon onClick={() => setShowJournalClubs(false)} />
        //                                 ) : (
        //                                     <KeyboardArrowDownIcon
        //                                         onClick={() => setShowJournalClubs(true)} />
        //                                 )
        //                             } */}
        //                 </Row>

        //             </ListItemText>
        //             {/* <ListItemIcon>
        //                         <AddCircle />

        //                     </ListItemIcon> */}
        //         </ListItemButton>
        //         <Divider />

        //         <ListItemButton
        //             selected={value === 'saved'}
        //             sx={{ display: 'flex', flexDirection: 'row' }}
        //             onClick={() => navigate('/saved')}
        //         >
        //             <Link sx={{ textDecoration: "none", color: 'inherit', cursor: "pointer", display: 'flex', justifyContent: 'flex-end' }} href="/saved">
        //                 <ListItemIcon>
        //                     {
        //                         value === 'saved' ?
        //                             <SaveIconOutlined width={"40"} height={"30"} /> :
        //                             <SaveIcon width={"40"} height={"30"} />
        //                     }
        //                 </ListItemIcon>

        //                 <ListItemText>
        //                     <Typography fontFamily="BITTER" fontWeight="bold" fontSize="1rem" lineHeight="1.5rem">
        //                         Saved Articles
        //                     </Typography>
        //                 </ListItemText>

        //             </Link>


        //         </ListItemButton>

        //         <Divider />
        //     </List>

        //     {/* Sidebar footer */}
        //     <SidebarFooter />



        // </Card >