import React, {useState} from 'react';
import { Box, Typography, FormControl, FormControlLabel, RadioGroup, Radio, Grid } from '@mui/material';

import { OPTIONS_SORT_ARTICLES_BY_TYPE } from '../../../constants/constants';
import { JC_COLORS } from '../../../styles/constants';


const CustomRadioArticleType = ({ orderByLabel, changeArticleTypeHandler }) => {
    
    return (
        <FormControl>

            <RadioGroup
                name="sort-by-type"
                onChange={ (event) => changeArticleTypeHandler(event.target.value) }
                value={orderByLabel}
            >
                {
                    Object.keys(OPTIONS_SORT_ARTICLES_BY_TYPE).map((el, i) => (
                        <FormControlLabel
                            key={i}
                            value={el}
                            control={
                                <Radio
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: "1rem",
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography
                                    sx={{
                                        fontFamily: 'Raleway',
                                        fontWeight: '600',
                                        fontSize: '0.75rem',
                                        lineHeight: '0.875rem'
                                    }}
                                >{el}</Typography>
                            }
                        />
                    ))
                }



            </RadioGroup>
        </FormControl>
    )
}

export default CustomRadioArticleType