import * as api from "../../api"; 

export const SEARCH_RESULTS_SOURCES = "SEARCH_RESULTS_SOURCES"; 
export const SEARCH_RESULTS_SOURCES_SUCCESS = "SEARCH_RESULTS_SOURCES_SUCCESS"; 
export const SEARCH_RESULTS_SOURCES_FAILURE = "SEARCH_RESULTS_SOURCES_FAILURE"; 

//Action Creators
export const setSources = (data) => {
    return {
        type: "SET_SOURCES", 
        payload: data
    }

} 

export const sourceClicked = (data) => {
    //console.log(data, "****")
    return {
        type: "SOURCE_CLICKED", 
        payload: data
    }
}

export const editSources = () => {
    return {
        type: "EDIT_SOURCES"
    }
}

export const removeAnySources = () => {
    return {
        type: "REMOVE_ANY_SOURCES"
    }
}

export const clearAllSources = () => {
    return {
        type: "CLEAR_ALL_SOURCES"
    }
}

export const sourceRemoved = (data) => {
    return {
        type: "SOURCE_REMOVED", 
        payload: data
    }
}

export const sourceAdded = (data) => {
    return {
        type: "SOURCE_ADDED", 
        payload: data
    }
}


// export const getSearchResultsSources = (search) => async (dispatch) => {
//     try {
//         const {data} = await api.searchSources(search)
//         dispatch({
//             type: SEARCH_RESULTS_SOURCES_SUCCESS, 
//             payload: data
//         })
//     } catch(error) {
//         console.log(error)
//     }
// }

// export const getSources = () => async (dispatch) => {
//     try {
//         const { data } = await api.fetchSources(); 
//         const action = {
//             type: "GET_SOURCES",
//             payload: data
//         }
//         dispatch(action); 
//     } catch(error) {
//         console.log(error.message); 
//     }
// }