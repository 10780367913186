import React from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/system';

const StyledContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
        width: '600px',
        margin: "70px auto",
    },
    [theme.breakpoints.down('md')]: {
        height: '70vh',
        margin: '0 auto',
        ...theme.typography.BODY_COPY1_RALEWAY_MOBILE,
    }
}));

const CenteredContainer = ({children}) => {
  return (
    <StyledContainer>
        {children}
    </StyledContainer>
  )
}

export default CenteredContainer