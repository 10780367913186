import React ,{useEffect} from 'react'

import { Box, Paper, Card, Typography, Select, MenuItem, FormGroup, FormControlLabel, Switch, Grid, useMediaQuery } from "@mui/material";
import { Hidden } from '@mui/material';
import { useTheme } from '@mui/material/styles';


import { Sidebar,BottomAppBar } from '../common/components';
import NavbarDesktop from "../common/containers/NavbarDesktop";
import NavbarMobile from "../common/containers/NavbarMobile";
import PrivacyPolicy from '../common/components/PrivacyPolicy';

function PrivacyPolicyPage() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if(!matches){
        document.body.style.backgroundColor = '#FFFFFF'
    }
    else{
        document.body.style.backgroundColor = '#F3F3F3'
    }
  })
  
  return (
    <PrivacyPolicy />
    // <>
    //   {
    //     matches ?
    //       <>
    //         <NavbarDesktop />
    //         <Box
    //           sx={{
    //             display: 'flex',
    //             flexDirection: 'row',
    //             paddingTop: '6rem', 
    //             marginLeft:'1.5rem',
    //             paddingBottom: '3rem', 
    //           }}
    //         >
    //           <Hidden mdDown>
    //           <Box>
    //             <Sidebar />
    //           </Box>
    //           </Hidden>
              
    //           <Box>
    //             <PrivacyPolicy />
    //           </Box>

    //         </Box>
    //       </>

    //       :
    //       <div>
    //         <NavbarMobile title={"Privacy Policy"} />
    //         <Box sx={{marginTop:'5rem'}}>
    //             <PrivacyPolicy />
    //         </Box>
    //         <BottomAppBar />
    //       </div>

    //   }

    // </>





  )
}

export default PrivacyPolicyPage