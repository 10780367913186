import { FEED_DEFAULT_DATE_RANGE, OPTIONS_SORT_ARTICLES_BY_TIME } from "../../constants/constants"
import { 
    FETCHING_USER_FEED, 
    FETCH_USER_FEED_SUCCESS, 
    FETCH_NEXT_PAGE_USER_FEED_SUCCESS, 
    FETCH_USER_FEED_FAILURE, 
    UPDATE_FFED_QUERY_PARAMETERS, 
    SET_CONTENT, 
} from "../actions/feed"

const initialState = {
    isFetchingFeed: false,
    queryParameters: {
        pageNumber: 1,
        unreadOnly: false,
        dateRange: OPTIONS_SORT_ARTICLES_BY_TIME[FEED_DEFAULT_DATE_RANGE], 
        dateRangeLabel: FEED_DEFAULT_DATE_RANGE, 
        orderBy: 'publicationdate', 
        orderByLabel: "Most Recent",
        keyword: "", 
        source: "", 
    },
    numberOfUnreadArticles: 0,
    hasMoreArticles: false, 
    nextPage: 1,
    articleList: null,
    content: 'feed', 
    error: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_USER_FEED:
            return {
                ...state,
                isFetchingFeed: true,
            }
        case FETCH_USER_FEED_SUCCESS:
            return {
                ...state,
                isFetchingFeed: false,
                articleList: action.payload.articleList,
                numberOfUnreadArticles: action.payload.numberOfUnreadArticles, 
                hasMoreArticles: action.payload.next ? true: false,
                nextPage : action.payload.next
            }
        case FETCH_NEXT_PAGE_USER_FEED_SUCCESS:
            const { articleList, numberOfUnreadArticles, next } = action.payload; 
            let updatedList = [...new Set([...state.articleList, ...articleList])];
            return {
                ...state,
                isFetchingFeed: false,
                articleList: updatedList,
                numberOfUnreadArticles: numberOfUnreadArticles,
                hasMoreArticles: next ? true: false, 
                nextPage : action.payload.next
            }
        case FETCH_USER_FEED_FAILURE:
            return {
                ...state,
                isFetchingFeed: false,
                error: action.payload,
            }
        case UPDATE_FFED_QUERY_PARAMETERS:
            let newQueryParams = {...state.queryParameters, ...action.payload}
            localStorage.setItem("appState", JSON.stringify(newQueryParams))
            return {
                ...state,
                queryParameters: {...newQueryParams}, 
            };
        case SET_CONTENT: 
            return {
                ...state, 
                content: action.payload,
            }
        default:
            return state;

    }

}