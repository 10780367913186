import React from 'react';
import { Helmet } from 'react-helmet';
import og1 from '../assets/og_images/og1.png';

const JCAppHelmet = ({ children }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Journal Club</title>
      <link rel="canonical" href={`${process.env.REACT_APP_URI}`} />
      <meta
        name="description"
        content="The first networked platform where institutions, associations and individual experts can create and disseminate ongoing monetizable content on any medical or biomedical topic."
      />

      <meta property='og:type' content="website" />
      <meta property="og:title" content="Journal Club" />
      <meta property="og:description" content="The first networked platform where institutions, associations and individual experts can create and disseminate ongoing monetizable content on any medical or biomedical topic."/>
      <meta property="og:image" content={og1} />
    </Helmet>
  );
};

export default JCAppHelmet;
