import React, { useContext } from 'react';
import { useEffect } from 'react';
import { Box, Hidden } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import { getArticle, setSaved, markArticleAsRead, getJournalClubArticle, openDenovoArticleInEditMode, resetDenovoArticleInEditMode, resetDenovoLockState } from '../state/actions/article';
import { getJournalClubDetails, getJCUserDetails, getJournalClubs } from '../state/actions/journalclub';
import { Sidebar } from '../common/components';
import ArticleDetailedView from "../common/components/ArticleDetailedView";
import BottomDrawer from '../common/components/BottomDrawer';
import Error404Component from '../common/components/Error404';
import NavbarDesktop from "../common/containers/NavbarDesktop";
import NavbarMobile from "../common/containers/NavbarMobile";
import { AuthContext } from "../context/AuthContext";
import { getCommentAndNotes } from '../state/actions/comment';
import UserProfileCheck from './UserProfileCheck';
import { getUserDetails } from '../state/actions/user';
import ArticleDenovoNew from './ArticleDenovoNew';
import GuestViewArticleDetailed from './GuestPages/GuestViewArticleDetailed';
import GuestNavbarMobile from './GuestPages/GuestNavbarMobile';

function ArticleDetailed() {
  const auth = useContext(AuthContext);
  const userEmail = auth.user.email;
  const { isAuthenticated } = auth; 
  const theme = useTheme();
  const mobileMatch = useMediaQuery(theme.breakpoints.down('md'))
  const keywordClass = mobileMatch ? "article-box" : "article-box-desktop"

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, articleData, error } = useSelector((state) => state.article);
  const { isFetchingDetails } = useSelector((state) => state.journalclub);
  const user = useSelector((state) => state.user);
  const { PMID, JCID } = useParams()
  const JCPage = JCID ? JCID === 'J00000000' ? false : true : false

  const location = useLocation();



  useEffect(() => {

    if (userEmail) {
      dispatch(getUserDetails(userEmail));
      if (JCPage) {
        dispatch(getJournalClubArticle(JCID, PMID, userEmail))
        dispatch(getJournalClubDetails(JCID, userEmail))
        dispatch(getJournalClubs(userEmail))
        dispatch(getJCUserDetails(JCID, userEmail))
        // dispatch(getCommentAndNotes(JCID,PMID,userEmail))
      }
      else {
        dispatch(getArticle(PMID, userEmail));

      }

      if (location?.state?.editIconOnDenovoCardClicked) {
        //console.log("seting denovo mode")
        navigate(location.pathname, {});
        dispatch(openDenovoArticleInEditMode())
      } else {
        //console.log("reseting denovo mode")
        dispatch(resetDenovoArticleInEditMode());
        dispatch(resetDenovoLockState());
      }


    }

  }, [PMID, JCID])



  return (isLoading && userEmail) ? (
    <Box>
      <CircularProgress color='primary' sx={{ position: 'fixed', right: { xs: '45%', md: '40%' }, top: '40%' }} />
    </Box>
  ) :
    (
      <UserProfileCheck>
        <Box className='article-box-desktop'>
          <Box mt={{ xs: 0, md: 0 }}>
            {
              userEmail && (
                <ArticleDetailedView isJCPage={JCPage} />
              )
            }
            {
              (
                (userEmail === null || userEmail === undefined) ||
                (isAuthenticated === false)
              ) && (
                <GuestViewArticleDetailed isJCPage={JCPage} />
              )
            }

            <Hidden mdUp>
              <BottomDrawer
                isDisabled={
                  (
                    (userEmail === null || userEmail === undefined) ||
                    (isAuthenticated === false)
                  ) ? true : false}
              />
            </Hidden>
          </Box>
        </Box>
      </UserProfileCheck>
    )
}

export default ArticleDetailed
