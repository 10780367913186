import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

export const JCSmallDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
      width: '80%',
      height: 'auto',
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      width: '30%',
      height: 'auto',
    }
  },
}));

export const JCSmallDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        pb: { xs: 1, sm: 0, md: 0 },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }} {...other}>
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
            cursor: 'pointer',
          }}
        />
      ) : null}
    </DialogTitle>
  );
};

JCSmallDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


export const JCDialogLarge = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root':{
    padding: '8px 24px'
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper':{
      width:'100%',
      maxWidth: '100%',
      height:'100%',
      maxHeight:'100%',
      borderRadius:'0px',
      margin:0,
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper':{
      width:'88%',
      height:'85%',
      maxWidth: '900px',
    }
  },
}));

export const JCDialogMedium = styled(Dialog)(({ theme }) => ({
'& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root':{
        padding: '8px 24px'
    },
    '& .MuiIconButton-root':{
        padding: '0px'
    },
    [theme.breakpoints.down('md')]: {
    '& .MuiDialog-paper': {
        width: '80%',
        height: 'auto',
    }
    },
    [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
        width: '30%',
        height: 'auto',
    }
},
}));

export const JCDialogLargeTitle = (props) => {
const { children, onClose, ...other } = props;

return (
  <DialogTitle sx={{ m: 0, p:2 }} {...other}>
  {children}
  {onClose ? (
      <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          // color: (theme) => theme.palette.white[500],
          color: '#fff'
      }}
      >
      <CloseIcon />
      </IconButton>
  ) : null}
  </DialogTitle>
);
};

JCDialogLargeTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
