import React, { useState } from 'react';
import { Box, Typography, FormControl, FormControlLabel, RadioGroup, Radio, Grid, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';


import { Row } from '../../../common/components';
import { OPTIONS_SORT_ARTICLES_BY_TIME } from '../../../constants/constants';
import { JC_COLORS } from '../../../styles/constants';
import CenteredActionButton from '../../../common/components/CenteredActionButton';


const StyledFormControl = styled(FormControl)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: "375px",

    }

}));

const StyledDateInput = styled(TextField)(({ theme }) => ({
    padding: 0,
    backgroundColor: JC_COLORS.JC_GREY,
    [theme.breakpoints.up('md')]: {


    },
    [theme.breakpoints.down('md')]: {


    }
}));

const dateToday = moment().format('L');
const date1Week = moment().subtract(7, 'days').format('L');
const date15Days = moment().subtract(15, 'days').format('L');
const date1Month = moment().subtract(1, 'month').format('L');
const date3Months = moment().subtract(3, 'month').format('L');
const date6Months = moment().subtract(6, 'month').format('L');
const date1Year = moment().subtract(1, 'year').format('L');

const OPTIONS = {
    "Past 1 Week": { startDate: date1Week, endDate: dateToday },
    "Past 15 Days": { startDate: date15Days, endDate: dateToday },
    "Past 1 Month": { startDate: date1Month, endDate: dateToday },
    "Past 3 Months": { startDate: date3Months, endDate: dateToday },
    "Past 6 Months": { startDate: date6Months, endDate: dateToday },
    "Past 1 Year": { startDate: date1Year, endDate: dateToday },
    "CUSTOM": {},
    "All Time": {},
};

const CustomRadioDateRange = ({ dateRangeInput, dateRangeLabel, changeDateRangeLabel, changeDateRangeHandler,filterCount = 8 }) => {
    const [startDate, setStartDate] = useState(dateRangeInput.startDate);
    const [endDate, setEndDate] = useState(dateRangeInput.endDate);
    const [dateRange, setDateRange] = useState(dateRangeInput);
    const [option, setOption] = useState(dateRangeLabel)

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <StyledFormControl>
                <RadioGroup
                    name="sort-by-time"
                    onChange={
                        (event) => {
                            setOption(event.target.value);
                            changeDateRangeLabel(event.target.value);
                            changeDateRangeHandler(OPTIONS[event.target.value]);
                        }
                    }
                >
                    <Grid container spacing={0.5}>
                        {
                            Object.keys(OPTIONS_SORT_ARTICLES_BY_TIME).slice(0,filterCount).map((el, i) => (
                                <Grid item sm={6} xs={6} key={i}>
                                    <FormControlLabel
                                        value={el}
                                        control={
                                            <Radio
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        display: 'none',
                                                    },

                                                }}
                                            />
                                        }
                                        label={
                                            <Row
                                                sx={{
                                                    width: "9rem",
                                                    height: '2rem',
                                                    borderRadius: '2px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    padding: '0 0.5rem',
                                                    backgroundColor: el === option ? JC_COLORS.JC_RED : '#F3F3F3',
                                                    color: el === option ? JC_COLORS.JC_WHITE : JC_COLORS.JC_ICON,
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Raleway',
                                                        fontWeight: '600',
                                                        fontSize: '0.75rem',
                                                        lineHeight: '0.875rem',
                                                    }}
                                                >
                                                    {el}
                                                </Typography>
                                                {el === option && (<CheckIcon />)}

                                            </Row>

                                        }
                                    />
                                </Grid>
                            ))
                        }
                        {
                            option === 'CUSTOM' && (
                                <>
                                    <Grid item sm={6} xs={6} key={`from-date`}>
                                        <Box sx={{ ml: "10px" }}>
                                            <Typography
                                                sx={{
                                                    color: JC_COLORS.JC_ICON,
                                                    fontSize: "0.8rem",
                                                    lineHeight: "1.3rem",
                                                    fontWeight: 700
                                                }}
                                            >
                                                From
                                            </Typography>
                                            <MobileDatePicker
                                                //fullWidth
                                                value={startDate}
                                                onChange={
                                                    (date) => {
                                                        setStartDate(Date.parse(moment(date).format('YYYY-MM-DD')))
                                                        setDateRange({ ...dateRange, startDate: moment(date).format('MM/DD/YYYY') })
                                                        changeDateRangeHandler(dateRange)
                                                    }
                                                }
                                                inputFormat="DD.MM.yyyy"
                                                orientation="portrait"
                                                //placeholder={"DD.MM.YYYY"}
                                                renderInput={(params) => {
                                                    params.InputProps.sx = { height: "28px", width: "158px", borderRadius: "2px" };
                                                    params.InputProps.placeholder = "DD.MM.YYYY";
                                                    return (
                                                        <StyledDateInput
                                                            variant="outlined"
                                                            {...params}
                                                        />
                                                    )
                                                }
                                                }
                                                //okText=""
                                                //cancelText=""
                                                toolbarTitle="From"
                                                showToolbar={false}


                                            />

                                        </Box>

                                    </Grid>
                                    <Grid item sm={6} xs={6} key={`to-date`}>
                                        <Box sx={{ mr: "20px", ml: "5px" }}>
                                            <Typography
                                                sx={{
                                                    color: JC_COLORS.JC_ICON,
                                                    fontSize: "0.8rem",
                                                    lineHeight: "1.3rem",
                                                    fontWeight: 700
                                                }}
                                            >
                                                To
                                            </Typography>
                                            <MobileDatePicker
                                                value={endDate}
                                                onChange={
                                                    (date) => {
                                                        setEndDate(Date.parse(moment(date).format('YYYY-MM-DD')))
                                                        setDateRange({ ...dateRange, endDate: moment(date).format('MM/DD/YYYY') })
                                                        changeDateRangeHandler(dateRange)
                                                        changeDateRangeLabel("CUSTOM");
                                                    }
                                                }

                                                inputFormat="DD.MM.yyyy"
                                                orientation="portrait"
                                                //placeholder={"DD.MM.YYYY"}
                                                renderInput={(params) => {
                                                    params.InputProps.sx = { height: "28px", width: "158px", borderRadius: "2px" };
                                                    params.InputProps.placeholder = "DD.MM.YYYY";
                                                    return (
                                                        <StyledDateInput
                                                            variant="outlined"
                                                            {...params}
                                                        />
                                                    )
                                                }
                                                }
                                                //okText=""
                                                //cancelText=""
                                                toolbarTitle="To"
                                                showToolbar={false}
                                            />
                                        </Box>
                                    </Grid>
                                </>
                            )
                        }


                    </Grid>
                    {/* {
                        option === 'CUSTOM' && (
                            <Box sx={{
                                marginTop: "16px",
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        width: "92px",
                                        height: "42px"
                                    }}
                                    onClick={() => changeDateRangeHandler({ startDate, endDate })}
                                >Apply</Button>
                            </Box>
                        )
                    } */}
                </RadioGroup>
            </StyledFormControl >

        </LocalizationProvider>

    )
}

export default CustomRadioDateRange