import React,{useEffect} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Chip,Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Divider } from '@mui/material';
import { Hidden } from '@mui/material';

import { Error404 } from "../../assets"
import JCHeader from './JCHeader';
import { JC_COLORS } from '../../styles/constants';

function Error404Component() {
  useEffect(() =>{
    document.body.style.backgroundColor = JC_COLORS.JC_WHITE
  },[])

  return (
    <React.Fragment>
    <JCHeader />
    <Box sx={{textAlign:'center'}} mt={{xs:'20%',sm:'10%',md:'5%'}} mr={{xs:5,md:36}} ml={{xs:5,md:36}}>
        
        <Box pb={2} mb={{xs:12,md:0}} >
          <Hidden smUp>
            <Box>
              <Error404 width="200px" height="200px"/>
            </Box>
          </Hidden>
          <Hidden smDown>
            <Box>
              <Error404 width="300px" height="300px"/>
            </Box>
          </Hidden>
          <Box mt={{xs:2,sm:4,md:0}} pb={{xs:4,sm:5,md:2}}>
            <Typography variant="H1_RALEWAY" color="primary" gutterBottom>
                We cannot find the page you are looking for!
            </Typography>
          </Box>
          <Button href="/" variant='contained' size="small" color="primary" className="mobile-botton">GO BACK TO JOURNAL CLUB</Button>
          
        </Box>
        <Divider/>
        <Box pt={2}>
          <Typography variant="BODY_COPY2_RALEWAY" gutterBottom>
          Journal Club &copy; 2022
          </Typography>
        </Box>

    </Box>

    </React.Fragment>
  )
}

export default Error404Component