import React from 'react';
import { Box } from '@mui/material';
import SquareAvatar from './SquareAvatar';
import AvatarName from './AvatarName';

const AvatarNameRow = ({ name, logoUrl }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10px',
        marginRight: '15px',
        maxWidth: '400px',
      }}>
      <SquareAvatar
        name={name ? name.length > 0 ? name[0] : '': ''}
        logoUrl={logoUrl}
      >

      </SquareAvatar>
      <AvatarName>{name}</AvatarName>
    </Box>
  );
}

export default AvatarNameRow