import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Button,
    Grid,
    InputAdornment,
    Divider,
    Link,
    IconButton,
    styled,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';
import CustomDropdownDateRange from '../../../Feed/components/CustomDropdownDateRange';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import MessageIcon from '@mui/icons-material/Message';
import { ReactComponent as Edit } from '../../../../assets/Edit.svg';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';
import { ReactComponent as EditBlack } from '../../../../assets/EditBlack.svg'
import {
    InvitedIcon,
    InviteRejectedIcon,
    JoinedIcon,
    LeftIcon,
    RequestedIcon,
    RequestRejectedIcon
} from '../../../../assets'
import { useSelector, useDispatch } from "react-redux";
import { fetchMembershipReports, removeJCMember,postJCInviteUser } from '../../../../api';
import { JC_COLORS } from '../../../../styles/constants';
import { GOOGLE_FORM_FOR_MISSING_ARTICLES, REPORTS_NAV, REPORT_ROWS_PER_PAGE } from '../../../../constants/constants';
import { JC_CONTENT,MEMBERSHIP_STATUS } from "../../../../constants/constants";
import { setJCContent } from "../../../../state/actions/journalclub";
import { updateReportNav, updateMemberId, updateCurrentTab, getReportRootData } from '../../../../state/actions/jcreports';
import { CenterFlexBox, UserCell, InstitutionCell, InlineBox,ManageButton,NumberBox, SummaryBox, StyledTextField, StyledSearchButton, DownloadReportButton, EnhancedTableHead, MembershipHeadCells } from '../report-content/ReportHelpers';
import CustomDropdown from '../../../JCDetails/components/CustomDropdown';
import { AuthContext } from '../../../../context/AuthContext';
import BulkInvite from './BulkInvite';
import ConfirmModal from './ConfirmModal';


const ManageMembers = ({ userEmail }) => {
    const [userRole, setUserRole] = useState("All Users")
    const [value, setValue] = useState('')
    const [queryParameters, setQueryParameters] = useState(null)
    const [membershipData, setMembershipData] = useState({})
    const [userStatus, setUserStatus] = useState("All Status")
    const [inviteOpen,setInviteOpen] = useState(false)
    const [confirmModal,setConfirmModal] = useState(false)
    const [currentUser,setCurrentUser] = useState(false)
    const [SearchText, setSearchText] = useState("")
    const [successToast,setSuccessToast] = useState(false)
    const [toastMessage,setToastMessage] = useState('')
    const dispatch = useDispatch()
    const { jcDetails } = useSelector((state) => state.journalclub);

    // report table states
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [isLoading, setIsLoading] = useState(true)
    let currentLoginUser = localStorage.getItem("jcid");

    const { reportRootData, reportRootFilter, rootDateRange } = useSelector((state) => state.jcreports);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleRowClick = (event) => {
    };

    const handleChangePage = (event, changedValue) => {
        setPage(changedValue);
    };
    const handleMemberClick = (memberData) => {
        dispatch(updateCurrentTab(JC_CONTENT.MANAGE_MEMBERS))
        dispatch(updateMemberId(memberData))
        dispatch(setJCContent(JC_CONTENT.JC_MEMBER))
    }

    const handleEditClick = (memberData) => {
        dispatch(updateCurrentTab(JC_CONTENT.MANAGE_MEMBERS))
        dispatch(updateMemberId(memberData))
        dispatch(setJCContent(JC_CONTENT.EDIT_JC_MEMBER))
    }

    const removeMember = () =>{
        // Remove Member
        removeJCMember(jcDetails?.JCID,currentUser.email).then(res =>{
            setToastMessage("Member Removed!")
            setConfirmModal(false)
            setSuccessToast(true)
        }).catch(error => {
            setToastMessage("Failed to Remove!")
            setConfirmModal(false)
            setSuccessToast(true)
        })
    }

    const resendInvite = (memberData) => {
        postJCInviteUser(jcDetails?.JCID,userEmail,{
            "firstName": memberData.term.split(" ")[0],
            "lastName": memberData.term.split(" ")[1],
            "email" : memberData.email,
            "role" : memberData.role,
            "authUserEmail": userEmail,
          }).then(res => {
            setToastMessage("Invite Re-sent!")
            setSuccessToast(true)
          }).catch(error => {
            setToastMessage("Invite Failed!")
            setSuccessToast(true)
          })
    }
    const emptyRows = 0;

    const handleSearch = () => {
        setIsLoading(true)
        setSearchText(value)
        var queryParams = {
            authUserEmail : userEmail,
            numRecordsPerPage: REPORT_ROWS_PER_PAGE,
            pageNumber: 1,
            sort: order,
            orderBy: orderBy,
            term: value
        }
        if (userStatus !== "All Status") {
            queryParams = { ...queryParams, status: userStatus }
        }
        if (userRole !== "All Users") {
            queryParams = { ...queryParams, role: userRole.slice(0, -1) }
        }
        setQueryParameters(queryParams)
        fetchData(queryParams)
    }

    useEffect(() => {
        setIsLoading(true)
        var queryParams = {
            authUserEmail : userEmail,
            numRecordsPerPage: REPORT_ROWS_PER_PAGE,
            pageNumber: page,
            sort: order,
            orderBy: orderBy
        }
        if(SearchText !== ""){
            queryParams = { ...queryParams, term: SearchText }
        }
        if (userStatus !== "All Status") {
            queryParams = { ...queryParams, status: userStatus }
        }
        if (userRole !== "All Users") {
            queryParams = { ...queryParams, role: userRole.slice(0, -1) }
        }
        setQueryParameters(queryParams)
        fetchData(queryParams)
    }, [ userStatus, page, userRole, order, orderBy, SearchText,toastMessage,inviteOpen])

    const fetchData = (queryParams) => {
        fetchMembershipReports(jcDetails?.JCID, queryParams).then(response => {
            if (response?.data) {
                setMembershipData(response.data)
                setIsLoading(false)
            }
        }).catch(error => {
            console.error(error)
        })
    }

    const handleRemoveClick = (memberData) => {
        setConfirmModal(true)
        setToastMessage('')
        setCurrentUser(memberData)
    }

    const resetPageNumber = (val, callback) => {
        setPage(1)
        callback(val)
    }

    return (
        <React.Fragment>
            <Box sx={{ textAlign: 'left' }} my={-1} mx={-0.8}>
                <Link sx={{ cursor: 'pointer', textDecoration: 'None'}} onClick={() => {dispatch(setJCContent(JC_CONTENT.MANAGE))}} className='report-nav-root_text'>
                    <KeyboardArrowLeftIcon sx={{ position: 'relative', top: '8px' }} />
                    <Typography variant="BODY_COPY2_RALEWAY" sx={{textTransform: 'capitalize'}}>
                        {JC_CONTENT.MANAGE.toLowerCase()}
                    </Typography>
                </Link>
            </Box>


            <Box sx={{ textAlign: 'left' }} mt={2} mb={2}>
                <InlineBox>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant="H1_BITTER" color="primary" sx={{ fontWeight: '700 !important' }}>
                            Manage Members
                        </Typography>
                    </Box>
                </InlineBox>
            </Box>
            {/* Manage Member Summary */}
            <Grid container mb={{ xs: 8, md: 3 }}>
            <Grid item xs={12} md={8} mt={2} mb={3}>
              <Grid container spacing={{ xs: 1.5 }}>
                <Grid item xs={6} md={2.4}>
                  <NumberBox numberOf={reportRootData.numAllReaders} title="Readers" />
                </Grid>
                <Grid item xs={6} md={2.4}>
                  <NumberBox numberOf={reportRootData.numAllContributors} title="Contributors" />
                </Grid>
                <Grid item xs={6} md={3}>
                  <NumberBox numberOf={reportRootData.numAllCourseDirectors} title="Course Directors"/>
                </Grid>
                <Grid item xs={6} md={2.4}>
                  <NumberBox numberOf={reportRootData.numAllAdministrators} title="Administrators" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{textAlign:'left'}} mx={-1}>
            <ManageButton label="Invite New Members" onClick={() => dispatch(setJCContent(JC_CONTENT.INVITE_MEMBER)) }/>
            <ManageButton label="Bulk Invite Members" onClick={() => setInviteOpen(true)}/>
            <DownloadReportButton JCID={jcDetails.JCID} parameter={"members"} queryParameters={queryParameters} currentLoginUser={currentLoginUser} downloadName={"Members_list"} dateRange={{ startDate: null, endDate: null }} variant="outlined" label="Export Member List"/>
          </Box>

        <BulkInvite open={inviteOpen} onClose={ () => setInviteOpen(false)} />
        {/*Manage Member Summary Ends */}
            <Box mt={3} sx={{ textAlign: 'left' }}>
                {membershipData &&
                    <Typography variant="BODY_ARTICLE_RALEWAY" sx={{ fontWeight: '700!important', marginRight: '12px' }}> {membershipData.numberOfRow !== 0 ? membershipData.numberOfRow && (membershipData.numberOfRow !== 1 ? membershipData.numberOfRow + " Members Found" : membershipData.numberOfRow + " Member Found") : "No Result Found"}</Typography>
                }
                {isLoading &&
                    <CircularProgress color='primary' sx={{ width: '20px!important', height: '20px!important' }} />
                }
            </Box>
            <Box sx={{ textAlign: 'left' }} mb={1}>
                <InlineBox mt={2}>
                    <StyledTextField
                        value={value}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch()
                            }
                        }}
                        placeholder={"Search Username"}
                        autoComplete='off'
                        inputProps={{
                            style: {
                                fontFamily: 'Raleway',
                                fontWeight: 600,
                            }
                        }}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
                            endAdornment: (
                                <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                                    <CloseIcon style={{ display: `${value === "" ? 'none' : 'block'}` }} onClick={() => { setSearchText(""); setValue("") }} />
                                </InputAdornment>),
                        }}
                        onChange={(e) => { setValue(e.target.value ? e.target.value : ""); }}
                    ></StyledTextField>
                    <StyledSearchButton variant="outlined" onClick={() => handleSearch()} sx={{ marginLeft: '11px' }}>
                        Search
                    </StyledSearchButton>
                </InlineBox>
                <InlineBox sx={{ float: 'right' }} mt={2}>
                    <InlineBox ml={1}>
                        <CustomDropdown
                            selectedLabel={userRole}
                            listOfLabels={["All Users", "Course Directors", "Administrators", "Contributors", "Readers"]}
                            changeLabel={(val) => resetPageNumber(val, setUserRole)}
                        />
                    </InlineBox>
                    <InlineBox ml={1}>
                        <CustomDropdown
                            selectedLabel={userStatus}
                            listOfLabels={["All Status", "Joined", "Invited", "Requested", "Left","Removed","Invite Rejected", "Request Rejected"]}
                            changeLabel={(val) => resetPageNumber(val, setUserStatus)}
                        />
                    </InlineBox>
                </InlineBox>
            </Box>
            {/*Report Table */}
            {membershipData && membershipData.members &&
                <Box mt={3} mb={3}>
                    <Box sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none",
                                        fontFamily: 'Raleway',
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        borderRadius: '4px'
                                    },
                                    minWidth: 750
                                }}
                                aria-labelledby="tableTitle"
                                size="medium"
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={MembershipHeadCells}
                                />

                                <TableBody sx={{ position: 'relative' }}>
                                    <Backdrop
                                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute', backgroundColor: 'transparent' }}
                                        open={isLoading}
                                    >
                                        <CircularProgress color='primary' />
                                    </Backdrop>
                                    {membershipData.members.map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <React.Fragment>
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleRowClick(event)}
                                                    tabIndex={-1}
                                                    key={row.name}
                                                    sx={{
                                                        "&:hover": {
                                                            backgroundColor: "#FFF7F6!important"
                                                        },
                                                        backgroundColor: "#F3F3F3!important"
                                                    }}
                                                    className={`${row.status !== "Joined" && "disableTableRowManage"}`}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        sx={{ fontFamily: 'Raleway!important' }}
                                                    >
                                                        <UserCell name={row.term} onClick={() => handleMemberClick(row)} isDisabled={row.status !== "Joined"}/>
                                                    </TableCell>
                                                    <TableCell align="left"> <InstitutionCell name={row.institution} /></TableCell>
                                                    <TableCell align="left"><Typography variant="H3_RALEWAY" color="#262626">{row.role}</Typography> </TableCell>
                                                    <TableCell align="left"><Typography variant="H3_RALEWAY" color="#262626" sx={row.status !== "Joined" && { fontStyle: "italic" }}>{row.status}</Typography></TableCell>
                                                    <TableCell align="right" sx={{'&.MuiTableCell-root':{paddingLeft:'8px!important',paddingRight:'8px!important',minWidth:'80px'}}}>
                                                    {row.status === MEMBERSHIP_STATUS.JOINED &&
                                                        <Tooltip title="Edit" arrow>
                                                            <IconButton sx={{cursor: 'pointer'}} onClick={() => handleEditClick(row) } >
                                                                <Edit width={'16'} height={'16'} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {row.status === MEMBERSHIP_STATUS.JOINED &&
                                                        <Tooltip title="Remove" arrow>
                                                            <IconButton sx={{cursor: 'pointer'}} onClick={()=> handleRemoveClick(row)}>
                                                                <DeleteIcon sx={{color:JC_COLORS.JC_RED}} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {row.status === MEMBERSHIP_STATUS.INVITED &&
                                                        <Tooltip title="Resend Invite" arrow>
                                                            <IconButton sx={{cursor: 'pointer'}} onClick={() => resendInvite(row)}>
                                                                <ReplayIcon sx={{color:JC_COLORS.JC_RED,fill: 'rgba(255, 0, 0, 1)'}} />
                                                            </IconButton>
                                                        </Tooltip>
                                                     }
                                                    {row.status === MEMBERSHIP_STATUS.REQUESTED &&
                                                        <Tooltip title="Decide Membership" arrow>
                                                            <IconButton sx={{cursor: 'pointer'}} onClick={() => handleMemberClick(row)}>
                                                                <MessageIcon sx={{color:JC_COLORS.JC_RED,fill: 'rgba(255, 0, 0, 1)'}} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    </TableCell>
                                                </TableRow>
                                                <Box sx={{ minHeight: '12px' }}></Box>
                                            </React.Fragment>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box mt={3} sx={{ marginLeft: 'calc( 50% - 80px)' }}>
                            <Pagination count={Math.ceil(membershipData.numberOfRow / REPORT_ROWS_PER_PAGE)} color="primary" page={page} onChange={handleChangePage} />
                        </Box>
                    </Box>
                </Box>
            }
            <ConfirmModal 
            open = {confirmModal}
            onClose = {() => setConfirmModal(false)}
            onCancel = {() => setConfirmModal(false)}
            onProceed = {removeMember}
            message = {`You are about to remove ${currentUser.term} from this journal club?`}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={successToast}
                autoHideDuration={2000}
                onClose={() => setSuccessToast(false)}
                key={'bottom' + 'center'}
            >
            <SnackbarContent sx={{
                backgroundColor: '#262626',
                fontFamily: 'Raleway',
                fontSize: '14px',
                padding: '0',
                paddingLeft: '20px',
            }}
                message={ toastMessage }
            />
            </Snackbar>
        </React.Fragment>
    )
}

export default ManageMembers
