import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogContent, DialogTitle, Typography, Input, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import JCSnackbar from '../../../common/components/JCSnackbar';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { acceptJCInvite, rejectJCInvite, joinJC, leaveJC } from '../../../api';
import { getJournalClubs, getJCUserDetails, getJournalClubDetails, setJCContent } from '../../../state/actions/journalclub';
import { JC_COLORS } from '../../../styles/constants';
import { pxToRem } from '../../../utils';
import { JC_CONTENT } from '../../../constants/constants';

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Raleway',
    fontWeight: 700,
    textTransform: 'none',
    width: 'fit-content',
    [theme.breakpoints.up('md')]: {
        fontSize: '0.75rem',
        lineHeight: '0.875rem',
        //height: '42px',
        minWidth: '142px',


    },
    [theme.breakpoints.down('md')]: {
        fontSize: pxToRem(12),
        lineHeight: pxToRem(20),
        //height: '28px',
        //minWidth: '150px',
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        '& .MuiDialogContent-root': {
            padding: "25px",
        },
        '& .MuiDialogActions-root': {
            padding: "25px",
        },
        '& .MuiDialog-paper': {
            width: '70%',
            //height: '68%'
        }
    },
    [theme.breakpoints.down('md')]: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialog-paper': {
            width: '80%',
            height: 'auto',
        }
    },

}));


const JoinJCButton = ({ JCID, userEmail, journalClubName, isJCPublic, nextAction, sx }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [openJoinJCRequestNote, setOpenJoinJCRequestNote] = useState(false);
    const [joinJCRequestNote, setJoinJCRequestNote] = useState("");

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { jcDetails, jcContent, jcUserDetails } = useSelector((state) => state.journalclub);

    return (
        <>
            <StyledButton
                variant="contained"
                sx={{
                    height: '42px',
                    textTransform: 'uppercase',
                    ...sx,
                }}
                onClick={
                    async () => {
                        if (isJCPublic) {
                            try {
                                const response = await joinJC(JCID, userEmail) 
                                if (response?.status === 204) {
                                    dispatch(getJournalClubDetails(JCID, userEmail))
                                    dispatch(getJCUserDetails(JCID, userEmail));
                                    dispatch(getJournalClubs(userEmail));
                                    dispatch(setJCContent(JC_CONTENT.DEFAULT));
                                }
                                if (nextAction) {
                                    nextAction()
                                }
                            } catch (error) {
                                console.log(error);
                                console.log(error?.response);
                                setErrorMessage(`Message: ${error?.response?.data?.message}. Status: ${error?.response?.status}`);
                                setOpenErrorDialog(true);
                            }

                        } else {
                            setOpenJoinJCRequestNote(true)
                        }

                    }
                }
            >
                Join Journal Club
            </StyledButton>
            <BootstrapDialog
                open={openJoinJCRequestNote}
                onClose={() => setOpenJoinJCRequestNote(false)}
            >
                <DialogTitle
                    sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
                        backgroundColor: JC_COLORS.JC_RED,
                        color: JC_COLORS.JC_WHITE,
                        ...theme.typography.H3_BITTER,
                        fontWeight: 700,

                    }}
                >
                    Request to join journal club

                </DialogTitle>
                <DialogContent
                    sx={{
                        margin: '33px 12px 24px 0px',
                        '&::-webkit-scrollbar': { width: '6px' },
                        '&::-webkit-scrollbar-track': { backgroundColor: JC_COLORS.LIGHT_GREY, borderRadius: '9px', },
                        '&::-webkit-scrollbar-thumb': { backgroundColor: JC_COLORS.JC_ICON, borderRadius: '9px' },
                    }}
                >
                    <Typography
                        sx={{
                            color: JC_COLORS.JC_BLACK,
                            fontFamily: 'Raleway',
                            fontWeight: 500,
                            fontSize: '24px',
                            lineHeight: '30px',
                            margin: '0px 0px 0px 0px',
                            textAlign: 'left',
                            overflowWrap: 'break-word',

                        }}
                    >
                        Your profile details will be shared with the {journalClubName ?? 'journal club'} admin. Please ensure your profile is up-to-date.
                    </Typography>

                    <Input
                        placeholder="Optional message here"
                        disableUnderline
                        multiline={true}
                        autoFocus={true}
                        fullWidth={true}
                        minRows={6}
                        sx={{
                            marginTop: '27px',
                            pt: '16px',
                            pl: '24px',
                            border: `1px solid ${JC_COLORS.JC_ICON}`,
                            borderRadius: '4px',
                            '& .Mui-focused': {
                                borderBottom: `1px solid ${JC_COLORS.JC_ICON}`,
                            },

                        }}
                        onChange={(event) => setJoinJCRequestNote(event.target.value)}
                    ></Input>

                    <Box
                        sx={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                            margin: '29px 0px 0px 0px',
                        }}
                    >
                        <StyledButton
                            variant="outlined"
                            sx={{
                                margin: '0px 0px 0px 0px',
                                padding: '8px 22px',
                                textAlign: 'center',
                                width: 'fit-content',
                                display: 'inline-block',
                            }}
                            onClick={() => setOpenJoinJCRequestNote(false)}
                        >
                            CANCEL REQUEST
                        </StyledButton>

                        <StyledButton
                            variant="contained"
                            sx={{
                                margin: '0px 0px 0px 18px',
                                padding: '8px 22px',
                                textAlign: 'center',
                                width: 'fit-content',
                                display: 'inline-block',
                            }}
                            onClick={
                                async () => {

                                    try {
                                        const response = await joinJC(JCID, userEmail, { reason: joinJCRequestNote })
                                        //const response = await joinJC(JCID, userEmail, {}); 
                                        if (response) {
                                            if (theme.breakpoints.up('md')) {
                                                dispatch(getJournalClubDetails(JCID, userEmail))
                                                dispatch(getJCUserDetails(JCID, userEmail));
                                                dispatch(getJournalClubs(userEmail));
                                                dispatch(setJCContent(JC_CONTENT.DEFAULT));
                                            } else {
                                                dispatch(setJCContent(JC_CONTENT.DEFAULT));
                                                window.location.reload();
                                            }
                                        }
                                        if (nextAction) {
                                            nextAction()
                                        }
                                    } catch (error) {
                                        console.log(error);
                                        console.log(error?.response)
                                        setErrorMessage(`Message: ${error?.response?.data?.message}. Status: ${error?.response?.status}`);
                                        setOpenErrorDialog(true);


                                    }
                                }
                            }
                        >
                            SUBMIT REQUEST
                        </StyledButton>

                    </Box>
                </DialogContent>
            </BootstrapDialog>
            <BootstrapDialog
                open={openErrorDialog}
                onClose={() => setOpenErrorDialog(false)}
            >
                <DialogTitle
                    sx={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                        backgroundColor: JC_COLORS.JC_RED,
                        color: JC_COLORS.JC_WHITE,
                    }}
                >
                    <Typography sx={{ ...theme.typography.H3_BITTER, fontWeight: 700, }}>Error</Typography>
                    <CloseIcon
                        sx={{
                            width: '24px',
                            height: '24px',
                            color: JC_COLORS.WHITE,
                        }}
                        onClick={() => setOpenErrorDialog(false)}
                    />

                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{ ...theme.typography.H3_RALEWAY, fontWeight: 500, marginTop: "16px" }}
                    >
                        {errorMessage}
                    </Typography>
                </DialogContent>

            </BootstrapDialog>

        </>
    )
}

export default JoinJCButton
