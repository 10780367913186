import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { url as API_URL } from '../../api';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { deleteComment,deleteCommentMedia } from '../../api';
import { getCommentAndNotes,setNewCount } from '../../state/actions/comment';
import { getArticle, setSaved,markArticleAsRead,getJournalClubArticle } from '../../state/actions/article';
import ReportComment from './ReportComment';
import CommentModal from './CommentModal';

const options = [
  'Report',
  'Edit',
  'Delete',
];
function getStyles(mobileMatch,theme) {
    return {
      minHeight: mobileMatch ? '25px' : '30px',
    };
}
const ITEM_HEIGHT = 42;

export default function CommentOptions(props) {
 
  const { id, data ,isNote,isReply,postedByCurrentUser,reportedByUser} = props
  const commentMedia = data !== undefined?data.commentMedia: []
  const auth =  React.useContext(AuthContext)
  const { PMID ,JCID } = useParams()
  const isJCPage = JCID ? JCID === 'J00000000' ? false : true : false
  const userEmail = auth.user.email.split('@').join('%40')
  const [anchorEl, setAnchorEl] = React.useState(null) 
  const [reportOpen,setReportOpen] = React.useState(false)
  const [editOpen,setEditOpen] = React.useState(false)
  const theme = useTheme();
  const dispatch = useDispatch()
  const mobileMatch= useMediaQuery(theme.breakpoints.down('md'))
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEditOpen = () =>{
    setEditOpen(true)
    setAnchorEl(null);
  }
  const handleEditClose = () =>{
    setEditOpen(false)
    
  }

  const handleClose = (e=null) => {
    if(e.target.textContent === 'Delete'){
      let _DATA = JSON.parse(localStorage.getItem('jc-comments'))
      _DATA = _DATA.filter(d => d.id !== id)
      localStorage.setItem('jc-comments', JSON.stringify(_DATA))
    }
    
    
    const _actionName = e.target.dataset.commentaction
    // NOTE: dispatch action through fetchAPI
    let _actionEndpoint = `/comments/${id}/${userEmail}/action/`
    const _editEndpoint = `/comments/${id}/${userEmail}`
    if(_actionName.toLowerCase() === 'report' ||  _actionName.toLowerCase() === 'like'){
      const _body = {body: ''}
      if(_actionName === 'report') _body.body = ''
      else if(_actionName === 'like') _body.body = ''
      
      fetch(API_URL, _actionEndpoint + _actionName, {
        headers:{
          'Authorization' : 'Bearer ' + localStorage.id_token,
          "Content-Type": "application/json",
        },
        method:'POST',
        mode: 'cors',
      })
      .then(res => {
        return res.text()
      })
      .then(res => {
        const _res = JSON.parse(res)
        console.log(`After performing "${_actionName}" result is: `, _res)
      })
      .catch(err => console.warn(`Error occurred while performing "${_actionName}" operation.`))
    }
    else if(_actionName.toLowerCase() === 'edit'){
      fetch(API_URL + _editEndpoint, {
        headers:{
          'Authorization' : 'Bearer ' + localStorage.id_token,
          "Content-Type": "application/json",
        },
        method:'POST',
        mode: 'cors',
        // body: '' // NOTE: TO BE ADDED
      })
      .then(res => res.text())
      .then(res => {
        const _res = JSON.parse(res)
        console.log(`After performing "${_actionName}"(only edit is possible) result is: `, _res)
      })
      .catch(err => console.warn(`Error occurred while performing "${_actionName}" operation.`))
    }
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
  }

  const handleDeleteClick = () => {
    deleteComment(id).then(response => {
      if (response.data) {
           // Note :  Deleting media related to this comment from s3
          //  if(commentMedia && commentMedia.length > 0){
          //    commentMedia.map(comment =>{
          //     deleteCommentMedia(id,comment.fileName).then(res => 
          //       console.log("deleted media successfully"))
          //       .catch(error => console.error(error))
          //    })
          //  }
          if(isJCPage){
            dispatch(getJournalClubArticle(JCID,PMID,auth.user.email,false))
          }
          else{
            dispatch(getArticle(PMID, auth.user.email,false))
          }
          dispatch(setNewCount())
        }
      }).catch(error => {
          const errorMsg = error.message
          console.log(errorMsg)
      }
    )
    setAnchorEl(null);
  }

  const handleReportClick = () =>{
    setReportOpen(true)
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{float:"left"}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '15ch',
          },
        }}
      >
        {!postedByCurrentUser &&
        <MenuItem key='Report' onClick={handleReportClick} style={getStyles(mobileMatch,theme)}>
          <Typography variant='H3_RALEWAY'>Report</Typography>
        </MenuItem>
        }
        {postedByCurrentUser &&
        <MenuItem key='Edit' onClick={handleEditOpen} style={getStyles(mobileMatch,theme)}>
          <Typography variant='H3_RALEWAY'>Edit</Typography>
        </MenuItem>
        }
        {postedByCurrentUser &&
        <MenuItem key='Delete'  onClick={handleDeleteClick} style={getStyles(mobileMatch,theme)}>
          <Typography variant='H3_RALEWAY'>Delete</Typography>
        </MenuItem>
        }
      
      </Menu>

      <ReportComment open={reportOpen} id={id} onClose={() => setReportOpen(!reportOpen)} />
      <CommentModal open={editOpen} data={data} onClose={handleEditClose} isReply={isReply} editCommentID={id} isNote={isNote} isEdit={editOpen}/>
        
    </div>
  );
}
