import React from "react";

const ShareIcon = ({className}) => {
    return (
        <svg className={className} width="29" height="29" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 19.44C19.9867 19.44 19.08 19.84 18.3867 20.4667L8.88 14.9333C8.94667 14.6267 9 14.32 9 14C9 13.68 8.94667 13.3733 8.88 13.0667L18.28 7.58666C19 8.25332 19.9467 8.66666 21 8.66666C23.2133 8.66666 25 6.87999 25 4.66666C25 2.45332 23.2133 0.666656 21 0.666656C18.7867 0.666656 17 2.45332 17 4.66666C17 4.98666 17.0533 5.29332 17.12 5.59999L7.72 11.08C7 10.4133 6.05333 9.99999 5 9.99999C2.78667 9.99999 1 11.7867 1 14C1 16.2133 2.78667 18 5 18C6.05333 18 7 17.5867 7.72 16.92L17.2133 22.4667C17.1467 22.7467 17.1067 23.04 17.1067 23.3333C17.1067 25.48 18.8533 27.2267 21 27.2267C23.1467 27.2267 24.8933 25.48 24.8933 23.3333C24.8933 21.1867 23.1467 19.44 21 19.44Z" fill="#838383" stroke="#838383" strokeWidth="0.25"/>
</svg>
          
    )
}
export default ShareIcon