import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as api from "../../api";

import { makeStyles } from '@mui/styles';
import { alpha, styled } from '@mui/material/styles';

import { Hidden, Card, Box, Typography, Button, IconButton, CircularProgress, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Row, Spacer, TopDrawer, CenteredActionButton } from "../../common/components";
import SearchBox from './components/SearchBox';
import KeyWordsList from '../../common/containers/KeyWordsList';
import { JC_COLORS } from '../../styles/constants.js';
import { calcViewWidth, calcViewHeight, calcRem } from '../../utils';

import { editKeywords, removeAnyKeywords, clearAllKeywords, keywordAdded } from '../../state/actions/keywords';
import { removeAnySources, clearAllSources, sourceAdded } from '../../state/actions/sources';
import { updateUserDetails, getUserDetails } from '../../state/actions/user';
import keywords from '../../state/reducers/keywords';
import CenteredContainer from '../../common/components/CenteredContainer';

import { AuthContext } from '../../context/AuthContext';
import { useKeywordsContext } from '../../context/KeywordsContext';

let appState = JSON.parse(localStorage.getItem('appState'));

const MainCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: JC_COLORS.JC_WHITE,
    [theme.breakpoints.up('md')]: {
        //width: "73vw", // 1369 / 1920
        //maxHeight: "928px",  // 1203 / 1377 or 1203 to rem 
        //height: '380px', 
        height: '78vh',
        padding: "30px 16px 0px 40px",
        justifyContent: 'space-between',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0 1rem 0 1rem'
    }

}));

const TextHyperLink = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: JC_COLORS.JC_RED,
    ...theme.typography.BODY_COPY2_RALEWAY_DESKTOP,
}));

const UnderlinedText = styled(Typography)(({ theme }) => ({
    textDecoration: 'underline',
    color: JC_COLORS.JC_RED,
    fontFamily: 'Raleway',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontWeight: '600',
    marginBottom: "14px",

}));

const StyledTitle = styled(Typography)(({ theme }) => ({
    textAlign: 'left',
    ...theme.typography.H3_BITTER,
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
        minWidth: '20%',
        marginRight: "22px",
        // fontSize: '1rem',
        // lineHeight: '1.5rem',
    },
    [theme.breakpoints.down('md')]: {
        margin: '18px 0',
        fontSize: '12px',
        lineHeight: '16px',

    }

}));

//const TextTitle = styled(Typography)(({theme}) => ({})); 


const Title = ({ closeEditPageHandler }) => {
    return (
        <Row sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Typography
                variant="H2_BITTER_DESKTOP"
                fontWeight='bold'
                color="primary"
                textAlign="left"
                display="flex"
                justifyContent="flex-start"
                sx={{ marginBottom: '16px' }}
            >
                Edit Feed Keywords and/or Sources
            </Typography>

            <IconButton aria-label="close" onClick={closeEditPageHandler}>
                <CloseIcon sx={{ width: "28px", height: "28px" }} />
            </IconButton>
        </Row>
    )
}



const RemoveAny = ({ clearAllCallback, removeAnyCallback, text }) => {
    return (
        <Row sx={{ alignItems: "center", justifyContent: 'flex-end' }}>
            <TextHyperLink onClick={clearAllCallback}>Clear All</TextHyperLink>
        </Row>
    )
}




const EditKeywordsOrSources = () => {
    const auth = useContext(AuthContext);
    const userEmail = auth.user.email;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state);
    const { isUpdating, userDetails } = user;
    

    const { keywordsState, sourcesState, addAKeyword, addASource, clearAllKeywords, clearAllSources} = useKeywordsContext(); 


    return (

        <MainCard>
            <Hidden mdDown>
                <Title closeEditPageHandler={() => navigate('/feed')} />
                {
                    isUpdating && (

                        <CenteredContainer>
                            <CircularProgress />
                        </CenteredContainer>
                    )
                }

                {
                    !isUpdating && (
                        <Box
                            sx={{
                                maxHeight: "75%",
                                paddingRight: '18px',
                                overflowY: 'scroll',
                                '&::-webkit-scrollbar': { width: '6px' },
                                '&::-webkit-scrollbar-track': { backgroundColor: JC_COLORS.LIGHT_GREY, borderRadius: '9px', },
                                '&::-webkit-scrollbar-thumb': { backgroundColor: JC_COLORS.JC_ICON, borderRadius: '9px' },
                            }}
                        >
                            <StyledTitle>Feed Key Words</StyledTitle>
                            <Spacer height={calcRem(24)} />
                            <SearchBox
                                searchApiUrl={api.SEARCH_KEYWORDS_URL}
                                placeholder={"Search Keyword"}
                                addButtonClickHandler={addAKeyword}
                            >
                            </SearchBox>

                            <RemoveAny
                                clearAllCallback={() => clearAllKeywords()}
                                text={"keyword"}
                            />
                            <Spacer height={calcRem(16)} />
                            <KeyWordsList
                                type={"keyword"}
                                title={"Feed Key Words"}
                                itemsObj={keywordsState}
                                edit={true}
                                showCloseIconForChip={true}
                                disableClickOnChips={true}
                            />
                            <Spacer height={calcRem(40)} />



                            <StyledTitle>Journal Sources</StyledTitle>
                            <Spacer height={calcRem(24)} />
                            <SearchBox
                                searchApiUrl={api.SEARCH_SOURCES_URL}
                                placeholder={"Search Journal Source"}
                                addButtonClickHandler={addASource}
                            >
                            </SearchBox> 
                            <RemoveAny
                                clearAllCallback={() => clearAllSources()}
                                text={"source"}
                            /> 
                            <Spacer height={calcRem(16)} />
                            <KeyWordsList
                                type={"source"}
                                title={"Sources"}
                                itemsObj={sourcesState}
                                edit={true}
                                showCloseIconForChip={true}
                                disableClickOnChips={true}
                            />
                            <Spacer height={calcRem(52)} />


                        </Box>
                    )
                }
                <Button
                    variant="contained"
                    sx={{
                        width: calcViewWidth(88),
                        fontWeight: 'bold',
                        alignSelf: 'center',
                        marginBottom: '12px',
                    }}
                    onClick={
                        async () => {

                            try {
                                let res = await api.updateUserDetails({
                                    keywords: Object.keys(keywordsState).join("|"), 
                                    sources: Object.keys(sourcesState).join("|")
                                }, userEmail);
                                if (res.data === "User updated") {
                                    window.location = "/feed"; 
                                } else {
                                    console.log(res);
    
                                }
                            } catch (error) {
                                console.log(error.response);
                                
                            }

                        }
                    }
                >
                    Done
                </Button>



            </Hidden>


        </MainCard >



    );


}

export default EditKeywordsOrSources 