import React from 'react'
import { Snackbar,SnackbarContent, Button, IconButton } from '@mui/material'; 
import CloseIcon from '@mui/icons-material/Close';



const JCSnackbar = ({ message, open, onClose,vertical="bottom",horizontal="center" }) => {
    const calcwidth = message.length * 10 < 140 ? 140 : message.length * 10
    return (
        <Snackbar
          anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
          open={open}
          autoHideDuration={20000}
          onClose={onClose}
          key={'vertical' + horizontal}
        >
          <SnackbarContent sx={{
            backgroundColor: '#262626',
            fontFamily: 'Raleway',
            fontSize: '14px',
            padding: '0',
            paddingLeft: '20px',
            maxWidth: `${calcwidth}px!important`,
            minWidth: `${calcwidth}px!important`
          }}
            message={message}
          />
        </Snackbar>
    )
}

export default JCSnackbar