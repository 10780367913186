import React from 'react';
import { Hidden, Box, Button, Typography, AppBar, Toolbar, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { JC_COLORS } from '../../styles/constants';
import { JCLogoNew } from '../../assets';
import { useLocation } from 'react-router';

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'white',
    color: JC_COLORS.JC_RED,
    fontFamily: 'Raleway',
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
        marginLeft: '11px',
        padding: '8px 22px',
        width: '142px',
        height: '42px',
        borderRadius: '4px',
        fontSize: '15px',
        lineHeight: '26px',
        '&:hover': {
            backgroundColor: 'white',
            color: JC_COLORS.JC_RED,
        }

    },
    [theme.breakpoints.down('md')]: {
        marginLeft: '5px',
        padding: '4px 10px',
        width: '62px',
        height: '32px',
        fontSize: '12px',
        lineHeight: '20px',
    }
}));

const GuestNavbarMobile = () => {
    const theme = useTheme();
    const location = useLocation();
    return (
        <Box>
            <AppBar
                position="fixed"
                sx={{
                    [theme.breakpoints.down("sm")]: {
                        width: "100%"
                    },
                    zIndex: theme.zIndex.drawer + 1,
                    bgcolor: JC_COLORS.JC_RED,
                    color: JC_COLORS.JC_WHITE,
                }}>
                <Toolbar >
                    <div style={{ width: '100%' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                p: 1,
                                borderRadius: 1,
                                alignItems: "center",
                                height: "2.5rem",
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexGrow: 1,

                            }}>
                                <JCLogoNew width="140px" height="40px" />

                            </Box>

                            <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <StyledButton
                                    variant="contained"
                                    onClick={
                                        () => {
                                            localStorage.setItem('usr_landing_url', location.pathname)
                                            window.location = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
                                        }
                                    }
                                >
                                    Login
                                </StyledButton>
                                <StyledButton
                                    variant="contained"
                                    onClick={
                                        () => {
                                            window.location = `${process.env.REACT_APP_AWS_DOMAIN}/signup?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
                                        }
                                    }
                                >
                                    Signup
                                </StyledButton>
                            </Box>



                        </Box>
                    </div>

                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default GuestNavbarMobile